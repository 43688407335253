import React from 'react';
import { Card } from '@shopify/polaris';
import { Query, Mutation } from 'react-apollo';
import { Button, ButtonGroup, PageActions, Stack, TextContainer, Banner, TextStyle } from '@shopify/polaris';
import ErrorPage from '../../Error/ErrorPage.jsx';
import ErrorCard from '../../Error/ErrorCard';
import LegacySubscribedEvents from './LegacySubscribedEvents';
import LegacyShopSubscription from './LegacyShopSubscription';
import CardLoading from '../../shared/CardLoading.jsx';
import { useHistory } from "react-router-dom";
import { useFrame } from '../../../contexts/frame';
import { GET_ACTIVE_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from '../../../utils/graphql';
import { goToPage } from '../../../utils/routing';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const SubscriptionCard = () => {
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = React.useState(false);

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Query query={GET_ACTIVE_SUBSCRIPTION}>
      {({ loading, error, data }) => {
        if (error) return <ErrorCard title='Current subscription' error={error}/>;
        if (loading) return <CardLoading />;

        const shop = data.currentShop;
        const activeSubscription = shop && shop.activeSubscription;

        if (activeSubscription && activeSubscription.externalBilling) {
          return (
            <Banner status='info'>You currently have an external or comped subscription. Please contact us directly to make changes to this.</Banner>
          );
        }

        if (!activeSubscription && shop.developmentPlan) {
          return (
            <Card title='Current subscription' sectioned>
              <TextContainer>The app is free with access to all features from a development shop. Reach out to us if you have any questions!</TextContainer>
            </Card>
          );
        }

        return (
          <Card sectioned
            title="Current subscription"
            primaryFooterAction={{
              content: activeSubscription ? 'Change plan' : 'Select plan',
              onAction: () => goToPage({ history, path: '/plans' }),
            }}
            secondaryFooterActions={activeSubscription ? [{
              content: 'Cancel Subscription',
              destructive: true,
              onAction: () => setConfirmCancelModalOpen(true),
            }] : []}
          >
            <TextContainer spacing="loose">
              {activeSubscription ? (
                <>You've subscribed to the <span style={{fontWeight:'bold'}}>{activeSubscription.plan.name}</span> plan for ${parseInt(activeSubscription.price).toString()} per month plus ${activeSubscription.perTicketPrice} per ticket.</>
              ) : (
                shop.trialExpiresAt && shop.trialExpiresAt ? (
                  <>You have no active subscription. Your trial expires in {dayjs(shop.trialExpiresAt).fromNow()}. Click the Select Plan button to choose a plan before your trial ends.</>
                ) : (
                  <>You have no active subscription. Click the Select Plan button to choose one and start using the app.</>
                )
              )}
            </TextContainer>
            {confirmCancelModalOpen &&
              <CancelSubscriptionModal shop={shop} subscription={activeSubscription} onDismiss={() => setConfirmCancelModalOpen(false)} />
            }
          </Card>
        );
      }}
    </Query>
  );
};

export default SubscriptionCard;
