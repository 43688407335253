import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, TextField, FormLayout, TextContainer, Filters, ChoiceList, Stack, Spinner, Banner, Select, Link, InlineError, Checkbox } from '@shopify/polaris';
import { useState } from 'react';
import DeleteReminderConfirmModal from './DeleteReminderConfirmModal.jsx';
import SendTestModal from './SendTestModal.jsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Editor } from '@tinymce/tinymce-react';
import styled from '@emotion/styled';
import FormFieldLabel from '../shared/FormFieldLabel';
import ScheduleMessageModal from './ScheduleMessageModal';
import { hasPlanFeature } from '../../utils/features';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';
import AttendeeFilters from '../AttendeeList/AttendeeFilters';

const TINY_MCE_API = document.head.querySelector('[name=tiny-mce-api]').content;

const InlineErrorContainer = styled.div`
  margin-top:10px;
`;

dayjs.extend(utc)

const GET_ATTENDEES = gql`
  query ReminderAttendeesQuery($eventId: ID!, $category: String, $filters: String, $search: String) {
    currentShop {
      id
      event(id: $eventId) {
        id
        attendeesCount(eventId: $eventId, category: $category, filters: $filters, search: $search)
        totalAttendeeCount(eventId: $eventId)
      }
    }
  }
`;

const CREATE_REMINDER = gql`
  mutation createAttendeeReminder($eventId: ID!, $name: String, $filters: String, $search: String, $subject: String, $message: String, $ctaLabel: String, $ctaUrl: String, $sendUnique: Boolean) {
    createAttendeeReminder(eventId: $eventId, name: $name, filters: $filters, search: $search, subject: $subject, message: $message, ctaLabel: $ctaLabel, ctaUrl: $ctaUrl, sendUnique: $sendUnique) {
      attendeeReminder {
        id
      }
    }
  }
`;

const EDIT_REMINDER = gql`
  mutation editAttendeeReminder($eventId: ID!, $reminderId: ID!, $name: String, $filters: String, $search: String, $subject: String, $message: String, $ctaLabel: String, $ctaUrl: String, $sendUnique: Boolean) {
    editAttendeeReminder(eventId: $eventId, reminderId: $reminderId, name: $name, filters: $filters, search: $search, subject: $subject, message: $message, ctaLabel: $ctaLabel, ctaUrl: $ctaUrl, sendUnique: $sendUnique) {
      attendeeReminder {
        id
      }
    }
  }
`;

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === '' || value == null;
  }
};

const initialFilterValue = (reminder, filter) => {
  if (!reminder) {
    if (filter == 'ticketStatus') {
      return ['Active'];
    } else if (filter == 'purchasedSinceDate' || filter == 'purchasedSinceTime') {
      return null;
    } else if (filter == 'purchasedSinceMessage') {
      return '';
    } else {
      return [];
    }
  }

  const options = JSON.parse(reminder.filters || '{}');
  if (filter == 'ticketStatus') {
    if (options['status'] == 'active') {
      return ['Active'];
    } else if (options['status'] == 'cancelled') {
      return ['Cancelled'];
    } else {
      return [];
    }
  } else if (filter == 'ticketType') {
    return options['ticket_type_id'] ? options['ticket_type_id'].split(',') : [];
  } else if (filter == 'checkinStatus') {
    if (options['checkedin'] != undefined && options['checkedin']) {
      return ['Checked-In'];
    } else if (options['checkedin'] != undefined && !options['checkedin']) {
      return ['Not Checked-In'];
    } else {
      return [];
    }
  } else if (filter == 'ticketDeliveryStatus') {
    if (options['delivery_status'] != undefined && options['delivery_status']) {
      return ['Sent'];
    } else if (options['delivery_status'] != undefined && !options['delivery_status']) {
      return ['Not Sent'];
    } else {
      return [];
    }
  } else if (filter == 'purchasedSinceDate') {
    if (options['purchased_since'] != null) {
      const d = dayjs.utc(options['purchased_since']);
      const yearPart = d.get('year').toString();
      const monthPart = (d.get('month')+1).toString().padStart(2, '0');
      const dayPart = d.get('date').toString().padStart(2, '0');
      return `${yearPart}-${monthPart}-${dayPart}`;
    } else {
      return null;
    }
  } else if (filter == 'purchasedSinceTime') {
    if (options['purchased_since'] != null) {
      const d = dayjs.utc(options['purchased_since']);
      const hourPart = d.get('hour').toString().padStart(2, '0');
      const minutePart = d.get('minute').toString().padStart(2, '0');
      return `${hourPart}:${minutePart}`;
    } else {
      return null;
    }
  } else if (filter === 'scheduledOn') {
    if (options['scheduled_on'] != null) {
      return options['scheduled_on'].split(',');
    } else {
      return null;
    }
  }
};

const ReminderEditCard = ({ shop, event, reminder, onChange, flashCallback, history, messageError, subjectError, ctaLabelError, ctaUrlError }) => {
  const [name, setName] = useState(reminder ? reminder.name : '');
  const [searchValue, setSearchValue] = useState(reminder ? reminder.search : '');
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [sendTestModalOpen, setSendTestModalOpen] = useState(false);
  const [sendUnique, setSendUnique] = useState(reminder ? reminder.sendUnique : false);
  const [appliedFilters, setAppliedFilters] = useState([
    { key: 'ticketStatus', value: initialFilterValue(reminder, 'ticketStatus') },
    { key: 'checkinStatus', value: initialFilterValue(reminder, 'checkinStatus') },
    { key: 'ticketDeliveryStatus', value: initialFilterValue(reminder, 'ticketDeliveryStatus') },
    { key: 'ticketType', value: initialFilterValue(reminder, 'ticketType') },
    { key: 'purchasedSinceDate', value: initialFilterValue(reminder, 'purchasedSinceDate') },
    { key: 'purchasedSinceTime', value: initialFilterValue(reminder, 'purchasedSinceTime') },
    { key: 'purchasedSinceMessage', value: initialFilterValue(reminder, 'purchasedSinceMessage') },
    { key: 'emailStatus', value: initialFilterValue(reminder, 'emailStatus') },
    { key: 'phoneStatus', value: initialFilterValue(reminder, 'phoneStatus') },
    { key: 'scheduledOn', value: initialFilterValue(reminder, 'scheduledOn') },
  ]);

  const [message, setMessage] = useState(reminder ? reminder.message : '');
  const [subject, setSubject] = useState(reminder ? reminder.subject : event.email.defaultReminderSubject);
  const [ctaLabel, setCtaLabel] = useState(reminder ? reminder.ctaLabel : '');
  const [ctaUrl, setCtaUrl] = useState(reminder ? reminder.ctaUrl : '');

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const ticketTypeOptions = event.tickets.map((t) => ({ label: t.title, value: t.id }));

  const editorRef = React.useRef(null);

  const hasMessagesFeature = hasPlanFeature({ shop, feature: 'messages' });

  const isValid = name && name.length && subject && subject.length && message && message.length && !messageError && !subjectError && !ctaLabelError && !ctaUrlError;
  const canDelete = reminder && !reminder.sentAt && !reminder.sending;
  const canSave = reminder && !reminder.sentAt && !reminder.sending || !reminder;
  const canSendTest = !!reminder;

  let secondaryActions = [];

  if (canSendTest) {
    secondaryActions.push({
      content: 'Send test email',
      onAction: () => {},
    });
    secondaryActions.push({
      content: 'Schedule delivery',
      onAction: () => setShowScheduleModal(true),
    });
  }

  if (canDelete) {
    secondaryActions.push({
      content: 'Delete',
      destructive: true,
      onAction: () => setConfirmDeleteModalOpen(true)
    });
  }

  return (
    <Mutation mutation={reminder ? EDIT_REMINDER : CREATE_REMINDER}
      onError={() => {
        flashCallback(true, 'Unable to create message template', true);
      }}
      onCompleted={(data) => {
        if (data && (data.createAttendeeReminder || data.editAttendeeReminder)) {
          flashCallback(true, 'Attendee message saved');
          history.push(`/events/${event.id}/reminders/${data.createAttendeeReminder.attendeeReminder.id}`);
          window.scrollTo({ top: 0 });
        }
      }}
      refetchQueries={() => ['EventQuery', 'AttendeeReminderQuery']}
    >
      {(createAttendeeReminder, { loading: saving }) => (
        <Card
          primaryFooterAction={canSave ? {
            content: 'Save',
            loading: saving,
            disabled: !isValid || !hasMessagesFeature,
            onAction: () => {
              const variables = {
                eventId: event.id,
                name: name,
                filters: JSON.stringify(appliedFilters),
                search: searchValue,
                subject,
                message,
                ctaUrl,
                ctaLabel,
                sendUnique,
              };
              if (reminder) {
                variables.reminderId = reminder.id;
              }
              createAttendeeReminder({
                variables,
              });
            }
          } : undefined}
          secondaryFooterActions={secondaryActions}
        >
          <Card.Section>
            <TextField
              label='Name *'
              required
              value={name}
              placeholder={'Livestream reminder'}
              helpText='A name for this message template, this is only visible to use and to help you identify it.'
              onChange={(newValue) => setName(newValue)}
            />
          </Card.Section>
          <Card.Section title="Message">
            <FormLayout>
              <TextContainer>
                HTML and Liquid are supported on all fields, for example: {'{{ attendee.ticket_number }}'}
              </TextContainer>
              <TextField
                label='Subject *'
                required
                value={subject}
                placeholder={event.email.defaultReminderSubject}
                helpText='The subject of the email sent to attendees.'
                onChange={(v) => {
                  setSubject(v);
                  onChange('subject')(v)
                }}
                error={subjectError}
              />
              <div>
                <FormFieldLabel label='Message content' />
                <Editor
                  apiKey={`${TINY_MCE_API}`}
                  onInit={(evt, editor) => editorRef.current = editor}
                  initialValue={reminder ? reminder.message : ''}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | link | bullist numlist outdent indent | ' +
                    'removeformat | code',
                    content_style: 'body { font-size:14px; color:rgba(32, 34, 35, 1); font-weight:400 }'
                  }}
                  onEditorChange={(v) => {
                    setMessage(v);
                    onChange('message')(v)
                  }}
                  value={message}
                 />
                 {messageError &&
                   <InlineErrorContainer><InlineError message={messageError} fieldID="dfgd" /></InlineErrorContainer>
                 }
                 <div className="Polaris-Labelled__HelpText" id="PolarisTextField3HelpText">The main message text that you want to send to your attendees. Supports <Link url="https://evey-events.zendesk.com/hc/en-us/" external>liquid variables.</Link></div>
              </div>
              <TextField
                label='Call-to-action label'
                value={ctaLabel}
                placeholder='View livestream'
                helpText='The call-to-action (CTA) is a button near the bottom of the email that directs the attendee to some URL. For example you may want a button to bring them to the livestream page called "View livestream"'
                onChange={(v) => {
                  setCtaLabel(v);
                  onChange('ctaLabel')(v)
                }}
                error={ctaLabelError}
              />
              <TextField
                label='Call-to-action URL'
                value={ctaUrl}
                placeholder='https://'
                helpText='The URL for the call-to-action. Leave this blank to not display a CTA.'
                onChange={(v) => {
                  setCtaUrl(v);
                  onChange('ctaUrl')(v)
                }}
                error={ctaUrlError}
              />
            </FormLayout>
          </Card.Section>
          <Card.Section title="Audience">
            <Query
              query={GET_ATTENDEES}
              variables={{
                'eventId': event.id,
                'category': 'all',
                'filters': JSON.stringify(appliedFilters),
                'search': searchValue,
              }}
            >
              {({ loading, data }) => {
                let attendeeCount = 0;
                let attendeeTotalCount = 0;

                if (data && data.currentShop && data.currentShop.event) {
                  attendeeCount = data.currentShop.event.attendeesCount;
                  attendeeTotalCount = data.currentShop.event.totalAttendeeCount;
                }

                return (
                  <Stack vertical={true} spacing='loose'>
                    <AttendeeFilters
                      filters={{
                        search: searchValue,
                        appliedFilters: appliedFilters,
                      }}
                      event={event}
                      onUpdate={({ search, appliedFilters }) => {
                        setSearchValue(search);
                        setAppliedFilters(appliedFilters);
                      }}
                    />
                    <TextContainer>
                      {loading ? (
                        <div style={{textAlign:'center'}}>
                          <Spinner size="small" color="teal" />
                        </div>
                      ) : (
                        <div>{attendeeCount} of {attendeeTotalCount} attendees selected</div>
                      )}
                    </TextContainer>
                    <Checkbox
                      checked={sendUnique}
                      label='Send only one message to each unique email address'
                      onChange={setSendUnique}
                      helpText='If your message does not include individual ticket information then you can enable this to prevent customers who ordered multiple tickets from receving multiple emails'
                    />
                  </Stack>
                );
              }}
            </Query>
          </Card.Section>
          {canSave &&
            <Card.Section>
              <Banner status='info'>Saving this will <strong>not</strong> send the notification to your attendees yet. You can do this on the next page.</Banner>
            </Card.Section>
          }
          {!hasMessagesFeature &&
            <Card.Section>
              <UpgradeRequiredBanner message='Upgrade your plan to access the Attendee Messages feature.' />
            </Card.Section>
          }
          {canDelete && confirmDeleteModalOpen &&
            <DeleteReminderConfirmModal
              shop={shop}
              event={event}
              reminder={reminder}
              onComplete={() => {
                setConfirmDeleteModalOpen(false);
                history.push(`/events/${event.id}/reminders`);
                window.scrollTo({ top: 0 });
              }}
              onClose={() => setConfirmDeleteModalOpen(false)}
              flashCallback={flashCallback}
              history={history}
            />
          }
          {canSendTest && sendTestModalOpen &&
            <SendTestModal
              shop={shop}
              event={event}
              reminder={reminder}
              onComplete={() => setSendTestModalOpen(false)}
              onClose={() => setSendTestModalOpen(false)}
              flashCallback={flashCallback}
              history={history}
            />
          }
          {showScheduleModal &&
            <ScheduleMessageModal
              event={event}
              reminder={reminder}
              onClose={() => setShowScheduleModal(false)}
            />
          }
        </Card>
      )}
    </Mutation>
  );
};

export default ReminderEditCard;
