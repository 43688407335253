import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';

const DELETE_SEATS = gql`
  mutation deleteSeats($seatIds: String!, $seatingZoneId: ID) {
    deleteSeats(seatIds: $seatIds, seatingZoneId: $seatingZoneId) {
      success
    }
  }
`;

const DeleteSeatsModal = ({ seatIds, seatingZoneId, onClose, onCompleted }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={DELETE_SEATS}
      onError={() => showToastError('Failed to delete')}
      onCompleted={(data) => {
        showToastNotice('Seats deleted');
        onCompleted();
      }}
      refetchQueries={() => ['GetLocations', 'GetSeats']}
    >
      {(deleteSeats, { loading: deleting }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Delete seats'
          primaryAction={{
            content: 'Yes, delete them',
            loading: deleting,
            destructive: true,
            onAction: () => {
              deleteSeats({
                variables: {
                  seatIds: seatIds == 'all' ? 'all' : seatIds.join(','),
                  seatingZoneId,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: deleting,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextContainer>Are you sure you want to delete {seatIds == 'all' ? 'all' : seatIds.length} seats? This is irreversible.</TextContainer>
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default DeleteSeatsModal;
