import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { FormLayout, Layout, Card, TextField, Banner, Select, Link as ShopifyLink, Button, Collapsible, OptionList, Checkbox, Stack } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import EventPage from '../EventPage/EventPage.jsx';
import EventQuery from '../EventPage/EventQuery.jsx';
import ReactPhoneInput from 'react-phone-input-2';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import CancelTicketModal from './CancelTicketModal';
import DeleteTicketModal from './DeleteTicketModal';
import { SEND_TICKET, EDIT_ATTENDEE } from '../../utils/graphql';
import CommunicationsCard from './CommunicationsCard.jsx';
import VirtualEventSessionsCard from './VirtualEventSessionsCard.jsx';
import { buildDateWithCompensatedTimezone, formatDate } from '../../utils/time';
import ScheduleModal from './ScheduleModal';
import 'react-phone-input-2/lib/style.css';
import CheckinsCard from './CheckinsCard';
import SeatSelect from './SeatSelect';
import ZoomRegistrantCard from './ZoomRegistrantCard';
import RelatedTicketsCard from './RelatedTicketsCard';
import AttendeeAttributesCard from './AttendeeAttributesCard';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { getHostFromShopDomain } from '../../utils/auth';
import { useHistory, useLocation } from "react-router-dom";
import { reportError, hasErrors, formatErrors } from '../../utils/errors';

const EVENT_FIELDS = `
  attendeeAttributeSpecs {
    name
    label
    type
    options
    selectMultiple
  }
  tickets {
    id
    title
    variantId
    groupSize
    visible
    isDeleted
    variant {
      id
      title
    }
  }
  zoom {
    id
    active
    meetingId
  }
  attendee(eventId: $eventId, id: $attendeeId) {
    id
    number
    ticketCode
    firstName
    lastName
    name
    email
    phone
    createdAt
    purchasedAt
    manageUrl
    orderName
    remoteOrderId
    nametagUrl
    labelUrl
    scheduleUrl
    cancelledAt
    cancelReason
    refundedAt
    accessCode
    eventStartAt
    eventEndAt
    scheduledEvent
    scheduledEventTs
    scheduleItemId
    seat {
      id
      label
    }
    transferredTo {
      id
      number
    }
    transferredFrom {
      id
      number
    }
    checkinActions {
      id
      createdAt
      action
      user {
        email
      }
    }
    eticketSentAt
    deliverable
    eticketDeliveryMethod
    eticketDeliveryMethodLabel
    ticket {
      id
      inventory
      title
      variantId
      visible
      groupSize
      isDeleted
      variant {
        id
        title
      }
      eventDate
    }
    attendeeAttributes {
      value
      spec {
        name
        label
        type
        options
        selectMultiple
        ticket_types
      }
    }
    attendeeAttributeSpecs {
      name
      label
      type
      options
      ticket_types
    }
    communications {
      id
      createdAt
      content
      subject
      status
      to
      from
      communicationType
      service
      sentAt
      errorCode
      tags
    }
    virtualEventActiveSessions {
      id
      attendeeId
    }
    virtualEventUrl
    zoomRegistrant {
      id
      first_name
      last_name
      email
      status
    }
    zoomRegistrantAttendees {
      id
      ticketCode
      zoomRegistrant {
        id
      }
    }
    order {
      attendees(limit: 1000) {
        id
        ticketCode
        name
        email
        phonePretty
        ticketTitle
      }
    }
  }
`;

const AttendeeEdit = ({ match }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [ticketTypeId, setTicketTypeId] = React.useState(null);
  const [seatId, setSeatId] = React.useState(null);
  const [initialSeatId, setInitialSeatId] = React.useState(null);
  const [attendeeAttributes, setAttendeeAttributes] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [unknownError, setUnknownError] = React.useState(false);
  const [ticketCancelConfirm, setTicketCancelConfirm] = React.useState(false);
  const [ticketDeleteConfirm, setTicketDeleteConfirm] = React.useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = React.useState(false);

  const { showToastError, showToastNotice, canCreateAttendees, canEditAttendees, isExternal } = useFrame();
  const history = useHistory();
  const location = useLocation(); 
  const [searchParams, setSearchParams] = React.useState(null); 

  return (
    <EventQuery
      eventId={match.params.eventId}
      extraEventAttributes={EVENT_FIELDS}
      extraVariables={{'attendeeId': match.params.attendeeId}}
      queryDeclaration={'AttendeeQuery($eventId: ID!, $attendeeId: ID!)'}
      onCompleted={(data) => {
        const shop = data && data.currentShop;
        setSearchParams("host=" + getHostFromShopDomain(shop?.domain));
        const event = shop && shop.event;
        const attendee = event.attendee;

        setFirstName(attendee.firstName);
        setLastName(attendee.lastName);
        setEmail(attendee.email);
        setPhone(attendee.phone);
        setTicketTypeId(attendee.ticket.id.toString());
        setSeatId(attendee.seat && attendee.seat.id.toString());
        setInitialSeatId(attendee.seat && attendee.seat.id.toString());

        let _attendeeAttributes = {};
        for (var i in attendee.attendeeAttributes) {
          let spec = attendee.attendeeAttributes[i].spec;
          let value = attendee.attendeeAttributes[i].value;
          if(spec.selectMultiple) {
            value = JSON.parse(value);
          }
          _attendeeAttributes[spec.name] = {
            spec: spec,
            value: value
          };
        }
        setAttendeeAttributes(_attendeeAttributes);
      }}
    >
      {({ shop, event }) => {
        const attendee = event.attendee;

        const ticketOptions = [];
        for (var i in event.tickets) {
          let ticket = event.tickets[i];
          ticketOptions.push({
            label: ticket.title,
            value: ticket.id
          });
        }

        if(attendee.ticket.isDeleted) {
          ticketOptions.unshift({
            label: attendee.ticket.title + ' (DELETED)',
            value: attendee.ticket.id
          });
        }

        const selectedTicket = ticketTypeId && (event?.tickets || []).find((t) => t.id.toString() == ticketTypeId.toString());

        let actionGroups = undefined;

        let secondaryActions = [
          {
            content: isExternal ? 'Attendees' : 'Back to attendees',
            icon: isExternal ? ArrowLeftMinor : undefined,
            onAction: () => goToPage({ history, path: `/events/${event.id}/attendees` }),
            target: 'APP'
          },
          ...(canCreateAttendees ? [{
            content: 'Create new attendee',
            onAction: () => goToPage({ history, path: `/events/${event.id}/attendees/new` }),
            target: 'APP'
          }] : []),
        ];

        return (
          <Mutation mutation={EDIT_ATTENDEE}
            onError={(error) => {
              if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
                setErrors(error.graphQLErrors[0].errors);
                setUnknownError(false);
              } else {
                setUnknownError(true);
                setErrors([]);
                reportError(error);
              }
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            onCompleted={(data) => {
              if (data && data.editAttendee) {
                showToastNotice('Attendee successfully updated');

                const attendee = data.editAttendee.attendee;
                setInitialSeatId(attendee.seat && attendee.seat.id.toString());
              }
            }}
          >
            {(editAttendee, { loading }) => {
              return (
                <EventPage
                  shop={shop}
                  event={event}
                  title={attendee.name}
                  primaryAction={canEditAttendees ? {
                    content: 'Save',
                    primary: true,
                    loading:  loading,
                    onAction: () => {
                      document.getElementById('attendee-form').querySelector('[type="submit"]').click();
                    }
                  } : undefined}
                  secondaryActions={secondaryActions}
                  actionGroups={actionGroups}
                  extraBreadcrumbs={[
                    {
                      content: 'Attendees',
                      onAction: () => goToPage({ history, path: `/events/${event.id}/attendees` }),
                      target: 'APP'
                    }
                  ]}
                >
                  {attendee.cancelledAt && attendee.cancelledAt.length > 0 &&
                    <Banner
                      title="This ticket is cancelled"
                      status="critical"
                    >
                      <p>
                        This ticket was cancelled on {formatDate(attendee.cancelledAt)} with the reason: <span style={{fontStyle:'italic'}}>{attendee?.cancelReason || 'No reason given'}</span>
                      </p>
                    </Banner>
                  }
                  {attendee.refundedAt && !attendee.cancelledAt &&
                    <Banner
                      title="This order has been refunded"
                      status="warning"
                    >
                      <p>
                        {attendee.orderName ? <ShopifyLink url={'/orders?id=' + attendee.remoteOrderId + "&" + searchParams}>Order {attendee.orderName}</ShopifyLink> : 'This order'} was refunded but this ticket was not cancelled.
                      </p>
                    </Banner>
                  }
                  {unknownError &&
                  <Banner
                    title="There was a problem updating the attendee"
                    status="critical"
                  >
                    <p>
                      Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                    </p>
                  </Banner>
                  }
                  {hasErrors(errors) &&
                  <Banner
                    title="There was a problem updating the attendee"
                    status="critical"
                  >
                    {formatErrors(errors)}
                  </Banner>
                  }
                  <Layout>
                    <form id="attendee-form" onSubmit={e => {
                      e.preventDefault();

                      let selectedTicketId = null;
                      let selectedTicketTitle = null;
                      if (selectedTicket == null) {
                        // Ticket doesn't exist anymore, it was deleted.
                        selectedTicketId = attendee.ticket.id;
                        selectedTicketTitle = attendee.ticket.title;
                      } else {
                        selectedTicketId = selectedTicket.id;
                        selectedTicketTitle = selectedTicket.title;
                      }

                      editAttendee({
                        variables:{
                          eventId: event.id,
                          attendeeId: attendee.id,
                          firstName: firstName,
                          lastName: lastName,
                          email: email,
                          phone: !phone || phone.length < 6 ? '' : phone,
                          ticketTypeId: selectedTicketId,
                          ticketTypeTitle: selectedTicketTitle,
                          attendeeAttributes: JSON.stringify(attendeeAttributes),
                          seatId: seatId,
                        }
                      });
                    }}>
                      <input type="submit" style={{display:'none'}}/>
                      <Stack vertical spacing="loose">
                        <div>
                          <Layout.AnnotatedSection
                            title="Attendee Information"
                            description=""
                          >
                            <Card sectioned>
                              <p><span style={{fontWeight: 'bold'}}>Ticket Number: </span> #{attendee.number}</p>
                              {attendee.ticketCode != `#${attendee.number}` &&
                                <p><span style={{fontWeight: 'bold'}}>Ticket Code: </span> {attendee.ticketCode}</p>
                              }
                              <p><span style={{fontWeight: 'bold'}}>Created On: </span> {(new Date(attendee.createdAt)).toLocaleString()}</p>
                              <p><span style={{fontWeight: 'bold'}}>Purchased On: </span> {(new Date(attendee.purchasedAt)).toLocaleString()}</p>
                              <p style={{wordBreak: 'break-all'}}><span style={{fontWeight: 'bold'}}>Ticket Page: </span> <ShopifyLink external url={`${attendee.manageUrl}?${searchParams}`} target="_blank">{attendee.manageUrl}</ShopifyLink></p>
                              <p><span style={{fontWeight: 'bold'}}>Source: </span>
                                {attendee.orderName && <ShopifyLink url={'/orders?id=' + attendee.remoteOrderId + '&' + searchParams}>Order {attendee.orderName}</ShopifyLink>}
                                {!attendee.orderName && 'Manual'}
                              </p>
                              <p>
                                <span style={{fontWeight: 'bold'}}>Ticket Delivery: </span>
                                {attendee.eticketSentAt != null && attendee.eticketSentAt.length > 0 &&
                                  <span>Ticket delivered via {attendee.eticketDeliveryMethodLabel} on {formatDate(attendee.eticketSentAt)}</span>
                                }
                                {(attendee.eticketSentAt == null || attendee.eticketSentAt.length == 0) && attendee.deliverable &&
                                  <span>Ticket has not been delivered yet.</span>
                                }
                                {(attendee.eticketSentAt == null || attendee.eticketSentAt.length == 0) && !attendee.deliverable &&
                                  <span>Ticket has not been delivered yet due to missing email and phone.</span>
                                }
                              </p>
                              {attendee.refundedAt && attendee.refundedAt.length > 0 &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Refund: </span>
                                  <span>Order was refunded on {formatDate(attendee.refundedAt)}</span>
                                </p>
                              }
                              {event.dateType === 'recurring' &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Event date/time: </span>
                                  {attendee.scheduledEvent ? (
                                    <span>{formatDate(buildDateWithCompensatedTimezone(attendee.eventStartAt))}</span>
                                  ) : (
                                    <span>The customer did not schedule an event time.</span>
                                  )}
                                  <span style={{ marginLeft: '10px' }}>
                                    <Button
                                      plain
                                      monochrome
                                      onClick={() => setScheduleModalOpen(true)}
                                    >
                                      Update
                                    </Button>
                                  </span>
                                </p>
                              }
                              {attendee.ticket.eventDate &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Event date/time: </span>
                                  <span>{formatDate(buildDateWithCompensatedTimezone(attendee.ticket.eventDate))}</span>
                                </p>
                              }
                              {attendee.transferredFrom &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Transferred From: </span>
                                  <Link to={`/events/${event.id}/attendees/${attendee.transferredFrom.id}`}>#{attendee.transferredFrom.number}</Link>
                                </p>
                              }
                              {attendee.transferredTo &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Transferred To: </span>
                                  <Link to={`/events/${event.id}/attendees/${attendee.transferredTo.id}`}>#{attendee.transferredTo.number}</Link>
                                </p>
                              }
                              {event.locationType == 'online' &&
                                <p style={{wordBreak: 'break-all'}}>
                                  <span style={{fontWeight: 'bold'}}>Virtual event URL: </span>
                                  <ShopifyLink external url={`${attendee.virtualEventUrl}?${searchParams}`} target="_blank">{attendee.virtualEventUrl}</ShopifyLink>
                                </p>
                              }
                              {event.locationType == 'online' && event.virtualEventPage && event.virtualEventPage.enabled && event.virtualEventPage.requirePassword &&
                                <p>
                                  <span style={{fontWeight: 'bold'}}>Access code: </span>
                                  <ShopifyLink external url={`${event.virtualEventPageUrlBase}?${searchParams}`} target="_blank">{attendee.accessCode}</ShopifyLink>
                                </p>
                              }
                            </Card>

                            <div className="AttendeeEdit__Form">
                              <Card sectioned>
                                <FormLayout>
                                  <TextField
                                    value={firstName || ''}
                                    label="First Name"
                                    onChange={setFirstName}
                                    disabled={!canEditAttendees}
                                  />
                                  <TextField
                                    value={lastName || ''}
                                    label="Last Name"
                                    onChange={setLastName}
                                    disabled={!canEditAttendees}
                                  />
                                  <TextField
                                    value={email || ''}
                                    label="Email"
                                    onChange={setEmail}
                                    disabled={!canEditAttendees}
                                  />
                                  <div className="Polaris-Labelled__LabelWrapper">
                                    <div className="Polaris-Label">
                                      <label id="TextField2Label" className="Polaris-Label__Text">Phone</label>
                                    </div>
                                  </div>
                                  <div className="Polaris-TextField">
                                    <ReactPhoneInput
                                      country='us'
                                      disableAreaCodes={true}
                                      label=""
                                      value={phone || ''}
                                      onChange={setPhone}
                                      inputStyle={{width:'100%'}}
                                      dropdownClass='PhoneInputDropdown'
                                      disabled={!canEditAttendees}
                                    />
                                  </div>
                                  {ticketOptions.length > 1 &&
                                    <Select
                                      value={ticketTypeId}
                                      options={ticketOptions}
                                      label="Ticket Type"
                                      onChange={setTicketTypeId}
                                      disabled={!canEditAttendees}
                                    />
                                  }
                                  {ticketOptions.length == 1 &&
                                    <input type="hidden" value={ticketOptions[0].value} />
                                  }
                                  {event.hasSeating &&
                                    <SeatSelect
                                      shop={shop}
                                      event={event}
                                      value={seatId}
                                      onChange={setSeatId}
                                      ticketTypeId={ticketTypeId}
                                      initialValue={initialSeatId}
                                      scheduledEventTs={attendee.scheduledEventTs}
                                      disabled={!canEditAttendees}
                                    />
                                  }
                                </FormLayout>
                              </Card>
                            </div>
                          </Layout.AnnotatedSection>

                          {event.zoom && event.zoom.active &&
                            <Layout.AnnotatedSection
                              title='Zoom Meeting Registrant'
                              description="Zoom meeting information for this attendee"
                            >
                              <ZoomRegistrantCard
                                event={event}
                                attendee={attendee}
                                registrant={attendee.zoomRegistrant}
                              />
                            </Layout.AnnotatedSection>
                          }

                          <Layout.AnnotatedSection
                            title="Custom Information"
                            description="This is extra information that you've asked the customer about each attendee or for your own internal records. The attendee never sees this unless you customize their ticket or email to show it."
                          >
                            <AttendeeAttributesCard
                              event={event}
                              attendee={attendee}
                              selectedTicket={selectedTicket}
                              attendeeAttributes={attendeeAttributes || {}}
                              setAttendeeAttributes={setAttendeeAttributes}
                              disabled={!canEditAttendees}
                            />
                          </Layout.AnnotatedSection>

                          <Layout.AnnotatedSection
                            title={'Ticket and message deliveries'}
                            description="A list of all ticket and message deliveries, whether via SMS or email. Including details of receipt confirmations and failures. Tap the item to see a preview and more details."
                          >
                            <CommunicationsCard communications={attendee.communications} />
                          </Layout.AnnotatedSection>

                          {attendee.order?.attendees && attendee.order?.attendees?.length > 1 ? (
                            <Layout.AnnotatedSection
                              title='Related tickets'
                              description="List of tickets on the same order"
                            >
                              <RelatedTicketsCard
                                shop={shop}
                                event={event}
                                attendees={attendee.order.attendees.filter((a) => a.id != attendee.id)}
                              />
                            </Layout.AnnotatedSection>
                          ) : ''}

                          {attendee.checkinActions && attendee.checkinActions.length > 0 ? (
                            <Layout.AnnotatedSection
                              title='Check-In history'
                              description="List of past check-in activity for this ticket"
                            >
                              <CheckinsCard checkins={attendee.checkinActions} />
                            </Layout.AnnotatedSection>
                          ) : ''}

                          {event.locationType == 'online' && event.virtualEventPage.enabled && event.virtualEventPage.requirePassword && event.virtualEventPage.restrictSessions &&
                            <Layout.AnnotatedSection
                              title='Virtual event active sessions'
                              description="A list of active sessions for this user on the virtual event page"
                            >
                              <VirtualEventSessionsCard
                                shop={shop}
                                event={event}
                                sessions={attendee.virtualEventActiveSessions}
                                showAttendee={false}
                              />
                            </Layout.AnnotatedSection>
                          }
                        </div>

                        <Stack distribution='equalSpacing'>
                          <Stack distribution='leading' spacing='loose'>
                            {(!attendee.cancelledAt || attendee.cancelledAt.length == 0) &&
                              <Mutation
                                mutation={SEND_TICKET}
                                onError={() => showToastError('Unable to send ticket')}
                                onCompleted={() => showToastNotice('Ticket send in progress')}
                                refetchQueries={() => ['AttendeeQuery']}
                              >
                                {(sendTicket, { loading: sendingTicket }) => (
                                  <Button
                                    disabled={loading}
                                    loading={sendingTicket}
                                    onClick={() => {
                                      sendTicket({
                                        variables:{
                                          eventId: event.id,
                                          attendeeId: attendee.id,
                                        }
                                      });
                                    }}
                                  >
                                    {attendee.eticketSentAt != null && attendee.eticketSentAt.length > 0 ? 'Re-send ticket' : 'Send ticket'}
                                  </Button>
                                )}
                              </Mutation>
                            }
                            {canEditAttendees && (
                              (!attendee.cancelledAt || attendee.cancelledAt.length == 0) ? (
                                <Button
                                  destructive={true}
                                  disabled={loading}
                                  onClick={() => setTicketCancelConfirm(true)}
                                  connectedDisclosure={{
                                    accessibilityLabel: 'Other delete actions',
                                    actions: [
                                      {
                                        content: 'Delete ticket',
                                        destructive: true,
                                        onAction: () => setTicketDeleteConfirm(true),
                                      },
                                    ],
                                  }}
                                >
                                  Cancel ticket
                                </Button>
                              ) : (
                                <Button
                                  destructive={true}
                                  disabled={loading}
                                  onClick={() => setTicketDeleteConfirm(true)}
                                >
                                  Delete ticket
                                </Button>
                              )
                            )
                          }
                          </Stack>
                          {canEditAttendees &&
                            <Stack distribution='trailing' spacing='loose'>
                              <Button primary
                                loading={loading}
                                onClick={() => {
                                  document.getElementById('attendee-form').querySelector('[type="submit"]').click();
                                }}
                              >
                                Save
                              </Button>
                            </Stack>
                          }
                        </Stack>

                      </Stack>
                      {ticketCancelConfirm &&
                        <CancelTicketModal
                          event={event}
                          attendee={attendee}
                          onClose={() => setTicketCancelConfirm(false)}
                          onCompleted={() => {
                            setTicketCancelConfirm(false);
                            window.scrollTo({ top: 0 });
                          }}
                        />
                      }
                      {ticketDeleteConfirm &&
                        <DeleteTicketModal
                          event={event}
                          attendee={attendee}
                          onDismiss={() => setTicketDeleteConfirm(false)}
                          onDelete={() => goToPage({ history, path: `/events/${event.id}/attendees` })}
                        />
                      }
                      {scheduleModalOpen &&
                        <ScheduleModal
                          event={event}
                          attendee={attendee}
                          ticket={attendee.ticket}
                          onClose={() => setScheduleModalOpen(false)}
                        />
                      }
                    </form>
                  </Layout>
                </EventPage>
              );
            }}
          </Mutation>
        );
      }}
    </EventQuery>
  );
};

export default AttendeeEdit;
