import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Modal, Spinner, DataTable, Button } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const SpinnerContainer = styled.div`
  display:flex;
  height:100px;
  justify-content: center;
  align-items:center;
`;

const CANCEL_SEAT_RESERVATION = gql`
  mutation cancelCartReservation($eventId: ID!, $seatId: ID!) {
    cancelCartReservation(eventId: $eventId, seatId: $seatId) {
      success
    }
  }
`;

export const GET_SEAT_RESERVATIONS = gql`
  query GetSeatReservations($eventId: ID!, $seatId: ID!, $eventDate: String, $eventTime: String) {
    currentShop {
      event(id: $eventId) {
        seatReservations(seatId: $seatId, eventDate: $eventDate, eventTime: $eventTime) {
          id
          expiresAt
          userAgent
          ipAddress
        }
      }
    }
  }
`;

const CancelReservationButton = ({ event, seat, scheduledTime }) => {

  return (
    <Mutation
      mutation={CANCEL_SEAT_RESERVATION}
      onError={() => showToastError('Failed to create seating variant options')}
      onCompleted={(data) => {
        showToastNotice('Seating variant options created');
        onClose();
      }}
      refetchQueries={() => ['GetEventSeats', 'GetSeatReservations']}
    >
      {(cancelCartReservation, { loading: saving }) => (
        <Button
          destructive
          loading={saving}
          size='slim'
          onClick={() => {
            cancelCartReservation({
              variables: {
                eventId: event.id,
                seatId: seat.id,
                'eventDate': dayjs(scheduledTime).format('YYYY-MM-DD'),
                'eventTime': dayjs(scheduledTime).format('HH:mm'),
              },
            });
          }}
        >
          Remove
        </Button>
      )}
    </Mutation>
  );
};

const ReservationsModal = ({ event, seat, onClose }) => {
  const [refresh, setRefresh] = React.useState(0);

  const { showToastNotice, showToastError } = useFrame();

  const env = document.head.querySelector('[name=environment]').content;

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={`Cart reservations for ${seat.label}`}
      primaryAction={env !== 'production' ? {
        content: 'Refresh',
        onAction: () => setRefresh(refresh + 1),
      } : undefined}
      secondaryActions={[
        {
          content: 'Close',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Query
          query={GET_SEAT_RESERVATIONS}
          variables={{
            eventId: event.id,
            seatId: seat.id,
            refresh,
          }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <SpinnerContainer><Spinner size="large" color="teal" /></SpinnerContainer>;
            }

            const reservations = data && data.currentShop.event.seatReservations || [];

            return (
              <DataTable
                verticalAlign='middle'
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                ]}
                headings={[
                  'Expires',
                  'IP Address',
                  ''
                ]}
                rows={reservations.map((res) => [
                  // dayjs(res.expiresAt).format('LLL'),
                  dayjs(res.expiresAt).fromNow(),
                  res.ipAddress || '',
                  <CancelReservationButton event={event} seat={seat} />
                ])}
              />
            );
          }}
        </Query>
      </Modal.Section>
    </Modal>
  );
};

export default ReservationsModal;
