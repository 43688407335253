import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { Modal } from '@shopify/polaris';

class SubscribeEvent extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    confirmModalOpen: true,
    activating: false
  };

  SUBSCRIBE_EVENT = gql`
    mutation subscribeEvent($eventId: ID!) {
      subscribeEvent(eventId: $eventId) {
        event {
          id
        }
        confirmUrl
      }
    }
  `;

  render() {
    const event = this.props.event;

    return (
      <div className="SubscribeEvent__Main">
        <Mutation
          mutation={this.SUBSCRIBE_EVENT}
          onError={() => {
            if (this.props.onError) {
              this.props.onError();
            }
          }}
          onCompleted={(data) => {
            if (this.props.onConfirmRequest) {
              this.props.onConfirmRequest(data.subscribeEvent.confirmUrl);
            }
            this.setState({ confirmModalOpen: false });
          }}
        >
          {(subscribeEvent) => (
            <Modal
              title='Confirm event activation'
              open={this.state.confirmModalOpen}
              onClose={() => {
                this.setState({ confirmModalOpen: false });
                if (this.props.onDismiss) {
                  this.props.onDismiss();
                }
              }}
              primaryAction={{
                content: 'Continue',
                loading: this.state.activating,
                onAction: () => {
                  this.setState({ activating: true });
                  subscribeEvent({
                    variables:{
                      eventId: event.id
                    }
                  });
                }
              }}
              secondaryActions={[
                {
                  content: 'Go back',
                  disabled: this.state.activating,
                  onAction: () => {
                    this.setState({ confirmModalOpen: false });
                    if (this.props.onDismiss) {
                      this.props.onDismiss();
                    }
                  }
                }
              ]}
            >
              <Modal.Section>
                You will be asked by Shopify on the next page to confirm the total subscription charge for all your active events, which will include the charge for this event.
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      </div>
    );
  }
}

SubscribeEvent.propTypes = {
  event: PropTypes.object.isRequired,
  onDismiss: PropTypes.func,
  onConfirmRequest: PropTypes.func.isRequired,
  onError: PropTypes.func
};

export default SubscribeEvent;
