import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import PropTypes from 'prop-types';
import { Card, DataTable, FormLayout, TextField, PageActions } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';

export default class EventOrderList extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    orderId: ''
  }

  EVENT_FIELDS = `
    orders(eventId: $eventId) {
      id
      createdAt
      quantity
      order {
        id
        orderName
        name
        email
        orderUrl
        remoteId
      }
    }
    ordersCount(eventId: $eventId)
  `;

  renderPageContent = (shop, event) => {
    const eventOrders = event.orders;
    const eventOrdersCount = event.ordersCount;

    let rows = [];
    for (var i in eventOrders) {
      let eventOrder = eventOrders[i];
      let order = eventOrder.order;

      let orderLink = <Link to={`/orders?id=${order.remoteId}`}>{order.orderName}</Link>;
      let date = (new Date(eventOrder.createdAt)).toLocaleString();
      let quantityLink = <Link to={`/events/${event.id}/attendees?search=${order.orderName.replace('#', '%23')}`}>{eventOrder.quantity}</Link>;

      rows.push([orderLink, date, order.name, order.email, quantityLink]);
    }

    return (
      <div>
        <Card title="Order lookup" sectioned>
          <FormLayout>
            <div
              onKeyDown={(event) => {
                if (event.keyCode == 13) {
                  this.props.history.push(`/orders?id=${this.state.orderId.replace('#', '%23')}`);
                  window.scrollTo({ top: 0 });
                }
              }}
            >
              <TextField
                key='orderLookupId'
                value={this.state.orderId || ''}
                label='Unique order ID or name'
                placeholder='398590438789 or #12345'
                onChange={(value) => {
                  this.setState({ orderId: value });
                }}
                helpText='The unique ID or name for the order you want more infomation about. This will bring you to the detailed order lookup page where you can see if and how it was processed, what attendees were created, and more.'
              />
            </div>
          </FormLayout>
          <PageActions
            primaryAction={{
              content: 'Lookup Order',
              onClick: () => {
                this.props.history.push(`/orders?id=${this.state.orderId.replace('#', '%23')}`);
                window.scrollTo({ top: 0 });
              }
            }}
          />
        </Card>
        <Card title="Recent orders for this event" sectioned>
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'numeric'
            ]}
            headings={[
              'Order',
              'Date',
              'Name',
              'Email',
              'Quantity'
            ]}
            rows={rows}
            footerContent={`Showing most recent ${rows.length} of ${eventOrdersCount}`}
          />
        </Card>
      </div>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          return (
            <EventPage
              shop={shop}
              event={event}
              title="Ticket Orders"
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              {this.renderPageContent(shop, event)}
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

EventOrderList.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
