import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, FormLayout, Heading, TextContainer, Button } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';

const ADD_SEATS = gql`
  mutation addSeats($seatingZoneId: ID!, $labels: String!, $capacity: Int) {
    addSeats(seatingZoneId: $seatingZoneId, labels: $labels, capacity: $capacity) {
      seats {
        id
        label
      }
    }
  }
`;

const AddSeatsModal = ({ shop, seatingChart, seatingZone, onClose }) => {
  const [labels, setLabels] = React.useState('');
  const [capacity, setCapacity] = React.useState('1');
  const [showRangeOptions, setShowRangeOptions] = React.useState(false);
  const [rangeFrom, setRangeFrom] = React.useState(1);
  const [rangeTo, setRangeTo] = React.useState(100);
  const [buildingRange, setBuildingRange] = React.useState(false);
  const [rangePrefix, setRangePrefix] = React.useState('');
  const [rangeSuffix, setRangeSuffix] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = true;

  return (
    <Mutation
      mutation={ADD_SEATS}
      onError={() => showToastError('Fail to add seats')}
      onCompleted={() => {
        showToastNotice('Seats added');
        onClose();
      }}
      refetchQueries={() => ['GetSeats', 'GetSeatingChart']}
    >
      {(addSeats, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title={`Add seats to ${seatingZone.name}`}
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              addSeats({
                variables: {
                  seatingZoneId: seatingZone.id,
                  labels,
                  capacity: capacity && parseInt(capacity) || 1,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                value={labels}
                label="Seat labels (comma separated)"
                placeholder="A1, A2, A3, A4"
                onChange={setLabels}
                disabled={saving}
                multiline={1}
              />
              <TextField
                type="number"
                label="Capacity"
                value={capacity}
                min={1}
                placeholder="1"
                onChange={setCapacity}
                helpText='Maximum number of tickets that can be purchased for this seat. In most cases, this should be kept at a value of 1'
              />
              <Button
                plain
                monochrome
                onClick={() => setShowRangeOptions(!showRangeOptions)}
              >
                {!showRangeOptions ? 'Build from range' : 'Hide range options'}
              </Button>
              {showRangeOptions &&
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      value={rangeFrom.toString()}
                      min={0}
                      type="number"
                      label="From"
                      onChange={(v) => setRangeFrom(parseInt(v))}
                    />
                    <TextField
                      value={rangeTo.toString()}
                      min={0}
                      type="number"
                      label="To"
                      onChange={(v) => setRangeTo(parseInt(v))}
                    />
                  </FormLayout.Group>
                  <TextField
                    value={rangePrefix}
                    label="Prefix"
                    onChange={setRangePrefix}
                    helpText='Before seat number, for example "A" would be A1, A2, A3'
                  />
                  <TextField
                    value={rangeSuffix}
                    label="Suffix"
                    onChange={setRangeSuffix}
                    helpText='After seat number, for example "A" would be 1A, 2A, 3A'
                  />
                  <Button
                    disabled={saving}
                    loading={buildingRange}
                    onClick={() => {
                      setBuildingRange(true);
                      let result = [];
                      for (let i = rangeFrom; i <= rangeTo; i++) {
                        result.push(`${rangePrefix}${i}${rangeSuffix}`);
                      }
                      setLabels(result.join(','));
                      setTimeout(() => setBuildingRange(false), 1000);
                    }}
                  >
                    Build range
                  </Button>
                </FormLayout>
              }
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default AddSeatsModal;
