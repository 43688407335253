import React from 'react';
import { Mutation } from 'react-apollo';
import { Card } from '@shopify/polaris';
import { EDIT_ATTENDEE_ATTRIBUTE_SPECS } from '../../utils/graphql';
import AttendeeInfoSpecModal from './AttendeeInfoSpecModal';
import ReorderItemsModal from './ReorderItemsModal';
import { useFrame } from '../../contexts/frame';
import AttendeeInfoSpecsTable from '../AttendeeInfo/AttendeeInfoSpecsTable';

const AttendeeInfoCard = ({ event, template, specs }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [reorderModalActive, setReorderModalActive] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  let attendeeAttributeSpecs = specs || [];
  if (!attendeeAttributeSpecs.find((spec) => spec.name == 'email')) {
    attendeeAttributeSpecs.unshift({ name: 'email', label: 'Email', type: 'text', required: true });
  }
  if (!attendeeAttributeSpecs.find((spec) => spec.name == 'lastname')) {
    attendeeAttributeSpecs.unshift({ name: 'lastname', label: 'Last name', type: 'text', required: true });
  }
  if (!attendeeAttributeSpecs.find((spec) => spec.name == 'firstname')) {
    attendeeAttributeSpecs.unshift({ name: 'firstname', label: 'First name', type: 'text', required: true });
  }

  return (
    <Mutation mutation={EDIT_ATTENDEE_ATTRIBUTE_SPECS}
      onError={() => {
        showToastError('Unable to update attendee info fields');
        showAddModal(false);
      }}
      onCompleted={(data) => {
        showToastNotice('Attendee info fields updated');
        setShowAddModal(false);
      }}
      refetchQueries={() => ['AttendeeAttributeSpecsQuery', 'GetAttendeeInfoFieldsTemplates']}
    >
      {(editAttendeeAttributeSpecs, { loading: saving }) => (
        <>
          <Card sectioned
            actions={[
              {
                content: 'Add custom field',
                onAction: () => setShowAddModal(true),
              },
              ...(attendeeAttributeSpecs.length > 1 ? [{
               content: 'Change field order',
               onAction: () => setReorderModalActive(true),
             }] : []),
            ]}
          >
            <AttendeeInfoSpecsTable
              event={event}
              template={template}
              attendeeAttributeSpecs={attendeeAttributeSpecs}
            />
          </Card>
          {showAddModal &&
            <AttendeeInfoSpecModal
              specs={attendeeAttributeSpecs}
              saving={saving}
              ticketTypes={event?.tickets}
              onSave={(newSpec) => {
                let updatedSpecs = attendeeAttributeSpecs;
                updatedSpecs.push(newSpec);
                editAttendeeAttributeSpecs({
                  variables: {
                    eventId: event?.id,
                    templateId: template?.id,
                    specs: JSON.stringify(updatedSpecs),
                  }
                });
              }}
              onClose={() => setShowAddModal(false)}
            />
          }
          {reorderModalActive &&
            <ReorderItemsModal
              event={event}
              template={template}
              initialSpecs={attendeeAttributeSpecs}
              onClose={() => setReorderModalActive(false)}
            />
          }
        </>
      )}
    </Mutation>
  );
};

export default AttendeeInfoCard;
