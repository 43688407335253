import React from 'react';
import { Query, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Banner, TextContainer } from '@shopify/polaris';
import { GET_ACTIVE_SUBSCRIPTION } from '../../utils/graphql';
import { getHostFromShopDomain } from '../../utils/auth';

const UPDATE_APP_REVIEW = gql`
mutation updateAppReview($email: String!, $domain: String!, $value: String!) {
  updateAppReview(email: $email, domain: $domain, value: $value) {
    success
    shopifyDomain
    reviewStatus
  }
}
`;

const  ReviewAppBanner = () => {
  const [updateReview, {}] = useMutation(UPDATE_APP_REVIEW, {
    onCompleted(response) {
      if(response?.updateAppReview?.success) {
        if(response?.updateAppReview?.reviewStatus == "completed") window.open("https://apps.shopify.com/event-tickets?#modal-show=WriteReviewModal/?reveal_new_review=true&utm_source=in-app&utm_medium=reviewmodal&utm_campaign=leavereview")
        let shopHost = getHostFromShopDomain(response?.updateAppReview?.shopifyDomain);
        if (window.location.search.indexOf("host") == -1) { 
          window.location.href += (window.location.search.substring(0,1) == "?") ? "&host=" + shopHost : "?host=" + shopHost;
        } else {
          window.location.reload(false);
        }
      }
    }
  });
  
  return (
    <Query query={GET_ACTIVE_SUBSCRIPTION} >
      {({ loading, data }) => {
        if (
            loading || 
            !data || 
            data?.currentShop?.getEveyAppReview == "unavailable" ||
            data?.currentShop?.getEveyAppReview == "completed" ||
            data?.currentShop?.getEveyAppReview == "dismissed"
        ) return '';

        const shop = data.currentShop;

        return (
          <div className="ReviewAppBanner">
            <Banner
              title="Your opinion matters"
              status="info"
              action={{
                content: 'Leave Review',
                onClick: () =>  {
                  updateReview({ variables: { email: shop?.email, domain: shop?.domain, value: "completed" } });
                },
              }}
              secondaryAction={{
                content: 'Dismiss',
                onAction: () => {
                  updateReview({ variables: { email: shop?.email, domain: shop?.domain, value: "dismissed" } });
                },
              }}
            >
              <TextContainer>
                Share your experience and make an impact!
              </TextContainer>
            </Banner>
          </div>
        );
      }}
    </Query>
  );
};

export default ReviewAppBanner;
