import { storageGetItem, storageSetItem, storageRemoveItem } from './storage';
import { clearShop } from './auth';

const env = document.head.querySelector('[name=environment]').content;
const apiHost = document.head.querySelector('[name=api-host]').content;
const BASE_URL = (env === 'development' || env === 'staging')
  ? `${apiHost}/vendors/api` : 'https://app.eveyevents.com/vendors/api';

const fetchData = async (key) => {
  return storageGetItem(`EveyVendor:${key}`);
}

const storeData = async (key, value) => {
  return storageSetItem(`EveyVendor:${key}`, value);
}

const removeData = async (key) => {
  return storageRemoveItem(`EveyVendor:${key}`);
}


export class AuthenticationRequiredError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthenticationRequiredError";
  }
}

export class ApiResourceNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiResourceNotFoundError";
  }
}

export class ApiConflict extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiConflict";
  }
}

export class UnknownApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnknownApiError";
  }
}

export const logout = async () => {
  await removeData('userToken');
  await removeData('refreshToken');
  await removeData('user');
  clearShop();
};

export const fetchAccessToken = async () => await fetchData('userToken');

export const fetchBrand = async (shop) => {
  const response = await fetch(`${BASE_URL}/brand?shop=${shop || ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  const result = await response.json();
  return result;
};

export const forgotPasswordRequest = async (email, shop) => {
  const response = await fetch(`${BASE_URL}/sessions/forgot_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({ email, shop }),
  });
  const result = await response.json();
  return result;
};

export const resetPasswordRequest = async ({ token, password, confirmPassword }) => {
  const response = await fetch(`${BASE_URL}/sessions/reset_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({ token, password, password_confirm: confirmPassword }),
  });
  const result = await response.json();
  return result;
};

export const loginRequest = async ({ email, password }) => {
  const response = await fetch(`${BASE_URL}/sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  if (response.status === 401) {
    throw new AuthenticationRequiredError('Failed to login with email and password');
  } else if (response.status === 404) {
    throw new ApiResourceNotFoundError('Not found');
  } else if (response.status === 409) {
    throw new ApiConflict('Conflict');
  } else if (response.status === 200) {
    const newToken = response.headers.get('x-evey-vendor-access-token');
    const newRefreshToken = response.headers.get('x-evey-vendor-refresh-token');
    if (newToken) {
      await storeData('userToken', newToken);
      await storeData('refreshToken', newRefreshToken);
      const user = await response.json();
      await storeData('user', email);
      return {
        ...user,
        access_token: newToken,
      };
    }
  } else {
    throw new UnknownApiError('Unknown error when tring to login');
  }
};

export const refreshAccessToken = async () => {
  console.log(
    `[refreshAccessToken] Login required for vendor access, attempting refresh`
  );
  const refreshToken = await fetchData('refreshToken');
  const email = await fetchData('user');
  if (refreshToken) {
    const refreshResponse = await fetch(`${BASE_URL}/sessions/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        email,
        refresh_token: refreshToken,
      }),
    });

    if (refreshResponse.status === 200) {
      const newToken = refreshResponse.headers.get('x-evey-vendor-access-token');
      const newRefreshToken = refreshResponse.headers.get('x-evey-vendor-refresh-token');
      await storeData('userToken', newToken);
      await storeData('refreshToken', newRefreshToken);
      console.log(`Refresh for ${email} successful: ${newToken}`);
      return newToken;
    }
  }

  throw new AuthenticationRequiredError('Request failed due to missing authentication');
};

export const request = async ({ path, params, data, method, attempt }) => {
  const accessToken = await fetchData('userToken');
  console.log(`[api] access token: `, accessToken);
  if (!accessToken) {
    throw new AuthenticationRequiredError('Request failed due to missing access token');
  }

  const url = new URL(`${BASE_URL}${path}`);
  url.search = new URLSearchParams(params || {}).toString();
  console.log(`url: ${url}`);
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Evey-Vendor-Token': accessToken,
    },
    ...(data && {
      body: JSON.stringify(data),
    }),
  });

  if (response.status == 401 || response.status == 403) {
    console.log(
      `Login required for vendor access, attempting refresh: ${path}`
    );
    await refreshAccessToken();
    if (attempt && attempt > 5) {
      throw new AuthenticationRequiredError('Request failed due to missing authentication');
    }
    return request({ path, method, data, attempt: (attempt || 1) + 1 });
  } else if (response.status === 404) {
    throw new ApiResourceNotFoundError('Not found');
  } else if (response.status === 409) {
    throw new ApiConflict('Conflict');
  } else {
    try {
      return await response.json();
    } catch (error) {
      console.log(`Unable to parse response: ${error}`);
      // TODO: to errbit
      throw new UnknownApiError('Error while communicating with Evey');
    }
  }
};

export const vendorGet = async ({ path, params }) => {
  return await request({ path, params, method: 'GET' });
};

export const vendorPost = async ({ path, data }) => {
  return await request({ path, data, method: 'POST' });
};

export const vendorPut = async ({ path, data }) => {
  return await request({ path, data, method: 'PUT' });
};
