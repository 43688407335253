import React from 'react';
import { Query } from 'react-apollo';
import { Card, Select, Stack, DataTable, IndexTable, EmptyState, Spinner, Button, Pagination, Subheading, Filters, TextStyle, useIndexResourceState } from '@shopify/polaris';
import AddSeatsModal from './AddSeatsModal';
import EditSeatModal from './EditSeatModal';
import { GET_SEATS } from '../../../utils/graphql';
import styled from '@emotion/styled';
import DeleteSeatsModal from './DeleteSeatsModal';

const PaginationContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
`;

const PER_PAGE = 10;

const SeatsTable = ({ shop, displaySeats, seatsCount, loading, currentPage, selectedZone, searchValue, emptyStateAction }) => {
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(displaySeats);
  const [showEditSeatModal, setShowEditSeatModal] = React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);

  React.useEffect(() => {
    handleSelectionChange('page', false, undefined);
  }, [currentPage, selectedZone, searchValue]);

  return (
    <>
      <IndexTable
        resourceName={{ singular: 'seat', plural: 'seats' }}
        itemCount={seatsCount}
        loading={loading}
        hasMoreItems={seatsCount > displaySeats.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={(selectionType, toggleType, selection) => {
          if (selectionType == 'page' && toggleType && selectedResources.length == PER_PAGE) {
            handleSelectionChange('page', false, undefined);
          } else {
            handleSelectionChange(selectionType, toggleType, selection);
          }
        }}
        headings={[
          { title: 'Seat Label' },
          { title: 'Seat Capacity' },
          { title: '' },
        ]}
        emptyState={(
          <EmptyState
            action={{
              content: 'Add seats',
              onAction: () => emptyStateAction(),
            }}
          >
            <p>
              Add seats to this seating zone to allow customers to choose one when they purcase tickets.
            </p>
          </EmptyState>
        )}
        promotedBulkActions={[
          {
            content: 'Delete seats',
            onAction: () => setShowBulkDeleteModal(true),
          }
        ]}
      >
        {displaySeats.map((seat, index) => (
          <IndexTable.Row
            id={seat.id}
            key={seat.id}
            selected={selectedResources.includes(seat.id)}
            position={index}
          >
            <IndexTable.Cell>
              <TextStyle variation="strong">{seat.label}</TextStyle>
            </IndexTable.Cell>
            <IndexTable.Cell>{seat.capacity}</IndexTable.Cell>
            <IndexTable.Cell>{(
              <Stack vertical={false} spacing='tight'>
                <Button
                  size='slim'
                  onClick={() => {
                    setShowEditSeatModal(seat);
                    if (selectedResources.includes(seat.id)) {
                      setTimeout(() => handleSelectionChange('single', true, seat.id), 100);
                    } else {
                      setTimeout(() => handleSelectionChange('single', false, seat.id), 100);
                    }
                  }}
                >
                  Edit
                </Button>
              </Stack>
            )}</IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
      {showEditSeatModal &&
        <EditSeatModal
          seat={showEditSeatModal}
          onClose={() => setShowEditSeatModal(false)}
        />
      }
      {showBulkDeleteModal &&
        <DeleteSeatsModal
          seatIds={allResourcesSelected ? 'all' : selectedResources}
          seatingZoneId={selectedZone}
          onClose={() => setShowBulkDeleteModal(false)}
          onCompleted={() => {
            setShowBulkDeleteModal(false);
            handleSelectionChange('page', false, undefined);
          }}
        />
      }
    </>
  );
};

const SeatsCard = ({ shop, seatingChart }) => {
  const seatingZones = [...seatingChart.seatingZones];

  const [selectedZone, setSelectedZone] = React.useState(seatingZones[0].id);
  const [showAddSeatModal, setShowAddSeatModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');

  const zone = seatingZones.find((z) => z.id == selectedZone);

  return (
    <Card
      sectioned
      actions={[
        {
          content: `Add seats to ${zone.name}`,
          onAction: () => setShowAddSeatModal(true),
        },
      ]}
    >
      <Stack vertical={true} spacing='loose'>
        <Select
          options={seatingZones.map((zone) => ({ value: zone.id, label: zone.name }))}
          value={selectedZone}
          onChange={(v) => {
            setSelectedZone(v);
            setSearchValue('');
            setCurrentPage(1);
          }}
          label='Seating zone'
        />
        <Query
          query={GET_SEATS}
          variables={{
            seatingChartId: seatingChart.id,
            seatingZoneId: selectedZone,
            // page: currentPage,
            // perPage: PER_PAGE,
            // search: searchValue,
          }}
        >
          {({ loading, data }) => {
            const seats = data && data.currentShop.seatingChart.seatingZone.allSeats || [];
            const seatsCount = seats ? seats.length : 0;
            // const seatsCount = data && data.currentShop.seatingChart.seatingZone.seatsCount || 0;

            const start = (currentPage - 1) * PER_PAGE;
            const displaySeats = seats
              .filter((seat) => !searchValue || seat.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
              .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))
              .slice(start, start + PER_PAGE);

            return (
              <Stack vertical={true}>
                <Subheading>Seats</Subheading>
                {seatsCount > 0 &&
                  <Filters
                    queryValue={searchValue}
                    queryPlaceholder='Seat label'
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={(v) => {
                      setSearchValue(v)
                      setCurrentPage(1);
                    }}
                    onQueryClear={() => {
                      setSearchValue('');
                      setCurrentPage(1);
                    }}
                    onClearAll={() => {
                      setSearchValue('');
                      setCurrentPage(1);
                    }}
                  />
                }
                <SeatsTable
                  shop={shop}
                  displaySeats={displaySeats}
                  seatsCount={seatsCount}
                  loading={loading}
                  selectedZone={selectedZone}
                  searchValue={searchValue}
                  currentPage={currentPage}
                  emptyStateAction={() => setShowAddSeatModal(true)}
                />
                {displaySeats.length > 0 &&
                  <div className="Polaris-DataTable__Footer">{`Showing ${start+1}-${start+displaySeats.length} of ${seatsCount} results`}</div>
                }
                {displaySeats.length > 0 &&
                  <PaginationContainer>
                    <Pagination
                      hasPrevious={currentPage > 1}
                      hasNext={currentPage < (Math.ceil(seatsCount / PER_PAGE))}
                      onPrevious={() => {
                        setCurrentPage(currentPage - 1);
                      }}
                      onNext={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                    />
                  </PaginationContainer>
                }
              </Stack>
            )
          }}
        </Query>
      </Stack>
      {showAddSeatModal &&
        <AddSeatsModal
          shop={shop}
          seatingChart={seatingChart}
          seatingZone={seatingZones.find((zone) => zone.id == selectedZone)}
          onClose={() => setShowAddSeatModal(false)}
        />
      }
    </Card>
  );
};

export default SeatsCard;
