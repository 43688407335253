import React from 'react';
import { Autocomplete, Modal, TextField, FormLayout } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { CirclePlusMinor } from '@shopify/polaris-icons';
import { useFrame } from '../../../contexts/frame';

const SAVE_SEATING_ZONE = gql`
  mutation saveSeatingZone($seatingChartId: ID!, $seatingZoneId: ID, $name: String!) {
    saveSeatingZone(seatingChartId: $seatingChartId, seatingZoneId: $seatingZoneId, name: $name) {
      seatingZone {
        id
        name
      }
    }
  }
`;

const SeatingZoneModal = ({ seatingChart, seatingZone, onClose, onCompleted }) => {
  const [name, setName] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = name && name.length > 0;

  return (
    <Mutation
      mutation={SAVE_SEATING_ZONE}
      onError={() => showToastError(seatingZone ? 'Failed to update zone' : 'Failed to add seating zone')}
      onCompleted={(data) => {
        showToastNotice(seatingZone ? 'Seating zone updated' : 'Seating zone created');
        onCompleted(data.saveSeatingZone.seatingZone);
      }}
      refetchQueries={() => ['GetSeatingChart']}
      awaitRefetchQueries={true}
    >
      {(saveSeatingZone, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title={seatingZone ? 'Edit seating edit' : 'Add seating zone'}
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              saveSeatingZone({
                variables: {
                  seatingChartId: seatingChart.id,
                  seatingZoneId: seatingZone && seatingZone.id,
                  name,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label="Name"
                value={name}
                placeholder="Section A"
                onChange={setName}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

const SeatingZoneSelect = ({ seatingChart, seatingZones, selected, onChange }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [showAddZoneModal, setShowAddZoneModal] = React.useState(false);
  const [showEditZoneModal, setShowEditZoneModal] = React.useState(false);

  return (
    <>
      <Autocomplete
        allowMultiple={false}
        actionBefore={{
          accessibilityLabel: 'Add seating zone',
          content: 'Add seating zone',
          ellipsis: true,
          helpText: 'Add a new seating zone before creating seats for that zone',
          icon: CirclePlusMinor,
          onAction: () => setShowAddZoneModal(true),
        }}
        options={seatingZones.filter((z) => z.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1).map((z) => ({ label: z.name, value: z.id }))}
        selected={[selected]}
        textField=<Autocomplete.TextField
          onChange={setInputValue}
          value={inputValue}
          label="Seating zones"
        />
        onSelect={(v) => {
          onChange(v[0]);
          setInputValue(seatingZones.find((z) => z.id == v[0]).name);
        }}
        listTitle="Seating zone"
      />
      {showAddZoneModal &&
        <SeatingZoneModal
          seatingChart={seatingChart}
          onClose={() => setShowAddZoneModal(false)}
          onCompleted={(newZone) => {
            setShowEditZoneModal(false);
            onChange(newZone.id);
            setInputValue(newZone.name);
          }}
        />
      }
      {showEditZoneModal &&
        <SeatingZoneModal
          seatingChart={seatingChart}
          seatingZone={showEditZoneModal}
          onClose={() => setShowEditZoneModal(false)}
        />
      }
    </>
  );
};

export default SeatingZoneSelect;
