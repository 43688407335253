import React from 'react';
import AppPage from '../AppPage/AppPage.jsx';
import SettingsCard from './SettingsCard';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';

const Settings = () => {
  const history = useHistory();
  const { isVendor } = useFrame();

  React.useEffect(() => {
    if (isVendor) {
      goToPage({ history, path: '/events' });
    }
  }, []);

  return (
    <AppPage
      title="Settings"
      pageTitle="Settings"
      pageSubtitle="These are shop-wide settings and apply to all events."
      secondaryActions={[
        {
          content: 'Back to events',
          target: 'APP',
          onAction: () => goToPage({ history, path: '/events' }),
        }
      ]}
      primaryAction={null}
    >
      <SettingsCard />
    </AppPage>
  );
}

export default Settings;
