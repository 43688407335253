import React from 'react';
import { Card, ResourceList, EmptyState } from '@shopify/polaris';
import AttendeeFieldsResourceItem from './AttendeeFieldsResourceItem';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../../utils/routing';

const AttendeeFieldsCard = ({ shop, templates }) => {
  const history = useHistory();

  return (
    <Card
      sectioned
      actions={[
        {
          content: 'Add template',
          onAction: () => goToPage({ history, path: '/settings/attendee_info/new' }),
        },
      ]}
    >
      <ResourceList
        emptyState={!templates.length ? (
          <EmptyState
            heading="No attendee information fields templates yet"
          >
            <p>
              All attendee information fields templates will appear here.
            </p>
          </EmptyState>
        ) : undefined}
        items={templates}
        renderItem={(template) => (
          <AttendeeFieldsResourceItem
            template={template}
            onSelect={(t) => goToPage({ history, path: `/settings/attendee_info/${template.id}` })}
          />
        )}
        resourceName={{singular: 'template', plural: 'templates'}}
      />
    </Card>
  );
};

export default AttendeeFieldsCard;
