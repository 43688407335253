import React from 'react';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import { SAVE_SCHEDULE_ITEM, DELETE_SCHEDULE_ITEM } from '../../utils/graphql';
import { Popover, TextField, FormLayout, Select, Checkbox, Banner, Button, Stack, OptionList, InlineError } from '@shopify/polaris';
import { normalizeToUtc, getOrdinalNum } from '../../utils/time';
import randomstring from 'randomstring';
import FormFieldLabel from '../shared/FormFieldLabel';
import styled from '@emotion/styled';
import { useFrame } from '../../contexts/frame';
import { parseBoolean } from '../../utils/parse';
import { NestedFormLayoutContainer } from '../shared/containers';

const PeriodFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const PeriodNumberContainer = styled.div`
  width: 100px;
  margin-right: 30px;
`;

const PeriodRepeatContainer = styled.div`
  margin-right: 30px;
`;

const EndDateGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  align-items: center;
`;
const EndDateSelect = styled.div`
  margin-right: 30px;
`;

const ScheduleItemForm = ({ eveyEvent, item, event, onSave, onCancel }) => {
  const itemStartDateTime = moment(item.startDate).set({ hour: parseInt(item.startTime.split(':')[0]), minute: parseInt(item.startTime.split(':')[1]), second: 0, millisecond: 0 });

  const initialStartDate = moment(item.startDate).format('YYYY-MM-DD');
  const initialEndDate = itemStartDateTime.clone().add(parseInt(item.duration), 'second').format('YYYY-MM-DD');

  const [name, setName] = React.useState(item.name || '');
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const [repeatEndDate, setRepeatEndDate] = React.useState(item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : null);
  const [allDay, setAllDay] = React.useState(item.allDay)
  const [startTime, setStartTime] = React.useState(item.startTime);
  const [endTime, setEndTime] = React.useState(item.duration ? itemStartDateTime.clone().add(parseInt(item.duration), 'second').format('HH:mm') : '');
  const [period, setPeriod] = React.useState(item.period);
  const [periodNumber, setPeriodNumber] = React.useState(item.periodNumber);
  const [allTicketTypes, setAllTicketTypes] = React.useState(item.ticketTypes == null ? true : false);
  const [selectedTicketTypes, setSelectedTicketTypes] = React.useState(item.ticketTypes || []);
  const [ticketTypesPopoverActive, setTicketTypesPopoverActive] = React.useState(false);
  const [multiDay, setMultiDay] = React.useState(initialStartDate != initialEndDate);

  const { showToastNotice, showToastError } = useFrame();

  const titleDate = moment(event ? event.start : itemStartDateTime).format('MMMM D, YYYY');
  const newItem = !item.id;

  const currentDate = moment(event ? event.start : itemStartDateTime);
  const weekOfMonth = Math.ceil(currentDate.date() / 7);
  const dayOfMonth = currentDate.date();
  const isLastWeekOfMonth = currentDate.clone().add(1, 'week').month() != currentDate.month();

  const startTimeIsValid = /^[0-9]{2}:[0-9]{2}$/.test(startTime);
  const endTimeIsValid = /^[0-9]{2}:[0-9]{2}$/.test(endTime);

  const startTimeIsBeforeEndTime = () => {
    const start = moment(startDate).set({ hour: parseInt(startTime.split(':')[0]), minute: parseInt(startTime.split(':')[1]), second: 0, millisecond: 0 });
    const end = moment(endDate).set({ hour: parseInt(endTime.split(':')[0]), minute: parseInt(endTime.split(':')[1]), second: 0, millisecond: 0 });
    return startTimeIsValid && endTimeIsValid && start < end;
  }

  const isValid = startTimeIsValid && endTimeIsValid && startTimeIsBeforeEndTime();

  const tickets = eveyEvent.tickets;
  const selectedTicketTypesTitles = selectedTicketTypes
    .filter((ticketTypeId) => tickets.find((t) => t.id == ticketTypeId))
    .map((ticketTypeId) => tickets.find((t) => t.id == ticketTypeId).title);
  const ticketTypesLabel = selectedTicketTypesTitles ? `${selectedTicketTypesTitles.length} ticket type${selectedTicketTypesTitles.length == 1 ? '' : 's'} selected` : 'Select ticket types';

  const onChangeStartTime = (value) => {
    const start = moment(startDate).set({ hour: parseInt(value.split(':')[0]), minute: parseInt(value.split(':')[1]), second: 0, millisecond: 0 });
    const end = moment(endDate).set({ hour: parseInt(endTime.split(':')[0]), minute: parseInt(endTime.split(':')[1]), second: 0, millisecond: 0 });
    setStartTime(value);
    const isValueValid = /^[0-9]{2}:[0-9]{2}$/.test(value);
    // if (isValueValid && endTimeIsValid && start >= end) {
      // setEndTime(start.clone().add(1, 'hour').format('HH:mm'));
    // }
  }

  const onChangeEndTime = (value) => {
    const start = moment(startDate).set({ hour: parseInt(startTime.split(':')[0]), minute: parseInt(startTime.split(':')[1]), second: 0, millisecond: 0 });
    const end = moment(endDate).set({ hour: parseInt(value.split(':')[0]), minute: parseInt(value.split(':')[1]), second: 0, millisecond: 0 });
    setEndTime(value);
    const isValueValid = /^[0-9]{2}:[0-9]{2}$/.test(value);
    // if (startTimeIsValid && isValueValid && start >= end) {
    //   setStartTime(end.clone().subtract(1, 'hour').format('HH:mm'));
    // }
  }

  return (
    <Mutation mutation={SAVE_SCHEDULE_ITEM}
      onError={(error) => showToastError('Unable to save schedule')}
      onCompleted={(data) => {
        showToastNotice('Schedule saved')
        onSave();
      }}
      refetchQueries={() => ['EventQuery']}
      awaitRefetchQueries={true}
    >
      {(saveScheduleItem, { loading: saving }) => (
        <Mutation mutation={DELETE_SCHEDULE_ITEM}
          onError={(error) => showToastError('Unable to update schedule')}
          onCompleted={(data) => {
            showToastNotice('Schedule saved')
            onSave();
          }}
          refetchQueries={() => ['EventQuery']}
          awaitRefetchQueries={true}
        >
          {(deleteScheduleItem, { loading: deleting }) => (
            <FormLayout>
              {!newItem && item.period &&
                <Banner status='info'>Changes will apply to all instances of this schedule</Banner>
              }
              <FormFieldLabel label='Event recurring frequency' />
              <PeriodFieldsContainer>
                <PeriodRepeatContainer>
                  <Select
                    options={[
                      { label: "Don't repeat", value: 'no-repeat' },
                      { label: 'Repeat every', value: 'repeat' },
                    ]}
                    value={period && period.length ? 'repeat' : 'no-repeat'}
                    onChange={(v) => v == 'repeat' ? setPeriod('daily') : setPeriod('')}
                  />
                </PeriodRepeatContainer>
                {period && period.length ? (
                  <>
                    <PeriodNumberContainer>
                      <TextField value={(periodNumber || '1').toString()} type="number" min={1} onChange={(v) => setPeriodNumber(v)} />
                    </PeriodNumberContainer>
                    <Select
                      options={[
                        { label: parseInt(periodNumber) > 1 ? 'days' : 'day', value: 'daily' },
                        { label: parseInt(periodNumber) > 1 ? 'weeks' : 'week', value: 'weekly' },
                        { label: parseInt(periodNumber) > 1 ? `months on the ${dayOfMonth}${getOrdinalNum(dayOfMonth)}` : `month on the ${dayOfMonth}${getOrdinalNum(dayOfMonth)}`, value: 'monthly' },
                        ...(weekOfMonth <= 4 ? [{ label: parseInt(periodNumber) > 1 ? `months on the ${weekOfMonth}${getOrdinalNum(weekOfMonth)} ${currentDate.format('dddd')}` : `month on the ${weekOfMonth}${getOrdinalNum(weekOfMonth)}  ${currentDate.format('dddd')}`, value: 'monthly-weekday-first' }] : []),
                        ...(isLastWeekOfMonth ? [{ label: parseInt(periodNumber) > 1 ? `months on the last ${currentDate.format('dddd')}` : `month on the last ${currentDate.format('dddd')}`, value: 'monthly-weekday-last' }] : []),
                      ]}
                      value={period}
                      onChange={(v) => setPeriod(v)}
                    />
                  </>
                ) : null}
              </PeriodFieldsContainer>
              <FormLayout.Group>
                <TextField
                  value={startDate}
                  type="date"
                  label="Start date"
                  onChange={(v) => setStartDate(v)}
                  placeholder="YYYY-MM-DD"
                />
                {!allDay &&
                  <TextField
                    value={startTime}
                    type="time"
                    label="Start time"
                    onChange={onChangeStartTime}
                    placeholder="HH:MM (24-hour)"
                    error={startTimeIsValid ? undefined : 'HH:MM (24-hour)'}
                  />
                }
              </FormLayout.Group>
              <Checkbox
                checked={allDay}
                label="All day"
                onChange={setAllDay}
              />
              {!allDay &&
                <FormLayout.Group>
                  <TextField
                    value={endDate}
                    type="date"
                    label="End date"
                    onChange={(v) => setEndDate(v)}
                    placeholder="YYYY-MM-DD"
                  />
                  {!allDay &&
                    <TextField
                      value={endTime}
                      type="time"
                      label="End time"
                      onChange={onChangeEndTime}
                      placeholder="HH:MM (24-hour)"
                      error={endTimeIsValid ? (startTimeIsBeforeEndTime() ? undefined : 'Start time must be before end time') : 'HH:MM (24-hour)'}
                    />
                  }
                </FormLayout.Group>
              }
              {period &&
                <>
                  <FormFieldLabel label='Repeats until' />
                  <EndDateGroup>
                    <EndDateSelect>
                      <Select
                        options={[
                          { label: "Forever", value: 'never' },
                          { label: 'Date', value: 'date' },
                        ]}
                        value={!!repeatEndDate ? 'date' : 'never'}
                        onChange={(v) => v == 'date' ? setRepeatEndDate(moment(startDate).add(1, 'day').format('YYYY-MM-DD')) : setRepeatEndDate('')}
                      />
                    </EndDateSelect>
                    {repeatEndDate &&
                      <TextField value={repeatEndDate} type="date" label="" onChange={(v) => setRepeatEndDate(v)} placeholder="YYYY-MM-DD" />
                    }
                  </EndDateGroup>
                </>
              }
              <Checkbox
                value={true}
                checked={allTicketTypes}
                label='All ticket types can be purchased for these events'
                onChange={setAllTicketTypes}
              />
              {!allTicketTypes &&
                <NestedFormLayoutContainer>
                  <FormLayout>
                    <Popover
                      active={ticketTypesPopoverActive}
                      activator={<Button onClick={() => setTicketTypesPopoverActive(!ticketTypesPopoverActive)}>{ticketTypesLabel}</Button>}
                      onClose={() => setTicketTypesPopoverActive(false)}
                    >
                      <OptionList
                        title='Ticket types'
                        onChange={(v) => setSelectedTicketTypes(v)}
                        options={tickets.map((t) => ({ label: t.title, value: t.id.toString() }))}
                        selected={selectedTicketTypes}
                        allowMultiple
                      />
                    </Popover>
                    {(!selectedTicketTypes || selectedTicketTypes.length == 0) &&
                      <InlineError message='No ticket types selected' />
                    }
                  </FormLayout>
                </NestedFormLayoutContainer>
              }
              <TextField value={name} label="Name (optional)" onChange={(v) => setName(v)} helpText='Internal use only to help you organize your calendar.' />
              <Stack vertical={false} distribution='fill'>
                <Stack vertical={false} distribution='leading'>
                  {event &&
                    <Button
                      destructive
                      loading={deleting}
                      onClick={() => {
                        deleteScheduleItem({
                          variables: {
                            eventId: eveyEvent.id,
                            itemId: item.id,
                          },
                        });
                      }}
                    >
                      Remove all
                    </Button>
                  }
                </Stack>
                <Stack vertical={false} distribution='trailing'>
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button
                    primary
                    loading={saving}
                    disabled={deleting || !isValid}
                    onClick={() => {
                      const duration =
                        moment(endDate).set({ hour: parseInt(endTime.split(':')[0]), minute: parseInt(endTime.split(':')[1]), second: 0, millisecond: 0 }).unix()
                          -
                        moment(startDate).set({ hour: parseInt(startTime.split(':')[0]), minute: parseInt(startTime.split(':')[1]), second: 0, millisecond: 0 }).unix();
                      // onSave({ name, startDate, endDate, startTime, duration, period, periodNumber, id: item.id, resourceId: item.resourceId });
                      saveScheduleItem({
                        variables: {
                          eventId: eveyEvent.id,
                          itemId: item.id,
                          name,
                          startDate,
                          endDate: repeatEndDate,
                          allDay: parseBoolean(allDay),
                          startTime,
                          duration: parseBoolean(allDay) ? 3600 : duration,
                          period,
                          periodNumber: parseInt(periodNumber),
                          ticketTypes: !allTicketTypes && selectedTicketTypes ? JSON.stringify(selectedTicketTypes) : null,
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </FormLayout>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default ScheduleItemForm;
