import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ErrorPage from '../Error/ErrorPage.jsx';
import InitLoading from '../InitLoading.jsx';
import InaccessibleEvent from '../InaccessibleEvent/InaccessibleEvent.jsx';

const EVENT_FIELDS = `
  id
  eveyEventId
  title
  locationType
  location
  locationId
  locationUrl
  locationCode
  savedLocation {
    id
    seatingChart {
      id
      variantOptionZoneName
      seatingZones {
        id
        name
      }
    }
  }
  hasSeating
  destination
  destinationUrl
  destinationCode
  transitType
  organizer {
    id
  }
  startAt
  endAt
  dateType
  timezone
  timezoneLabel
  inventory
  scheduleCapacity
  hasCrossVariantInventory
  hasEtickets
  hasPdfTicketType
  hasPassbookTicketType
  hasIcsTicketType
  hasCalendarOptions
  autoSendEtickets
  trial
  subscribed
  isShopifySubscription
  price
  expiresAt
  isMissingProduct
  isApiEventMissing
  publicProductUrl
  productId
  isExpired
  checkinUrl
  createCustomers
  customerEventTag
  customerPurchaserTag
  contactEmail
  metafields
  virtualEventPageUrlBase
  virtualEventPage {
    enabled
    config
    body
    service
    serviceUrl
    embedVideo
    requirePassword
    restrictSessions
    showPasswordInNotification
    openDate
    closeDate
    defaultBody
    showUrlForTicketTypes
  }
  scheduleItems {
    id
    name
    startDate
    endDate
    allDay
    startTime
    duration
    period
    periodNumber
    scheduleExceptions {
      startDate
      startTime
    }
    ticketTypes
  }
`;

const EventQuery = ({ eventId, extraEventAttributes, extraShopAttributes, extraVariables, queryDeclaration, onCompleted, loadingPage, children }) => {
  const query = gql`
    query ${queryDeclaration && queryDeclaration.length > 0 ? queryDeclaration : 'EventQuery($eventId: ID!)'} {
      currentShop {
        id
        remoteId
        name
        domain
        email
        shopUrl
        adminAppUrl
        prettyEventPrice
        prettyUnlimitedPlanPrice
        eventTags
        subscribed
        orderStatusEnabled
        customerNotificationsEnabled
        hasLegacyPricing
        trialExpired
        activeSubscription {
          hasCheckinFeature
          hasRecurringEventsFeature
          hasSeatingFeature
          hasMailchimpFeature
          hasMessagesFeature
          hasPosFeature
          id
        }
        hasReportsFeature
        hasRandomTicketNumbersFeature
        canUseRecurringEvents
        moneyFormat
        currency
        attendeeInfoStorefrontEnabled
        attendeeInfoStorefrontConfig
        features
        availableTimezones {
          label
          name
        }
        eventMetafields {
          fieldType
          name
          metafieldName
        }
        ${extraShopAttributes || ''}
        hasCustomersScope
        event(id: $eventId) {
          ${EVENT_FIELDS}
          ${extraEventAttributes || ''}
        }
        defaultNotificationsTemplate {
          id
        }
        locations {
          id
          name
          url
          imageUrl
          address
          description
          seatingChart {
            id
          }
        }
        organizers {
          id
          name
          imageUrl
          description
        }
      }
    }
  `;

  return (
    <Query
      query={query}
      variables={{
        ...(extraVariables ? extraVariables : {}),
        'eventId': eventId,
      }}
      onCompleted={(data) => {
        if (onCompleted) {
          onCompleted(data);
        }
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return loadingPage || <InitLoading />;
        } else if (error) {
          return <ErrorPage error={error}/>;
        } else {
          const shop = data && data.currentShop;
          const event = shop && shop.event;

          if (event.isApiEventMissing) {
            return <InaccessibleEvent shop={shop} event={event} />;
          }

          return (
            <div className="EventQuery__Main">
              {children({ shop, event })}
            </div>
          );
        }
      }}
    </Query>
  );
};

export default EventQuery;
