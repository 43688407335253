import React from 'react';
import { Card, Stack } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import CheckinTicketTypesCard from './CheckinTicketTypesCard';
import CheckinUsersCard from './CheckinUsersCard';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../utils/features';

const EVENT_FIELDS = `
  checkinUsers {
    id
    createdAt
    email
    checkedinAttendeesCount
    fullAccess
    permissions {
      id
      eventId
      permissions
    }
  }
  globalCheckinUsers {
    id
    createdAt
    email
    checkedinAttendeesCount
    fullAccess
    permissions {
      id
      eventId
      permissions
    }
  }
  ticketsIncludingDeleted {
    id
    title
    variantId
    checkinEnabledAfter
    checkinEnabledBefore
    checkinLimit
    expiresInDays
    allowCheckinCancel
    enabledDuringEvent
    enabledAfterEventStart
    enabledRelativeStart
    isDeleted
  }
`;

const CheckinSettings = ({ eventId }) => {

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
    >
      {({ shop, event }) => {
        const hasCheckinFeature = hasPlanFeature({ shop, feature: 'checkin' });

        return (
          <Stack vertical={true}>
            {!hasCheckinFeature &&
              <UpgradeRequiredBanner message='Upgrade your plan to access the check-in accounts feature.' />
            }
            <CheckinUsersCard shop={shop} event={event} />
            <CheckinTicketTypesCard shop={shop} event={event} />
          </Stack>
        );
      }}
    </EventQuery>
  );
};

export default CheckinSettings;
