import React from 'react';
import gql from 'graphql-tag';
import { Layout } from '@shopify/polaris';
import EventQuery from '../../EventPage/EventQuery.jsx';
import EventCalendarCard from './EventCalendarCard';
import { Query } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../../utils/routing';
import AnnotatedSectionLoading from '../../shared/AnnotatedSectionLoading.jsx';

const EVENT_FIELDS = `
  calendarSyncIntegration {
    enabled,
    icsUrl
  }
`;

const EventCalendar = ({ eventId }) => {
  const history = useHistory();

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        event.dateType === 'recurring' ? (
          <Layout.AnnotatedSection
            title="External calendar sync"
            description={<div>
              <p>Add the schedule, along with ticket sales and customer information for each time slot, to an external calendar.</p>
              <p>
                This calendar subscription is not a public calendar, it contains customer
                information to help you manage check-ins and bookings for different time slots
                (for example, on your front-desk terminal).
              </p>
            </div>}
          >
            <EventCalendarCard
              shop={shop}
              event={event}
            />
          </Layout.AnnotatedSection>
        ) : ('')
      )}
    </EventQuery>
  );
};

export default EventCalendar;
