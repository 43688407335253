import React from 'react';
import { Card, Link, TextField, TextContainer, TextStyle } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import { EDIT_TICKET_CODE_TEMPLATE } from '../../../utils/graphql';
import { useFrame } from '../../../contexts/frame';
import { liquidEngine } from '../../../utils/liquid';
import { buildPreviewData } from '../../../utils/preview';

const TicketCodeTemplateCard = ({ shop, event }) => {
  const [codeTemplate, setCodeTemplate] = React.useState(event.ticketCodeTemplate);
  const [renderError, setRenderError] = React.useState(false);
  const [renderedCode, setRenderedCode] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const renderPreview = async () => {
    try {
      const previewContext = buildPreviewData({ shop, event, config: {} });
      const result = await liquidEngine.parseAndRender(codeTemplate, previewContext);
      setRenderedCode(result);
      setRenderError(false);
    } catch (error) {
      if (error.name === 'ParseError') {
        setRenderError(error.message);
      } else {
        throw error;
      }
    }
  };

  React.useEffect(() => {
    renderPreview();
  }, [codeTemplate]);

  const isDirty = codeTemplate != event.ticketCodeTemplate;

  return (
    <Mutation
      mutation={EDIT_TICKET_CODE_TEMPLATE}
      onError={() => showToastError('Failed to save ticket code')}
      onCompleted={() => showToastNotice('Ticket code saved')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editTicketCodeTemplate, { loading: saving }) => (
        <Card
          primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !isDirty || renderError,
              onAction: () => {
                editTicketCodeTemplate({
                  variables: {
                    eventId: event.id,
                    codeTemplate,
                  },
                });
              },
          }}
          actions={[
            {
              content: 'Liquid variables',
              url: 'https://evey-events.zendesk.com/hc/en-us/',
              external: true,
            }
          ]}
        >
          <Card.Section>
            <TextField
              label="Ticket code template (liquid)"
              value={codeTemplate}
              onChange={(v) => setCodeTemplate(v)}
              error={renderError}
              autoComplete={false}
            />
          </Card.Section>
          <Card.Section title='Preview'>
            <TextContainer><TextStyle variation="subdued">{renderedCode}</TextStyle></TextContainer>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default TicketCodeTemplateCard;
