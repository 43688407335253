import React from 'react';
import { Card, ResourceList, EmptyState } from '@shopify/polaris';
import LocationResourceItem from './LocationResourceItem';
import LocationModal from './LocationModal';

const LocationsCard = ({ shop, locations }) => {
  const [showAddLocationModal, setShowAddLocationModal] = React.useState(false);
  const [showLocationModal, setShowLocationModal] = React.useState(null);

  return (
    <Card
      sectioned
      actions={[
        {
          content: 'Add location',
          onAction: () => setShowAddLocationModal(true),
        },
      ]}
    >
      <ResourceList
        emptyState={!locations.length ? (
          <EmptyState
            heading="No saved locations yet"
          >
            <p>
              All saved locations will appear here.
            </p>
          </EmptyState>
        ) : undefined}
        items={locations}
        renderItem={(location) => (
          <LocationResourceItem
            location={location}
            onSelect={(loc) => setShowLocationModal(loc)}
          />
        )}
        resourceName={{singular: 'location', plural: 'locations'}}
      />
      {showAddLocationModal &&
        <LocationModal
          shop={shop}
          location={null}
          onClose={() => setShowAddLocationModal(false)}
        />
      }
      {showLocationModal &&
        <LocationModal
          shop={shop}
          location={showLocationModal}
          onClose={() => setShowLocationModal(null)}
        />
      }
    </Card>
  );
};

export default LocationsCard;
