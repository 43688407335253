import React from 'react';
import { TextField } from '@shopify/polaris';
import styled from '@emotion/styled';
import { useDebounce } from 'use-debounce';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding-top:10px;
  padding-bottom:10px;
`;

const makeTitle = (slug) => {
  let result = slug.toString().toLowerCase().replace(/[^\w-_]+/g,'').replace(/-/g,' ').replace(/_/g,' ');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const TemplateEditorString = ({ string, value, onChange }) => {
  const [localValue, setLocalValue] = React.useState(value || string.default);
  const [debouncedValue] = useDebounce(localValue, 500);

  React.useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Container>
      <TextField
        label={string.label || makeTitle(string.id.split('.').pop())}
        value={localValue}
        helpText={string.info || ''}
        onChange={setLocalValue}
      />
    </Container>
  );
};

export default TemplateEditorString;
