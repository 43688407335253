import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Badge, Stack, Subheading, TextField, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import ColorPicker from '../shared/ColorPicker.jsx';
import SaveBar from '../shared/SaveBar';
import { shallowEqual } from '../../utils/compare';
import StorefrontScriptCard from './StorefrontScriptCard';
import styled from '@emotion/styled';

const SubtitleContents = styled.div`
  padding: 20px;
`;

const TEXT = [
  {
    handle: 'seating_seat_label',
    label: 'Seat select label',
    defaultValue: 'Seat',
    helpText: 'Label displayed above the seat dropdown.',
  },
  {
    handle: 'seating_view_map',
    label: 'View seating map link label',
    defaultValue: 'View seating map',
    helpText: 'If the seating plan has a image URL included, the customer will see a link to that image with this label.',
  },
  {
    handle: 'seating_sold_out',
    label: 'Sold out label',
    defaultValue: 'sold out',
    helpText: 'If sold out seats are displayed, show this next to each sold out seat',
  },
  {
    handle: 'seating_not_available_header',
    label: 'Not available popup heading',
    defaultValue: 'Not available',
    helpText: 'If cart reservations are enabled, show this as the popup heading when the customer tries to add a seat to their cart that is no longer available',
  },
  {
    handle: 'seating_not_available_message',
    label: 'Not available popup message',
    defaultValue: 'The seat you have selected is no longer available',
    helpText: 'If cart reservations are enabled, show this message when the customer tries to add a seat to their cart that is no longer available',
  },
]

const SeatingCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [strings, setStrings] = React.useState(originalStrings);

  const { showToastNotice, showToastError } = useFrame();

  const defaultLocale = shop.defaultLocaleFile.locale;
  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config) || JSON.stringify(originalStrings) != JSON.stringify(strings);

  return (
    <>
      <SubtitleContents>
        <TextContainer>
          <p>The storefront seating integration allows your customer to select a seat for events that have reserved seating enabled. The app will show a dropdown with seating options.</p>
          <p>Learn more about seating plans and reserved seating <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">in our help center</ShopifyLink> or see it in action on our <ShopifyLink external url='https://eveyevents.myshopify.com/products/awesome-seated-event'>demo shop</ShopifyLink>.</p>
        </TextContainer>
      </SubtitleContents>
      <StorefrontScriptCard shop={shop} />
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update customizations')}
        onCompleted={() => showToastNotice('Customizations updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Card sectioned
            title='Customize'
            actions={[
              {
                content: 'Reset to default',
                onAction: () => {
                  let newConfig = {};

                  let newStrings = {};
                  TEXT.map((string) => {
                    newStrings[string.handle] = string.defaultValue;
                  });
                  setConfig(newConfig);
                  setStrings(newStrings);
                }
              }
            ]}
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !hasChanges,
              onAction: () => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }
            }}
          >
            <Stack distribution="fillEvenly" spacing="extraLoose">
              <Stack vertical spacing='loose'>
                <Subheading>PREFERENCES</Subheading>
                <Checkbox
                  checked={config.seating_hide_soldout}
                  label='Hide sold out / reserved seats from the dropdown'
                  onChange={(v) => setConfig({ ...config, seating_hide_soldout: v })}
                  helpText=""
                />
                <Checkbox
                  checked={config.seating_reservations_enabled}
                  label='Enable cart reservation'
                  onChange={(v) => setConfig({ ...config, seating_reservations_enabled: v })}
                  helpText='If enabled, when a customer adds a seat to their cart the seat will be reserved for a period of time, no one else will be able to add the seat to their cart until the reservation expires'
                />
                {config.seating_reservations_enabled &&
                  <TextField
                    label='Cart reservation expiration time'
                    type='number'
                    min={0}
                    max={10000}
                    value={config.seating_reservations_expiry_minutes == null ? '10' : config.seating_reservations_expiry_minutes.toString()}
                    helpText='The reservation will expire after this period of time'
                    onChange={(v) => setConfig({ ...config, seating_reservations_expiry_minutes: parseInt(v) })}
                    suffix='minutes'
                  />
                }
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>TEXT (for default language: {defaultLocale})</Subheading>
                {TEXT.map((text) =>
                  <TextField
                    key={text.handle}
                    label={text.label}
                    value={strings[text.handle] || text.defaultValue}
                    helpText={text.helpText}
                    onChange={(v) => setStrings({ ...strings, [text.handle]: v })}
                  />
                )}
              </Stack>
            </Stack>
            <SaveBar
              show={hasChanges}
              loading={saving}
              onDiscard={() => {
                setConfig(originalConfig);
                setStrings(originalStrings);
              }}
              onSave={() => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }}
            />
          </Card>
        )}
      </Mutation>
    </>
  );
};

export default SeatingCard;
