import Liquid from 'liquidjs/dist/liquid';

export const liquidEngine = new Liquid();

liquidEngine.registerFilter('pad', (value, count, character="0") => value.toString().padStart(count, character));

liquidEngine.registerTag('schema', {
  parse: (tagToken, remainTokens) => {
    const stream = liquidEngine.parser.parseStream(remainTokens);
    stream
      .on('token', (token) => {
        if (token.name === 'endschema') {
          stream.stop();
        }
      })
      .on('end', () => {
        throw new Error(`tag ${tagToken.getText()} not closed`);
      })
    stream.start();
  },
  render: async (ctx) => {
    return '';
  },
});
