import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, TextField, ChoiceList } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { EVENTS_BULK_ACTION } from '../../utils/graphql';

const ExportAttendeesModal = ({ shop, eventIds, category, filters, search, onClose }) => {
  const [email, setEmail] = React.useState('');
  const [format, setFormat] = React.useState('csv');
  const [includeCancelled, setIncludedCancelled] = React.useState(false);

  console.log(`selected events: ${JSON.stringify(eventIds)}`);

  const { showToastNotice, showToastError } = useFrame();

  const isValid = email && email.length;

  return (
    <Mutation
      mutation={EVENTS_BULK_ACTION}
      onError={() => {
        showToastError('Unable to export attendees');
        onClose();
      }}
      onCompleted={() => {
        showToastNotice('Export processing, you will receive an email when ready');
        onClose();
      }}
    >
      {(eventsBulkAction, { loading }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Export attendees'
          primaryAction={{
            content: 'Send it',
            disabled: !isValid,
            loading: loading,
            onAction: () => {
              eventsBulkAction({
                variables: {
                  eventIds,
                  action: 'export-attendees',
                  email,
                  category,
                  filters: JSON.stringify(filters),
                  search,
                  options: JSON.stringify({
                    format,
                    include_cancelled: includeCancelled,
                  }),
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Close',
              disabled: loading,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label='Email to send export'
                value={email}
                placeholder='test@example.com'
                onChange={setEmail}
                type='email'
              />
              <ChoiceList
                title='Format of export'
                choices={[
                  { label: 'Single CSV file containing all attendees', value: 'csv' },
                  { label: 'A separate CSV file for each event', value: 'multiple-csv' }
                ]}
                selected={[format]}
                onChange={(v) => setFormat(v[0])}
              />
              <ChoiceList
                title='Attendees'
                choices={[
                  { label: 'Only active tickets', value: false },
                  { label: 'All tickets, including cancelled tickets', value: true }
                ]}
                selected={[includeCancelled]}
                onChange={(v) => setIncludedCancelled(v[0])}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ExportAttendeesModal;
