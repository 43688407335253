import React from 'react';
import { ResourceItem, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import SeatingChartImage from './SeatingChartImage';

const SeatingChartResourceItem = ({ seatingChart, onSelect }) => {
  return (
    <ResourceItem
      id={seatingChart.id}
      onClick={() => onSelect(seatingChart)}
    >
      <Stack vertical={false} alignment='center'>
        <SeatingChartImage seatingChart={seatingChart} />
        <Stack vertical={true} distribtion='leading'>
          <TextContainer>{seatingChart.name}</TextContainer>
        </Stack>
      </Stack>
    </ResourceItem>
  );
};

export default SeatingChartResourceItem;
