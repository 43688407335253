import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button } from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import { useFrame } from '../../contexts/frame';

const DELETE_USER_FILE = gql`
  mutation deleteUserFile($eventId: ID!, $fileId: String!) {
    deleteUserFile(eventId: $eventId, fileId: $fileId) {
      files {
        id
        createdAt
        url
        filename
        size
        token
      }
    }
  }
`;

const UserFileDeleteButton = ({ event, file }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation mutation={DELETE_USER_FILE}
      onError={(error) => showToastError('Unable to delete file')}
      onCompleted={(data) => showToastNotice('File deleted')}
      refetchQueries={() => ['EventQuery']}
    >
      {(deleteUserFile, { loading }) => {
        return (
          <Button
            size={'slim'}
            loading={loading}
            outline={true}
            destructive={true}
            icon={DeleteMajor}
            onClick={() => {
              deleteUserFile({
                variables:{
                  eventId: event.id,
                  fileId: file.id
                }
              });
            }}
          >
          Delete
          </Button>
        );
      }}
    </Mutation>
  );
};

export default UserFileDeleteButton;
