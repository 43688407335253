import React from 'react';
import { Card, Stack, FormLayout, Tag, Autocomplete, TextContainer, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { Mutation } from 'react-apollo';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError } from '../../../utils/errors';

const EventTagsCard = ({ shop, event }) => {
  const [selectedTags, setSelectedTags] = React.useState(event.tags || []);
  const [tagsInputText, setTagsInputText] = React.useState('');
  const [saveProductTags, setSaveProductTags] = React.useState(event.saveProductTags);
  const [saveOrderTags, setSaveOrderTags] = React.useState(event.saveOrderTags);

  const { showToastNotice, showToastError } = useFrame();

  const uniqueOptions = Array.from(new Set(shop.eventTags.map((t) => t).concat(selectedTags)));
  const eventTagsOptions = uniqueOptions.map((t) => ({ value: t, label: t })).sort();

  let visibleOptions = [...eventTagsOptions].sort();
  if (tagsInputText) {
    let exactMatch = eventTagsOptions.filter((option) => option.value == tagsInputText);

    const filterRegex = new RegExp(tagsInputText, 'i');
    visibleOptions = eventTagsOptions.filter((option) =>
      option.label.match(filterRegex),
    );

    if (exactMatch.length == 0) {
      visibleOptions.unshift({
        value: tagsInputText, label: `Create "${tagsInputText}" tag`
      });
    }
    visibleOptions = visibleOptions.sort();
  }

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
        } else {
          reportError(error);
        }
        showToastError('Failed to save tags');
      }}
      onCompleted={(data) => showToastNotice('Tags updated')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables:{
                  eventId: event.id,
                  tags: selectedTags || [],
                  saveProductTags,
                  saveOrderTags,
                }
              });
            },
          }}
        >
          <Stack vertical={true} spacing='loose'>
            <TextContainer>
              <Stack>
                {selectedTags.map((tag) => (
                  <Tag
                    key={'option' + tag}
                    onRemove={() => setSelectedTags(selectedTags.filter((t) => t != tag))}
                  >
                    {tag}
                  </Tag>
                ))}
              </Stack>
            </TextContainer>
            <Autocomplete
              allowMultiple
              options={visibleOptions}
              selected={selectedTags}
              textField=<Autocomplete.TextField
                onChange={setTagsInputText}
                value={tagsInputText}
                helpText="Select the tags you want to apply to this event. To add more tags type the new name and select the new option from the list."
              />
              onSelect={(v) => {
                setSelectedTags(v);
                setTagsInputText('');
              }}
              listTitle="Tags"
            />
            <Checkbox
              checked={saveProductTags}
              label="Save these tags to the Shopify Product that is linked to this event"
              onChange={setSaveProductTags}
            />
            <Checkbox
              checked={saveOrderTags}
              label="Save these tags to all future Shopify Orders that include this event"
              onChange={setSaveOrderTags}
            />
          </Stack>
        </Card>
      )}
    </Mutation>
  );
};

export default EventTagsCard;
