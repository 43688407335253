import React from 'react';
import { Query } from 'react-apollo';
import { Card, Link, Stack, Button } from '@shopify/polaris';
import LoadingCard from './LoadingCard';
import { GET_RECURRING_EVENT_STATS } from '../../../utils/graphql';
import CardHeader from '../CardHeader';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import EmptyStateCard from './EmptyStateCard';
import { buildCalendarEvents } from '../../../utils/schedule';
import { buildDateWithCompensatedTimezone } from '../../../utils/time';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import ScheduleEventStatsModal from '../ScheduleEventStatsModal';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';

const CardContainer = styled.div`
  margin-right:16px;
  margin-top:16px;
  width:100%;
  padding: 0 24px;
`;

const Container = styled.div`
  padding:12px;
  padding-top:20px;
  padding-bottom:20px;
`;

const FooterContainer = styled.div`
  margin: 20px 27px 0 27px;
  padding-top:20px;
  padding-bottom:20px;
`;

const localizer = momentLocalizer(moment);

const RecurringCard = ({ shop, event: _event }) => {
  const [view, setView] = React.useState('month');
  const [selectedScheduleEvent, setSelectedScheduleEvent] = React.useState(null);

  const history = useHistory();

  return (
    <Query
      query={GET_RECURRING_EVENT_STATS}
      variables={{
        'eventId': _event.id
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingCard title='Schedule' />;
        }

        const event = {
          ..._event,
          ...(data?.currentShop?.event || {}),
        };
        const scheduleItems = (event?.scheduleItems || []).map((item) => ({
          ...item,
          startDate: item.startDate ? moment(buildDateWithCompensatedTimezone(item.startDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate: item.endDate ? moment(buildDateWithCompensatedTimezone(item.endDate)).format('YYYY-MM-DD') : null,
          scheduleExceptions: item.scheduleExceptions ? item.scheduleExceptions.map((ex) => ({
            ...ex,
            startDate: moment(buildDateWithCompensatedTimezone(ex.startDate)).format('YYYY-MM-DD')
          })) : [],
          resourceId: item.id.toString(),
        }));
        const calendarEvents = buildCalendarEvents({ event, items: scheduleItems });
        // const calendarEvents = [];

        if (calendarEvents.length == 0) {
          return (
            <EmptyStateCard
              title='Schedule'
              fullWidth={true}
              imageUrl={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/recurring-events.svg`}
              message='Set up your schedule to get started with your recurring event.'
              action={(
                <Button
                  primary
                  onClick={() => goToPage({ history, path: `/events/${event.id}/edit` })}
                >
                  Manage event schedule
                </Button>
              )}
              learnMore='https://evey-events.zendesk.com/hc/en-us/'
            />
          );
        }

        return (
          <CardContainer>
            <Card>
              <CardHeader
                title='Schedule'
              />
              <Container>
                <Calendar
                  localizer={localizer}
                  events={calendarEvents}
                  startAccessor="start"
                  endAccessor="end"
                  views={['month']}
                  view={view}
                  onView={setView}
                  style={{ height: 500 }}
                  selectable={true}
                  popup={true}
                  resources={scheduleItems}
                  eventPropGetter={(event, start, end, isSelected) => {
                    return {
                      style: { backgroundColor: '#3C3AB1', opacity: event.resource.draft ? 0.5 : 1 }
                    }
                  }}
                  onSelectEvent={(scheduleEvent) => setSelectedScheduleEvent(scheduleEvent)}
                />
                {selectedScheduleEvent &&
                  <ScheduleEventStatsModal
                    shop={shop}
                    event={event}
                    scheduleEvent={selectedScheduleEvent}
                    onClose={() => setSelectedScheduleEvent(null)}
                  />
                }
              </Container>
              <FooterContainer>
                <Stack distribution='trailing' alignment='center'>
                  <Button
                    primary
                    onClick={() => goToPage({ history, path: `/events/${event.id}/edit` })}
                  >
                    Manage event schedule
                  </Button>
                </Stack>
              </FooterContainer>
            </Card>
          </CardContainer>
        );
      }}
    </Query>
  );
};

export default RecurringCard;
