import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, Icon, Stack, TextContainer } from '@shopify/polaris';
import { EDIT_ATTENDEE_ATTRIBUTE_SPECS } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import styled from '@emotion/styled';
import { ReactSortable } from "react-sortablejs";
import { SelectMinor } from '@shopify/polaris-icons';

const ListItem = styled.div`
  border:1px solid #D3D3D3;
  background-color:#FFFFFF;
  padding: .75rem 1.25rem;
  border: 1px solid rgba(0,0,0,.125);
  cursor: pointer;
  box-sizing: border-box;

  :first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  :last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
`;

const ReorderItemsModal = ({ event, template, initialSpecs, onClose }) => {
  const [specs, setSpecs] = React.useState(initialSpecs);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation mutation={EDIT_ATTENDEE_ATTRIBUTE_SPECS}
      onError={(error) => showToastError('Failed to update item order')}
      onCompleted={(data) => {
        showToastNotice('Item order updated')
        onClose();
      }}
      refetchQueries={() => ['AttendeeAttributeSpecsQuery', 'GetAttendeeInfoFieldsTemplates']}
      awaitRefetchQueries={true}
    >
      {(editAttendeeAttributeSpecs, { loading: saving }) => (
        <Modal
          open={true}
          title='Change attachment item order'
          onClose={onClose}
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editAttendeeAttributeSpecs({
                variables: {
                  eventId: event?.id,
                  templateId: template?.id,
                  specs: JSON.stringify(specs),
                }
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <ReactSortable
              list={specs}
              setList={setSpecs}
              animation={150}
              ghostClass='ReorderItemsSortableGhost'
            >
              {specs.map((spec) => (
                <ListItem key={spec.name}>
                  <Stack vertical={false}>
                    <Icon source={SelectMinor} color="base" />
                    <TextContainer>{spec.label.length > 50 ? `${spec.label.substring(0, 50)}...` : spec.label}</TextContainer>
                  </Stack>
                </ListItem>
              ))}
            </ReactSortable>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ReorderItemsModal;
