import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Banner } from '@shopify/polaris';
import { Context as ShopifyContext } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useFrame } from '../../../contexts/frame';

const SUBSCRIBE_SHOP = gql`
  mutation subscribeShop {
    subscribeShop {
      confirmUrl
    }
  }
`;

const LegacyShopSubscription = ({ shop }) => {
  const [subscribing, setSubscribing] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();
  const app = React.useContext(ShopifyContext);

  return (
    <div>
      {shop.subscribed ? (
        <Banner
          status="info"
          title="Unlimited events"
        >
          <div>
            <p>Your active subscription is {shop.formattedTotalSubscriptionCost} USD per month for unlimited events and full feature access.</p>
          </div>
        </Banner>
      ) : (
        <Mutation
          mutation={SUBSCRIBE_SHOP}
          onError={(error) => {
            showToastError('Unable to subscribe, try again or contact us');
            setSubscribing(false);
          }}
          onCompleted={(data) => {
            if (data && data.subscribeShop) {
              const redirect = Redirect.create(app);
              redirect.dispatch(Redirect.Action.ADMIN_PATH, data.subscribeShop.confirmUrl);
            } else {
              showToastError('Unable to subscribe, try again or contact us');
              setSubscribing(false);
            }
          }}
          refetchQueries={() => ['SubscriptionsQuery']}
        >
          {(subscribeShop, { loading }) => (
            <Banner
              status="info"
              title="Unlimited events"
              action={{
                content: 'Subscribe now',
                loading: loading || subscribing,
                onAction: () => {
                  setSubscribing(true);
                  subscribeShop();
                },
              }}
            >
              <p>Do you have many events, recurring events, or just don&apos;t want to manually manage the subscription for each event every time you create an event or an event is over? This plan might be a good option for you. For <strong>{shop.prettyUnlimitedPlanPrice} USD per month</strong> you can create and sell tickets for as many events as you&apos;d like, with access to all features in the app.</p>
            </Banner>
          )}
        </Mutation>
      )}
    </div>
  );
};

export default LegacyShopSubscription;
