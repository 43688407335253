
export const hasLocations = (shop) => true;
  // [
  //   'eveydev.myshopify.com',
  //   'happy-labs-fr.myshopify.com',
  //   'eveyevents.myshopify.com',
  //   'we-cruise.myshopify.com',
  //   'superhifitrademo.myshopify.com',
  // ].includes(shop);

export const hasNewPricing = (shop) => true;
  // [
  //   'eveydev.myshopify.com',
  // ].includes(shop);

export const hasImportEvents = (shop) =>
  [
    'eveydev.myshopify.com',
  ].includes(shop);

export const hasZoomIntegration = (shop) => true;

export const hasPlanFeature = ({ shop, event, feature }) => {
  const newPricingEnabled = hasNewPricing(shop.domain);

  if (shop.activeSubscription) {
    let featuresMap = {
      'checkin': shop.activeSubscription.hasCheckinFeature,
      'recurring': shop.activeSubscription.hasRecurringEventsFeature,
      'seating': shop.activeSubscription.hasSeatingFeature,
      'mailchimp': shop.activeSubscription.hasMailchimpFeature,
      'zoom': shop.activeSubscription.hasMailchimpFeature,
      'klaviyo': shop.activeSubscription.hasMailchimpFeature,
      'messages': shop.activeSubscription.hasMessagesFeature,
      'pos': shop.activeSubscription.hasPosFeature,
      'vendors': shop.activeSubscription.hasVendorsFeature,
      'branding': shop.activeSubscription.hasBrandingFeature
    }

    return Object.keys(featuresMap).includes(feature) ? featuresMap[feature] : true
  } else {
    if (newPricingEnabled) {
      if (shop.hasLegacyPricing) {
        if (feature === 'recurring') {
          return shop.canUseRecurringEvents;
        } else {
          return true;
        }
      } else {
        return !shop.trialExpired;
      }
    } else {
      if (feature === 'recurring') {
        return shop.canUseRecurringEvents;
      } else {
        return true;
      }
    }
  }
};
