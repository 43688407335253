import React from 'react';
import { FormLayout, TextField, Autocomplete, ChoiceList, Select, Link as ShopifyLink, Stack, Banner } from '@shopify/polaris';
import { hasLocations } from '../../../utils/features';
import { useFrame } from '../../../contexts/frame';
import UpgradeRequiredBanner from '../../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../../utils/features';
import { getHostFromShopDomain } from '../../../utils/auth';
import { useLocation } from "react-router-dom";

const LocationVenueForm = ({ shop, event, locationType, name, setName, url, setUrl, locationId, setLocationId }) => {
  const [useStoredLocation, setUseStoredLocation] = React.useState(locationId != null);
  const location = useLocation();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
  const savedLocations = JSON.parse(shop.savedLocations);

  const hasSeatingFeature = hasPlanFeature({ shop, feature: 'seating' });

  const newLocationMarkup = (
    <FormLayout>
      <Autocomplete
        allowMultiple={false}
        options={savedLocations.map((loc, index) => ({ label: loc.name, value: index }))}
        selected={[]}
        textField=<Autocomplete.TextField
          onChange={setName}
          value={name}
          helpText="Enter a new location or select one you've used before"
        />
        onSelect={(v) => {
          setName(savedLocations[v[0]].name);
          setUrl(savedLocations[v[0]].url);
        }}
        listTitle="Previous Locations"
      />
      <TextField value={url || ''} label="Location URL" placeholder={locationType == 'venue' ? 'https://goo.gl/maps/RMFXyDo5jh8DpwFS8' : 'https://my_livestream.com'} onChange={setUrl} />
    </FormLayout>
  );

  if (hasLocations(shop?.domain) && shop.locations.length > 0) {
    return (
      <FormLayout>
        <ChoiceList
          title=""
          titleHidden
          choices={[
            { value: true, label: <span>Use a saved location that you have already set up in <ShopifyLink external url={`/settings/locations${searchParams}`} target="_blank">the location settings</ShopifyLink>.</span> },
            { value: false , label: 'Set a new location just for this event' },
          ]}
          selected={[useStoredLocation]}
          onChange={(v) => {
            setUseStoredLocation(v[0])
            if (!v[0]) {
              setLocationId(null);
            } else {
              setLocationId(shop.locations.length ? shop.locations[0].id : null);
            }
          }}
        />
        {useStoredLocation ? (
          <Stack vertical={true}>
            <Select
              options={shop.locations.map((loc) => ({ value: loc.id, label: loc.name }))}
              value={locationId}
              onChange={setLocationId}
              label='Select a saved location'
            />
            {shop.locations.find((loc) => loc.id == locationId) && shop.locations.find((loc) => loc.id == locationId).seatingChart &&
              <Stack vertical={true}>
                {!hasSeatingFeature &&
                  <UpgradeRequiredBanner message='Reserved seating is not available on this plan, upgrade to get full access to reserved seating features.' />
                }
                <Banner status='info'>
                  {event ? (
                    event.locationId == locationId ? 'This location has a seating plan configured' : 'This location has a seating plan configured. We will attempt to create seating zone variants on this product if you save this update.'
                  ) : (
                    'This location has a seating plan configured. We will create seating zone variants on the product when it\'s created.'
                  )}
                </Banner>
              </Stack>
            }
          </Stack>
        ) : (
          <div style={{paddingTop: '10px'}}>
            {newLocationMarkup}
          </div>
        )}
      </FormLayout>
    );
  } else {
    return (
      newLocationMarkup
    );
  }
};

export default LocationVenueForm;
