import React from 'react';
import { FormLayout, ChoiceList, TextField, Select, Stack, Banner, TextContainer, TextStyle } from '@shopify/polaris';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const ActionScheduleForm = ({ schedule, onUpdate, extraInputs, event, disableRelativeOptions }) => {
  const customDateObject = schedule && schedule.customDate ? dayjs(schedule && schedule.customDate) : null;
  const originalCustomDate = customDateObject ? dayjs(customDateObject).format('YYYY-MM-DD') : '';
  const originalCustomTime = customDateObject ? dayjs(customDateObject).format('HH:mm') : '';

  const buildDate = (d, t) => {
    return dayjs(d + 'T' + t).utc().format('YYYY-MM-DDTHH:mm:ss[-00:00]');
  }

  const customDate = originalCustomDate || '';
  const setCustomDate = (v) => onUpdate({ ...schedule, customDate: buildDate(v, customTime) });

  const customTime = originalCustomTime || '';
  const setCustomTime = (v) => onUpdate({ ...schedule, customDate: buildDate(customDate, v) });

  const dateType = schedule && schedule.dateType || 'relative_event_start';
  const setDateType = (v) => onUpdate({
    ...schedule,
    dateType: v,
    customDate: v === 'custom' ? buildDate(customDate, customTime) : null,
  });

  const days = schedule && schedule.relativeMinutes && Math.floor(Math.abs(schedule.relativeMinutes) / 24 / 60) || 0;
  const setDays = (v) => onUpdate({ ...schedule, relativeMinutes: ((parseInt(v) * 24 + hours) * 60 + minutes) * (beforeAfter === 'before' ? -1 : 1) });

  const hours = schedule && schedule.relativeMinutes && (Math.floor(Math.abs(schedule.relativeMinutes) / 60) - days * 24) || 0;
  const setHours = (v) => onUpdate({ ...schedule, relativeMinutes: ((days * 24 + parseInt(v)) * 60 + minutes) * (beforeAfter === 'before' ? -1 : 1) });

  const minutes = schedule && schedule.relativeMinutes && (Math.floor(Math.abs(schedule.relativeMinutes)) - days * 24 * 60 - hours * 60) || 0;
  const setMinutes = (v) => onUpdate({ ...schedule, relativeMinutes: (days * 24 * 60 + hours * 60 + parseInt(v)) * (beforeAfter === 'before' ? -1 : 1) });

  const [beforeAfter, setBeforeAfter] = React.useState(schedule && schedule.relativeMinutes ? schedule && parseInt(schedule.relativeMinutes) <= 0 ? 'before' : 'after' : 'before');

  React.useEffect(() => {
    setDays(days);
  }, [beforeAfter]);

  let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' ');

  return (
    <FormLayout>
      {schedule && schedule.triggeredAt ?
        <Banner status='warning'>This scheduled action has already been triggered on {dayjs(schedule.triggeredAt).format('LLL')}. To reset it, disable and re-enable it.</Banner>
          :
        ''
      }
      <ChoiceList
        title="Select a date"
        choices={[
          { label: event.dateType === 'recurring' && !disableRelativeOptions ? 'Relative to every event start in the recurring schedule' : 'Relative to event start date', value: 'relative_event_start', disabled: disableRelativeOptions },
          { label: event.dateType === 'recurring' && !disableRelativeOptions ? 'Relative to every event end in the recurring schedule' : 'Relative to event end date', value: 'relative_event_end', disabled: disableRelativeOptions },
          { label: 'Custom date', value: 'custom' },
        ]}
        selected={[dateType]}
        onChange={(v) => setDateType(v[0])}
      />
      {dateType === 'relative_event_start' ? (
        <Stack vertical={true}>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(days || '0').toString()} type="number" onChange={setDays} />
            <span>days</span>
          </Stack>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(hours || '0').toString()} type="number" onChange={setHours} />
            <span>hours</span>
          </Stack>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(minutes || '0').toString()} type="number" onChange={setMinutes} />
            <span>minutes</span>
            <Select
              options={[
                { label: 'before', value: 'before' },
                { label: 'after', value: 'after' },
              ]}
              value={beforeAfter}
              onChange={setBeforeAfter}
            />
            <span>start of event</span>
          </Stack>
          {event &&
            <TextContainer>
            <TextStyle variation='subdued'>{
              event.dateType === 'recurring' ?
              `Will trigger ${beforeAfter === 'before' ? 'before' : 'after'} every event date/time in the recurring schedule`
                :
              `Will trigger on ${dayjs(event.startAtUtc).add((days*24*60 + hours*60 + minutes) * (beforeAfter === 'before' ? -1 : 1), 'minute').format('LLL')} (${localTimezone})`
            }</TextStyle>
            </TextContainer>
          }
        </Stack>
      ) : dateType === 'relative_event_end' ? (
        <Stack vertical={true}>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(days || '0').toString()} type="number" onChange={setDays} />
            <span>days</span>
          </Stack>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(hours || '0').toString()} type="number" onChange={setHours} />
            <span>hours</span>
          </Stack>
          <Stack vertical={false} spacing='tight' alignment='center'>
            <TextField value={(minutes || '0').toString()} type="number" onChange={setMinutes} />
            <span>minutes</span>
            <Select
              options={[
                { label: 'before', value: 'before' },
                { label: 'after', value: 'after' },
              ]}
              value={beforeAfter}
              onChange={setBeforeAfter}
            />
            <span>end of event</span>
          </Stack>
          {event &&
            <TextContainer>
              <TextStyle variation='subdued'>{
                event.dateType === 'recurring' ?
                `Will trigger ${beforeAfter === 'before' ? 'before' : 'after'} every event date/time in the recurring schedule`
                  :
                `Will trigger on ${dayjs(event.endAtUtc).add((days*24*60 + hours*60 + minutes) * (beforeAfter === 'before' ? -1 : 1), 'minute').format('LLL')} (${localTimezone})`
              }</TextStyle>
            </TextContainer>
          }
        </Stack>
      ) : (
        <FormLayout.Group condensed>
          <TextField value={customDate} type="date" label="Date" onChange={setCustomDate} placeholder="YYYY-MM-DD" />
          <TextField value={customTime} type="time" label={`Time (${localTimezone})`} onChange={setCustomTime} placeholder="HH:MM (24-hour)" />
        </FormLayout.Group>
      )}
      {extraInputs ? extraInputs : ''}
    </FormLayout>
  );
};

export default ActionScheduleForm;
