import React from 'react';
import { Banner, TextContainer } from '@shopify/polaris';

const SendingBanner = ({ reminder }) => {
  return (
    <Banner
      title='Message is sending'
      status='info'
    >
      <TextContainer>The message is being sent, this may take some time. Refresh this page to check the progress.</TextContainer>
      <TextContainer>{reminder.sentCount || 0} of {reminder.totalCount || 0} messages sent to your attendees.</TextContainer>
    </Banner>
  );
};

export default SendingBanner;
