import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, FormLayout, Heading, TextContainer, Link as ShopifyLink, ChoiceList, InlineError, Popover, OptionList, Button, Stack, Checkbox, Banner } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import ModalFooter from '../../shared/ModalFooter.jsx';

const SAVE_VENDOR = gql`
  mutation saveVendor($vendorId: ID, $email: String!, $name: String, $permissions: [String!], $eventIds: [String!], $allEvents: Boolean) {
    saveVendor(vendorId: $vendorId, email: $email, name: $name, permissions: $permissions, eventIds: $eventIds, allEvents: $allEvents) {
      vendor {
        id
      }
    }
  }
`;

const DELETE_VENDOR = gql`
  mutation deleteVendor($vendorId: ID!) {
    deleteVendor(vendorId: $vendorId) {
      success
    }
  }
`;

const VendorAccountModal = ({ shop, account, onClose }) => {
  const [name, setName] = React.useState(account ? account.name : '');
  const [email, setEmail] = React.useState(account ? account.email : '');
  const [eventsPopoverActive, setEventsPopoverActive] = React.useState(false);
  const [allEvents, setAllEvents] = React.useState(account ? account.allEvents : true);
  const [selectedEvents, setSelectedEvents] = React.useState(account ? account.eventIds : ['all']);
  const [selectedPermissions, setSelectedPermissions] = React.useState(account ? account.permissions : ['all']);

  const { showToastNotice, showToastError, externalHost } = useFrame();

  const isValid = email && email.length > 0;

  const events = shop.allEvents;

  const allPermissionsSelected = selectedPermissions.includes('all');

  const selectedEventsTitles = selectedEvents
    .filter((eventId) => events.find((e) => e.eveyEventId == eventId))
    .map((eventId) => events.find((e) => e.eveyEventId == eventId).title);
  let eventsLabel = selectedEventsTitles ? `${selectedEventsTitles.length} event${selectedEventsTitles.length == 1 ? '' : 's'} selected` : 'Select events';

  const availablePermissions = [
    { label: 'Create events', value: 'create-events' },
    ...(selectedPermissions.some((p) => ['all', 'create-events'].includes(p)) ? [
      { label: 'Allow new events to be published to the storefront by this user', value: 'new-events-allow-publish' },
    ] : []),
    { label: 'Edit events settings', value: 'edit-events' },
    ...(selectedPermissions.some((p) => ['all', 'create-events', 'edit-events'].includes(p)) ? [
      { label: 'Customize ticket templates', value: 'edit-ticket-templates' },
      { label: 'Customize notification templates', value: 'edit-notification-templates' },
      { label: 'Edit attendee info fields', value: 'edit-event-attendee-info' },
      { label: 'Set ticket fees', value: 'edit-event-ticket-fees' },
    ] : []),
    { label: 'View attendees', value: 'view-attendees' },
    { label: 'Create attendees', value: 'create-attendees' },
    { label: 'Edit attendees', value: 'edit-attendees' },
  ];

  return (
    <Mutation
      mutation={DELETE_VENDOR}
      onError={() => showToastError('Failed to delete account')}
      onCompleted={() => {
        showToastNotice('Account deleted');
        onClose();
      }}
      refetchQueries={() => ['GetVendors']}
      awaitRefetchQueries={true}
    >
      {(deleteVendor, { loading: deleting }) => (
        <Mutation
          mutation={SAVE_VENDOR}
          onError={() => showToastError(account ? 'Failed to update account' : 'Failed to add account')}
          onCompleted={() => {
            showToastNotice(account ? 'Account updated' : 'Account created');
            onClose();
          }}
          refetchQueries={() => ['GetVendors']}
        >
          {(saveVendor, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={account ? `Edit account` : 'Add account'}
              footer={
                <ModalFooter
                  saveButtonText="Save"
                  saveButtonAction={() => {
                    saveVendor({
                      variables: {
                        vendorId: account ? account.id : null,
                        email,
                        name,
                        permissions: selectedPermissions,
                        eventIds: allEvents ? [] : selectedEvents,
                        allEvents: allEvents,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={!isValid || deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText={'Delete'}
                  destructiveButtonAction={account ? () => {
                    deleteVendor({
                      variables: {
                        vendorId: account.id,
                      },
                    });
                  } : undefined}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />
              }
            >
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label="Name"
                    value={name}
                    onChange={setName}
                  />
                  <TextField
                    label="Email"
                    value={email}
                    placeholder="test@example.com"
                    onChange={setEmail}
                    disabled={account}
                    helpText={account ? 'The account owner can update their email and password after they log in to the vendor dashboard' : ''}
                  />
                  {!account &&
                    <Banner status='info'>An email will be sent to this email address to set up their password if the account does not already exist.</Banner>
                  }
                  {account &&
                    <ShopifyLink
                      url={`https://${externalHost}/vendor/forgot_password?shop=${shop.domain}`}
                      external
                    >
                      Forgot password
                    </ShopifyLink>
                  }
                </FormLayout>
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <Heading>Access controls</Heading>
                  <Checkbox
                    checked={allEvents}
                    label="Account has access to all events"
                    onChange={setAllEvents}
                  />
                  {!allEvents &&
                    <Stack vertical={true}>
                      <Popover
                        active={eventsPopoverActive}
                        activator={<Button onClick={() => setEventsPopoverActive(!eventsPopoverActive)}>{eventsLabel}</Button>}
                        onClose={() => setEventsPopoverActive(false)}
                      >
                        <OptionList
                          title='Events'
                          onChange={(v) => {
                            if (v == 'all') {
                              setSelectedEvents(['all']);
                            } else {
                              setSelectedEvents(v);
                            }
                          }}
                          options={events.map((e) => ({ label: e.title, value: e.eveyEventId.toString() }))}
                          selected={selectedEvents}
                          allowMultiple
                        />
                      </Popover>
                      {(!selectedEvents || selectedEvents.length == 0) &&
                        <InlineError message='No events selected' />
                      }
                    </Stack>
                  }
                  <ChoiceList
                    allowMultiple
                    title='Permissions'
                    choices={[
                      { label: 'Full access', value: 'all' },
                      ...availablePermissions.map((p) => ({ label: p.label, value: p.value, disabled: allPermissionsSelected })),
                    ]}
                    selected={allPermissionsSelected ? ['all', ...availablePermissions.map((p) => p.value)] : selectedPermissions}
                    onChange={(v) => setSelectedPermissions(v)}
                  />
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default VendorAccountModal;
