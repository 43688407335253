import React from 'react';
import { Card, DataTable, Badge } from '@shopify/polaris';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const CheckinsCard = ({ checkins }) => {
  return (
    <Card>
      <Card.Section>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Date',
            'Action',
            'User',
          ]}
          rows={checkins.map((checkin) => ([
            dayjs(checkin.createdAt).format('LLL'),
            <Badge status={checkin.action == 'checkin' ? 'success' : 'critical'}>{checkin.action == 'checkin' ? 'Check-In' : 'Cancel'}</Badge>,
            checkin.user && checkin.user.email || 'Unknown',
          ]))}
          footerContent={`Showing ${checkins.length} results`}
        />
      </Card.Section>
    </Card>
  );
};

export default CheckinsCard;
