import React from 'react';
import { Card, Link, Button, DataTable, Pagination, Filters } from '@shopify/polaris';
import TicketTypeModal from './TicketTypeModal';
import { moneyFormat } from '../../utils/money';
import { buildDateWithCompensatedTimezone } from '../../utils/time';
import { getHostFromShopDomain } from '../../utils/auth';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

const PaginationContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  margin-top:5px;
`;

const PER_PAGE = 5;

const TicketTypesCard = ({ shop, event }) => {
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
  const [openTicketTypeModal, setOpenTicketTypeModal] = React.useState(null);
  const [openNewTicketTypeModal, setOpenNewTicketTypeModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');

  const hasDates = event.tickets.some((t) => t.eventDate);

  const seatingChart = event.savedLocation && event.savedLocation.seatingChart;
  const hasSeating = !!seatingChart;

  const ticketsCount = event.tickets.length;
  // seats = seats.offset((page.to_i - 1) * per_page.to_i).limit(per_page.to_i)
  const start = (currentPage - 1) * PER_PAGE;
  const rows = event.tickets.filter((t) => !searchValue || t.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
  .slice(start, start + PER_PAGE).map((ticket) => {
    let titleRow = ticket.title;
    if (ticket.variantId) {
      titleRow = <Link
        external={true}
        url={`https://${shop.domain}/admin/products/${event.productId}/variants/${ticket.variantId}${searchParams}`}
      >
        {ticket.title}
      </Link>;
    } else if (ticket.isDeleted) {
      titleRow = `${ticket.title} (DELETED)`;
    }

    const actions = [
      <Button key='edit' onClick={() => setOpenTicketTypeModal(ticket.id)} size='slim'>Edit</Button>
    ];

    let priceString = ticket.ticketFee ? `${moneyFormat(shop, (ticket.price - ticket.ticketFee))} + ${moneyFormat(shop, ticket.ticketFee)}` : moneyFormat(shop, ticket.price);

    return [
      titleRow,
      priceString.replace(/(<([^>]+)>)/gi, ""),
      ...(!event.hasCrossVariantInventory && !hasSeating ? [ticket.inventory] : []),
      ticket.groupSize,
      ...(hasDates ? [ticket.eventDate && dayjs(buildDateWithCompensatedTimezone(ticket.eventDate)).format('YYYY-MM-DD h:mma')] : []),
      actions
    ];
  });

  let columnTypes = [
    'text',
    'numeric',
    ...(!event.hasCrossVariantInventory && !hasSeating ? ['numeric'] : []),
    'text',
    ...(hasDates ? ['text'] : []),
    'text'
  ];

  let headings = [
    'Title',
    'Price',
    ...(!event.hasCrossVariantInventory && !hasSeating ? [event.dateType === 'recurring' ? 'Per event capacity' : 'Inventory'] : []),
    'Group Size',
    ...(hasDates ? ['Date'] : []),
    ''
  ];

  const startIndex = (currentPage-1) * PER_PAGE;

  return (
    <Card
      actions={[
        {
          content: 'Add ticket type',
          onAction: () => setOpenNewTicketTypeModal(true),
        }
      ]}
    >
      <Card.Section>
        {ticketsCount > PER_PAGE &&
          <Filters
            queryValue={searchValue}
            queryPlaceholder='Search'
            filters={[]}
            appliedFilters={[]}
            onQueryChange={(v) => setSearchValue(v)}
            onQueryClear={() => {
              setSearchValue('');
            }}
            onClearAll={() => {
              setSearchValue('');
            }}
          />
        }
        <DataTable
          columnContentTypes={columnTypes}
          headings={headings}
          rows={rows}
          footerContent={`Showing ${startIndex+1}-${startIndex + rows.length} of ${ticketsCount} results`}
        />
        {rows.length > 0 && ticketsCount > PER_PAGE &&
          <PaginationContainer>
            <Pagination
              hasPrevious={currentPage > 1}
              hasNext={currentPage < (Math.ceil(ticketsCount / PER_PAGE))}
              onPrevious={() => setCurrentPage(currentPage - 1)}
              onNext={() => setCurrentPage(currentPage + 1)}
            />
          </PaginationContainer>
        }
      </Card.Section>
      {(openTicketTypeModal != null || openNewTicketTypeModal) &&
        <TicketTypeModal
          shop={shop}
          event={event}
          ticketTypeId={openNewTicketTypeModal ? null : openTicketTypeModal}
          onClose={() => {
            setOpenTicketTypeModal(null);
            setOpenNewTicketTypeModal(false);
          }}
        />
      }
    </Card>
  );
};

export default TicketTypesCard;
