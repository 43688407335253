import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Modal } from '@shopify/polaris';

const UNSUBSCRIBE_EVENT = gql`
  mutation unsubscribeEvent($eventId: ID!) {
    unsubscribeEvent(eventId: $eventId) {
      event {
        id
      }
      confirmUrl
    }
  }
`;

const UnsubscribeEvent = ({ event, onDismiss, onConfirmRequest, onComplete, onError }) => {
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(true);
  const [unsubscribing, setUnsubscribing] = React.useState(false);

  return (
    <div className="UnsubscribeEvent__Main">
      <Mutation
        mutation={UNSUBSCRIBE_EVENT}
        onError={() => {
          if (onError) {
            onError();
          }
        }}
        onCompleted={(data) => {
          if (data.unsubscribeEvent.confirmUrl && data.unsubscribeEvent.confirmUrl.length > 0) {
            onConfirmRequest(data.unsubscribeEvent.confirmUrl);
          } else {
            onComplete();
          }
          setConfirmModalOpen(false);
        }}
      >
        {(unsubscribeEvent) => (
          <Modal
            title='Confirm subscription cancellation for event'
            open={confirmModalOpen}
            onClose={() => {
              setConfirmModalOpen(false);
              if (onDismiss) {
                onDismiss();
              }
            }}
            primaryAction={{
              content: 'Yes, cancel subscription',
              destructive: true,
              disabled: unsubscribing,
              onAction: () => {
                setUnsubscribing(true);
                unsubscribeEvent({
                  variables: {
                    eventId: event.id,
                  },
                });
              }
            }}
            secondaryActions={[
              {
                content: 'No, go back',
                disabled: unsubscribing,
                onAction: () => {
                  setConfirmModalOpen(false);
                  if (onDismiss) {
                    onDismiss();
                  }
                }
              }
            ]}
          >
            <Modal.Section>
              Are you sure you want to cancel your event subscription for this event? You will not have access to this event anymore or be able to create new tickets unless you re-subscribe. You will be asked by Shopify on the next page to confirm the change in total subscription price, this new price will not contain the cost for this event.
            </Modal.Section>
          </Modal>
        )}
      </Mutation>
    </div>
  );
};

export default UnsubscribeEvent;
