import React from 'react';
import { Modal, Spinner, Stack, Subheading, TextContainer, TextStyle, DataTable, Link, Tabs, ResourceList, Avatar, Pagination, Filters } from '@shopify/polaris';
import { Query } from 'react-apollo';
import { GET_INVENTORY_LEVELS_FOR_DATE, GET_ATTENDEES, GET_RECURRING_EVENT_INSTANCE_REVENUE } from '../../utils/graphql';
import moment from 'moment';
import { sum } from '../../utils/array';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import Gravatar from 'gravatar';
import { FullHeightContainer } from '../shared/containers';
import { useFrame } from '../../contexts/frame';
import { moneyFormat } from '../../utils/money';
import { getHostFromShopDomain } from '../../utils/auth';

const AttendeeName = styled.div`
  font-weight:bold;
`;

const TicketCode = styled.div`
  font-weight:normal;
`;

const TicketType = styled.div`
  font-weight:normal;
`;

const PaginationContainer = styled.div`
  display:flex;
  justify-content:center;
`;

const FooterContainer = styled.div`
  padding:10px;
  margin-left:10px;
  margin-right:10px;
`;

const InventorySummary = ({ shop, event, scheduleEvent }) => {
  const searchParams = "&host=" + getHostFromShopDomain(shop?.domain);
  const titleDate = moment(scheduleEvent.start).format('MMMM D, YYYY [at] h:mmA');

  return (
    <Query
      query={GET_INVENTORY_LEVELS_FOR_DATE}
      variables={{
        'eventId': event.id,
        'date': moment(scheduleEvent.start).format('YYYY-MM-DD'),
      }}
    >
      {({ loading, data }) => (
        <Query
          query={GET_RECURRING_EVENT_INSTANCE_REVENUE}
          variables={{
            'eventId': event.id,
            'ts': moment(scheduleEvent.start).format('YYYY-MM-DDTHH:mm:00'),
          }}
        >
          {({ loading: revenueLoading, data: revenueData }) => {
            if (loading || revenueLoading) {
              return (
                <FullHeightContainer alignVertically>
                  <Spinner size="small" />
                </FullHeightContainer>
              );
            }

            const inventoryLevels = data && data.currentShop && data.currentShop.event && data.currentShop.event.inventoryLevels.filter((lvl) => lvl.startTime === moment(scheduleEvent.start).format('HH:mm'));

            let ticketCapacities = [];
            let sharedCapacity = event.inventory;
            let sharedTicketsSold = 0;
            let sharedRemaining = event.inventory;
            if (event.hasSeating) {
              const seatingZones = data && data.currentShop && data.currentShop.event.savedLocation.seatingChart.seatingZones;
              ticketCapacities = seatingZones.map((seatingZone) => {
                const ticketCapacity = seatingZone.seatsCount;
                const ticketsSold = sum(
                  inventoryLevels.filter((lvl) => lvl.seat && lvl.seat.seatingZone && lvl.seat.seatingZone.id == seatingZone.id),
                  'ticketsSold'
                );

                return {
                  eventTicketId: seatingZone.id,
                  ticketTitle: seatingZone.name,
                  capacity: ticketCapacity,
                  ticketsSold: ticketsSold,
                  ticketsRemaining: ticketCapacity - ticketsSold,
                };
              });
            } else if (event.hasCrossVariantInventory) {
              const sharedCapacityLevel = inventoryLevels.find((lvl) => !lvl.eventTicket || lvl.eventTicket.id == null);
              if (sharedCapacityLevel) {
                sharedCapacity = sharedCapacityLevel.capacity == null ? event.scheduleCapacity : parseInt(sharedCapacityLevel.capacity);
                sharedTicketsSold = parseInt(sharedCapacityLevel.ticketsSold);
                sharedRemaining = sharedCapacity - sharedTicketsSold;
              }
            } else {
              ticketCapacities = event.tickets.map((ticket) => {
                const inventoryLevel = inventoryLevels.find((lvl) => lvl.eventTicket && lvl.eventTicket.id == ticket.id);
                const ticketCapacity = !inventoryLevel || inventoryLevel.capacity == null ? ticket.inventory : (inventoryLevel && inventoryLevel.capacity);
                const ticketsSold = inventoryLevel ? inventoryLevel.ticketsSold : 0;
                return {
                  eventTicketId: ticket.id,
                  ticketTitle: ticket.title,
                  capacity: ticketCapacity,
                  ticketsSold: ticketsSold,
                  ticketsRemaining: ticketCapacity - ticketsSold,
                };
              });
            }

            const attendeeListUrl = `${shop.adminAppUrl}/events/${event.id}/attendees?shop=${shop.domain}&scheduledOn=${moment(scheduleEvent.start).format('YYYY-MM-DDTHH:mm:00')}`;

            const revenues = JSON.parse(revenueData?.currentShop?.event?.recurringEventInstanceRevenue || '[]');

            const rows = event.hasCrossVariantInventory ? ([
              [
                'All ticket types',
                sharedTicketsSold,
                sharedCapacity,
                sharedRemaining,
                moneyFormat(shop, revenues.filter((t) => !t.deleted_at).reduce((a, t) => a + (t.revenue || 0), 0)),
              ]
            ]) : (
              ticketCapacities.map((ticketCapacity) => {
                return [
                  ticketCapacity.ticketTitle,
                  ticketCapacity.ticketsSold > 0 ? (
                    event.hasSeating ?
                      <Link external url={`${attendeeListUrl}&seatingZone=${ticketCapacity.eventTicketId}${searchParams}`}>{ticketCapacity.ticketsSold}</Link>
                        :
                      <Link external url={`${attendeeListUrl}&ticketType=${ticketCapacity.eventTicketId}${searchParams}`}>{ticketCapacity.ticketsSold}</Link>
                    ) : ticketCapacity.ticketsSold,
                  ticketCapacity.capacity,
                  ticketCapacity.ticketsRemaining,
                  moneyFormat(shop, revenues.find((t) => t.ticket_type_id == ticketCapacity.eventTicketId)?.revenue || 0),
                ];
              })
            );

            return (
              <>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                  ]}
                  headings={[
                    '',
                    <Link external url={attendeeListUrl + searchParams}>Tickets sold</Link>,
                    'Capacity',
                    'Remaining',
                    <span>Revenue<sup>*</sup></span>,
                  ]}
                  rows={rows}
                />
                <FooterContainer>
                  <TextContainer>
                    <TextStyle variation='subdued'>* Revenue is calculated using current ticket price multiplied by the number of tickets sold. It does not account for discounts or price changes.</TextStyle>
                  </TextContainer>
                </FooterContainer>
              </>
            );
          }}
        </Query>
      )}
    </Query>
  );
};

const AttendeesList = ({ shop, event, scheduleEvent }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [localSearchValue, setLocalSearchValue] = React.useState('');
  const history = useHistory();
  const { navigateTo } = useFrame();

  const PER_PAGE = 10;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const isFirstPage = () => {
    if (currentPage <= 1) {
      return true;
    } else {
      return false;
    }
  };

  const isLastPage = (attendeesCount) => {
    let numPages = Math.ceil(attendeesCount / PER_PAGE);
    if (currentPage >= numPages) {
      return true;
    } else {
      return false;
    }
  };

  const handlePreviousPage = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  return (
    <Query
      query={GET_ATTENDEES}
      variables={{
        'eventId': event.id,
        'perPage': PER_PAGE,
        'page': currentPage,
        'category': 'active',
        'search': searchValue,
        'filters': JSON.stringify([
          { key: 'scheduledOn', value: [moment(scheduleEvent.start).format('YYYY-MM-DDTHH:mm:00')] }
        ]),
      }}
    >
      {({ loading, data }) => {
        const shop = data?.currentShop;
        const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
        const event = shop?.event;
        const attendees = event?.attendees || [];
        const attendeesCount = (event?.attendeesCounts ? event.attendeesCounts[0] : 0) || 0;

        return (
          <Stack vertical={true}>
            <ResourceList
              resourceName={{ singular: 'attendee', plural: 'attendees' }}
              items={attendees}
              loading={loading}
              showHeader={false}
              renderItem={(attendee) => (
                <ResourceList.Item
                  id={attendee.id}
                  media={<Avatar customer size="medium" name={attendee.name} source={Gravatar.url(attendee.email, {d: 'mp'})} />}
                  shortcutActions={[
                    {
                      content: 'View / Edit',
                      url: `/events/${event.id}/attendees/${attendee.id}${searchParams}`,
                      external: true,
                      newContext: true 
                    }
                  ]}
                >
                  <Stack vertical={true} spacing='extraTight'>
                    <Stack vertical={false}>
                      <TicketCode>{attendee.ticketCode}</TicketCode>
                      <AttendeeName>{attendee.name}</AttendeeName>
                    </Stack>
                    <TicketType>{attendee.ticketTitle}</TicketType>
                  </Stack>
                </ResourceList.Item>
              )}
              filterControl={
                <div onKeyDown={(ev) => {
                  if (ev.keyCode == 13) {
                    setSearchValue(localSearchValue);
                  }
                }}>
                  <Filters
                    queryValue={localSearchValue}
                    queryPlaceholder='Search'
                    filters={[]}
                    appliedFilters={[]}
                    onQueryChange={(v) => setLocalSearchValue(v)}
                    onQueryClear={() => {
                      setSearchValue('');
                      setLocalSearchValue('');
                    }}
                    onClearAll={() => {
                      setSearchValue('');
                      setLocalSearchValue('');
                    }}
                  />
                </div>
              }
            />
            <PaginationContainer>
              <Pagination
                hasPrevious={!isFirstPage()}
                hasNext={!isLastPage(attendeesCount)}
                onPrevious={handlePreviousPage}
                onNext={handleNextPage}
              />
            </PaginationContainer>
          </Stack>
        );
      }}
    </Query>
  );
};

const ScheduleEventStatsModal = ({ shop, event, scheduleEvent, onClose }) => {
  const [selectedTab, setSelectedTab] = React.useState('inventory');

  const titleDate = moment(scheduleEvent.start).format('MMMM D, YYYY [at] h:mmA');

  const tabs = [
    {
      id: 'inventory',
      content: 'Inventory & Sales',
      accessibilityLabel: 'Inventory and sales',
      panelID: 'inventory-content',
    },
    {
      id: 'attendees',
      content: 'Tickets / Bookings',
      accessibilityLabel: 'Tickets and bookings',
      panelID: 'attendees-content',
    },
  ];

  console.log(`selectedTab: ${selectedTab}`);

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={titleDate}
      large
    >
      <Modal.Section>
        <Tabs
          tabs={tabs}
          selected={tabs.findIndex((t) => t.id == selectedTab) === -1 ? 0 : tabs.findIndex((t) => t.id == selectedTab)}
          onSelect={(v) => setSelectedTab(tabs[v].id)}
        >
          {selectedTab == 'inventory' &&
            <InventorySummary shop={shop} event={event} scheduleEvent={scheduleEvent} />
          }
          {selectedTab == 'attendees' &&
            <AttendeesList shop={shop} event={event} scheduleEvent={scheduleEvent} />
          }
        </Tabs>
      </Modal.Section>
    </Modal>
  );
};

export default ScheduleEventStatsModal;
