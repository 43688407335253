import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Layout, Autocomplete, Stack, FormLayout, TextField, PageActions } from '@shopify/polaris';
import AppPage from '../../AppPage/AppPage.jsx';
import AnnotatedSectionLoading from '../../shared/AnnotatedSectionLoading.jsx';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import SeatingZoneSelect from './SeatingZoneSelect';
import SeatingChartCard from './SeatingChartCard';
import DeleteSeatingChartModal from './DeleteSeatingChartModal';
import SeatingZonesCard from './SeatingZonesCard';
import SeatsCard from './SeatsCard';

const GET_SEATING_CHART = gql`
  query GetSeatingChart($seatingChartId: ID!) {
    currentShop {
      id
      domain
      seatingChart(id: $seatingChartId) {
        id
        name
        imageUrl
        seatingZones {
          id
          name
          seatsCount
        }
      }
    }
  }
`;

const SeatingChartPage = ({ match }) => {
  const [selectedZone, setSelectedZone] = React.useState();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const history = useHistory();

  return (
    <AppPage
      title='Seating plan'
      fullWidth
      breadcrumbs={[
        {
          content: 'Settings',
          onAction: () => goToPage({ history, path: '/settings' }),
          target: 'APP'
        },
        {
          content: 'Locations',
          onAction: () => goToPage({ history, path: '/settings/locations' }),
          target: 'APP'
        },
      ]}
      secondaryActions={[
        {
          content: 'Back',
          icon: ArrowLeftMinor,
          onAction: () => goToPage({ history, path: '/settings/locations' }),
          target: 'APP'
        },
      ]}
    >
      <Query query={GET_SEATING_CHART}
        variables={{
          'seatingChartId': match.params.seatingChartId,
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return <AnnotatedSectionLoading />;
          }

          const shop = data.currentShop;
          const seatingChart = shop && shop.seatingChart;

          return (
            <Layout>
              <Layout.AnnotatedSection
                title="Seating plan details"
                description='Name and chart image'
              >
                <SeatingChartCard shop={shop} seatingChart={seatingChart} />
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Seating zones"
                description='A seating plan can be subdivided into top level zones or sections, such as upper and lower levels or more detailed sections. Zones are repsented by different variants in Shopify, and can have different pricing.'
              >
                <SeatingZonesCard shop={shop} seatingChart={seatingChart} />
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Seats"
                description='Create and manage individual seats for seating zones. Customers can select their seat on the product page for an event, where the seat is reserved until they complete checkout (or for up to a set time limit).'
              >
                <Stack vertical={true} spacing='loose'>
                  <SeatsCard
                    shop={shop}
                    seatingChart={seatingChart}
                  />
                </Stack>
              </Layout.AnnotatedSection>
              <Layout.Section>
                <PageActions
                  secondaryActions={[
                    {
                      content: 'Delete seating plan',
                      destructive: true,
                      onAction: () => setShowDeleteModal(true),
                    },
                  ]}
                />
              </Layout.Section>
              {showDeleteModal &&
                <DeleteSeatingChartModal
                  seatingChart={seatingChart}
                  onClose={() => setShowDeleteModal(false)}
                  onCompleted={() => goToPage({ history, path: '/settings/locations' })}
                />
              }
            </Layout>
          );
        }}
      </Query>
    </AppPage>
  );
};

export default SeatingChartPage;
