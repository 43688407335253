import React from 'react';
import { ResourceItem, Stack, TextContainer, Badge, TextStyle } from '@shopify/polaris';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const AttendeeReminderResourceItem = ({ event, reminder, history }) => {

  let statusBadge = <Badge status='default'>Ready to send</Badge>;
  if (reminder.sending) {
    statusBadge = <Badge status='info'>Send in progress</Badge>;
  } else if (reminder.sentAt) {
    statusBadge = <Badge status='success'>Send complete</Badge>;
  }

  return (
    <ResourceItem
      id={reminder.id}
      onClick={() => {
        history.push(`/events/${event.id}/reminders/${reminder.id}`);
        window.scrollTo({ top: 0 });
      }}
    >
      <Stack vertical={false} distribution='equalSpacing' alignment='center'>
        <Stack vertical={true} distribtion='leading'>
          <TextContainer><strong>{reminder.name}</strong></TextContainer>
          <TextContainer>Created on {dayjs(reminder.createdAt).format('LLL')}</TextContainer>
          {reminder.scheduledDate ? (
            <TextContainer><TextStyle variation='subdued'>Scheduled to send on {dayjs(reminder.scheduledDate).format('LLL')}</TextStyle></TextContainer>
          ) : ('')}
        </Stack>
        <Stack vertical={true} distribtion='trailing' alignment='center'>
          {statusBadge}
        </Stack>
      </Stack>
    </ResourceItem>
  );
};

export default AttendeeReminderResourceItem;
