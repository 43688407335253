import React from 'react';
import EventQuery from '../EventPage/EventQuery.jsx';
import { Layout, Banner, Link as ShopifyLink } from '@shopify/polaris';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import SeatingCard from './Seating/SeatingCard';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import SeatingIssuesBanner from './Seating/SeatingIssuesBanner';

const EVENT_FIELDS = `
  savedLocation {
    id
    seatingChart {
      id
      seatingZones {
        id
        name
      }
    }
  }
  tickets {
    id
    title
    price
    visible
    inventory
    variantId
    groupSize
    groupIndividualTickets
    variant {
      id
      title
      price
      option1
      option2
      option3
    }
    eventDate
    eventEndDate
    seatingZoneId
    isDeleted
  }
`;

const SeatingPage = ({ eventId }) => {
  const history = useHistory();

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <Layout>
          <SeatingIssuesBanner eventId={event.id} />
          <Layout.AnnotatedSection
            title="Reserved seating"
            description=<div>
              <p>An overview of seating sales and reservations for this event.</p>
              <p>Visit the reserved <ShopifyLink onClick={() => goToPage({ history, path: '/settings/locations' })}>seating plans page</ShopifyLink> to configure seating plans, zones, and other seating options.</p>
              <p>You can configure the storefront customer experience and more on the <ShopifyLink onClick={() => goToPage({ history, path: '/settings/storefront?section=seating' })}>storefront integrations seating page</ShopifyLink>.</p>
            </div>
          >
            <SeatingCard shop={shop} event={event} />
          </Layout.AnnotatedSection>
        </Layout>
      )}
    </EventQuery>
  );
};

export default SeatingPage;
