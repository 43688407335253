import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, Checkbox, Collapsible, ChoiceList, TextField, Banner, Stack } from '@shopify/polaris';
import styled from '@emotion/styled';
import ActionScheduleForm from '../../ActionSchedule/ActionScheduleForm';
import { useFrame } from '../../../contexts/frame';
import { UPDATE_SCHEDULE_AVAILABILITY } from '../../../utils/graphql';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat)

const FormContainer = styled.div`
  padding-left:28px;
  padding-top:5px;
`;

const MethodContainer = styled.div`
  padding-top:5px;
`;

const ScheduleAvailabilityModal = ({ event, onClose }) => {
  const [schedulePublish, setSchedulePublish] = React.useState(event.publishSchedule ? { ...event.publishSchedule } : null);
  const [scheduleUnpublish, setScheduleUnpublish] = React.useState(event.unpublishSchedule ? { ...event.unpublishSchedule } : null);

  const schedulePublishParams = schedulePublish && schedulePublish.params ? JSON.parse(schedulePublish.params || '{}') : {};
  const scheduleUnpublishParams = scheduleUnpublish && scheduleUnpublish.params ? JSON.parse(scheduleUnpublish.params || '{}') : {};

  const [schedulePublishMethod, setSchedulePublishMethod] = React.useState(schedulePublishParams.method || 'product_status');
  const [schedulePublishInventory, setSchedulePublishInventory] = React.useState(schedulePublishParams.inventory);
  const [scheduleUnpublishMethod, setScheduleUnpublishMethod] = React.useState(scheduleUnpublishParams.method || 'product_status');

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={UPDATE_SCHEDULE_AVAILABILITY}
      onError={() => showToastError('Failed to update schedule')}
      onCompleted={() => {
        showToastNotice('Scheduled availability updated');
        onClose();
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(updateScheduledAvailability, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Schedule event product availability'
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              updateScheduledAvailability({
                variables: {
                  eventId: event.id,
                  publishDateType: schedulePublish ? schedulePublish.dateType : null,
                  publishRelativeMinutes: schedulePublish ? schedulePublish.relativeMinutes : null,
                  publishCustomDate: schedulePublish ? schedulePublish.customDate : null,
                  publishParams: JSON.stringify({
                    ...schedulePublishParams,
                    method: schedulePublishMethod,
                    inventory: schedulePublishInventory,
                  }),
                  publishCreateNew: schedulePublish ? schedulePublish.new : false,
                  unpublishDateType: scheduleUnpublish ? scheduleUnpublish.dateType : null,
                  unpublishRelativeMinutes: scheduleUnpublish ? scheduleUnpublish.relativeMinutes : null,
                  unpublishCustomDate: scheduleUnpublish ? scheduleUnpublish.customDate : null,
                  unpublishParams: JSON.stringify({
                    ...scheduleUnpublishParams,
                    method: scheduleUnpublishMethod,
                  }),
                  unpublishCreateNew: scheduleUnpublish ? scheduleUnpublish.new : false,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <Checkbox
                checked={schedulePublish}
                label='Schedule when the product is available for sale'
                onChange={(v) => {
                  setSchedulePublish(v ? { dateType: 'custom', new: true } : null)
                }}
                helpText='Select a date that you want to make it possible for customers to purchase this product'
              />
              <Collapsible open={schedulePublish} id="schedulePublishCollaps">
                <FormContainer>
                  <Stack vertical={true} spacing='tight'>
                    <ActionScheduleForm
                      event={event}
                      schedule={schedulePublish}
                      onUpdate={(newSchedule) => {
                        setSchedulePublish(newSchedule);
                      }}
                      disableRelativeOptions={event.dateType === 'recurring'}
                      extraInputs={(
                        <MethodContainer>
                          <FormLayout>
                            <ChoiceList
                              title="How should we make the product available?"
                              choices={[
                                { label: 'Make event product status active and visible on the storefront', value: 'product_status' },
                                { label: 'Set inventory of all ticket types', value: 'inventory', disabled: event.dateType === 'recurring' && schedulePublish && schedulePublish.dateType === 'custom' },
                              ]}
                              selected={[schedulePublishMethod]}
                              onChange={(v) => {
                                setSchedulePublishMethod(v[0])
                                setSchedulePublish({
                                  ...schedulePublish,
                                  params: JSON.stringify({ ...schedulePublishParams, method: v[0] }),
                                });
                              }}
                            />
                            {schedulePublishMethod === 'inventory' && !(event.dateType === 'recurring' && schedulePublish && schedulePublish.dateType === 'custom') &&
                              <TextField value={schedulePublishInventory && schedulePublishInventory.toString()} type="number" placeholder='Inventory' min={0} onChange={setSchedulePublishInventory} />
                            }
                            {schedulePublishMethod === 'inventory' &&
                              <Banner status='info'>If you do not want the tickets for sale until this date then you should manually set their inventory to zero.</Banner>
                            }
                          </FormLayout>
                        </MethodContainer>
                      )}
                    />
                  </Stack>
                </FormContainer>
              </Collapsible>
            </FormLayout>
          </Modal.Section>
          <Modal.Section>
            <FormLayout>
              <Checkbox
                checked={scheduleUnpublish}
                label=<span>Schedule when the product is <strong>not</strong> available for sale</span>
                onChange={(v) => setScheduleUnpublish(v ? { dateType: 'custom', new: true } : null)}
                helpText='Select a date that you want to remove the ability for customers to purchase this product'
              />
              <Collapsible open={scheduleUnpublish} id="scheduleUnpublishCollaps">
                <FormContainer>
                  <Stack vertical={true} spacing='tight'>
                    <ActionScheduleForm
                      event={event}
                      schedule={scheduleUnpublish}
                      onUpdate={(newSchedule) => {
                        setScheduleUnpublish(newSchedule);
                      }}
                      extraInputs={event.dateType === 'recurring' && scheduleUnpublish && scheduleUnpublish.dateType !== 'custom' ? undefined : (
                        <MethodContainer>
                          <FormLayout>
                            <ChoiceList
                              title="How should we make the product not available?"
                              choices={[
                                { label: 'Set the event product status to draft', value: 'product_status' },
                                { label: 'Set inventory to zero', value: 'inventory', disabled: event.dateType === 'recurring' },
                              ]}
                              selected={[scheduleUnpublishMethod]}
                              onChange={(v) => {
                                setScheduleUnpublishMethod(v[0])
                                setScheduleUnpublish({
                                  ...scheduleUnpublish,
                                  params: JSON.stringify({ ...scheduleUnpublishParams, method: v[0] }),
                                });
                              }}
                            />
                          </FormLayout>
                        </MethodContainer>
                      )}
                    />
                  </Stack>
                </FormContainer>
              </Collapsible>
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ScheduleAvailabilityModal;
