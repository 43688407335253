import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, Banner, FormLayout, TextField, ButtonGroup, Button, Link, TextStyle, Subheading, Stack, Select, Autocomplete } from '@shopify/polaris';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError, formatErrors, hasErrors } from '../../../utils/errors';
import { useFrame } from '../../../contexts/frame';
import { goToPage } from '../../../utils/routing';
import { parseBoolean } from '../../../utils/parse';
import LocationVenueForm from './LocationVenueForm';
import LocationTransitForm from './LocationTransitForm';

const EventLocationCard = ({ shop, event }) => {
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const [locationType, setLocationType] = React.useState(event.locationType);
  const [location, setLocation] = React.useState(event.location);
  const [locationUrl, setLocationUrl] = React.useState(event.locationUrl);
  const [locationCode, setLocationCode] = React.useState(event.locationCode);
  const [destination, setDestination] = React.useState(event.destination);
  const [destinationUrl, setDestinationUrl] = React.useState(event.destinationUrl);
  const [destinationCode, setDestinationCode] = React.useState(event.destinationCode);
  const [transitType, setTransitType] = React.useState(event.transitType || 'flight');
  const [locationId, setLocationId] = React.useState(event.locationId);
  const [destinationId, setDestinationId] = React.useState(event.destinationId);

  const { showToastNotice, showToastError, loadingMarkup } = useFrame();

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setErrors([]);
          setUnknownError(true);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editEvent) {
          setErrors([]);
          setUnknownError(false);
          showToastNotice('Event updated');
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables: {
                  eventId: event.id,
                  locationType,
                  location,
                  locationUrl,
                  locationCode,
                  destination,
                  destinationUrl,
                  destinationCode,
                  transitType,
                  locationId: locationId ? locationId : '0',
                  destinationId: destinationId ? destinationId : '0',
                }
              });
            },
          }}
        >
          {unknownError &&
            <Banner title="There was a problem updating your event" status="critical">
              Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
            </Banner>
          }
          {hasErrors(errors) &&
            <Banner title="There was a problem updating your event" status="critical">{formatErrors(errors)}</Banner>
          }
          {saving && loadingMarkup}
          <Stack vertical={true} spacing='loose'>
            <ButtonGroup segmented>
              <Button pressed={locationType == 'venue'} onClick={() => setLocationType('venue')}>Venue</Button>
              <Button pressed={locationType == 'online'} onClick={() => setLocationType('online')}>Online</Button>
              <Button pressed={locationType == 'transit'} onClick={() => setLocationType('transit')}>Origin / Destination</Button>
              <Button pressed={locationType == 'no-location'} onClick={() => setLocationType('no-location')}>No location yet</Button>
            </ButtonGroup>
            {locationType == 'online' &&
              <FormLayout>
                <TextStyle variation="subdued">Online events have unique virtual event pages where you can add links to livestreams and more.  Visit our help center to <Link external url="https://evey-events.zendesk.com/hc/en-us/">learn more</Link>.</TextStyle>
              </FormLayout>
            }
            {(locationType == 'venue') &&
              <LocationVenueForm
                shop={shop}
                event={event}
                locationType={locationType}
                name={location}
                setName={setLocation}
                url={locationUrl}
                setUrl={setLocationUrl}
                locationId={locationId}
                setLocationId={setLocationId}
              />
            }
            {(locationType == 'transit') &&
              <LocationTransitForm
                shop={shop}
                event={event}
                locationType={locationType}
                transitType={transitType}
                setTransitType={setTransitType}
                originName={location}
                setOriginName={setLocation}
                originUrl={locationUrl}
                setOriginUrl={setLocationUrl}
                originCode={locationCode}
                setOriginCode={setLocationCode}
                destinationName={destination}
                setDestinationName={setDestination}
                destinationUrl={destinationUrl}
                setDestinationUrl={setDestinationUrl}
                destinationCode={destinationCode}
                setDestinationCode={setDestinationCode}
                originId={locationId}
                setOriginId={setLocationId}
                destinationId={destinationId}
                setDestinationId={setDestinationId}
              />
            }
          </Stack>
        </Card>
      )}
    </Mutation>
  );
};

export default EventLocationCard;
