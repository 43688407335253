import React from 'react';
import { Banner, Layout, Page } from '@shopify/polaris';
import DeleteEventModal from '../DeleteEvent/DeleteEventModal';
import UnsubscribeEvent from '../UnsubscribeEvent/UnsubscribeEvent.jsx';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";

const InaccessibleEvent = ({ shop, event }) => {
  const [deleteClicked, setDeleteClicked] = React.useState(false);
  const [unsubscribeClicked, setUnsubscribeClicked] = React.useState(false);

  const { showToastNotice, showToastError, navigateTo } = useFrame();

  const history = useHistory();

  if (event.isApiEventMissing) {
    if (event.isShopifySubscription) {
      return (
        <div className="missing-api-event">
          <Page
            title="Inaccessible Event"
            breadcrumbs={[
              {
                content: 'Events',
                onAction: () => {
                  history.push('/events');
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              },
              {
                content: event.title,
                onAction: () => {
                  history.push(`/events/${event.id}/`);
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              }
            ]}
            primaryAction={{
              content: 'Create Event',
              onAction: () => {
                history.push('/events/new');
                window.scrollTo({ top: 0 });
              }
            }}
            secondaryActions={[
              {
                content: 'Back to Events',
                onAction: () => {
                  history.push('/events');
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              }
            ]}
          >
            <Layout>
              <Banner
                title="Unable to access event"
                status="critical"
                action={{
                  content: 'Cancel Subscription',
                  onAction: () => setUnsubscribeClicked(true),
                }}
              >
                <p>
                  This event is not accessible, if you&apos;ve deleted the Product in Shopify for this you can safely cancel the subscriptoion and delete this event now.
                </p>
              </Banner>
              {unsubscribeClicked &&
                <UnsubscribeEvent
                  event={event}
                  onConfirmRequest={(confirmUrl) => navigateTo({
                    history,
                    path: confirmUrl,
                    adminPath: true,
                    external: true,
                  })}
                  onComplete={() => {
                    showToastNotice('Successfully cancelled event subscription');
                    window.location.replace(`/events/${event.id}/edit`);
                  }}
                  onError={() => {
                    setUnsubscribeClicked(false);
                    showToastError('Error unsubscribing from event, please try again or contact support');
                  }}
                  onDismiss={() => setUnsubscribeClicked(false)}
                />
              }
            </Layout>
          </Page>
        </div>
      );
    } else {
      return (
        <div className="missing-api-event">
          <Page
            title="Inaccessible Event"
            breadcrumbs={[
              {
                content: 'Events',
                onAction: () => {
                  history.push('/events');
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              },
              {
                content: event.title,
                onAction: () => {
                  history.push(`/events/${event.id}/`);
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              }
            ]}
            primaryAction={{
              content: 'Create Event',
              onAction: () => {
                history.push('/events/new');
                window.scrollTo({ top: 0 });
              }
            }}
            secondaryActions={[
              {
                content: 'Back to Events',
                onAction: () => {
                  history.push('/events');
                  window.scrollTo({ top: 0 });
                },
                target: 'APP'
              }
            ]}
          >
            <Layout>
              <Banner
                title="Unable to access event"
                status="critical"
                action={{
                  content: 'Delete Event',
                  onAction: () => setDeleteClicked(true),
                }}
              >
                <p>
                  This event is not accessible, if you&apos;ve deleted the Product in Shopify for this you can safely delete this event now.
                </p>
              </Banner>
              {deleteClicked &&
                <DeleteEventModal
                  shop={shop}
                  event={event}
                  onComplete={() => {
                    showToastNotice('Event successfully deleted');
                    window.location.replace('/events');
                  }}
                  onError={() => setDeleteClicked(false)}
                  onDismiss={() => setDeleteClicked(false)}
                />
              }
            </Layout>
          </Page>
        </div>
      );
    }
  } else {
    return ('');
  }
};

export default InaccessibleEvent;
