import React from 'react';
import { Page, Layout, Card, TextField, FormLayout, Form, Button } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { useVendor } from '../../contexts/vendor';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';

const PageContainer = styled.div`
  .Polaris-Page {
    max-width:680px;
  }
`;

const ForgotPasswordPage = () => {
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { forgotPassword } = useVendor();

  const history = useHistory();
  const { showToastNotice, showToastError, currentShopDomain } = useFrame();

  return (
    <PageContainer>
      <Page
        title='Forgot your password'
        breadcrumbs={[
          {
            content: 'Login',
            onAction: () => goToPage({ history, path: '/vendor/login' }),
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Form
                  implicitSubmit={true}
                  onSubmit={async () => {
                    try {
                      setLoading(true);
                      const result = await forgotPassword(email, currentShopDomain || '');
                      if (result.error) {
                        showToastError(result.error);
                      } else {
                        showToastNotice(result.success);
                      }
                    } catch (error) {
                      showToastError('Failed to reset password');
                      throw error;
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  <FormLayout>
                    <TextField
                      label='Enter the email address of your account'
                      type='email'
                      value={email}
                      onChange={setEmail}
                    />
                    <Button
                      primary
                      submit
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </FormLayout>
                </Form>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </PageContainer>
  );
};

export default ForgotPasswordPage;
