import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, TextField, ChoiceList } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { SEND_NOTIFICATION_TEST } from '../../utils/graphql';

const SendTestEmailModal = ({ event, attendeeId, notificationType, onClose }) => {
  const [to, setTo] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = to && to.length;

  return (
    <Mutation
      mutation={SEND_NOTIFICATION_TEST}
      onError={() => {
        showToastError('Unable to send test');
        onClose();
      }}
      onCompleted={(data) => {
        if (data?.sendNotificationTest?.success) {
          showToastNotice('Test notification sent');
        } else {
          showToastError(data?.sendNotificationTest?.message || 'Failed to send test');
        }
        onClose();
      }}
    >
      {(sendNotificationTest, { loading }) => (
        <Modal
          open={true}
          onClose={onClose}
          title={'Send test email'}
          primaryAction={{
            content: 'Send it',
            disabled: !isValid,
            loading: loading,
            onAction: () => {
              sendNotificationTest({
                variables: {
                  eventId: event.id,
                  notificationType,
                  email: to,
                  phone: null,
                  attendeeId,
                }
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Close',
              disabled: loading,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={'Email'}
                value={to}
                placeholder={'test@example.com'}
                onChange={setTo}
                type={'email'}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default SendTestEmailModal;
