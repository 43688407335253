import React from 'react';
import { Card, TextField, ChoiceList, Stack, Banner, Collapsible, FormLayout } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { Mutation } from 'react-apollo';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError } from '../../../utils/errors';
import { parseBoolean } from '../../../utils/parse';

const CustomersCard = ({ shop, event }) => {
  const [createCustomers, setCreateCustomers] = React.useState([event.createCustomers]);
  const [customerEventTag, setCustomerEventTag] = React.useState(event.customerEventTag);
  const [customerPurchaserTag, setCustomerPurchaserTag] = React.useState(event.customerPurchaserTag);

  const { showToastNotice, showToastError } = useFrame();

  const hasCustomersScope = shop.hasCustomersScope;

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
        } else {
          reportError(error);
        }
        showToastError('Failed to save settings');
      }}
      onCompleted={(data) => showToastNotice('Event updated')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables:{
                  eventId: event.id,
                  createCustomers: parseBoolean(createCustomers[0]),
                  customerEventTag: customerEventTag,
                  customerPurchaserTag: customerPurchaserTag,
                }
              });
            },
          }}
        >
          <Stack vertical spacing='loose'>
            {!hasCustomersScope &&
              <Banner status="warning">We&apos;re missing an access permission from Shopify to use this. Please clear all cookies and log back into the app to grant this permission before using this. If there are issues, please contact us and we can help.</Banner>
            }
            <ChoiceList
              title="Customers"
              choices={[
                { label: 'Do not create customers in Shopify for attendees', value: false },
                { label: 'Create customers in Shopify for attendees', value: true },
              ]}
              selected={hasCustomersScope ? createCustomers : [false]}
              onChange={setCreateCustomers}
              disabled={!hasCustomersScope}
            />
            <Collapsible open={hasCustomersScope && createCustomers[0]} id="createCustomersCollapsible">
              <FormLayout>
                <TextField
                  value={customerEventTag || ''}
                  label={'The name of the tag we use for customers created for this event'}
                  onChange={setCustomerEventTag}
                  placeholder='Event'
                />
                <TextField
                  value={customerPurchaserTag || ''}
                  label={'The name of the tag we apply to the customer who purchased the tickets'}
                  onChange={setCustomerPurchaserTag}
                  placeholder='Purchaser'
                />
              </FormLayout>
            </Collapsible>
          </Stack>
        </Card>
      )}
    </Mutation>
  );
};

export default CustomersCard;
