import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, Banner, Button, Link as ShopifyLink, TextContainer, TextStyle, Layout, ChoiceList, Modal } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import { useFrame } from '../../contexts/frame';
import UserFilesCard from './UserFilesCard';
import { formatErrors, reportError } from '../../utils/errors';

const EVENT_FIELDS = `
  pdfTicket {
    id
    logoUrl
    logoFilename
    logoSize
    notes
    body
    pageWidth
    pageHeight
    defaultBody
    sharedEventsCount
  }
  files {
    id
    createdAt
    url
    filename
    size
    token
  }
`;

const EDIT_PDF_TICKET = gql`
  mutation editPdfTicket($eventId: ID!, $logo: Upload, $deleteLogo: Boolean, $notes: String, $body: String, $pageWidth: String, $pageHeight: String) {
    editPdfTicket(eventId: $eventId, logo: $logo, deleteLogo: $deleteLogo, notes: $notes, body: $body, pageWidth: $pageWidth, pageHeight: $pageHeight) {
      success
    }
  }
`;

const PdfTicketCode = ({ match }) => {
  const [body, setBody] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);
  const [customizeSelected, setCustomizeSelected] = React.useState([false]);

  const history = useHistory();
  const { showToastNotice, showToastError, loadingMarkup } = useFrame();

  const appHost = document.head.querySelector('[name=app-host]').content;

  return (
    <EventQuery
      eventId={match.params.eventId}
      extraEventAttributes={EVENT_FIELDS}
      onCompleted={(data) => {
        const shop = data.currentShop;
        const event = shop.event;
        const pdfTicket = event.pdfTicket;
        setBody(pdfTicket.body);
        setCustomizeSelected([pdfTicket.body && pdfTicket.body.length > 0 ? true : false]);
      }}
    >
      {({ shop, event }) => {
        const pdfTicket = event.pdfTicket;

        const displayableBody = (customizeSelected[0] ? body : pdfTicket.defaultBody) || pdfTicket.defaultBody;

        return (
          <EventPage
            shop={shop}
            event={event}
            title="Edit Code"
            extraBreadcrumbs={[
              {
                content: 'PDF Ticket',
                onAction: () => goToPage({ history, path: `/events/${event.id}/pdf_ticket` }),
                target: 'APP'
              }
            ]}
            secondaryActions={[
              {
                content: 'Back to Ticket Settings',
                onAction: () => goToPage({ history, path: `/events/${event.id}/pdf_ticket` }),
                target: 'APP'
              },
              {
                content: 'View Sample Ticket',
                external: true,
                target: 'REMOTE',
                url: `https://${appHost}/events/${event.id}/pdf/sample?shop=${shop.domain}&shop_id=${shop.remoteId}`,
              }
            ]}
          >
            <Layout>
              <Layout.Section>
                <Mutation mutation={EDIT_PDF_TICKET}
                  onError={(error) => {
                    if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
                      setErrors(error.graphQLErrors[0].errors);
                      setUnknownError(false);
                    } else {
                      setErrors([]);
                      setUnknownError(true);
                      reportError(error);
                    }
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  onCompleted={(data) => {
                    if (data && data.editPdfTicket) {
                      showToastNotice('PDF Ticket updated');
                      setErrors([]);
                      setUnknownError(false);
                    }
                  }}
                  refetchQueries={() => ['EventQuery']}
                >
                  {(editPdfTicket, { loading }) => {
                    return (
                      <div>
                        {unknownError &&
                        <Banner
                          title="There was a problem updating the PDF ticket code"
                          status="critical"
                        >
                          <p>
                            Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                          </p>
                        </Banner>
                        }
                        {errors && errors.length > 0 &&
                        <Banner
                          title="There was a problem updating the PDF ticket code"
                          status="critical"
                        >
                          {formatErrors(errors)}
                        </Banner>
                        }
                        {loading && loadingMarkup}
                        <TextContainer spacing="loose">
                          <p>
                              If you want more control over the look of your PDF tickets you can update the template below.
                              It uses the liquid templating language with HTML so you can customize as much as you want while using event variables.
                              Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink> about customizing
                              this ticket template and a detailed list of liquid variables available to you.
                          </p>
                        </TextContainer>
                        <Card sectioned
                          title="PDF Ticket HTML/Liquid Template"
                          primaryFooterAction={{
                            content: 'Save',
                            loading: loading,
                            onAction: () => {
                              editPdfTicket({
                                variables:{
                                  eventId: event.id,
                                  logo: null,
                                  deleteLogo: false,
                                  notes: pdfTicket.notes,
                                  pageWidth: pdfTicket.pageWidth,
                                  pageHeight: pdfTicket.pageHeight,
                                  body: customizeSelected[0] ? body : null,
                                }
                              });
                            },
                          }}
                        >
                          <ChoiceList
                            title=''
                            choices={[
                              { label: 'Use default ticket template', value: false },
                              { label: 'Customize ticket template', value: true }
                            ]}
                            selected={customizeSelected}
                            onChange={setCustomizeSelected}
                          />
                          <div className="TicketEmailEdit__AceEditor" style={{position: 'relative'}}>
                            {!customizeSelected[0] &&
                              <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#5c5c5c',
                                opacity: '0.05',
                                zIndex: '1000'
                              }}></div>
                            }
                            <AceEditor
                              mode="liquid"
                              theme="tomorrow"
                              onChange={setBody}
                              value={displayableBody || ''}
                              name="pdfTicketBody"
                              fontSize='1.4rem'
                              width='100%'
                              height='500px'
                              showPrintMargin={false}
                              showGutter={false}
                              highlightActiveLine={true}
                              setOptions={{
                                showLineNumbers: false,
                                tabSize: 2,
                                fontSize: 'inherit',
                              }}
                              editorProps={{$blockScrolling: true}}
                              readOnly={!(customizeSelected[0])}
                              focus={customizeSelected[0]}
                            />
                          </div>
                        </Card>
                      </div>
                    );
                  }}
                </Mutation>
              </Layout.Section>
            </Layout>
          </EventPage>
        );
      }}
    </EventQuery>
  );
}

export default PdfTicketCode;
