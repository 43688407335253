import React from 'react';
import { Query } from 'react-apollo';
import { Banner, Link as ShopifyLink, TextContainer } from '@shopify/polaris';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import { GET_ACTIVE_SUBSCRIPTION } from '../../utils/graphql';
import { hasNewPricing } from '../../utils/features';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const  TrialBanner = ({ backToPath, backToLabel }) => {
  const history = useHistory();

  return (
    <Query query={GET_ACTIVE_SUBSCRIPTION} >
      {({ loading, data }) => {
        if (loading || !data) {
          return '';
        }

        const shop = data.currentShop;
        const activeSubscription = shop.activeSubscription;

        const newPricingEnabled = hasNewPricing(shop.domain);

        if (!newPricingEnabled) {
          return '';
        }

        if (activeSubscription) {
          return '';
        }

        if (shop.hasLegacyPricing) {
          return '';
        }

        if (shop.developmentPlan) {
          return (
            <Banner status='info' title='Try the app as long as you need'>
              The app is free with access to all features from a development shop. Reach out to us if you have any questions!
            </Banner>
          )
        }

        return (
          <Banner
            title={shop.trialExpired ? 'Your free trial has expired' : "Select a plan when you're ready to launch"}
            status={shop.trialExpired ? 'critical' : 'info'}
            action={{
              content: 'Select a plan',
              onClick: () =>  goToPage({
                history,
                path: '/plans',
                params: {
                  ...(backToPath ? { nextUrl: backToPath } : {}),
                  ...(backToLabel ? { nextLabel: backToLabel } : {}),
                }}),
            }}
          >
          {!shop.trialExpired ? (
            <TextContainer>
              You're still on our free trial, which will expire {dayjs(shop.trialExpiresAt).fromNow()}. After this, your event ticketing features will no longer function.
            </TextContainer>
          ) : (
            <TextContainer>
              Your free trial has expired, which means ticket sales and other features will no longer function. Select a plan to continue selling tickets.
            </TextContainer>
          )}
          </Banner>
        );
      }}
    </Query>
  );
};

export default TrialBanner;
