import React from 'react';
import { Card, ResourceList, EmptyState } from '@shopify/polaris';
import SeatingChartResourceItem from './SeatingChartResourceItem';
import AddSeatingChartModal from './AddSeatingChartModal';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import UpgradeRequiredBanner from '../../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../../utils/features';

const SeatingChartsCard = ({ shop, seatingCharts }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);

  const hasSeatingFeature = hasPlanFeature({ shop, feature: 'seating' });

  const history = useHistory();

  return (
    <Card
      sectioned
      actions={[
        {
          content: 'Add seating plan',
          onAction: () => setShowAddModal(true),
        },
      ]}
    >
      {!hasSeatingFeature &&
        <UpgradeRequiredBanner message='Reserved seating is not available on this plan, upgrade to get full access to reserved seating features.' />
      }
      <ResourceList
        emptyState={!seatingCharts.length ? (
          <EmptyState
            heading="No seating plans yet"
          >
            <p>
              All seating plans will appear here.
            </p>
          </EmptyState>
        ) : undefined}
        items={seatingCharts}
        renderItem={(seatingChart) => (
          <SeatingChartResourceItem
            seatingChart={seatingChart}
            onSelect={(v) => goToPage({ history, path: `/settings/seating_charts/${seatingChart.id}` })}
          />
        )}
        resourceName={{singular: 'seating plan', plural: 'seating plans'}}
      />
      {showAddModal &&
        <AddSeatingChartModal
          shop={shop}
          onClose={() => setShowAddModal(false)}
          onCompleted={(newSeatingChart) => {
            setShowAddModal(false);
            goToPage({ history, path: `/settings/seating_charts/${newSeatingChart.id}` });
          }}
        />
      }
    </Card>
  );
};

export default SeatingChartsCard;
