import styled from '@emotion/styled';

export const FullHeightContainer = styled.div`
  min-height: 100vh;
  padding-bottom: 80px;
  ${({ alignVertically }) =>
    alignVertically
      ? 'display: flex; align-items: center; justify-content: center;'
      : ''};
`;

export const NestedFormLayoutContainer = styled.div`
  ${({ topFlush }) => topFlush ? 'margin-top:0;' : 'margin-top:2em;'};
  margin-left:1em;
`;
