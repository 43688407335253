import React from 'react';
import { Stack, Button } from '@shopify/polaris';
import styled from '@emotion/styled';

const Container = styled.div`
  padding-left:20px;
  padding-right:20px;
  padding-bottom:10px;
`;

const CardFooter = ({ left, primaryAction, secondaryAction }) => {
  return (
    <Container>
      <Stack distribution='fill' alignment='center'>
        {left}
        <Stack vertical={false} spacing='tight' alignment='center' distribution='trailing'>
          {secondaryAction &&
            <Button
              onClick={secondaryAction.onAction}
            >
              {secondaryAction.content}
            </Button>
          }
          {primaryAction &&
            <Button
              primary
              onClick={primaryAction.onAction}
            >
              {primaryAction.content}
            </Button>
          }
        </Stack>
      </Stack>
    </Container>
  );
};

export default CardFooter;
