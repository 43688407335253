import React from 'react';
import { Icon, Button, Collapsible, TextContainer, Popover, ActionList, FormLayout, Link as ShopifyLink, Banner, Modal, ChoiceList } from '@shopify/polaris';
import styled from '@emotion/styled';
import TemplateEditorSetting from './TemplateEditorSetting';
import { HeaderMajor, TextMajor, SectionMajor, DragHandleMinor, HideMinor, ViewMajor, ArrowLeftMinor, ThemesMajor, SettingsMinor, ChevronRightMinor, ChevronDownMinor, CirclePlusMinor, TextAlignmentLeftMajor, ImageMajor, CodeMajor, Columns2Major, DeleteMajor, ResetMinor, MinusMinor, SelectMinor } from '@shopify/polaris-icons';
import { ReactSortable } from "react-sortablejs";
import { uuidv4 } from '../../utils/random';
import { useLocation } from "react-router-dom";
import { getHostFromShopDomain } from '../../utils/auth';
import TemplateEditorString from './TemplateEditorString';

const iconForPrebuiltBlock = (pb) => {
  if (pb.name == 'text') {
    return TextAlignmentLeftMajor;
  } else if (pb.name == 'image') {
    return ImageMajor;
  } else if (pb.name == 'custom') {
    return CodeMajor;
  } else if (pb.name == 'multi-column') {
    return Columns2Major;
  } else if (pb.name == 'separator') {
    return MinusMinor;
  } else if (pb.name == 'spacer') {
    return SelectMinor;
  } else {
    return CodeMajor;
  }
};

const Container = styled.div`
  min-width:301px;
  max-width:301px;
  width:301px;
  position:relative;
  /* height:100%; */
  height: calc(100vh - 58px);
  background-color: var(--p-surface);
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  overflow:auto;
  display:flex;
  flex-direction:column;
`;

const TemplateHeading = styled.h1`
  padding:1.2rem;
  border-bottom: 0.1rem solid #dfe3e8;
  margin-left:5px;
  margin-right:5px;

  font-size:1.6rem;
  font-weight:600;
  line-height:2.4rem;

  h1 {
    text-transform: capitalize;
    padding:0 15px;
  }
`;

const BlockHeading = styled.div`
  display:flex;
  align-items:center;
  padding:1.2rem;
  border-bottom: 0.1rem solid #dfe3e8;
  margin-left:5px;
  margin-right:5px;

  font-size:1.6rem;
  font-weight:600;
  line-height:2.4rem;

  .Polaris-Icon {
    display:flex;
    align-items:center;
    width:15px;
    height:15px;
  }
  .Polaris-Button {
    border:none;
  }

  h1 {
    flex:1;
    text-transform: capitalize;
    padding:0 10px;
  }
`;

const SettingsSection = styled.div`
  padding:1.2rem;
  border-bottom: 0.1rem solid #dfe3e8;
  margin-left:5px;
  margin-right:5px;

  h2 {
    font-size:1.4rem;
    font-weight:600;
    line-height:2.4rem;
    margin-left:10px;
    margin-bottom:10px;
  }
`;

const BlockItem = styled.div`
  display:flex;
  align-items:center;

  h2 {
    font-size:1.4rem;
    font-weight:600;
    line-height:2.4rem;
    margin-left:10px;
    margin-bottom:0;

    .Polaris-Button {
      border:none;
    }
    .Polaris-Button:hover {
      color: var(--p-text,#454f5b);
      text-decoration: none;
    }
    .Polaris-Button__Text {
      text-transform: capitalize;
    }
  }
  h2.disabled {
    .Polaris-Button__Text {
      color: var(--p-text-subdued);
    }
  }
  .Polaris-Icon {
    margin:0;
  }
`;
const BlockItemActions = styled.div`
  display:flex;
  flex:1;
  justify-content:flex-end;

  &.delete {
    path {
      fill:red !important;
    }
  }
  &.action {
    path {
      fill:var(--p-interactive) !important;
    }
  }

  .Polaris-Icon {
    display:flex;
    align-items:center;
    width:15px;
    height:15px;
  }
  .Polaris-Button {
    border:none;
  }
`;

const NavItem = styled.div`
  h2 {
    font-size:1.4rem;
    font-weight:600;
    line-height:2.4rem;
    margin-left:10px;
    margin-bottom:0;
  }
  .Polaris-Button {
    border:none;
    width:100%;
  }
  .Polaris-Button__Content {
    width:100%;
  }
  .Polaris-Button__Content .Polaris-Button__Text {
    display:flex;
    align-items:center;
    width:100%;
  }
  .Polaris-Button:hover {
    color: var(--p-text,#454f5b);
    text-decoration: none;
  }
  .Polaris-Icon {
    margin:0;
  }
`;

const AddBlockContainer = styled.div`
  margin-left:0;

  .Polaris-Button {
    display:flex;
    align-items:center;
  }
  .Polaris-Button__Text {
    color: var(--p-interactive);
    font-weight:400;
  }

  .Polaris-Icon {
    path {
      fill: var(--p-interactive) !important;
    }
  }
`;

const AddBlocksListContainer = styled.div`
  min-width:250px;
`;

const LegacyConvertBannerContainer = styled.div`
  margin:10px;
  .Polaris-Banner__Ribbon {
    display:none;
  }
`;

const MainNavItem = ({ onClick, icon, label, open }) => {

  return (
    <SettingsSection>
      <NavItem>
        <Button
          plain
          onClick={onClick}
        >
          <Icon source={icon} color='base' />
          <h2>
            {label}
          </h2>
          <BlockItemActions>
            <Icon source={open ? ChevronDownMinor : ChevronRightMinor} color='base' />
          </BlockItemActions>
        </Button>
      </NavItem>
    </SettingsSection>
  );
};

const BlockNavItem = ({ blockName, block, settings, onChange, onClick, isStatic }) => {
  console.log(`BlockNavItem: `, block);
  const disabled = settings.blocks && settings.blocks[blockName] && settings.blocks[blockName].disabled;

  return (
    <SettingsSection>
      <BlockItem>
        <Icon source={isStatic ? HeaderMajor : SectionMajor} color={disabled ? 'subdued' : "base"} />
        <h2 className={disabled ? 'disabled' : ''} style={disabled ? { color: 'var(--p-text-subdued)' } : {}}>
          <Button
            plain
            onClick={onClick}
          >
            {block.schema?.name || makeTitle(block.name)}
          </Button>
        </h2>
        <BlockItemActions>
          <Button
            size='slim'
            outline
            onClick={() => {
              onChange({
                ...settings,
                blocks: {
                  ...(settings.blocks || {}),
                  [blockName]: {
                    ...((settings.blocks || {})[blockName]),
                    disabled: !(settings.blocks || {})[blockName]?.disabled,
                  }
                }
              });
            }}
          >
            <Icon source={disabled ? HideMinor : ViewMajor} color={disabled ? 'subdued' : "base"} />
          </Button>
          {!isStatic &&
            <div className='drag-handler'>
              <Button
                size='slim'
                outline
              >
                <Icon source={DragHandleMinor} color={disabled ? 'subdued' : "base"} />
              </Button>
            </div>
          }
        </BlockItemActions>
      </BlockItem>
    </SettingsSection>
  );
};

const makeTitle = (slug) => slug.toString().toLowerCase().replace(/[^\w-_]+/g,'').replace(/-/g,' ').replace(/_/g,' ');

const TemplateEditorBlockConfig = ({ shop, templateBlock, blocksCache, title, settings, strings, onChange, onStringsChange, prebuiltBlocks, preview, previewLoading, onUseDefault }) => {
  const [stack, setStack] = React.useState([]);
  const [layoutOpen, setLayoutOpen] = React.useState(true);
  const [settingsOpen, setSettingsOpen] = React.useState(true);
  const [stringsOpen, setStringsOpen] = React.useState(false);
  const [addBlockPopoverActive, setAddBlockPopoverActive] = React.useState(false);

  console.log('stack: ', stack);
  console.log(`blocksCache: `, blocksCache);

  const findBlock = (type) => blocksCache[type] || { name: type, type, schema: {}, blocks: [], max_blocks: 0 };

  const topBlockName = stack.length > 0 ? stack[stack.length - 1] : 'template';

  let block = templateBlock;
  let blockSchema = block;
  let blockSettings = settings || {};
  let blocks = (blockSchema?.blocks || []).map((name) => findBlock(blockSettings.blocks && blockSettings.blocks[name]?.type || name));
  for (let index = 0; index < stack.length; index++) {
    blockSettings = blockSettings && (blockSettings.blocks || {})[stack[index]] || {};
    block = findBlock(blockSettings.type || stack[index]);
    blockSchema = block.schema;
    blocks = (blockSchema?.blocks || []).map((type) => findBlock(type));
  }

  // find static blocks
  let staticBlocks = [];
  const staticBlockRe = /\{%[\s]+render_block [\'\"]([a-zA-Z0-9-_\/]+)[\'\"][\s]+%\}/gm;
  let staticBlockMatches;
  do {
    staticBlockMatches = staticBlockRe.exec(block.body || '');
    if (staticBlockMatches) {
      staticBlocks.push(staticBlockMatches[1]);
    }
  } while (staticBlockMatches);
  console.log(`staticBlocks: `, staticBlocks);

  console.log(`block: `, block);
  console.log(`blockSettings: `, blockSettings);
  console.log(`blocks: `, blocks);
  console.log(`schema: `, blockSchema);

  const blockOrder = block.legacy ? [] : blockSettings.block_order || blockSchema?.blocks || [];
  console.log(`blockOrder: `, blockOrder);

  const addBlockAllowed = blockSchema && blockSchema.type && blockSchema.max_blocks == null ? true : blockOrder.length < blockSchema.max_blocks;

  const onSettingsChange = (v) => {
    let newSettings = { ...settings };

    console.log(`[onSettingsChange] blockSettings ${topBlockName}: `, blockSettings);
    let tmpBlockSettings = newSettings;
    if (topBlockName !== 'template') {
      const buildBlockSettings = (bs, stackIndex) => {
        console.log(`${stackIndex}. bs && (bs.blocks || {})[stack[stackIndex]] || {}: `, bs && (bs.blocks || {})[stack[stackIndex]] || {});
        if (stackIndex < stack.length - 1) {
          return {
            ...bs,
            blocks: {
              ...(bs.blocks || {}),
              [stack[stackIndex]]: buildBlockSettings(bs && (bs.blocks || {})[stack[stackIndex]] || {}, stackIndex + 1),
            },
          };
        } else {
          return {
            ...bs,
            blocks: {
              ...(bs.blocks || {}),
              [stack[stackIndex]]: v,
            },
          };
        }
      }
      newSettings = buildBlockSettings(newSettings, 0);
      // for (let index = 0; index < stack.length-1; index++) {
      //   tmpBlockSettings = tmpBlockSettings && (tmpBlockSettings.blocks || {})[stack[index]] || {};
      // }
      // if (!tmpBlockSettings.blocks) {
      //   tmpBlockSettings.blocks = {};
      // }
      // console.log(`tmpBlockSettings: `, tmpBlockSettings)
      // console.log(`stack[stack.length - 1]: `, stack[stack.length - 1])
      // console.log(`tmpBlockSettings.blocks: `, tmpBlockSettings.blocks)
      // console.log(`tmpBlockSettings.blocks[stack[stack.length - 1]]: `, tmpBlockSettings.blocks[stack[stack.length - 1]])
      // debugger
      // tmpBlockSettings.blocks[stack[stack.length - 1]] = v;
    } else {
      newSettings = v;
    }

    console.log(`newSettings: `, newSettings);
    onChange(newSettings);
  };

  const drawPreviewOverlay = () => {
    const frameDocument = document.getElementById('PreviewFrame')?.contentDocument;
    const existingOverlay = frameDocument?.getElementById('preview-block-overlay');
    if (existingOverlay) {
      existingOverlay.parentNode.removeChild(existingOverlay);
    }

    if (stack.length > 0) {
      const blockElement = frameDocument.getElementById(`block-container--${stack[stack.length-1]}`);
      if (blockElement) {
        blockElement.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});

        // overlay
        var overlay = frameDocument.createElement('div');
        overlay.id = 'preview-block-overlay';
        overlay.style.setProperty('position', 'absolute', 'important');
        overlay.style.setProperty('display', 'inline-block', 'important');
        overlay.style.setProperty('top', blockElement.offsetTop + 'px', 'important');
        overlay.style.setProperty('left', blockElement.offsetLeft + 'px', 'important');
        overlay.style.setProperty('width', blockElement.offsetWidth + 'px', 'important');
        overlay.style.setProperty('height', blockElement.offsetHeight + 'px', 'important');
        overlay.style.setProperty('z-index', '100', 'important');
        overlay.style.setProperty('background-color', 'transparent', 'important');
        overlay.style.setProperty('cursor', 'pointer', 'important');
        overlay.style.setProperty('margin', '0', 'important');
        overlay.style.setProperty('border', '3px solid rgba(80, 181, 255, 0.2)');
        overlay.style.setProperty('box-shadow', '0px 0px 9px 3px rgba(80, 181, 255, 0.1)');
        overlay.style.setProperty('border-radius', '4px');
        blockElement.parentNode.append(overlay);
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => drawPreviewOverlay(), 100);
  }, [stack, settings, preview, previewLoading]);
  React.useEffect(() => {
    drawPreviewOverlay();
  }, []);

  const handleStackChange = (v) => {
    setStack(v);
  }

  const stringFromId = (id) => {
    const parts = id.split('.');
    let result = { ...strings };
    for (let i = 0; i < parts.length; i++) {
      result = result && result[parts[i]];
    }
    return result;
  };

  const location = useLocation();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <Container>
      {stack.length > 0 ? (
        <BlockHeading>
          {stack.length > 0 &&
            <Button
              outline
              onClick={() => {
                let tmp = [...stack];
                tmp.pop();
                handleStackChange(tmp);
              }}
            >
              <Icon source={ArrowLeftMinor} color='base' />
            </Button>
          }
          <h1>{blockSchema?.name || makeTitle(block.name)}</h1>
        </BlockHeading>
      ) : (
        <TemplateHeading><h1>{title}</h1></TemplateHeading>
      )}

      <MainNavItem
        label='Layout'
        onClick={() => setLayoutOpen(!layoutOpen)}
        icon={ThemesMajor}
        open={layoutOpen}
      />
      <Collapsible open={layoutOpen}>
        {staticBlocks.length === 0 && blockOrder.length === 0 ? (
            <SettingsSection><TextContainer>No layout blocks available.</TextContainer></SettingsSection>
        ) : (
          <>
            {staticBlocks.map((key) => (
              <BlockNavItem
                key={key}
                blockName={key}
                block={findBlock(blockSettings.blocks && blockSettings.blocks[key]?.type || key)}
                settings={blockSettings}
                onChange={(v) => {
                  onSettingsChange(v);
                }}
                onClick={() => {
                  handleStackChange([ ...stack, key ]);
                }}
                isStatic={true}
              />
            ))}
            <ReactSortable
              list={blockOrder.map((b) => ({ id: b }))}
              setList={(v) => {
                console.log(`setList: `, v);
                onSettingsChange({
                  ...blockSettings,
                  block_order: v.map((b) => b.id),
                });
              }}
              animation={150}
              ghostClass='TemplateEditorBlocksSortableGhost'
              handle='.drag-handler'
            >
              {blockOrder.map((key) => (
                <BlockNavItem
                  key={key}
                  blockName={key}
                  block={findBlock(blockSettings.blocks && blockSettings.blocks[key]?.type || key)}
                  settings={blockSettings}
                  onChange={(v) => {
                    onSettingsChange(v);
                  }}
                  onClick={() => {
                    handleStackChange([ ...stack, key ]);
                  }}
                />
              ))}
            </ReactSortable>
          </>
        )}
        {blockSchema?.legacy &&
          <LegacyConvertBannerContainer>
            <Banner
              status='default'
              action={{
                content: 'Preview upgrade',
                onAction: () => {
                  onUseDefault();
                },
              }}
            >
              Layout blocks allow no-code customizations to your template.
              This template version does not support layout blocks.
            </Banner>
          </LegacyConvertBannerContainer>
        }
        {addBlockAllowed &&
          <SettingsSection>
            <AddBlockContainer>
              <Popover
                active={addBlockPopoverActive}
                activator={(
                  <Button
                    plain
                    icon={<Icon source={CirclePlusMinor} color='interactive' />}
                    onClick={() => setAddBlockPopoverActive(!addBlockPopoverActive)}
                  >
                   Add block
                  </Button>
                )}
                onClose={() => setAddBlockPopoverActive(false)}
              >
                <AddBlocksListContainer>
                  <ActionList
                    sections={[{
                      title: 'Layout blocks',
                      items: [
                        ...(prebuiltBlocks || []).map((pb) => ({
                          content: pb.schema.name,
                          icon: iconForPrebuiltBlock(pb),
                          onAction: () => {
                            const uuid = uuidv4();
                            onSettingsChange({
                              ...blockSettings,
                              blocks: {
                                ...blockSettings.blocks,
                                [uuid]: {
                                  type: pb.schema.type,
                                  settings: {

                                  },
                                },
                              },
                              block_order: [ ...blockOrder, uuid ],
                            });
                            setAddBlockPopoverActive(false);
                            setTimeout(() => handleStackChange([ ...stack, uuid ]), 100);
                          },
                        })),
                      ]
                    }]}
                  />
                </AddBlocksListContainer>
              </Popover>
            </AddBlockContainer>
          </SettingsSection>
        }
      </Collapsible>

      <MainNavItem
        label='Settings'
        onClick={() => setSettingsOpen(!settingsOpen)}
        icon={SettingsMinor}
        open={settingsOpen}
      />
      <Collapsible open={settingsOpen}>
        <SettingsSection>
        {(blockSchema?.settings || []).length == 0 ? (
          <TextContainer>No customizable settings available.</TextContainer>
        ) : (
          <FormLayout>
            {(blockSchema?.settings || []).map((setting) => (
              <TemplateEditorSetting
                key={`${block.name}-${setting.id}`}
                shop={shop}
                setting={setting}
                value={(blockSettings.settings || {})[setting.id]}
                onChange={(v) => {
                  console.log(`[TemplateEditorSetting] on change: `, v)
                  onSettingsChange({
                    ...blockSettings,
                    settings: {
                      ...(blockSettings.settings || {}),
                      [setting.id]: v,
                    },
                  });
                }}
              />
            ))}
          </FormLayout>
        )}
        </SettingsSection>
      </Collapsible>

      <MainNavItem
        label='Text'
        onClick={() => setStringsOpen(!stringsOpen)}
        icon={TextMajor}
        open={stringsOpen}
      />
      <Collapsible open={stringsOpen}>
        {(blockSchema?.strings || []).length == 0 ? (
          <SettingsSection>
            <TextContainer>No customizable static text available.</TextContainer>
          </SettingsSection>
        ) : (
          <>
            <SettingsSection>
              <TextContainer>
                Translation strings for your default locale (<strong>{shop.defaultLocaleFile.locale}</strong>).
                Go to the <ShopifyLink external url={`/settings/translations${searchParams}`} target="_blank">Translation Manager</ShopifyLink> for more detailed settings.
              </TextContainer>
            </SettingsSection>
            <SettingsSection>
              {(blockSchema?.strings || []).map((string) => (
                <TemplateEditorString
                  string={string}
                  value={stringFromId(string.id)}
                  onChange={(v) => {
                    const parts = string.id.split('.');
                    if (parts.length == 3) {
                      onStringsChange({
                        ...strings,
                        [parts[0]]: {
                          ...strings[parts[0]],
                          [parts[1]]: {
                            ...strings[parts[0]][parts[1]],
                            [parts[2]]: v,
                          },
                        },
                      });
                    }
                  }}
                />
              ))}
            </SettingsSection>
          </>
        )}
      </Collapsible>

      {topBlockName == 'template' &&
        <SettingsSection>
          <NavItem>
            <Button
              plain
              onClick={() => {
                onChange({});
                // const resetBlockSettings = (bs) => {
                //   if (bs.blocks) {
                //     for (let i = 0; i < Object.keys(bs.blocks).length; i++) {
                //       const key = Object.keys(bs.blocks)[i];
                //       bs.blocks[key] = resetBlockSettings(bs.blocks[key]);
                //     }
                //   }
                //   return {
                //     ...bs,
                //     settings: {},
                //   };
                // }
                // const newSettings = resetBlockSettings({ ...settings });
                // onChange(newSettings);
              }}
            >
              <h2 style={{color:'var(--p-interactive)'}}>Reset to default</h2>
              <BlockItemActions className='action'>
                <Icon source={ResetMinor} color='interactive' />
              </BlockItemActions>
            </Button>
          </NavItem>
        </SettingsSection>
      }

      {['text', 'image', 'custom', 'multi-column', 'multi-column-text'].includes(block.type) &&
        <SettingsSection>
          <NavItem>
            <Button
              plain
              onClick={() => {
                const blockName = stack[stack.length - 1];
                handleStackChange([ ...stack.filter((n) => n != blockName) ]);

                // let newSettings = { ...settings };
                // let tmpBlockSettings = newSettings;
                // for (let index = 0; index < stack.length-1; index++) {
                //   tmpBlockSettings = tmpBlockSettings && (tmpBlockSettings.blocks || {})[stack[index]] || {};
                // }
                // const index = tmpBlockSettings.block_order.indexOf(block.name);
                // if (index) {
                //   tmpBlockSettings.block_order.splice(index, 1);
                // }
                // delete tmpBlockSettings.blocks[block.name];


                console.log(`remove block: `, blockName);
                let newSettings = { ...settings };
                const buildBlockSettings = (bs, stackIndex) => {
                  console.log(`${stackIndex}. bs && (bs.blocks || {})[stack[stackIndex]] || {}: `, bs && (bs.blocks || {})[stack[stackIndex]] || {});
                  if (stackIndex < stack.length - 1) {
                    return {
                      ...bs,
                      blocks: {
                        ...(bs.blocks || {}),
                        [stack[stackIndex]]: buildBlockSettings(bs && (bs.blocks || {})[stack[stackIndex]] || {}, stackIndex + 1),
                      },
                    };
                  } else {
                    console.log(`bs.block_order: `, bs.block_order);
                    const index = bs.block_order.indexOf(blockName);
                    console.log(`removing at index: ${index}`);
                    const tmp = { ...bs };
                    console.log(`tmp: `, tmp);
                    tmp.block_order.splice(index, 1);
                    delete tmp.blocks[blockName];
                    return tmp;
                  }
                }
                newSettings = buildBlockSettings(newSettings, 0);

                onChange(newSettings);
              }}
            >
              {/*<Icon source={icon} color='base' />*/}
              <h2 style={{color:'red'}}>Remove block</h2>
              <BlockItemActions className='delete'>
                <Icon source={DeleteMajor} color='critical' />
              </BlockItemActions>
            </Button>
          </NavItem>
        </SettingsSection>
      }
    </Container>
  );
};

export default TemplateEditorBlockConfig;
