import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Layout, Card, TextContainer, TextStyle, Link as ShopifyLink, DataTable, Button, ButtonGroup, SkeletonDisplayText, SkeletonBodyText, Badge, Stack, Spinner, Select, FormLayout } from '@shopify/polaris';
import { GET_ATTENDEE_ATTRIBUTE_SPECS, EDIT_EVENT } from '../../utils/graphql';
import AttendeeInfoCard from '../AttendeeInfo/AttendeeInfoCard';
import CardLoading from '../shared/CardLoading.jsx';
import { useHistory, useLocation } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';
import AttendeeInfoSpecsTable from '../AttendeeInfo/AttendeeInfoSpecsTable';
import { getHostFromShopDomain } from '../../utils/auth';

const EventAttendeeInfo = ({ shop, event }) => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('custom');
  const [ticketTypeOverrides, setTicketTypeOverrides] = React.useState({});

  const { showToastNotice, showToastError, isVendor } = useFrame();
  const history = useHistory();
  const location = useLocation();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  console.log('ticketTypeOverrides: ', ticketTypeOverrides);

  return (
    <Query
      query={GET_ATTENDEE_ATTRIBUTE_SPECS}
      variables={{ eventId: event.id }}
      onCompleted={(data) => {
        setSelectedTemplateId(data?.currentShop?.event?.sharedAttendeeFieldsTemplate?.id || 'custom');
        console.log(`data: `, data);
        setTicketTypeOverrides(Object.fromEntries((data?.currentShop?.event?.tickets || []).map((ticket) => [ticket.id, ticket.attendeeFieldsTemplateId && ticket.attendeeFieldsTemplateId.toString() || null])));
      }}
    >
      {({ loading, data }) => {
        console.log('data: ', data);
        const attendeeInfoStorefrontEnabled = data && data.currentShop && data.currentShop.attendeeInfoStorefrontEnabled;
        const specs = data && data.currentShop && data.currentShop.event.attendeeAttributeSpecs;
        const templates = data?.currentShop?.attendeeFieldsTemplates;
        const tickets = data?.currentShop?.event?.tickets || [];

        return (
          <Layout>
            {templates && templates.length > 0 &&
              <Layout.AnnotatedSection
                title="Attendee information templates"
                description={<div>
                  <p>Select a shared template or configure the custom questions just for this event.</p>
                  {!isVendor && <p>Manage templates in the <ShopifyLink external url={`/settings/attendee_info${searchParams}`} target="_blank">attendee information settings</ShopifyLink> area.</p>}
                </div>}
              >
                {loading ? (
                  <CardLoading />
                ) : (
                  <Mutation mutation={EDIT_EVENT}
                    onError={(error) => showToastError('Failed to save settings')}
                    onCompleted={(data) => showToastNotice('Event updated')}
                    refetchQueries={() => ['EventQuery']}
                  >
                    {(editEvent, { loading: saving }) => (
                      <Card
                        primaryFooterAction={{
                          content: 'Save',
                          loading: saving,
                          onAction: () => {
                            editEvent({
                              variables:{
                                eventId: event.id,
                                sharedAttendeeFieldsTemplateId: selectedTemplateId == 'custom' ? null : selectedTemplateId,
                                tickets: selectedTemplateId == 'custom' ?
                                  JSON.stringify(tickets.map((t) => ({
                                    id: t.id,
                                    attendee_fields_template_id: null,
                                  })))
                                    :
                                  JSON.stringify(Object.entries(ticketTypeOverrides).map(([key, value]) => ({
                                    id: key,
                                    attendee_fields_template_id: value,
                                  }))
                                ),
                              }
                            });
                          },
                        }}
                        actions={selectedTemplateId === 'custom' ? [] : [
                          {
                            content: 'View template',
                            onAction: () => {
                              window.open(`/settings/attendee_info/${templates.find((t) => t.id == selectedTemplateId).id}${searchParams}`, '_blank');
                            }
                          },
                        ]}
                      >
                        <Card.Section>
                          <FormLayout>
                            <Select
                              label='Select a template'
                              options={[
                                { label: 'Custom for this event', value: 'custom' },
                                ...templates.map((t) => ({ label: t.name, value: t.id })),
                              ]}
                              value={selectedTemplateId}
                              onChange={setSelectedTemplateId}
                            />
                          </FormLayout>
                        </Card.Section>
                        {selectedTemplateId != 'custom' &&
                          <Card.Section title='Preview'>
                            <AttendeeInfoSpecsTable
                              template={templates.find((t) => t.id == selectedTemplateId)}
                              attendeeAttributeSpecs={templates.find((t) => t.id == selectedTemplateId).specs}
                              readonly
                            />
                          </Card.Section>
                        }
                        {selectedTemplateId != 'custom' &&
                          <Card.Section title='Ticket types'>
                            <TextContainer>Use a different template for some ticket types</TextContainer>
                            <Stack vertical={true}>
                              {tickets.map((ticket) => (
                                <FormLayout.Group key={ticket.id}>
                                  <TextStyle variation='strong'>
                                    {ticket.title}
                                  </TextStyle>
                                  <Select
                                    options={[
                                      { label: 'Same as event', value: '' },
                                      ...templates.map((t) => ({ label: t.name, value: t.id })),
                                    ]}
                                    value={ticketTypeOverrides[ticket.id] || ''}
                                    onChange={(v) => setTicketTypeOverrides({
                                      ...ticketTypeOverrides,
                                      [ticket.id]: v && v.length > 0 ? v : null,
                                    })}
                                  />
                                </FormLayout.Group>
                              ))}
                            </Stack>
                          </Card.Section>
                        }
                      </Card>
                    )}
                  </Mutation>
                )}
              </Layout.AnnotatedSection>
            }
            {selectedTemplateId == 'custom' &&
              <Layout.AnnotatedSection
                title="Attendee information fields"
                description={<div>
                  <p>These fields will be displayed as inputs to your customers and attendees, they&apos;ll be able to edit them based on the rules you set up for each.</p>
                  <p>Edit this list of questions to ask your customers before purchasing {!isVendor && 'using the storefront integration (install it below) '}and/or after they purchase from their ticket pages.</p>
                </div>}
              >
                {loading ? (
                  <CardLoading />
                ) : (
                  <AttendeeInfoCard
                    event={event}
                    specs={specs}
                  />
                )}
              </Layout.AnnotatedSection>
            }
            {!isVendor &&
              <Layout.AnnotatedSection
                title="Storefront integration"
                description=<div>
                  <p>Install this storefront integration to collect attendee information before checkout. This is a store-wide setting, you can only enable it for all events or none at all.</p>
                  <p>Learn more about this storefront integration <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">in our help center</ShopifyLink> or see it in action on our <ShopifyLink external url={`https://eveyevents.myshopify.com`} target="_blank">demo shop</ShopifyLink>.</p>
                </div>

              >
                <div>
                  {loading ? (
                    <Card sectioned>
                      <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText />
                      </TextContainer>
                    </Card>
                  ) : (
                    <Card sectioned>
                      <Stack spacing="loose" vertical>
                        <TextContainer>
                          {attendeeInfoStorefrontEnabled ? (
                            <div>
                              <p><strong>Status: </strong> <Badge status='success'>Installed</Badge></p>
                              <p>Customers can submit the extra information fields you have configured above before they purchase tickets.</p>
                            </div>
                          ) : (
                            <div>
                              <p><strong>Status: </strong> <Badge status='critical'>Disabled</Badge></p>
                              <p>Customers cannot submit the extra information you have configured above before they purchase tickets, they can submit it through their online ticket page after purchase.</p>
                            </div>
                          )}
                        </TextContainer>
                        <Stack distribution="leading">
                          <ButtonGroup>
                            <Button plain
                              onClick={() => goToPage({ history, path: '/settings/storefront' })}
                            >
                        Manage integration for all events
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      </Stack>
                    </Card>
                  )}
                </div>
              </Layout.AnnotatedSection>
            }
          </Layout>
        );
      }}
    </Query>
  );
};

export default EventAttendeeInfo;
