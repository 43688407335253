import React from 'react';
import { EmptyState, Card, Layout } from '@shopify/polaris';
import AppPage from '../AppPage/AppPage.jsx';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";

const EventListEmptyState = ({ secondaryActions }) => {
  const { canCreateEvents } = useFrame();

  const history = useHistory();

  return (
    <AppPage
      title="Events"
      primaryAction={canCreateEvents ? {
        content: 'Create event',
        onAction: () => {
          history.push('/events/new');
        }
      } : undefined}
      secondaryActions={secondaryActions}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <EmptyState
              heading="Create your first event"
              image="https://cdn.shopify.com/s/files/1/0481/9383/1070/files/ticket-bundle-500.png?v=1625753171"
              imageContained
              action={canCreateEvents ? {
                content: 'Create event',
                onAction: () => {
                  history.push('/events/new');
                }
              } : undefined}
            >
              <p>Start selling tickets today.</p>
              {!canCreateEvents &&
                <p>This account does not have access to create events. Contact the store owner to create events.</p>
              }
            </EmptyState>
          </Card>
        </Layout.Section>
      </Layout>
    </AppPage>
  );
};

export default EventListEmptyState;
