import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, FormLayout, Heading, TextContainer, Link as ShopifyLink } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import ModalFooter from '../../shared/ModalFooter.jsx';
import { getShopNameFromDomain } from '../../../utils/auth';

const SAVE_ORGANIZER = gql`
  mutation saveOrganizer($organizerId: ID, $name: String!, $imageUrl: String, $description: String) {
    saveOrganizer(organizerId: $organizerId, name: $name, imageUrl: $imageUrl, description: $description) {
      organizer {
        id
      }
    }
  }
`;

const DELETE_ORGANIZER = gql`
  mutation deleteOrganizer($organizerId: ID!) {
    deleteOrganizer(organizerId: $organizerId) {
      success
    }
  }
`;

const OrganizerModal = ({ shop, organizer, onClose }) => {
  const shopName = getShopNameFromDomain(shop?.domain);
  const [name, setName] = React.useState(organizer ? organizer.name : '');
  const [imageUrl, setImageUrl] = React.useState(organizer ? organizer.imageUrl : '');
  const [description, setDescription] = React.useState(organizer ? organizer.description : '');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = name && name.length > 0;

  return (
    <Mutation
      mutation={DELETE_ORGANIZER}
      onError={() => showToastError('Failed to delete organizer')}
      onCompleted={() => {
        showToastNotice('Organizer deleted');
        onClose();
      }}
      refetchQueries={() => ['GetLocations']}
      awaitRefetchQueries={true}
    >
      {(deleteOrganizer, { loading: deleting }) => (
        <Mutation
          mutation={SAVE_ORGANIZER}
          onError={() => showToastError(organizer ? 'Failed to update organizer' : 'Failed to add organizer')}
          onCompleted={() => {
            showToastNotice(organizer ? 'Organizer updated' : 'Organizer created');
            onClose();
          }}
          refetchQueries={() => ['GetLocations']}
        >
          {(saveOrganizer, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={organizer ? `Edit organizer` : 'Add organizer'}
              footer={
                <ModalFooter
                  saveButtonText="Save"
                  saveButtonAction={() => {
                    saveOrganizer({
                      variables: {
                        organizerId: organizer ? organizer.id : null,
                        name,
                        imageUrl,
                        description,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={!isValid || deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText={'Delete'}
                  destructiveButtonAction={organizer ? () => {
                    deleteOrganizer({
                      variables: {
                        organizerId: organizer.id,
                      },
                    });
                  } : undefined}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />
              }
            >
              <Modal.Section>
                <FormLayout>
                  <TextContainer>These fields are not used by default on events but are available for custom liquid templates and through metafields on the storefront.</TextContainer>
                  <TextField
                    label="Name"
                    value={name}
                    placeholder="Peter Parker Productions"
                    onChange={setName}
                  />
                  <TextField value={imageUrl} label="Image URL" onChange={setImageUrl} helpText=<div>Upload any custom files through the Shopify <ShopifyLink external url={`https://admin.shopify.com/store/${shopName}/content/files?selectedView=all`} target="_blank">files section</ShopifyLink> and paste the URL here.</div> />
                  <TextField value={description} label="Description" onChange={setDescription} multiline={3} />
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default OrganizerModal;
