import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Layout } from '@shopify/polaris';
import SettingsPage from './SettingsPage';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import LocationsCard from './Locations/LocationsCard';
import SeatingChartsCard from './Seating/SeatingChartsCard';
import OrganizersCard from './Organizers/OrganizersCard';

const GET_LOCATIONS = gql`
  query GetLocations {
    currentShop {
      id
      name
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      locations {
        id
        name
        url
        code
        imageUrl
        address
        description
        seatingChart {
          id
        }
      }
      organizers {
        id
        name
        imageUrl
        description
      }
      seatingCharts {
        id
        name
        imageUrl
      }
    }
  }
`;

const LocationsPage = () => {

  return (
    <SettingsPage title='Locations'>
      <Query query={GET_LOCATIONS} >
        {({ loading, data }) => {
          if (loading) {
            return <AnnotatedSectionLoading />;
          }

          const shop = data.currentShop;

          return (
            <Layout>
              <Layout.AnnotatedSection
                title="Locations"
                description=<div>
                  <p>Saved locations can be used to share location details across different events.</p>
                  <p>After adding locations here you will see an option in your event settings to select a saved location to apply to that event.</p>
                </div>
              >
                <LocationsCard shop={shop} locations={shop.locations} />

              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Seating plans"
                description=<div>
                  <p>Seating plans allow you to define seat arrangements for a venue and seating layout. They can be used to enable reserved seating for an events using locations you assign seatings chart to.</p>
                </div>
              >
                <SeatingChartsCard shop={shop} seatingCharts={shop.seatingCharts} />

              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Organizers"
                description=<div>
                  <p>Event organizers can be used to share organizer details across different events. This information is not used by default on any tickets or notifications, you can use this in custom liquid or through metafields on your storefront.</p>
                  <p>After adding organizers here you will see an option in your event settings to select an organizer to assign to that event.</p>
                </div>
              >
                <OrganizersCard shop={shop} organizers={shop.organizers} />
              </Layout.AnnotatedSection>
            </Layout>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default LocationsPage;
