import React from 'react';
import { Link as ShopifyLink, Card, Badge, DescriptionList, Banner } from '@shopify/polaris';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const ZOOM_REGISTER = gql`
  mutation zoomRegister($attendeeId: ID!) {
    zoomRegister(attendeeId: $attendeeId) {
      registrant {
        id
      }
    }
  }
`;

const ZOOM_UNREGISTER = gql`
  mutation zoomUnregister($attendeeId: ID!) {
    zoomUnregister(attendeeId: $attendeeId) {
      success
    }
  }
`;

const ZoomRegistrantCard = ({ event, attendee, registrant }) => {
  const { showToastNotice, showToastError } = useFrame();
  const history = useHistory();

  return (
    <Mutation mutation={ZOOM_UNREGISTER}
      onError={() => showToastError('Failed to unregister attendee')}
      onCompleted={(data) => showToastNotice('Attendee unregistered')}
      refetchQueries={() => ['EventQuery', 'AttendeeQuery']}
      awaitRefetchQueries={true}
    >
      {(zoomUnregister, { loading: unregistering }) => (
        <Mutation mutation={ZOOM_REGISTER}
          onError={() => showToastError('Failed to register attendee')}
          onCompleted={(data) => showToastNotice('Attendee registered')}
          refetchQueries={() => ['EventQuery', 'AttendeeQuery']}
          awaitRefetchQueries={true}
        >
          {(zoomRegister, { loading: saving }) => (
            <Card
              actions={[
                {
                  content: 'Zoom dashboard',
                  url: `https://zoom.us/meeting/${event.zoom.meetingId}`,
                  external: true,
                }
              ]}
              primaryFooterAction={registrant ? {
                content: 'Cancel registration',
                destructive: true,
                loading: unregistering,
                onAction: () => {
                  zoomUnregister({
                    variables: {
                      attendeeId: attendee.id,
                    },
                  });
                },
              } : {
                content: 'Register',
                loading: saving,
                onAction: () => {
                  zoomRegister({
                    variables: {
                      attendeeId: attendee.id,
                    },
                  });
                },
              }}
            >
              {registrant ? (
                <Card.Section>
                  <DescriptionList
                    items={[
                      { term: 'Email', description: registrant && registrant.email || '' },
                      { term: 'First name', description: registrant && registrant.first_name || '' },
                      { term: 'Last name', description: registrant && registrant.last_name || '' },
                      { term: 'Status', description: registrant && registrant.status || '' },
                      // { term: 'Join URL', description: registrant && registrant.join_url || '' },
                    ]}
                  />
                  {attendee && attendee.zoomRegistrantAttendees.length > 1 &&
                    <Banner
                      status='info'
                      secondaryAction={{
                        content: 'View attendees',
                        onAction: () => goToPage({ history, path: `/events/${event.id}/attendees?zoomRegistrantId=${registrant.id}` }),
                      }}
                    >
                      There {attendee.zoomRegistrantAttendees.length == 1 ? 'is' : 'are'} {attendee.zoomRegistrantAttendees.length} attendee{attendee.zoomRegistrantAttendees.length == 1 ? '' : 's'} registered with the Zoom meeting using the same email address. Only one registrant exists on the Zoom meeting.
                    </Banner>
                  }
                </Card.Section>
              ) : (
                <Card.Section>
                  {attendee && attendee.email ? (
                    <Banner status='warning'>
                      This attendee has not been registered for the zoom meeting yet. Tap the button below to register {attendee.email}.
                    </Banner>
                  ) : (
                    <Banner status='critical'>Missing email address. Update the email address for this attendee before it can be registered to the Zoom meeting.</Banner>
                  )}
                </Card.Section>
              )}
            </Card>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default ZoomRegistrantCard;
