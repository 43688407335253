import React from 'react';
import { Banner } from '@shopify/polaris';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';

const UpgradeRequiredBanner = ({ message }) => {
  const history = useHistory();

  return (
    <Banner
      status='warning'
      title='Upgrade required'
      action={{
        content: 'Upgrade plan',
        onAction: () => goToPage({ history, path: '/plans' })
      }}
    >
      {message}
    </Banner>
  );
};

export default UpgradeRequiredBanner;
