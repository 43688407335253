import React from 'react';
import { ResourceItem, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import OrganizerImage from './OrganizerImage';

const OrganizerResourceItem = ({ organizer, onSelect }) => {
  return (
    <ResourceItem
      id={organizer.id}
      onClick={() => onSelect(organizer)}
    >
      <Stack vertical={false} alignment='center'>
        <OrganizerImage organizer={organizer} />
        <Stack vertical={true} distribtion='leading'>
          <TextContainer>{organizer.name}</TextContainer>
        </Stack>
      </Stack>
    </ResourceItem>
  );
};

export default OrganizerResourceItem;
