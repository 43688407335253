import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_EVENT } from '../../../utils/graphql';
import { Card, FormLayout, Select, Link as ShopifyLink } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';

const OrganizerCard = ({ shop, event }) => {
  const [organizerId, setOrganizerId] = React.useState(event.organizer ? event.organizer.id : null);
  const { currentShopDomain, showToastError, showToastNotice } = useFrame();

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={() => {
        showToastError('Failed to update event');
      }}
      onCompleted={() => {
        showToastNotice('Event updated');
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables: {
                  eventId: event.id,
                  organizerId: organizerId ? organizerId : '0',
                }
              });
            },
          }}
        >
          <FormLayout>
            <Select
              options={[
                { value: '0', label: '' },
                ...shop.organizers.map((o) => ({ value: o.id, label: o.name }))
              ]}
              value={organizerId}
              onChange={setOrganizerId}
              label='Select an organizer to assign to this event'
            />
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default OrganizerCard;
