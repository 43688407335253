import React from 'react';
import { Card, Tabs, TextContainer, TextStyle, Spinner } from '@shopify/polaris';
import { Query } from 'react-apollo';
import EmailPreview from '../Notifications/EmailPreview.jsx';
import { useState } from 'react';
import { TEMPLATE_PREVIEW } from '../../utils/graphql';
import { FullHeightContainer } from '../shared/containers';
import { useDebounce } from 'use-debounce';

const ReminderPreviewCard = ({ shop, event, subject, message, plainTextMessage, ctaLabel, ctaUrl, flashCallback, history, onChangeType }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const config = JSON.parse(event.email.config);

  const [debouncedMessage] = useDebounce(selectedTab == 1 ? (plainTextMessage || message) : message, 1000);
  const [debouncedCtaLabel] = useDebounce(ctaLabel, 1000);
  const [debouncedCtaUrl] = useDebounce(ctaUrl, 1000);

  return (
    <Card title="Preview">
      <Card.Section>
        <Tabs
          tabs={[
            {
              id: 'email',
              content: 'Email',
              accessiblityLabel: 'Attendee notification email',
              panelID: 'email-content'
            }
          ]}
          selected={selectedTab}
          onSelect={(newValue) => {
            onChangeType('email');
            setSelectedTab(newValue)
          }}
        >
          <Query
            query={TEMPLATE_PREVIEW}
            variables={{
              templateType: 'reminder-email',
              templateId: event.email.id,
              eventId: event.id,
              extraContext: JSON.stringify({
                message: debouncedMessage,
                cta_label: debouncedCtaLabel,
                cta_url: debouncedCtaUrl,
              }),
            }}
          >
          {({ loading: previewLoading, error, data }) => {

            // if (previewLoading) {
            //   return (
            //     <div style={{textAlign:'center',marginTop:'50px',marginBottom:'50px'}}>
            //       <Spinner size="small" />
            //     </div>
            //   );
            // }

            const currentPreview = data?.currentShop?.template?.renderedHtml || '';

            return (
              <div style={{position:'relative', pointerEvents: 'none'}}>
                {previewLoading && (
                  <div style={{position:'absolute', top:'15px', right:'15px'}}>
                    <Spinner size="small" />
                  </div>
                )}
                {selectedTab == 0 &&
                  <EmailPreview
                    key='reminder-email'
                    flashCallback={flashCallback}
                    history={history}
                    shop={shop}
                    event={event}
                    subject={subject}
                    body={currentPreview || ''}
                    renderHtml={event.email.reminderSendAsHtml}
                    config={config || {}}
                    extraContext={{
                      message: message,
                      cta_label: ctaLabel,
                      cta_url: ctaUrl,
                    }}
                  />
                }
              </div>
            );
          }}
          </Query>
        </Tabs>
        <TextContainer><TextStyle variation="subdued">This is a simulated preview. Always check your message using the <strong>Test email</strong> action under the More button.</TextStyle></TextContainer>
      </Card.Section>
    </Card>
  );
};

export default ReminderPreviewCard;
