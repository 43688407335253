import React from "react";
import { Query } from "react-apollo";
import {
  Banner,
} from "@shopify/polaris";
import { GET_ACCESS_SCOPES_STATUS } from "../../utils/graphql";

const AccessScopeBanner = () => {
  return (
    <Query query={GET_ACCESS_SCOPES_STATUS}>
      {({ loading, data }) => {
        if (loading || !data) {
          return "";
        }
        const shop = data.currentShop;

        if (!shop.shouldUpdateAccessScopes) {
          return "";
        }

        return (
          <Banner
            title="Urgent Action Required"
            status="critical"
            action={{
              content: "Update app permissions",
              url: shop.redirectLoginUrl,
              external: true,
            }}
          >
            <p>
              Shopify recently made changes which affect your Evey Events integration. You will need to update some permissions to ensure the app continues to work seamlessly with your store. But don't worry, we've made it easy for you! Simply click the button below before <strong>Tuesday, August 1st</strong> to approve the new permissions. Please note: if no action is taken by August 1st, you may experience problems using the app.
            </p>
          </Banner>
        );
      }}
    </Query>
  );
};

export default AccessScopeBanner;
