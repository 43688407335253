import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, Checkbox, FormLayout, TextContainer } from '@shopify/polaris';
import { DELETE_EVENT } from '../../utils/graphql';
import { parseBoolean } from '../../utils/parse';

const DeleteEventModal = ({ shop, event, onComplete, onError, onDismiss }) => {
  const [deleteProduct, setDeleteProduct] = React.useState(true);

  if (event.isShopifySubscription) {
    return (
      <Modal
        title='Unable to delete event'
        open={true}
        onClose={onDismiss}
        primaryAction={{
          content: 'Go back',
          onAction: onDismiss,
        }}
      >
        <Modal.Section>
          You cannont delete an event with an active subscription. Please unsubscribe first by clicking the Cancel Subscription button.
        </Modal.Section>
      </Modal>
    );
  } else {
    return (
      <Mutation
        mutation={DELETE_EVENT}
        onError={() => {
          if (onError) {
            onError();
          }
        }}
        onCompleted={() => {
          if (onComplete) {
            onComplete();
          }
        }}
      >
        {(deleteEvent, { loading: deleting }) => (
          <Modal
            title='Confirm event deletion'
            open={true}
            onClose={onDismiss}
            primaryAction={{
              content:'Yes, delete it',
              destructive: true,
              loading: deleting,
              onAction: () => {
                deleteEvent({
                  variables: {
                    eventId: event.id,
                    deleteProduct: parseBoolean(deleteProduct),
                  },
                });
              }
            }}
            secondaryActions={[
              {
                content: 'No, go back',
                disabled: deleting,
                onAction: onDismiss,
              },
            ]}
          >
            <Modal.Section>
              <FormLayout>
                <TextContainer>Are you sure you want to delete this event and all of the associated data? This cannot be reversed.</TextContainer>
                <Checkbox
                  checked={deleteProduct}
                  label='Delete the product associated to this event in the Shopify Dashboard'
                  onChange={setDeleteProduct}
                />
              </FormLayout>
            </Modal.Section>
          </Modal>
        )}
      </Mutation>
    );
  }
};

export default DeleteEventModal;
