import React from 'react';
import gql from 'graphql-tag';
import { Layout, Link as ShopifyLink, Banner, Card } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import KlaviyoCard from './KlaviyoCard';
import { GET_SHOP_INTEGRATIONS } from '../../utils/graphql';
import { Query } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';

const EVENT_FIELDS = `
  klaviyo {
    id
    publicApiKey
    apiKey
    syncListId
    syncListName
    tagEventName
    tagTicketType
    customTags
    lists {
      id
      name
    }
  }
`;

const EventKlaviyo = ({ eventId }) => {
  const history = useHistory();

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <Layout.AnnotatedSection
          title="Klaviyo"
          description=<div>
            <p>
              The Klaviyo integration allows you to link your Klaviyo account to all of your events. When tickets
              are created, cancelled, or deleted activity metrics and ticket data will be sent to Klaviyo so that
              you can segment, automate, and analyze using the Klavyo marketing and analytics tools.
            </p>
            {/*<Query query={GET_SHOP_INTEGRATIONS} >
              {({ loading, data }) => (
                loading ? ('') : (
                  data.currentShop.klaviyo && data.currentShop.klaviyo.apiKey ? (
                    <Banner status='info'>You have the <ShopifyLink onClick={() => goToPage({ history, path: '/settings/integrations/klaviyo' })}>shop-wide Klaviyo integration</ShopifyLink> linked to an account already, if you link an account here it will override those settings for this event only.</Banner>
                  ) : (
                    ''
                  )
                )
              )}
            </Query>*/}
          </div>
        >
          {/*<KlaviyoCard
            shop={shop}
            klaviyo={event.klaviyo}
            redirectTo={`/events/${event.id}/klaviyo?shop=${shop.domain}`}
          />*/}
          <Card sectioned>
            <Banner
              status='default'
              secondaryAction={{
                content: 'Manage Klaviyo Integration',
                onAction: () => goToPage({ history, path: '/settings/integrations/klaviyo' }),
              }}
            >
              The Klaviyo integration can be enabled for all events under the main Settings area, tap the link below to manage your integration.
            </Banner>
          </Card>
        </Layout.AnnotatedSection>
      )}
    </EventQuery>
  );
};

export default EventKlaviyo;
