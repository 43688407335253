import React from 'react';
import { Card, FormLayout, TextField, ChoiceList, Link as ShopifyLink } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useFrame } from '../../../contexts/frame';
import { SAVE_SEATING_CHART } from '../../../utils/graphql';
import { getShopNameFromDomain } from '../../../utils/auth';

const SeatingChartCard = ({ shop, seatingChart }) => {
  const [name, setName] = React.useState(seatingChart.name);
  const [imageUrl, setImageUrl] = React.useState(seatingChart.imageUrl);

  const { showToastNotice, showToastError } = useFrame();

  const isValid = name && name.length > 0;

  const shopName = getShopNameFromDomain(shop?.domain);

  return (
    <Mutation
      mutation={SAVE_SEATING_CHART}
      onError={() => showToastError('Failed to upate')}
      onCompleted={(data) => {
        showToastNotice('Seating plan saved');
      }}
      refetchQueries={() => ['GetSeatingChart']}
    >
      {(saveSeatingChart, { loading: saving }) => (
        <Card sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            disabled: !isValid,
            onAction: () => {
              saveSeatingChart({
                variables: {
                  id: seatingChart.id,
                  name,
                  imageUrl,
                },
              });
            },
          }}
        >
          <FormLayout>
            <TextField
              label="Name"
              value={name}
              placeholder="Venue A seating max capacity"
              onChange={setName}
              helpText='Internal name for seating plan, only used to help you organize and select the chart'
            />
            <TextField
              value={imageUrl}
              label="Image URL"
              onChange={setImageUrl}
              helpText=<div>Include a link to an image of a seating map on your product page. Upload files through the Shopify <ShopifyLink external url={`https://admin.shopify.com/store/${shopName}/content/files?selectedView=all`} target="_blank">files section</ShopifyLink> and paste the URL here.</div>
            />
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default SeatingChartCard;
