import React from 'react';
import { Query } from 'react-apollo';
import { Card, Select, Stack, DataTable, Spinner, Button, Pagination, Subheading, Filters, Link, Banner } from '@shopify/polaris';
import { GET_EVENT_SEATS } from '../../../utils/graphql';
import styled from '@emotion/styled';
import ReservationsModal from './ReservationsModal';
import UpgradeRequiredBanner from '../../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../../utils/features';
import { getHostFromShopDomain } from '../../../utils/auth';
import RecurringCalendarModal from './RecurringCalendarModal';
import dayjs from 'dayjs';

const SpinnerContainer = styled.div`
  display:flex;
  height:100px;
  justify-content: center;
  align-items:center;
`;

const PaginationContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
`;

const PER_PAGE = 10;

const SeatingCard = ({ shop, event }) => {
  const seatingZones = [...event.savedLocation.seatingChart.seatingZones];

  const [selectedZone, setSelectedZone] = React.useState(seatingZones[0].id);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [localSearchValue, setLocalSearchValue] = React.useState('');
  const [showReservationsModal, setShowReservationsModal] = React.useState(false);
  const [showRecurringCalendarModal, setShowRecurringCalendarModal] = React.useState(false);
  const [selectedScheduledTime, setSelectedScheduledEvent] = React.useState();

  const localizedSelectedScheduledTime = (new Date(selectedScheduledTime)).toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' });

  const hasSeatingFeature = hasPlanFeature({ shop, feature: 'seating' });

  return (
    <Card sectioned>
      {!hasSeatingFeature ? (
        <UpgradeRequiredBanner message='Reserved seating is not available on this plan, upgrade to get full access to reserved seating features.' />
      ) : (
        <Stack vertical={true} spacing='loose'>
          <Select
            options={seatingZones.map((zone) => ({ value: zone.id, label: zone.name }))}
            value={selectedZone}
            onChange={setSelectedZone}
            label='Seating zone'
          />
          {event.dateType === 'recurring' &&
            <Button
              onClick={() => {
                setShowRecurringCalendarModal(true);
              }}
            >
              {selectedScheduledTime ? localizedSelectedScheduledTime : 'Select date/time'}
            </Button>
          }
          {event.dateType === 'recurring' && !selectedScheduledTime ? (
            <Banner status='default'>Select a date/time</Banner>
          ) : (
            <Query
              query={GET_EVENT_SEATS}
              variables={{
                'eventId': event.id,
                'seatingZoneId': selectedZone,
                'page': currentPage,
                'perPage': PER_PAGE,
                'search': searchValue,
                ...(event.dateType === 'recurring' ? {
                  'eventDate': dayjs(selectedScheduledTime).format('YYYY-MM-DD'),
                  'eventTime': dayjs(selectedScheduledTime).format('HH:mm'),
                } : {}),
              }}
            >
              {({ loading, data }) => {
                if (loading) {
                  return <SpinnerContainer><Spinner size="large" color="teal" /></SpinnerContainer>;
                }

                const seatsCount = data && data.currentShop.event.seatsCount || 0;
                const searchParams = "&host=" + getHostFromShopDomain(data.currentShop?.domain);

                const config = JSON.parse(data && data.currentShop.attendeeInfoStorefrontConfig || '{}');
                const reservationsEnabled = !!config.seating_reservations_enabled;

                const rows = data && data.currentShop.event.seats.map((seat) => ([
                  seat.label,
                  seat.capacity,
                  seat.soldCount > 0 ? <Link external url={`${shop.adminAppUrl}/events/${event.id}/attendees?shop=${shop.domain}&seat=${seat.label}${searchParams}`}>{seat.soldCount}</Link> : 0,
                  ...(reservationsEnabled ? [
                    seat.reservedCount > 0 ? <Link onClick={() => setShowReservationsModal(seat)}>{seat.reservedCount}</Link> : 0
                  ] : []),
                ])) || [];

                const startIndex = (currentPage-1) * PER_PAGE;

                return (
                  <Stack vertical={true}>
                    <Subheading>Seats</Subheading>
                    <div onKeyDown={(ev) => {
                      if (ev.keyCode == 13) {
                        setSearchValue(localSearchValue);
                      }
                    }}>
                      <Filters
                        queryValue={localSearchValue}
                        queryPlaceholder='Seat label'
                        filters={[]}
                        appliedFilters={[]}
                        onQueryChange={(v) => setLocalSearchValue(v)}
                        onQueryClear={() => {
                          setLocalSearchValue('');
                          setSearchValue('');
                        }}
                        onClearAll={() => {
                          setLocalSearchValue('');
                          setSearchValue('');
                        }}
                      />
                    </div>
                    <DataTable
                      columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        ...(reservationsEnabled ? ['text'] : []),
                      ]}
                      headings={[
                        'Label',
                        'Capacity',
                        'Sales',
                        ...(reservationsEnabled ? ['Cart reservations'] : []),
                      ]}
                      rows={rows}
                      footerContent={`Showing ${startIndex+1}-${startIndex + rows.length} of ${seatsCount} results`}
                    />
                    {rows.length > 0 &&
                      <PaginationContainer>
                        <Pagination
                          hasPrevious={currentPage > 1}
                          hasNext={currentPage < (Math.ceil(seatsCount / PER_PAGE))}
                          onPrevious={() => setCurrentPage(currentPage - 1)}
                          onNext={() => setCurrentPage(currentPage + 1)}
                        />
                      </PaginationContainer>
                    }
                    {showReservationsModal &&
                      <ReservationsModal
                        event={event}
                        seat={showReservationsModal}
                        scheduledTime={event.dateType === 'recurring' ? selectedScheduledTime : null}
                        onClose={() => setShowReservationsModal(false)}
                      />
                    }
                  </Stack>
                );
              }}
            </Query>
          )}
          {event.dateType === 'recurring' && showRecurringCalendarModal &&
            <RecurringCalendarModal
              shop={shop}
              event={event}
              onClose={() => setShowRecurringCalendarModal(false)}
              onSelect={(start, scheduleItemId) => {
                setSelectedScheduledEvent(start);
                setShowRecurringCalendarModal(false);
              }}
            />
          }
        </Stack>
      )}
    </Card>
  );
};

export default SeatingCard;
