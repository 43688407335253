import React from 'react';
import { Page, Layout, Card, TextField, FormLayout, Form, Button, Link } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { useVendor } from '../../contexts/vendor';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';

const PageContainer = styled.div`
  .Polaris-Page {
    max-width:680px;
  }
`;

const LoginPage = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { login, loggingIn } = useVendor();

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <PageContainer>
      <Page
        title='Login'
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Section>
                <Form
                  implicitSubmit={true}
                  onSubmit={async () => {
                    try {
                      const next = (new URLSearchParams(window.location.search)).get('next');
                      await login({ email, password });
                      goToPage({ history, path: next || '/' });
                    } catch (error) {
                      if (error.name === 'AuthenticationRequiredError') {
                        showToastError('Login failed, invalid email or password');
                      } else {
                        showToastError('Login failed');
                        throw error;
                      }
                    }
                  }}
                >
                  <FormLayout>
                    <TextField
                      label='Email'
                      type='email'
                      value={email}
                      onChange={setEmail}
                    />
                    <TextField
                      label='Password'
                      type='password'
                      value={password}
                      onChange={setPassword}
                    />
                    <Button
                      primary
                      submit
                      loading={loggingIn}
                    >
                      Login
                    </Button>
                    <Link
                      onClick={() => goToPage({ history, path: '/vendor/forgot_password' })}
                    >
                      Forgot password
                    </Link>
                  </FormLayout>
                </Form>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </PageContainer>
  );
};

export default LoginPage;
