import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, Banner, FormLayout, TextField, Checkbox, ButtonGroup, Button, DatePicker, Select, Stack } from '@shopify/polaris';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError, formatErrors, hasErrors } from '../../../utils/errors';
import { useFrame } from '../../../contexts/frame';
import { goToPage } from '../../../utils/routing';
import { parseBoolean } from '../../../utils/parse';
import { convertToDateTime, buildDateWithCompensatedTimezone, getTimeString } from '../../../utils/time';
import Schedule from '../../Schedule/Schedule';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import UpgradeRequiredBanner from '../../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../../utils/features';

const EventDateCard = ({ shop, event }) => {
  let initialStartAt = event.startAt ? buildDateWithCompensatedTimezone(event.startAt) : new Date();
  const initialStartAtUtc = new Date(initialStartAt.getTime());
  initialStartAt.setHours(0, 0, 0, 0);
  let initialEndAt = event.endAt ? buildDateWithCompensatedTimezone(event.endAt) : new Date();
  const initialEndAtUtc = new Date(initialEndAt.getTime());
  initialEndAt.setHours(0, 0, 0, 0);

  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const [month, setMonth] = React.useState(initialStartAt.getMonth());
  const [year, setYear] = React.useState(initialStartAt.getFullYear());
  const [selectedDateRange, setSelectedDateRange] = React.useState({ start: initialStartAt, end: initialEndAt });
  const [multiDayRange, setMultiDayRange] = React.useState(initialStartAt.valueOf() != initialEndAt.valueOf());
  const [startTime, setStartTime] = React.useState(getTimeString(initialStartAtUtc));
  const [endTime, setEndTime] = React.useState(getTimeString(initialEndAtUtc));
  const [selectedTimezone, setSelectedTimezone] = React.useState(event.timezone);
  const [dateType, setDateType] = React.useState(event.dateType);

  const history = useHistory();

  const hasRecurringEventsFeature = hasPlanFeature({ shop, feature: 'recurring' });

  const scheduleItems = event.scheduleItems.map((item) => ({
    ...item,
    startDate: item.startDate ? moment(buildDateWithCompensatedTimezone(item.startDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    endDate: item.endDate ? moment(buildDateWithCompensatedTimezone(item.endDate)).format('YYYY-MM-DD') : null,
    scheduleExceptions: item.scheduleExceptions ? item.scheduleExceptions.map((ex) => ({
      ...ex,
      startDate: moment(buildDateWithCompensatedTimezone(ex.startDate)).format('YYYY-MM-DD')
    })) : [],
    draft: false,
    resourceId: item.id.toString(),
  }));

  const { showToastNotice, showToastError, loadingMarkup } = useFrame();

  const handleDateChange = (value) => setSelectedDateRange(value);
  const handleMonthChange = (month, year) => {
    setMonth(month);
    setYear(year);
  };

  let availableTimezones = [];
  let isTimezoneInAvailableList = false;
  for (let i in shop.availableTimezones) {
    let timezone = shop.availableTimezones[i];
    availableTimezones.push({label: timezone.label, value: timezone.name});
    if (timezone.name == event.timezone) {
      isTimezoneInAvailableList = true;
    }
  }
  if (!isTimezoneInAvailableList) {
    availableTimezones.unshift({label: event.timezoneLabel, value: event.timezone});
  }

  let buildRecurringMarkup = ({ editEvent, saving }) => {
    if (dateType === 'recurring') {
      if (event.dateType !== 'recurring') {
        if (hasRecurringEventsFeature) {
          return (
            <Banner
              status='info'
              action={{
                content: 'Convert to recurring event',
                loading: saving,
                onAction: () => {
                  editEvent({
                    variables: {
                      eventId: event.id,
                      dateType: 'recurring',
                    },
                  });
                }
              }}
            >
              Click the button below to convert this to a recurring event. If you have any ticket sales for this event, they will not have a selected date/time. You can change this back later.
            </Banner>
          );
        } else {
          return (
            <UpgradeRequiredBanner message='Recurring events are not available on this plan, upgrade to get full access to recurring events features.' />
          );
          // return (
          //   <Banner
          //     status='info'
          //     title='Upgrade required'
          //     action={{
          //       content: 'Upgrade plan',
          //       onAction: () => goToPage({ history, path: '/settings/account?tab=subscription&plan=shop' })
          //     }}
          //   >
          //     Recurring events are only supported on the Unlimited events plan.
          //   </Banner>
          // );
        }
      } else {
        return (
          <Stack vertical={true}>
            {!hasRecurringEventsFeature &&
              <UpgradeRequiredBanner message='Recurring events are not available on this plan, upgrade to get full access to recurring events features.' />
            }
            <Schedule event={event} items={scheduleItems} />
            <Mutation mutation={EDIT_EVENT}
              onError={() => showToastError('Unable to update timezone')}
              onCompleted={() => showToastNotice('Event timezone updated')}
              refetchQueries={() => ['EventQuery']}
            >
              {(editEvent, { loading: saving }) => (
                <Select
                  label="Timezone"
                  options={availableTimezones}
                  onChange={(v) => {
                    setSelectedTimezone(v);
                    editEvent({
                      variables: {
                        eventId: event.id,
                        timezone: v,
                      }
                    });
                  }}
                  value={selectedTimezone}
                  loading={saving}
                  disabled={saving}
                />
              )}
            </Mutation>
          </Stack>
        );
      }
    }
  };

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setErrors([]);
          setUnknownError(true);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editEvent) {
          setErrors([]);
          setUnknownError(false);
          showToastNotice('Event updated');
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={dateType === 'recurring' ? undefined : {
            content: 'Save',
            loading: saving,
            onAction: () => {
              let startDate = null;
              let endDate = null;
              let dateRange = selectedDateRange;
              if (dateRange && dateRange.start && startTime) {
                startDate = convertToDateTime(dateRange.start, startTime);
              }
              if (dateRange && dateRange.end && endTime) {
                endDate = convertToDateTime(dateRange.end, endTime);
              }
              editEvent({
                variables: {
                  eventId: event.id,
                  dateType: dateType,
                  startAt: startDate,
                  endAt: endDate,
                  timezone: selectedTimezone,
                }
              });
            },
          }}
        >
          {unknownError &&
            <Banner title="There was a problem updating your event" status="critical">
              Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
            </Banner>
          }
          {hasErrors(errors) &&
            <Banner title="There was a problem updating your event" status="critical">{formatErrors(errors)}</Banner>
          }
          {saving && loadingMarkup}
          {event.dateType === 'recurring' && !shop.attendeeInfoStorefrontEnabled &&
            <div style={{ paddingBottom: '10px' }}>
              <Banner
                title="Storefront product page scheduler is disabled"
                status="warning"
                action={{
                  content: 'Go to settings',
                  onAction: () => goToPage({ history, path: '/settings/storefront' })
                }}
              >
                The storefront integration that allows us to inject a button on your product page that asks customers to select a event date and time is disabled. Enable it if you are setting up a recurring event with multiple date/times.
              </Banner>
            </div>
          }
          <FormLayout>
            <ButtonGroup segmented>
              <Button
                pressed={dateType == 'one-time'}
                onClick={() => setDateType('one-time')}
              >
                One-time event
              </Button>
              <Button
                pressed={dateType == 'recurring'}
                onClick={() => setDateType('recurring')}
              >
                Recurring event
              </Button>
              <Button
                pressed={dateType == 'no-date'}
                onClick={() => setDateType('no-date')}
              >
                No date yet
              </Button>
            </ButtonGroup>
            {dateType == 'one-time' &&
              <div style={{ marginTop: '60px' }}>
                <FormLayout>
                  <div className='EventForm__MultiDayCheckbox'>
                    <Checkbox
                      checked={multiDayRange}
                      label="Event date is across more than one day"
                      onChange={(v) => {
                        setMultiDayRange(v);
                        setSelectedDateRange({ start: new Date(event.startAt), end: new Date(event.startAt) });
                      }}
                    />
                  </div>
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={handleDateChange}
                    onMonthChange={handleMonthChange}
                    selected={selectedDateRange}
                    allowRange={multiDayRange}
                    multiMonth={multiDayRange}
                  />
                  <FormLayout.Group condensed>
                    <TextField value={startTime} type="time" label="Start Time" onChange={(v) => setStartTime(v || '00:00')} placeholder="HH:MM (24-hour)" />
                    <TextField value={endTime} type="time" label="End Time" onChange={(v) => setEndTime(v || '00:00')} placeholder="HH:MM (24-hour)" />
                  </FormLayout.Group>

                  <Select
                    label="Timezone"
                    options={availableTimezones}
                    onChange={(v) => setSelectedTimezone(v)}
                    value={selectedTimezone}
                  />
                </FormLayout>
              </div>
            }
            {dateType == 'recurring' && buildRecurringMarkup({ editEvent, saving })}
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default EventDateCard;
