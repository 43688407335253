import React from "react";
import AppPage from "../AppPage/AppPage.jsx";
import { useHistory, useLocation } from "react-router-dom";
import { goToPage } from "../../utils/routing";
import { useFrame } from "../../contexts/frame";
import { ArrowLeftMinor } from "@shopify/polaris-icons";

const SettingsPage = ({
  title,
  extraBreadcrumbs,
  primaryAction,
  secondaryActions,
  actionGroups,
  fullWidth,
  pageTitle,
  pageSubtitle,
  children,
  hideTrialBanner,
  hideKlaviyoKeyBanner,
  backToLabel,
  backToPath,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { isVendor } = useFrame();

  let breadcrumbs = [
    {
      content: "Settings",
      onAction: () => goToPage({ history, path: "/settings" }),
      target: "APP",
    },
  ];
  if (extraBreadcrumbs) {
    breadcrumbs.push(...extraBreadcrumbs);
  }

  React.useEffect(() => {
    if (isVendor) {
      goToPage({ history, path: "/events" });
    }
  }, []);

  return (
    <AppPage
      title={title}
      breadcrumbs={breadcrumbs}
      primaryAction={primaryAction}
      hideTrialBanner={hideTrialBanner}
      hideKlaviyoKeyBanner={hideKlaviyoKeyBanner}
      secondaryActions={[
        {
          content: backToPath ? backToLabel || "Back" : "Back to settings",
          icon: ArrowLeftMinor,
          onAction: () =>
            goToPage({ history, path: backToPath || "/settings" }),
          target: "APP",
        },
        ...(secondaryActions || []),
      ]}
      actionGroups={actionGroups}
      fullWidth={fullWidth || false}
      pageTitle={pageTitle || null}
      pageSubtitle={pageSubtitle || null}
    >
      {children}
    </AppPage>
  );
};

export default SettingsPage;
