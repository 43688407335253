import React from 'react';
import { Card, DataTable, Pagination, Badge, Link } from '@shopify/polaris';
import styled from '@emotion/styled';
import { currentHost, getHostFromShopDomain } from '../../utils/auth'

const RelatedTicketsCard = ({ shop, event, attendees }) => {
  const arrendeesAsc = attendees.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
  const [currentPage, setCurrentPage] = React.useState(1);
  const PER_PAGE = 10;
  const PaginationContainer = styled.div`
    display:flex;
    justify-content:center;
    padding-top:1rem;
  `;

  return (
    <Card>
      <Card.Section>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Ticket',
            'Name',
            'Email / Phone',
            'Ticket Type',
          ]}
          rows={
            arrendeesAsc.slice(((currentPage-1)*PER_PAGE), (currentPage*PER_PAGE)).map((attendee) => ([
              <Link url={`/events/${event.id}/attendees/${attendee.id}${searchParams}`} external>{attendee.ticketCode}</Link>,
              attendee.name,
              attendee.email || attendee.phonePretty,
              attendee.ticketTitle,
            ]))
          }
          footerContent={`
            Showing 
            ${(currentPage-1 < 1 ? 1 : ((currentPage-1)*PER_PAGE)+1)}
            -
            ${(currentPage*PER_PAGE > arrendeesAsc.length ? arrendeesAsc.length : currentPage*PER_PAGE)} 
            of ${attendees.length} 
            results
          `}
        />
        <PaginationContainer>
          <Pagination
            hasPrevious={currentPage > 1}
            hasNext={currentPage < (Math.ceil(arrendeesAsc.length / PER_PAGE))}
            onPrevious={() => {
              setCurrentPage(currentPage - 1);
            }}
            onNext={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        </PaginationContainer>
      </Card.Section>
    </Card>
  );
};

export default RelatedTicketsCard;
