import React from 'react';
import { TextField, FormLayout, Banner } from '@shopify/polaris';
import { EDIT_REPORT } from '../../utils/graphql';
import { Mutation } from 'react-apollo';
import { useFrame } from '../../contexts/frame';
import DeleteReportModal from './DeleteReportModal';

const EditReport = ({ event, report, onUpdate, onClose, filters, searchValue, category }) => {
  const [name, setName] = React.useState(report.name);
  const [email, setEmail] = React.useState(report.email);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <>
      <Mutation
        mutation={EDIT_REPORT}
        onError={() => showToastError('Unable to save report')}
        onCompleted={(data) => {
          if (data && data.editAttendeeReport) {
            showToastNotice('Report successfully saved');
            onUpdate({
              reports: data.editAttendeeReport.attendeeReports,
              report:  data.editAttendeeReport.attendeeReport,
            });
          }
        }}
      >
        {(editAttendeeReport, { loading }) => {
          return (
            <Banner
              title='Attendee report details'
              status="default"
              action={{
                content: 'Save report',
                loading: loading,
                disabled: loading,
                onAction: () => {
                  editAttendeeReport({
                    variables:{
                      eventId: event.id,
                      reportId: report.id,
                      name,
                      email,
                      filters: Array.isArray(filters) ? JSON.stringify(filters) : filters,
                      search: searchValue,
                      category: category
                    }
                  });
                }
              }}
              secondaryAction={report.id && report.id.length > 0 && {
                content: 'Delete',
                disabled: loading,
                onAction: () => setConfirmDeleteOpen(true)
              }}
              onDismiss={onClose}
            >
              <FormLayout>
                <TextField
                  label="Name of report"
                  helpText="A friendly name for this report that helps you identify it"
                  id='reportName'
                  value={name}
                  onChange={(v) => setName(v)}
                />
                <TextField
                  label="Email"
                  helpText="Let us know where we should send the report. Separate multiple emails by commas"
                  type="email"
                  id='reportEmail'
                  value={email}
                  onChange={(v) => setEmail(v)}
                />
              </FormLayout>
            </Banner>
          );
        }}
      </Mutation>
      {confirmDeleteOpen &&
        <DeleteReportModal
          event={event}
          report={report}
          onClose={() => setConfirmDeleteOpen(false)}
          onDeleted={({ reports }) => onUpdate({ reports, report: null })}
        />
      }
    </>
  );
};

export default EditReport;
