import React from 'react';
import { Modal, Tabs } from '@shopify/polaris';
import TicketEmailForm from './TicketEmailForm';

const TicketNotificationModal = ({ email, onClose }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Modal
      open={true}
      onClose={onClose}
      large={true}
      title='Ticket notification templates'
    >
      <Tabs
        tabs={[
          {
            id: 'email',
            content: 'Email',
            accessiblityLabel: 'Email ticket notification',
            panelID: 'email-content'
          }
        ]}
        selected={selectedTab}
        onSelect={setSelectedTab}
      >
        <Modal.Section>
          {selectedTab == 0 &&
            <TicketEmailForm
              email={email}
              onCancel={onClose}
            />
          }
        </Modal.Section>
      </Tabs>
    </Modal>
  );
};

export default TicketNotificationModal;
