import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Badge, Stack, Subheading, TextField, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import ColorPicker from '../shared/ColorPicker.jsx';
import SaveBar from '../shared/SaveBar';
import { shallowEqual } from '../../utils/compare';
import StorefrontScriptCard from './StorefrontScriptCard';
import styled from '@emotion/styled';

const SubtitleContents = styled.div`
  padding: 20px;
`;

const TEXT = [
  {
    handle: 'scheduler_button_label',
    label: 'Schedule button label',
    defaultValue: 'Choose date/time',
    helpText: 'The text for the button on the product page that customers tap to show the calendar options.',
  },
  {
    handle: 'scheduler_option_label',
    label: 'Schedule option label',
    defaultValue: 'Date/time',
    helpText: 'The text for the option on the product page that customers tap to show the calendar options. Use when the theme has direct support.',
  },
  {
    handle: 'scheduler_header_title',
    label: 'Header title',
    defaultValue: 'Select event date and time',
    helpText: 'The heading text for the calendar selection popup.',
  },
  {
    handle: 'scheduler_tickets_remaining',
    label: 'Tickets remaining',
    defaultValue: '%{available} tickets remaining',
  },
]

const SchedulerCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [strings, setStrings] = React.useState(originalStrings);

  const { showToastNotice, showToastError } = useFrame();

  const defaultLocale = shop.defaultLocaleFile.locale;
  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config) || JSON.stringify(originalStrings) != JSON.stringify(strings);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = '/js/online_store_preview.js';
    script.async = true;
    document.body.appendChild(script);
  });

  return (
    <>
      <SubtitleContents>
        <TextContainer>
          <p>The storefront scheduler allows you to ask the customer for the date/time for tickets of for recurring events before they purchase the ticket. The app will show a calendar with options for the recurring event.</p>
          <p>Learn more about recurring events <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">in our help center</ShopifyLink> or see it in action on our <ShopifyLink external url='https://eveyevents.myshopify.com/products/awesome-recurring-event'>demo shop</ShopifyLink>.</p>
        </TextContainer>
      </SubtitleContents>
      <StorefrontScriptCard shop={shop} />
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update customizations')}
        onCompleted={() => showToastNotice('Customizations updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Card sectioned
            title='Customize'
            actions={[
              // {
              //   content: 'Preview',
              //   onAction: () => {
              //     window.eveyAttendeeInfoModal.setConfig(config || {});
              //     window.eveyAttendeeInfoModal.setStrings();
              //     window.eveyAttendeeInfoModal.show();
              //   },
              // },
              {
                content: 'Reset to default',
                onAction: () => {
                  let newConfig = { scheduler_enabled: true };

                  let newStrings = {};
                  TEXT.map((string) => {
                    newStrings[string.handle] = string.defaultValue;
                  });
                  setConfig(newConfig);
                  setStrings(newStrings);
                }
              }
            ]}
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !hasChanges,
              onAction: () => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }
            }}
          >
            <Stack distribution="fillEvenly" spacing="extraLoose">
              <Stack vertical spacing='loose'>
                <Subheading>PREFERENCES</Subheading>
                <Checkbox
                  checked={config.scheduler_enabled !== false}
                  label='Enable the date/time scheduler for recurring events'
                  onChange={(v) => setConfig({ ...config, scheduler_enabled: v })}
                  helpText="If you have any recurring events set up in the app, disabling this will not let your customers select their date/time. If you have recurring events, only disable this if you have a custom solution."
                />
                <Checkbox
                  checked={config.scheduler_center_button}
                  label='Center button in parent container'
                  onChange={(v) => setConfig({ ...config, scheduler_center_button: v })}
                  helpText=""
                />
                <Checkbox
                  checked={config.scheduler_full_width_button}
                  label='Make button full width of parent container'
                  onChange={(v) => setConfig({ ...config, scheduler_full_width_button: v })}
                  helpText=""
                />
                <Checkbox
                  checked={config.scheduler_show_inventory}
                  label='Show number of tickets remaining for each timeslot'
                  onChange={(v) => setConfig({ ...config, scheduler_show_inventory: v })}
                  helpText=""
                />
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>TEXT (for default language: {defaultLocale})</Subheading>
                {TEXT.map((text) =>
                  <TextField
                    key={text.handle}
                    label={text.label}
                    value={strings[text.handle] || text.defaultValue}
                    helpText={text.helpText}
                    onChange={(v) => setStrings({ ...strings, [text.handle]: v })}
                  />
                )}
              </Stack>
            </Stack>
            <SaveBar
              show={hasChanges}
              loading={saving}
              onDiscard={() => {
                setConfig(originalConfig);
                setStrings(originalStrings);
              }}
              onSave={() => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }}
            />
          </Card>
        )}
      </Mutation>
    </>
  );
};

export default SchedulerCard;
