import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Stack, Button, Select, Autocomplete, TextStyle } from '@shopify/polaris';
import styled from '@emotion/styled';
import { ExitMajor, CircleCancelMinor } from '@shopify/polaris-icons';

const GET_ATTENDEES = gql`
  query TemplateEditorAttendeesQuery($eventId: ID!, $category: String, $filters: String, $search: String) {
    currentShop {
      id
      event(id: $eventId) {
        id
        attendees(eventId: $eventId, category: $category, filters: $filters, search: $search) {
          id
          ticketCode
          email
          phonePretty
          ticketTitle
        }
      }
    }
  }
`;

const Container = styled.div`
  display:flex;
  align-items:center;
  width:100%;
  padding: 10px 10px 10px 0;
  background-color: #f9fafb;
  border-bottom:.1rem solid var(--p-border-subdued,#dfe3e8);
`;

const LeftActions = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  >div {
    margin-left:0.8rem;
  }
  >button {
    margin-left:0.8rem;
  }
  flex:1;
`;

const RightActions = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  >div {
    margin-left:0.8rem;
  }
  >button {
    margin-left:0.8rem;
  }
`;

const ExitButtonContainer = styled.div`
  .Polaris-Button {
    @media (max-width: 800px) {
      padding-left:5px;
      padding-right:1px;
    }
  }
  .Polaris-Button__Text {
    @media (max-width: 800px) {
      display:none;
    }
  }
`;

const EventSelectContainer = styled.div`
  .Polaris-Select {
    @media (max-width: 800px) {
      width:85px;
    }
  }
`;

const AttendeeSelectContainer = styled.div`
  max-width:500px;
  width:100%;
  flex:1;
  position:relative;
`;

const CancelSearchButton = styled.div`
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 101;

  .Polaris-Button {
    background:transparent;
    border:none;
  }
`;

const TemplateEditorNavbar = ({ template, onSave, onDiscardChanges, onExit, saving, isDirty, selectedEventId, setSelectedEventId, selectedAttendeeId, setSelectedAttendeeId }) => {
  const [search, setSearch] = React.useState('');
  const events = template && template.events || [];

  const mobileView = window.innerWidth <= 800;

  return (
    <Container>
      <LeftActions>
        <ExitButtonContainer>
          <Button
            icon={ExitMajor}
            onClick={onExit}
            disabled={saving}
          >
            Exit
          </Button>
        </ExitButtonContainer>
        <EventSelectContainer>
          <Select
            label='Event'
            labelInline
            options={events.map((event) => ({ value: event.id, label: event.title }))}
            value={selectedEventId}
            onChange={(v) => {
              setSelectedAttendeeId(null);
              setSelectedEventId(v);
              setSearch('');
            }}
          />
        </EventSelectContainer>
        {selectedEventId &&
          <Query
            query={GET_ATTENDEES}
            variables={{
              'eventId': selectedEventId,
              'category': 'all',
              'filters': JSON.stringify([]),
              'search': search,
            }}
          >
            {({ loading: attendeesLoading, data }) => {

              const formatAttendee = (attendee) => (
                <Stack vertical={false}>
                  <Stack vertical={false} spacing='extraLoose'>
                    <TextStyle variation='subdued'>{attendee.ticketCode}</TextStyle>
                    <div>{attendee.email || attendee.phonePretty}</div>
                    <div>{attendee.ticketTitle}</div>
                  </Stack>
                </Stack>
              );

              const textFormatAttendee = (attendee) => `${attendee.ticketCode} - ${attendee.email || attendee.phonePretty} (${attendee.ticketTitle})`;

              const attendees = data?.currentShop?.event?.attendees || [];

              return (
                <AttendeeSelectContainer>
                  <Autocomplete
                    allowMultiple={false}
                    loading={attendeesLoading}
                    options={attendees.map((attendee) => ({ value: attendee.id, label: formatAttendee(attendee) }))}
                    selected={[selectedAttendeeId]}
                    textField=<Autocomplete.TextField
                      onChange={setSearch}
                      value={search}
                      placeholder={mobileView ? 'Attendee' : 'Sample attendee'}
                      labelHidden
                    />
                    onSelect={(v) => {
                      setSelectedAttendeeId(v[0]);
                      setSearch(textFormatAttendee(attendees.find((a) => a.id == v[0])));
                    }}
                    listTitle="Attendees"
                  />
                  {search && search.length > 0 &&
                    <CancelSearchButton>
                      <Button
                        icon={CircleCancelMinor}
                        onClick={() => {
                          setSelectedAttendeeId(null);
                          setSearch('');
                        }}
                      ></Button>
                    </CancelSearchButton>
                  }
                </AttendeeSelectContainer>
              );
            }}
          </Query>
        }
      </LeftActions>
      <RightActions>
        {isDirty && !mobileView &&
          <Button
            onClick={onDiscardChanges}
            disabled={saving}
          >
            Clear changes
          </Button>
        }
        <Button
          onClick={onSave}
          primary
          loading={saving}
          disabled={!isDirty}
        >
          Save
        </Button>
      </RightActions>
    </Container>
  );
};

export default TemplateEditorNavbar;
