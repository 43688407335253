import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, TextField, ChoiceList, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { EVENTS_BULK_ACTION } from '../../utils/graphql';

const ArchiveEventsModal = ({ shop, eventIds, category, filters, search, onClose }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={EVENTS_BULK_ACTION}
      onError={() => {
        showToastError('Unable to archive events');
        onClose();
      }}
      onCompleted={() => {
        showToastNotice('Archiving events, refresh page to see progress');
        onClose();
      }}
    >
      {(eventsBulkAction, { loading }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Archive events'
          primaryAction={{
            content: 'Yes, archive events',
            loading: loading,
            onAction: () => {
              eventsBulkAction({
                variables: {
                  eventIds,
                  action: 'archive',
                  category,
                  filters: JSON.stringify(filters),
                  search,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Close',
              disabled: loading,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>Archiving these events will remove them from your main events list. You can still access them under the archived events list.</TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ArchiveEventsModal;
