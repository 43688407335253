import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { TextField, Modal, ChoiceList, Banner, FormLayout, InlineError } from '@shopify/polaris';
import { EDIT_REGISTRATION_TEMPLATE, VALIDATE_REGISTRATION_TEMPLATE } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import ModalFooter from '../shared/ModalFooter.jsx';
import { useDebounce } from 'use-debounce';

const EditRegistrationPageCodeModal = ({ shop, onClose }) => {
  const [customizeSelected, setCustomizeSelected] = React.useState([shop.registrationTemplate ? true : false]);
  const [body, setBody] = React.useState(shop.registrationTemplate || shop.defaultRegistrationTemplate);
  const [isValidated, setIsValidated] = React.useState(false);

  const [debouncedBody] = useDebounce(body, 500);

  const { showToastNotice, showToastError } = useFrame();

  const displayableBody = (customizeSelected[0] ? body : shop.defaultRegistrationTemplate) || shop.defaultRegistrationTemplate;

  return (
    <Query
      query={VALIDATE_REGISTRATION_TEMPLATE}
      variables={{ body: debouncedBody }}
      onCompleted={(data) => {
        setIsValidated(true);
      }}
    >
      {({ loading: validating, error, data }) => {

        console.log('validating: ', validating);
        console.log(`data: `, data);

        let isValid = false;
        if (customizeSelected[0]) {
          if (!validating) {
            try {
              const result = JSON.parse(data?.currentShop?.validateRegistrationTemplate);
              isValid = result.valid;
            } catch (error) {
              console.log(`Error: `, error);
            }
          }
        } else {
          isValid = true;
        }

        console.log('isValid: ', isValid);
        console.log(`isValidated: `, isValidated);

        return (
          <Mutation mutation={EDIT_REGISTRATION_TEMPLATE}
            onError={(error) => showToastError('Unable to save')}
            onCompleted={(data) => {
              showToastNotice('Template updated');
              onClose();
            }}
            refetchQueries={() => ['StorefrontIntegrationQuery']}
          >
            {(editRegistrationTemplate, { loading: saving }) => (
              <Modal
                open={true}
                onClose={onClose}
                large={true}
                title='Ticket info page template code'
                footer={
                  <ModalFooter
                    saveButtonText="Save"
                    saveButtonAction={() => {
                      editRegistrationTemplate({
                        variables:{
                          template: customizeSelected[0] ? body : null,
                        }
                      });
                    }}
                    saveButtonLoading={saving}
                    saveButtonDisabled={validating || !isValid}
                    cancelButtonAction={onClose}
                    cancelButtonDisabled={saving}
                    errorMessage={!validating && !isValid ? 'The code has Liquid errors' : undefined}
                  />
                }
              >
                <Modal.Section>
                  <FormLayout>
                    <ChoiceList
                      choices={[
                        { label: 'Use default page template', value: false },
                        { label: 'Customize page template', value: true }
                      ]}
                      selected={customizeSelected}
                      onChange={setCustomizeSelected}
                    />
                    <TextField
                      label='Page template'
                      value={displayableBody}
                      onChange={(v) => {
                        setIsValidated(true);
                        setBody(v);
                      }}
                      disabled={!(customizeSelected[0])}
                      multiline={3}
                    />
                  </FormLayout>
                </Modal.Section>
              </Modal>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default EditRegistrationPageCodeModal;
