import React from 'react';
import { SkeletonBodyText, SkeletonDisplayText, Card, FormLayout } from '@shopify/polaris';

class CardLoading extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Card sectioned>
        <FormLayout>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={5} />
        </FormLayout>
      </Card>
    );
  }
}

export default CardLoading;
