import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Banner, FormLayout, TextField, ChoiceList } from '@shopify/polaris';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import { formatErrors, reportError } from '../../utils/errors';
import { useFrame } from '../../contexts/frame';
import { parseBoolean } from '../../utils/parse';
import { getHostFromShopDomain } from '../../utils/auth';
import ModalFooter from '../shared/ModalFooter.jsx';

const EDIT_EMAIL = gql`
  mutation editEmail($templateId: ID!, $subject: String, $body: String, $sendAsHtml: Boolean) {
    editEmail(templateId: $templateId, subject: $subject, body: $body, sendAsHtml: $sendAsHtml) {
      success
      domain
    }
  }
`;

const TicketEmailForm = ({ email, onCancel }) => {
  const [subject, setSubject] = React.useState(email.subject || email.defaultSubject);
  const [body, setBody] = React.useState(email.body || email.defaultBody);
  const [sendAsHtml, setSendAsHtml] = React.useState([email.sendAsHtml]);
  const [resetDefaultConfirm, setResetDefaultConfirm] = React.useState(false);
  const [customizeSelected, setCustomizeSelected] = React.useState([email.subject || email.body ? true : false]);
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation mutation={EDIT_EMAIL}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setUnknownError(true);
          setErrors([]);
          reportError(error);
        }
        showToastError('Template update failed');
      }}
      onCompleted={(data) => {
        if (data && data.editEmail) {
          let shopHost = getHostFromShopDomain(data.editEmail.domain);
          showToastNotice('Ticket email updated');
          setErrors([]);
          setUnknownError(false);
          onCancel();
          if (window.location.search.indexOf("host") == -1) {
            window.location.href += (window.location.search.substring(0,1) == "?") ? "&host=" + shopHost : "?host=" + shopHost;
          } else {
            window.location.reload();
          }
        }
      }}
      refetchQueries={() => ['EventQuery', 'GetDefaultNotificationTemplate', 'GetTemplatePreview']}
    >
      {(editEmail, { loading: saving }) => {
        return (
          <FormLayout>
            {unknownError &&
            <Banner
              title="There was a problem updating the email template"
              status="critical"
            >
              <p>
                Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
              </p>
            </Banner>
            }
            {errors && errors.length > 0 &&
            <Banner
              title="There was a problem updating the email template"
              status="critical"
            >
              {formatErrors(errors)}
            </Banner>
            }
            <ChoiceList
              title=''
              choices={[
                { label: 'Use default email template', value: false },
                { label: 'Customize email template', value: true }
              ]}
              selected={customizeSelected}
              onChange={(v) => {
                setCustomizeSelected(v);
                if (!v[0]) {
                  setBody(email.defaultBody);
                  setSubject(email.defaultSubject);
                }
              }}
            />
            <TextField key="subject" id='subject' value={subject || ''} label="Subject" placeholder="" onChange={setSubject} disabled={!(customizeSelected[0])} />
            <div>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                  <label id="bodyLabel" className="Polaris-Label__Text">Body</label>
                </div>
              </div>
              <div className="TicketEmailEdit__AceEditor" style={{position: 'relative'}}>
                {!customizeSelected[0] &&
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#5c5c5c',
                    opacity: '0.05',
                    zIndex: '1000'
                  }}></div>
                }
                <AceEditor
                  mode="liquid"
                  theme="tomorrow"
                  onChange={setBody}
                  value={body || ''}
                  name="emailBody"
                  fontSize='1.4rem'
                  width='100%'
                  height='300px'
                  showPrintMargin={false}
                  showGutter={false}
                  highlightActiveLine={true}
                  setOptions={{
                    showLineNumbers: false,
                    tabSize: 2,
                    fontSize: 'inherit',
                  }}
                  editorProps={{$blockScrolling: true}}
                  readOnly={!(customizeSelected[0])}
                  focus={customizeSelected[0]}
                />
              </div>
            </div>
            <ChoiceList
              title=''
              disabled={!(customizeSelected[0])}
              choices={[
                { label: 'Send as HTML (default, select this if you\'re not sure)', value: true },
                { label: 'Send as plain text', value: false },
              ]}
              selected={customizeSelected[0] ? sendAsHtml : [true]}
              onChange={setSendAsHtml}
            />
            <ModalFooter
              cancelButtonAction={onCancel}
              cancelButtonDisabled={saving}
              saveButtonText='Save'
              saveButtonLoading={saving}
              saveButtonAction={() => {
                editEmail({
                  variables:{
                    templateId: email.id,
                    subject: customizeSelected[0] ? subject : null,
                    body: customizeSelected[0] ? body : null,
                    sendAsHtml: customizeSelected[0] ? parseBoolean(sendAsHtml) : true,
                  },
                });
              }}
            />
          </FormLayout>
        );
      }}
    </Mutation>
  );
};

export default TicketEmailForm;
