import React from 'react';
import { Card, Stack, DisplayText, TextContainer, Button, Icon, Select, TextStyle } from '@shopify/polaris';
import { TickMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import styled from '@emotion/styled';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const PlanCardContainer = styled.div`
  height: 100%;
  margin-right:7px;
  margin-left:7px;
  margin-bottom:30px;
  height:725px;
  width:350px;

  .Polaris-Card {
    height: 100%;
    padding-bottom: 10px;
  }
  .Polaris-Card__Section {
    height:100%;
  }
  .Polaris-Card__Section>.Polaris-Stack {
    height:100%;
  }
`;

const PerTicketDisplay = styled.div`
  font-size: 16px;
  /* color: rgba(109, 113, 117, 1); */
`;

const FeaturesContainer = styled.div`
  flex-grow: 1;
`;

const FeatureItemContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const FeatureItemIcon = styled.div`
  flex: 0;
  margin-right: 5px;
`;

const FeatureItemText = styled.div`
  flex: 1;
`;

const FeatureItemTextStrike = styled.div`
  flex: 1;
  text-decoration: line-through;
`;

const FeatureItem = ({ enabled, children }) => {
  return (
    <FeatureItemContainer>
      <FeatureItemIcon>
        {enabled ? (
          <Icon source={TickMinor} color="inkLighter" />
        ) : (
          <div style={{ width: '20px', height: '20px' }}>&nbsp;</div>
        )}
      </FeatureItemIcon>
      {enabled ? (
        <FeatureItemText>{children}</FeatureItemText>
      ) : (
        <FeatureItemTextStrike>{children}</FeatureItemTextStrike>
      )}
    </FeatureItemContainer>
  );
};

const PlanCard = ({ shop, plan, activeSubscription, onSelect, selecting, disabled }) => {
  const isActivePlan = activeSubscription && activeSubscription.plan && activeSubscription.plan.id === plan.id
  const price = parseFloat(plan.price);
  const features = JSON.parse(plan.features);

  const trialExpiresAt = dayjs(shop.trialExpiresAt).endOf('d');
  const today = dayjs().startOf('d');
  const remainingTrialDays = Math.max((trialExpiresAt.isValid() ? trialExpiresAt.diff(today, 'd') : 0), 0);

  return (
    <PlanCardContainer>
      <Card title={plan.name}>
        <Card.Section>
          <Stack vertical={true} spacing='extraLoose' alignment='fill'>
            <Stack.Item>
              <Stack vertical={true} spacing='extraTight'>
                <DisplayText size="medium">${price.toString()} / month</DisplayText>
                <PerTicketDisplay>+ ${plan.perTicketPrice} per ticket sold</PerTicketDisplay>
              </Stack>
            </Stack.Item>
            <Stack.Item fill>
              <Stack vertical={true} spacing='extraTight'>
                <FeatureItem enabled>Unlimited events and ticket types</FeatureItem>
                {/*plan.name !== 'Lite' && <FeatureItem>All features from {plan.name == 'Professional' ? 'Basic' : (plan.name == 'Advanced' ? 'Professional' : 'previous')} plan</FeatureItem>*/}
                <FeatureItem enabled>Customize email and web/PDF/Apple Wallet tickets</FeatureItem>
                <FeatureItem enabled>Collect ticket info before checkout</FeatureItem>
                <FeatureItem enabled>Import and export attendees</FeatureItem>
                <FeatureItem enabled>Storefront events calendars</FeatureItem>
                <FeatureItem enabled={features.checkin}>Check-In with phone/tablet apps using camera or scanner</FeatureItem>
                <FeatureItem enabled={features.mailchimp}>Mailchimp and Klaviyo integrations</FeatureItem>
                <FeatureItem enabled={features.messages}>Attendee messages & scheduled reminders</FeatureItem>
                <FeatureItem enabled={features.recurring}>Recurring events</FeatureItem>
                <FeatureItem enabled={features.seating}>Reserved seating</FeatureItem>
                <FeatureItem enabled={features.pos}>Shopify POS integration</FeatureItem>
                <FeatureItem enabled={features.shopify_flow}>Shopify Flow integration</FeatureItem>
                {features.dedicated_support && <FeatureItem enabled>Dedicated customer support</FeatureItem>}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack vertical={true} spacing='extraTight'>
                { remainingTrialDays > 0 && plan.name !== 'Lite' && 
                  <DisplayText size='extraSmall'>{remainingTrialDays} trial days remaining</DisplayText>
                }
                <Button
                  fullWidth
                  loading={selecting}
                  disabled={disabled || (activeSubscription && activeSubscription.plan.id == plan.id)}
                  onClick={() => onSelect()}
                  primary
                >
                  {activeSubscription && activeSubscription.plan.id == plan.id ? 'Current plan' : 'Select plan'}
                </Button>
              </Stack>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </PlanCardContainer>
  );
};

export default PlanCard;
