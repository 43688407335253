import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Modal,
  FormLayout,
  TextField,
  Banner,
  Link,
} from '@shopify/polaris';
import { useState } from 'react';
import { formatErrors, reportError } from '../../utils/errors';
import { EDIT_CHECKIN_USER, EDIT_CHECKIN_USER_PERMISSIONS } from '../../utils/graphql';
import { CHECKIN_HOST } from '../../utils/checkinHost';
import ModalFooter from '../shared/ModalFooter.jsx';
import { useFrame } from '../../contexts/frame';

const EditUserOnEventModal = ({ event, user, onClose }) => {
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { showToastNotice, showToastError } = useFrame();

  const forgotPasswordUrl = `${CHECKIN_HOST}/forgot_password`;

  const isValid = passwordConfirm.length && password == passwordConfirm;

  return (
    <Mutation mutation={EDIT_CHECKIN_USER}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
        } else {
          setErrors(['There was a problem updating this user. Contact us if this problem persists.']);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editCheckinUser) {
          showToastNotice('User updated');
          onClose();
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editCheckinUser, { loading: saving }) => (
        <Mutation mutation={EDIT_CHECKIN_USER_PERMISSIONS}
          onError={() => {
            showToastError('Failed to revoke access');
          }}
          onCompleted={(data) => {
            if (data && data.editCheckinUserPermissions) {
              showToastNotice('User access revoked');
              onClose();
            }
          }}
          refetchQueries={() => ['EventQuery']}
        >
          {(editCheckinUserPermissions, { loading: deleting }) => (
            <Modal
              open={true}
              onClose={() => onClose()}
              title={'Update check-in user'}
              footer={
                <ModalFooter
                  // saveButtonText="Save"
                  // saveButtonAction={async () => {
                  //   setErrors([]);
                  //   editCheckinUser({
                  //     variables: {
                  //       userId: user.id,
                  //       password,
                  //       passwordConfirm,
                  //       fullAccess: user.fullAccess,
                  //     }
                  //   });
                  // }}
                  // saveButtonLoading={saving}
                  // saveButtonDisabled={saving || deleting || !isValid}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonLoading={deleting}
                  destructiveButtonText={'Remove from event'}
                  destructiveButtonAction={user.fullAccess ? undefined : async () => {
                    setErrors([]);
                    const permissions = user.permissions.filter((p) => p.eventId != event.eveyEventId).map((p) => ({ event_id: p.eventId }));
                    editCheckinUserPermissions({
                      variables: {
                        userId: user.id,
                        permissions: JSON.stringify(permissions),
                      }
                    });
                  }}
                  destructiveButtonDisabled={saving || deleting}
                />
              }
            >
              {errors && errors.length > 0 &&
                <Modal.Section>
                  <Banner
                    title="There was a problem updating the user"
                    status="critical"
                  >
                    {formatErrors(errors)}
                  </Banner>
                </Modal.Section>
              }
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label='Email'
                    type='email'
                    value={user.email}
                    readOnly
                    helpText=<span>To reset your password, go to the <Link external url={forgotPasswordUrl}>forgot password</Link> page.</span>
                  />
                  {/*
                  <TextField
                    label='Password (blank to keep the same)'
                    type='password'
                    value={password}
                    onChange={(newValue) => setPassword(newValue)}
                  />
                  <TextField
                    label='Confirm Password'
                    type='password'
                    value={passwordConfirm}
                    onChange={(newValue) => setPasswordConfirm(newValue)}
                  />
                  */}
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default EditUserOnEventModal;
