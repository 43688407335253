import gql from "graphql-tag";

export const EVENT_FIELDS = `
  id
  title
  description
  imageUrl
  locationType
  location
  locationUrl
  locationId
  locationCode
  savedLocation {
    id
    seatingChart {
      id
      variantOptionZoneName
      seatingZones {
        id
        name
      }
    }
  }
  hasSeating
  destination
  destinationUrl
  destinationCode
  destinationId
  organizer {
    id
  }
  transitType
  hasDate
  startAt
  startAtUtc
  endAt
  endAtUtc
  timezone
  timezoneLabel
  published
  inventory
  scheduleCapacity
  hasCrossVariantInventory
  hasEtickets
  waitlistEnabled
  waitlistKlaviyoIntegrationEnabled
  autoSendEtickets
  deliverOnFulfill
  hasPdfTicketType
  hasPassbookTicketType
  hasIcsTicketType
  hasCalendarOptions
  allowTransfers
  contactEmail
  ticketNumberStart
  randomTicketNumberEnd
  orderStatusEnabled
  publicProductUrl
  productId
  isMissingProduct
  isApiEventMissing
  isShopifySubscription
  isExpired
  expiresAt
  trial
  subscribed
  tags
  saveProductTags
  saveOrderTags
  price
  product {
    id
    title
    metafields {
      namespace
      key
      value
    }
    options {
      name
    }
  }
  attendeeAttributeSpecs {
    name
    label
    type
    options
  }
  tickets {
    id
    title
    price
    visible
    inventory
    variantId
    groupSize
    groupIndividualTickets
    variant {
      id
      title
      price
      option1
      option2
      option3
    }
    eventDate
    eventEndDate
    seatingZoneId
    isDeleted
    ticketFee
    location {
      id
      name
    }
    destinationLocation {
      id
      name
    }
  }
  checkinUrl
  sampleCheckinUrl
  ticketCodeTemplate
  barcodeValueTemplate
  publishSchedule {
    id
    action
    dateType
    relativeMinutes
    customDate
    params
    triggeredAt
  }
  unpublishSchedule {
    id
    action
    dateType
    relativeMinutes
    customDate
    params
    triggeredAt
  }
  scheduledPublishDate
  scheduledUnpublishDate
  sampleTicketPageUrl
  ticketPage {
    id
  }
  pdfTicket {
    id
  }
  passbook {
    id
  }
`;

export const EDIT_EVENT = gql`
  mutation editEvent(
      $eventId: ID!, $title: String, $description: String, $imageUrl: String, $location: String, $locationUrl: String, $locationCode: String, $destination: String, $destinationUrl: String, $destinationCode: String, $transitType: String, $dateType: String, $startAt: String, $endAt: String,
      $timezone: String, $published: Boolean, $hasCrossVariantInventory: Boolean, $inventory: Int,
      $hasEtickets: Boolean, $autoSendEtickets: Boolean, $deliverOnFulfill: Boolean, $hasPdfTicketType: Boolean,
      $hasPassbookTicketType: Boolean, $hasIcsTicketType: Boolean,
      $allowTransfers: Boolean, $contactEmail: String, $ticketNumberStart: Int, $randomTicketNumberEnd: Int,
      $orderStatusEnabled: Boolean, $tickets: String, $tags: [String!], $saveProductTags: Boolean, $saveOrderTags: Boolean, $metafields: String, $scheduleItems: String,
      $createCustomers: Boolean, $customerEventTag: String, $customerPurchaserTag: String, $locationType: String, $locationId: ID, $destinationId: ID, $organizerId: ID,
      $sharedAttendeeFieldsTemplateId: ID, $waitlistEnabled: Boolean, $waitlistKlaviyoIntegrationEnabled: Boolean
    ) {

    editEvent(
        eventId: $eventId, title: $title, description: $description, imageUrl: $imageUrl, location: $location, locationUrl: $locationUrl, locationCode: $locationCode, destination: $destination, destinationUrl: $destinationUrl, destinationCode: $destinationCode, transitType: $transitType, dateType: $dateType, startAt: $startAt, endAt: $endAt,
        timezone: $timezone, published: $published, hasCrossVariantInventory: $hasCrossVariantInventory,
        inventory: $inventory, hasEtickets: $hasEtickets, autoSendEtickets: $autoSendEtickets, deliverOnFulfill: $deliverOnFulfill,
        hasPdfTicketType: $hasPdfTicketType, hasPassbookTicketType: $hasPassbookTicketType,
        hasIcsTicketType: $hasIcsTicketType, allowTransfers: $allowTransfers,
        contactEmail: $contactEmail, ticketNumberStart: $ticketNumberStart, randomTicketNumberEnd: $randomTicketNumberEnd,
        orderStatusEnabled: $orderStatusEnabled, tickets: $tickets, tags: $tags, saveProductTags: $saveProductTags, saveOrderTags: $saveOrderTags, metafields: $metafields, scheduleItems: $scheduleItems,
        createCustomers: $createCustomers, customerEventTag: $customerEventTag, customerPurchaserTag: $customerPurchaserTag,
        locationType: $locationType, locationId: $locationId, destinationId: $destinationId, organizerId: $organizerId,
        sharedAttendeeFieldsTemplateId: $sharedAttendeeFieldsTemplateId, waitlistEnabled: $waitlistEnabled, waitlistKlaviyoIntegrationEnabled: $waitlistKlaviyoIntegrationEnabled
      ) {
      event {
        ${EVENT_FIELDS}
      }
      nextUrl
    }
  }
`;

export const ATTENDEE_INFO_SPECS_FIELDS = `
  name
  label
  type
  options
  required
  hide_storefront
  allow_multiple
  ticket_types
  hide_ticket_page
  readonly_ticket_page
  checkin_hide
  checkin_editable
  conditions {
    field
    op
    value
  }
`;

export const GET_ATTENDEE_ATTRIBUTE_SPECS = gql`
  query AttendeeAttributeSpecsQuery($eventId: ID!) {
    currentShop {
      attendeeFieldsTemplates {
        id
        name
        specs {
          ${ATTENDEE_INFO_SPECS_FIELDS}
        }
        specCount
      }
      attendeeInfoStorefrontEnabled
      event(id: $eventId) {
        sharedAttendeeFieldsTemplate {
          id
        }
        attendeeAttributeSpecs {
          ${ATTENDEE_INFO_SPECS_FIELDS}
        }
        tickets {
          id
          title
          attendeeFieldsTemplateId
        }
      }
    }
  }
`;

export const EDIT_ATTENDEE_ATTRIBUTE_SPECS = gql`
  mutation editAttendeeAttributeSpecs($eventId: ID, $templateId: ID, $specs: String!) {
    editAttendeeAttributeSpecs(eventId: $eventId, templateId: $templateId, specs: $specs) {
      attendeeAttributeSpecs {
        ${ATTENDEE_INFO_SPECS_FIELDS}
      }
    }
  }
`;

export const EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION = gql`
  mutation editAttendeeInfoStorefrontIntegration(
    $enabled: Boolean
    $config: String
    $strings: String
    $syncCollection: Boolean
  ) {
    editAttendeeInfoStorefrontIntegration(
      enabled: $enabled
      config: $config
      strings: $strings
      syncCollection: $syncCollection
    ) {
      enabled
      config
      strings
    }
  }
`;

export const EDIT_CALENDAR_TEMPLATE = gql`
  mutation editCalendarTemplate($calendarTemplate: String) {
    editCalendarTemplate(calendarTemplate: $calendarTemplate) {
      success
    }
  }
`;

export const EDIT_REGISTRATION_TEMPLATE = gql`
  mutation editRegistrationTemplate($template: String) {
    editRegistrationTemplate(template: $template) {
      success
    }
  }
`;

export const VALIDATE_REGISTRATION_TEMPLATE = gql`
  query ValidateRegistrationTemplate($body: String!) {
    currentShop {
      validateRegistrationTemplate(body: $body)
    }
  }
`;

export const GET_STOREFRONT_INTEGRATION = gql`
  query StorefrontIntegrationQuery {
    currentShop {
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      proxyUrl
      proxyPath
      adminUrl
      attendeeInfoStorefrontEnabled
      attendeeInfoStorefrontConfig
      appEmbedUrl
      eventsCalendarTemplate
      defaultEventsCalendarTemplate
      registrationTemplate
      defaultRegistrationTemplate
      features
      eventTags
      hasSeating
      defaultLocaleFile {
        id
        locale
        content
      }
      allEvents {
        id
        title
        eveyEventId
      }
      collections {
        id
        handle
        title
        collectionType
        sortOrder
      }
    }
  }
`;

export const SEND_TICKET = gql`
  mutation sendTicket($eventId: ID!, $attendeeId: ID!) {
    sendTicket(eventId: $eventId, attendeeId: $attendeeId) {
      success
    }
  }
`;

export const CREATE_CHECKIN_USER = gql`
  mutation createCheckinUser(
    $email: String!
    $password: String!
    $passwordConfirm: String!
    $eventId: ID
    $fullAccess: Boolean
  ) {
    createCheckinUser(
      email: $email
      password: $password
      passwordConfirm: $passwordConfirm
      eventId: $eventId
      fullAccess: $fullAccess
    ) {
      user {
        id
      }
    }
  }
`;

export const EDIT_CHECKIN_USER = gql`
  mutation editCheckinUser(
    $userId: ID!
    $password: String
    $passwordConfirm: String
    $fullAccess: Boolean
  ) {
    editCheckinUser(
      userId: $userId
      password: $password
      passwordConfirm: $passwordConfirm
      fullAccess: $fullAccess
    ) {
      user {
        id
      }
    }
  }
`;

export const DELETE_CHECKIN_USER = gql`
  mutation deleteCheckinUser($userId: ID!) {
    deleteCheckinUser(userId: $userId) {
      success
    }
  }
`;

export const EDIT_CHECKIN_USER_PERMISSIONS = gql`
  mutation editCheckinUserPermissions($userId: ID!, $permissions: String!) {
    editCheckinUserPermissions(userId: $userId, permissions: $permissions) {
      success
    }
  }
`;

export const GET_CHECKIN_SETTINGS = gql`
  query CheckinSettingsQuery {
    currentShop {
      domain
      name
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      allEvents {
        id
        eveyEventId
        title
      }
      checkinUsers {
        id
        createdAt
        email
        checkedinAttendeesCount
        fullAccess
        permissions {
          id
          eventId
          permissions
        }
      }
    }
  }
`;

export const EDIT_VIRTUAL_EVENT_PAGE = gql`
  mutation editVirtualEventPage(
    $eventId: ID!
    $config: String
    $service: String
    $serviceUrl: String
    $embedVideo: Boolean
    $requirePassword: Boolean
    $restrictSessions: Boolean
    $showPasswordInNotification: Boolean
    $openDate: String
    $closeDate: String
    $showUrlForTicketTypes: String
    $enabled: Boolean
  ) {
    editVirtualEventPage(
      eventId: $eventId
      config: $config
      service: $service
      serviceUrl: $serviceUrl
      embedVideo: $embedVideo
      requirePassword: $requirePassword
      restrictSessions: $restrictSessions
      showPasswordInNotification: $showPasswordInNotification
      openDate: $openDate
      closeDate: $closeDate
      showUrlForTicketTypes: $showUrlForTicketTypes
      enabled: $enabled
    ) {
      virtualEventPage {
        id
      }
    }
  }
`;

export const EDIT_VIRTUAL_EVENT_PAGE_CODE = gql`
  mutation editVirtualEventPageCode($eventId: ID!, $body: String) {
    editVirtualEventPageCode(eventId: $eventId, body: $body) {
      virtualEventPage {
        id
      }
    }
  }
`;

export const REVOKE_VIRTUAL_EVENT_SESSION = gql`
  mutation revokeVirtualEventSession($eventId: ID!, $id: String!) {
    revokeVirtualEventSession(eventId: $eventId, id: $id) {
      success
    }
  }
`;

export const EDIT_SCHEDULED_TIME = gql`
  mutation editScheduledTime(
    $eventId: ID!
    $attendeeId: ID!
    $start: String
    $scheduleItemId: ID
  ) {
    editScheduledTime(
      eventId: $eventId
      attendeeId: $attendeeId
      start: $start
      scheduleItemId: $scheduleItemId
    ) {
      success
    }
  }
`;

export const CREATE_OR_UPDATE_TICKET_TYPE = gql`
  mutation createOrUpdateTicketType(
    $eventId: ID!
    $ticketTypeId: ID
    $option1: String
    $option2: String
    $option3: String
    $groupSize: Int
    $inventory: Int
    $price: String
    $visible: Boolean
    $eventDate: String
    $eventEndDate: String
    $groupIndividualTickets: Boolean
    $seatingZoneId: ID
    $ticketFee: String
    $locationId: ID
    $destinationId: ID
  ) {
    createOrUpdateTicketType(
      eventId: $eventId
      ticketTypeId: $ticketTypeId
      option1: $option1
      option2: $option2
      option3: $option3
      groupSize: $groupSize
      inventory: $inventory
      price: $price
      visible: $visible
      eventDate: $eventDate
      eventEndDate: $eventEndDate
      groupIndividualTickets: $groupIndividualTickets
      seatingZoneId: $seatingZoneId
      ticketFee: $ticketFee
      locationId: $locationId
      destinationId: $destinationId
    ) {
      success
    }
  }
`;

export const DELETE_TICKET_TYPE = gql`
  mutation deleteTicketType($eventId: ID!, $ticketTypeId: ID!) {
    deleteTicketType(eventId: $eventId, ticketTypeId: $ticketTypeId) {
      success
    }
  }
`;

export const GET_ATTENDEES = gql`
  query AttendeesQuery(
    $eventId: ID!
    $category: String
    $filters: String
    $search: String
    $page: Int
    $perPage: Int
  ) {
    currentShop {
      id
      name
      domain
      trialExpired
      event(id: $eventId) {
        id
        hasPdfTicketType
        hasPassbookTicketType
        hasIcsTicketType
        dateType
        hasSeating
        tickets {
          id
          title
          price
          inventory
          variantId
          groupSize
          variant {
            id
            title
            price
            option1
            option2
            option3
          }
        }
        scheduleItems {
          id
          name
          startDate
          endDate
          allDay
          startTime
          duration
          period
          periodNumber
          scheduleExceptions {
            startDate
            startTime
          }
          ticketTypes
        }
        zoom {
          id
          active
          meetingId
        }
        attendees(
          eventId: $eventId
          category: $category
          filters: $filters
          search: $search
          page: $page
          perPage: $perPage
        ) {
          id
          number
          ticketCode
          name
          firstName
          lastName
          email
          phone
          phonePretty
          createdAt
          manageUrl
          ticketUrl
          orderName
          remoteOrderId
          ticketTitle
          checkedinAt
          eticketSentAt
          deliverable
          pdfTicketUrl
          passbookTicketUrl
          icsTicketUrl
          cancelledAt
          cancelReason
          refundedAt
          checkinCount
          checkinLimit
          eventStartAt
          eventEndAt
          scheduledEvent
          seat {
            id
            label
          }
          zoomRegistered
        }
        attendeesCounts(
          eventId: $eventId
          category: $category
          filters: $filters
          search: $search
        )
        attendeeReports {
          id
          name
          lastSentAt
          email
          period
          filters
        }
      }
    }
  }
`;

export const BULK_ACTION = gql`
  mutation attendeesBulkAction(
    $eventId: ID!
    $action: String!
    $attendeeIds: [String!]
    $email: String!
    $filters: String
    $search: String
    $exportFormat: String
    $category: String
  ) {
    attendeesBulkAction(
      eventId: $eventId
      action: $action
      attendeeIds: $attendeeIds
      email: $email
      filters: $filters
      search: $search
      exportFormat: $exportFormat
      category: $category
    ) {
      status
      email
    }
  }
`;

export const QUEUERS_EXPORT = gql`
  mutation queuersExport($eventId: ID!) {
    queuersExport(eventId: $eventId) {
      content
    }
  }
`;

export const EDIT_REPORT = gql`
  mutation editAttendeeReport(
    $eventId: ID!
    $reportId: ID
    $name: String
    $email: String
    $filters: String
    $search: String
    $category: String
  ) {
    editAttendeeReport(
      eventId: $eventId
      reportId: $reportId
      name: $name
      email: $email
      filters: $filters
      search: $search
      category: $category
    ) {
      attendeeReports {
        id
        name
        lastSentAt
        email
        period
        filters
      }
      attendeeReport {
        id
        name
        lastSentAt
        email
        period
        filters
      }
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteAttendeeReport($eventId: ID!, $reportId: ID) {
    deleteAttendeeReport(eventId: $eventId, reportId: $reportId) {
      attendeeReports {
        id
        name
        lastSentAt
        email
        period
        filters
      }
    }
  }
`;

export const GET_QUEUERS = gql`
  query QueuersQuery(
    $eventId: ID!
    $page: Int
    $perPage: Int
  ) {
    currentShop {
      id
      name
      domain
      event(id: $eventId) {
        id
        hasPdfTicketType
        hasPassbookTicketType
        hasIcsTicketType
        dateType
        hasSeating
        title
        tickets {
          id
          title
          price
          inventory
          variantId
          groupSize
          variant {
            id
            title
            price
            option1
            option2
            option3
          }
        }
        scheduleItems {
          id
          name
          startDate
          endDate
          allDay
          startTime
          duration
          period
          periodNumber
          scheduleExceptions {
            startDate
            startTime
          }
          ticketTypes
        }
        zoom {
          id
          active
          meetingId
        }
        queuers(
          eventId: $eventId
          page: $page
          perPage: $perPage
        ) {
          id
          datetime
          fullName
          email
          quantity
          ticketTypeName
          createdAt
        }
        queuersTotal(
          eventId: $eventId
        )
      }
    }
  }
`;
export const SAVE_SCHEDULE_ITEM = gql`
  mutation saveScheduleItem(
    $eventId: ID!
    $itemId: ID
    $name: String
    $startDate: String
    $endDate: String
    $allDay: Boolean
    $startTime: String
    $duration: Int
    $period: String
    $periodNumber: Int
    $ticketTypes: String
  ) {
    saveScheduleItem(
      eventId: $eventId
      itemId: $itemId
      name: $name
      startDate: $startDate
      endDate: $endDate
      allDay: $allDay
      startTime: $startTime
      duration: $duration
      period: $period
      periodNumber: $periodNumber
      ticketTypes: $ticketTypes
    ) {
      success
    }
  }
`;

export const DELETE_SCHEDULE_ITEM = gql`
  mutation deleteScheduleItem($eventId: ID!, $itemId: ID!) {
    deleteScheduleItem(eventId: $eventId, itemId: $itemId) {
      success
    }
  }
`;

export const DELETE_SCHEDULE_EVENT = gql`
  mutation deleteScheduleEvent(
    $eventId: ID!
    $itemId: ID!
    $startDate: String!
    $startTime: String!
  ) {
    deleteScheduleEvent(
      eventId: $eventId
      itemId: $itemId
      startDate: $startDate
      startTime: $startTime
    ) {
      success
    }
  }
`;

export const GET_INVENTORY_LEVELS_FOR_DATE = gql`
  query InventoryLevelForDateQuery($eventId: ID!, $date: String, $seatId: ID) {
    currentShop {
      event(id: $eventId) {
        seat(id: $seatId) {
          id
          label
          capacity
        }
        savedLocation {
          seatingChart {
            seatingZones {
              id
              name
              seatsCount
            }
          }
        }
        inventoryLevels(date: $date, seatId: $seatId) {
          id
          eventTicket {
            id
            title
            isDeleted
          }
          startDate
          startTime
          capacity
          ticketsSold
          seat {
            id
            label
            seatingZone {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_RECURRING_EVENT_INSTANCE_REVENUE = gql`
  query GetRecurringEventInstanceRevenue($eventId: ID!, $ts: String!) {
    currentShop {
      event(id: $eventId) {
        recurringEventInstanceRevenue(ts: $ts)
      }
    }
  }
`;

export const SAVE_INVENTORY_LEVELS = gql`
  mutation saveInventoryLevels($eventId: ID!, $inventoryLevels: String!) {
    saveInventoryLevels(eventId: $eventId, inventoryLevels: $inventoryLevels) {
      success
    }
  }
`;

export const GET_EVENT_STATS = gql`
  query EventStatsQuery($eventId: ID!) {
    currentShop {
      event(id: $eventId) {
        ticketSalesByDate
        totalRevenue
        ticketStats
        totalTicketsSold
        capacity
        hasCrossVariantInventory
      }
    }
  }
`;

export const COPY_EVENT = gql`
  mutation copyEvent(
    $eventId: ID!
    $title: String
    $draft: Boolean!
    $copySchedules: Boolean
  ) {
    copyEvent(
      eventId: $eventId
      title: $title
      draft: $draft
      copySchedules: $copySchedules
    ) {
      jobId
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: ID!, $deleteProduct: Boolean) {
    deleteEvent(eventId: $eventId, deleteProduct: $deleteProduct) {
      status
    }
  }
`;

export const EDIT_ATTENDEE = gql`
  mutation editAttendee(
    $eventId: ID!
    $attendeeId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $ticketTypeId: ID
    $ticketTypeTitle: String
    $attendeeAttributes: String
    $seatId: ID
  ) {
    editAttendee(
      eventId: $eventId
      attendeeId: $attendeeId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      ticketTypeId: $ticketTypeId
      ticketTypeTitle: $ticketTypeTitle
      attendeeAttributes: $attendeeAttributes
      seatId: $seatId
    ) {
      attendee {
        id
        firstName
        lastName
        email
        phone
        ticket {
          id
          title
          groupSize
          variant {
            id
            title
          }
        }
        attendeeAttributes {
          value
          spec {
            name
            label
            type
            options
            ticket_types
          }
        }
        seat {
          id
          label
        }
      }
      nextUrl
    }
  }
`;

export const EDIT_TICKET_CODE_TEMPLATE = gql`
  mutation editTicketCodeTemplate($eventId: ID!, $codeTemplate: String!) {
    editTicketCodeTemplate(eventId: $eventId, codeTemplate: $codeTemplate) {
      success
    }
  }
`;

export const EDIT_BARCODE_TEMPLATE = gql`
  mutation editBarcodeTemplate($eventId: ID!, $codeTemplate: String) {
    editBarcodeTemplate(eventId: $eventId, codeTemplate: $codeTemplate) {
      success
    }
  }
`;

export const GET_SHARED_TEMPLATE_OPTIONS = gql`
  query SharedTemplateOptionsQuery($templateType: String!) {
    currentShop {
      defaultNotificationsTemplate {
        id
      }
      sharedTemplateOptions(templateType: $templateType)
    }
  }
`;

export const EDIT_SHARED_TEMPLATE = gql`
  mutation editSharedTemplate(
    $eventId: ID!
    $templateType: String!
    $templateId: ID
  ) {
    editSharedTemplate(
      eventId: $eventId
      templateType: $templateType
      templateId: $templateId
    ) {
      success
    }
  }
`;

export const GET_CHECKIN_USERS = gql`
  query CheckinUsersQuery($eventId: ID!) {
    currentShop {
      event(id: $eventId) {
        checkinUsers {
          id
          createdAt
          email
          checkedinAttendeesCount
        }
      }
    }
  }
`;

export const GET_VIRTUAL_EVENT_STATS = gql`
  query GetVirtualEventStats($eventId: ID!) {
    currentShop {
      event(id: $eventId) {
        virtualEventPageUrlBase
        virtualEventPage {
          config
          body
          service
          serviceUrl
          embedVideo
          requirePassword
          restrictSessions
          showPasswordInNotification
          openDate
          closeDate
          defaultBody
        }
      }
    }
  }
`;

export const GET_RECURRING_EVENT_STATS = gql`
  query GetRecurringEventStats($eventId: ID!) {
    currentShop {
      moneyFormat
      event(id: $eventId) {
        scheduleItems {
          id
          name
          startDate
          endDate
          allDay
          startTime
          duration
          period
          periodNumber
          scheduleExceptions {
            startDate
            startTime
          }
          ticketTypes
        }
        tickets {
          id
          title
          price
          visible
          inventory
          variantId
          groupSize
          variant {
            id
            title
            price
            option1
            option2
            option3
          }
          eventDate
        }
      }
    }
  }
`;

export const GET_STOREFRONT_URL = gql`
  query GetStorefrontUrl($eventId: ID!) {
    currentShop {
      event(id: $eventId) {
        storefrontUrl
      }
    }
  }
`;

export const NOTIFICATION_TEMPLATE_FIELDS = `
  id
  subject
  defaultSubject
  body
  defaultBody
  sendAsHtml
  attachFiles

  reminderSubject
  reminderBody
  reminderSendAsHtml
  defaultReminderSubject
  defaultReminderBody

  hasMultipleEmail
  multiTicketSubject
  multiTicketBody
  defaultMultiTicketSubject
  defaultMultiTicketBody
  multiTicketSendAsHtml
  multiTicketThreshold

  smsBody
  defaultSmsBody
  reminderSmsBody
  defaultReminderSmsBody

  config
  sharedEventsCount
  preferEmail
`;

export const GET_DEFAULT_NOTIFICATION_TEMPLATE = gql`
  query GetDefaultNotificationTemplate {
    currentShop {
      name
      domain
      defaultNotificationsTemplate {
        ${NOTIFICATION_TEMPLATE_FIELDS}
        events {
          id
          title
        }
      }
    }
  }
`;

export const SEND_NOTIFICATION_TEST = gql`
  mutation sendNotificationTest(
    $eventId: ID!
    $notificationType: String!
    $email: String
    $phone: String
    $attendeeId: ID
  ) {
    sendNotificationTest(
      eventId: $eventId
      notificationType: $notificationType
      email: $email
      phone: $phone
      attendeeId: $attendeeId
    ) {
      success
      message
    }
  }
`;

export const EVENTS_BULK_ACTION = gql`
  mutation eventsBulkAction(
    $eventIds: [ID!]
    $action: String!
    $email: String
    $category: String
    $filters: String
    $search: String
    $options: String
  ) {
    eventsBulkAction(
      eventIds: $eventIds
      action: $action
      email: $email
      category: $category
      filters: $filters
      search: $search
      options: $options
    ) {
      status
      email
    }
  }
`;

export const GET_SHOP_INTEGRATIONS = gql`
  query GetShopIntegrations {
    currentShop {
      id
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      mailchimp {
        id
        username
        email
        token
        syncListId
        syncListName
        tagEventName
        tagTicketType
        customTags
        audiences {
          id
          name
        }
      }
      klaviyo {
        id
        publicApiKey
        apiKey
        syncListId
        syncListName
        tagEventName
        tagTicketType
        customTags
        lists {
          id
          name
        }
      }
      zoom {
        id
        uid
        hasAccessToken
        email
        registerPolicy
        user {
          id
          first_name
          last_name
          email
          type
          pic_url
          status
        }
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query EventsQuery(
    $category: String
    $sort: String
    $filters: String
    $search: String
    $page: Int
    $perPage: Int
  ) {
    currentShop {
      id
      domain
      eventTags
      name
      email
      firstName
      lastName
      phone
      countryCode
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
        plan {
          name
          hubspotRef
        }
      }
      archivedEventsCount
      events(
        category: $category
        sort: $sort
        filters: $filters
        search: $search
        page: $page
        perPage: $perPage
      ) {
        id
        eveyEventId
        number
        title
        locationType
        savedLocation {
          id
          name
        }
        location
        locationCode
        destination
        destinationCode
        transitType
        dateType
        prettyStartAt
        prettyEndAt
        prettyDateRange
        totalTicketsSold
        subscriptionStatusLabel
        productId
        productHandle
        tags
      }
      eventsCount(category: $category, filters: $filters, search: $search)
      totalEventCount
      askForFeedback
    }
  }
`;

export const UPDATE_SCHEDULE_AVAILABILITY = gql`
  mutation updateScheduledAvailability(
    $eventId: ID!
    $publishDateType: String
    $publishRelativeMinutes: Int
    $publishCustomDate: String
    $publishParams: String
    $publishCreateNew: Boolean
    $unpublishDateType: String
    $unpublishRelativeMinutes: Int
    $unpublishCustomDate: String
    $unpublishParams: String
    $unpublishCreateNew: Boolean
  ) {
    updateScheduledAvailability(
      eventId: $eventId
      publishDateType: $publishDateType
      publishRelativeMinutes: $publishRelativeMinutes
      publishCustomDate: $publishCustomDate
      publishParams: $publishParams
      publishCreateNew: $publishCreateNew
      unpublishDateType: $unpublishDateType
      unpublishRelativeMinutes: $unpublishRelativeMinutes
      unpublishCustomDate: $unpublishCustomDate
      unpublishParams: $unpublishParams
      unpublishCreateNew: $unpublishCreateNew
    ) {
      success
    }
  }
`;

export const UPDATE_SCHEDULED_REMINDER = gql`
  mutation updateScheduledReminder(
    $reminderId: ID!
    $dateType: String
    $relativeMinutes: Int
    $customDate: String
    $createNew: Boolean
  ) {
    updateScheduledReminder(
      reminderId: $reminderId
      dateType: $dateType
      relativeMinutes: $relativeMinutes
      customDate: $customDate
      createNew: $createNew
    ) {
      success
    }
  }
`;

export const GET_SHOP_ORDERS_SETTINGS = gql`
  query GetShopOrdersSettings {
    currentShop {
      id
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      refundPolicy
      autoCancelUnavailableSeats
      orderStatusEnabled
      customerNotificationsEnabled
    }
  }
`;

// export const GET_SEATS = gql`
//   query GetSeats($seatingChartId: ID!, $seatingZoneId: ID!, $page: Int, $perPage: Int, $search: String) {
//     currentShop {
//       seatingChart(id: $seatingChartId) {
//         seatingZone(id: $seatingZoneId) {
//           seats(page: $page, perPage: $perPage search: $search) {
//             id
//             label
//             capacity
//           }
//           seatsCount(search: $search)
//         }
//       }
//     }
//   }
// `;
export const GET_SEATS = gql`
  query GetSeats($seatingChartId: ID!, $seatingZoneId: ID!) {
    currentShop {
      seatingChart(id: $seatingChartId) {
        seatingZone(id: $seatingZoneId) {
          allSeats {
            id
            label
            capacity
          }
        }
      }
    }
  }
`;

export const SAVE_SEATING_CHART = gql`
  mutation saveSeatingChart($id: ID, $name: String!, $imageUrl: String) {
    saveSeatingChart(id: $id, name: $name, imageUrl: $imageUrl) {
      seatingChart {
        id
      }
    }
  }
`;

export const GET_EVENT_SEATS = gql`
  query GetEventSeats(
    $eventId: ID!
    $seatingZoneId: ID!
    $page: Int
    $perPage: Int
    $search: String
    $eventDate: String
    $eventTime: String
  ) {
    currentShop {
      domain
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        id
      }
      attendeeInfoStorefrontEnabled
      attendeeInfoStorefrontConfig
      event(id: $eventId) {
        seats(
          seatingZoneId: $seatingZoneId
          page: $page
          perPage: $perPage
          search: $search
          eventDate: $eventDate
          eventTime: $eventTime
        ) {
          id
          label
          capacity
          soldCount(eventId: $eventId)
          reservedCount(eventId: $eventId)
        }
        seatsCount(seatingZoneId: $seatingZoneId, search: $search)
      }
    }
  }
`;

export const GET_PLANS = gql`
  query PlansQuery {
    currentShop {
      domain
      trialExpiresAt
      activeSubscription {
        id
        activatedAt
        plan {
          id
        }
      }
      availablePlans {
        id
        name
        price
        perTicketPrice
        features
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($planId: ID!, $nextUrl: String) {
    createSubscription(planId: $planId, nextUrl: $nextUrl) {
      subscription {
        id
        confirmationUrl
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($subscriptionId: ID!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      subscription {
        id
      }
    }
  }
`;

export const GET_ACTIVE_SUBSCRIPTION = gql`
  query ActiveSubscriptionQuery {
    currentShop {
      domain
      email
      developmentPlan
      trialExpiresAt
      trialExpired
      getEveyAppReview
      hasLegacyPricing
      developmentPlan
      activeSubscription {
        id
        externalBilling
        price
        perTicketPrice
        plan {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACCESS_SCOPES_STATUS = gql`
  query AccessScopesStatusQuery {
    currentShop {
      domain
      shouldUpdateAccessScopes
      redirectLoginUrl
    }
  }
`;

export const GET_KLAVIYO_KEY_STATUS = gql`
  query KlaviyoKeyQuery {
    currentShop {
      domain
      shouldUpdateKlaviyoKey
    }
  }
`;

export const GET_TEMPLATE_FOR_EDITOR = gql`
  query GetTemplateForEditor(
    $templateType: String
    $templateId: ID
    $useDefault: Boolean
  ) {
    currentShop {
      domain
      activeSubscription {
        hasBrandingFeature
      }
      defaultLocaleFile {
        id
        locale
        content
        contentWithFallback
      }
      template(id: $templateId, type: $templateType) {
        id
        templateType
        body
        templateSchema(useDefault: $useDefault)
        availableBlocks(useDefault: $useDefault)
        settings
        prebuiltBlocks
        events {
          id
          title
          eveyEventId
        }
        defaultBody
        convertedSettings
      }
    }
  }
`;

export const TEMPLATE_PREVIEW = gql`
  query GetTemplatePreview(
    $templateType: String
    $templateId: ID
    $settings: String
    $strings: String
    $eventId: ID
    $attendeeId: ID
    $body: String
    $extraContext: String
  ) {
    currentShop {
      template(id: $templateId, type: $templateType) {
        id
        templateType
        renderedHtml(
          eventId: $eventId
          settings: $settings
          strings: $strings
          attendeeId: $attendeeId
          body: $body
          extraContext: $extraContext
        )
      }
    }
  }
`;

export const SAVE_TEMPLATE = gql`
  mutation saveTemplate(
    $templateType: String!
    $templateId: ID!
    $settings: String
    $body: String
    $strings: String
  ) {
    saveTemplate(
      templateType: $templateType
      templateId: $templateId
      settings: $settings
      body: $body
      strings: $strings
    ) {
      template {
        id
        templateType
      }
    }
  }
`;

export const SHOPIFY_STAGED_UPLOAD_CREATE = gql`
  mutation shopifyStagedUploadCreate(
    $resource: String!
    $filename: String!
    $mimeType: String
    $fileSize: String
  ) {
    shopifyStagedUploadCreate(
      resource: $resource
      filename: $filename
      mimeType: $mimeType
      fileSize: $fileSize
    ) {
      url
      parameters
      resourceUrl
    }
  }
`;
