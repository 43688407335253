import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Layout, Card, Banner, Button, Link as ShopifyLink, DropZone, TextContainer, DataTable, TextStyle, Spinner, ChoiceList } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import {
  DeleteMajor,
} from '@shopify/polaris-icons';

export default class TicketPageCode extends EveyComponent {
  constructor(props) {
    super(props);
  }

  ticketPage = null;
  files = null;

  state = {
    body: null,
    customizeSelected: [false],

    errors: {},
    unknownError: false
  }

  EVENT_FIELDS = `
    ticketPage {
      id
      logoUrl
      logoFilename
      logoSize
      notes
      body
      topbarColor
      sampleUrl
      defaultBody
    }
    files {
      id
      createdAt
      url
      filename
      size
      token
    }
  `;

  EDIT_TICKET_PAGE = gql`
    mutation editTicketPage($eventId: ID!, $logo: Upload, $deleteLogo: Boolean, $notes: String, $body: String, $topbarColor: String) {
      editTicketPage(eventId: $eventId, logo: $logo, deleteLogo: $deleteLogo, notes: $notes, body: $body, topbarColor: $topbarColor) {
        success
      }
    }
  `;

  ADD_USER_FILE = gql`
    mutation addUserFile($eventId: ID!, $file: Upload!) {
      addUserFile(eventId: $eventId, file: $file) {
        files {
          id
          createdAt
          url
          filename
          size
          token
        }
      }
    }
  `;

  DELETE_USER_FILE = gql`
    mutation deleteUserFile($eventId: ID!, $fileId: String!) {
      deleteUserFile(eventId: $eventId, fileId: $fileId) {
        files {
          id
          createdAt
          url
          filename
          size
          token
        }
      }
    }
  `;

  ticketPageBodyValue = (ticket) => {
    if (this.state.customizeSelected[0]) {
      if (this.state.body == null) {
        if (!ticket.body || ticket.body.length == 0) {
          return ticket.defaultBody;
        } else {
          return ticket.body;
        }
      } else {
        return this.state.body;
      }
    } else {
      return ticket.defaultBody;
    }
  }

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  renderUserFileDeleteButton = (shop, event, files, file) => {
    return (
      <Mutation mutation={this.DELETE_USER_FILE}
        onError={(error) => {
          if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
            this.props.flashCallback(true, 'Unable to delete file', true);
          } else {
            this.props.flashCallback(true, 'Unable to delete file', true);
          }
        }}
        onCompleted={(data) => {
          if (data && data.deleteUserFile) {
            this.props.flashCallback(true, 'File successfully deleted');
            this.files = data.deleteUserFile.files;
          }
        }}
      >
        {(deleteUserFile, { loading }) => {
          return (
            <Button
              size={'slim'}
              loading={loading}
              outline={true}
              destructive={true}
              icon={DeleteMajor}
              onClick={() => {
                deleteUserFile({
                  variables:{
                    eventId: event.id,
                    fileId: file.id
                  }
                });
              }}
            >
            Delete
            </Button>
          );
        }}
      </Mutation>
    );
  }

  renderUserFilesTable = (shop, event, files) => {
    let rows = [];

    for (let i in files) {
      let file = files[i];

      let fileLink = <ShopifyLink external url={`${file.url}`} target="_blank">{file.filename}</ShopifyLink>;
      let fileSize = this.humanFileSize(file.size, true);
      let fileTag = `{{ '${file.token}' | file_url }}`;

      let actions = this.renderUserFileDeleteButton(shop, event, files, file);

      rows.push([fileLink, fileSize, fileTag, actions]);
    }

    return (
      <DataTable
        columnContentTypes={[
          'text',
          'text',
          'text',
          'text'
        ]}
        headings={[
          'File',
          'Size',
          'Liquid Tag',
          ''
        ]}
        rows={rows}
      />
    );
  }

  renderUserFilesCard = (shop, event, files) => {
    return (
      <Mutation mutation={this.ADD_USER_FILE}
        onError={(error) => {
          if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
            this.props.flashCallback(true, 'Unable to upload file', true);
          } else {
            this.props.flashCallback(true, 'Unable to upload file', true);
          }
        }}
        onCompleted={(data) => {
          if (data && data.addUserFile) {
            this.props.flashCallback(true, 'File successfully added');
            this.files = data.addUserFile.files;
          }
        }}
      >
        {(addUserFile, { loading }) => {
          return (
            <Card sectioned title="Custom Files">
              <TextContainer spacing="loose">
                <p>
                Upload files here so that you can use them in your ticket template code.
                Just include the liquid tag provided for each file in your code to use it, for example in an
                HTML &lt;img&gt; tag src attribute if it is an image.
                </p>
              </TextContainer>
              <TextStyle variation="strong">Add file</TextStyle>
              {!loading &&
              <DropZone
                label={''}
                allowMultiple={false}
                onDropAccepted={(acceptedFiles) => {
                  addUserFile({
                    variables:{
                      eventId: event.id,
                      file: acceptedFiles[0]
                    }
                  });
                }}
              >
                <DropZone.FileUpload
                  actionTitle='Select file'
                  actionHint='or drop file to upload'
                />
              </DropZone>
              }
              {loading &&
              <div style={{textAlign:'center',marginTop:'50px'}}>
                <Spinner size="large" color="teal" />
              </div>
              }
              {event.files && event.files.length > 0 && this.renderUserFilesTable(shop, event, files)}
            </Card>
          );
        }}
      </Mutation>
    );
  }

  renderPageContent = (shop, event) => {
    if (this.ticketPage == null) {
      this.ticketPage = event.ticketPage;
    }
    if (this.files == null) {
      this.files = event.files;
    }

    return (
      <div>
        <Layout.Section>
        <Mutation mutation={this.EDIT_TICKET_PAGE}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false });
            } else {
              this.setState({ unknownError: true });
              this.reportError(error);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onCompleted={(data) => {
            if (data && data.editTicketPage) {
              this.props.flashCallback(true, 'Ticket page successfully updated');
              this.setState({
                errors: [],
                unknownError: false,
              });
            }
          }}
        >
          {(editTicketPage, { loading }) => {
            return (
              <div>
                {this.state.unknownError &&
                <Banner
                  title="There was a problem updating the ticket code"
                  status="critical"
                >
                  <p>
                    Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                  </p>
                </Banner>
                }
                {this.state.errors && this.state.errors.length > 0 &&
                <Banner
                  title="There was a problem updating the ticket code"
                  status="critical"
                >
                  {this.errorMessage()}
                </Banner>
                }
                <form id="ticketPageForm" onSubmit={e => {
                  e.preventDefault();

                  let body = null;
                  if (this.state.customizeSelected[0]) {
                    body = this.ticketPageBodyValue(this.ticketPage);
                  }

                  editTicketPage({
                    variables:{
                      eventId: event.id,
                      logo: null,
                      deleteLogo: false,
                      notes: this.ticketPage.notes,
                      body: body,
                      topbarColor: this.ticketPage.topbarColor
                    }
                  });
                }}>
                  <TextContainer spacing="loose">
                    <p>
                        If you want more control over the look of your ticket page you can update the template below.
                        It uses the liquid templating language with HTML so you can customize as much as you want while using event variables.
                        Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink> about customizing
                        this ticket template and a detailed list of liquid variables available to you.
                    </p>
                  </TextContainer>
                  <Card sectioned
                    title="Ticket Page HTML/Liquid Template"
                    primaryFooterAction={{
                      content: 'Save',
                      loading: loading,
                      submit: true
                    }}
                  >
                    <ChoiceList
                      title=''
                      choices={[
                        { label: 'Use default ticket template', value: false },
                        { label: 'Customize ticket template', value: true }
                      ]}
                      selected={this.state.customizeSelected}
                      onChange={this.handleChange('customizeSelected')}
                    />
                    <div className="TicketEmailEdit__AceEditor" style={{position: 'relative'}}>
                      {!this.state.customizeSelected[0] &&
                        <div style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#5c5c5c',
                          opacity: '0.05',
                          zIndex: '1000'
                        }}></div>
                      }
                      <AceEditor
                        mode="liquid"
                        theme="tomorrow"
                        onChange={this.handleChange('body')}
                        value={this.ticketPageBodyValue(this.ticketPage) || ''}
                        name="ticketPageBody"
                        fontSize='1.4rem'
                        width='100%'
                        height='500px'
                        showPrintMargin={false}
                        showGutter={false}
                        highlightActiveLine={true}
                        setOptions={{
                          showLineNumbers: false,
                          tabSize: 2,
                          fontSize: 'inherit',
                        }}
                        readOnly={!(this.state.customizeSelected[0])}
                        editorProps={{$blockScrolling: true}}
                        focus={this.state.customizeSelected[0]}
                      />
                    </div>
                  </Card>
                </form>
              </div>
            );
          }}
        </Mutation>
        </Layout.Section>
      </div>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
        onCompleted={(data) => {
          if (data && data.currentShop && data.currentShop.event && data.currentShop.event.ticketPage) {
            console.log('data.currentShop.event.ticketPage.body: ' + data.currentShop.event.ticketPage.body != null);
            this.setState({ customizeSelected: [!!data.currentShop.event.ticketPage.body] });
          }
        }}
      >
        {({ shop, event }) => {
          if (this.ticketPage == null) {
            this.ticketPage = event.ticketPage;
          }
          if (this.files == null) {
            this.files = event.files;
          }

          return (
            <EventPage
              shop={shop}
              event={event}
              title="Edit Code"
              fullWidth={true}
              extraBreadcrumbs={[
                {
                  content: 'Ticket Page',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/ticket_page`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              secondaryActions={[
                {
                  content: 'Back to Ticket Settings',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/ticket_page`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                },
                {
                  content: 'View Sample Ticket',
                  external: true,
                  target: 'REMOTE',
                  url: this.ticketPage ? this.ticketPage.sampleUrl : ''
                }
              ]}
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              <Layout>
                {this.renderPageContent(shop, event)}
              </Layout>
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

TicketPageCode.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
