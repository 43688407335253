import React from 'react';
import SettingsPage from './SettingsPage';

const ActionSchedulesPage = () => {

  return (
    <SettingsPage pageTitle='Scheduled actions' title='Scheduled actions'>

    </SettingsPage>
  );
};

export default ActionSchedulesPage;
