import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Card, TextField, Banner, ResourceList, Pagination,
  TextStyle, Button, Link, Tabs, Popover, ActionList, FormLayout,
  Collapsible, ChoiceList } from '@shopify/polaris';
import EventPage from '../EventPage/EventPage.jsx';
import EventQuery from '../EventPage/EventQuery.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import {
  EmailNewsletterMajor,
  ExportMinor,
  ImportMinor,
  ConversationMinor,
  PriceLookupMinor,
} from '@shopify/polaris-icons';
import { GET_ATTENDEES, BULK_ACTION } from '../../utils/graphql';
import EditReport from './EditReport';
import AttendeeFilters from './AttendeeFilters';
import AttendeeListResourceItem from './AttendeeListResourceItem';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const BULK_ACTION_MAX = 10000;

const initialFilters = () => {
  let filters = [];

  const scheduledOn = (new URLSearchParams(window.location.search)).get('scheduledOn');
  if (scheduledOn) {
    filters.push({
      key: 'scheduledOn',
      value: [scheduledOn],
    });
  }

  const ticketType = (new URLSearchParams(window.location.search)).get('ticketType');
  if (ticketType) {
    filters.push({
      key: 'ticketType',
      value: [ticketType],
    });
  }

  const seatingZone = (new URLSearchParams(window.location.search)).get('seatingZone');
  if (seatingZone) {
    filters.push({
      key: 'seatingZone',
      value: [seatingZone],
    });
  }

  const seat = (new URLSearchParams(window.location.search)).get('seat');
  if (seat) {
    filters.push({
      key: 'seat',
      value: [seat],
    });
  }

  const zoomRegistrantId = (new URLSearchParams(window.location.search)).get('zoomRegistrantId');
  if (zoomRegistrantId) {
    filters.push({
      key: 'zoomRegistrantId',
      value: [zoomRegistrantId],
    });
  }

  const zoomRegistrationStatus = (new URLSearchParams(window.location.search)).get('zoomRegistrationStatus');
  if (zoomRegistrationStatus == '1' || zoomRegistrationStatus == '0') {
    filters.push({
      key: 'zoomRegistrationStatus',
      value: [zoomRegistrationStatus == '1' ? 'Is registered' : 'Is not registered'],
    });
  }

  return filters;
};

const PER_PAGE = 20;

const AttendeeList = ({ match }) => {
  const [category, setCategory] = React.useState((new URLSearchParams(window.location.search)).get('category') || 'active');
  const [appliedFilters, setAppliedFilters] = React.useState(initialFilters());
  const [searchValue, setSearchValue] = React.useState((new URLSearchParams(window.location.search)).get('search') || '');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [fullExport, setFullExport] = React.useState(false);
  const [confirmBulkAction, setConfirmBulkAction] = React.useState(false);
  const [bulkAction, setBulkAction] = React.useState('');
  const [confirmBulkActionEmail, setConfirmBulkActionEmail] = React.useState('');
  const [reportsPopoverActive, setReportsPopoverActive] = React.useState(false);
  const [reportDetailsActive, setReportDetailsActive] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState({
    id: '',
    name: '',
    email: '',
    filters: {}
  });
  const [reports, setReports] = React.useState(null);
  const [exportCustomFormatActive, setExportCustomFormatActive] = React.useState(false);
  const [exportFormat, setExportFormat] = React.useState(['csv']);

  const { showToastNotice, showToastError, canCreateAttendees } = useFrame();
  const history = useHistory();

  const handleFiltersChange = (f) => {
    if (JSON.stringify(f || []) != JSON.stringify(appliedFilters || [])) {
      setAppliedFilters(f);
      handlePageChange(1);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const isFirstPage = () => {
    if (currentPage <= 1) {
      return true;
    } else {
      return false;
    }
  };

  const isLastPage = (attendeesCount) => {
    let numPages = Math.ceil(attendeesCount / PER_PAGE);
    if (currentPage >= numPages) {
      return true;
    } else {
      return false;
    }
  };

  const handlePreviousPage = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  const handleBulkCheckIn = () => {
    setBulkAction('checkin');
    setConfirmBulkAction(true);
  };

  const handleBulkCancelCheckIn = () => {
    setBulkAction('cancel_checkin');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkZoomRegister = () => {
    setBulkAction('zoom_register');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkZoomCancel = () => {
    setBulkAction('zoom_cancel');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkTicketSend = () => {
    setBulkAction('deliver');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkTicketDownload = () => {
    setBulkAction('download');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkDelete = () => {
    setBulkAction('delete');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkExport = () => {
    setBulkAction('export');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBulkExportCheckins = () => {
    setBulkAction('export-checkins');
    setConfirmBulkAction(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bulkActionReset = () => {
    setConfirmBulkAction(false);
    setConfirmBulkActionEmail('');
    setFullExport(false);
    setBulkAction('');
  }

  const bulkActionConfirmTitle = () => {
    if (bulkAction == 'export') {
      return 'Confirm attendee export';
    } else if (bulkAction == 'checkin') {
      return 'Confirm attendees Check-In';
    } else if (bulkAction == 'cancel_checkin') {
      return 'Confirm attendees Check-In cancel';
    } else if (bulkAction == 'delete') {
      return 'Confirm tickets cancel';
    } else if (bulkAction == 'deliver') {
      return 'Confirm send/resend of attendees ticket emails';
    } else if (bulkAction == 'download') {
      return 'Confirm attendee tickets download';
    } else if (bulkAction == 'zoom_register') {
      return 'Confirm attendees add to Zoom meeting';
    } else if (bulkAction == 'zoom_register') {
      return 'Confirm attendees remove from Zoom meeting';
    } else if (bulkAction == 'export-checkins') {
      return 'Confirm attendees check-in activity export';
    }
  }

  const bulkActionModalMessage = (_bulkActionAttendeeCount) => {
    const bulkActionAttendeeCount = Math.min(_bulkActionAttendeeCount, BULK_ACTION_MAX);
    if (bulkAction == 'checkin') {
      return `Are you sure you want to mark the ${bulkActionAttendeeCount} selected attendee(s) as checked in? It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'cancel_checkin') {
      return `Are you sure you want to mark the ${bulkActionAttendeeCount} selected attendee(s) as not checked in? It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'delete') {
      return `Are you sure you want to cancel and invalidate the tickets for the ${bulkActionAttendeeCount} selected attendee(s)? This is irreversible, those attendees will lose access to their tickets.  This will not refund any payments associated with these tickets, you should do that through the Shopify orders page.  It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'deliver') {
      return `Are you sure you want to send or resend the ticket emails for the ${bulkActionAttendeeCount} selected attendee(s)?  It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'zoom_register') {
      return `Are you sure you want to register ${bulkActionAttendeeCount} selected attendee(s) with the connected Zoom meeting? If any attendee is already registered they will be skipped. It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'zoom_cancel') {
      return `Are you sure you want to cancel registration (remove) ${bulkActionAttendeeCount} selected attendee(s) with the connected Zoom meeting? It may take some time depending on how many you've selected, you can see the changes by refreshing the page.`;
    } else if (bulkAction == 'export-checkins') {
      return `Continue to export the check-in activity logs of ${bulkActionAttendeeCount} selected attendee(s)? It may take some time depending on how many you've selected, you'll receive an email with download instructions when it's ready.`;
    }
  };

  const bulkActionCompleted = () => {
    if (bulkAction == 'export') {
      showToastNotice('Export will be delivered to ' + confirmBulkActionEmail);
    } else if (bulkAction == 'checkin') {
      showToastNotice('Attendees check-in processing');
    } else if (bulkAction == 'cancel_checkin') {
      showToastNotice('Cancel check-in processing');
    } else if (bulkAction == 'delete') {
      showToastNotice('Cancel tickets processing');
    } else if (bulkAction == 'deliver') {
      showToastNotice('Attendee ticket delivery processing');
    } else if (bulkAction == 'download') {
      showToastNotice('Export will be delivered to ' + confirmBulkActionEmail);
    } else if (bulkAction == 'zoom_register') {
      showToastNotice('Attendees Zoom register processing');
    } else if (bulkAction == 'zoom_cancel') {
      showToastNotice('Attendees Zoom cancel processing');
    } else if (bulkAction == 'export-checkins') {
      showToastNotice('Check-In activity export will be delivered to ' + confirmBulkActionEmail);
    }
    bulkActionReset();
  }

  const bulkActionMutation = (event, attendeesBulkAction) => {
    return () => {
      let filters = null;
      let search = null;
      let attendeeIds = null;
      let _category = category;
      if (bulkAction == 'export' && fullExport) {
        // leave it all null to get all attendees
        _category = 'all';
      } else if (selectedItems == 'All') {
        // null attendeeId list but keep search/filters applied
        filters = appliedFilters;
        search = searchValue;
      } else {
        // subset of applied filters/search
        filters = appliedFilters;
        search = searchValue;
        attendeeIds = selectedItems;
      }
      attendeesBulkAction({
        variables:{
          eventId: event.id,
          action: bulkAction,
          attendeeIds: attendeeIds,
          email: confirmBulkActionEmail,
          filters: Array.isArray(filters) ? JSON.stringify(filters) : filters,
          search: search,
          exportFormat: exportFormat[0],
          category: _category,
        }
      });
    };
  }

  const extraEventFields = `
    tickets {
      id
      title
      price
      inventory
      variantId
      groupSize
      variant {
        id
        title
        price
        option1
        option2
        option3
      }
    }
    zoom {
      id
      active
      meetingId
    }
  `;

  return (
    <EventQuery
      eventId={match.params.eventId}
      extraEventAttributes={extraEventFields}
      onCompleted={(data) => {
        const shop = data?.currentShop;
        const event = shop?.event;

        setReports(event?.attendeeReports);
      }}
    >
      {({ shop, event }) => {
        return (
          <EventPage
            shop={shop}
            event={event}
            title="Attendees"
            primaryAction={canCreateAttendees ? {
              content: 'Add Attendee',
              onAction: () => goToPage({ history, path: `/events/${event.id}/attendees/new` }),
            } : undefined}
          >
            <Query
              query={GET_ATTENDEES}
              variables={{
                'eventId': event.id,
                'category': category,
                'filters': JSON.stringify(appliedFilters),
                'search': searchValue,
                'page': currentPage,
                'perPage': PER_PAGE
              }}
            >
              {({ loading, error, data }) => {
                if (error) return <ErrorPage error={error}/>;

                if (data && data.currentShop) {
                  shop = data.currentShop;
                  event = data.currentShop.event;
                }

                let attendees = [];
                let attendeesCount = 0;
                let attendeesAllCount = 0;
                let attendeesActiveCount = 0;
                let attendeesCancelledCount = 0;
                let attendeesCheckedinCount = 0;
                let attendeesNotCheckedinCount = 0;
                let totalAttendeeCount = 0;
                let attendeeList = [];

                let reportItems = [];
                let headerActions = [];

                if (!loading) {
                  attendees = event.attendees;
                  attendeesCount = event.attendeesCounts[0];
                  attendeesAllCount = event.attendeesCounts[1];
                  attendeesActiveCount = event.attendeesCounts[2];
                  attendeesCancelledCount = event.attendeesCounts[3];
                  attendeesCheckedinCount = event.attendeesCounts[4];
                  attendeesNotCheckedinCount = event.attendeesCounts[5];
                  totalAttendeeCount = event.attendeesCounts[6];

                  attendeeList = [...attendees];

                  if ((reports || []).length > 0) {
                    for (let i in reports) {
                      let report = reports[i];
                      reportItems.push({
                        content: report.name,
                        onAction: () => {
                          let reportFilters = JSON.parse(report.filters);
                          let filters = [];
                          let search = reportFilters['search'] || '';
                          // let category = reportFilters['category'] && reportFilters['category'].length > 0 ? reportFilters['category'] : 'active';
                          let _category = 'all';
                          for (let key in reportFilters) {
                            let value = reportFilters[key];
                            if (key == 'status') {
                              // if (value == 'cancelled' && category != 'cancelled') {
                              //   filters.push({ 'key': 'ticketStatus', 'value': 'Cancelled' });
                              // }
                              // if (value == 'active' && category != 'active') {
                              //   filters.push({ 'key': 'ticketStatus', 'value': 'Active' });
                              // }
                              if (value == 'cancelled') {
                                filters.push({ 'key': 'ticketStatus', 'value': 'Cancelled' });
                              } else if (value == 'all') {
                                // nothing to do
                              } else {
                                filters.push({ 'key': 'ticketStatus', 'value': 'Active' });
                              }
                            } else if (key == 'delivery_status') {
                              filters.push({ 'key': 'ticketDeliveryStatus', 'value': (value ? 'Sent' : 'Not Sent') });
                            } else if (key == 'checkedin') {
                              filters.push({ 'key': 'checkinStatus', 'value': (value ? 'Checked-In' : 'Not Checked-In') });
                            } else if (key == 'purchased_since') {
                              filters.push({ 'key': 'purchasedSince', 'value': value });
                            }
                          }

                          setSelectedReport(report);
                          setReportDetailsActive(false);
                          setReportsPopoverActive(false);
                          setCategory(_category);
                          setSearchValue(search);
                          handleFiltersChange(filters);
                        }
                      });
                    }
                  } else {
                    reportItems = [{ content: 'No reports saved', disabled: true }];
                  }

                  if (canCreateAttendees && shop.trialExpired) {
                    headerActions.push(
                      <Button
                        key='import'
                        id="importAttendees"
                        icon={ImportMinor}
                        plain={true}
                        disabled={loading}
                        onClick={() => goToPage({ history, path: `/events/${event.id}/attendees_imports` })}
                      >
                        Import attendees
                      </Button>
                    );
                  }
                  headerActions.push(
                    <Button
                      key='export'
                      id="exportAttendees"
                      icon={ExportMinor}
                      plain={true}
                      disabled={loading}
                      onClick={() => {
                        setFullExport(true);
                        setBulkAction('export');
                        setConfirmBulkAction(true);
                      }}
                    >
                      Export all attendees
                    </Button>
                  );
                  headerActions.push(
                    <Button
                      key='reminders'
                      id="reminders"
                      icon={ConversationMinor}
                      plain={true}
                      disabled={loading}
                      onClick={() => goToPage({ history, path: `/events/${event.id}/reminders` })}
                    >
                      Attendee messages
                    </Button>
                  );
                  // if (event.locationType == 'online' && event.virtualEventPage.requirePassword) {
                  //   headerActions.push(
                  //     <Button
                  //       key='virtual_sessions'
                  //       id="virtual_sessions"
                  //       icon={PriceLookupMinor}
                  //       plain={true}
                  //       disabled={loading}
                  //       onClick={() => goToPage({ history, path: `/events/${event.id}/virtual_sessions` })}
                  //     >
                  //       Virtual event user sessions
                  //     </Button>
                  //   );
                  // }
                  if (shop.hasReportsFeature) {
                    headerActions.push(
                      <Popover
                        key='reports'
                        active={reportsPopoverActive}
                        onClose={() => setReportsPopoverActive(false)}
                        activatorWrapper='span'
                        activator={
                          <Button
                            id='reports-button'
                            icon={EmailNewsletterMajor}
                            plain={true}
                            disabled={loading}
                            disclosure
                            onClick={() => setReportsPopoverActive(true)}
                          >
                            Reports
                          </Button>
                        }
                      >
                        <ActionList
                          sections={[
                            {
                              title: 'Current filter',
                              items: [
                                {
                                  content: 'Save as report',
                                  helpText: 'We can send you a daily export of the attendees for your current search/filter settings',
                                  onAction: () => {
                                    setSelectedReport(null);
                                    setReportDetailsActive(true);
                                    setReportsPopoverActive(false);
                                  }
                                }
                              ]
                            },
                            {
                              title: 'Saved reports',
                              items: reportItems
                            }
                          ]}
                        />
                      </Popover>
                    );
                  }
                }

                const tabs = [
                  {
                    id: 'all',
                    content: `All (${attendeesAllCount == 10000 ? '10,000+' : attendeesAllCount})`,
                    accessibilityLabel: 'All attendees',
                    panelID: 'all-attendees-content'
                  },
                  {
                    id: 'active',
                    content: `Active (${attendeesActiveCount == 10000 ? '10,000+' : attendeesActiveCount})`,
                    accessibilityLabel: 'Active attendees',
                    panelID: 'active-attendees-content'
                  },
                  {
                    id: 'cancelled',
                    content: `Cancelled (${attendeesCancelledCount == 10000 ? '10,000+' : attendeesCancelledCount})`,
                    accessibilityLabel: 'Cancelled attendees',
                    panelID: 'cancelled-attendees-content'
                  },
                  {
                    id: 'checkedin',
                    content: `Checked-In (${attendeesCheckedinCount == 10000 ? '10,000+' : attendeesCheckedinCount})`,
                    accessibilityLabel: 'Checked-In attendees',
                    panelID: 'checkedin-attendees-content'
                  },
                  {
                    id: 'notcheckedin',
                    content: `Not Checked-In (${attendeesNotCheckedinCount == 10000 ? '10,000+' : attendeesNotCheckedinCount})`,
                    accessibilityLabel: 'Not checked-in attendees',
                    panelID: 'notcheckedin-attendees-content'
                  }
                ];

                const selectedTab = tabs.findIndex((t) => t.id == category) === -1 ? 0 : tabs.findIndex((t) => t.id == category);

                return (
                  <div>
                    <div className="AttendeeList__Header">
                      <div className="AttendeeList__ImportExport">
                        {headerActions}
                      </div>
                      <TextStyle variation="subdued">
                        Showing {attendees.length} of {attendeesCount} attendees
                      </TextStyle>
                    </div>
                    {reportDetailsActive &&
                      <EditReport
                        event={event}
                        report={selectedReport}
                        onClose={() => {
                          setReportDetailsActive(false);
                          setSelectedReport(null);
                        }}
                        onUpdate={({ reports, report }) => {
                          setReports(reports);
                          setSelectedReport(report);
                          setReportDetailsActive(report != null);
                        }}
                        filters={appliedFilters}
                        searchValue={searchValue}
                        category={category}
                      />
                    }
                    {confirmBulkAction &&
                      <Mutation
                        mutation={BULK_ACTION}
                        onError={() => showToastError('Unable to process action')}
                        onCompleted={bulkActionCompleted}
                      >
                        {(attendeesBulkAction, { loading }) => {
                          let bulkActionAttendeeCount = 0;
                          if (bulkAction == 'export' && fullExport) {
                            bulkActionAttendeeCount = totalAttendeeCount;
                          } else if (selectedItems == 'All') {
                            bulkActionAttendeeCount = attendeesCount;
                          } else {
                            bulkActionAttendeeCount = selectedItems.length;
                          }

                          if (bulkAction == 'export') {
                            return (
                              <Banner
                                title={bulkActionConfirmTitle()}
                                status="default"
                                action={{
                                  content: 'Export attendees',
                                  disabled: !confirmBulkActionEmail || confirmBulkActionEmail.length == 0,
                                  loading: loading,
                                  onAction: bulkActionMutation(event, attendeesBulkAction)
                                }}
                                onDismiss={bulkActionReset}
                              >
                                <p>
                                  Please confirm your export of {Math.min(bulkActionAttendeeCount, BULK_ACTION_MAX)} attendees, we will email you when your export is ready. It may take some time depending on the number of attendees you&apos;ve selected. Enter the email address that you would like us to send the export to:
                                </p>
                                <TextField type="email" id='exportEmail' value={confirmBulkActionEmail} onChange={setConfirmBulkActionEmail} />
                                <p style={{marginTop:'10px'}}>
                                  <Link
                                    onClick={() => setExportCustomFormatActive(!exportCustomFormatActive)}
                                  >
                                    Custom format
                                  </Link>
                                </p>
                                <Collapsible open={exportCustomFormatActive} id="exportCustomFormatActiveCollapsible">
                                  <ChoiceList
                                    title="Select export format"
                                    choices={[
                                      { label: 'CSV with all fields', value: 'csv' },
                                      { label: <span>Zoom CSV (<Link external url="https://support.zoom.us/hc/en-us/articles/360036179892-Importing-Webinar-Registrants-Via-CSV" >import it directly into Zoom</Link>)</span>, value: 'zoom' }
                                    ]}
                                    selected={exportFormat}
                                    onChange={setExportFormat}
                                  />
                                </Collapsible>
                              </Banner>
                            );
                          } else if (bulkAction == 'download') {
                            return (
                              <Banner
                                title={bulkActionConfirmTitle()}
                                status="default"
                                action={{
                                  content: 'Download tickets',
                                  disabled: !confirmBulkActionEmail || confirmBulkActionEmail.length == 0,
                                  loading: loading,
                                  onAction: bulkActionMutation(event, attendeesBulkAction)
                                }}
                                onDismiss={bulkActionReset}
                              >
                                <p>
                                Please confirm your export of {bulkActionAttendeeCount} tickets, we will email you when the tickets are ready to download. It may take some time depending on the number of attendees you&apos;ve selected. Enter the email address that you would like us to notify you on:
                                </p>
                                <TextField type="email" id='exportEmail' value={confirmBulkActionEmail} onChange={setConfirmBulkActionEmail} />
                              </Banner>
                            );
                          } else if (bulkAction == 'export-checkins') {
                            return (
                              <Banner
                                title={bulkActionConfirmTitle()}
                                status="default"
                                action={{
                                  content: 'Export check-in activity',
                                  disabled: !confirmBulkActionEmail || confirmBulkActionEmail.length == 0,
                                  loading: loading,
                                  onAction: bulkActionMutation(event, attendeesBulkAction)
                                }}
                                onDismiss={bulkActionReset}
                              >
                                <p>
                                {bulkActionModalMessage(bulkActionAttendeeCount)}
                                </p>
                                <TextField type="email" value={confirmBulkActionEmail} onChange={setConfirmBulkActionEmail} />
                              </Banner>
                            );
                          } else {
                            return (
                              <Banner
                                title={bulkActionConfirmTitle()}
                                status="default"
                                action={{
                                  content: 'Yes, continue',
                                  loading: loading,
                                  destructive: bulkAction == 'delete',
                                  onAction: bulkActionMutation(event, attendeesBulkAction)
                                }}
                                onDismiss={bulkActionReset}
                              >
                                <p>{bulkActionModalMessage(bulkActionAttendeeCount)}</p>
                              </Banner>
                            );
                          }
                        }}
                      </Mutation>
                    }
                    <Card>
                      <Tabs tabs={tabs} selected={selectedTab} onSelect={(v) => {
                        setCategory(tabs[v].id);
                        setCurrentPage(1);
                      }}>
                        <ResourceList
                          resourceName={{ singular: 'attendee', plural: 'attendees' }}
                          items={attendeeList}
                          loading={loading}
                          hasMoreItems={attendeesCount > PER_PAGE}
                          showHeader={true}
                          selectedItems={selectedItems}
                          onSelectionChange={setSelectedItems}
                          bulkActions={[
                            { content: 'Export attendees', onAction: handleBulkExport },
                            { content: 'Check-In attendees', onAction: handleBulkCheckIn },
                            { content: 'Mark as not checked in', onAction: handleBulkCancelCheckIn },
                            { content: '(Re)-Send ticket emails', onAction: handleBulkTicketSend },
                            { content: 'Download tickets', onAction: handleBulkTicketDownload },
                            { content: 'Cancel tickets', onAction: handleBulkDelete, destructive: true },
                            ...(event && event.zoom && event.zoom.active ? [
                              { content: 'Add to Zoom meeting', onAction: handleBulkZoomRegister },
                              { content: 'Remove from Zoom meeting', onAction: handleBulkZoomCancel },
                            ] : []),
                            { content: 'Export check-in activity', onAction: handleBulkExportCheckins },
                          ]}
                          filterControl={
                            <AttendeeFilters
                              filters={{
                                search: searchValue,
                                appliedFilters: appliedFilters,
                              }}
                              event={event}
                              onUpdate={({ search, appliedFilters }) => {
                                setSearchValue(search);
                                handleFiltersChange(appliedFilters);
                              }}
                            />
                          }
                          renderItem={(item) => <AttendeeListResourceItem shop={shop} event={event} attendee={item} />}
                        />
                        {attendeeList.length > 0 &&
                        <div className="AttendeeListFooter">
                          <Pagination
                            hasPrevious={!isFirstPage()}
                            hasNext={!isLastPage(attendeesCount)}
                            onPrevious={handlePreviousPage}
                            onNext={handleNextPage}
                          />
                        </div>
                        }
                      </Tabs>
                    </Card>
                  </div>
                );
              }}
            </Query>
          </EventPage>
        );
      }}
    </EventQuery>
  );
};

export default AttendeeList;
