import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Modal,
  FormLayout,
  TextField,
  Banner,
  Link,
} from '@shopify/polaris';
import { useState } from 'react';
import { formatErrors, reportError } from '../../utils/errors';
import { CREATE_CHECKIN_USER, EDIT_CHECKIN_USER_PERMISSIONS } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import { CHECKIN_HOST } from '../../utils/checkinHost';

const AddUserToEventModal = ({ event, onClose, availableUsers }) => {
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { showToastNotice, showToastError } = useFrame();

  const forgotPasswordUrl = `${CHECKIN_HOST}/forgot_password`;

  function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // const isValid = email.length && password.length && passwordConfirm.length && password == passwordConfirm && validateEmail(email);
  const isValid = email.length && validateEmail(email);

  const alreadyHasAccess = availableUsers.find((u) => u.email == email && (u.fullAccess || u.permissions.find((p) => p.eventId == event.eveyEventId)));
  const isExistingUser = availableUsers.find((u) => u.email == email);

  console.log(`isValid: ${isValid}`);
  console.log(`isExistingUser: ${isExistingUser}`);

  return (
    <Mutation mutation={CREATE_CHECKIN_USER}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
        } else {
          setErrors(['There was a problem creating this user. Contact us if this problem persists.']);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.createCheckinUser) {
          showToastNotice('User created');
          onClose();
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(createCheckinUser, { loading: saving }) => (
        <Mutation mutation={EDIT_CHECKIN_USER_PERMISSIONS}
          onError={() => {
            showToastError('Failed to grant access');
          }}
          onCompleted={(data) => {
            if (data && data.editCheckinUserPermissions) {
              showToastNotice('User access granted');
              onClose();
            }
          }}
          refetchQueries={() => ['EventQuery']}
        >
          {(editCheckinUserPermissions, { loading: grantingAccess }) => (
            <Modal
              open={true}
              onClose={() => onClose()}
              title={'Add check-in user'}
              primaryAction={{
                content: 'Save',
                disabled: saving || grantingAccess || !isValid || isExistingUser,
                loading: saving,
                onAction: () => {
                  setErrors([]);
                  createCheckinUser({
                    variables: {
                      email,
                      password,
                      passwordConfirm,
                      eventId: event.id,
                    }
                  });
                },
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  disabled: saving || grantingAccess,
                  onAction: () => {
                    onClose();
                  },
                },
              ]}
            >
              {errors && errors.length > 0 &&
                <Modal.Section>
                  <Banner
                    title="There was a problem creating the user"
                    status="critical"
                  >
                    {formatErrors(errors)}
                  </Banner>
                </Modal.Section>
              }
              {isExistingUser && !alreadyHasAccess &&
                <Modal.Section>
                  <Banner
                    title="This user already exists"
                    status="default"
                    action={{
                      content: 'Grant access',
                      loading: grantingAccess,
                      disabled: saving || grantingAccess,
                      onAction: () => {
                        setErrors([]);
                        const user = availableUsers.find((u) => u.email == email);
                        let permissions = user.permissions.map((p) => ({ event_id: p.eventId }));
                        permissions.push({ event_id: event.eveyEventId });
                        editCheckinUserPermissions({
                          variables: {
                            userId: user.id,
                            permissions: JSON.stringify(permissions),
                          }
                        });
                      }
                    }}
                  >
                    A user with this email address already exists on your shop. Click the button below to give them access to this event.
                  </Banner>
                </Modal.Section>
              }
              {isExistingUser && alreadyHasAccess &&
                <Modal.Section>
                  <Banner
                    title="This user already exists"
                    status="critical"
                  >
                    A user with this email address already exists and has access to this event.
                  </Banner>
                </Modal.Section>
              }
              <Modal.Section>
                {isExistingUser ? (
                  <FormLayout>
                    <TextField
                      label='Email'
                      type='email'
                      value={email}
                      onChange={(newValue) => setEmail(newValue)}
                    />
                  </FormLayout>
                ) : (
                  <FormLayout>
                    <TextField
                      label='Email'
                      type='email'
                      value={email}
                      onChange={(newValue) => setEmail(newValue)}
                    />
                    {/*
                    <TextField
                      label='Password'
                      type='password'
                      value={password}
                      onChange={(newValue) => setPassword(newValue)}
                    />
                    <TextField
                      label='Confirm password'
                      type='password'
                      value={passwordConfirm}
                      onChange={(newValue) => setPasswordConfirm(newValue)}
                    />
                    */}
                    <Banner status='info'>New users will receive an email with a link to set their password. Existing users can reset their password <Link external url={forgotPasswordUrl}>here</Link>.</Banner>
                  </FormLayout>
                )}
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default AddUserToEventModal;
