import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useFrame } from '../contexts/frame';

import EventList from './EventListing/EventList.jsx';
import DashboardPage from './Dashboard/DashboardPage';
import EventEdit from './EventEdit/EventEdit.jsx';
import EventCreate from './EventCreate/EventCreate.jsx';
import LinkProduct from './LinkProduct/LinkProduct.jsx';
import AttendeeList from './AttendeeList/AttendeeList.jsx';
import QueuerList from './Queue/QueuerList.jsx';
import AttendeeCreate from './attendees/new.jsx';
import AttendeeEdit from './attendees/edit.js';
import AttendeeImportList from './AttendeeImport/AttendeeImportList.jsx';
import AttendeeImportEdit from './AttendeeImport/AttendeeImportEdit.jsx';
import EventOrderList from './Order/EventOrderList.jsx';
import OrderLookup from './Order/OrderLookup.jsx';
import PdfTicketCode from './PdfTicket/PdfTicketCode.jsx';
import CheckinUserCreate from './CheckinUser/CheckinUserCreate.jsx';
import CheckinUserEdit from './CheckinUser/CheckinUserEdit.jsx';
import CustomerDataTable from './Customer/CustomerDataTable.jsx';
import CustomerShow from './Customer/CustomerShow.jsx';
import CustomerEdit from './Customer/CustomerEdit.jsx';
import TicketPageCode from './TicketPage/TicketPageCode.jsx';
import Settings from './Settings/Settings.jsx';
import AttendeeReminders from './AttendeeReminders/AttendeeReminders.jsx';
import ReminderPage from './AttendeeReminders/ReminderPage.jsx';
import VirtualEventSessions from './VirtualEvents/VirtualEventSessions.jsx';
import AccountPage from './Settings/AccountPage';
import StorefrontPage from './Settings/StorefrontPage';
import TranslationsPage from './Settings/TranslationsPage';
import CheckinPage from './Settings/CheckinPage';
import NotificationsPage from './Settings/NotificationsPage';
import TicketBundleCreatePage from './TicketBundle/TicketBundleCreatePage';
import IntegrationsPage from './Settings/IntegrationsPage';
import ActionSchedulesPage from './Settings/ActionSchedulesPage';
import LocationsPage from './Settings/LocationsPage';
import OrdersSettingsPage from './Settings/OrdersPage';
import SeatingChartPage from './Settings/Seating/SeatingChartPage';
import PlansPage from './Plans/PlansPage';
import TemplateEditorPage from './TemplateEditor/TemplateEditorPage';
import AttendeeInfoPage from './Settings/AttendeeInfoPage';
import AttendeeFieldsEdit from './Settings/AttendeeInfo/AttendeeFieldsEdit';
import VendorsPage from './Settings/Vendors/VendorsPage';
import LoginPage from './ExternalFrame/LoginPage';
import ForgotPasswordPage from './ExternalFrame/ForgotPasswordPage';
import ResetPasswordPage from './ExternalFrame/ResetPasswordPage';
import ShopSelectPage from './ExternalFrame/ShopSelectPage';
import ErrorPage from './Error/ErrorPage.jsx';
import VendorAccountPage from './ExternalFrame/AccountPage';

const App = () => {
  const { showToastError, showToastNotice } = useFrame();

  const flashCallback = (visible, message, error) => {
    if (visible) {
      if (error === true) {
        showToastError(message);
      } else {
        showToastNotice(message);
      }
    }
  };

  return (
    <div>
      <Switch>
        <Route exact path="/" render={(props) => <EventList {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events" render={(props) => <EventList {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/new" render={(props) => <EventCreate {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/product" render={(props) => <LinkProduct {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId" render={(props) => <DashboardPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/dashboard" render={(props) => <DashboardPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/edit" render={(props) => <EventEdit {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/seating" render={(props) => <EventEdit {...props} flashCallback={flashCallback} section='seating'/>} />
        <Route exact path="/events/:eventId/attendees" render={(props) => <AttendeeList {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/attendees/new" render={(props) => <AttendeeCreate {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/attendees/:attendeeId" render={(props) => <AttendeeEdit {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/attendees/:attendeeId/edit" render={(props) => <AttendeeEdit {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/attendees_imports" render={(props) => <AttendeeImportList {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/attendees_imports/:attendeeImportId" render={(props) => <AttendeeImportEdit {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/queues" render={(props) => <QueuerList {...props} flashCallback={flashCallback} />} />
        <Route exact path="/events/:eventId/virtual_sessions" render={(props) => <VirtualEventSessions {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/reminders" render={(props) => <AttendeeReminders {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/reminders/new" render={(props) => <ReminderPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/reminders/:reminderId" render={(props) => <ReminderPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/event_orders" render={(props) => <EventOrderList {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/email" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=notifications`} />} />
        <Route exact path="/events/:eventId/passbook" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=tickets`} />} />
        <Route exact path="/events/:eventId/pdf_ticket" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=tickets`} />} />
        <Route exact path="/events/:eventId/pdf_ticket/code" render={(props) => <PdfTicketCode {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/ticket_page" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=tickets`} />} />
        <Route exact path="/events/:eventId/ticket_page/code" render={(props) => <TicketPageCode {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/checkin_settings" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=checkin`} />} />
        <Route exact path="/events/:eventId/event_users/:eventUserId/edit" render={(props) => <CheckinUserEdit {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/event_users/new" render={(props) => <CheckinUserCreate {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/events/:eventId/mailchimp" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=integrations`} />} />
        <Route exact path="/events/:eventId/klaviyo" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=integrations`} />} />
        <Route exact path="/events/:eventId/zoom" render={(props) => <Redirect to={`/events/${props.match.params.eventId}/edit?section=integrations`} />} />

        <Route exact path="/plans" render={(props) => <PlansPage {...props} />} />
        <Route exact path="/settings" render={(props) => <Settings {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/account" render={(props) => <AccountPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/storefront" render={(props) => <StorefrontPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/translations" render={(props) => <TranslationsPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/checkin" render={(props) => <CheckinPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/notifications" render={(props) => <NotificationsPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/integrations" render={(props) => <IntegrationsPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/integrations/mailchimp" render={(props) => <IntegrationsPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/integrations/klaviyo" render={(props) => <IntegrationsPage {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/settings/integrations/zoom" render={(props) => <IntegrationsPage {...props} />} />
        <Route exact path="/settings/scheduled_actions" render={(props) => <ActionSchedulesPage {...props} />} />
        <Route exact path="/settings/locations" render={(props) => <LocationsPage {...props} />} />
        <Route exact path="/settings/orders" render={(props) => <OrdersSettingsPage {...props} />} />
        <Route exact path="/settings/vendors" render={(props) => <VendorsPage {...props} />} />
        <Route exact path="/settings/seating_charts/:seatingChartId" render={(props) => <SeatingChartPage {...props} />} />
        <Route exact path="/settings/attendee_info" render={(props) => <AttendeeInfoPage {...props} />} />
        <Route exact path="/settings/attendee_info/new" render={(props) => <AttendeeFieldsEdit {...props} />} />
        <Route exact path="/settings/attendee_info/:templateId" render={(props) => <AttendeeFieldsEdit {...props} />} />
        <Route exact path="/orders" render={(props) => <OrderLookup {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/customers" render={(props) => <CustomerDataTable {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/customers/:customerId" render={(props) => <CustomerShow {...props} flashCallback={flashCallback}/>} />
        <Route exact path="/customers/:customerId/edit" render={(props) => <CustomerEdit {...props} flashCallback={flashCallback}/>} />

        <Route exact path="/bundles/new" render={(props) => <TicketBundleCreatePage {...props} />} />

        <Route exact path="/editor" render={(props) => <TemplateEditorPage {...props} />} />

        <Route exact path="/vendor/login" render={(props) => <LoginPage {...props} />} />
        <Route exact path="/vendor/forgot_password" render={(props) => <ForgotPasswordPage {...props} />} />
        <Route exact path="/vendor/reset_password" render={(props) => <ResetPasswordPage {...props} />} />
        <Route exact path="/vendor/shops" render={(props) => <ShopSelectPage {...props} />} />
        <Route exact path="/vendor/account" render={(props) => <VendorAccountPage {...props} />} />

        <Route exact path="/error" render={(props) => <ErrorPage {...props} />} />
      </Switch>
    </div>
  );
};

export default App;
