import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

const FirstEventReviewModal = () => {
  const history = useHistory();
  const showReviewModalString = (new URLSearchParams(window.location.search)).get('review');
  const showReviewModalBoolean = (/true/i).test(showReviewModalString);

  if (showReviewModalBoolean) {
    const [active, setActive] = React.useState(showReviewModalBoolean);
    
    return (
      <Modal
        title='Please share your experience'
        open={active}
        onClose={() => {
          history.push(window.location.pathname)
          setActive(!active);
        }}
        primaryAction={{
          content: 'Leave a review',
          onAction: () => {
            history.push(window.location.pathname)
            window.open('https://apps.shopify.com/event-tickets?#modal-show=WriteReviewModal/?reveal_new_review=true&utm_source=in-app&utm_medium=reviewmodal&utm_campaign=leavereview')
            setActive(!active);
          }
        }}
        secondaryActions={[
          {
            content: "No - I'm having issues",
            onAction: () => {
              history.push(window.location.pathname)
              window.open('https://www2.eveyevents.com/contact-us/?utm_source=in-app&utm_medium=reviewmodal&utm_campaign=havingissues')
              setActive(!active)
            },
          }
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Great job! You created your first event. Please take a moment to leave us a review &#128591;</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  } else {
    return ''
  }
};

export default FirstEventReviewModal;
