import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Card, Banner, Badge, DataTable, ResourceList, Pagination, Button, Tabs } from '@shopify/polaris';
import EventPage from '../EventPage/EventPage.jsx';
import EventQuery from '../EventPage/EventQuery.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import { ExportMinor } from '@shopify/polaris-icons';
import { GET_QUEUERS, QUEUERS_EXPORT } from '../../utils/graphql.js';
import QueuerListResourceItem from './QueuerListResourceItem';
import { useFrame } from '../../contexts/frame.js';

const PER_PAGE = 20;

const QueuerList = ({ match }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [executeExport, setExecuteExport] = React.useState(false);


  const { showToastNotice, showToastError } = useFrame();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const isFirstPage = () => {
    return currentPage <= 1
  };

  const isLastPage = (attendeesCount) => {
    let numPages = Math.ceil(attendeesCount / PER_PAGE);
    return currentPage >= numPages
  };

  const handlePreviousPage = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  const closeExport = () => {
    setExecuteExport(false);
  }

  const createDownloadLink = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    return a;
  }

  const extraEventFields = `
    tickets {
      id
      title
      price
      inventory
      variantId
      groupSize
      variant {
        id
        title
        price
        option1
        option2
        option3
      }
    }
    zoom {
      id
      active
      meetingId
    }
  `;

  return (
    <EventQuery
      eventId={match.params.eventId}
      extraEventAttributes={extraEventFields}
    >
      {({ shop, event }) => {
        return (
          <EventPage
            shop={shop}
            event={event}
            title="Waitlist"
          >
            <Query
              query={GET_QUEUERS}
              variables={{
                'eventId': event.id,
                'page': currentPage,
                'perPage': PER_PAGE
              }}
            >
              {({ loading, error, data }) => {
                if (error) return <ErrorPage error={error} />;

                if (data && data.currentShop) {
                  shop = data.currentShop;
                  event = data.currentShop.event;
                }

                let queuers = [];
                let queuersTotal = 0;
                let headerActions = [];

                if (!loading) {
                  queuers = event.queuers;
                  queuersTotal = event.queuersTotal;

                  headerActions.push(
                    <Button
                      key='export'
                      id="exportQueuers"
                      icon={ExportMinor}
                      plain={true}
                      disabled={loading}
                      onClick={() => {
                        setExecuteExport(true)
                      }}
                    >
                      Export waitlist
                    </Button>
                  );
                }


                const tabs = [
                  {
                    id: 'all',
                    content: `All (${queuersTotal == 10000 ? '10,000+' : queuersTotal})`,
                    accessibilityLabel: 'All queuers',
                    panelID: 'all-queuers-content'
                  }
                ];

                return (
                  <div>
                    <div className="QueuerList__Header">
                      <div className="QueuerList__ImportExport">
                        {headerActions}
                      </div>
                    </div>

                    {executeExport &&
                      <Mutation
                        mutation={QUEUERS_EXPORT}
                        onError={() => showToastError('Unable to process action')}
                        onCompleted={(data) => {
                          setExecuteExport(false)
                          const blob = new Blob(
                            [data.queuersExport.content],
                            { type: 'text/csv' }
                          );

                          createDownloadLink(blob, `Exported waitlist from ${event.title}.csv`)
                          showToastNotice('Processing export and generating data')
                        }}
                      >
                        {(queuersExport, { loading }) => {
                          return (
                            <Banner
                              title='Export waitlist'
                              status="default"
                              action={{
                                content: 'Download',
                                disabled: false,
                                loading: loading,
                                onAction: () => {
                                  queuersExport({
                                    variables: {
                                      eventId: event.id
                                    }
                                  })
                                }
                              }}
                              onDismiss={closeExport}
                            >
                              <p>
                                Download all queuers into a CSV
                              </p>
                            </Banner>
                          );
                        }}
                      </Mutation>
                    }



                    <Card>
                      <DataTable
                        columnContentTypes={[
                          'text',
                          'text',
                          'text',
                          'text',
                          'numeric'
                        ]}
                        headings={[
                          'Email',
                          'Full Name',
                          'Desired Datetime',
                          'Quantity',
                          'Ticket Type'
                        ]}
                        rows={queuers.map((queuer) => {
                          return [
                            queuer.email,
                            queuer.fullName,
                            queuer.datetime,
                            queuer.quantity,
                            <Badge status='info'>{queuer.ticketTypeName}</Badge>
                          ]
                        })}
                      />

                      {queuers.length > 0 &&
                        <div className="AttendeeListFooter">
                          <Pagination
                            hasPrevious={!isFirstPage()}
                            hasNext={!isLastPage(queuersTotal)}
                            onPrevious={handlePreviousPage}
                            onNext={handleNextPage}
                          />
                        </div>
                      }
                    </Card>
                  </div>
                );
              }}
            </Query>
          </EventPage>
        );
      }}
    </EventQuery>
  );
};

export default QueuerList;
