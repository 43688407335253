import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Card, Checkbox, FormLayout, Link, DataTable, Select, TextField, Banner, PageActions, ExceptionList, TextStyle, List, Collapsible } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';

export default class AttendeeImportEdit extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    attendeeImport: null,
    errors: {},
    unknownError: false,
    importing: false
  }

  EVENT_FIELDS = `
    attendeeImport(eventId: $eventId, id: $attendeeImportId) {
      id
      startedAt
      completedAt
      filename
      fileUrl
      totalProcessed
      totalSuccess
      totalErrors
      assignments
      importErrors
      sample
      numRows
      notifyEmail
      skipFirst
      sendEmails
      groupEmails
      createOrders
    }
    email {
      id
      hasMultipleEmail
    }
  `;

  EDIT_IMPORT = gql`
    mutation editAttendeeImport($eventId: ID!, $attendeeImportId: ID!, $skipFirst: Boolean, $sendEmails: Boolean, $groupEmails: Boolean, $createOrders: Boolean, $notifyEmail: String, $assignments: [String!]) {
      editAttendeeImport(eventId: $eventId, attendeeImportId: $attendeeImportId, skipFirst: $skipFirst, sendEmails: $sendEmails, groupEmails: $groupEmails, createOrders: $createOrders, notifyEmail: $notifyEmail, assignments: $assignments) {
        success
      }
    }
  `;

  initializeAttendeeImportState = (shop, event) => {
    let attendeeImport = this.state.attendeeImport;
    if (attendeeImport == null) {
      let assignments = event.attendeeImport.assignments;
      if (assignments == null || assignments.length == 0) {
        if (!event.attendeeImport.sample || event.attendeeImport.sample.length == 0 || event.attendeeImport.sample[0].length == 0) {
          assignments = [];
        } else {
          assignments = new Array(event.attendeeImport.sample[0].length).fill('');
        }
      }

      let notifyEmail = event.attendeeImport.notifyEmail;
      if (notifyEmail == null) {
        notifyEmail = shop.email;
      }
      attendeeImport = {
        skipFirst: event.attendeeImport.skipFirst,
        sendEmails: event.attendeeImport.sendEmails,
        groupEmails: event.attendeeImport.groupEmails,
        createOrders: event.attendeeImport.createOrders,
        assignments: assignments,
        notifyEmail: notifyEmail
      };
      this.setState({ attendeeImport: attendeeImport });
    }
    return attendeeImport;
  }

  attendeeImportState = (shop, event) => {
    return this.initializeAttendeeImportState(shop, event);
  }

  attendeeImportFieldState = (shop, event, field) => {
    return this.attendeeImportState(shop, event)[field];
  }

  handleAttendeeImportChange = (shop, event, field) => {
    return (value) => {
      let attendeeImport = this.attendeeImportState(shop, event);
      attendeeImport[field] = value;
      this.setState({ attendeeImport: attendeeImport });
    };
  }

  handleAssignmentChange = (shop, event, index) => {
    return (value) => {
      let attendeeImport = this.attendeeImportState(shop, event);
      let assignments = this.attendeeImportFieldState(shop, event, 'assignments');
      assignments[index] = value;
      attendeeImport.assignments = assignments;
      this.setState({ attendeeImport: attendeeImport });
    };
  }

  renderAssignmentTable = (shop, event, attendeeImport) => {
    if (!attendeeImport.sample || attendeeImport.sample.length == 0 || attendeeImport.sample[0].length == 0) {
      return ('');
    }

    let columnContentTypes = [];
    let headings = [];
    let rows = [];

    let headingOptions = [
      {label: '', value: ''},
      {label: 'First Name', value: 'first_name'},
      {label: 'Last Name', value: 'last_name'},
      {label: 'Email', value: 'email'},
      {label: 'Variant ID / Title', value: 'variant_value'},
      {label: 'Ticket Quantity', value: 'quantity'},
      {label: 'Amount Paid for Ticket(s)', value: 'price'}
    ];

    let assignments = this.attendeeImportFieldState(shop, event, 'assignments');
    for (let i in attendeeImport.sample[0]) {
      columnContentTypes.push('text');
      headings.push(
        <Select
          label=''
          options={headingOptions}
          value={assignments[i] || ''}
          onChange={this.handleAssignmentChange(shop, event, i)}
        />
      );
    }

    for (let i in attendeeImport.sample) {
      let row = attendeeImport.sample[i];
      rows.push(row);
    }
    return (
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
        footerContent={`Showing ${attendeeImport.sample.length} of ${attendeeImport.numRows} rows in file`}
      />
    );
  }

  renderConfig = (shop, event, attendeeImport) => {
    if (attendeeImport.completedAt.length == 0 && attendeeImport.startedAt.length == 0) {
      return (
        <Mutation
          mutation={this.EDIT_IMPORT}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false, importing: false });
            } else {
              this.setState({ unknownError: true, importing: false });
              this.reportError(error);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onCompleted={(data) => {
            if (data && data.editAttendeeImport) {
              this.setState({ importing: false });
              this.props.flashCallback(true, 'Import started, we\'ll notify you when it\'s complete');

              this.props.history.push(`/events/${event.id}/attendees`);
              window.scrollTo({ top: 0 });
            }
          }}
        >
          {(editAttendeeImport) => (
            <Card title="Configure import" sectioned>
              <form id="attendeeImportForm" onSubmit={e => {
                e.preventDefault();
                this.setState({ importing: true });
                let attendeeImportState = this.attendeeImportState(shop, event);
                editAttendeeImport({
                  variables: {
                    eventId: event.id,
                    attendeeImportId: attendeeImport.id,
                    skipFirst: !!attendeeImportState.skipFirst,
                    sendEmails: !!attendeeImportState.sendEmails,
                    groupEmails: !!attendeeImportState.groupEmails,
                    createOrders: !!attendeeImportState.createOrders,
                    assignments: attendeeImportState.assignments || [],
                    notifyEmail: attendeeImportState.notifyEmail || ''
                  }
                });
              }}>
                {this.state.unknownError &&
                <Banner
                  title="There was a problem updating your import configuration"
                  status="critical"
                >
                  <p>
                    Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                  </p>
                </Banner>
                }
                {this.state.errors && this.state.errors.length > 0 &&
                <Banner
                  title="There was a problem updating your import configuration"
                  status="critical"
                >
                  {this.errorMessage()}
                </Banner>
                }
                <Card.Section title="Step 1: Select file">
                  <div>
                    <Link external={true} url={attendeeImport.fileUrl}>{attendeeImport.filename}</Link>
                  </div>
                </Card.Section>
                <Card.Section title="Step 2: Select import options">
                  <FormLayout>
                    <Checkbox
                      key='importSkipFirstRow'
                      checked={this.attendeeImportFieldState(shop, event, 'skipFirst')}
                      label="Skip first row of the file"
                      onChange={this.handleAttendeeImportChange(shop, event, 'skipFirst')}
                    />
                    <div>
                      <Checkbox
                        key='importSendEmails'
                        checked={this.attendeeImportFieldState(shop, event, 'sendEmails')}
                        label="Send ticket emails for each imported attendee"
                        onChange={this.handleAttendeeImportChange(shop, event, 'sendEmails')}
                      />
                      {event.email.hasMultipleEmail &&
                        <div style={{marginLeft:'20px'}}>
                          <Collapsible open={this.attendeeImportFieldState(shop, event, 'sendEmails')} id="attendeeImportSendEmailsCollapsible">
                            <Checkbox
                              key='importGroupEmails'
                              checked={this.attendeeImportFieldState(shop, event, 'groupEmails')}
                              label="Group emails together into a single email when there is more than one of the same recipient address"
                              onChange={this.handleAttendeeImportChange(shop, event, 'groupEmails')}
                            />
                          </Collapsible>
                        </div>
                      }
                    </div>
                    <Checkbox
                      key='importCreateOrders'
                      checked={this.attendeeImportFieldState(shop, event, 'createOrders')}
                      label="Create an order in Shopify for each row (this will not process any payments)"
                      onChange={this.handleAttendeeImportChange(shop, event, 'createOrders')}
                    />
                    <TextField
                      key='importNotifyEmail'
                      value={this.attendeeImportFieldState(shop, event, 'notifyEmail')}
                      label={'Email address to notify you when import is complete'}
                      onChange={this.handleAttendeeImportChange(shop, event, 'notifyEmail')}
                    />
                  </FormLayout>
                </Card.Section>
                <Card.Section title="Step 3: Select columns assignments from sample">
                  {this.renderAssignmentTable(shop, event, attendeeImport)}
                </Card.Section>
                <PageActions
                  primaryAction={{
                    content: 'Save and Import',
                    loading: this.state.importing,
                    submit: true
                  }}
                />
              </form>
            </Card>
          )}
        </Mutation>
      );
    } else {
      return ('');
    }
  }

  renderImportLog = (shop, event, attendeeImport) => {
    if (attendeeImport.startedAt.length > 0 || attendeeImport.completedAt.length > 0) {
      let errorItems = [];
      if (attendeeImport.importErrors && attendeeImport.importErrors.length > 0) {
        for (var i in attendeeImport.importErrors) {
          errorItems.push({
            status: 'warning',
            truncate: true,
            description: attendeeImport.importErrors[i]
          });
        }
      }

      return (
        <Card title="Import log" sectioned>
          <p>Import started on {(new Date(attendeeImport.startedAt)).toLocaleString()} for file <Link external={true} url={attendeeImport.fileUrl}>{attendeeImport.filename}</Link></p>
          {attendeeImport.completedAt.length > 0 &&
            <div>
              <p>
                Import completed on {(new Date(attendeeImport.completedAt)).toLocaleString()} with:
              </p>
              <div style={{paddingBottom:'14px'}}>
                <List type="bullet">
                  <List.Item>Total number of rows processed: {attendeeImport.totalProcessed}</List.Item>
                  <List.Item>Number of attendees created: {attendeeImport.totalSuccess}</List.Item>
                  <List.Item>Number of errors: {attendeeImport.totalErrors}</List.Item>
                </List>
              </div>
              <p>We notified you at {attendeeImport.notifyEmail} when it was completed.</p>
            </div>
          }
          {attendeeImport.completedAt.length == 0 &&
            <p>We will notify you at {attendeeImport.notifyEmail} when it&apos;s completed.</p>
          }
          <TextStyle variation='strong'>Errors:</TextStyle>
          <ExceptionList
            items={errorItems}
          />
        </Card>
      );
    } else {
      return ('');
    }
  }

  renderPageContent = (shop, event) => {
    let attendeeImport = event.attendeeImport;
    return (
      <div>
        {this.renderImportLog(shop, event, attendeeImport)}
        {this.renderConfig(shop, event, attendeeImport)}
      </div>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        extraVariables={{'attendeeImportId': this.props.match.params.attendeeImportId}}
        queryDeclaration={'AttendeeImportQuery($eventId: ID!, $attendeeImportId: ID!)'}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          return (
            <EventPage
              shop={shop}
              event={event}
              title="Attendee Import"
              flashCallback={this.props.flashCallback}
              history={this.props.history}
              fullWidth={true}
            >
              {this.renderPageContent(shop, event)}
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

AttendeeImportEdit.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string,
      attendeeImportId: PropTypes.string
    })
  })
};
