import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, TextField, Stack, FormLayout, Checkbox, Collapsible, Banner, ChoiceList, Subheading, Select, Link as ShopifyLink } from '@shopify/polaris';
import styled from '@emotion/styled';
import ModalFooter from '../shared/ModalFooter.jsx';
import { useFrame } from '../../contexts/frame';
import { currencySymbolLookup, moneyFormat } from '../../utils/money';
import { CREATE_OR_UPDATE_TICKET_TYPE, DELETE_TICKET_TYPE } from '../../utils/graphql';
import { parseBoolean } from '../../utils/parse';
import { buildDateWithCompensatedTimezone, getTimeString, getDateString, convertToDateTime } from '../../utils/time';
import { NestedFormLayoutContainer } from '../shared/containers';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const TicketTypeModal = ({ shop, event, ticketTypeId, onClose, defaultSeatingZone }) => {
  const ticketType = event.tickets.find((t) => t.id == ticketTypeId);

  const originalEventDate = ticketType && ticketType.eventDate && buildDateWithCompensatedTimezone(ticketType.eventDate);
  const originalEventEndDate = ticketType && ticketType.eventEndDate && buildDateWithCompensatedTimezone(ticketType.eventEndDate);

  const [option1, setOption1] = React.useState(ticketType ? (ticketType.variant ? ticketType.variant.option1 : ticketType.title) : '');
  const [option2, setOption2] = React.useState(ticketType && ticketType.variant ? ticketType.variant.option2 : '');
  const [option3, setOption3] = React.useState(ticketType && ticketType.variant ? ticketType.variant.option3 : '');
  const [price, setPrice] = React.useState(ticketType ? (ticketType.ticketFee ? (parseFloat(ticketType.price || 0) - parseFloat(ticketType.ticketFee || 0)).toFixed(2).toString() : ticketType.price) : '');
  const [inventory, setInventory] = React.useState(ticketType ? ticketType.inventory.toString() : '');
  const [isGroupTicket, setIsGroupTicket] = React.useState(ticketType ? ticketType.groupSize > 1 : false);
  const [groupSize, setGroupSize] = React.useState(ticketType ? ticketType.groupSize.toString() : '1');
  const [groupIndividualTickets, setGroupIndividualTickets] = React.useState(ticketType ? ticketType.groupIndividualTickets : true);
  const [visibleOnStorefront, setVisibleOnStorefront] = React.useState(event.product ? (ticketType ? ticketType.visible : true) : false);
  const [hasEventDate, setHasEventDate] = React.useState(ticketType ? !!ticketType.eventDate : false);
  const [eventDateDate, setEventDateDate] = React.useState(ticketType && ticketType.eventDate ? getDateString(originalEventDate) : '');
  const [eventDateTime, setEventDateTime] = React.useState(ticketType && ticketType.eventDate ? getTimeString(originalEventDate) : '');
  const [hasLocation, setHasLocation] = React.useState(ticketType ? !!ticketType.location : false);
  const [locationId, setLocationId] = React.useState(ticketType?.location?.id);
  const [destinationId, setDestinationId] = React.useState(ticketType?.destiationLocation?.id);

  const [hasEventEndDate, setHasEventEndDate] = React.useState(ticketType ? !!ticketType.eventEndDate : false);
  const [eventEndDateDate, setEventEndDateDate] = React.useState(ticketType && ticketType.eventEndDate ? getDateString(originalEventEndDate) : '');
  const [eventEndDateTime, setEventEndDateTime] = React.useState(ticketType && ticketType.eventEndDate ? getTimeString(originalEventEndDate) : '');

  const [ticketFee, setTicketFee] = React.useState(ticketType ? ticketType.ticketFee : null);

  const seatingChart = event.savedLocation && event.savedLocation.seatingChart;
  const seatingZones = seatingChart && seatingChart.seatingZones || [];
  const hasSeating = !!seatingChart;
  const seatingZoneOptionIndex = hasSeating && event.product.options.findIndex((option) => seatingChart && option.name === seatingChart.variantOptionZoneName);
  const hasSeatingZoneOption = seatingZoneOptionIndex !== -1;

  const [seatingZoneId, setSeatingZoneId] = React.useState(ticketType ? ticketType.seatingZoneId : defaultSeatingZone);

  const { showToastNotice, showToastError, canEditEventTicketFees } = useFrame();
  const history = useHistory();

  const defaultTicketFee = 0.0;
  const totalPrice = ticketFee ? (parseFloat(ticketFee) + parseFloat(price || 0)).toFixed(2).toString() : price;

  const location = shop.locations.find((loc) => loc.id == locationId);
  const destination = shop.locations.find((loc) => loc.id == destinationId);

  React.useEffect(() => {
    const zone = seatingZones.find((z) => z.id == seatingZoneId);
    if (seatingZoneOptionIndex === 0) {
      setOption1(zone ? zone.name : '');
    } else if (seatingZoneOptionIndex === 1) {
      setOption2(zone ? zone.name : '');
    } else if (seatingZoneOptionIndex === 2) {
      setOption3(zone ? zone.name : '');
    }
  }, [seatingZoneId]);

  const seatingZoneSelect = (
    <Select
      options={[
        { value: 'no-zone', label: '' },
        ...seatingZones.map((zone) => ({ value: zone.id, label: zone.name }))
      ]}
      value={seatingZoneId == null ? 'no-zone' : seatingZoneId}
      onChange={(v) => setSeatingZoneId(v == 'no-zone' ? null : v)}
      label='Seating zone'
      helpText='Select the seating zone that this ticket type / variant belongs to. All tickets should belong to a seating zone if they are available for sale.'
    />
  );

  return (
    <Mutation
      mutation={DELETE_TICKET_TYPE}
      onError={() => showToastError('Failed to delete ticket type')}
      onCompleted={() => {
        showToastNotice('Ticket type deleted');
        onClose();
      }}
      refetchQueries={() => ['EventQuery', 'GetSeatingIssues']}
      awaitRefetchQueries={true}
    >
      {(deleteTicketType, { loading: deleting }) => (
        <Mutation
          mutation={CREATE_OR_UPDATE_TICKET_TYPE}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              showToastError(error.graphQLErrors[0].errors.toString())
            } else {
              showToastError('Failed to save ticket type')
            }
          }}
          onCompleted={() => {
            showToastNotice('Ticket type updated');
            onClose();
          }}
          refetchQueries={() => ['EventQuery', 'GetSeatingIssues']}
          awaitRefetchQueries={true}
        >
          {(createOrUpdateTicketType, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={ticketType ? ticketType.title : 'Add ticket type'}
              footer={
                <ModalFooter
                  saveButtonText="Save"
                  saveButtonAction={() => {
                    createOrUpdateTicketType({
                      variables: {
                        eventId: event.id,
                        ticketTypeId: ticketType ? ticketType.id : null,
                        option1,
                        option2,
                        option3,
                        groupSize: isGroupTicket ? parseInt(groupSize || 1) : 1,
                        inventory: parseInt(inventory || 0),
                        price: totalPrice || '0',
                        visible: parseBoolean(visibleOnStorefront),
                        eventDate: hasEventDate ? convertToDateTime(eventDateDate, eventDateTime) : null,
                        eventEndDate: hasEventDate && hasEventEndDate ? convertToDateTime(eventEndDateDate, eventEndDateTime) : null,
                        groupIndividualTickets: isGroupTicket && event.dateType === 'recurring' ? groupIndividualTickets : true,
                        seatingZoneId,
                        ticketFee,
                        locationId: hasLocation ? locationId : null,
                        destinationId: hasLocation ? destinationId : null,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText={'Delete'}
                  destructiveButtonAction={ticketType ? () => {
                    deleteTicketType({
                      variables: {
                        eventId: event.id,
                        ticketTypeId: ticketType.id,
                      },
                    });
                  } : undefined}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />

              }
            >
              <Modal.Section>
                <FormLayout>
                  {visibleOnStorefront && hasSeating && seatingZoneOptionIndex === 0 ? (
                    seatingZoneSelect
                  ) : (
                    <TextField
                      value={option1}
                      label={visibleOnStorefront ? event.product.options[0].name : 'Title'}
                      placeholder=""
                      onChange={(v) => setOption1(v)}
                    />
                  )}
                  {visibleOnStorefront && event.product.options[1] && (
                    hasSeating && seatingZoneOptionIndex === 1 ? (
                      seatingZoneSelect
                    ) : (
                      <TextField
                        value={option2}
                        label={event.product.options[1].name}
                        placeholder=""
                        onChange={(v) => setOption2(v)}
                      />
                    )
                  )}
                  {visibleOnStorefront && event.product.options[2] && (
                    hasSeating && seatingZoneOptionIndex === 2 ? (
                      seatingZoneSelect
                    ) : (
                      <TextField
                        value={option3}
                        label={event.product.options[2].name}
                        placeholder=""
                        onChange={(v) => setOption3(v)}
                      />
                    )
                  )}
                  <FormLayout.Group condensed>
                    <TextField
                      type="number"
                      prefix={currencySymbolLookup(shop.currency) || '$'}
                      label="Price"
                      placeholder="10.00"
                      min={0}
                      value={price}
                      onChange={(v) => setPrice(v)}
                      helpText={ticketFee ? `Customer pays ${moneyFormat(shop, totalPrice)}` : ''}
                    />
                    {!event.hasCrossVariantInventory && !hasSeating &&
                      <TextField
                        type="number"
                        label="Inventory"
                        value={inventory}
                        placeholder="100"
                        min={0}
                        onChange={(v) => setInventory(v)}
                      />
                    }
                  </FormLayout.Group>
                  <Checkbox
                    checked={hasEventDate}
                    label="Assign a date/time to this ticket type"
                    helpText='Any tickets purchased will use this date/time when displaying the event date to the attendee'
                    onChange={(v) => setHasEventDate(v)}
                  />
                  <Collapsible open={hasEventDate} id="hasEventDateCollapse">
                    <FormLayout>
                      <FormLayout.Group condensed>
                        <TextField
                          value={eventDateDate}
                          type="date"
                          label="Start date"
                          onChange={(v) => setEventDateDate(v)}
                          placeholder="YYYY-MM-DD"
                        />
                        <TextField
                          value={eventDateTime}
                          type="time"
                          label='Start time'
                          onChange={(v) => setEventDateTime(v)}
                          placeholder="HH:MM (24-hour)"
                        />
                      </FormLayout.Group>
                      <Checkbox
                        checked={hasEventEndDate}
                        label="Specify end date/time"
                        onChange={(v) => setHasEventEndDate(v)}
                      />
                      <Collapsible open={hasEventDate && hasEventEndDate} id="hasEventEndDateCollapse">
                        <FormLayout>
                          <FormLayout.Group condensed>
                            <TextField
                              value={eventEndDateDate}
                              type="date"
                              label="End date"
                              onChange={(v) => setEventEndDateDate(v)}
                              placeholder="YYYY-MM-DD"
                            />
                            <TextField
                              value={eventEndDateTime}
                              type="time"
                              label='End time'
                              onChange={(v) => setEventEndDateTime(v)}
                              placeholder="HH:MM (24-hour)"
                            />
                          </FormLayout.Group>
                        </FormLayout>
                      </Collapsible>
                    </FormLayout>
                  </Collapsible>
                  {(event.locationType == 'venue' || event.locationType == 'no-location' || event.locationType == 'transit') && shop.locations.length > 0 &&
                    <>
                      <Checkbox
                        checked={hasLocation}
                        label={event.locationType == 'transit' ? 'Assign transit locations to this ticket type' : "Assign a location to this ticket type"}
                        helpText='Any tickets purchased location info when displaying the location to the attendee.'
                        onChange={(v) => {
                          setHasLocation(v);
                          if (v) {
                            setLocationId(shop.locations[0].id);
                          }
                        }}
                      />
                      <Collapsible open={hasLocation}>
                        <Stack vertical={true}>
                          <Select
                            options={[
                              ...shop.locations.map((loc) => ({ value: loc.id, label: loc.name }))
                            ]}
                            value={locationId}
                            onChange={setLocationId}
                            label={event.locationType == 'transit' ? 'Origin' : 'Location'}
                          />
                          {event.locationType == 'transit' &&
                            <Select
                              options={[
                                ...shop.locations.map((loc) => ({ value: loc.id, label: loc.name }))
                              ]}
                              value={destinationId}
                              onChange={setDestinationId}
                              label='Destination'
                            />
                          }
                        </Stack>
                      </Collapsible>
                    </>
                  }
                  {!ticketType &&
                    <Checkbox
                      checked={visibleOnStorefront}
                      disabled={!event.product}
                      label="Available on the online store"
                      helpText="Keep this checked to sell the ticket on your store. If this is not checked then there will be no product variant associated with this ticket type. This cannot be changed after you create the ticket type. Keep this checked if you are not sure."
                      onChange={(v) => setVisibleOnStorefront(v)}
                    />
                  }
                  {ticketType && !ticketType.visible &&
                    <Banner status='info'>This ticket type is not available on your online store. It can only be used when creating attendees manually in the app.</Banner>
                  }
                </FormLayout>
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <Subheading>Group ticket</Subheading>
                  <Checkbox
                    checked={isGroupTicket}
                    label="This ticket is a group ticket"
                    onChange={(v) => setIsGroupTicket(v)}
                  />
                  <Collapsible open={isGroupTicket} id="addTicketGroupCollasp">
                    <NestedFormLayoutContainer>
                      <FormLayout>
                        <TextField
                          type="number"
                          label="Group size"
                          value={groupSize}
                          min={1}
                          max={25}
                          onChange={(v) => setGroupSize(v)}
                          helpText='This is the number of tickets that will be generated each time this ticket type is purchased'
                        />
                        {event.dateType === 'recurring' &&
                          <Checkbox
                            checked={groupIndividualTickets}
                            label="Each group member should be given their own ticket"
                            onChange={setGroupIndividualTickets}
                            helpText='Leave this checked to give one ticket to each group member. If you do not keep this checked then only one ticket will be generated for all group members under the name of the purchaser.'
                          />
                        }
                      </FormLayout>
                    </NestedFormLayoutContainer>
                  </Collapsible>
                </FormLayout>
              </Modal.Section>
              {hasSeating && !hasSeatingZoneOption &&
                <Modal.Section>
                  <FormLayout>
                    <Subheading>Reserved seating</Subheading>
                    {seatingZoneSelect}
                  </FormLayout>
                </Modal.Section>
              }
              {canEditEventTicketFees &&
                <Modal.Section>
                  <FormLayout>
                    <Subheading>Service fees</Subheading>
                    <Checkbox
                      checked={ticketFee != null}
                      label="This ticket type has a service fee that the customer will pay"
                      onChange={(v) => setTicketFee(v ? defaultTicketFee.toString() : null)}
                    />
                    <Collapsible open={ticketFee != null} id="ticketFeeCollasp">
                      <NestedFormLayoutContainer>
                        <FormLayout>
                          <TextField
                            type="number"
                            prefix={currencySymbolLookup(shop.currency) || '$'}
                            label=''
                            placeholder="1.00"
                            min={0}
                            value={ticketFee}
                            onChange={(v) => setTicketFee(v)}
                          />
                          {shop.attendeeInfoStorefrontEnabled && shop.attendeeInfoStorefrontConfig.ticket_fee_enabled !== false ? (
                            <Banner status='info'>
                              The price and fee will be displayed on the product page for this event when the ticket type is selected. Configure how it is displayed <ShopifyLink onClick={() => goToPage({ history, path: '/settings/storefront?section=ticket-fees' })}>in the storefront integration settings</ShopifyLink>.
                            </Banner>
                          ) : (
                            <Banner
                              status='warning'
                            >
                              The storefront integration is not installed, this fee will <strong>not</strong> be displayed on the product page. <ShopifyLink onClick={() => goToPage({ history, path: '/settings/storefront?section=ticket-fees' })}>Install the storefront integration</ShopifyLink> to show the service fee on the product page.
                            </Banner>
                          )}
                        </FormLayout>
                      </NestedFormLayoutContainer>
                    </Collapsible>
                  </FormLayout>
                </Modal.Section>
              }
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  )
};

export default TicketTypeModal;
