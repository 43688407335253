import React from 'react';
import { Card, Stack, Heading } from '@shopify/polaris';
import styled from '@emotion/styled';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import LoadingCard from './Cards/LoadingCard';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  height:100%;
`;

const CardContent = styled.div`
  padding-top:20px;
  flex-grow: 2;
`;

const DashboardCard = ({ loading, title, headerAction, footerLeft, primaryAction, secondaryAction, children }) => {
  if (loading) {
    return <LoadingCard title='Ticket sales' />;
  }

  return (
    <Card>
      <Container>
        <CardHeader
          title={title}
          action={headerAction}
        />
        <CardContent>
          {children}
        </CardContent>
        <CardFooter
          left={footerLeft}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
        />
      </Container>
    </Card>
  );
};

export default DashboardCard;
