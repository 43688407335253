import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { CANCEL_SUBSCRIPTION } from '../../../utils/graphql';

const CancelSubscriptionModal = ({ shop, subscription, onDismiss }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={CANCEL_SUBSCRIPTION}
      onError={() => showToastError('Failed to cancel subscription')}
      onCompleted={() => {
        showToastNotice('Subscription cancelled');
        onDismiss();
      }}
      refetchQueries={() => ['EventQuery', 'ActiveSubscriptionQuery']}
    >
      {(cancelSubscription, { loading: cancelling }) => (
        <Modal
          title='Confirm subscription cancel'
          open={true}
          onClose={onDismiss}
          primaryAction={{
            content: 'Yes, cancel subscription',
            destructive: true,
            loading: cancelling,
            onAction: () => {
              cancelSubscription({
                variables:{
                  subscriptionId: subscription.id,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: cancelling,
              onAction: onDismiss,
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>Are you sure you want to cancel your subscription? You will not have access to your events anymore or be able to create new tickets unless you re-subscribe.</TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
}

export default CancelSubscriptionModal;
