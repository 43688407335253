import React from 'react';
import { Modal, Card, ResourceList, TextContainer, EmptyState } from '@shopify/polaris';
import CommunicationResourceItem from './CommunicationResourceItem.jsx';

const communicationFailureMessage = (comm) => {
  if (comm.errorCode == 'invalid_mobile_number') {
    return 'The phone number is not a valid mobile number. It is likely that the number is a landline or invalid number';
  } else if (comm.errorCode == 'cannot_deliver') {
    return 'The SMS could not be delivered to the destination, the number is unknown or no longer exists';
  } else if (comm.errorCode == 'carrier_guidelines') {
    return 'The SMS could not be delivered to the destination due to content restrictions from the destination carrier';
  } else if (comm.errorCode == 'mail_spam') {
    return 'The recipient email provider has marked this email as spam and rejected delivery';
  } else if (comm.errorCode == 'mail_bounced' || comm.errorCode == 'mail_rejected') {
    return 'We receieved a bounce or reject notice from the recipient provider. It is likely that the email address does not exist or is disabled';
  } else if (comm.errorCode == 'invalid_content') {
    return 'The content of the message was invalid. This could be due to some invalid characters or that the message was too long (possible for SMS).';
  } else {
    return 'Unknown error';
  }
};

const PreviewModal = ({ communication, onClose }) => {
  const didFail = !communication.sentAt || communication.errorCode;

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={didFail ? 'Failure details' : 'Message content'}
      secondaryActions={[
        {
          content: 'Close',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        {didFail ? (
          <TextContainer>{communicationFailureMessage(communication)}</TextContainer>
        ) : (
          <div>
            {communication.communicationType == 'sms' ? (
              <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: communication.content}}></pre>
            ) : (
              <div dangerouslySetInnerHTML={{__html: communication.content}}></div>
            )}
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
};

const CommunicationsCard = ({ communications }) => {
  const [showPreviewFor, setShowPreviewFor] = React.useState(false);

  return (
    <Card>
      <Card.Section>
        <ResourceList
          emptyState={!communications.length ? (
            <EmptyState
              heading="No communications yet"
              image="https://cdn.shopify.com/s/files/1/0481/9383/1070/files/Post.svg?v=1602010637"
            >
              <p>
                A list of all tickets and messages will apear here with a delivery status and detailed information about each.
              </p>
            </EmptyState>
          ) : undefined}
          items={communications}
          renderItem={(comm) => (
            <CommunicationResourceItem
              communication={comm}
              onSelect={(comm) => setShowPreviewFor(comm)}
            />
          )}
          resourceName={{singular: 'delivery', plural: 'deliveries'}}
        />
        {showPreviewFor &&
          <PreviewModal
            communication={showPreviewFor}
            onClose={() => setShowPreviewFor(false)}
          />
        }
      </Card.Section>
    </Card>
  );
};

export default CommunicationsCard;
