export const VIRTUAL_EVENT_SERVICES = {
  'livestream': {
    label: 'Link to livestream',
    labelPrefix: 'Livestream',
    placeholder: 'https://my_livestream.com',
    canEmbed: true,
  },
  'zoom': {
    label: 'Zoom',
    labelPrefix: 'Zoom',
    placeholder: 'https://zoom.com/my_call',
    canEmbed: false,
  },
  'google-meet': {
    label: 'Google Meet',
    labelPrefix: 'Google Meet',
    placeholder: 'https://meet.google.com/my_call',
    canEmbed: false,
  },
  'youtube': {
    label: 'YouTube Live',
    labelPrefix: 'YouTube embed',
    placeholder: 'https://youtube.com/my_live',
    canEmbed: true,
  },
  'webinar': {
    label: 'Link to webinar',
    labelPrefix: 'Webinar',
    placeholder: 'https://my_webinar.com',
    canEmbed: false,
  }
};
