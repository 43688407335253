import React from 'react';
import { Tabs, SkeletonPage, SkeletonDisplayText, Layout } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import EventSettings from './EventSettings.jsx';
import EventTickets from './EventTickets.jsx';
import EventTags from './EventTags.jsx';
import EventSubscription from './EventSubscription.jsx';
import EventAttendeeInfo from './EventAttendeeInfo.jsx';
import EventIntegrations from './EventIntegrations.jsx';
import EventNotifications from './EventNotifications.jsx';
import CheckinSettings from '../CheckinSettings/CheckinSettings.jsx';
import VirtualEvents from './VirtualEvents.jsx';
import { EVENT_FIELDS } from '../../utils/graphql';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import SeatingPage from './SeatingPage';
import { useHistory, useLocation } from "react-router-dom";
import { hasNewPricing } from '../../utils/features';
import { trackEvent } from '../../utils/track';
import { useFrame } from '../../contexts/frame';
import FirstEventReviewModal from './FirstEventReviewModal.js';

const LOADING_PAGE = (
  <SkeletonPage fullWidth>
    <div className="EventEdit__Tabs">
      <Tabs
        tabs={[
          {
            id: 'skeleton1',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton1',
            panelID: 'skeleton1-content'
          },
          {
            id: 'skeleton2',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton2',
            panelID: 'skeleton2-content'
          },
          {
            id: 'skeleton3',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton3',
            panelID: 'skeleton3-content'
          },
          {
            id: 'skeleton4',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton4',
            panelID: 'skeleton4-content'
          },
          {
            id: 'skeleton5',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton5',
            panelID: 'skeleton5-content'
          },
          {
            id: 'skeleton6',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton6',
            panelID: 'skeleton6-content'
          },
          {
            id: 'skeleton7',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton7',
            panelID: 'skeleton7-content'
          },
          {
            id: 'skeleton8',
            content: <SkeletonDisplayText size="medium" />,
            accessibilityLabel: 'Skeleton8',
            panelID: 'skeleton8-content'
          },
        ]}
        selected={0}
        fitted
      >
      </Tabs>
    </div>
    <Layout>
      <AnnotatedSectionLoading />
      <AnnotatedSectionLoading />
    </Layout>
  </SkeletonPage>
);

const EventEdit = ({ match, flashCallback, section }) => {
  const [selectedTabId, setSelectedTabId] = React.useState(section || (new URLSearchParams(window.location.search)).get('section') || 'event');

  const location = useLocation();
  const history = useHistory();
  const { isVendor, canEditEventAttendeeInfo, canEditNotificationTemplates } = useFrame();

  const showSeating = true;

  return (
    <EventQuery
      eventId={match.params.eventId}
      extraEventAttributes={EVENT_FIELDS}
      extraShopAttributes={`
        savedLocations
      `}
      loadingPage={LOADING_PAGE}
    >
      {({ shop, event }) => {
        const newPricingEnabled = hasNewPricing(shop.domain);

        let tabs = [
          {
            id: 'event',
            content: 'Event',
            accessibilityLabel: 'Event',
            panelID: 'event-content'
          },
          {
            id: 'tickets',
            content: 'Tickets',
            accessibilityLabel: 'Tickets',
            panelID: 'tickets-content'
          },
          ...(canEditNotificationTemplates ? [{
            id: 'notifications',
            content: 'Notifications',
            accessibilityLabel: 'Notifications',
            panelID: 'notifications-content'
          }] : []),
          ...(isVendor ? [] : [{
            id: 'tags',
            content: 'Customers & Tags',
            accessibilityLabel: 'Tags',
            panelID: 'tags-content'
          }]),
          ...(canEditEventAttendeeInfo ? [{
            id: 'attendee-info',
            content: 'Attendee Information',
            accessibilityLabel: 'Attendee information',
            panelID: 'attendee-info-content'
          }] : []),
          {
            id: 'checkin',
            content: 'Check-In',
            accessibilityLabel: 'Check-In',
            panelID: 'checkin-content'
          },
          {
            id: 'integrations',
            content: 'Integrations',
            accessibilityLabel: 'Integrations',
            panelID: 'integrations-content'
          },
          ...(event.locationType == 'online' ? [{
              id: 'virtual',
              content: 'Virtual Events',
              accessibilityLabel: 'Virtual events',
              panelID: 'virtual-content'
            }] : []
          ),
          ...(shop.subscribed || shop.activeSubscription || (newPricingEnabled && !shop.hasLegacyPricing && !event.subscribed) ? [] : [{
            id: 'subscription',
            content: 'Subscription',
            accessibilityLabel: 'Subscription',
            panelID: 'subscription-content'
          }]),
          ...(event.locationType == 'venue' && event.savedLocation && event.savedLocation.seatingChart && showSeating ? [{
              id: 'seating',
              content: 'Seating',
              accessibilityLabel: 'Seating',
              panelID: 'seating-content'
            }] : []
          ),
        ];

        return (
          <EventPage
            fullWidth={true}
            shop={shop}
            event={event}
            title="Event Settings"
            primaryAction={null}
            pageTitle="Event Settings"
          >
            <FirstEventReviewModal />
            <div className="EventEdit__Tabs">
              <Tabs
                tabs={tabs}
                selected={tabs.findIndex((t) => t.id == selectedTabId) || 0}
                onSelect={(newTab) => {
                  setSelectedTabId(tabs[newTab].id);
                  history.push(`/events/${event.id}/edit?section=${tabs[newTab].id}`);
                  trackEvent(`Navigate to /events/00/edit`, { section: tabs[newTab].id });
                }}
                fitted
              >
              </Tabs>
            </div>
            <div className="EventEdit__TabContent">
              {selectedTabId === 'event' &&
                <EventSettings
                  shop={shop}
                  event={event}
                />
              }
              {selectedTabId === 'tickets' &&
                <EventTickets
                  shop={shop}
                  event={event}
                  flashCallback={flashCallback}
                  history={history}
                />
              }
              {selectedTabId === 'notifications' &&
                <EventNotifications eventId={match.params.eventId} />
              }
              {selectedTabId === 'tags' &&
                <EventTags
                  shop={shop}
                  event={event}
                />
              }
              {selectedTabId === 'attendee-info' &&
                <EventAttendeeInfo
                  shop={shop}
                  event={event}
                  flashCallback={flashCallback}
                  history={history}
                />
              }
              {selectedTabId === 'checkin' &&
                <CheckinSettings
                  eventId={match.params.eventId}
                  flashCallback={flashCallback}
                  history={history}
                />
              }
              {selectedTabId === 'integrations' &&
                <EventIntegrations shop={shop} eventId={match.params.eventId} />
              }
              {selectedTabId === 'virtual' &&
                <VirtualEvents
                  eventId={match.params.eventId}
                  flashCallback={flashCallback}
                  history={history}
                />
              }
              {selectedTabId === 'subscription' &&
                <EventSubscription
                  shop={shop}
                  event={event}
                  flashCallback={flashCallback}
                  history={history}
                />
              }
              {selectedTabId === 'seating' &&
                <SeatingPage shop={shop} eventId={match.params.eventId} />
              }
            </div>
          </EventPage>
        );
      }}
    </EventQuery>
  );
};

export default EventEdit;
