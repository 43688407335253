import React from 'react';
import { SkeletonBodyText, SkeletonDisplayText, TextContainer, Card } from '@shopify/polaris';
import CardHeader from '../CardHeader';
import styled from '@emotion/styled';

const Section = styled.div`
  padding:20px;
`;

const LoadingCard = ({ title }) => {
  return (
    <Card>
      {title && <CardHeader title={title} />}
      <Section>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Section>
      <Section>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Section>
    </Card>
  );
};

export default LoadingCard;
