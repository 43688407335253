import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Banner, PageActions, Button, Collapsible, Modal } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';

export default class CheckinUserEdit extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    email: null,
    password: '',
    passwordConfirm: '',
    toggleChangePassword: false,
    deleteConfirmModalOpen: false,

    errors: {},
    unknownError: false
  }

  EVENT_FIELDS = `
    user(eventId: $eventId, id: $userId) {
      id
      createdAt
      email
      checkedinAttendeesCount
    }
  `;

  EDIT_USER = gql`
    mutation editCheckinUser($eventId: ID!, $userId: ID!, $email: String!, $password: String, $passwordConfirm: String) {
      editCheckinUser(eventId: $eventId, userId: $userId, email: $email, password: $password, passwordConfirm: $passwordConfirm) {
        user {
          id
        }
      }
    }
  `;

  DELETE_USER = gql`
    mutation deleteCheckinUser($eventId: ID!, $userId: ID!) {
      deleteCheckinUser(eventId: $eventId, userId: $userId) {
        success
      }
    }
  `;

  userEmailState = (user) => {
    if (this.state.email == null) {
      return user.email;
    } else {
      return this.state.email;
    }
  }

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  renderPageContent = (shop, event, user) => {
    const { toggleChangePassword, password, passwordConfirm } = this.state;
    return (
      <div>
        <Mutation mutation={this.EDIT_USER}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false });
            } else {
              this.setState({ unknownError: true, errors: [] });
              this.reportError(error);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onCompleted={(data) => {
            if (data && data.editCheckinUser) {
              this.props.flashCallback(true, 'User successfully updated');
            }
          }}
        >
          {(editCheckinUser, { loading }) => {
            return (
              <div>
                {this.state.unknownError &&
            <Banner
              title="There was a problem creating the user"
              status="critical"
            >
              <p>
                Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
              </p>
            </Banner>
                }
                {this.state.errors && this.state.errors.length > 0 &&
            <Banner
              title="There was a problem creating the user"
              status="critical"
            >
              {this.errorMessage()}
            </Banner>
                }
                <form id="editCheckinUserForm" onSubmit={e => {
                  e.preventDefault();
                  this.setState({ unknownError: false, errors: [] });

                  editCheckinUser({
                    variables: {
                      eventId: event.id,
                      userId: user.id,
                      email: this.userEmailState(user),
                      password: toggleChangePassword ? password : '',
                      passwordConfirm: toggleChangePassword ? passwordConfirm : ''
                    }
                  });
                }}>
                  <Card title="Update check-in user" sectioned>
                    <FormLayout>
                      <TextField key="email" id='email' type='email' value={this.userEmailState(user) || ''} label="Email" placeholder="" onChange={this.handleChange('email')} />
                      <Button
                        id="changePassword"
                        plain={true}
                        onClick={() => this.setState({ toggleChangePassword: !toggleChangePassword }) }
                      >
                        {toggleChangePassword ? 'Cancel change password' : 'Change password'}
                      </Button>
                      <Collapsible open={toggleChangePassword} id="checkinUserChangePasswordCollapsible">
                        <TextField key="password" type='password' label="New password" value={password} onChange={this.handleChange('password')} />
                        <TextField key="passwordConfirm" type='password' label="Confirm new password" value={passwordConfirm} onChange={this.handleChange('passwordConfirm')} />
                      </Collapsible>
                    </FormLayout>

                    <PageActions
                      primaryAction={{
                        content: 'Save',
                        loading: loading,
                        submit: true
                      }}
                      secondaryActions={[
                        {
                          content: 'Cancel',
                          disabled: loading,
                          target: 'APP',
                          onAction: () => {
                            this.props.history.push(`/events/${event.id}/checkin_settings`);
                            window.scrollTo({ top: 0 });
                          }
                        },
                        {
                          content: 'Delete User',
                          disabled: loading,
                          destructive: true,
                          onAction: () => this.setState({ deleteConfirmModalOpen: true }) }
                      ]}
                    />
                  </Card>
                </form>
              </div>
            );
          }}
        </Mutation>
        <Mutation mutation={this.DELETE_USER}
          onError={() => {
            this.props.flashCallback(true, 'Unable to delete user', true);
            this.setState({ deleteConfirmModalOpen: false });
          }}
          onCompleted={() => {
            this.setState({ deleteConfirmModalOpen: false });
            this.props.flashCallback(true, 'User successfully deleted');
            window.location.replace(`/events/${event.id}/checkin_settings`);
          }}
        >
          {(deleteCheckinUser, { loading }) => (
            <Modal
              title='Confirm check-in user deletion'
              open={this.state.deleteConfirmModalOpen}
              onClose={() => {
                this.setState({ deleteConfirmModalOpen: false });
              }}
              primaryAction={{
                content: 'Yes, delete it',
                destructive: true,
                disabled: loading,
                onAction: () => {
                  deleteCheckinUser({
                    variables:{
                      eventId: event.id,
                      userId: user.id
                    }
                  });
                }
              }}
              secondaryActions={[
                {
                  content: 'No, go back',
                  disabled: loading,
                  onAction: () => {
                    this.setState({ deleteConfirmModalOpen: false });
                  }
                }
              ]}
            >
              <Modal.Section>
                Are you sure you want to delete this check-in user? This cannot be reversed.
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      </div>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        extraVariables={{'userId': this.props.match.params.eventUserId}}
        queryDeclaration={'CheckinUserQuery($eventId: ID!, $userId: ID!)'}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          let user = event.user;

          return (
            <EventPage
              shop={shop}
              event={event}
              title={user.email}
              extraBreadcrumbs={[
                {
                  content: 'Check-In Settings',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/checkin_settings`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              {this.renderPageContent(shop, event, user)}
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

CheckinUserEdit.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string,
      eventUserId: PropTypes.string
    })
  })
};
