import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, ChoiceList } from '@shopify/polaris';
import ModalFooter from '../shared/ModalFooter.jsx';
import styled from '@emotion/styled';
import { useFrame } from '../../contexts/frame';
import { EDIT_SCHEDULED_TIME } from '../../utils/graphql';
import { buildDateWithCompensatedTimezone } from '../../utils/time';
import Calendar from '../Schedule/Calendar';
import moment from 'moment';
import { buildCalendarEvents } from '../../utils/schedule';
import InventoryLevel from './InventoryLevel';

const ScheduleModal = ({ shop, event, attendee, ticket, onClose, saveTitle, onSelect }) => {
  const originalDate = attendee && attendee.scheduledEvent && buildDateWithCompensatedTimezone(attendee.scheduledEvent);
  const originalStart = attendee && attendee.scheduledEvent && buildDateWithCompensatedTimezone(attendee.scheduledEvent);

  const [selectedDate, setSelectedDate] = React.useState(originalDate);
  const [selectedEvent, setSelectedEvent] = React.useState(originalStart ? `${moment(originalStart).unix()}|${attendee.scheduleItemId}` : null);

  const { showToastNotice, showToastError } = useFrame();

  const items = event.scheduleItems.map ((item) => ({
    ...item,
    startDate: item.startDate ? moment(buildDateWithCompensatedTimezone(item.startDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    endDate: item.endDate ? moment(buildDateWithCompensatedTimezone(item.endDate)).format('YYYY-MM-DD') : null,
    scheduleExceptions: item.scheduleExceptions ? item.scheduleExceptions.map((ex) => ({
      ...ex,
      startDate: moment(buildDateWithCompensatedTimezone(ex.startDate)).format('YYYY-MM-DD')
    })) : [],
    resourceId: item.id.toString(),
  }));

  let calendarEvents = buildCalendarEvents({ event, items });

  const availableEvents = selectedDate ? calendarEvents.filter((ev) => moment(ev.start).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) : [];

  return (
    <Mutation mutation={EDIT_SCHEDULED_TIME}
      onError={() => showToastError('Unable to update date/time')}
      onCompleted={() => {
        showToastNotice('Date/time updated');
        onClose();
      }}
      refetchQueries={() => ['AttendeeQuery', 'OrderQuery']}
    >
      {(editScheduledTime, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Update event date and time'
          footer={
            <ModalFooter
              saveButtonText={saveTitle || "Update"}
              saveButtonAction={() => {
                let start, scheduleItemId;
                if (selectedEvent) {
                  const parts = selectedEvent.split('|');
                  start = moment.unix(parseInt(parts[0])).format('YYYY-MM-DDTHH:mm:ss');
                  scheduleItemId = parts[1];
                }
                if (attendee) {
                  editScheduledTime({
                    variables: {
                      eventId: event.id,
                      attendeeId: attendee.id,
                      start,
                      scheduleItemId,
                    },
                  });
                }

                if (onSelect) {
                  onSelect(start, scheduleItemId);
                }
              }}
              saveButtonLoading={saving}
              cancelButtonAction={onClose}
              cancelButtonDisabled={saving}
            />
          }
        >
          <Modal.Section>
            <Calendar
              events={calendarEvents}
              selectedDate={selectedDate}
              onSelect={(date) => setSelectedDate(date)}
            />

            {selectedDate && availableEvents.length > 0 &&
              <ChoiceList
                title="Select time"
                choices={availableEvents.map((ev) => ({
                  label: (
                    <span>
                      {ev.start.toLocaleString(undefined, { dateStyle: 'medium', ...(ev.all_day ? {} : { timeStyle: 'short' }) })}
                      <InventoryLevel event={event} ticket={ticket} start={ev.start} seatId={attendee && attendee.seat && attendee.seat.id} />
                    </span>
                  ),
                  value: `${moment(ev.start).unix()}|${ev.resource.id}`
                }))}
                selected={selectedEvent ? [selectedEvent] : []}
                onChange={(v) => setSelectedEvent(v[0])}
              />
            }
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ScheduleModal;
