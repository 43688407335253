import React from 'react';
import { Page, Layout, Card, TextField, FormLayout, Form, Button, Stack, Avatar, Heading, TextStyle, Icon, Spinner } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { FullHeightContainer } from '../shared/containers';
import { useFrame } from '../../contexts/frame';
import { useVendor } from '../../contexts/vendor';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';

const AccountPage = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [requestingPasswordChange, setRequestingPasswordChange] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const { user, forgotPassword, updateUser } = useVendor();

  React.useEffect(() => {
    setName(user.name);
    setEmail(user.email);
  }, [user]);

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Page
      title='Account settings'
      breadcrumbs={[
        {
          content: 'Back',
          onAction: () => goToPage({ history, path: '/' }),
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card
            sectioned
            footerActionAlignment='left'
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              onAction: async () => {
                try {
                  setSaving(true);
                  await updateUser({ name });
                  showToastNotice('Account settings updated');
                } catch (error) {
                  console.error(error);
                  showToastError('Failed to save settings');
                } finally {
                  setSaving(false);
                }
              },
            }}
            secondaryFooterActions={[
              {
                content: 'Change password',
                loading: requestingPasswordChange,
                plain: true,
                disabled: saving,
                onAction: async () => {
                  try {
                    setRequestingPasswordChange(true);
                    const result = await forgotPassword(email);
                    if (result.error) {
                      showToastError(result.error);
                    } else {
                      showToastNotice('An email was sent to you with password reset instructions');
                    }
                  } catch (error) {
                    showToastError('Failed to reset password');
                    throw error;
                  } finally {
                    setRequestingPasswordChange(false);
                  }
                },
              }
            ]}
          >
            <FormLayout>
              <TextField
                label='Name'
                value={name}
                onChange={setName}
              />
              <TextField
                label='Email'
                value={email}
                onChange={setEmail}
                disabled
              />
            </FormLayout>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default AccountPage;
