import React from 'react';
import moment from 'moment';
import { Mutation, Query } from 'react-apollo';
import { DELETE_SCHEDULE_EVENT, GET_INVENTORY_LEVELS_FOR_DATE, SAVE_INVENTORY_LEVELS } from '../../utils/graphql';
import { Popover, TextField, FormLayout, Select, Checkbox, Banner, Button, Stack, Spinner, Subheading } from '@shopify/polaris';
import { normalizeToUtc } from '../../utils/time';
import randomstring from 'randomstring';
import FormFieldLabel from '../shared/FormFieldLabel';
import styled from '@emotion/styled';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const ScheduleEventForm = ({ eveyEvent, item, event, onSave, onCancel }) => {
  const { showToastNotice, showToastError } = useFrame();
  const [ticketCapacities, setTicketCapacities] = React.useState([]);
  const [capacity, setCapacity] = React.useState(0);

  const history = useHistory();

  if (eveyEvent.hasSeating) {
    return (
      <Banner
        status='info'
        secondaryAction={{
          content: 'Seating manager',
          onAction: () => {
            goToPage({ history, path: `/events/${eveyEvent.id}/edit?section=seating` });
            window.location.reload();
          },
        }}
      >
        Manage your capacity levels per date and time under the seating inventory manager.
      </Banner>
    )
  }

  return (
    <Query
      query={GET_INVENTORY_LEVELS_FOR_DATE}
      variables={{
        'eventId': eveyEvent.id,
        'date': moment(event.start).format('YYYY-MM-DD'),
      }}
      onCompleted={(data) => {
        const inventoryLevels = data && data.currentShop && data.currentShop.event && data.currentShop.event.inventoryLevels.filter((lvl) => lvl.startTime === moment(event.start).format('HH:mm'));

        setTicketCapacities(eveyEvent.tickets.map((ticket) => {
          const inventoryLevel = inventoryLevels.find((lvl) => lvl.eventTicket && lvl.eventTicket.id == ticket.id);
          return {
            eventTicketId: ticket.id,
            ticketTitle: ticket.title,
            capacity: !inventoryLevel || inventoryLevel.capacity == null ? ticket.inventory : (inventoryLevel && inventoryLevel.capacity),
          };
        }));

        let initialSharedCapacity = eveyEvent.inventory;
        const sharedCapacityLevel = inventoryLevels.find((lvl) => !lvl.eventTicket || lvl.eventTicket.id == null);
        if (sharedCapacityLevel) {
          initialSharedCapacity = sharedCapacityLevel.capacity;
        }
        setCapacity(initialSharedCapacity);
      }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <Spinner size="small" color="teal" />;
        }

        return (
          <Mutation mutation={SAVE_INVENTORY_LEVELS}
            onError={(error) => showToastError('Unable to update capacity')}
            onCompleted={(data) => {
              showToastNotice('Capacity updated')
              onSave();
            }}
            refetchQueries={() => ['InventoryLevelForDateQuery']}
            awaitRefetchQueries={true}
          >
            {(saveInventoryLevels, { loading: saving }) => (
              <Mutation mutation={DELETE_SCHEDULE_EVENT}
                onError={(error) => showToastError('Unable to delete event')}
                onCompleted={(data) => {
                  showToastNotice('Schedule event removed')
                  onSave();
                }}
                refetchQueries={() => ['EventQuery']}
                awaitRefetchQueries={true}
              >
                {(deleteScheduleEvent, { loading: deleting }) => (
                  <FormLayout>
                    {eveyEvent.hasCrossVariantInventory ? (
                      <TextField value={capacity ? capacity.toString() : '0'} type="number" min={0} step={1} label="Capacity" onChange={(v) => setCapacity(v)} placeholder="100" />
                    ) : (
                      <>
                        <FormLayout>
                          <Subheading>Capacity</Subheading>
                          {ticketCapacities.map((ticketCapacity, index) => (
                            <TextField
                              key={index}
                              value={ticketCapacity.capacity ? ticketCapacity.capacity.toString() : '0'}
                              type="number" min={0} step={1}
                              label={ticketCapacity.ticketTitle}
                              onChange={(v) => {
                                const updated = [...ticketCapacities];
                                ticketCapacity.capacity = parseInt(v);
                                updated[index] = ticketCapacity;
                                setTicketCapacities(updated);
                              }}
                              placeholder="100"
                            />
                          ))}
                        </FormLayout>
                      </>
                    )}
                    <Stack vertical={false} distribution='fill'>
                      <Stack vertical={false} distribution='leading'>
                        <Button
                          loading={deleting}
                          disabled={saving}
                          destructive
                          onClick={() => {
                            deleteScheduleEvent({
                              variables: {
                                eventId: eveyEvent.id,
                                itemId: item.id,
                                startDate: moment(event.start).format('YYYY-MM-DD'),
                                startTime: moment(event.start).format('HH:mm'),
                              },
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </Stack>
                      <Stack vertical={false} distribution='trailing'>
                        <Button onClick={onCancel} disabled={saving || deleting}>Cancel</Button>
                        <Button
                          primary
                          loading={saving}
                          disabled={deleting}
                          onClick={() => {
                            let levels;
                            if (eveyEvent.hasCrossVariantInventory) {
                              levels = JSON.stringify([{
                                event_ticket_id: null,
                                start_date: moment(event.start).format('YYYY-MM-DD'),
                                start_time: moment(event.start).format('HH:mm'),
                                capacity,
                              }]);
                            } else {
                              levels = JSON.stringify(ticketCapacities.map((cap) => ({
                                event_ticket_id: cap.eventTicketId,
                                start_date: moment(event.start).format('YYYY-MM-DD'),
                                start_time: moment(event.start).format('HH:mm'),
                                capacity: cap.capacity,
                              })));
                            }
                            saveInventoryLevels({
                              variables: {
                                eventId: eveyEvent.id,
                                inventoryLevels: levels,
                              },
                            });
                          }}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Stack>
                  </FormLayout>
                )}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default ScheduleEventForm;
