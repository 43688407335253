import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, TextContainer, TextStyle, DropZone, Spinner, DataTable, Link as ShopifyLink } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import UserFileDeleteButton from './UserFileDeleteButton';
import { humanFileSize } from '../../utils/files';

const ADD_USER_FILE = gql`
  mutation addUserFile($eventId: ID!, $file: Upload!) {
    addUserFile(eventId: $eventId, file: $file) {
      files {
        id
        createdAt
        url
        filename
        size
        token
      }
    }
  }
`;

const UserFilesCard = ({ event, files }) => {
  const { showToastNotice, showToastError } = useFrame();

  let rows = [];

  for (let i in files) {
    let file = files[i];

    let fileLink = <ShopifyLink external url={`${file.url}`} target="_blank">{file.filename}</ShopifyLink>;
    let fileSize = humanFileSize(file.size, true);
    let fileTag = `{{ '${file.token}' | file_url:'http' }}`;

    let actions = <UserFileDeleteButton event={event} file={file} />;

    rows.push([fileLink, fileSize, fileTag, actions]);
  }

  return (
    <Mutation mutation={ADD_USER_FILE}
      onError={(error) => showToastError('Unable to upload file')}
      onCompleted={(data) => showToastNotice('File added')}
      refetchQueries={() => ['EventQuery']}
    >
      {(addUserFile, { loading }) => {
        return (
          <Card sectioned title="Custom Files">
            <TextContainer spacing="loose">
              <p>
              Upload files here so that you can use them in your PDF ticket template code.
              Just include the liquid tag provided for each file in your code to use it, for example in an
              HTML &lt;img&gt; tag src attribute if it is an image.
              </p>
            </TextContainer>
            <TextStyle variation="strong">Add file</TextStyle>
            {!loading &&
            <DropZone
              label={''}
              allowMultiple={false}
              onDropAccepted={(acceptedFiles) => {
                addUserFile({
                  variables:{
                    eventId: event.id,
                    file: acceptedFiles[0]
                  }
                });
              }}
            >
              <DropZone.FileUpload
                actionTitle='Select file'
                actionHint='or drop file to upload'
              />
            </DropZone>
            }
            {loading &&
            <div style={{textAlign:'center',marginTop:'50px'}}>
              <Spinner size="large" color="teal" />
            </div>
            }
            {event.files && event.files.length > 0 &&
              <DataTable
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'text'
                ]}
                headings={[
                  'File',
                  'Size',
                  'Liquid Tag',
                  ''
                ]}
                rows={rows}
              />
            }
          </Card>
        );
      }}
    </Mutation>
  );
};

export default UserFilesCard;
