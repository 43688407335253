import React from 'react';
import { Query } from 'react-apollo';
import { Card, Button, ButtonGroup, TextContainer, TextStyle, Heading, Popover, ActionList, SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris';
import styled from '@emotion/styled';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import gql from 'graphql-tag';
import SharedTemplateModal from '../shared/SharedTemplateModal';

const GET_TEMPLATE = gql`
  query GetTemplateSummary($templateType: String, $templateId: ID) {
    currentShop {
      domain
      template(id: $templateId, type: $templateType) {
        id
        templateType
        eventsCount
        previewImageUrl
      }
    }
  }
`;

const Header = styled.div`
  padding:2rem;
  display:flex;
  align-items:center;

  .Polaris-ButtonGroup {
    flex:1;
    justify-content:flex-end;
  }
`;

const ImageContainer = styled.div`
  display:flex;
  align-items:flex-end;
  justify-content:center;

  img {
    width:70%;
  }
`;

const LoadingCardSection = styled.div`
  padding:20px;
`;

const LoadingCard = () => {
  return (
    <Card>
      <LoadingCardSection>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LoadingCardSection>
      <LoadingCardSection>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LoadingCardSection>
      <LoadingCardSection>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </LoadingCardSection>
    </Card>
  );
};

const TemplateCard = ({ templateId, templateType, sharedTemplateType, event, editCodePath, sampleUrl }) => {
  const [moreActionsActive, setMoreActionsActive] = React.useState(false);
  const [showSharedTemplateModal, setShowSharedTemplateModal] = React.useState(false);

  const history = useHistory();

  return (
    <Query
      query={GET_TEMPLATE}
      variables={{ templateId, templateType }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <LoadingCard />
          );
        }

        const shop = data?.currentShop;
        const template = data?.currentShop?.template;

        return (
          <Card>
            <Header>
              <TextContainer>
                <Heading>Default</Heading>
                <TextStyle variation='subdued'>Used by {template.eventsCount == 0 ? 'no' : template.eventsCount} event{template.eventsCount == 1 ? '' : 's'}. {template.eventsCount > 1 ? 'Any updates here will apply to those events as well.' : ''}</TextStyle>
              </TextContainer>
              <ButtonGroup>
                <Popover
                  active={moreActionsActive}
                  activator={(
                    <Button
                      disclosure
                      onClick={() => setMoreActionsActive(!moreActionsActive)}
                    >
                      Actions
                    </Button>
                  )}
                  onClose={() => setMoreActionsActive(false)}
                >
                  <Popover.Pane>
                    <ActionList
                      actionRole="menuitem"
                      items={[
                        {
                          content: 'Preview',
                          url: sampleUrl,
                          external: true,
                          target: 'REMOTE',
                        },
                        ...(editCodePath ? [{
                            content: 'Edit code',
                            onAction: () => goToPage({ history, path: editCodePath }),
                          }] : []),
                        {
                          content: 'Edit languages',
                          onAction: () => goToPage({ history, path: '/settings/translations' }),
                        },
                        {
                          content: 'Shared template options',
                          onAction: () => setShowSharedTemplateModal(true),
                        },
                      ]}
                    />
                  </Popover.Pane>
                </Popover>
                <Button
                  primary
                  onClick={() => goToPage({
                    history,
                    path: '/editor',
                    params: {
                      template_id: templateId,
                      template_type: templateType,
                      ...(event ? { event_id: event.id } : {}),
                    },
                  })}
                >
                  Customize
                </Button>
              </ButtonGroup>
            </Header>
            <ImageContainer>
              <img src={template.previewImageUrl} />
            </ImageContainer>
            {event && showSharedTemplateModal &&
              <SharedTemplateModal
                shop={shop}
                event={event}
                template={template}
                templateType={sharedTemplateType}
                onClose={() => setShowSharedTemplateModal(false)}
              />
            }
          </Card>
        );
      }}
    </Query>
  );
};

export default TemplateCard;
