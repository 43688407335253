import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, ChoiceList } from '@shopify/polaris';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import { EDIT_VIRTUAL_EVENT_PAGE_CODE } from '../../utils/graphql';

const VirtualEventPageCodeCard = ({ event, flashCallback }) => {
  const [body, setBody] = React.useState(event.virtualEventPage.body && event.virtualEventPage.body.length ? event.virtualEventPage.body : event.virtualEventPage.defaultBody);
  const [selected, setSelected] = React.useState([!!(event.virtualEventPage.body && event.virtualEventPage.body.length)]);

  const virtualEventPage = event.virtualEventPage;

  const customize = selected[0];

  return (
    <Mutation mutation={EDIT_VIRTUAL_EVENT_PAGE_CODE}
      onError={() => {
        flashCallback(true, 'Unable to update virtual event page', true);
      }}
      onCompleted={() => {
        flashCallback(true, 'Virtual event page updated');
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editVirtualEventPageCode, { loading }) => (
        <Card
          primaryFooterAction={{
            content: 'Save',
            loading: loading,
            onAction: () => {
              editVirtualEventPageCode({
                variables: {
                  eventId: event.id,
                  body: customize ? body : null,
                },
              });
            },
          }}
        >
          <Card.Section>
            <ChoiceList
              title=''
              choices={[
                { label: 'Use default virtual event page template', value: false },
                { label: 'Customize virtual event page template', value: true }
              ]}
              selected={selected}
              onChange={(v) => setSelected(v)}
            />
            <div className="TicketEmailEdit__AceEditor" style={{position: 'relative'}}>
              {!customize &&
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#5c5c5c',
                  opacity: '0.05',
                  zIndex: '1000'
                }}></div>
              }
              <AceEditor
                mode="liquid"
                theme="tomorrow"
                onChange={(v) => setBody(v)}
                value={(customize ? body : virtualEventPage.defaultBody) || ''}
                fontSize='1.4rem'
                width='100%'
                height='500px'
                showPrintMargin={false}
                showGutter={false}
                highlightActiveLine={true}
                setOptions={{
                  showLineNumbers: false,
                  tabSize: 2,
                  fontSize: 'inherit',
                }}
                readOnly={!customize}
                editorProps={{$blockScrolling: true}}
                focus={customize}
              />
            </div>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default VirtualEventPageCodeCard;
