import React from 'react';
import AppPage from '../AppPage/AppPage.jsx';
import LegacyTrial from '../Trial/LegacyTrial';
import MissingProduct from '../MissingProduct/MissingProduct.jsx';
import { useHistory, useLocation } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { GET_STOREFRONT_URL } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import { hasNewPricing } from '../../utils/features';
import { ArrowLeftMinor } from '@shopify/polaris-icons';

const EventPage = ({ shop, event, title, extraBreadcrumbs, primaryAction, secondaryActions, actionGroups, fullWidth, pageTitle, pageSubtitle, children }) => {
  const [loadingStorefrontUrl, setLoadingStorefrontUrl] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const { loadingMarkup, canEditEvents, canViewAttendees, navigateTo, isVendor, isExternal } = useFrame();

  const newPricingEnabled = hasNewPricing(shop.domain);

  const appHost = document.head.querySelector('[name=app-host]').content;

  let breadcrumbs = [
    {
      content: 'Events',
      onAction: () => goToPage({ history, path: '/events' }),
      target: 'APP'
    },
    {
      content: event.title,
      onAction: () => goToPage({ history, path: `/events/${event.id}` }),
      target: 'APP'
    }
  ];
  if (extraBreadcrumbs) {
    breadcrumbs.push(...extraBreadcrumbs);
  }

  let eventOptions = [
    {
      content: 'View on storefront',
      // url: event.publicProductUrl,
      loading: loadingStorefrontUrl,
      onAction: async () => {
        const url = `https://${appHost}/events/${event.id}/storefront_url?shop=${shop.domain}&shop_id=${shop.remoteId}`;
        navigateTo({
          history,
          path: url,
          external: true,
          newContext: true
        });
      },
      target: 'REMOTE',
      external: true
    },
    ...(isVendor ? [] : [{
      content: 'Open in Shopify admin',
      url: '/products/' + event.productId,
      target: 'ADMIN_PATH'
    }]),
    ...(canEditEvents ? [{
      content: 'Event settings',
      onAction: () => goToPage({ history, path: `/events/${event.id}/edit` }),
      target: 'APP'
    }] : []),
  ];
  if (event.isShopifySubscription) {
    eventOptions.push({
      content: 'View Subscription',
      onAction: () => goToPage({ history, path: `/events/${event.id}/edit?section=subscription` }),
      target: 'APP'
    });
  }

  let _actionGroups = [
    {
      title: 'More',
      actions: eventOptions
    },
  ];
  if (actionGroups != undefined) {
    _actionGroups = actionGroups;
  }

  let _secondaryActions = [
    {
      content: isExternal ? 'Events' : 'Back to events',
      icon: isExternal ? ArrowLeftMinor : undefined,
      onAction: () => goToPage({ history, path: '/events' }),
      target: 'APP'
    },
    {
      content: 'Dashboard',
      onAction: () => goToPage({ history, path: `/events/${event.id}` }),
      target: 'APP'
    },
    ...(canViewAttendees ? [{
      content: 'Attendees',
      onAction: () => goToPage({ history, path: `/events/${event.id}/attendees` }),
      target: 'APP'
    }] : []),
    ...(canViewAttendees ? [{
      content: 'Waitlist',
      onAction: () => goToPage({ history, path: `/events/${event.id}/queues` }),
      target: 'APP'
    }] : []),
    ...(canViewAttendees ? [{
      content: 'Orders',
      onAction: () => goToPage({ history, path: `/events/${event.id}/event_orders` }),
      target: 'APP'
    }] : []),
  ];
  if (secondaryActions) {
    _secondaryActions = secondaryActions;
  }

  let _primaryAction = canEditEvents ? {
    content: 'Event settings',
    onAction: () => goToPage({ history, path: `/events/${event.id}/edit` }),
  } : undefined;
  // let primaryAction = undefined;
  if (primaryAction) {
    _primaryAction = primaryAction;
  }

  let availableTrialPaths = [
    `/events/${event.id}/edit`,
    `/events/${event.id}/copy`,
  ];
  // if (shop.domain == 'ls-n-global.myshopify.com') {
  //   availableTrialPaths.push(`/events/${event.id}/attendees`);
  // }
  const pageNotAvailable = (!shop.subscribed && !event.subscribed && !(newPricingEnabled && !shop.trialExpired || !newPricingEnabled && event.trial || newPricingEnabled && shop.hasLegacyPricing && event.trial) && !shop.activeSubscription && !availableTrialPaths.includes(window.location.pathname));
  // const pageNotAvailable = false;

  return (
    <AppPage
      title={title}
      breadcrumbs={breadcrumbs}
      primaryAction={_primaryAction}
      secondaryActions={_secondaryActions}
      actionGroups={_actionGroups}
      fullWidth={fullWidth || false}
      pageTitle={pageTitle || null}
      pageSubtitle={pageSubtitle || null}
    >
      {loadingStorefrontUrl && loadingMarkup}
      <LegacyTrial shop={shop} event={event} />
      <MissingProduct shop={shop} event={event} />
      {pageNotAvailable ? (
        ''
      ) : (
        children
      )}
    </AppPage>
  );
};

export default EventPage;
