import React from 'react';
import { Card, TextContainer, FormLayout, ChoiceList, TextField, Checkbox, Collapsible, Button, Stack } from '@shopify/polaris';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useFrame } from '../../contexts/frame';
import { NestedFormLayoutContainer } from '../shared/containers';
import { getTimeString } from '../../utils/time';
import { parseBoolean } from '../../utils/parse';

const EDIT_CHECKIN_TICKETS = gql`
  mutation editCheckinTicketSettings($eventId: ID!, $tickets: String) {
    editCheckinTicketSettings(eventId: $eventId, tickets: $tickets) {
      success
    }
  }
`;

const appendLeadingZeroes = (n) => {
  if(n <= 9){
    return '0' + n;
  }
  return n;
};

const buildInitialTickets = (event) => {
  let tickets = {};
  for(let i in event.ticketsIncludingDeleted) {
    let ticket = event.ticketsIncludingDeleted[i];
    console.log(`ticket: ${JSON.stringify(ticket)}`);
    let item = {
      checkinEnabledOption: 'any',
      enableOnlyAfter: false,
      startDate: null,
      startTime: null,
      enableOnlyBefore: false,
      endDate: null,
      endTime: null,
      checkinLimit: ticket.checkinLimit.toString(),
      expiryEnabled: !!ticket.expiresInDays,
      expiresInDays: (ticket.expiresInDays || '').toString(),
      allowCheckinCancel: ticket.allowCheckinCancel,
      enabledRelativeStart: (ticket.enabledRelativeStart == null ? '0' : ticket.enabledRelativeStart).toString(),
    };

    if (ticket.enabledAfterEventStart) {
      item.checkinEnabledOption = 'event-start';
    } else if (ticket.enabledDuringEvent) {
      item.checkinEnabledOption = 'event';
    }

    if (ticket.checkinEnabledAfter != null && ticket.checkinEnabledAfter.toString().length > 0) {
      item.enableOnlyAfter = true;
      if (!ticket.enabledDuringEvent) {
        item.checkinEnabledOption = 'custom';
      }
      let startAt = new Date(ticket.checkinEnabledAfter);
      // let startAtUtc = new Date(startAt.getTime() + startAt.getTimezoneOffset() * 60000);
      // item.startTime = this.getTimeString(startAtUtc);
      // item.startDate = startAtUtc.getFullYear() + "-" + appendLeadingZeroes(startAtUtc.getMonth() + 1) + "-" + startAtUtc.getDate();

      item.startTime = getTimeString(startAt);
      item.startDate = startAt.getFullYear() + '-' + appendLeadingZeroes(startAt.getMonth() + 1) + '-' + appendLeadingZeroes(startAt.getDate());
    }

    if (ticket.checkinEnabledBefore != null && ticket.checkinEnabledBefore.toString().length > 0) {
      item.enableOnlyBefore = true;
      if (!ticket.enabledDuringEvent) {
        item.checkinEnabledOption = 'custom';
      }
      let endAt = new Date(ticket.checkinEnabledBefore);
      // let endAtUtc = new Date(endAt.getTime() + endAt.getTimezoneOffset() * 60000);
      // item.endTime = this.getTimeString(endAtUtc);
      // item.endDate = endAtUtc.getFullYear() + "-" + appendLeadingZeroes(endAtUtc.getMonth() + 1) + "-" + endAtUtc.getDate();

      item.endTime = getTimeString(endAt);
      item.endDate = endAt.getFullYear() + '-' + appendLeadingZeroes(endAt.getMonth() + 1) + '-' + appendLeadingZeroes(endAt.getDate());
    }

    tickets[ticket.id.toString()] = item;
  }
  return tickets;
}

const CheckinTicketTypesCard = ({ shop, event }) => {
  const [tickets, setTickets] = React.useState(buildInitialTickets(event));
  const [showDeletedTicketTypes, setShowDeletedTicketTypes] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' ');

  const handleTicketChange = (field, event, ticket) => {
    return (value) => {
      let tmpTickets = {...tickets};
      tmpTickets[ticket.id.toString()][field] = value;
      setTickets(tmpTickets);
    };
  };

  const renderTicketTypeCardSection = (shop, event, ticket) => (
    <Card.Section title={ticket.title} key={`ticket-section-${ticket.id.toString()}`}>
      <FormLayout>
        <ChoiceList
          title="When is check-in allowed for this ticket type?"
          choices={[
            { label: 'Check-in is allowed at any time', value: 'any' },
            { label: "Enable check-in for a ticket only during it's scheduled event time", value: 'event' },
            { label: "Enable check-in for a ticket any time after the start of the scheduled event time", value: 'event-start' },
            { label: "Enable check-in at custom start/end times", value: 'custom' },
          ]}
          selected={[tickets[ticket.id.toString()].checkinEnabledOption]}
          onChange={(v) => handleTicketChange('checkinEnabledOption', event, ticket)(v[0])}
        />
        {tickets[ticket.id.toString()].checkinEnabledOption == 'custom' &&
          <NestedFormLayoutContainer>
            <div>
              <Checkbox
                checked={tickets[ticket.id.toString()].enableOnlyAfter}
                label=<span>Enable check-in only <strong>after</strong> a date/time</span>
                onChange={handleTicketChange('enableOnlyAfter', event, ticket)}
                helpText=""
              />
            </div>
            <Collapsible open={tickets[ticket.id.toString()].enableOnlyAfter} id="checkin-enable-after-collapsible">
              <div style={{marginBottom:'1.6em'}}>
                <FormLayout.Group condensed>
                  <TextField value={tickets[ticket.id.toString()].startDate} type="date" label="After date" onChange={handleTicketChange('startDate', event, ticket)} placeholder="YYYY-MM-DD" />
                  <TextField value={tickets[ticket.id.toString()].startTime} type="time" label={`After time (${localTimezone})`} onChange={handleTicketChange('startTime', event, ticket)} placeholder="HH:MM (24-hour)" />
                </FormLayout.Group>
              </div>
            </Collapsible>

            <Checkbox
              checked={tickets[ticket.id.toString()].enableOnlyBefore}
              label=<span>Enable check-in only <strong>before</strong> a date/time</span>
              onChange={handleTicketChange('enableOnlyBefore', event, ticket)}
              helpText=""
            />
            <Collapsible open={tickets[ticket.id.toString()].enableOnlyBefore} id="checkin-enable-before-collapsible">
              <FormLayout.Group condensed>
                <TextField value={tickets[ticket.id.toString()].endDate} type="date" label="Before date" onChange={handleTicketChange('endDate', event, ticket)} placeholder="YYYY-MM-DD" />
                <TextField value={tickets[ticket.id.toString()].endTime} type="time" label={`Before time (${localTimezone})`} onChange={handleTicketChange('endTime', event, ticket)} placeholder="HH:MM (24-hour)" />
              </FormLayout.Group>
            </Collapsible>
          </NestedFormLayoutContainer>
        }
        {tickets[ticket.id.toString()].checkinEnabledOption == 'event-start' &&
          <TextField
            type="number"
            min={0}
            step={1}
            helpText='Enable check-in this number of minutes before the start of the event'
            value={tickets[ticket.id.toString()].enabledRelativeStart}
            label='Enable check-in buffer time'
            onChange={handleTicketChange('enabledRelativeStart', event, ticket)}
          />
        }

        <TextField
          type="number"
          min={1}
          helpText='This is the maximum number of check-ins allowed for each attendee. After this limit is reached and the ticket is scanned again, the check-in interface will clearly show the user that the attendee has reached their limit. This is great for events that have ticket types that allow multiple entrances to a long running event (such as a theme park or museum).'
          value={tickets[ticket.id.toString()].checkinLimit}
          label='Entrance / check-in limit'
          onChange={handleTicketChange('checkinLimit', event, ticket)}
        />

        {tickets[ticket.id.toString()].checkinLimit == 1 &&
          <Checkbox
            checked={tickets[ticket.id.toString()].allowCheckinCancel}
            label='Allow check-in user to cancel / undo a previous check-in'
            onChange={handleTicketChange('allowCheckinCancel', event, ticket)}
            helpText=""
          />
        }

        <Checkbox
          checked={tickets[ticket.id.toString()].expiryEnabled}
          label='This ticket expires after a certain number of days'
          onChange={handleTicketChange('expiryEnabled', event, ticket)}
          helpText=""
        />
        <Collapsible open={tickets[ticket.id.toString()].expiryEnabled}>
          <TextField
            type="number"
            min={1}
            helpText='An expiration for check-in of this ticket. After this number of days is reached (following the purchase date) and the ticket is scanned again, the check-in interface will clearly show the user that the ticket has expired. This is great for events that have ticket types that allow multiple entrances to a long running event (such as a theme park or museum).'
            value={tickets[ticket.id.toString()].expiresInDays}
            label='Expires in days after purchase'
            onChange={handleTicketChange('expiresInDays', event, ticket)}
          />
        </Collapsible>
      </FormLayout>
    </Card.Section>
  );

  const ticketTypesMarkup = event.ticketsIncludingDeleted
    .filter((ticket) => !ticket.isDeleted)
    .map((ticket) => renderTicketTypeCardSection(shop, event, ticket));
  const deletedTicketTypesMarkup = event.ticketsIncludingDeleted
    .filter((ticket) => ticket.isDeleted)
    .map((ticket) => renderTicketTypeCardSection(shop, event, ticket));

  return (
    <Mutation mutation={EDIT_CHECKIN_TICKETS}
      onError={(error) => showToastError('Failed to update settings')}
      onCompleted={(data) => showToastNotice('Check-In settings successfully updated')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editCheckinTicketSettings, { loading }) => (
        <Card
          title="Ticket Type Settings"
          primaryFooterAction={{
            content: 'Save Ticket Settings',
            loading: loading,
            onAction: () => {
              let ticketsResult = {};
              for(let id in tickets) {
                let ticket = tickets[id];
                let startDate = null;
                let endDate = null;
                if (ticket.enableOnlyAfter && ticket.startDate && ticket.startTime) {
                  startDate = new Date(ticket.startDate + 'T' + ticket.startTime);
                }
                if (ticket.enableOnlyBefore && ticket.endDate && ticket.endTime) {
                  endDate = new Date(ticket.endDate + 'T' + ticket.endTime);
                }
                ticketsResult[id] = {
                  enabledDuringEvent: ticket.checkinEnabledOption == 'event',
                  enabledAfterEventStart: ticket.checkinEnabledOption == 'event-start',
                  checkinEnabledStartAt: ticket.checkinEnabledOption == 'custom' ? startDate : null,
                  checkinEnabledEndAt: ticket.checkinEnabledOption == 'custom' ? endDate : null,
                  checkinLimit: parseInt(ticket.checkinLimit),
                  expiresInDays: ticket.expiryEnabled ? parseInt(ticket.expiresInDays) : null,
                  allowCheckinCancel: parseBoolean(ticket.allowCheckinCancel),
                  enabledRelativeStart: parseInt(ticket.enabledRelativeStart),
                };
              }
              editCheckinTicketSettings({
                variables:{
                  eventId: event.id,
                  tickets: JSON.stringify(ticketsResult)
                }
              });
            },
          }}
        >
          <Card.Section>
            <div style={{marginBottom:'20px'}}>
              <TextContainer spacing="loose">
                Here you can configure check-in related settings for each ticket type / variant that you have created for this event.
                You can enable check-in for attendees only during a specific time window, this is useful
                if you have an event over multiple days with a ticket type for each day and you do not want people scanning
                tickets / checking guests in to have to enforce it by looking at the ticket type. When check-in is disabled and you
                scan a ticket, you will not see the check-in button for them, instead a message that says it has been disabled is displayed.
              </TextContainer>
            </div>
              {ticketTypesMarkup}
              {deletedTicketTypesMarkup && deletedTicketTypesMarkup.length > 0 ? (
                <Stack vertical={true}>
                  <Button
                    plain
                    onClick={() => setShowDeletedTicketTypes(!showDeletedTicketTypes)}
                  >
                    {!showDeletedTicketTypes ? 'Show deleted ticket types' : 'Hide deleted ticket types'}
                  </Button>
                  <Collapsible open={showDeletedTicketTypes} id="showDeletedTicketTypesCollapsible">
                    {deletedTicketTypesMarkup}
                  </Collapsible>
                </Stack>
              ) : ('')}
            </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default CheckinTicketTypesCard;
