import React from 'react';
import { Mutation } from 'react-apollo';
import { Layout, Link as ShopifyLink, DataTable, Card, Badge, Button } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import { REVOKE_VIRTUAL_EVENT_SESSION } from '../../utils/graphql';
import { getHostFromShopDomain } from '../../utils/auth';

const EVENT_FIELDS = `
  virtualEventActiveSessions {
    id
    attendeeId
  }
`;

const VirtualEventSessions = ({ match, flashCallback, history }) => {
  const [revokingSessionId, setRevokingSessionId] = React.useState(null);

  return (
    <Mutation mutation={REVOKE_VIRTUAL_EVENT_SESSION}
      onError={() => {
        flashCallback(true, 'Unable to revoke session', true);
        setRevokingSessionId(null);
      }}
      onCompleted={() => {
        flashCallback(true, 'Session access revoked');
        setRevokingSessionId(null);
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(revokeVirtualEventSession) => (
        <EventQuery
          eventId={match.params.eventId}
          extraEventAttributes={EVENT_FIELDS}
          flashCallback={flashCallback}
          history={history}
        >
          {({ shop, event }) => {
            const sessions = event.virtualEventActiveSessions;
            const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

            const rows = sessions.map((session) => ([
              session.id,
              <ShopifyLink url={`/events/${event.id}/attendees/${session.attendeeId}${searchParams}`}>{session.attendeeId}</ShopifyLink>,
              <Badge status='info'>Active</Badge>,
              <Button
                loading={revokingSessionId == session.id}
                disabled={revokingSessionId}
                onClick={() => {
                  setRevokingSessionId(session.id);
                  revokeVirtualEventSession({
                    variables: {
                      eventId: event.id,
                      id: session.id,
                    },
                  });
                }}
              >
                Revoke
              </Button>,
            ]));
            return (
              <EventPage
                fullWidth={true}
                shop={shop}
                event={event}
                title="Virtual Event Active Sessions"
                flashCallback={flashCallback}
                history={history}
                primaryAction={null}
                pageTitle="Virtual Event Active Sessions"
              >
                <Card sectioned>
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'ID',
                      'Attendee',
                      'Status',
                      '',
                    ]}
                    rows={rows}
                  />
                </Card>
              </EventPage>
            );
          }}
        </EventQuery>
      )}
    </Mutation>
  );
};

export default VirtualEventSessions;
