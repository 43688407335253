import React from 'react';
import { Card, ResourceList, EmptyState } from '@shopify/polaris';
import OrganizerResourceItem from './OrganizerResourceItem';
import OrganizerModal from './OrganizerModal';

const OrganizersCard = ({ shop, organizers }) => {
  const [showAddOrganizerModal, setShowAddOrganizerModal] = React.useState(false);
  const [showOrganizerModal, setShowOrganizerModal] = React.useState(null);

  return (
    <Card
      sectioned
      actions={[
        {
          content: 'Add organizer',
          onAction: () => setShowAddOrganizerModal(true),
        },
      ]}
    >
      <ResourceList
        emptyState={!organizers.length ? (
          <EmptyState
            heading="No organizers yet"
          >
            <p>
              All organizers will appear here.
            </p>
          </EmptyState>
        ) : undefined}
        items={organizers}
        renderItem={(organizer) => (
          <OrganizerResourceItem
            organizer={organizer}
            onSelect={(loc) => setShowOrganizerModal(loc)}
          />
        )}
        resourceName={{singular: 'organizer', plural: 'organizers'}}
      />
      {showAddOrganizerModal &&
        <OrganizerModal
          shop={shop}
          organizer={null}
          onClose={() => setShowAddOrganizerModal(false)}
        />
      }
      {showOrganizerModal &&
        <OrganizerModal
          shop={shop}
          organizer={showOrganizerModal}
          onClose={() => setShowOrganizerModal(null)}
        />
      }
    </Card>
  );
};

export default OrganizersCard;
