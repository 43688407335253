import React from 'react';
import { Card, Link, TextContainer, Button, Stack } from '@shopify/polaris';
import { Query } from 'react-apollo';
import CardHeader from '../CardHeader';
import LoadingCard from './LoadingCard';
import styled from '@emotion/styled';
import EmptyStateCard from './EmptyStateCard';
import { GET_VIRTUAL_EVENT_STATS } from '../../../utils/graphql';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../../utils/routing';
import { getHostFromShopDomain } from '../../../utils/auth';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  padding:12px;
  height:290px;
`;

const StatValue = styled.div`
  color:#008060;
  font-size:144px;
  line-height:150px;
  text-align:center;
`;

const StatLabel = styled.div`
  color:#202223;
  font-size:14px;
  text-align:center;
  font-weight:600;
`;

const Section = styled.div`
  padding:20px;
`;

const FooterContainer = styled.div`
  margin: 20px 27px 0 27px;
`;

const VirtualEventCard = ({ shop, event }) => {
  const history = useHistory();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <Query
      query={GET_VIRTUAL_EVENT_STATS}
      variables={{ 'eventId': event.id }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingCard title='Virtual event page' />;
        }

        const virtualEventPage = data?.currentShop?.event?.virtualEventPage;
        const url = data?.currentShop?.event?.virtualEventPageUrlBase;

        // if (!virtualEventPage.serviceUrl) {
          return (
            <EmptyStateCard
              title='Virtual event page'
              imageUrl={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/play.png`}
              message='Configure your virtual event page to get started'
              action={(
                <Button
                  primary
                  onClick={() => goToPage({ history, path: `/events/${event.id}/edit?section=checkin` })}
                >
                  Set up virtual event page
                </Button>
              )}
              learnMore='https://evey-events.zendesk.com/hc/en-us/'
            />
          );
        // }

        return (
          <Card>
            <CardHeader
              title='Virtual event page'
              action={<Link external url={url + searchParams}>View virtual event page</Link>}
            />
            <Container>
              <Section>
                <StatValue>{virtualEventPage.activeSessionCount}</StatValue>
                <StatLabel>Live session{virtualEventPage.activeSessionCount == 1 ? '' : 's'}</StatLabel>
              </Section>
            </Container>
            <FooterContainer>
              <Stack distribution='trailing' alignment='center'>
                <Button
                  primary
                  onClick={() => goToPage({ history, path: `/events/${event.id}/edit?section=virtual` })}
                >
                  Manage virtual event page
                </Button>
              </Stack>
            </FooterContainer>
          </Card>
        );
      }}
    </Query>
  );
};

export default VirtualEventCard;
