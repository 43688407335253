import React from 'react';
import { TextField, Checkbox, Stack } from '@shopify/polaris';
import { ChromePicker } from 'react-color';
import styled from '@emotion/styled';

const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 110;
`;

const InputContainer = styled.div`
  display:inline-block;
  width: 100%;
`;

const ColorCover = styled.div`
  position: fixed;
  z-index: 105;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ColorSwatch = styled.div`
  display:inline-block;

  padding:5px;
  background:#fff;
  border-radius:1px;
  box-shadow:0 0 0 1px rgba(0,0,0,.1);
  display:inline-block;
  cursor:pointer;
`;

const ColorSwatchColor = styled.div`
  width:36px;
  height:14px;
  border-radius:2px;
`;

const ColorPicker = ({ label, value, onChange, helpText, allowInherit, defaultValue, initialValue }) => {
  const [open, setOpen] = React.useState(false);

  let color = value || defaultValue;
  if (color && !color.startsWith('#') && color !== 'inherit') {
    color = `#${color}`;
  }

  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label id="TextField1Label" className="Polaris-Label__Text">{label}</label>
        </div>
      </div>
      <Stack vertical={false} alignment='center'>
        <Stack.Item fill>
          <InputContainer onClick={() => setOpen(!open)}>
            <TextField
              value={color === 'inherit' ? '' : color}
              onChange={onChange}
              prefix='#'
              disabled={color === 'inherit'}
            />
          </InputContainer>
        </Stack.Item>
        <ColorSwatch onClick={() => setOpen(!open)} >
          <ColorSwatchColor style={{background: color}}></ColorSwatchColor>
        </ColorSwatch>
        {open &&
          <ColorPickerContainer>
            <ChromePicker
              color={color}
              onChange={(v) => onChange(v.hex)}
              disableAlpha
            />
          </ColorPickerContainer>
        }
        {helpText && helpText.length &&
          <div className="Polaris-Labelled__HelpText" id="TextField1HelpText">
            {helpText}
          </div>
        }
        {open &&
          <ColorCover onClick={() => setOpen(false)} ></ColorCover>
        }
        {allowInherit &&
          <Checkbox
            checked={color === 'inherit'}
            label='Inherit'
            onChange={(v) => onChange(v ? 'inherit' : initialValue)}
          />
        }
      </Stack>
    </div>
  );
};

export default ColorPicker;
