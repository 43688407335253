import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Layout, Card, Banner, Button, Link as ShopifyLink, Image, DropZone, Collapsible, Checkbox, FormLayout, TextField } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import AceEditor from 'react-ace';
import 'brace/mode/liquid';
import 'brace/theme/tomorrow';
import {
  DeleteMajor,
} from '@shopify/polaris-icons';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import SharedTemplateModal from '../shared/SharedTemplateModal';

export default class PdfTicketEdit extends EveyComponent {
  constructor(props) {
    super(props);
  }

  pdfTicket = null;

  state = {
    logo: null,
    logoDelete: false,
    notes: null,
    pageWidth: null,
    pageHeight: null,

    customPageSizeEnabled: null,

    showSharedTemplateModal: false,

    errors: {},
    unknownError: false
  }

  EVENT_FIELDS = `
    pdfTicketSharedCount
    pdfTicket {
      id
      logoUrl
      logoFilename
      logoSize
      notes
      body
      pageWidth
      pageHeight
      sharedEventsCount
    }
  `;

  EDIT_PDF_TICKET = gql`
    mutation editPdfTicket($eventId: ID!, $logo: Upload, $deleteLogo: Boolean, $notes: String, $body: String, $pageWidth: String, $pageHeight: String) {
      editPdfTicket(eventId: $eventId, logo: $logo, deleteLogo: $deleteLogo, notes: $notes, body: $body, pageWidth: $pageWidth, pageHeight: $pageHeight) {
        success
      }
    }
  `;

  pdfTicketState = (pdfTicket, field) => {
    if (this.state[field] == null) {
      if (field == 'logo') {
        return this.state.logo;
      } else {
        return pdfTicket[field];
      }
    } else {
      return this.state[field];
    }
  }

  customPageSizeEnabledState = (pdfTicket) => {
    if (this.state.customPageSizeEnabled == null) {
      if (pdfTicket.pageWidth && pdfTicket.pageWidth.length > 0 && pdfTicket.pageHeight && pdfTicket.pageHeight.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.state.customPageSizeEnabled;
    }
  }

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  renderUploadImage = (shop, event, pdfTicket, field) => {
    return (
      <DropZone
        label={''}
        allowMultiple={false}
        accept='image/*'
        type='image'
        onDropAccepted={(acceptedFiles) => {
          const img = document.createElement('img');
          img.src = window.URL.createObjectURL(acceptedFiles[0]);
          let poll = setInterval(() => {
            if (img.naturalWidth) {
              clearInterval(poll);
              if (parseInt(img.naturalWidth) > 900 || parseInt(img.naturalHeight) > 900) {
                this.props.flashCallback(true, 'Logo cannot be larger than 900 x 900', true);
              } else {
                this.setState({ [field]: acceptedFiles[0], [`${field}Delete`]: false });
              }
            }
          }, 10);
        }}
      >
        <DropZone.FileUpload
          actionTitle='Select file'
          actionHint='or drop file to upload'
        />
      </DropZone>
    );
  }

  renderImage = (field, url, filename, bytes) => {
    return (
      <div>
        <p>
          <span style={{marginRight:'10px'}}>
            Selected file: <ShopifyLink external url={url}>{filename}</ShopifyLink> ({this.humanFileSize(bytes, true)})
          </span>
          <span style={{position:'relative', top:'4px'}}>
            <Button
              size={'slim'}
              outline={true}
              destructive={true}
              icon={DeleteMajor}
              onClick={() => {
                this.setState({
                  [`${field}Delete`]: true,
                  [field]: null
                });
              }}
            >
              Delete
            </Button>
          </span>

        </p>
        <Image
          key={`${field}Image`}
          source={url}
          alt={`PDF Ticket ${field} image`}
          style={{marginLeft:'auto', marginRight:'auto', display:'block', maxWidth:'60%'}}
          width='100px'
        />
      </div>
    );
  }

  renderLogo = (shop, event, pdfTicket) => {
    if (this.state.logo) {
      const url = window.URL.createObjectURL(this.state.logo);
      const filename = this.state.logo.name;
      const bytes = this.state.logo.size;

      return this.renderImage('logo', url, filename, bytes);
    } else {
      const url = pdfTicket.logoUrl;
      const filename = pdfTicket.logoFilename;
      const sizeBytes = pdfTicket.logoSize;
      const isDeleted = this.state.logoDelete;

      if (url && url.length > 0 && !isDeleted) {
        return this.renderImage('logo', url, filename, sizeBytes);
      } else {
        return this.renderUploadImage(shop, event, pdfTicket, 'logo');
      }
    }
  }

  renderCustomPageSize = (pdfTicket) => {
    return (
      <div>
        <Checkbox
          checked={this.customPageSizeEnabledState(pdfTicket)}
          label='Set custom page size (default A4 paper)'
          onChange={this.handleChange('customPageSizeEnabled')}
          helpText="Enable this to set a custom page width and height in inches for the PDF ticket. The default value is A4 paper size, which has a width of 8.27 inches and height of 11.7 inches. Changing this could result in unknown printed ticket formats since each attendee may have different PDF and printer software that formats the ticket on the page differently."
        />
        <Collapsible open={this.customPageSizeEnabledState(pdfTicket)} id="pdf-custom-page-size-collapsible">
          <div style={{marginBottom:'1.6em'}}>
            <FormLayout.Group condensed>
              <TextField value={this.pdfTicketState(pdfTicket, 'pageWidth') || ''} type="number" min='0' step='0.1' label='Width (inches)' onChange={this.handleChange('pageWidth')} placeholder="8.27" />
              <TextField value={this.pdfTicketState(pdfTicket, 'pageHeight') || ''} type="number" min='0' step='0.1' label={'Height (inches)'} onChange={this.handleChange('pageHeight')} placeholder="11.7" />
            </FormLayout.Group>
          </div>
        </Collapsible>
      </div>
    );
  }

  renderPageContent = (shop, event) => {
    if (this.pdfTicket == null) {
      this.pdfTicket = event.pdfTicket;
    }

    return (
      <Mutation mutation={this.EDIT_PDF_TICKET}
        onError={(error) => {
          if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
            this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false });
          } else {
            this.setState({ unknownError: true });
            this.reportError(error);
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        onCompleted={(data) => {
          if (data && data.editPdfTicket) {
            this.props.flashCallback(true, 'PDF Ticket successfully updated');
            this.setState({
              errors: [],
              unknownError: false
            });
          }
        }}
        refetchQueries={() => ['EventQuery']}
      >
        {(editPdfTicket, { loading }) => {
          return (
            <div>
              {this.state.unknownError &&
              <Banner
                title="There was a problem updating the PDF ticket configuration"
                status="critical"
              >
                <p>
                  Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                </p>
              </Banner>
              }
              {this.state.errors && this.state.errors.length > 0 &&
              <Banner
                title="There was a problem updating the PDF ticket configuration"
                status="critical"
              >
                {this.errorMessage()}
              </Banner>
              }
              <form id="pdfTicketForm" onSubmit={e => {
                e.preventDefault();

                let pageWidth = this.pdfTicketState(this.pdfTicket, 'pageWidth');
                let pageHeight = this.pdfTicketState(this.pdfTicket, 'pageHeight');

                if (!this.customPageSizeEnabledState(this.pdfTicket) || !pageWidth || pageWidth.length == 0 || !pageHeight || pageHeight.length == 0) {
                  pageWidth = null;
                  pageHeight = null;
                }

                editPdfTicket({
                  variables:{
                    eventId: event.id,
                    logo: this.state.logo,
                    deleteLogo: this.state.logoDelete,
                    notes: this.pdfTicketState(this.pdfTicket, 'notes'),
                    body: this.pdfTicket.body,
                    pageWidth: pageWidth,
                    pageHeight: pageHeight
                  }
                });
              }}>
                <Layout.AnnotatedSection
                  title="PDF Ticket Customization"
                  description=<div>
                    <p>
                      You can customize the PDF ticket appearance for this event by setting a custom logo
                      image to include at the top left corner of the PDF and/or some
                      notes / instructions text. This image will be scaled to fit the correct area
                      (aspect ratio will be respected). It is recommended to keep the image size small
                      or attendees will get a very large attachment in their email.
                      The ideal logo width is about 100-200 pixels.
                    </p>
                    <p>
                      We do not reduce the file size of your images as we scale them to fit the area they
                      belong to. If you provide files that are very large, your customers will have to
                      download very large PDF ticket files and some email clients may not accept them as
                      attachments.
                    </p>
                    <p>
                      Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink> about customizing
                      this ticket.
                    </p>
                  </div>
                >
                  <Card sectioned
                    primaryFooterAction={{
                      content: 'Save',
                      loading: loading,
                      submit: true
                    }}
                    secondaryFooterActions={[
                      {
                        content: 'Edit Code',
                        disabled: loading,
                        onAction: () => {
                          this.props.history.push(`/events/${event.id}/pdf_ticket/code`);
                          window.scrollTo({ top: 0 });
                        }
                      },
                      {
                        content: 'View Sample Ticket',
                        disabled: loading,
                        external: true,
                        target: 'REMOTE',
                        url: `/events/${event.id}/pdf/sample?shop=${shop.domain}&shop_id=${shop.remoteId}`,
                      },
                      {
                        content: 'Use shared template',
                        disable: loading,
                        onAction: () => this.setState({ showSharedTemplateModal: true }),
                      }
                    ]}
                  >
                    {(!event.hasEtickets || !event.hasPdfTicketType) &&
                      <Banner
                        title="Ticket format is not enabled"
                        status="warning"
                      >
                        <p>
                          You do not have the PDF ticket type enabled for this event,
                          updates to this page will not change anything for your
                          attendees. To enable the PDF ticket type, go to the event
                          settings page.
                        </p>
                      </Banner>
                    }
                    {event.pdfTicketSharedCount > 0 &&
                      <Banner
                        title="Shared template"
                        status="info"
                      >
                        This template is shared by {event.pdfTicketSharedCount + 1} event{(event.pdfTicketSharedCount + 1) == 1 ? '' : 's'}. Any updates here will apply to those events as well.
                      </Banner>
                    }
                    <Card.Section title="Logo Image">
                      {this.renderLogo(shop, event, this.pdfTicket)}
                    </Card.Section>
                    <Card.Section title="Notes / Instructions (supports HTML formatting)">
                      <div className="TicketEmailEdit__AceEditor">
                        <AceEditor
                          mode="html"
                          theme="tomorrow"
                          onChange={this.handleChange('notes')}
                          value={this.pdfTicketState(this.pdfTicket, 'notes') || ''}
                          name="pdfNotes"
                          fontSize='1.4rem'
                          width='100%'
                          height='300px'
                          showPrintMargin={false}
                          showGutter={false}
                          highlightActiveLine={false}
                          setOptions={{
                            showLineNumbers: false,
                            tabSize: 2,
                            fontSize: 'inherit',
                          }}
                          editorProps={{$blockScrolling: true}}
                        />
                      </div>
                    </Card.Section>
                    <Card.Section title="Custom Page Size">
                      {this.renderCustomPageSize(this.pdfTicket)}
                    </Card.Section>
                  </Card>
                </Layout.AnnotatedSection>
              </form>
              {this.state.showSharedTemplateModal &&
                <SharedTemplateModal
                  shop={shop}
                  event={event}
                  template={event.pdfTicket}
                  templateType='pdf_ticket'
                  onClose={() => this.setState({ showSharedTemplateModal: false })}
                />
              }
            </div>
          );
        }}
      </Mutation>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
        loadingPage={<AnnotatedSectionLoading />}
      >
        {({ shop, event }) => {
          return this.renderPageContent(shop, event);
        }}
      </EventQuery>
    );
  }
}

PdfTicketEdit.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
};
