import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Layout, Link as ShopifyLink, TextContainer, Stack, Badge, Banner, Card, Tabs } from '@shopify/polaris';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import { GET_STOREFRONT_INTEGRATION, EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import AttendeeInfoCard from '../StorefrontIntegration/AttendeeInfoCard';
import { useFrame } from '../../contexts/frame';
import SchedulerCard from '../StorefrontIntegration/SchedulerCard';
import EventsCalendarCard from '../StorefrontIntegration/EventsCalendarCard';
import EventsCollectionCard from '../StorefrontIntegration/EventsCollectionCard';
import StorefrontScriptCard from '../StorefrontIntegration/StorefrontScriptCard';
import { useHistory } from "react-router-dom";
import SeatingCard from '../StorefrontIntegration/SeatingCard';
import TicketFeesCard from '../StorefrontIntegration/TicketFeesCard';
import { trackEvent } from '../../utils/track';

const StorefrontIntegrationTabs = ({ shop }) => {
  const TABS = [
    {
      id: 'ticket-info',
      content: 'Collect Ticket Info',
      accessiblityLabel: 'Collect ticket information',
      panelID: 'ticketInfo-content'
    },
    {
      id: 'scheduler',
      content: 'Recurring Events Scheduler',
      accessiblityLabel: 'Recurring Events Scheduler',
      panelID: 'recurringEvents-content'
    },
    {
      id: 'calendar',
      content: 'Events Calendar Page',
      accessiblityLabel: 'Events Calendar Page',
      panelID: 'calendar-content'
    },
    {
      id: 'collection',
      content: 'Events Collection',
      accessiblityLabel: 'Events Collection',
      panelID: 'collection-content'
    },
    ...(shop.hasSeating ? [{
      id: 'seating',
      content: 'Seating',
      accessiblityLabel: 'Seating',
      panelID: 'seating-content'
    }] : []),
    {
      id: 'ticket-fees',
      content: 'Service Fees',
      accessiblityLabel: 'Service fees',
      panelID: 'service-fees-content',
    },
  ];

  const [selectedTabId, setSelectedTabId] = React.useState((new URLSearchParams(window.location.search)).get('section') || 'ticket-info');

  const { showToastNotice, showToastError } = useFrame();
  const history = useHistory();

  return (
    <Layout>
      <Layout.Section>
        <Tabs
          tabs={TABS}
          selected={TABS.findIndex((tab) => tab.id == selectedTabId)}
          onSelect={(v) => {
            setSelectedTabId(TABS[v].id)
            history.push(`/settings/storefront?section=${TABS[v].id}`);
            trackEvent(`Navigate to /settings/storefront`, { section: TABS[v].id });
          }}
          fitted
        >
          {selectedTabId === 'ticket-info' &&
            <AttendeeInfoCard
              shop={shop}
            />
          }
          {selectedTabId === 'scheduler' &&
            <SchedulerCard
              shop={shop}
            />
          }
          {selectedTabId === 'calendar' &&
            <EventsCalendarCard
              shop={shop}
            />
          }
          {selectedTabId === 'collection' &&
            <EventsCollectionCard
              shop={shop}
            />
          }
          {selectedTabId === 'seating' &&
            <SeatingCard
              shop={shop}
            />
          }
          {selectedTabId === 'ticket-fees' &&
            <TicketFeesCard
              shop={shop}
            />
          }
        </Tabs>
      </Layout.Section>
    </Layout>
  );
};

const StorefrontIntegrations = () => {

  return (
    <Query
      query={GET_STOREFRONT_INTEGRATION}
    >
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;
        if (loading) return <InitLoading />;

        const shop = data.currentShop;

        return (
          <StorefrontIntegrationTabs shop={shop} />
        );
      }}
    </Query>
  );
};

export default StorefrontIntegrations;
