import React from 'react';
import { Card, ResourceList, EmptyState } from '@shopify/polaris';
import VendorAccountResourceItem from './VendorAccountResourceItem';
import VendorAccountModal from './VendorAccountModal';

const VendorAccountsCard = ({ shop, accounts }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(null);

  return (
    <Card
      sectioned
      actions={[
        {
          content: 'Add account',
          onAction: () => setShowAddModal(true),
        },
      ]}
    >
      <ResourceList
        emptyState={!accounts.length ? (
          <EmptyState
            heading="No vendor accounts yet"
          >
            <p>
              All accounts will appear here.
            </p>
          </EmptyState>
        ) : undefined}
        items={accounts}
        renderItem={(account) => (
          <VendorAccountResourceItem
            account={account}
            onSelect={(a) => setShowModal(a)}
          />
        )}
        resourceName={{singular: 'account', plural: 'accounts'}}
      />
      {showAddModal &&
        <VendorAccountModal
          shop={shop}
          account={null}
          onClose={() => setShowAddModal(false)}
        />
      }
      {showModal &&
        <VendorAccountModal
          shop={shop}
          account={showModal}
          onClose={() => setShowModal(null)}
        />
      }
    </Card>
  );
};

export default VendorAccountsCard;
