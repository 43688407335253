import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Modal, Banner, FormLayout, TextField,
  ChoiceList, Link as ShopifyLink, TextContainer } from '@shopify/polaris';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import { formatErrors, reportError } from '../../utils/errors';
import { useFrame } from '../../contexts/frame';
import { parseBoolean } from '../../utils/parse';

const EDIT_MULTI_TICKET_EMAIL = gql`
  mutation editMultiTicketEmail($templateId: ID! $multiTicketSubject: String, $multiTicketBody: String, $multiTicketSendAsHtml: Boolean) {
    editMultiTicketEmail(templateId: $templateId, multiTicketSubject: $multiTicketSubject, multiTicketBody: $multiTicketBody, multiTicketSendAsHtml: $multiTicketSendAsHtml) {
      success
    }
  }
`;

const MultiTicketEmailModal = ({ email, onClose }) => {
  const [multiTicketSubject, setMultiTicketSubject] = React.useState(email.multiTicketSubject || email.defaultMultiTicketSubject);
  const [multiTicketBody, setMultiTicketBody] = React.useState(email.multiTicketBody || email.defaultMultiTicketBody);
  const [multiTicketSendAsHtml, setMultiTicketSendAsHtml] = React.useState([email.multiTicketSendAsHtml]);
  const [resetDefaultConfirm, setResetDefaultConfirm] = React.useState(false);
  const [customizeSelected, setCustomizeSelected] = React.useState([email.multiTicketSubject || email.multiTicketBody ? true : false]);
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();;

  return (
    <Mutation mutation={EDIT_MULTI_TICKET_EMAIL}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setUnknownError(true);
          setErrors([]);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editMultiTicketEmail) {
          showToastNotice('Multi-ticket email updated');
          setErrors([]);
          setUnknownError(false);
          onClose();
        }
      }}
      refetchQueries={() => ['EventQuery', 'GetDefaultNotificationTemplate', 'GetTemplatePreview']}
    >
      {(editMultiTicketEmail, { loading: saving }) => {
        return (
          <Modal
            open={true}
            onClose={onClose}
            large={true}
            title='Milti / Group ticket email template'
            primaryAction={{
              content: 'Save',
              loading: saving,
              onAction: () => {
                editMultiTicketEmail({
                  variables:{
                    templateId: email.id,
                    multiTicketSubject: customizeSelected[0] ? multiTicketSubject : null,
                    multiTicketBody: customizeSelected[0] ? multiTicketBody : null,
                    multiTicketSendAsHtml: customizeSelected[0] ? parseBoolean(multiTicketSendAsHtml) : true,
                  }
                });
              }
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                disabled: saving,
                onAction: onClose,
              }
            ]}
          >
            <Modal.Section>
              {unknownError &&
              <Banner
                title="There was a problem updating the email template"
                status="critical"
              >
                <p>
                  Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                </p>
              </Banner>
              }
              {errors && errors.length > 0 &&
              <Banner
                title="There was a problem updating the email template"
                status="critical"
              >
                {formatErrors(errors)}
              </Banner>
              }
              <FormLayout>
                <TextContainer>If you enable this option, the template is used for the case when a customer purchases multiple tickets for your event in a single order or if they purchase a group ticket. This email should list all the customers tickets, giving them links to download their ticket files (if applicable) directly or through their individual online ticket page.</TextContainer>
                <ChoiceList
                  title=''
                  choices={[
                    { label: 'Use default email template', value: false },
                    { label: 'Customize email template', value: true }
                  ]}
                  selected={customizeSelected}
                  onChange={(v) => {
                    setCustomizeSelected(v);
                    if (!v[0]) {
                      setMultiTicketBody(email.defaultMultiTicketBody);
                      setMultiTicketSubject(email.defaultMultiTicketSubject);
                    }
                  }}
                />
                <TextField key="multiTicketSubject" id='multiTicketSubject' value={multiTicketSubject || ''} label="Subject" placeholder="" onChange={setMultiTicketSubject} disabled={!(customizeSelected[0])} />
                <div>
                  <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                      <label id="bodyLabel" className="Polaris-Label__Text">Body</label>
                    </div>
                  </div>
                  <div className="TicketEmailEdit__AceEditor" style={{position: 'relative'}}>
                    {!customizeSelected[0] &&
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#5c5c5c',
                        opacity: '0.05',
                        zIndex: '1000'
                      }}></div>
                    }
                    <AceEditor
                      mode="liquid"
                      theme="tomorrow"
                      onChange={setMultiTicketBody}
                      value={multiTicketBody || ''}
                      name="multiTicketEmailBody"
                      fontSize='1.4rem'
                      width='100%'
                      height='300px'
                      showPrintMargin={false}
                      showGutter={false}
                      highlightActiveLine={true}
                      setOptions={{
                        showLineNumbers: false,
                        tabSize: 2,
                        fontSize: 'inherit',
                      }}
                      editorProps={{$blockScrolling: true}}
                      readOnly={!(customizeSelected[0])}
                      focus={customizeSelected[0]}
                    />
                  </div>
                </div>
                <ChoiceList
                  title=''
                  disabled={!(customizeSelected[0])}
                  choices={[
                    { label: 'Send as HTML (default, select this if you\'re not sure)', value: true },
                    { label: 'Send as plain text', value: false },
                  ]}
                  selected={customizeSelected[0] ? multiTicketSendAsHtml : [true]}
                  onChange={setMultiTicketSendAsHtml}
                />
              </FormLayout>
            </Modal.Section>
          </Modal>
        );
      }}
    </Mutation>
  );
};

export default MultiTicketEmailModal;
