import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Link as ShopifyLink, ChoiceList, Checkbox, TextStyle, Collapsible, Stack, Popover, Button, OptionList, InlineError } from '@shopify/polaris';
import ColorPicker from '../shared/ColorPicker.jsx';
import { EDIT_VIRTUAL_EVENT_PAGE } from '../../utils/graphql';
import { getTimeString, getDateString, buildDateWithCompensatedTimezone } from '../../utils/time';
import { getShopNameFromDomain } from '../../utils/auth';
import { NestedFormLayoutContainer } from '../shared/containers';
import { VIRTUAL_EVENT_SERVICES } from '../../utils/constants';

const VirtualEventPageConfigCard = ({ shop, event, flashCallback }) => {
  const shopName = getShopNameFromDomain(shop?.domain);
  const [config, setConfig] = React.useState(JSON.parse(event.virtualEventPage.config || {}));
  const [requirePassword, setRequirePassword] = React.useState([event.virtualEventPage.requirePassword || false]);
  const [restrictSessions, setRestrictSessions] = React.useState(event.virtualEventPage.restrictSessions);
  const [showPasswordInNotification, setShowPasswordInNotification] = React.useState(event.virtualEventPage.showPasswordInNotification);
  const [embedVideo, setEmbedVideo] = React.useState(event.virtualEventPage.embedVideo);
  const [openDateEnabled, setOpenDateEnabled] = React.useState(event.virtualEventPage.openDate);
  const [openDate, setOpenDate] = React.useState(event.virtualEventPage.openDate && getDateString(buildDateWithCompensatedTimezone(event.virtualEventPage.openDate)));
  const [openTime, setOpenTime] = React.useState(event.virtualEventPage.openDate && getTimeString(buildDateWithCompensatedTimezone(event.virtualEventPage.openDate)));
  const [closeDateEnabled, setCloseDateEnabled] = React.useState(event.virtualEventPage.closeDate);
  const [closeDate, setCloseDate] = React.useState(event.virtualEventPage.closeDate && getDateString(buildDateWithCompensatedTimezone(event.virtualEventPage.closeDate)));
  const [closeTime, setCloseTime] = React.useState(event.virtualEventPage.closeDate && getTimeString(buildDateWithCompensatedTimezone(event.virtualEventPage.closeDate)));

  const service = event.virtualEventPage.service || 'livestream';

  const onConfigChange = (field) => (newValue) => {
    let newConfig = Object.assign({}, config);
    newConfig[field] = newValue;
    setConfig(newConfig);
  };

  const virtualEventPage = event.virtualEventPage;
  const pageUrl = `${event.virtualEventPageUrlBase}`;

  return (
    <Mutation mutation={EDIT_VIRTUAL_EVENT_PAGE}
      onError={() => {
        flashCallback(true, 'Unable to update virtual event page', true);
      }}
      onCompleted={() => {
        flashCallback(true, 'Virtual event page updated');
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editVirtualEventPage, { loading }) => (
        <Card
          primaryFooterAction={{
            content: 'Save',
            loading: loading,
            onAction: () => {
              let openDateResult = null;
              let closeDateResult = null;
              if (openDateEnabled && openDate && openTime) {
                openDateResult = `${openDate}T${openTime}`;
              }
              if (closeDateEnabled && closeDate && closeTime) {
                closeDateResult = `${closeDate}T${closeTime}`;
              }

              editVirtualEventPage({
                variables: {
                  eventId: event.id,
                  body: virtualEventPage.body,
                  config: JSON.stringify(config),
                  embedVideo,
                  requirePassword: requirePassword[0],
                  restrictSessions: restrictSessions,
                  showPasswordInNotification: showPasswordInNotification,
                  openDate: openDateResult,
                  closeDate: closeDateResult,
                },
              });
            },
          }}
        >
          <Card.Section
            title="Settings"
            actions={[
              {
                content: 'Open page',
                url: pageUrl,
                external: true,
              }
            ]}
          >
            <FormLayout>
              {VIRTUAL_EVENT_SERVICES[service].canEmbed &&
                <Checkbox
                  checked={embedVideo}
                  label="Embed video on page"
                  onChange={(v) => setEmbedVideo(v)}
                />
              }
            </FormLayout>
          </Card.Section>
          <Card.Section title="Access controls">
            <FormLayout>
              <ChoiceList
                title="Require an access code or password to access virtual event"
                choices={[
                  { label: 'Yes, ask the attendee for an access code / password before granting access to the virtual event', value: true },
                  { label: 'No, anyone can access it by going to this unique page URL', value: false }
                ]}
                selected={requirePassword}
                onChange={(v) => setRequirePassword(v)}
              />
              <Collapsible open={requirePassword[0]}>
                <NestedFormLayoutContainer>
                  <FormLayout>
                    <Checkbox
                      checked={showPasswordInNotification}
                      label="Provide the access code / password to the attendee in their ticket notification email"
                      onChange={(v) => setShowPasswordInNotification(v)}
                    />
                    <Checkbox
                      checked={restrictSessions}
                      label="Limit the number of active sessions for each attendee to one session at a time"
                      onChange={(v) => setRestrictSessions(v)}
                    />
                  </FormLayout>
                </NestedFormLayoutContainer>
              </Collapsible>
              <Checkbox
                checked={openDateEnabled}
                label="Grant access to the live video content only after a certain date/time"
                onChange={(v) => setOpenDateEnabled(v)}
              />
              <Collapsible open={openDateEnabled}>
                <Stack vertical={true} spacing='loose'>
                  <FormLayout.Group condensed>
                    <TextField value={openDate} type="date" label="Open date" onChange={(v) => setOpenDate(v)} placeholder="YYYY-MM-DD" />
                    <TextField value={openTime} type="time" label='Open time' onChange={(v) => setOpenTime(v)} placeholder="HH:MM (24-hour)" />
                  </FormLayout.Group>
                  <TextStyle variation="subdued">The date/time that attendees can access the livestream or video content on this page. Before this time they will be shown a message stating when it will open.</TextStyle>
                </Stack>
              </Collapsible>
              <Checkbox
                checked={closeDateEnabled}
                label="Grant access to the live video content until a certain date/time"
                onChange={(v) => setCloseDateEnabled(v)}
              />
              <Collapsible open={closeDateEnabled}>
                <Stack vertical={true} spacing='loose'>
                  <FormLayout.Group condensed>
                    <TextField value={closeDate} type="date" label="Close date" onChange={(v) => setCloseDate(v)} placeholder="YYYY-MM-DD" />
                    <TextField value={closeTime} type="time" label='Close time' onChange={(v) => setCloseTime(v)} placeholder="HH:MM (24-hour)" />
                  </FormLayout.Group>
                  <TextStyle variation="subdued">The date/time that attendees will no longer be shown the livestream or video content on this page. After this time they will be shown a message stating that the event is over.</TextStyle>
                </Stack>
              </Collapsible>
            </FormLayout>
          </Card.Section>
          <Card.Section title='Design'>
            <FormLayout>
              <div style={{maxWidth:'300px'}}>
                <ColorPicker
                  label='Top bar background color'
                  value={config.topbar_color}
                  helpText=''
                  onChange={onConfigChange('topbar_color')}
                />
              </div>
              <div style={{maxWidth:'300px'}}>
                <ColorPicker
                  label='Top bar title color'
                  value={config.topbar_title_color || '#CCCCCC'}
                  helpText=''
                  onChange={onConfigChange('topbar_title_color')}
                />
              </div>
              <TextField
                label='Logo image URL'
                value={config.logo_url}
                helpText=<div>This is the URL of the logo image you want to display on top left corner of the page. Upload any custom files through the Shopify <ShopifyLink external url={`https://admin.shopify.com/store/${shopName}/content/files?selectedView=all`} target="_blank">files section</ShopifyLink> and paste the URL here.</div>
                onChange={onConfigChange('logo_url')}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default VirtualEventPageConfigCard;
