import React from 'react';
import styled from '@emotion/styled';
import LoadingBar from 'react-top-loading-bar';

const PreviewContainer = styled.div(({ width, height }) => `
  width:100%;
  min-width:400px;
  position:relative;
  height:100%;
  background-color: var(--p-surface);
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  display:flex;
  justify-content:center;

  iframe {
    width:${width ? width : 'calc(100% - 40px)'};
    /* height:100%; */
    height:calc(100vh - 100px);
    border:none;
    margin:20px;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);

    @media (max-width: 800px) {
      width:calc(100% - 60px);
    }
  }
`);

const TemplateEditorPreview = ({ preview, settings, strings, loading, onClick, fixedWidth, fixedHeight, onDownloadSample }) => {
  const loaderRef = React.useRef(null);

  const startLoading = () => {
    loaderRef.current && loaderRef.current.staticStart();
  };

  const stopLoading = () => {
    loaderRef.current && loaderRef.current.complete();
  };

  React.useEffect(() => {
    startLoading();

    return () => stopLoading();
  }, [loading]);

  const updatePreviewContent = (content) => {
    const iframe = document.getElementById('PreviewFrame');
    const doc = iframe?.contentDocument;
    if (doc) {
      doc.open();
      doc.write(preview);
      doc.close();
    }

    // setTimeout(() => {
    //   const computedStyles = window.getComputedStyle(iframe.contentWindow.document.body);
    //   iframe.style.height = iframe.contentWindow.document.body.scrollHeight + (parseInt(computedStyles.getPropertyValue('margin-top')) || 0) + 'px';
    // }, 100);
  };

  React.useEffect(() => {
    updatePreviewContent(preview);

    const frame = document.getElementById('PreviewFrame');
    frame.onload = () => {
      frame.contentDocument.addEventListener('click', (e) => {
        e = e || window.event;
        const target = e.target || e.srcElement;

        if (onClick) {
          onClick(target);
        }
      });

      frame.contentDocument.addEventListener('evey:download_sample', (e) => {
        if (onDownloadSample) {
          onDownloadSample();
        }
      });
    };
  }, []);

  React.useEffect(() => {
    updatePreviewContent(preview);
  }, [preview]);

  return (
    <PreviewContainer width={fixedWidth} height={fixedHeight}>
      <LoadingBar color='#50B5FF' ref={loaderRef} />
      <iframe id="PreviewFrame" src="about:blank"></iframe>
    </PreviewContainer>
  );
};

export default TemplateEditorPreview;
