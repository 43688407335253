import React from 'react';
import { Banner, Stack } from '@shopify/polaris';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { normalizeToUtc } from '../../utils/time';
import randomstring from 'randomstring';
import ScheduleItemModal from './ScheduleItemModal';
import { buildCalendarEvents } from '../../utils/schedule';

const localizer = momentLocalizer(moment);

const Schedule = ({ event, items }) => {
  const [showItemModal, setShowItemModal] = React.useState(false);
  const [view, setView] = React.useState('month');
  const [showCreateHelpBanner, setShowCreateHelpBanner] = React.useState(true);

  // console.log(`items: ${JSON.stringify(items)}`);

  let calendarEvents = buildCalendarEvents({ event, items });

  const createDraftItem = ({ start, end }) => {
    const newItem = {
      resourceId: randomstring.generate(10),
      draft: true,
      name: '',
      startDate: start.toDate(),
      endDate: null,
      startTime: start.format('HH:mm'),
      duration: end.unix() - start.unix(),
      period: null,
      periodNumber: 1,
    };
    return newItem;
  };

  return (
    <Stack vertical={true}>
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        view={view}
        onView={setView}
        style={{ height: 500 }}
        selectable={true}
        popup={true}
        resources={items}
        eventPropGetter={(event, start, end, isSelected) => {
          // console.log(`item: ${JSON.stringify(item)}`);
          return {
            style: { backgroundColor: '#3C3AB1', opacity: event.resource.draft ? 0.5 : 1 }
          }
        }}
        onSelectSlot={(slot) => {
          // console.log(`Slot select: ${JSON.stringify(slot)}`)
          if (slot.action === 'select' || slot.action === 'doubleClick') {
            let start = moment(slot.start);
            let end = moment(slot.end);
            if (view === 'month') {
              start.set({ hour: moment().get('hour'), minute: 0, second: 0, millisecond: 0 });
              end.set({ hour: moment().get('hour'), minute: 0, second: 0, millisecond: 0 });
              end = end.add(1, 'hour');
            } else {
              if (slot.start === slot.end) {
                end = end.add(1, 'hour');
              }
            }
            const draftItem = createDraftItem({ start, end });
            setShowItemModal({ item: draftItem });
          }
        }}
        onSelectEvent={(event) => {
          // console.log(`event selected: ${JSON.stringify(event)}`);
          const item = items.find((i) => i.resourceId == event.resource.resourceId);
          setShowItemModal({ item: item, event: event });
        }}
      />
      {showCreateHelpBanner &&
        <Banner status='info' onDismiss={() => setShowCreateHelpBanner(false)}>
          Double click any empty space in the calendar to add a new event.
        </Banner>
      }
      {showItemModal &&
        <ScheduleItemModal
          eveyEvent={event}
          onClose={() => setShowItemModal(false)}
          item={showItemModal.item}
          event={showItemModal.event}
        />
      }
    </Stack>
  );
};

export default Schedule;
