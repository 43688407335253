import React from 'react';
import moment from 'moment';
import { Popover, Modal, TextField, FormLayout, Select, Checkbox, Banner, ChoiceList, Stack } from '@shopify/polaris';
import { normalizeToUtc } from '../../utils/time';
import randomstring from 'randomstring';
import FormFieldLabel from '../shared/FormFieldLabel';
import styled from '@emotion/styled';
import ModalFooter from '../shared/ModalFooter.jsx';
import ScheduleItemForm from './ScheduleItemForm';
import ScheduleEventForm from './ScheduleEventForm';

const ScheduleItemModal = ({ eveyEvent, onClose, item, event }) => {
  const [updateItem, setUpdateItem] = React.useState(false);

  const itemStartDateTime = moment(item.startDate).set({ hour: parseInt(item.startTime.split(':')[0]), minute: parseInt(item.startTime.split(':')[1]), second: 0, millisecond: 0 });

  const titleDate = moment(event ? event.start : itemStartDateTime).format(event ? (item.allDay ? 'MMMM D, YYYY [(all day)]' : 'MMMM D, YYYY [at] h:mmA') : 'MMMM D, YYYY');
  const newItem = !item.id;

  const itemForm = <ScheduleItemForm
    eveyEvent={eveyEvent}
    item={item}
    event={event}
    onSave={onClose}
    onCancel={onClose}
  />;

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={titleDate}
    >
      <Modal.Section>
        {event ? (
          <Stack vertical={true} spacing='loose'>
            <ChoiceList
              title=""
              titleHidden
              choices={[
                {label: 'Update just this event', value: false},
                {label: 'Update all events in this series', value: true},
              ]}
              selected={[updateItem]}
              onChange={(v) => setUpdateItem(v[0])}
            />
            {updateItem ? (
              <>{itemForm}</>
            ) : (
              <ScheduleEventForm
                eveyEvent={eveyEvent}
                item={item}
                event={event}
                onSave={onClose}
                onCancel={onClose}
              />
            )}
          </Stack>
        ) : (
          <>{itemForm}</>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default ScheduleItemModal;
