import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Modal } from '@shopify/polaris';

const DELETE_REMINDER = gql`
  mutation deleteAttendeeReminder($eventId: ID!, $reminderId: ID!) {
    deleteAttendeeReminder(eventId: $eventId, reminderId: $reminderId) {
      success
    }
  }
`;

const DeleteReminderConfirmModal = ({ event, reminder, onComplete, onClose, flashCallback }) => {
  return (
    <Mutation
      mutation={DELETE_REMINDER}
      onError={() => {
        flashCallback(true, 'Unable to delete messages', true);
        onClose();
      }}
      onCompleted={() => {
        flashCallback(true, 'Attendee message deleted');
        onComplete();
      }}
    >
      {(deleteAttendeeReminder, { loading }) => (
        <Modal
          title='Confirm attendee message delete'
          open={true}
          onClose={() => onClose() }
          primaryAction={{
            content: 'Yes, delete it',
            destructive: true,
            disabled: loading,
            loading: loading,
            onAction: () => {
              deleteAttendeeReminder({
                variables:{
                  eventId: event.id,
                  reminderId: reminder.id,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: loading,
              onAction: () => onClose(),
            }
          ]}
        >
          <Modal.Section>
            Are you sure you want to delete this message template? It cannot be reversed.
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default DeleteReminderConfirmModal;
