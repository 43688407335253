import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Card, TextField, Select, OptionList, Banner, DataTable, FormLayout, Modal, PageActions, Collapsible, Checkbox } from '@shopify/polaris';
import AppPage from '../AppPage/AppPage.jsx';
import { Link } from 'react-router-dom';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';

export default class CustomerEdit extends EveyComponent {
  constructor(props) {
    super(props);
  }

  shop = null;
  customer = null;

  state = {
    selectedAttendees: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    attendeeAttributes: null,
    editFields: {},
    errors: {},
    unknownError: false
  };

  GET_CUSTOMER = gql`
    query CustomerQuery($customerId: String) {
      currentShop {
        id
        customer(id: $customerId) {
          id
          name
          email
          ticketCount
          orders {
            id
            orderUrl
            orderName
            createdAt
          }
          eventStats {
            attendees {
              id
              number
            }
            event {
              id
              title
              prettyDateRange
              attendeeAttributeSpecs {
                name
                label
                type
                options
                selectMultiple
              }
            }
            orders {
              id
              orderUrl
              orderName
            }
            totalSales
          }
          attendees {
            id
            name
            email
            number
            ticketTitle
            event {
              id
              title
            }
            attendeeAttributes {
              value
              spec {
                name
                label
                type
                options
                selectMultiple
              }
            }
          }
        }
      }
    }
  `;

  EDIT_CUSTOMER = gql`
    mutation editCustomer($customerId: String!, $attendeeIds: [String!]!, $firstName: String, $lastName: String, $email: String, $phone: String, $attendeeAttributes: String, $editFields: String) {
      editCustomer(customerId: $customerId, attendeeIds: $attendeeIds, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, attendeeAttributes: $attendeeAttributes, editFields: $editFields) {
        success
      }
    }
  `;

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  handleAttendeeAttributeChange = (field) => {
    return (value) => {
      let attendeeAttributes = this.state.attendeeAttributes;
      attendeeAttributes[field].value = value;
      this.setState({ attendeeAttributes: attendeeAttributes });
    };
  }

  handleEditFieldsChange = (field) => {
    return (value) => {
      let editFields = this.state.editFields;
      editFields[field] = value;
      this.setState({ editFields: editFields });
    };
  }

  initializeAttendeeAttributeState = (customer) => {
    if (this.state.attendeeAttributes == null) {
      let attributeSpecs = [];
      for (let i in customer.eventStats) {
        let event = customer.eventStats[i].event;
        for (let j in event.attendeeAttributeSpecs) {
          let spec = event.attendeeAttributeSpecs[j];

          let alreadyExists = false;
          for (let k in attributeSpecs) {
            if (attributeSpecs[k].name == spec.name) {
              alreadyExists = true;
              break;
            }
          }

          if (!alreadyExists) {
            attributeSpecs.push(spec);
          }
        }
      }

      let attendeeAttributes = {};
      for (var i in attributeSpecs) {
        let spec = attributeSpecs[i];
        attendeeAttributes[spec.name] = {
          spec: spec,
          value: ''
        };
      }
      this.setState({ attendeeAttributes: attendeeAttributes });

    }
  }

  attendeeAttributesState = (customer) => {
    this.initializeAttendeeAttributeState(customer);
    return this.state.attendeeAttributes;
  }

  buildAttendeeAttributeSpecs = (shop, customer) => {
    let result = [];
    let attendeeAttributes = this.attendeeAttributesState(customer);
    for (var i in attendeeAttributes) {
      var spec = attendeeAttributes[i].spec;
      var value = attendeeAttributes[i].value;
      if (spec.type == 'select') {
        if (spec.selectMultiple) {
          let options = [];
          for (let optIndex in spec.options) {
            let opt = spec.options[optIndex];
            options.push({value: opt, label: opt});
          }
          result.push(
            <div>
              <Checkbox checked={this.state.editFields[spec.name]} label={spec.label} onChange={this.handleEditFieldsChange(spec.name)} />
              <Collapsible open={this.state.editFields[spec.name]} id={`editCustomerFieldCollaps_${spec.name}`} key={`editCustomerFieldCollaps_${spec.name}`}>
                <FormLayout.Group key={spec.name}>
                  <OptionList
                    title={spec.label}
                    onChange={this.handleAttendeeAttributeChange(spec.name)}
                    options={options}
                    selected={value || []}
                    allowMultiple
                  />
                </FormLayout.Group>
              </Collapsible>
            </div>
          );
        } else {
          result.push(
            <div>
              <Checkbox checked={this.state.editFields[spec.name]} label={spec.label} onChange={this.handleEditFieldsChange(spec.name)} />
              <Collapsible open={this.state.editFields[spec.name]} id={`editCustomerFieldCollaps_${spec.name}`} key={`editCustomerFieldCollaps_${spec.name}`}>
                <Select id={spec.name} key={spec.name} value={value} options={spec.options} label={spec.label} onChange={this.handleAttendeeAttributeChange(spec.name)} />
              </Collapsible>
            </div>
          );
        }
      } else {
        result.push(
          <div>
            <Checkbox checked={this.state.editFields[spec.name]} label={spec.label} onChange={this.handleEditFieldsChange(spec.name)} />
            <Collapsible open={this.state.editFields[spec.name]} id={`editCustomerFieldCollaps_${spec.name}`} key={`editCustomerFieldCollaps_${spec.name}`}>
              <TextField id={spec.name} key={spec.name} value={value} label={spec.label} onChange={this.handleAttendeeAttributeChange(spec.name)} />
            </Collapsible>
          </div>
        );
      }
    }
    return result;
  }

  renderCustomAttributeValue = (attr) => {
    if (attr && attr.value) {
      if (attr.spec.type == 'select' && attr.spec.selectMultiple && attr.value.indexOf('[') == 0) {
        return JSON.parse(attr.value).join(', ');
      } else {
        return attr.value;
      }
    } else {
      return '';
    }
  }

  renderTickets = (shop, customer) => {
    let attributeSpecs = [];
    for (let i in customer.eventStats) {
      let event = customer.eventStats[i].event;
      for (let j in event.attendeeAttributeSpecs) {
        let spec = event.attendeeAttributeSpecs[j];

        let alreadyExists = false;
        for (let k in attributeSpecs) {
          if (attributeSpecs[k].name == spec.name) {
            alreadyExists = true;
            break;
          }
        }

        if (!alreadyExists) {
          attributeSpecs.push(spec);
        }
      }
    }

    if (this.state.selectedAttendees == null) {
      let selectedAttendees = [];
      for (let i in customer.attendees) {
        selectedAttendees.push(customer.attendees[i].id);
      }
      this.setState({ selectedAttendees: selectedAttendees });
    }

    const columnContentTypes = [
      'text',
      'text',
      'text',
      'text',
    ];
    const headings = [
      'Name',
      'Email',
      'Event',
      'Ticket Type',
    ];
    for (let i in attributeSpecs) {
      columnContentTypes.push('text');
      headings.push(attributeSpecs[i].label);
    }

    let rows = [];
    for (let i in customer.attendees) {
      let attendee = customer.attendees[i];

      let attendeeLink = <Link to={`/events/${attendee.event.id}/attendees/${attendee.id}/edit`}>{attendee.name}</Link>;
      let eventLink = <Link to={`/events/${attendee.event.id}/attendees`}>{attendee.event.title}</Link>;

      let row = [attendeeLink, attendee.email, eventLink, attendee.ticketTitle];
      for (let i in attributeSpecs) {
        let attendeeAttr = null;
        for (let j in attendee.attendeeAttributes) {
          let attr = attendee.attendeeAttributes[j];
          if (attr.spec.name == attributeSpecs[i].name) {
            attendeeAttr = attr;
          }
        }
        if (attendeeAttr) {
          row.push(this.renderCustomAttributeValue(attendeeAttr));
        } else {
          row.push('');
        }
      }

      rows.push(row);
    }

    return (
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
      />
    );
  }

  renderUpdateTicketsForm = (shop, customer) => {
    const { firstName, lastName, email, phone, selectedAttendees } = this.state;

    return (
      <Mutation mutation={this.EDIT_CUSTOMER}
        onError={(error) => {
          if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
            this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false, confirmUpdateModalOpen: false });
          } else {
            this.setState({ errors: [], unknownError: true, confirmUpdateModalOpen: false });
            this.reportError(error);
          }
          window.scrollTo({ top: 50, behavior: 'smooth' });
        }}
        onCompleted={(data) => {
          if (data && data.editCustomer) {
            this.setState({ confirmUpdateModalOpen: false });
            this.props.flashCallback(true, 'Attendees successfully updated');

            // this.props.history.push(`/customers/${customer.id}`);
            // window.scrollTo({ top: 0 });
            window.location.replace(`/customers/${customer.id}`);
          }
        }}
      >
        {(editCustomer, { loading }) => {
          return (
            <div>
              <Banner status="warning">
                Select the fields you want to change. This will update the information of the fields you select for <strong>all tickets listed above</strong>.
              </Banner>
              {this.state.unknownError &&
              <Banner
                title="There was a problem updating the attendees"
                status="critical"
              >
                <p>
                  Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                </p>
              </Banner>
              }
              {this.state.errors && this.state.errors.length > 0 &&
              <Banner
                title="There was a problem updating the information for all attendees"
                status="critical"
              >
                {this.errorMessage()}
              </Banner>
              }
              <form id="customerForm" onSubmit={e => {
                e.preventDefault();
                this.setState({ confirmUpdateModalOpen: true });
              }}>
                <FormLayout>
                  <Checkbox checked={this.state.editFields['firstName']} label='First Name' onChange={this.handleEditFieldsChange('firstName')} />
                  <Collapsible open={this.state.editFields['firstName']} id="editCustomerFieldCollaps_firstName" key="editCustomerFieldCollaps_firstName">
                    <TextField key='firstName' id='firstName' value={firstName} label="First Name" placeholder="" onChange={this.handleChange('firstName')} />
                  </Collapsible>

                  <Checkbox checked={this.state.editFields['lastName']} label='Last Name' onChange={this.handleEditFieldsChange('lastName')} />
                  <Collapsible open={this.state.editFields['lastName']} id="editCustomerFieldCollaps_lastName" key="editCustomerFieldCollaps_lastName">
                    <TextField key='lastName' id='lastName' value={lastName} label="Last Name" placeholder="" onChange={this.handleChange('lastName')} />
                  </Collapsible>

                  <Checkbox checked={this.state.editFields['email']} label='Email' onChange={this.handleEditFieldsChange('email')} />
                  <Collapsible open={this.state.editFields['email']} id="editCustomerFieldCollaps_email" key="editCustomerFieldCollaps_email">
                    <TextField key='email' id='email' value={email} label="Email" placeholder="" onChange={this.handleChange('email')} />
                  </Collapsible>

                  <Checkbox checked={this.state.editFields['phone']} label='Phone' onChange={this.handleEditFieldsChange('phone')} />
                  <Collapsible open={this.state.editFields['phone']} id="editCustomerFieldCollaps_phone" key="editCustomerFieldCollaps_phone">
                    <TextField key='phone' id='phone' value={phone} label="Phone" placeholder="" onChange={this.handleChange('phone')} />
                  </Collapsible>

                  {this.buildAttendeeAttributeSpecs(this.shop, this.customer)}
                </FormLayout>
                <PageActions
                  primaryAction={{
                    content: 'Save',
                    onAction: () => {
                      this.setState({ confirmUpdateModalOpen: true });
                    }
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: () => {
                        this.props.history.push(`/customers/${this.customer.id}`);
                        window.scrollTo({ top: 0 });
                      }
                    }
                  ]}
                />
                <Modal
                  title='Confirm customer tickets updates'
                  open={this.state.confirmUpdateModalOpen}
                  onClose={() => {
                    this.setState({ confirmUpdateModalOpen: false });
                  }}
                  primaryAction={{
                    content: 'Yes, update',
                    disabled: loading,
                    onAction: () => {
                      editCustomer({
                        variables:{
                          customerId: customer.id,
                          attendeeIds: this.state.selectedAttendees,
                          firstName: this.state.firstName,
                          lastName: this.state.lastName,
                          email: this.state.email,
                          phone: this.state.phone,
                          attendeeAttributes: JSON.stringify(this.state.attendeeAttributes),
                          editFields: JSON.stringify(this.state.editFields)
                        }
                      });
                    }
                  }}
                  secondaryActions={[
                    {
                      content: 'No, go back',
                      disabled: loading,
                      onAction: () => {
                        this.setState({ confirmUpdateModalOpen: false });
                      }
                    }
                  ]}
                >
                  <Modal.Section>
                    {`This will update the information for the ${(selectedAttendees || []).length} ${(selectedAttendees || []).length == 1 ? 'attendee' : 'attendees'} you have selected above. Are you sure you want to continue with this update? `}
                  </Modal.Section>
                </Modal>
              </form>
            </div>
          );
        }}
      </Mutation>
    );
  }

  render() {
    return (
      <Query
        query={this.GET_CUSTOMER}
        variables={{
          'customerId': this.props.match.params.customerId,
        }}
      >
        {({ loading, error, data }) => {
          if (error) return <ErrorPage error={error}/>;
          if (loading) return <InitLoading />;

          if (data.currentShop) {
            this.shop = data.currentShop;
            this.customer = data.currentShop.customer;
          }

          return (
            <AppPage
              title={this.customer.name}
              fullWidth={true}
              breadcrumbs={[
                {
                  content: 'Master Attendance Report',
                  onAction: () => {
                    this.props.history.push('/customers');
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              secondaryActions={[
                {
                  content: 'Back to Customers',
                  onAction: () => {
                    this.props.history.push('/customers');
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              <div>
                <Card title={this.customer.name} sectioned>
                  <Card.Section title="Tickets">
                    {this.renderTickets(this.shop, this.customer)}
                  </Card.Section>
                  <Card.Section title="Update Tickets">
                    {this.renderUpdateTicketsForm(this.shop, this.customer)}
                  </Card.Section>
                </Card>
              </div>
            </AppPage>
          );
        }}
      </Query>
    );
  }
}

CustomerEdit.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      customerId: PropTypes.string
    })
  })
};
