import React from 'react';
import { Mutation } from 'react-apollo';
import {
  Modal,
  FormLayout,
  TextField,
  Banner,
  Checkbox,
  Collapsible,
  Autocomplete,
  ChoiceList,
  Stack,
  Link,
  Subheading
} from '@shopify/polaris';
import { useState } from 'react';
import { formatErrors, reportError } from '../../utils/errors';
import { CHECKIN_HOST } from '../../utils/checkinHost';
import { CREATE_CHECKIN_USER, EDIT_CHECKIN_USER_PERMISSIONS } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import LazyLoadingSelectedEvents from './LazyLoadingSelectedEvents.jsx';

const AddCheckinUserModal = ({ onClose, availableUsers, events }) => {
  const paginationInterval = 25;
  const deselectedOptions = events.map((e) => ({ value: e.eveyEventId, label: e.title }));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [fullAccess, setFullAccess] = useState(true);
  const [saving, setSaving] = useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const forgotPasswordUrl = `${CHECKIN_HOST}/forgot_password`;

  function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // const isValid = email.length && password.length && passwordConfirm.length && password == passwordConfirm && validateEmail(email);
  const isValid = email.length && validateEmail(email);

  const isExistingUser = availableUsers.find((u) => u.email == email);

  return (
    <Mutation mutation={EDIT_CHECKIN_USER_PERMISSIONS}
      onError={() => {
        showToastError('Failed to create user');
        setSaving(false);
      }}
      onCompleted={(data) => {
        if (data && data.editCheckinUserPermissions) {
          showToastNotice('User created');
          onClose();
        }
      }}
      refetchQueries={() => ['CheckinSettingsQuery']}
    >
      {(editCheckinUserPermissions) => (
        <Mutation mutation={CREATE_CHECKIN_USER}
          onError={(error) => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
              setErrors(error.graphQLErrors[0].errors);
            } else {
              setErrors(['There was a problem creating this user. Contact us if this problem persists.']);
              reportError(error);
            }
            setSaving(false);
          }}
          onCompleted={(data) => {
            if (data && data.createCheckinUser) {
              let permissions = selectedOptions.map((id) => ({ event_id: id }));
              editCheckinUserPermissions({
                variables: {
                  userId: data.createCheckinUser.user.id,
                  permissions: JSON.stringify(permissions),
                }
              });
            }
          }}
          refetchQueries={() => ['CheckinSettingsQuery']}
        >
          {(createCheckinUser) => (
            <Modal
              open={true}
              onClose={() => onClose()}
              title={'Add check-in user'}
              primaryAction={{
                content: 'Save',
                disabled: !isValid || isExistingUser,
                loading: saving,
                onAction: () => {
                  setErrors([]);
                  setSaving(true);
                  createCheckinUser({
                    variables: {
                      email,
                      password,
                      passwordConfirm,
                      fullAccess,
                    }
                  });
                },
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  disabled: saving,
                  onAction: () => {
                    onClose();
                  },
                },
              ]}
            >
              {errors && errors.length > 0 &&
                <Modal.Section>
                  <Banner
                    title="There was a problem creating the user"
                    status="critical"
                  >
                    {formatErrors(errors)}
                  </Banner>
                </Modal.Section>
              }
              {isExistingUser && !saving &&
                <Modal.Section>
                  <Banner
                    title="This user already exists"
                    status="critical"
                  >
                    A user with this email address already exists, select this user in the list to update it.
                  </Banner>
                </Modal.Section>
              }
              <Modal.Section>
                {isExistingUser ? (
                  <FormLayout>
                    <TextField
                      label='Email'
                      type='email'
                      value={email}
                      onChange={(newValue) => setEmail(newValue)}
                    />
                  </FormLayout>
                ) : (
                  <FormLayout>
                    <TextField
                      label='Email'
                      type='email'
                      value={email}
                      onChange={(newValue) => setEmail(newValue)}
                    />
                    {/*
                    <TextField
                      label='Password'
                      type='password'
                      value={password}
                      onChange={(newValue) => setPassword(newValue)}
                    />
                    <TextField
                      label='Confirm password'
                      type='password'
                      value={passwordConfirm}
                      onChange={(newValue) => setPasswordConfirm(newValue)}
                    />
                    */}
                    <Banner status='info'>New users will receive an email with a link to set their password. Existing users can reset their password <Link external url={forgotPasswordUrl}>here</Link>.</Banner>
                  </FormLayout>
                )}
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <Subheading>Permissions</Subheading>
                  <Checkbox
                    checked={fullAccess}
                    label="Full check-in access to all events"
                    onChange={(newValue) => setFullAccess(newValue)}
                  />
                  <Collapsible open={!fullAccess}>
                    <Stack vertical={true} spacing='loose'>
                      <LazyLoadingSelectedEvents
                        events={events} 
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        deselectedOptions={deselectedOptions}
                        paginationInterval={paginationInterval}
                      /> 
                    </Stack>
                  </Collapsible>
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default AddCheckinUserModal;
