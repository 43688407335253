import React from 'react';
import { Toast as ExternalToast, Loading as ExternalLoading } from '@shopify/polaris';
import { Context, Toast, Loading, } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { currentShopDomain } from '../utils/auth';
import { useVendor } from './vendor';
import { useHistory } from "react-router-dom";
import { goToPage } from '../utils/routing';
import { trackEvent } from '../utils/track';

const FrameContext = React.createContext();

const FrameProvider = ({ children }) => {
  const [renderToast, setRenderToast] = React.useState(false);
  const [toastContent, setToastContent] = React.useState('');
  const [toastError, setToastError] = React.useState(false);

  const { isExternal, hasPermission } = useVendor();
  const history = useHistory();
  const app = isExternal ? null : React.useContext(Context);

  const appHost = document.head.querySelector('[name=app-host]').content;
  const apiToken = document.head.querySelector('[name=api-token]').content;
  const externalHost = document.head.querySelector('[name=external-host]').content;

  const showToastNotice = (content) => {
    setToastContent(content);
    setToastError(false);
    setRenderToast(true);
  };

  const showToastError = (content) => {
    setToastContent(content);
    setToastError(true);
    setRenderToast(true);
  };

  const hideToast = () => {
    setRenderToast(false);
    setToastContent('');
    setToastError(false);
  };

  let toastMarkup;
  if (renderToast) {
    toastMarkup = isExternal ? (
      <ExternalToast
        content={toastContent}
        error={toastError}
        duration={5000}
        onDismiss={hideToast}
      />
    ) : (
      <Toast
        content={toastContent}
        error={toastError}
        duration={5000}
        onDismiss={hideToast}
      />
    );
  }

  const loadingMarkup = isExternal ? <ExternalLoading /> : <Loading />;

  const shopDomain = currentShopDomain();

  const navigateTo = ({ history, path, params, external, adminPath, newContext }) => {
    if (external || adminPath) {
      if (isExternal) {
        if (newContext) {
          window.open(path);
        } else {
          window.location = path;
        }
      } else {
        console.log(`path: `, path)
        const redirect = Redirect.create(app);
        if (adminPath) {
          redirect.dispatch(Redirect.Action.ADMIN_PATH, path);
        } else {
          const _path = path.startsWith('http') ? path : `https://${shopDomain}/admin/apps/${apiToken}${path}`;
          redirect.dispatch(Redirect.Action.REMOTE, { url: _path, newContext, });
        }
      }

      trackEvent(`Navigate to ${(path || '').split('?')[0].replace(/\/\d+\//g, '/00/').replace(/\/\d+$/g, '/00')}`, params);
    } else {
      goToPage({ history, path, params });
    }
  };

  return (
    <FrameContext.Provider
      value={{
        renderToast,
        showToastNotice,
        showToastError,
        hideToast,
        loadingMarkup,
        currentShopDomain: shopDomain,
        isExternal,
        canCreateEvents: hasPermission(shopDomain, 'create-events'),
        newEventsAllowPublish: hasPermission(shopDomain, 'new-events-allow-publish'),
        canEditEvents: hasPermission(shopDomain, 'edit-events'),
        canEditTicketTemplates: hasPermission(shopDomain, 'edit-ticket-templates'),
        canEditNotificationTemplates: hasPermission(shopDomain, 'edit-notification-templates'),
        canEditEventAttendeeInfo: hasPermission(shopDomain, 'edit-event-attendee-info'),
        canEditEventTicketFees: hasPermission(shopDomain, 'edit-event-ticket-fees'),
        canViewAttendees: hasPermission(shopDomain, 'view-attendees'),
        canCreateAttendees: hasPermission(shopDomain, 'create-attendees'),
        canEditAttendees: hasPermission(shopDomain, 'edit-attendees'),
        isVendor: isExternal,
        navigateTo,
        appHost,
        externalHost,
      }}
    >
      {children}
      {toastMarkup}
    </FrameContext.Provider>
  );
};

const useFrame = () => React.useContext(FrameContext);

export { FrameProvider, useFrame };
