import React from 'react';
import { Layout } from '@shopify/polaris';
import SettingsPage from './SettingsPage';
import LegacySubscriptionCard from './Subscription/LegacySubscriptionCard';
import SubscriptionCard from './Subscription/SubscriptionCard';
import { useHistory } from "react-router-dom";
import { hasNewPricing } from '../../utils/features';
import { currentShopDomain } from '../../utils/auth';

const AccountPage = ({ flashCallback }) => {
  const history = useHistory();

  const newPricingEnabled = hasNewPricing(currentShopDomain());

  return (
    <SettingsPage pageTitle='Account' title='Account' fullWidth={true} hideTrialBanner={true}>
      <Layout.AnnotatedSection
        title="Subscription"
        description='Manage your billing and subscriptions.'
      >
        {newPricingEnabled &&
          <SubscriptionCard />
        }
        <LegacySubscriptionCard />
      </Layout.AnnotatedSection>
    </SettingsPage>
  );
};

export default AccountPage;
