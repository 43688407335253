import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import PropTypes from 'prop-types';
import { Banner, Link } from '@shopify/polaris';
import { getHostFromShopDomain } from '../../utils/auth';

class MissingProduct extends EveyComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const shop = this.props.shop;
    const event = this.props.event;
    const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
    
    if (event.isMissingProduct) {
      return (
        <div className="missing-product">
          <Banner
            title="The product for this event is missing"
            status="warning"
          >
            <p>
              It looks like the Shopify Product for this event has been deleted, you&apos;ll have limited access to it.
              {event.isShopifySubscription &&
                <span>
                  {' You also have an active subscription for this event, if you do not need access to it please cancel it from the '}
                  <Link url={'/events/' + event.id + '/edit' + searchParams}>Event Settings page</Link>
                </span>
              }
            </p>
          </Banner>
        </div>
      );
    } else {
      return ('');
    }
  }
}

MissingProduct.propTypes = {
  shop: PropTypes.object,
  event: PropTypes.object
};

export default MissingProduct;
