import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { Modal, FormLayout, TextField, Label, ChoiceList, Stack, Spinner, Autocomplete, TextContainer, TextStyle, Banner } from '@shopify/polaris';
import AttendeeFilters from '../AttendeeList/AttendeeFilters';
import ReactPhoneInput from 'react-phone-input-2';

const SEND_REMINDER_TEST = gql`
  mutation sendAttendeeReminderTest($eventId: ID!, $reminderId: ID!, $email: String, $phone: String, $attendeeId: ID) {
    sendAttendeeReminderTest(eventId: $eventId, reminderId: $reminderId, email: $email, phone: $phone, attendeeId: $attendeeId) {
      success
    }
  }
`;

const GET_ATTENDEES = gql`
  query ReminderAttendeesQuery($eventId: ID!, $category: String, $filters: String, $search: String) {
    currentShop {
      id
      event(id: $eventId) {
        id
        attendees(eventId: $eventId, category: $category, filters: $filters, search: $search) {
          id
          ticketCode
          email
          phonePretty
          ticketTitle
        }
      }
    }
  }
`;

const SendTestModal = ({ event, reminder, onComplete, onClose, flashCallback }) => {
  const [to, setTo] = React.useState('');
  const [selectedAttendeeId, setSelectedAttendeeId] = React.useState();
  const [search, setSearch] = React.useState('');

  const isValid = to && to.length;

  return (
    <Mutation
      mutation={SEND_REMINDER_TEST}
      onError={() => {
        flashCallback(true, 'Unable to send test', true);
        onClose();
      }}
      onCompleted={() => {
        flashCallback(true, 'Test message sent');
        onComplete();
      }}
    >
      {(sendAttendeeReminderTest, { loading }) => (
        <Modal
          open={true}
          onClose={() => onClose()}
          title={'Send test email'}
          primaryAction={{
            content: 'Send it',
            disabled: !isValid,
            loading: loading,
            onAction: () => {
              sendAttendeeReminderTest({
                variables: {
                  eventId: event.id,
                  reminderId: reminder.id,
                  email: to,
                  attendeeId: selectedAttendeeId,
                }
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Close',
              disabled: loading,
              onAction: () => onClose(),
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label={'Email'}
                value={to}
                placeholder={'test@example.com'}
                onChange={(newValue) => setTo(newValue)}
                type={'email'}
              />
              <Query
                query={GET_ATTENDEES}
                variables={{
                  'eventId': event.id,
                  'category': 'all',
                  'filters': JSON.stringify([]),
                  'search': search,
                }}
              >
                {({ loading: attendeesLoading, data }) => {

                  const formatAttendee = (attendee) => (
                    <Stack vertical={false}>
                      <Stack vertical={false} spacing='extraLoose'>
                        <TextStyle variation='subdued'>{attendee.ticketCode}</TextStyle>
                        <div>{attendee.email || attendee.phonePretty}</div>
                        <div>{attendee.ticketTitle}</div>
                      </Stack>
                    </Stack>
                  );

                  const textFormatAttendee = (attendee) => `${attendee.ticketCode} - ${attendee.email || attendee.phonePretty} (${attendee.ticketTitle})`;

                  const attendees = data?.currentShop?.event?.attendees || [];

                  return (
                    <Stack vertical={true} spacing='tight'>
                      <Autocomplete
                        allowMultiple={false}
                        loading={attendeesLoading}
                        options={attendees.map((attendee) => ({ value: attendee.id, label: formatAttendee(attendee) }))}
                        selected={[selectedAttendeeId]}
                        textField=<Autocomplete.TextField
                          onChange={setSearch}
                          value={search}
                          label="Sample attendee"
                          helpText='This attendees data will be used when sending the test message, the message will be sent to the address you specify above'
                        />
                        onSelect={(v) => {
                          setSelectedAttendeeId(v[0]);
                          setSearch(textFormatAttendee(attendees.find((a) => a.id == v[0])));
                        }}
                        listTitle="Attendees"
                      />
                    </Stack>
                  );
                }}
              </Query>
              {!selectedAttendeeId &&
                <Banner status='warning'>With no sample attendee selected we will use fake attendee data, this may not always be accurate.</Banner>
              }
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default SendTestModal;
