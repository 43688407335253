import React from 'react';
import { Query } from 'react-apollo';
import { Card, Select, Button, Stack, Modal, DataTable } from '@shopify/polaris';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { GET_EVENT_STATS } from '../../../utils/graphql';
import LoadingCard from './LoadingCard';
import CardHeader from '../CardHeader';
import CardFooter from '../CardFooter';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import { moneyFormat } from '../../../utils/money';
import EmptyStateCard from './EmptyStateCard';
import DashboardCard from '../DashboardCard';
import { useFrame } from '../../../contexts/frame';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';

const TotalRevenueContainer = styled.div`
`;

const TotalRevenueLabel = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-size:12px;
  line-height:16px;
  letter-spacing: 0.4px;
  color:#9C9C9C;
`;

const TotalRevenueValue = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-weight:bold;
  font-size:24px;
  line-height:132%;
  color:#333333;
`;

const TicketSalesCard = ({ shop, event }) => {
  const [days, setDays] = React.useState('7');
  const [ticketsOpen, setTicketsOpen] = React.useState(false);

  const history = useHistory();
  const { canViewAttendees } = useFrame();

  return (
      <Query query={GET_EVENT_STATS} variables={{ eventId: event.id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingCard title='Ticket sales' />;
          }

          let ticketSalesByDate = data?.currentShop?.event?.ticketSalesByDate && JSON.parse(data?.currentShop.event.ticketSalesByDate) || [];
          const totalRevenue = data?.currentShop?.event?.totalRevenue || 0;
          const ticketStats = data?.currentShop?.event?.ticketStats && JSON.parse(data?.currentShop?.event?.ticketStats) || [];
          const totalTicketsSold = data?.currentShop?.event?.totalTicketsSold || 0;
          const capacity = data?.currentShop?.event?.capacity || 0;
          const hasCrossVariantInventory = data?.currentShop?.event?.hasCrossVariantInventory;

          if (ticketSalesByDate.length < 1) {
            return (
              <EmptyStateCard
                title='Ticket sales'
                imageUrl={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/sales.svg`}
                message='Ticket sales will appear here.'
              />
            );
          }

          const filterDate = dayjs(dayjs().subtract(parseInt(days), 'day').format('YYYY-MM-DD'));
          ticketSalesByDate = ticketSalesByDate.filter((item) => dayjs(item.date).isAfter(filterDate));

          const lineData = ticketSalesByDate.map((item) => ({ name: item.date, tickets: item.count }));

          const formatDate = (date) => dayjs(date).format('MMM D');

          const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

          return (
            <DashboardCard
              title='Ticket sales'
              headerAction={(
                <Select
                  label='Sort by'
                  labelInline
                  options={[
                    { label: 'Past 7 days', value: '7' },
                    { label: 'Past 30 days', value: '30' },
                    { label: 'Past 6 months', value: `${30*6}` },
                  ]}
                  value={days}
                  onChange={setDays}
                />
              )}
              footerLeft={
                <TotalRevenueContainer>
                  <TotalRevenueLabel>Total revenue</TotalRevenueLabel>
                  <TotalRevenueValue>{(moneyFormat(shop, totalRevenue) || '').replace(/(<([^>]+)>)/gi, "")}</TotalRevenueValue>
                </TotalRevenueContainer>
              }
              primaryAction={canViewAttendees ? {
                content: 'Go to orders',
                onAction: () => goToPage({ history, path: `/events/${event.id}/event_orders` }),
              } : undefined}
              secondaryAction={event.dateType === 'recurring' ? undefined : {
                content: 'Ticket summary',
                onAction: () => setTicketsOpen(true)
              }}
            >
              <ComposedChart width={Math.min(windowWidth, 560)} height={280} data={lineData} margin={{top: 25, right: 30, left: 0, bottom: 5}}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#007bff" stopOpacity={0.33}/>
                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" padding={{ left: 10, right: 10 }} tickFormatter={formatDate} style={{ fontFamily: 'Montserrat', fontSize: '12px' }} />
                <YAxis style={{ fontFamily: 'Montserrat', fontSize: '12px' }} />
                <Tooltip />
                <CartesianGrid  vertical={false} stroke="#DDD" />
                <Line
                  type="monotone"
                  dataKey="tickets"
                  strokeLinecap="round"
                  stroke="#007bff"
                  dot={false}
                  legendType="none"
                />
                <Area
                  type="monotone"
                  dataKey="tickets"
                  stroke={false}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </ComposedChart>
              {ticketsOpen &&
                <Modal
                  open={true}
                  onClose={() => setTicketsOpen(false)}
                  title='Ticket summary'
                >
                  <Modal.Section>
                    <DataTable
                      columnContentTypes={[
                        'text',
                        'text',
                        'text',
                      ]}
                      headings={[
                        'Ticket type',
                        'Tickets sold',
                        'Capacity',
                      ]}
                      rows={hasCrossVariantInventory ?
                        [['Shared inventory', totalTicketsSold, capacity]]
                          :
                        ticketStats.map((t) => ([ t.title, t.total_tickets_sold, t.capacity ]))
                      }
                    />
                  </Modal.Section>
                </Modal>
              }
            </DashboardCard>
          );
        }}
      </Query>
  );
};

export default TicketSalesCard;
