import React from 'react';
import { Card, FormLayout, ChoiceList, Checkbox } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useFrame } from '../../../contexts/frame';

const SAVE_NOTIFICATION_SETTINGS = gql`
  mutation saveNotificationSettings($orderStatusEnabled: Boolean, $customerNotificationsEnabled: Boolean) {
    saveNotificationSettings(orderStatusEnabled: $orderStatusEnabled, customerNotificationsEnabled: $customerNotificationsEnabled) {
      success
    }
  }
`;

const NotificationsCard = ({ shop }) => {
  const [orderStatusEnabled, setOrderStatusEnabled] = React.useState(shop.orderStatusEnabled);
  const [customerNotificationsEnabled, setCustomerNotificationsEnabled] = React.useState(shop.customerNotificationsEnabled);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={SAVE_NOTIFICATION_SETTINGS}
      onError={() => showToastError('Failed to update settings')}
      onCompleted={() => showToastNotice('Settings updated')}
      refetchQueries={() => ['GetShopOrdersSettings']}
    >
      {(saveNotificationSettings, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              saveNotificationSettings({
                variables: {
                  orderStatusEnabled: orderStatusEnabled,
                  customerNotificationsEnabled,
                },
              });
            },
          }}
        >
          <FormLayout>
            <ChoiceList
              title="Order status page - the final summary page after a customer completes a purchase"
              choices={[
                { label: 'Each event has their own settings', value: null },
                { label: 'Show the details for purchased tickets on the order status page for all events', value: true },
                { label: 'Do not show the ticket details on the order status page for any event', value: false },
              ]}
              selected={[orderStatusEnabled]}
              onChange={(v) => setOrderStatusEnabled(v[0])}
            />
            <Checkbox
              checked={customerNotificationsEnabled == false}
              label='Never send notification email to customers when tickets are purchased or created with their email address'
              helpText="This may be useful if you use an integration with an external service that handles these notifications"
              onChange={(v) => setCustomerNotificationsEnabled(!v)}
            />
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default NotificationsCard;
