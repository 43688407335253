import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import PropTypes from 'prop-types';
import { Card, ResourceList, Link as ShopifyLink, EmptyState, Badge } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import AttendeeReminderResourceItem from './AttendeeReminderResourceItem.jsx';

export default class AttendeeReminders extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
  }

  EVENT_FIELDS = `
    attendeeReminders {
      id
      name
      subject
      sending
      sentAt
      createdAt
      scheduledDate
    }
  `;

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          const items = event.attendeeReminders;

          return (
            <EventPage
              shop={shop}
              event={event}
              title="Attendee messages"
              pageTitle="Attendee messages"
              pageSubtitle=<div><p>Design and send updates and reminders to your attendees using attendee messages. Start by building a template with the message details and select your audience.</p></div>
              flashCallback={this.props.flashCallback}
              history={this.props.history}
              actionGroups={[]}
              secondaryActions={[
                {
                  content: 'Back to Attendees',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/attendees`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                },
                {
                  content: 'Dashboard',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                },
                {
                  content: 'Orders',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/event_orders`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
            >
              <Card
                primaryFooterAction={items.length ? {
                  content: 'Create new message',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/reminders/new`);
                    window.scrollTo({ top: 0 });
                  }
                } : undefined}
              >
                <Card.Section>
                  <ResourceList
                    emptyState={!items.length ? (
                      <EmptyState
                        heading="Create an attendee message"
                        action={{
                          content: 'Create a message template',
                          onAction: () => {
                            this.props.history.push(`/events/${event.id}/reminders/new`);
                            window.scrollTo({ top: 0 });
                          }
                        }}
                        image="https://cdn.shopify.com/s/files/1/0481/9383/1070/files/Post.svg?v=1602010637"
                      >
                        <p>
                          You can send messages to attendees to tell them about changes to the event, send them livestream links, and more.
                        </p>
                      </EmptyState>
                    ) : undefined}
                    items={items}
                    renderItem={(item) => (
                      <AttendeeReminderResourceItem
                        shop={shop}
                        event={event}
                        reminder={item}
                        flashCallback={this.props.flashCallback}
                        history={this.props.history}
                      />
                    )}
                    resourceName={{singular: 'attendee message', plural: 'attendee messages'}}
                  />
                </Card.Section>
              </Card>
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

AttendeeReminders.propTypes = {
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
