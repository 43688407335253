import React from 'react';
import { Mutation } from 'react-apollo';
import { FormLayout, Modal, TextField, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { goToPage } from '../../../utils/routing';
import gql from 'graphql-tag';

const COPY_TEMPLATE = gql`
  mutation copyAttendeeFieldsTemplate($templateId: ID!, $name: String) {
    copyAttendeeFieldsTemplate(templateId: $templateId, name: $name) {
      template {
        id
      }
    }
  }
`;

const CopyTemplateModal = ({ template, onDismiss, onCopied }) => {
  const [name, setName] = React.useState(`Copy of ${template.name}`);
  const [copying, setCopying] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={COPY_TEMPLATE}
      onError={() => showToastError('Failed to copy template')}
      onCompleted={(data) => {
        showToastNotice('Template copied');
        onCopied(data?.copyAttendeeFieldsTemplate.template.id);
      }}
    >
      {(copyAttendeeFieldsTemplate, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onDismiss}
          title='Copy template'
          primaryAction={{
            content: 'Save copy',
            loading: copying,
            onAction: () => {
              setCopying(true);
              copyAttendeeFieldsTemplate({
                variables: {
                  templateId: template.id,
                  name,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: copying,
              onAction: () => onDismiss(),
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                value={name}
                label="New Name"
                onChange={(v) => setName(v)}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default CopyTemplateModal;
