import React from 'react';
import { Card, Banner, TextContainer } from '@shopify/polaris';

const ErrorCard = ({ title, error }) => {

  return (
    <Card title={title} sectioned>
      <Banner title="We're sorry, but something went wrong" status='critical'>
        <TextContainer>
          We've notified the crew and we&apos;re working on a fix
        </TextContainer>
      </Banner>
    </Card>
  );
};

export default ErrorCard;
