import React from 'react';
import { ContextualSaveBar as ExternalContextualSaveBar } from '@shopify/polaris';
import { Context } from '@shopify/app-bridge-react';
import { ContextualSaveBar as EmbeddedContextualSaveBarAction } from '@shopify/app-bridge/actions';
import { useFrame } from '../../contexts/frame';

const EmbeddedSaveBar = ({ onSave, onDiscard, loading, show }) => {
  const app = React.useContext(Context);

  const contextualSaveBar = EmbeddedContextualSaveBarAction.create(app, {
    saveAction: {
      disabled: false,
      loading: loading,
    },
    discardAction: {
      disabled: loading,
      loading: false,
      discardConfirmationModal: false,
    },
  });

  contextualSaveBar.subscribe(
    EmbeddedContextualSaveBarAction.Action.DISCARD,
    async () => {
      contextualSaveBar.dispatch(EmbeddedContextualSaveBarAction.Action.HIDE);
      await onDiscard();
    }
  );

  contextualSaveBar.subscribe(
    EmbeddedContextualSaveBarAction.Action.SAVE,
    async () => {
      await onSave();
    }
  );

  if (show) {
    contextualSaveBar.dispatch(EmbeddedContextualSaveBarAction.Action.SHOW);
  } else {
    contextualSaveBar.dispatch(EmbeddedContextualSaveBarAction.Action.HIDE);
  }

  return null;
};

const SaveBar = ({ show, loading, onDiscard, onSave }) => {
  const { isExternal } = useFrame();

  return (
    isExternal ? (
      show ? (
        <ExternalContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: onSave,
            loading: loading,
          }}
          discardAction={{
            onAction: onDiscard,
          }}
          isExternal={isExternal}
        />
      ) : ''
    ) : (
      <EmbeddedSaveBar
        show={show}
        onSave={onSave}
        onDiscard={onDiscard}
        loading={loading}
      />
    )
  );
};

export default SaveBar;
