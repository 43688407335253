import React from 'react';
import { Stack, Button, InlineError } from '@shopify/polaris';

const ModalFooter = ({
  destructiveButtonAction,
  destructiveButtonLoading,
  destructiveButtonDisabled,
  destructiveButtonText = 'Delete',
  destructiveButtonDestructive = true,
  cancelButtonAction,
  cancelButtonDisabled,
  saveButtonText,
  saveButtonAction,
  saveButtonLoading,
  saveButtonDisabled,
  errorMessage,
}) => (
  <Stack alignment='center'>
    <Stack.Item fill>
      {destructiveButtonAction && (
        <Button
          destructive={destructiveButtonDestructive}
          onClick={destructiveButtonAction}
          loading={destructiveButtonLoading}
          disabled={destructiveButtonDisabled}
        >
          {destructiveButtonText}
        </Button>
      )}
      {errorMessage && errorMessage.length > 0 &&
        <InlineError message={errorMessage} />
      }
    </Stack.Item>

    <Button onClick={cancelButtonAction} disabled={cancelButtonDisabled}>Cancel</Button>
    {saveButtonText &&
      <Button primary onClick={saveButtonAction} loading={saveButtonLoading} disabled={saveButtonDisabled}>
        {saveButtonText}
      </Button>
    }
  </Stack>
);

export default ModalFooter;
