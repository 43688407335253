import React from "react";
import EveyComponent from "../EveyComponent.jsx";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { Mutation, Query } from "react-apollo";
import {
  FormLayout,
  Layout,
  Card,
  TextField,
  Banner,
  DatePicker,
  PageActions,
  Checkbox,
} from "@shopify/polaris";
import InitLoading from "../InitLoading.jsx";
import AppPage from "../AppPage/AppPage.jsx";
import ErrorPage from "../Error/ErrorPage.jsx";

export default class LinkProduct extends EveyComponent {
  constructor(props) {
    super(props);
  }

  product = null;

  state = {
    productId: new URLSearchParams(window.location.search).get("id"),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    location: "",
    startTime: "13:30",
    endTime: "16:30",
    selected: null,
    multiDayRange: false,
    errors: {},
    unknownError: false,
  };

  GET_PRODUCT = gql`
    query ProductQuery($productId: ID!) {
      currentShop {
        id
        trialExpired
        product(id: $productId) {
          id
          title
        }
      }
    }
  `;

  LINK_PRODUCT = gql`
    mutation linkProduct(
      $productId: String!
      $location: String
      $startAt: String
      $endAt: String
    ) {
      linkProduct(
        productId: $productId
        location: $location
        startAt: $startAt
        endAt: $endAt
      ) {
        event {
          id
        }
        nextUrl
      }
    }
  `;

  handleChange = (field) => {
    return (value) => this.setState({ [field]: value });
  };

  handleDateChange = (value) => {
    this.setState({ selected: value });
  };

  handleMonthChange = (month, year) => {
    this.setState({
      month,
      year,
    });
  };

  render() {
    const {
      productId,
      location,
      startTime,
      endTime,
      month,
      year,
      selected,
      multiDayRange,
    } = this.state;

    return (
      <Query
        query={this.GET_PRODUCT}
        variables={{ productId: this.state.productId }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <InitLoading />;
          } else if (error) {
            return <ErrorPage error={error} />;
          } else {
            if (data.currentShop) {
              this.product = data.currentShop.product;
            }
            return (
              <Mutation
                mutation={this.LINK_PRODUCT}
                onError={(error) => {
                  if (
                    error.graphQLErrors &&
                    error.graphQLErrors.length > 0 &&
                    error.graphQLErrors[0].errors
                  ) {
                    this.setState({
                      errors: error.graphQLErrors[0].errors,
                      unknownError: false,
                    });
                  } else {
                    this.setState({ unknownError: true, errors: [] });
                    this.reportError(error);
                  }
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                onCompleted={(data) => {
                  if (data && data.linkProduct) {
                    this.setState({ unknownError: false, errors: [] });
                    this.props.flashCallback(
                      true,
                      "Event successfully created from product"
                    );

                    this.props.history.push(
                      `/events/${data.linkProduct.event.id}/edit`
                    );
                    window.scrollTo({ top: 0 });
                  }
                }}
              >
                {(linkProduct, { loading }) => {
                  return (
                    <AppPage
                      title="New Event"
                      breadcrumbs={[
                        {
                          content: "Events",
                          onAction: () => {
                            this.props.history.push("/events");
                            window.scrollTo({ top: 0 });
                          },
                          target: "APP",
                        },
                      ]}
                      primaryAction={{
                        content: "Save Event",
                        primary: true,
                        disabled: loading,
                        onAction: () => {
                          document
                            .getElementById("link-product-form")
                            .querySelector('[type="submit"]')
                            .click();
                        },
                      }}
                      secondaryActions={[
                        ...(data.currentShop.trialExpired ? [] : [{
                          content: 'Request 1:1 setup call',
                          url: 'https://calendly.com/evey-onboarding-staytuned',
                          target: 'REMOTE',
                          external: true
                        }]),
                        {
                          content: "Help",
                          url: "https://evey-events.zendesk.com/hc/en-us/",
                          target: "REMOTE",
                          external: true,
                        },
                        {
                          content: "Subscriptions",
                          onAction: () => {
                            this.props.history.push(
                              "/events?category=subscription-active"
                            );
                            window.scrollTo({ top: 0 });
                          },
                          target: "APP",
                        },
                      ]}
                      flashCallback={this.props.flashCallback}
                      history={this.props.history}
                    >
                      {this.state.unknownError && (
                        <Banner
                          title="There was a problem creating the event"
                          status="critical"
                        >
                          <p>
                            Please ensure all required fields are filled out and
                            try submitting again. Contact support at
                            support@eveyevents.com if the problem persists.
                          </p>
                        </Banner>
                      )}
                      {this.state.errors && this.state.errors.length > 0 && (
                        <Banner
                          title="There was a problem creating your event"
                          status="critical"
                        >
                          {this.errorMessage()}
                        </Banner>
                      )}
                      <Layout>
                        <form
                          id="link-product-form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            let startDate = null;
                            let endDate = null;
                            if (
                              this.state.selected &&
                              this.state.selected.start &&
                              this.state.startTime
                            ) {
                              startDate = this.convertToDateTime(
                                this.state.selected.start,
                                this.state.startTime
                              );
                            }
                            if (
                              this.state.selected &&
                              this.state.selected.end &&
                              this.state.endTime
                            ) {
                              endDate = this.convertToDateTime(
                                this.state.selected.end,
                                this.state.endTime
                              );
                            }
                            linkProduct({
                              variables: {
                                productId: this.state.productId,
                                location: this.state.location,
                                startAt: startDate,
                                endAt: endDate,
                              },
                            });
                          }}
                        >
                          <Layout.AnnotatedSection
                            title="Link Product to Event"
                            description="This will link your existing product to a new event in the Evey Events for Shopify app. You can edit more event details after linking to a product."
                          >
                            <Card sectioned>
                              <FormLayout>
                                <TextField
                                  id="product_id"
                                  value={productId}
                                  label="Product ID"
                                  disabled={true}
                                />
                                <TextField
                                  id="title"
                                  value={this.product.title}
                                  label="Event Name"
                                  disabled={true}
                                />

                                <TextField
                                  id="location"
                                  value={location}
                                  label="Location"
                                  placeholder="Ottawa, ON"
                                  onChange={this.handleChange("location")}
                                />

                                <div className="Polaris-Labelled__LabelWrapper">
                                  <div className="Polaris-Label">
                                    <label
                                      id="TextField2Label"
                                      className="Polaris-Label__Text"
                                    >
                                      Event Date
                                    </label>
                                  </div>
                                </div>
                                <div className="EventForm__MultiDayCheckbox">
                                  <Checkbox
                                    checked={multiDayRange}
                                    label="Event date is across more than one day"
                                    onChange={(value) => {
                                      this.setState({
                                        multiDayRange: value,
                                        selected: null,
                                      });
                                    }}
                                  />
                                </div>
                                <DatePicker
                                  id="start_at"
                                  month={month}
                                  year={year}
                                  onChange={this.handleDateChange}
                                  onMonthChange={this.handleMonthChange}
                                  selected={selected}
                                  allowRange={multiDayRange}
                                  multiMonth={multiDayRange}
                                />
                                <FormLayout.Group condensed>
                                  <TextField
                                    value={startTime}
                                    type="time"
                                    label="Start Time"
                                    onChange={this.handleChange("startTime")}
                                  />
                                  <TextField
                                    value={endTime}
                                    type="time"
                                    label="End Time"
                                    onChange={this.handleChange("endTime")}
                                  />
                                </FormLayout.Group>
                              </FormLayout>
                            </Card>
                          </Layout.AnnotatedSection>

                          <PageActions
                            primaryAction={{
                              content: "Save",
                              loading: loading,
                              submit: true,
                            }}
                          />
                        </form>
                      </Layout>
                    </AppPage>
                  );
                }}
              </Mutation>
            );
          }
        }}
      </Query>
    );
  }
}

LinkProduct.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
