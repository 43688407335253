export const hasStorageAccess =
  !!(() => {
    try {
      sessionStorage.getItem('test');
      return true;
    } catch {
      return false;
    }
  })();

export const storageGetItem = (key) => {
  try {
    return sessionStorage.getItem(key);
  } catch {
    return null;
  }
};

export const storageSetItem = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
  } catch {
    return null;
  }
};

export const storageRemoveItem = (key) => {
  try {
    return sessionStorage.removeItem(key);
  } catch {
    return null;
  }
};
