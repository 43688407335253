import React from 'react';

const FormFieldLabel = ({ label }) => {
  return (
    <div className="Polaris-Labelled__LabelWrapper">
      <div className="Polaris-Label">
        <label id="TextField2Label" className="Polaris-Label__Text">{label}</label>
      </div>
    </div>
  );
};

export default FormFieldLabel;
