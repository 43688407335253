import React from 'react';
import { Card, Link, TextField, TextContainer, TextStyle, Stack , FormLayout, Button} from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import { EDIT_BARCODE_TEMPLATE } from '../../../utils/graphql';
import { useFrame } from '../../../contexts/frame';
import { liquidEngine } from '../../../utils/liquid';
import { buildPreviewData } from '../../../utils/preview';
import QRCode from 'qrcode.react';

const DEFAULT_VALUE = '{{ attendee.checkin_url }}'

const BarCodeTemplateCard = ({ shop, event }) => {
  const [codeTemplate, setCodeTemplate] = React.useState(event.barcodeValueTemplate || DEFAULT_VALUE);
  const [renderError, setRenderError] = React.useState(false);
  const [renderedCode, setRenderedCode] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const renderPreview = async () => {
    try {
      const previewContext = buildPreviewData({ shop, event, config: {} });
      const result = await liquidEngine.parseAndRender(codeTemplate, previewContext);
      setRenderedCode(result);
      setRenderError(false);
    } catch (error) {
      if (error.name === 'ParseError') {
        setRenderError(error.message);
      } else {
        throw error;
      }
    }
  };

  React.useEffect(() => {
    renderPreview();
  }, [codeTemplate]);

  const isDirty = codeTemplate != (event.barcodeValueTemplate || DEFAULT_VALUE);

  return (
    <Mutation
      mutation={EDIT_BARCODE_TEMPLATE}
      onError={() => showToastError('Failed to save barcode template')}
      onCompleted={() => showToastNotice('Barcode template saved')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editBarcodeTemplate, { loading: saving }) => (
        <Card
          primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !isDirty || renderError,
              onAction: () => {
                editBarcodeTemplate({
                  variables: {
                    eventId: event.id,
                    codeTemplate: codeTemplate == DEFAULT_VALUE ? null : codeTemplate,
                  },
                });
              },
          }}
          actions={[
            {
              content: 'Liquid variables',
              url: 'https://evey-events.zendesk.com/hc/en-us/',
              external: true,
            }
          ]}
        >
          <Card.Section>
            <FormLayout>
              <TextField
                label="QR code template (liquid)"
                value={codeTemplate}
                onChange={(v) => setCodeTemplate(v)}
                error={renderError}
                autoComplete={false}
              />
              <Button plain onClick={() => setCodeTemplate(DEFAULT_VALUE)}>Reset to default</Button>
            </FormLayout>
          </Card.Section>
          <Card.Section title='Preview'>
            <Stack vertical={true}>
              <TextContainer><TextStyle variation="subdued">{renderedCode}</TextStyle></TextContainer>
              <QRCode value={renderedCode} />
            </Stack>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default BarCodeTemplateCard;
