import React, { useState} from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Layout, Link as ShopifyLink } from '@shopify/polaris';
import SettingsPage from '../SettingsPage';
import AnnotatedSectionLoading from '../../shared/AnnotatedSectionLoading.jsx';
import VendorAccountsCard from './VendorAccountsCard';
import UpgradeRequiredBanner from '../../shared/UpgradeRequiredBanner';
import { hasPlanFeature } from '../../../utils/features';
import { getHostFromShopDomain } from '../../../utils/auth';
import { useLocation } from "react-router-dom";
import VendorSettingsCard from './VendorSettingsCard';
import { useFrame } from '../../../contexts/frame';

const GET_VENDORS = gql`
  query GetVendors {
    currentShop {
      id
      name
      domain
      vendorOrgName
      vendorLogoUrl
      hasLegacyPricing
      trialExpired
      activeSubscription {
        hasCheckinFeature
        hasRecurringEventsFeature
        hasSeatingFeature
        hasMailchimpFeature
        hasMessagesFeature
        hasPosFeature
        hasVendorsFeature
        id
      }
      vendors {
        id
        name
        email
        permissions
        allEvents
        eventIds
      }
      allEvents {
        id
        title
        eveyEventId
      }
    }
  }
`;

const VendorsPage = () => {

  const { currentShopDomain, externalHost } = useFrame();
  const [dashboardUrl, setDashboardUrl] = useState(`https://${externalHost}?shop=${currentShopDomain}${"&host=" + getHostFromShopDomain(currentShopDomain)}`);

  const location = useLocation();

  return (
    <SettingsPage
      title='Vendors'
      primaryAction={{
        content: 'Open vendor dashboard',
        url: dashboardUrl,
        external: true,
      }}
    >
      <Query query={GET_VENDORS} >
        {({ loading, data }) => {
          if (loading) {
            return <AnnotatedSectionLoading />;
          }

          const shop = data.currentShop;
          if(shop?.domain) setDashboardUrl(`https://${externalHost}?shop=${shop?.domain}${"&host=" + getHostFromShopDomain(shop?.domain)}`);

          const hasVendorsFeature = hasPlanFeature({ shop, feature: 'vendors' });

          return (
            <Layout>
              {!hasVendorsFeature &&
                <Layout.Section>
                  <UpgradeRequiredBanner message='Upgrade your plan to access the multi-vendor accounts feature.' />
                </Layout.Section>
              }
              <Layout.AnnotatedSection
                title="Settings"
                description=<div>
                  <p>Manage the branding and other settings for the vendor dashboard.</p>
                </div>
              >
                <VendorSettingsCard shop={shop} accounts={shop.vendors} />
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Accounts"
                description=<div>
                  <p>Vendor accounts have access to the <ShopifyLink external url={`${dashboardUrl}`} target="_blank">vendor dashboard</ShopifyLink> to view and/or manage events.</p>
                </div>
              >
                <VendorAccountsCard shop={shop} accounts={shop.vendors} />
              </Layout.AnnotatedSection>
            </Layout>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default VendorsPage;
