import React from 'react';
import { Layout, Tabs, Link as ShopifyLink, Card } from '@shopify/polaris';
import VirtualEventPageConfigCard from './VirtualEventPageConfigCard';
import VirtualEventPageCodeCard from './VirtualEventPageCodeCard';
import VirtualEventConfigCard from './VirtualEventConfigCard';
import { getHostFromShopDomain } from '../../utils/auth';

const VirtualEventSettings = ({ shop, event, flashCallback, history }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <>
      <Layout.AnnotatedSection
        title="General settings"
        description=<div>
          <p>Manage your virtual or online event experience for your customers.</p>
          <p>
            Customers will receieve either a link directly to your livestream or a
            link to a dedicated virtual event page that can host your livestream and
            any other information you want to enhance their experience.
          </p>
        </div>
      >
        <VirtualEventConfigCard
          shop={shop}
          event={event}
        />
      </Layout.AnnotatedSection>
      {event.virtualEventPage.enabled &&
        <Layout.AnnotatedSection
          title="Virtual event page"
          description=<div>
            <p>Your virtual event page is a place you can offer your attendees that they can access all information about the event and gain access to embedded livestreams or videos. You can even require that they enter a password before gaining access.</p>
            <p>You can learn more about this page in our <ShopifyLink external url="https://evey-events.zendesk.com/hc/en-us/" target="_blank">help center</ShopifyLink></p>
            <p>Customizing or translating the text on this page can be done through the in-app <ShopifyLink external url={`/settings/translations${searchParams}`} target="_blank">Translation Manager</ShopifyLink>, or edit the code directly here.</p>
          </div>
        >
          <Tabs
            tabs={[
              {
                id: 'config',
                content: 'Settings',
                accessiblityLabel: 'Settings',
                panelID: 'config-content'
              },
              {
                id: 'code',
                content: 'Code',
                accessiblityLabel: 'Code',
                panelID: 'code-content'
              }
            ]}
            selected={selectedTab}
            onSelect={(v) => setSelectedTab(v)}
          >
            {selectedTab == 0 &&
                <VirtualEventPageConfigCard
                  flashCallback={flashCallback}
                  history={history}
                  event={event}
                  shop={shop}
                />
            }
            {selectedTab == 1 &&
                <VirtualEventPageCodeCard
                  flashCallback={flashCallback}
                  history={history}
                  event={event}
                  shop={shop}
                />
            }
          </Tabs>
        </Layout.AnnotatedSection>
      }
    </>
  );
};

export default VirtualEventSettings;
