import React from 'react';
import { SkeletonBodyText, SkeletonDisplayText, Layout, Card, FormLayout } from '@shopify/polaris';

class AnnotatedSectionLoading extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Layout.AnnotatedSection
        title=<SkeletonDisplayText size="small" />
        description=<SkeletonBodyText lines={3} />
      >
        <Card sectioned>
          <FormLayout>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={2} />
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={5} />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    );
  }
}

export default AnnotatedSectionLoading;
