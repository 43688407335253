import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Modal } from '@shopify/polaris';

const SEND_REMINDER = gql`
  mutation sendAttendeeReminder($eventId: ID!, $reminderId: ID!) {
    sendAttendeeReminder(eventId: $eventId, reminderId: $reminderId) {
      attendeeReminder {
        id
      }
    }
  }
`;

const SendReminderConfirmModal = ({ event, reminder, onComplete, onClose, flashCallback }) => {
  return (
    <Mutation
      mutation={SEND_REMINDER}
      onError={() => {
        flashCallback(true, 'Unable to send messages', true);
        onClose();
      }}
      onCompleted={() => {
        flashCallback(true, 'Attendee message sending');
        onComplete();
      }}
      refetchQueries={() => ['EventQuery', 'AttendeeReminderQuery']}
    >
      {(sendAttendeeReminder, { loading }) => (
        <Modal
          title='Confirm attendee message send'
          open={true}
          onClose={() => onClose() }
          primaryAction={{
            content: 'Yes, send message',
            disabled: loading,
            loading: loading,
            onAction: () => {
              sendAttendeeReminder({
                variables:{
                  eventId: event.id,
                  reminderId: reminder.id,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: loading,
              onAction: () => onClose(),
            }
          ]}
        >
          <Modal.Section>
            Click the button below to continue sending this message to the selected audience. It may take some time to send, you can monitor the process on this page.
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default SendReminderConfirmModal;
