import React from 'react';
import { Query } from 'react-apollo';
import { Card, ResourceList, TextContainer, Link as ShopifyLink, Stack } from '@shopify/polaris';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import { GET_CHECKIN_SETTINGS } from '../../utils/graphql';
import AddCheckinUserModal from './AddCheckinUserModal.jsx';
import EditCheckinUserModal from './EditCheckinUserModal.jsx';
import { hasPlanFeature } from '../../utils/features';
import { CHECKIN_HOST } from '../../utils/checkinHost.js';
import { getHostFromShopDomain } from '../../utils/auth';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';

const CheckinSettings = () => {
  const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
  const [editingUserId, setEditingUserId] = React.useState(null);

  return (
    <Query
      query={GET_CHECKIN_SETTINGS}
    >
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;
        if (loading) return <InitLoading />;

        const shop = data.currentShop;
        const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
        const users = shop.checkinUsers;
        const events = shop.allEvents;

        const hasCheckinFeature = hasPlanFeature({ shop, feature: 'checkin' });

        let rows = [];

        for (var i in users) {
          let user = users[i];
          rows.push({
            id: user.id,
            email: user.email,
            checkedinAttendeesCount: user.checkedinAttendeesCount,
            createdAt: user.createdAt,
            user,
          });
        }

        return (
          <>
            {!hasCheckinFeature &&
              <UpgradeRequiredBanner message='Upgrade your plan to access the check-in accounts feature.' />
            }
            <Card
              sectioned
              primaryFooterAction={{
                content: 'Add User',
                disabled: !hasCheckinFeature,
                onAction: () => setAddUserModalOpen(true),
              }}
            >
              <TextContainer spacing="loose">
                <p>
                  Check-in user accounts are used to access
                  the <ShopifyLink external url={`${CHECKIN_HOST}${searchParams}`} target="_blank">check-in interface</ShopifyLink> where you
                  can scan tickets an check-in guests at the door for your event. You
                  can use the <ShopifyLink external url={`${CHECKIN_HOST}${searchParams}`} target="_blank">web interface</ShopifyLink> to access
                  your attendee list and manage check ins, or download one of our mobile/tablet apps for
                  full scanning functionality with the camera or a supported external scanning device.
                  Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink> about
                  the check-in interface and check-in account management.
                </p>
                <p>
                  <div style={{width:'150px', display: 'inline-block'}}>
                    <ShopifyLink external url={`https://apps.apple.com/ca/app/evey-events/id1121775093`} target="_blank">
                      <img alt='Download on the Apple App Store' src={document.head.querySelector('[name=img-ios-download-badge]').content} style={{maxWidth:'100%', height: 'auto', margin: '6%', width: '88%'}} />
                    </ShopifyLink>
                  </div>
                  <div style={{width:'150px', display: 'inline-block', position: 'relative', top: '-2px'}}>
                    <ShopifyLink external url={`https://play.google.com/store/apps/details?id=com.eveyevents.checkin`} target="_blank">
                      <img alt='Get it on Google Play' src={document.head.querySelector('[name=img-google-play-badge]').content} style={{maxWidth:'100%', height: 'auto'}} />
                    </ShopifyLink>
                  </div>
                </p>
              </TextContainer>
              <ResourceList
                resourceName={{ singular: 'user', plural: 'users' }}
                items={rows}
                hasMoreItems={false}
                showHeader={true}
                renderItem={ (item) => {
                  const { id, email, checkedinAttendeesCount, createdAt, user } = item;

                  return (
                    <div className="CheckinUserListItem">
                      <ResourceList.Item
                        id={id}
                        onClick={() => hasCheckinFeature ? setEditingUserId(id) : undefined}
                        accessibilityLabel={`Veiw details for check-in user ${email}`}
                      >
                        <Stack vertical={false} distribution='equalSpacing' alignment='center'>
                          <Stack vertical={false} distribution='leading'>
                            <Stack vertical={true}>
                              <TextContainer><strong>{email}</strong></TextContainer>
                              <TextContainer>Created {(new Date(createdAt)).toLocaleString()}</TextContainer>
                            </Stack>
                          </Stack>
                          <Stack vertical={false} distribution='leading' alignment='center'>
                            <Stack vertical={true}>
                              <TextContainer>{checkedinAttendeesCount} attendees checked-in</TextContainer>
                              {user.fullAccess ? (
                                <TextContainer>Access to all events</TextContainer>
                              ) : (
                                <TextContainer>Access to {user.permissions.length} {user.permissions.length == 1 ? 'event' : 'events'}</TextContainer>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </ResourceList.Item>
                      {editingUserId == id &&
                        <EditCheckinUserModal
                          user={user}
                          onClose={() => setEditingUserId(null)}
                          events={events}
                        />
                      }
                    </div>
                  );
                }}
              />
              {addUserModalOpen &&
                <AddCheckinUserModal
                  onClose={() => setAddUserModalOpen(false)}
                  availableUsers={users}
                  events={events}
                />
              }
            </Card>
          </>
        );
      }}
    </Query>
  );
}

export default CheckinSettings;
