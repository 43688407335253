import React from 'react';
import { fetchAccessToken, vendorGet, vendorPost, vendorPut, loginRequest, logout as logoutFromApi, forgotPasswordRequest, resetPasswordRequest, fetchBrand as fetchBrandFromApi } from '../utils/vendor_api';
import { currentShopDomain } from '../utils/auth';

const VendorContext = React.createContext();

const VendorProvider = ({ children }) => {
  const [user, setUser] = React.useState();
  const [brand, setBrand] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [loggingIn, setLoggingIn] = React.useState();

  const externalHost = document.head.querySelector('[name=external-host]').content;

  const isExternal = [
    externalHost,
    'dashboard.eveyevents.com',
    'dash.eveyevents.com',
  ].includes(window.location.hostname);

  const publicPaths = [
    '/vendor/login',
    '/vendor/forgot_password',
    '/vendor/reset_password',
    '/vendor/shops',
  ];

  const isPublicPath = publicPaths.includes(window.location.pathname);

  let shop;
  const shopDomain = currentShopDomain();
  if (!isPublicPath && isExternal && user && shopDomain) {
    shop = user.shops.find((s) => s.shopify_domain === shopDomain);
  }

  const fetchBrand = async () => {
    try {
      if (shopDomain) {
        const brand = await fetchBrandFromApi(shopDomain);
        console.log('[vendor] brand: ', brand);
        setBrand(brand);
      }
    } catch (error) {
      console.log('Error fetching brand: ', error);
    }
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      const user = await vendorGet({ path: '/user' });
      console.log('[vendor] user: ', user);
      setUser(user);
      setLoading(false);
    } catch (error) {
      if (!isPublicPath) {
        if (error.name === 'AuthenticationRequiredError') {
          window.location = '/vendor/login';
        } else {
          setLoading(false);
          throw error;
        }
      } else {
        setLoading(false);
      }
    }
  };

  const updateUser = async ({ name }) => {
    return await vendorPut({
      path: '/user',
      data: {
        name,
      },
    });
  };

  React.useEffect(() => {
    if (isExternal) {
      (async () => {
        await fetchBrand();
        await fetchUser();
      })();
    } else {
      setLoading(false);
    }
  }, []);

  const login = async ({ email, password }) => {
    try {
      setLoggingIn(true);
      await loginRequest({ email, password });
      fetchUser();
    } finally {
      setLoggingIn(false);
    }
  };

  const logout = async () => {
    logoutFromApi();
    window.location = '/vendor/login';
  };

  const forgotPassword = async (email, shop) => {
    return await forgotPasswordRequest(email, shop);
  };

  const resetPassword = async ({ token, password, confirmPassword }) => {
    return await resetPasswordRequest({ token, password, confirmPassword });
  };

  const hasPermission = (shop, name) => {
    if (isExternal) {
      if (user) {
        const shopPermission = user.shops.find((s) => s.shopify_domain === shop);
        if (shopPermission) {
          return (shopPermission.permissions || []).includes(name) || (shopPermission.permissions || []).includes('all');
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <VendorContext.Provider
      value={{
        user,
        brand,
        loggingIn,
        login,
        logout,
        loading,
        forgotPassword,
        resetPassword,
        isExternal,
        fetchAccessToken: async () => await fetchAccessToken(),
        hasPermission,
        updateUser,
        shop,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};

const useVendor = () => React.useContext(VendorContext);

export { VendorProvider, useVendor };
