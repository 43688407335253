import React from 'react';
import { Page, Layout, Card, TextField, FormLayout, Form, Button, Stack, Avatar, Heading, TextStyle, Icon, Spinner } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { FullHeightContainer } from '../shared/containers';
import { useFrame } from '../../contexts/frame';
import { useVendor } from '../../contexts/vendor';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';

const Clickable = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #dfe3e8;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-image: linear-gradient(
      rgba(223, 227, 232, 0.3),
      rgba(223, 227, 232, 0.3)
    );
  }
`;

const ShopSelectPage = () => {
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const { user } = useVendor();

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  React.useEffect(() => {
    if (user) {
      if ((user.shops || []).length <= 1) {
        window.location = `/?shop=${user.shops[0].shopify_domain}`;
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  }, []);

  if (loading) {
    return (
      <FullHeightContainer alignVertically>
        <Spinner size="small" />
      </FullHeightContainer>
    );
  }

  return (
    <Page
      title='Select a shop'
      breadcrumbs={[
        {
          content: 'Login',
          onAction: () => goToPage({ history, path: '/vendor/login' }),
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            {(user?.shops || []).map((shop) => (
              <Clickable
                key={shop.shopify_domain}
                onClick={() => {
                  window.location = `/?shop=${shop.shopify_domain}`;
                }}
              >
                <Card.Section>
                  <Stack alignment="center">
                    <Avatar />
                    <Stack.Item fill>
                      <Heading>{shop.name}</Heading>
                      <p>
                        <TextStyle variation="subdued">{shop.shopify_domain}</TextStyle>
                      </p>
                    </Stack.Item>
                    <Icon color="inkLighter" source={ChevronRightMinor} />
                  </Stack>
                </Card.Section>
              </Clickable>
            ))}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default ShopSelectPage;
