import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, ChoiceList } from '@shopify/polaris';
import styled from '@emotion/styled';
import { useFrame } from '../../../contexts/frame';
import { buildDateWithCompensatedTimezone } from '../../../utils/time';
import Calendar from '../../Schedule/Calendar';
import moment from 'moment';
import { buildCalendarEvents } from '../../../utils/schedule';

const RecurringCalendarModal = ({ shop, event, onClose, onSelect, initialDate, initialScheduledEvent }) => {
  const [selectedDate, setSelectedDate] = React.useState(initialDate);
  const [selectedEvent, setSelectedEvent] = React.useState(initialScheduledEvent ? `${moment(initialScheduledEvent).unix()}` : null);

  const { showToastNotice, showToastError } = useFrame();

  const items = event.scheduleItems.map ((item) => ({
    ...item,
    startDate: item.startDate ? moment(buildDateWithCompensatedTimezone(item.startDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    endDate: item.endDate ? moment(buildDateWithCompensatedTimezone(item.endDate)).format('YYYY-MM-DD') : null,
    scheduleExceptions: item.scheduleExceptions ? item.scheduleExceptions.map((ex) => ({
      ...ex,
      startDate: moment(buildDateWithCompensatedTimezone(ex.startDate)).format('YYYY-MM-DD')
    })) : [],
    resourceId: item.id.toString(),
  }));

  let calendarEvents = buildCalendarEvents({ event, items });

  const availableEvents = selectedDate ? calendarEvents.filter((ev) => moment(ev.start).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) : [];

  return (
    <Modal
      open={true}
      onClose={onClose}
      title='Select event date and time'
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        }
      ]}
      primaryAction={{
        content: 'Select',
        disabled: !selectedDate || !selectedEvent,
        onAction: () => {
          let start;
          if (selectedEvent) {
            start = moment.unix(parseInt(selectedEvent)).format('YYYY-MM-DDTHH:mm:ss');
          }

          if (onSelect) {
            onSelect(start);
          }
        },
      }}
    >
      <Modal.Section>
        <Calendar
          events={calendarEvents}
          selectedDate={selectedDate}
          onSelect={(date) => setSelectedDate(date)}
        />

        {selectedDate && availableEvents.length > 0 &&
          <ChoiceList
            title="Select time"
            choices={availableEvents.map((ev) => ({
              label: ev.start.toLocaleString(undefined, { dateStyle: 'medium', ...(ev.all_day ? {} : { timeStyle: 'short' }) }),
              value: `${moment(ev.start).unix()}`
            }))}
            selected={selectedEvent ? [selectedEvent] : []}
            onChange={(v) => setSelectedEvent(v[0])}
          />
        }
      </Modal.Section>
    </Modal>
  );
};

export default RecurringCalendarModal;
