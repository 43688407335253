import React from 'react';
import { ResourceItem, Stack, TextContainer, TextStyle, Badge } from '@shopify/polaris';
import VendorAccountImage from './VendorAccountImage';
import styled from '@emotion/styled';

const RightContent = styled.div`
  display:flex;
  justify-content: flex-end;
`;

const VendorAccountResourceItem = ({ account, onSelect }) => {
  return (
    <ResourceItem
      id={account.id}
      onClick={() => onSelect(account)}
    >
      <Stack vertical={false} alignment='center'>
        <VendorAccountImage account={account} />
        <Stack vertical={true} distribtion='leading'>
          <TextContainer>{account.name}</TextContainer>
          <TextContainer><TextStyle variation='subdued'>{account.email}</TextStyle></TextContainer>
        </Stack>
        <Stack.Item fill>
          <RightContent>
            <Badge status='info'>{account.allEvents ? 'All events' : `${account.eventIds.length} event${account.eventIds.length == 1 ? '' : 's'}`}</Badge>
          </RightContent>
        </Stack.Item>
      </Stack>
    </ResourceItem>
  );
};

export default VendorAccountResourceItem;
