import React from 'react';
import { Query } from 'react-apollo';
import { Card, ResourceList, Avatar, Link, Stack, Button } from '@shopify/polaris';
import LoadingCard from './LoadingCard';
import { GET_ATTENDEES } from '../../../utils/graphql';
import CardHeader from '../CardHeader';
import Gravatar from 'gravatar';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import EmptyStateCard from './EmptyStateCard';
import DashboardCard from '../DashboardCard';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';

const AttendeeName = styled.div`
  font-weight:bold;
`;

const TicketType = styled.div`
  font-weight:normal;
`;

const ListContainer = styled.div`
  padding:12px;
`;

const FooterContainer = styled.div`
  margin: 20px 27px 0 27px;
`;

const AttendeesCard = ({ shop, event }) => {
  const history = useHistory();

  return (
    <Query
      query={GET_ATTENDEES}
      variables={{
        'eventId': event.id,
        'perPage': 3
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingCard title='Recent attendees' />;
        }

        const attendees = data?.currentShop?.event?.attendees || [];

        if (attendees.length == 0) {
          return (
            <EmptyStateCard
              title='Recent attendees'
              imageUrl={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/attendees.svg`}
              message='Recent attendees will appear here.'
              action={(
                <Button
                  primary
                  onClick={() => goToPage({ history, path: `/events/${event.id}/attendees/new` })}
                >
                  Create an attendee
                </Button>
              )}
              learnMore='https://evey-events.zendesk.com/hc/en-us/'
            />
          );
        }

        return (
          <DashboardCard
            title='Recent attendees'
            headerAction={<Link external onClick={() => goToPage({ history, path: `/events/${event.id}/reminders` })}>Send custom message</Link>}
            primaryAction={{
              content: 'Manage attendees',
              onAction: () => goToPage({ history, path: `/events/${event.id}/attendees` }),
            }}
          >
            <ListContainer>
              <Card>
                <ResourceList
                  resourceName={{ singular: 'attendee', plural: 'attendees' }}
                  items={attendees}
                  loading={loading}
                  showHeader={true}
                  renderItem={(attendee) => (
                    <ResourceList.Item
                      id={attendee.id}
                      accessibilityLabel={`Veiw details for attendee ${attendee.name}`}
                      media={<Avatar customer size="medium" name={attendee.name} source={Gravatar.url(attendee.email, {d: 'mp'})} />}
                      onClick={() => goToPage({ history, path: `/events/${event.id}/attendees/${attendee.id}` })}
                    >
                      <Stack vertical={true} spacing='extraTight'>
                        <AttendeeName>{attendee.name}</AttendeeName>
                        <TicketType>{attendee.ticketTitle}</TicketType>
                      </Stack>
                    </ResourceList.Item>
                  )}
                />
              </Card>
            </ListContainer>
          </DashboardCard>
        );
      }}
    </Query>
  );
};

export default AttendeesCard;
