import React from 'react';
import { Layout, DisplayText } from '@shopify/polaris';
import styled from '@emotion/styled';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 100px 0;

  svg {
    margin-bottom:47px;
  }

  .error-title {
    font-family: SF Pro Display, sans-serif;
    color:#202223;
    font-size:24px;
    line-height:30px;
    font-weight:bold;
  }
`;

const ICON = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.01 12C10.754 12 10.498 11.902 10.304 11.708L4.284 5.70799C3.892 5.31799 3.892 4.68599 4.282 4.29399C4.672 3.90199 5.31 3.90399 5.696 4.29199L11.716 10.292C12.108 10.682 12.108 11.314 11.72 11.706C11.524 11.902 11.266 12 11.01 12Z" fill="#202223"/>
<path d="M8.99 16H0.99C0.438 16 -0.0100002 15.552 -0.0100002 15C-0.0100002 14.448 0.438 14 0.99 14H8.99C9.542 14 9.99 14.448 9.99 15C9.99 15.552 9.542 16 8.99 16Z" fill="#202223"/>
<path d="M15.01 10C14.458 10 14.01 9.552 14.01 9V1C14.01 0.448 14.458 0 15.01 0C15.562 0 16.01 0.448 16.01 1V9C16.01 9.552 15.562 10 15.01 10Z" fill="#202223"/>
<path d="M43.01 42C42.754 42 42.498 41.902 42.304 41.708L36.284 35.708C35.892 35.318 35.892 34.686 36.28 34.294C36.67 33.902 37.308 33.904 37.694 34.292L43.714 40.292C44.106 40.682 44.106 41.314 43.718 41.706C43.524 41.902 43.266 42 43.01 42Z" fill="#202223"/>
<path d="M47.01 32H39.01C38.458 32 38.01 31.552 38.01 31C38.01 30.448 38.458 30 39.01 30H47.01C47.562 30 48.01 30.448 48.01 31C48.01 31.552 47.562 32 47.01 32Z" fill="#202223"/>
<path d="M32.99 46C32.438 46 31.99 45.552 31.99 45V37C31.99 36.448 32.438 36 32.99 36C33.542 36 33.99 36.448 33.99 37V45C33.99 45.552 33.542 46 32.99 46Z" fill="#202223"/>
<path d="M26.272 30.714C23.968 30.714 21.662 29.838 19.908 28.082C19.518 27.692 19.518 27.058 19.908 26.668C20.298 26.278 20.932 26.278 21.322 26.668C24.054 29.4 28.494 29.396 31.222 26.668L43.948 13.94C46.676 11.21 46.676 6.77 43.948 4.04C41.218 1.312 36.78 1.312 34.05 4.04L25.322 12.768C24.932 13.158 24.298 13.158 23.908 12.768C23.518 12.378 23.518 11.744 23.908 11.354L32.636 2.626C36.144 -0.882005 41.854 -0.882005 45.362 2.626C48.872 6.134 48.872 11.846 45.362 15.354L32.636 28.082C30.882 29.836 28.578 30.714 26.272 30.714Z" fill="#202223"/>
<path d="M8.98 48.004C6.676 48.004 4.37 47.128 2.616 45.374C-0.894001 41.866 -0.894001 36.154 2.616 32.646L15.342 19.918C18.846 16.41 24.558 16.406 28.07 19.918C28.46 20.308 28.46 20.942 28.07 21.332C27.68 21.722 27.046 21.722 26.656 21.332C23.926 18.604 19.486 18.606 16.756 21.332L4.032 34.06C1.304 36.79 1.304 41.23 4.032 43.96C6.762 46.688 11.2 46.688 13.93 43.96L22.658 35.232C23.048 34.842 23.682 34.842 24.072 35.232C24.462 35.622 24.462 36.256 24.072 36.646L15.344 45.374C13.59 47.128 11.286 48.004 8.98 48.004Z" fill="#202223"/>
</svg>;

class ErrorTemplate extends React.Component{
  constructor(props) {
    super(props);
  }
  render() {
    return(
      <Layout>
        <Container>
          {ICON}
          <div className='error-title'> We&apos;re sorry, but something went wrong. </div>
        </Container>
      </Layout>
    );
  }
}

export default ErrorTemplate;
