import React from "react";
import { Query } from "react-apollo";
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import {
  Banner,
  Stack,
  TextContainer,
  List,
  Link as ShopifyLink
} from "@shopify/polaris";
import { GET_KLAVIYO_KEY_STATUS } from "../../utils/graphql";

const KlaviyoKeyBanner = () => {
  const history = useHistory();
  return (
    <Query query={GET_KLAVIYO_KEY_STATUS}>
      {({ loading, data }) => {
        if (loading || !data) {
          return "";
        }
        const shop = data.currentShop;

        return (
          shop.shouldUpdateKlaviyoKey && 
          <Banner
            title="Action needed"
            status="critical"
            action={{
                content: 'Update your Klaviyo Api key',
                onClick: () =>  goToPage({
                  history,
                  path: '/settings/integrations/klaviyo',
                })
              }}
          >
            <Stack vertical={true}>
                <TextContainer variant="heading2xl" as="h3">
                    { "Klaviyo updated their API. To ensure your Evey <> Klaviyo email integration continues working, you need to:" }
                </TextContainer>
                <List type="number">
                    <List.Item>{"Generate a Private Klaviyo API key (within Klaviyo), "}<ShopifyLink external url="https://www.klaviyo.com/account#api-keys-tab" target="_blank">here are instructions</ShopifyLink></List.Item>
                    <List.Item>{"Enter your Private Klaviyo API key in Evey (Settings >> Integrations >> Klaviyo Private API Key)"}</List.Item>
                </List>
            </Stack>
          </Banner>
        );
      }}
    </Query>
  );
};

export default KlaviyoKeyBanner;
