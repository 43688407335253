import mixpanel from 'mixpanel-browser';
import { currentShopDomain } from './auth';

const mixpanelToken = document.head.querySelector('[name=mixpanel-token]').content;
if (mixpanelToken) {
  mixpanel.init(mixpanelToken);

  mixpanel.identify(currentShopDomain());
}

export const trackEvent = (name, params) => {
  try {
    if (mixpanelToken) {
      mixpanel.track(name, params || {});
    }
  } catch (error) {
    console.error(error);
  }
};
