import React from 'react';
import { Layout } from '@shopify/polaris';
import CustomersCard from './Tags/CustomersCard';
import EventTagsCard from './Tags/EventTagsCard';

const EventCustomersAndTags = ({ shop, event }) => {
  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Customers"
        description="The app can create a Customers in Shopify for every attendee when a ticket is purchased, even if they were not the purchaser. For example, if someone purchases tickets for a group, they submit informaiton for each attendee or transfer the ticket, this can ensure those customers are created in Shopify as well. The app will only create them if they do not already exist, we will not duplicate customers."
      >
        <CustomersCard shop={shop} event={event} />
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Event Tags"
        description="Event tags can be used to organize your events if you have a lot of them. They are only used in te search/filter on the event listing page, nothing is exposed to your customers."
      >
        <EventTagsCard shop={shop} event={event} />
      </Layout.AnnotatedSection>
    </Layout>
  );
}

export default EventCustomersAndTags;
