import React from 'react';
import { DropZone, Image, Label, Stack, Button, InlineError } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { currentShopDomain } from '../../../utils/auth';
import styled from '@emotion/styled';

const ImageContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin:5px auto;
  max-width:60%;
  padding-bottom:10px;
`;

const LabelContainer = styled.div`
  display:flex;
  justify-content:space-between;
`;

const FilePicker = ({ shop, setting, value, onChange }) => {
  const [uploading, setUploading] = React.useState(false);

  const { showToastError } = useFrame();

  const fetchSignedUploadUrl = async (f) => {
    const params = {
      shop: shop?.domain,
      filename: f.name,
      file_type: f.type,
      file_size: f.size.toString(),
    }

    const response = await fetch('/templates/signed_upload_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const result = await response.json();
    console.log(`[fetchSignedUploadUrl] result: `, result);
    return result;
  };

  const uploadFile = async (f) => {
    const signedResponse = await fetchSignedUploadUrl(f);
    const formData = new FormData();
    for (let i = 0; i < Object.keys(signedResponse.fields).length; i++) {
      const key = Object.keys(signedResponse.fields)[i];
      const value = signedResponse.fields[key];
      formData.append(key, value)
    }
    formData.append('file', f);
    const response = await fetch(signedResponse.url, {
      method: 'POST',
      body: formData
    });
    const result = await response.text();
    console.log(`s3 response: `, result);
    const url = result.match(/<Location>(.+)<\/Location>/m)[1];
    console.log(`url: ${url}`);
    return url;
  };

  if (value && value.length) {
    return (
      <Stack spacing='extraTight' vertical={true}>
        <LabelContainer>
          <Label>{setting.label}</Label>
          <Button
            destructive={true}
            plain
            onClick={() => {
              onChange(null);
            }}
          >
            Remove
          </Button>
        </LabelContainer>
        <ImageContainer>
          <Image
            source={value}
            alt={setting.label}
            width='100px'
          />
        </ImageContainer>
        {setting.info && setting.info.length &&
          <div className="Polaris-Labelled__HelpText" id="TextField1HelpText">
            {setting.info}
          </div>
        }
      </Stack>
    );
  } else {
    return (
      <>
        <DropZone
          label={setting.label}
          allowMultiple={false}
          accept='image/*'
          type='image'
          disabled={uploading}
          onDropAccepted={async (acceptedFiles) => {
            const f = acceptedFiles[0];

            if (setting.max_width || setting.max_height) {
              setUploading(true);
              const img = document.createElement('img');
              img.src = window.URL.createObjectURL(f);
              let poll = setInterval(async () => {
                if (img.naturalWidth) {
                  clearInterval(poll);
                  if (parseInt(img.naturalWidth) > parseInt(setting.max_width) || parseInt(img.naturalHeight) > parseInt(setting.max_height)) {
                    showToastError(`Logo cannot be larger than ${setting.max_width} x ${setting.max_height}`);
                  } else {
                    const url = await uploadFile(f);
                    onChange(url);
                  }
                } else {
                  showToastError('File not recognized as an image');
                }
                setUploading(false);
              }, 10);
            } else {
              setUploading(true);
              const url = await uploadFile(f);
              setUploading(false);
              onChange(url);
            }
          }}
        >
          <DropZone.FileUpload
            actionTitle='Select file'
            actionHint=''
          />
        </DropZone>
        {setting.info && setting.info.length &&
          <div className="Polaris-Labelled__HelpText" id="TextField1HelpText">
            {setting.info}
          </div>
        }
      </>
    );
  }
};

export default FilePicker;
