import React from 'react';
import { Filters, ChoiceList, Stack, FormLayout, TextField, Button } from '@shopify/polaris';
import { buildCalendarEvents } from '../../utils/schedule';
import moment from 'moment';
import { buildDateWithCompensatedTimezone } from '../../utils/time';
import Calendar from '../Schedule/Calendar';

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === '' || value == null;
  }
};

const ScheduledFilter = ({ event, selected, onSelect }) => {
  const [selectedDate, setSelectedDate] = React.useState(null);

  const items = event.scheduleItems.map ((item) => ({
    ...item,
    startDate: item.startDate ? moment(buildDateWithCompensatedTimezone(item.startDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    endDate: item.endDate ? moment(buildDateWithCompensatedTimezone(item.endDate)).format('YYYY-MM-DD') : null,
    scheduleExceptions: item.scheduleExceptions ? item.scheduleExceptions.map((ex) => ({
      ...ex,
      startDate: moment(buildDateWithCompensatedTimezone(ex.startDate)).format('YYYY-MM-DD')
    })) : [],
    resourceId: item.id.toString(),
  }));
  const calendarEvents = buildCalendarEvents({ event, items });

  const availableEvents = selectedDate ? calendarEvents.filter((ev) => moment(ev.start).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')) : [];

  return (
    <Stack vertical={true}>
      <Calendar
        events={calendarEvents}
        selectedDate={selectedDate}
        onSelect={setSelectedDate}
      />
      {selectedDate && availableEvents.length > 0 &&
        <ChoiceList
          title="Select time"
          choices={availableEvents.map((ev) => ({ label: ev.start.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' }), value: moment(ev.start).format('YYYY-MM-DDTHH:mm:ss') }))}
          selected={selected}
          onChange={(v) => onSelect(v)}
          allowMultiple
        />
      }
    </Stack>
  );
}

const AttendeeFilters = ({ event, onUpdate, filters }) => {
  const [localSearchValue, setLocalSearchValue] = React.useState(filters.search || '');
  const [searchValue, setSearchValue] = React.useState(filters.search || '');
  const [ticketType, setTicketType] = React.useState(filters.appliedFilters.find((f) => f.key === 'ticketType')?.value || []);
  const [ticketStatus, setTicketStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'ticketStatus')?.value || []);
  const [checkinStatus, setCheckinStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'checkinStatus')?.value || []);
  const [ticketDeliveryStatus, setTicketDeliveryStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'ticketDeliveryStatus')?.value || []);
  const [purchasedSince, setPurchasedSince] = React.useState(filters.appliedFilters.find((f) => f.key === 'purchasedSince')?.value || []);
  const [purchasedSinceCustomDate, setPurchasedSinceCustomDate] = React.useState();
  const [purchasedSinceCustomTime, setPurchasedSinceCustomTime] = React.useState();
  const [scheduledSelectedEvents, setScheduledSelectedEvents] = React.useState(filters.appliedFilters.find((f) => f.key === 'scheduledOn')?.value || []);
  const [emailStatus, setEmailStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'emailStatus')?.value || []);
  const [phoneStatus, setPhoneStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'phoneStatus')?.value || []);
  const [seatStatus, setSeatStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'seatStatus')?.value || []);
  const [seatingZone, setSeatingZone] = React.useState(filters.appliedFilters.find((f) => f.key === 'seatingZone')?.value || []);
  const [seat, setSeat] = React.useState(filters.appliedFilters.find((f) => f.key === 'seat')?.value || []);
  const [zoomRegistrantId, setZoomRegistrantId] = React.useState(filters.appliedFilters.find((f) => f.key === 'zoomRegistrantId')?.value || []);
  const [zoomRegistrationStatus, setZoomRegistrationStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'zoomRegistrationStatus')?.value || []);
  const [refunded, setRefunded] = React.useState(filters.appliedFilters.find((f) => f.key === 'refunded')?.value || []);

  const [ticketNumberStart, setTicketNumberStart] = React.useState('');
  const [ticketNumberEnd, setTicketNumberEnd] = React.useState('');
  const [localTicketNumberStart, setLocalTicketNumberStart] = React.useState('');
  const [localTicketNumberEnd, setLocalTicketNumberEnd] = React.useState('');

  const seatingZones = event && event.savedLocation && event.savedLocation.seatingChart && event.savedLocation.seatingChart.seatingZones ? event.savedLocation.seatingChart.seatingZones : [];

  React.useEffect(() => {
    let appliedFilters = [];
    if (!isEmpty(ticketType)) {
      const label = ticketType.map((selected) => {
        const ticketType = event && event.tickets.find((t) => t.id == selected);
        return ticketType ? ticketType.title : null;
      }).filter((t) => t != null).join(', ');
      appliedFilters.push({
        key: 'ticketType',
        label: `Ticket type is ${label}`,
        value: ticketType,
        onRemove: () => setTicketType([]),
      });
    }
    if (!isEmpty(ticketStatus)) {
      appliedFilters.push({
        key: 'ticketStatus',
        label: `Ticket is ${ticketStatus}`,
        value: ticketStatus,
        onRemove: () => setTicketStatus([]),
      });
    }
    if (!isEmpty(checkinStatus)) {
      appliedFilters.push({
        key: 'checkinStatus',
        label: `Check-In status: ${checkinStatus}`,
        value: checkinStatus,
        onRemove: () => setCheckinStatus([]),
      });
    }
    if (!isEmpty(ticketDeliveryStatus)) {
      appliedFilters.push({
        key: 'ticketDeliveryStatus',
        label: `Delivery status: ${ticketDeliveryStatus}`,
        value: ticketDeliveryStatus,
        onRemove: () => setTicketDeliveryStatus([]),
      });
    }
    if (!isEmpty(purchasedSince)) {
      if (purchasedSince[0] === 'custom') {
        if (!isEmpty(purchasedSinceCustomDate) && !isEmpty(purchasedSinceCustomTime)) {
          appliedFilters.push({
            key: 'purchasedSince',
            label: `Tickets purchased since: ${purchasedSinceCustomDate} ${purchasedSinceCustomTime} (UTC)`,
            value: `${purchasedSinceCustomDate}T${purchasedSinceCustomTime}:00`,
            onRemove: () => {
              setPurchasedSinceCustomDate(null);
              setPurchasedSinceCustomTime(null);
            },
          });
        }
      } else {
        appliedFilters.push({
          key: 'purchasedSince',
          label: `Tickets purchased since: ${purchasedSince}`,
          value: purchasedSince,
          onRemove: () => setPurchasedSince([]),
        });
      }
    }
    if (!isEmpty(scheduledSelectedEvents)) {
      const label = scheduledSelectedEvents.map((selected) => moment(selected).format('YYYY-DD-MM [at] hh:mmA')).join(', ');
      appliedFilters.push({
        key: 'scheduledOn',
        label: `Schedule for ${label}`,
        value: scheduledSelectedEvents,
        onRemove: () => setScheduledSelectedEvents([]),
      });
    }
    if (!isEmpty(emailStatus)) {
      appliedFilters.push({
        key: 'emailStatus',
        label: `Email ${emailStatus}`,
        value: emailStatus,
        onRemove: () => setEmailStatus([]),
      });
    }
    if (!isEmpty(phoneStatus)) {
      appliedFilters.push({
        key: 'phoneStatus',
        label: `Phone ${phoneStatus}`,
        value: phoneStatus,
        onRemove: () => setPhoneStatus([]),
      });
    }
    if (!isEmpty(seatingZone)) {
      const label = seatingZone.map((selected) => {
        const zone = seatingZones.find((z) => z.id == selected);
        return zone ? zone.name : null;
      }).filter((t) => t != null).join(', ');
      appliedFilters.push({
        key: 'seatingZone',
        label: `Seating zone is ${label}`,
        value: seatingZone,
        onRemove: () => setSeatingZone([]),
      });
    }
    if (!isEmpty(seatStatus)) {
      appliedFilters.push({
        key: 'seatStatus',
        label: `Seat ${seatStatus}`,
        value: seatStatus,
        onRemove: () => setSeatStatus([]),
      });
    }
    if (!isEmpty(seat)) {
      appliedFilters.push({
        key: 'seat',
        label: `Seat is ${seat.join(',')}`,
        value: seat,
        onRemove: () => setSeat([]),
      });
    }
    if (!isEmpty(zoomRegistrantId)) {
      appliedFilters.push({
        key: 'zoomRegistrantId',
        label: `Zoom Registrant is ${zoomRegistrantId}`,
        value: zoomRegistrantId,
        onRemove: () => setZoomRegistrantId([]),
      });
    }
    if (!isEmpty(zoomRegistrationStatus)) {
      appliedFilters.push({
        key: 'zoomRegistrationStatus',
        label: `Zoom registration ${zoomRegistrationStatus}`,
        value: zoomRegistrationStatus,
        onRemove: () => setZoomRegistrationStatus([]),
      });
    }
    if (!isEmpty(ticketNumberStart)) {
      appliedFilters.push({
        key: 'ticketNumberStart',
        label: `Ticket number start is ${ticketNumberStart}`,
        value: ticketNumberStart,
        onRemove: () => setTicketNumberStart(null),
      });
    }
    if (!isEmpty(ticketNumberEnd)) {
      appliedFilters.push({
        key: 'ticketNumberEnd',
        label: `Ticket number end is ${ticketNumberEnd}`,
        value: ticketNumberEnd,
        onRemove: () => setTicketNumberEnd(null),
      });
    }
    if (!isEmpty(refunded)) {
      appliedFilters.push({
        key: 'refunded',
        label: `Order line item is ${refunded}`,
        value: refunded,
        onRemove: () => setRefunded([]),
      });
    }

    onUpdate({
      search: searchValue,
      appliedFilters,
    });
  }, [event, searchValue, ticketType, ticketStatus, checkinStatus, ticketDeliveryStatus, purchasedSince, scheduledSelectedEvents, emailStatus, phoneStatus, seatStatus, seatingZone, zoomRegistrantId, zoomRegistrationStatus, ticketNumberStart, ticketNumberEnd, refunded]);

  const ticketTypeOptions = event ? event.tickets.map((t) => ({ label: t.title, value: t.id })) : [];
  const seatingZoneOptions = seatingZones.map((z) => ({ label: z.name, value: z.id }));

  const filterList = [
    ...(event && event.dateType === 'recurring' ? [{
      key: 'scheduledOn',
      label: 'Tickets scheduled on',
      filter: (
        <ScheduledFilter
          event={event}
          selected={scheduledSelectedEvents}
          onSelect={(scheduleEvents) => setScheduledSelectedEvents(scheduleEvents)}
        />
      ),
      shortcut: false,
    }] : []),
    {
      key: 'ticketType',
      label: 'Ticket type',
      filter: (
        <ChoiceList
          title="Ticket type"
          titleHidden
          choices={ticketTypeOptions}
          selected={ticketType}
          onChange={(v) => setTicketType(v)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'ticketStatus',
      label: 'Ticket status',
      filter: (
        <ChoiceList
          title="Ticket status"
          titleHidden
          choices={[
            {label: 'Active', value: 'Active'},
            {label: 'Cancelled', value: 'Cancelled'},
          ]}
          selected={ticketStatus}
          onChange={(v) => setTicketStatus(v)}
        />
      ),
      shortcut: false,
    },
    {
      key: 'checkinStatus',
      label: 'Check-In status',
      filter: (
        <ChoiceList
          title="Check-In status"
          titleHidden
          choices={[
            {label: 'Checked-In', value: 'Checked-In'},
            {label: 'Not Checked-In', value: 'Not Checked-In'},
          ]}
          selected={checkinStatus || []}
          onChange={(v) => setCheckinStatus(v)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'ticketDeliveryStatus',
      label: 'Ticket delivery status',
      filter: (
        <ChoiceList
          title="Ticket delivery status"
          titleHidden
          choices={[
            {label: 'Sent', value: 'Sent'},
            {label: 'Not Sent', value: 'Not Sent'},
          ]}
          selected={ticketDeliveryStatus || []}
          onChange={(v) => setTicketDeliveryStatus(v)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'purchasedSince',
      label: 'Tickets purchased since',
      filter: (
        <ChoiceList
          title="Tickets purchased since"
          titleHidden
          choices={[
            {label: '1 hour ago', value: '1 hour ago'},
            {label: '12 hours ago', value: '12 hours ago'},
            {label: '24 hours ago', value: '24 hours ago'},
            {label: '2 days ago', value: '2 days ago'},
            {label: '1 week ago', value: '1 week ago'},
          ]}
          selected={purchasedSince || []}
          onChange={(v) => setPurchasedSince(v)}
        />
      ),
      shortcut: false,
    },
    {
      key: 'emailStatus',
      label: 'Email status',
      filter: (
        <ChoiceList
          title="Email status"
          titleHidden
          choices={[
            {label: 'Is empty', value: 'Is empty'},
            {label: 'Is not empty', value: 'Is not empty'},
          ]}
          selected={emailStatus}
          onChange={(v) => setEmailStatus(v)}
        />
      ),
      shortcut: false,
    },
    {
      key: 'phoneStatus',
      label: 'Phone status',
      filter: (
        <ChoiceList
          title="Phone status"
          titleHidden
          choices={[
            {label: 'Is empty', value: 'Is empty'},
            {label: 'Is not empty', value: 'Is not empty'},
          ]}
          selected={phoneStatus}
          onChange={(v) => setPhoneStatus(v)}
        />
      ),
      shortcut: false,
    },
    ...(event && event.hasSeating ? [
      {
        key: 'seatStatus',
        label: 'Seat status',
        filter: (
          <ChoiceList
            title="Seat status"
            titleHidden
            choices={[
              {label: 'Is assigned', value: 'Is assigned'},
              {label: 'Is not assigned', value: 'Is not assigned'},
            ]}
            selected={seatStatus}
            onChange={(v) => setSeatStatus(v)}
          />
        ),
        shortcut: false,
      },
      {
        key: 'seatingZone',
        label: 'Seating zone',
        filter: (
          <ChoiceList
            title="Seating zone"
            titleHidden
            choices={seatingZoneOptions}
            selected={seatingZone}
            onChange={(v) => setSeatingZone(v)}
            allowMultiple
          />
        ),
        shortcut: true,
      },
    ] : []),
    ...(event && event.zoom && event.zoom.active ? [
      {
        key: 'zoomRegistrationStatus',
        label: 'Zoom registration status',
        filter: (
          <ChoiceList
            title="Zoom registration status"
            titleHidden
            choices={[
              {label: 'Is registered', value: 'Is registered'},
              {label: 'Is not registered', value: 'Is not registered'},
            ]}
            selected={zoomRegistrationStatus}
            onChange={(v) => setZoomRegistrationStatus(v)}
          />
        ),
        shortcut: false,
      },
    ] : []),
    {
      key: 'ticketNumber',
      label: 'Ticket number range',
      filter: (
        <FormLayout>
          <TextField
            value={localTicketNumberStart}
            label='Start'
            onChange={setLocalTicketNumberStart}
            type='number'
          />
          <TextField
            value={localTicketNumberEnd}
            label='End'
            onChange={setLocalTicketNumberEnd}
            type='number'
          />
          <Button
            onClick={() => {
              setTicketNumberStart(localTicketNumberStart);
              setTicketNumberEnd(localTicketNumberEnd);
            }}
          >
           Apply
          </Button>
        </FormLayout>
      ),
      shortcut: false,
    },
    {
      key: 'refunded',
      label: 'Refund status',
      filter: (
        <ChoiceList
          title="Refund status"
          titleHidden
          choices={[
            { label: 'Order line item is refunded', value: 'refunded' },
            { label: 'Order line item is not refunded', value: 'not refunded' },
          ]}
          selected={refunded}
          onChange={(v) => setRefunded(v)}
        />
      ),
      shortcut: false,
    },
  ];

  return (
    <div onKeyDown={(ev) => {
      if (ev.keyCode == 13) {
        setSearchValue(localSearchValue);
      }
    }}>
      <Filters
        queryValue={localSearchValue}
        queryPlaceholder='Search attendees'
        filters={filterList}
        appliedFilters={filters.appliedFilters}
        onQueryChange={(v) => setLocalSearchValue(v)}
        onQueryClear={() => {
          setSearchValue('');
          setLocalSearchValue('');
        }}
        onClearAll={() => {
          setSearchValue('');
          setLocalSearchValue('');
          setTicketStatus([]);
          setTicketType([]);
          setCheckinStatus([]);
          setTicketDeliveryStatus([]);
          setPurchasedSince([]);
          setEmailStatus([]);
          setPhoneStatus([]);
          setZoomRegistrantId([]);
          setZoomRegistrationStatus([]);
          setTicketNumberStart(null);
          setTicketNumberEnd(null);
        }}
      />
    </div>
  );
};

export default AttendeeFilters;
