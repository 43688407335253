import React from 'react';
import { Card, TextContainer, Stack, Link } from '@shopify/polaris';
import CardHeader from '../CardHeader';
import styled from '@emotion/styled';
import DashboardCard from '../DashboardCard';

const ContentContainer = styled.div`
  padding-top:12px;
  padding-left:10px;
  padding-right:10px;
`;

const EmptyStateCard = ({ title, imageUrl, message, action, learnMore }) => {
  return (
    <DashboardCard title={title}>
      <ContentContainer>
        <Stack vertical={true} alignment='center' distribution='center' spacing='extraLoose'>
          <img src={imageUrl} style={{ width: '152px' }} />
          <TextContainer>
            {message}
          </TextContainer>
          {action &&
              <Stack vertical={true} alignment='center' distribution='center'>
                <TextContainer>
                  {action}
                </TextContainer>
                {learnMore &&
                  <Link external url={learnMore}>Learn more</Link>
                }
              </Stack>
          }
        </Stack>
      </ContentContainer>
    </DashboardCard>
  );
};

export default EmptyStateCard;
