import React from 'react';
import { Mutation } from 'react-apollo';
import { TextField, Modal, ChoiceList, Banner, FormLayout } from '@shopify/polaris';
import { EDIT_CALENDAR_TEMPLATE } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';

const EditEventsCalendarCodeModal = ({ shop, onClose }) => {
  const [customizeSelected, setCustomizeSelected] = React.useState([shop.eventsCalendarTemplate ? true : false]);
  const [body, setBody] = React.useState(shop.eventsCalendarTemplate || shop.defaultEventsCalendarTemplate);

  const { showToastNotice, showToastError } = useFrame();

  const displayableBody = (customizeSelected[0] ? body : shop.defaultEventsCalendarTemplate) || shop.defaultEventsCalendarTemplate;

  return (
    <Mutation mutation={EDIT_CALENDAR_TEMPLATE}
      onError={(error) => showToastError('Unable to save')}
      onCompleted={(data) => {
        showToastNotice('Template updated');
        onClose();
      }}
      refetchQueries={() => ['StorefrontIntegrationQuery']}
    >
      {(editCalendarTemplate, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          large={true}
          title='Events calendar template code'
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editCalendarTemplate({
                variables:{
                  calendarTemplate: customizeSelected[0] ? body : null,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            }
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <ChoiceList
                choices={[
                  { label: 'Use default page template', value: false },
                  { label: 'Customize page template', value: true }
                ]}
                selected={customizeSelected}
                onChange={setCustomizeSelected}
              />
              <TextField
                label='Page template'
                value={displayableBody}
                onChange={setBody}
                disabled={!(customizeSelected[0])}
                multiline={3}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default EditEventsCalendarCodeModal;
