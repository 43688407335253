import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { FormLayout, Layout, Card, TextField, Banner, PageActions, Select, Checkbox, Button, Collapsible, OptionList, Stack } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import ReactPhoneInput from 'react-phone-input-2';
import {
  DeleteMajor,
  CircleCancelMajor,
  CirclePlusMajor,
} from '@shopify/polaris-icons';
import 'react-phone-input-2/lib/style.css';
import ScheduleModal from './ScheduleModal';
import { buildDateWithCompensatedTimezone, formatDate } from '../../utils/time';
import SeatSelect from './SeatSelect';

export default class AttendeeCreate extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ticketTypeId: '',
    seatId: null,
    createGroup: true,
    sendTicket: null,
    showAdvanced: false,
    bulkCreate: false,
    bulkValue: 1,
    attendeeAttributes: null,
    addAttendeeAttributeClicked: false,
    newAttendeeAttributeName: '',

    scheduleModalOpen: false,
    selectedRecurringEventDate: null,
    selectedRecurringEventScheduleItemId: null,

    errors: {},
    unknownError: false
  };

  EVENT_FIELDS = `
    id
    title
    inventory
    hasCrossVariantInventory
    autoSendEtickets
    attendeeAttributeSpecs {
      name
      label
      type
      options
      selectMultiple
      ticket_types
    }
    tickets {
      id
      title
      groupSize
      visible
      isDeleted
      variant {
        id
        title
      }
      inventory
    }
  `;

  CREATE_ATTENDEE = gql`
    mutation createAttendee(
        $eventId: ID!,
        $firstName: String,
        $lastName: String,
        $email: String,
        $phone: String,
        $ticketTypeId: ID!,
        $createGroup: Boolean,
        $sendTicket: Boolean!,
        $attendeeAttributes: String,
        $bulkValue: Int,
        $recurringEventDate: String,
        $recurringEventScheduleItemId: ID,
        $seatId: ID
    ) {
      createAttendee(
          eventId: $eventId,
          firstName: $firstName,
          lastName: $lastName,
          email: $email,
          phone: $phone,
          ticketTypeId: $ticketTypeId,
          createGroup: $createGroup,
          sendTicket: $sendTicket,
          attendeeAttributes: $attendeeAttributes,
          bulkValue: $bulkValue,
          recurringEventDate: $recurringEventDate,
          recurringEventScheduleItemId: $recurringEventScheduleItemId,
          seatId: $seatId
      ) {
        attendee {
          id
          firstName
          lastName
          email
          phone
          ticket {
            title
            groupSize
            variant {
              id
              title
            }
          }
          attendeeAttributes {
            value
            spec {
              name
              label
              type
              options
            }
          }
        }
      }
    }
  `;

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  handleAttendeeAttributeChange = (field) => {
    return (value) => {
      let attendeeAttributes = this.state.attendeeAttributes;
      attendeeAttributes[field].value = value;
      this.setState({ attendeeAttributes: attendeeAttributes });
    };
  }

  selectedTicket = (event) => {
    for (var i in event.tickets) {
      let ticket = event.tickets[i];
      if (this.state.ticketTypeId.toString() == ticket.id.toString()) {
        return ticket;
      }
    }
    return event.tickets[0];
  }

  sendTicketState = (event) => {
    if (this.state.sendTicket == null) {
      return event.autoSendEtickets;
    } else {
      return this.state.sendTicket;
    }
  }

  initializeAttendeeAttributeState = (event) => {
    if (this.state.attendeeAttributes == null) {
      let attendeeAttributes = {};
      for (var i in event.attendeeAttributeSpecs) {
        let spec = event.attendeeAttributeSpecs[i];
        attendeeAttributes[spec.name] = {
          spec: spec,
          value: ''
        };
      }
      this.setState({ attendeeAttributes: attendeeAttributes });
    }
  }

  attendeeAttributeState = (event, attrName) => {
    this.initializeAttendeeAttributeState(event);
    return this.state.attendeeAttributes[attrName];
  }

  attendeeAttributesState = (event) => {
    this.initializeAttendeeAttributeState(event);
    return this.state.attendeeAttributes;
  }

  removeAttendeeAttribute = (attrName) => {
    return () => {
      let attendeeAttributes = this.state.attendeeAttributes;
      delete attendeeAttributes[attrName];
      this.setState({ attendeeAttributes: attendeeAttributes });
    };
  };

  buildAttendeeAttributeSpecs = (event) => {
    let result = [];
    let attendeeAttributes = this.attendeeAttributesState(event);
    for (var i in attendeeAttributes) {
      var spec = attendeeAttributes[i].spec;

      if (spec.name == 'firstname' || spec.name == 'lastname' || spec.name == 'email') {
        continue;
      }

      const selectedTicket = this.selectedTicket(event);
      if (selectedTicket && spec.ticket_types != null && !spec.ticket_types.some((id) => id == selectedTicket.id)) {
        continue;
      }

      var value = attendeeAttributes[i].value;
      let deleteButton = <div style={{ marginTop:'24px' }}>
        <Button
          key={`attr_delete_${spec.name}`}
          icon={DeleteMajor}
          onClick={this.removeAttendeeAttribute(i)}
        >
        </Button>
      </div>;
      if (spec.type == 'select') {
        if (spec.selectMultiple) {
          let options = [];
          for (let optIndex in spec.options) {
            let opt = spec.options[optIndex];
            options.push({value: opt, label: opt});
          }
          result.push(
            <FormLayout.Group>
              <OptionList
                title={spec.label}
                onChange={this.handleAttendeeAttributeChange(spec.name)}
                options={options}
                selected={value || []}
                allowMultiple
              />
            </FormLayout.Group>
          );
        } else {
          result.push(
            <FormLayout.Group>
              <Select id={spec.name} key={spec.name} value={value} options={spec.options} label={spec.label} onChange={this.handleAttendeeAttributeChange(spec.name)} />
              {deleteButton}
            </FormLayout.Group>
          );
        }
      } else {
        result.push(
          <FormLayout.Group>
            <TextField id={spec.name} key={spec.name} value={value} label={spec.label} onChange={this.handleAttendeeAttributeChange(spec.name)} />
            {deleteButton}
          </FormLayout.Group>
        );
      }
    }
    return result;
  }

  addAttendeeAttribute = (label) => {
    this.initializeAttendeeAttributeState(event);
    let attendeeAttributes = this.state.attendeeAttributes;
    let name = this.slugify(label);
    attendeeAttributes[name] = {
      spec: {
        name: name,
        label: label,
        type: 'text'
      },
      value: ''
    };
    this.setState({ attendeeAttributes: attendeeAttributes, addAttendeeAttributeClicked: false, newAttendeeAttributeName: '' });
  }

  render() {
    const { firstName, lastName, email, phone, ticketTypeId, createGroup, showAdvanced, bulkCreate, bulkValue, seatId } = this.state;

    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          const ticketOptions = [];
          for (var i in event.tickets) {
            let ticket = event.tickets[i];
            ticketOptions.push({
              label: ticket.title,
              value: ticket.id
            });
          }

          let secondaryActions = [
            {
              content: 'Back to Attendees',
              onAction: () => {
                this.props.history.push(`/events/${event.id}/attendees`);
                window.scrollTo({ top: 0 });
              },
              target: 'APP'
            }
          ];

          let actionGroups = undefined;

          return (
            <Mutation mutation={this.CREATE_ATTENDEE}
              onError={(error) => {
                if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
                  this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false });
                } else {
                  this.setState({ unknownError: true });
                  this.reportError(error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              onCompleted={(data) => {
                if (data && data.createAttendee) {
                  if (showAdvanced && bulkCreate) {
                    this.props.flashCallback(true, 'Bulk create in progress');
                    this.props.history.push(`/events/${event.id}/attendees`);
                    window.scrollTo({ top: 0 });
                  } else {
                    this.props.flashCallback(true, 'Attendee successfully created');
                    this.props.history.push(`/events/${event.id}/attendees/${data.createAttendee.attendee.id}/edit`);
                    window.scrollTo({ top: 0 });
                  }
                }
              }}
            >
              {(createAttendee, { loading }) => {
                return (
                  <EventPage
                    shop={shop}
                    event={event}
                    title="New Attendee"
                    flashCallback={this.props.flashCallback}
                    history={this.props.history}
                    primaryAction={{
                      content: 'Save',
                      primary: true,
                      loading:  loading,
                      onAction: () => {
                        document.getElementById('attendee-form').querySelector('[type="submit"]').click();
                      }
                    }}
                    secondaryActions={secondaryActions}
                    actionGroups={actionGroups}
                  >
                    {this.state.unknownError &&
                    <Banner
                      title="There was a problem creating the attendee"
                      status="critical"
                    >
                      <p>
                        Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
                      </p>
                    </Banner>
                    }
                    {this.hasErrors() &&
                    <Banner
                      title="There was a problem creating your attendee"
                      status="critical"
                    >
                      {this.errorMessage()}
                    </Banner>
                    }
                    <Layout>
                      <form id="attendee-form" onSubmit={e => {
                        e.preventDefault();
                        createAttendee({
                          variables:{
                            eventId: event.id,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            email: this.state.email,
                            phone: this.state.phone,
                            ticketTypeId: this.selectedTicket(event).id,
                            createGroup: this.parseBoolean(this.state.createGroup),
                            sendTicket: (!showAdvanced || !bulkCreate) && this.parseBoolean(this.sendTicketState(event)),
                            attendeeAttributes: JSON.stringify(this.state.attendeeAttributes),
                            bulkValue: showAdvanced && bulkCreate ? parseInt(bulkValue) : null,
                            ...(event.dateType === 'recurring' ? {
                              recurringEventDate: this.state.selectedRecurringEventDate,
                              recurringEventScheduleItemId: this.state.selectedRecurringEventScheduleItemId,
                            } : {}),
                            seatId: this.state.seatId,
                          }
                        });
                      }}>
                        <Layout.AnnotatedSection
                          title="New Attendee Information"
                          description="This will create an attendee for this event with the information you enter here."
                        >
                          <Card sectioned>
                            <FormLayout>
                              <TextField key='firstName' id='firstName' value={firstName} label="First Name" placeholder="" onChange={this.handleChange('firstName')} />
                              <TextField key='lastName' id='lastName' value={lastName} label="Last Name" placeholder="" onChange={this.handleChange('lastName')} />
                              <TextField key='email' id='email' value={email} label="Email" placeholder="" onChange={this.handleChange('email')} />
                              <div className="Polaris-Labelled__LabelWrapper">
                                <div className="Polaris-Label">
                                  <label id="TextField2Label" className="Polaris-Label__Text">Phone</label>
                                </div>
                              </div>
                              <div className="Polaris-TextField">
                                <ReactPhoneInput
                                  country='us'
                                  disableAreaCodes={true}
                                  label="Phone"
                                  value={phone || ''}
                                  onChange={this.handleChange('phone')}
                                  inputStyle={{width:'100%'}}
                                />
                              </div>
                              {ticketOptions.length > 1 &&
                                <Select key='ticket' id='ticket' value={ticketTypeId} options={ticketOptions} label="Ticket Type" onChange={(v) => {
                                  this.handleChange('ticketTypeId')(v);
                                  this.setState({
                                    selectedRecurringEventDate: null,
                                    selectedRecurringEventScheduleItemId: null,
                                  });
                                }} />
                              }
                              {ticketOptions.length == 1 &&
                                <input key="ticket" id="ticket" type="hidden" value={ticketOptions[0].value} />
                              }
                              {event.hasSeating &&
                                <SeatSelect
                                  shop={shop}
                                  event={event}
                                  value={seatId}
                                  onChange={this.handleChange('seatId')}
                                  ticketTypeId={this.selectedTicket(event).id}
                                />
                              }
                              {event.dateType === 'recurring' ? (
                                  this.state.selectedRecurringEventDate ? (
                                    <Stack vertical={false} spacing='tight'>
                                      <span style={{fontWeight: 'bold'}}>Event date/time: </span>
                                      <span>{formatDate(buildDateWithCompensatedTimezone(this.state.selectedRecurringEventDate))}</span>
                                      <span style={{ marginLeft: '10px' }}>
                                        <Button
                                          plain
                                          onClick={() => this.setState({ scheduleModalOpen: true })}
                                        >
                                          Update
                                        </Button>
                                      </span>
                                    </Stack>
                                  ) : (
                                    <Button
                                      onClick={() => this.setState({ scheduleModalOpen: true })}
                                    >
                                      Select an event date/time
                                    </Button>
                                  )
                                ) : ('')
                              }
                              {this.selectedTicket(event).groupSize > 1 &&
                                <Checkbox key="createGroup" id="createGroup" value={createGroup} checked={createGroup} label={'This is a group ticket type, check this box to create ' + this.selectedTicket(event).groupSize + ' tickets for this attendee or leave it unchecked for just one.'} onChange={this.handleChange('createGroup')} />
                              }
                              <Checkbox disabled={showAdvanced && bulkCreate} checked={(!showAdvanced || !bulkCreate) && this.sendTicketState(event)} label="Send an email to this user with their ticket." onChange={this.handleChange('sendTicket')} />
                              <Button plain onClick={() => this.setState({ showAdvanced: !showAdvanced })}>{showAdvanced ? 'Hide advanced options' : 'Advanced options'}</Button>
                              <Collapsible open={showAdvanced} id="showAdvancedCollaps">
                                <FormLayout>
                                  <Checkbox checked={bulkCreate} label="Bulk create multiple attendees with this information" onChange={this.handleChange('bulkCreate')} />
                                  {bulkCreate &&
                                    <TextField value={bulkValue} label="Number of tickets" placeholder="1" onChange={this.handleChange('bulkValue')} type='number' min={1} max={100} />
                                  }
                                </FormLayout>
                              </Collapsible>
                            </FormLayout>
                          </Card>
                        </Layout.AnnotatedSection>

                        <Layout.AnnotatedSection
                          title="Custom Information"
                          description="This is extra information that you've asked the customer about each attendee or for your own internal records. The attendee never sees this unless you customize their ticket or email to show it."
                        >
                          <Card sectioned>
                            <FormLayout>
                              {this.buildAttendeeAttributeSpecs(event)}
                              <div>
                                <Button
                                  key="addAttendeeAttributeButton"
                                  id="addAttendeeAttribute"
                                  icon={this.state.addAttendeeAttributeClicked ? CircleCancelMajor : CirclePlusMajor}
                                  plain={true}
                                  onClick={() => this.setState({ addAttendeeAttributeClicked: !this.state.addAttendeeAttributeClicked }) }
                                >
                                  {this.state.addAttendeeAttributeClicked ? 'Cancel new field' : 'Add Field'}
                                </Button>
                                <Collapsible open={this.state.addAttendeeAttributeClicked} id="addAttendeeattributeCollasp">
                                  <div onKeyDown={(event) => {
                                    if (event.keyCode == 13) {
                                      event.preventDefault();
                                      if (this.state.newAttendeeAttributeName.length > 0) {
                                        this.addAttendeeAttribute(this.state.newAttendeeAttributeName);
                                      }
                                    }
                                  }}>
                                    <FormLayout.Group condensed>
                                      <TextField
                                        key="addAttendeeAttributeNameInput"
                                        id='addAttendeeAttributeNameInput'
                                        value={this.state.newAttendeeAttributeName}
                                        label="" placeholder="Field Name"
                                        onChange={this.handleChange('newAttendeeAttributeName')}
                                      />
                                      <Button
                                        key="addAttendeeAttributeSubmit"
                                        id="addAttendeeAttributeSubmit"
                                        disabled={this.state.newAttendeeAttributeName.length == 0}
                                        onClick={() => {
                                          if (this.state.newAttendeeAttributeName.length > 0) {
                                            this.addAttendeeAttribute(this.state.newAttendeeAttributeName);
                                          }
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </FormLayout.Group>
                                  </div>
                                </Collapsible>
                              </div>
                            </FormLayout>
                          </Card>
                        </Layout.AnnotatedSection>


                        <PageActions
                          primaryAction={{
                            content: 'Save',
                            loading: loading,
                            submit: true
                          }}
                          secondaryActions={[
                            {
                              content: 'Back to Attendees',
                              disabled: loading,
                              target: 'APP',
                              onAction: () => {
                                this.props.history.push(`/events/${event.id}/attendees`);
                                window.scrollTo({ top: 0 });
                              }
                            }
                          ]}
                        />
                        {this.state.scheduleModalOpen &&
                          <ScheduleModal
                            event={event}
                            ticket={this.selectedTicket(event)}
                            onClose={() => this.setState({ scheduleModalOpen: false })}
                            saveTitle='Select'
                            onSelect={(start, scheduleItemId) => {
                              this.setState({
                                selectedRecurringEventDate: start,
                                selectedRecurringEventScheduleItemId: scheduleItemId,
                                scheduleModalOpen: false,
                              });
                            }}
                          />
                        }
                      </form>
                    </Layout>
                  </EventPage>
                );
              }}
            </Mutation>
          );
        }}
      </EventQuery>
    );
  }
}

AttendeeCreate.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
