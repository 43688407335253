import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import SettingsPage from './SettingsPage';
import LocaleCard from './LocaleCard';
import InitLoading from '../InitLoading.jsx';
import ErrorPage from '../Error/ErrorPage.jsx';
import { useHistory } from "react-router-dom";
import { Link as ShopifyLink, Stack, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';

const GET_SHOP_LOCALES = gql`
  query ShopLocaleFiles {
    currentShop {
      localeFiles {
        id
        locale
        content
      }
      defaultLocaleFile {
        id
        locale
        content
      }
    }
  }
`;

const TranslationsPage = () => {
  const [selectedLocaleId, setSelectedLocaleId] = React.useState(null);

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <SettingsPage pageTitle='Translations manager' title='translations'>
      <Query
        query={GET_SHOP_LOCALES}
        onCompleted={(data) => {
          const shop = data.currentShop;

          const localeParam = (new URLSearchParams(window.location.search)).get('l');
          if (localeParam && localeParam.length) {
            console.log('Locale file param: ' + localeParam);
            const selectedLocale = shop.localeFiles.find((l) => l.id.toString() == localeParam.toString());
            if (selectedLocale) {
              setSelectedLocaleId(selectedLocale.id);
            } else {
              showToastError('Unable to find locale');
              setSelectedLocaleId(shop.defaultLocaleFile.id);
              goToPage({ history, path: '/settings/translations' });
            }
          } else {
            setSelectedLocaleId(shop.defaultLocaleFile.id); // start with default locale
          }
        }}
      >
        {({ loading, error, data }) => {
          if (error) return <ErrorPage error={error}/>;
          if (loading || !selectedLocaleId) return <InitLoading />;

          const shop = data.currentShop;

          return (
            <div>
              <Stack vertical spacing="loose">
                <TextContainer>Manage your translations for all customizable content such as ticket page, PDF ticket, Apple Wallet Pass, and notifications. Each locale definition is a JSON-formatted list of language specific strings for different customizable parts of the content produced by this app, such as the ticket page, PDF ticket, etc. This is similar to the <ShopifyLink external url="https://shopify.dev/tutorials/develop-theme-localization-manage-locale-files#structural-overview">Locale file</ShopifyLink> feature you have available in your Shopify dashboard. Visit the <ShopifyLink external url={'https://evey-events.zendesk.com/hc/en-us/'}>help page</ShopifyLink> to learn more about this advanced feature.</TextContainer>
                <LocaleCard
                  shop={shop}
                  selectedLocaleId={selectedLocaleId}
                  setSelectedLocaleId={(v) => setSelectedLocaleId(v)}
                />
              </Stack>
            </div>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default TranslationsPage;
