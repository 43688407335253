import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, Link as ShopifyLink, TextField, FormLayout, Select, Stack, Button, Popover, TextContainer } from '@shopify/polaris';
import { client } from '../../renderApp.js';
import SaveBar from '../shared/SaveBar';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import EditLocaleModal from './Locale/EditLocaleModal';
import LocaleEditor from './Locale/LocaleEditor';
import DeleteLocaleModal from './Locale/DeleteLocaleModal';
import { goToPage } from '../../utils/routing';

const SAVE_LOCALE_FILE = gql`
  mutation saveLocaleFile($localeFileId: ID, $locale: String!, $content: String) {
    saveLocaleFile(localeFileId: $localeFileId, locale: $locale, content: $content) {
      localeFile {
        id
        locale
        content
      }
    }
  }
`;


const LocaleCard = ({ shop, selectedLocaleId, setSelectedLocaleId }) => {
  const selectedLocale = shop.localeFiles.find((l) => l.id.toString() == selectedLocaleId.toString());
  const originalContent = selectedLocale && selectedLocale.content || '';

  const [content, setContent] = React.useState(originalContent);
  const [saving, setSaving] = React.useState(false);
  const [showEditLocaleModal, setShowEditLocaleModal] = React.useState(false);
  const [showAddLocaleModal, setShowAddLocaleModal] = React.useState(false);
  const [showDeleteLocaleModal, setShowDeleteLocaleModal] = React.useState(false);

  React.useEffect(() => {
    setContent(originalContent);
  }, [selectedLocaleId]);

  const { showToastNotice, showToastError } = useFrame();
  const history = useHistory();

  const selectedIsDefault = shop.defaultLocaleFile.id == selectedLocaleId;
  const hasChanges = originalContent != content;

  const saveLocaleFile = async () => {
    setSaving(true);
    console.log(`Updating locale '${selectedLocale.locale}'`);
    try {
      await window.client.mutate({
        mutation: SAVE_LOCALE_FILE,
        variables: {
          localeFileId: selectedLocaleId,
          locale: selectedLocale.locale,
          content: content,
        },
        refetchQueries: ['ShopLocaleFiles']
      });
      showToastNotice('Locale saved');
    } catch (err) {
      console.log('Error: ' + JSON.stringify(err));
      if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].errors && err.graphQLErrors[0].errors.length > 0) {
        showToastError(err.graphQLErrors[0].errors[0]);
      } else {
        showToastError('Unable to update locale');
      }
    }
    setSaving(false);
  };

  return (
    <Card sectioned
      primaryFooterAction={{
        content: 'Save',
        disabled: false,
        loading: saving,
        onAction: async () => {
          await saveLocaleFile();
        }
      }}
      secondaryFooterActions={[
        {
          content: 'Delete',
          disabled: selectedIsDefault || saving,
          destructive: true,
          onAction: () => setShowDeleteLocaleModal(true),
        }
      ]}
      actions={[
        {
          content: 'Add locale',
          onAction: () => setShowAddLocaleModal(true),
        }
      ]}
    >
      <div style={{ marginTop: '-38px' }}>
        <Card.Section title="Select locale">
          <Stack spacing='loose' alignment='center'>
            <Select
              label=""
              options={shop.localeFiles.map((localeFile) => ({ label: localeFile.id == shop.defaultLocaleFile.id ? `${localeFile.locale} (default)` : localeFile.locale, value: localeFile.id }))}
              value={selectedLocaleId}
              onChange={(value) => {
                history.push(`/settings/translations?l=${value}`);
                window.scrollTo({ top: 0 });
                setSelectedLocaleId(value);
              }}
            />
            <Button onClick={() => setShowEditLocaleModal(true)} plain>Edit</Button>
          </Stack>
        </Card.Section>
      </div>
      <Card.Section>
        <LocaleEditor
          isDefault={selectedIsDefault}
          content={content}
          setContent={setContent}
        />
      </Card.Section>
      <SaveBar
        show={hasChanges}
        loading={saving}
        onDiscard={() => {
          setContent(originalContent);
        }}
        onSave={async () => {
          await saveLocaleFile();
        }}
      />
      {showEditLocaleModal &&
        <EditLocaleModal
          shop={shop}
          localeFile={selectedLocale}
          onClose={() => setShowEditLocaleModal(false)}
        />
      }
      {showAddLocaleModal &&
        <EditLocaleModal
          shop={shop}
          localeFile={null}
          onClose={(localeFile) => {
            setShowAddLocaleModal(false);
            if (localeFile) {
              goToPage({ history, path: `/settings/translations`, params: { l: localeFile.id } });
              setSelectedLocaleId(localeFile.id);
            }
          }}
        />
      }
      {showDeleteLocaleModal &&
        <DeleteLocaleModal
          shop={shop}
          localeFile={selectedLocale}
          onDismiss={() => setShowDeleteLocaleModal(false)}
          onError={() => {
            setShowDeleteLocaleModal(false);
            showToastError('Delete failed');
          }}
          onComplete={() => {
            setShowDeleteLocaleModal(false);
            showToastNotice('Locale deleted');
            goToPage({ history, path: `/settings/translations` });
            setSelectedLocaleId(shop.defaultLocaleFile.id);
          }}
        />
      }
    </Card>
  );
};

export default LocaleCard;
