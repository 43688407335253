import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';

const DELETE_SEATING_CHART = gql`
  mutation deleteSeatingChart($id: ID!) {
    deleteSeatingChart(id: $id) {
      success
    }
  }
`;

const DeleteSeatingChartModal = ({ seatingChart, onClose, onCompleted }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={DELETE_SEATING_CHART}
      onError={() => showToastError('Failed to delete')}
      onCompleted={(data) => {
        showToastNotice('Seating plan deleted');
        onCompleted();
      }}
      refetchQueries={() => ['GetLocations']}
    >
      {(deleteSeatingChart, { loading: deleting }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Delete seating plan'
          primaryAction={{
            content: 'Yes, delete it',
            loading: deleting,
            destructive: true,
            onAction: () => {
              deleteSeatingChart({
                variables: {
                  id: seatingChart.id,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: deleting,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextContainer>Are you sure you want to delete this seating plan? This is irreversible.</TextContainer>
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default DeleteSeatingChartModal;
