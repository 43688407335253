import React from 'react';
import { Spinner } from '@shopify/polaris';
import { Query, Mutation } from 'react-apollo';
import AppPage from '../AppPage/AppPage.jsx';
import { GET_TEMPLATE_FOR_EDITOR, TEMPLATE_PREVIEW, SAVE_TEMPLATE } from '../../utils/graphql';
import { FullHeightContainer } from '../shared/containers';
import TemplateEditor from './TemplateEditor';
import TemplateEditorNavbar from './TemplateEditorNavbar';
import styled from '@emotion/styled';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const Container = styled.div`
  display:flex;
  flex-direction:column;

  .Polaris-Page {
    padding:0;
    flex-grow:1;

    .Polaris-Page__Content {
      margin-top:0;
    }
  }
`;

const TemplateEditorPage = () => {
  const eventIdParam = (new URLSearchParams(window.location.search)).get('event_id');

  const templateType = (new URLSearchParams(window.location.search)).get('template_type');
  const templateId = (new URLSearchParams(window.location.search)).get('template_id');
  const [currentPreview, setCurrentPreview] = React.useState('');
  const [originalSettings, setOriginalSettings] = React.useState({});
  const [settings, setSettings] = React.useState({});
  const [originalStrings, setOriginalStrings] = React.useState({});
  const [strings, setStrings] = React.useState({});
  const [selectedEventId, setSelectedEventId] = React.useState(eventIdParam);
  const [selectedAttendeeId, setSelectedAttendeeId] = React.useState();
  const [body, setBody] = React.useState('');
  const [originalBody, setOriginalBody] = React.useState('');
  const [useDefault, setUseDefault] = React.useState(false);

  const history = useHistory();

  console.log(`Settings: `, settings);

  const { loadingMarkup, showToastNotice, showToastError, isExternal, navigateTo } = useFrame();

  let backTo = eventIdParam ? `/events/${eventIdParam}/edit?section=tickets` : '/settings/tickets';
  if (['ticket-email', 'multi-ticket-email', 'reminder-email'].includes(templateType)) {
    backTo = eventIdParam ? `/events/${eventIdParam}/edit?section=notifications` : '/settings/notifications';
  }

  React.useEffect(() => {
    document.querySelector('.global-footer').style.display = 'none';
    document.body.style.overflow = 'hidden';

    return () => {
      document.querySelector('.global-footer').style.display = 'block';
      document.body.style.overflow = 'auto';
    }
  }, []);

  return (
    <>
      <Query
        query={GET_TEMPLATE_FOR_EDITOR}
        variables={{
          templateType,
          templateId,
          useDefault,
        }}
        onCompleted={(data) => {
          const shop = data.currentShop;
          const template = data.currentShop.template;

          if (!template) {
            showToastError('Failed to load template');
            navigateTo({ history, path: '/' });
            return;
          }

          setSettings(useDefault ? JSON.parse(template.convertedSettings) : JSON.parse(template.settings || '{}'));
          setOriginalSettings(JSON.parse(template.settings || '{}'));

          setStrings(JSON.parse(shop.defaultLocaleFile.contentWithFallback) || '{}');
          setOriginalStrings(JSON.parse(shop.defaultLocaleFile.contentWithFallback) || '{}');

          setBody((useDefault ? template.defaultBody : template.body) || '');
          setOriginalBody(template.body || '');

          if (selectedEventId == null) {
            setSelectedEventId(template?.events && template?.events[0]?.id);
          }
        }}
        onError={() => {
          showToastError('Failed to load template');
          navigateTo({ history, path: '/' });
        }}
      >
      {({ loading, error, data }) => {

        if (loading && !useDefault) {
          return (
            <FullHeightContainer alignVertically>
              <Spinner size="small" />
            </FullHeightContainer>
          );
        }

        const shop = data?.currentShop;
        const template = data?.currentShop?.template;
        const selectedEvent = selectedEventId && template?.events?.find((e) => e.id == selectedEventId);

        console.log(`selectedEventId: ${selectedEventId}`);
        console.log('selectedEvent: ', selectedEvent);

        if (!template) {
          return (
            <FullHeightContainer alignVertically>
              <Spinner size="small" />
            </FullHeightContainer>
          );
        }

        const isDirty = JSON.stringify(settings) !== JSON.stringify(originalSettings) || JSON.stringify(originalStrings) !== JSON.stringify(strings) || body !== originalBody;

        return (
          <Mutation
            mutation={SAVE_TEMPLATE}
            onError={(error) => showToastError('Failed to save template')}
            onCompleted={(data) => showToastNotice('Saved')}
            refetchQueries={() => []}
          >
            {(saveTemplate, { loading: saving }) => (
              <Container>
                <TemplateEditorNavbar
                  template={template}
                  onSave={() => {
                    saveTemplate({
                      variables: {
                        templateId,
                        templateType,
                        settings: JSON.stringify(settings),
                        strings: JSON.stringify(strings),
                        ...(useDefault ? { body: null } : {}),
                      },
                    });
                    setOriginalSettings(settings);
                    setOriginalStrings(strings);
                    setOriginalBody(body);
                  }}
                  onDiscardChanges={() => {
                    setSettings(originalSettings);
                    setStrings(originalStrings);
                    setBody(originalBody);
                    setUseDefault(false);
                  }}
                  isDirty={isDirty}
                  onExit={() => goToPage({ history, path: backTo })}
                  saving={saving}
                  selectedEventId={selectedEvent?.id}
                  setSelectedEventId={setSelectedEventId}
                  selectedAttendeeId={selectedAttendeeId}
                  setSelectedAttendeeId={setSelectedAttendeeId}
                />
                <AppPage
                  title={isExternal ? '' : 'Template Editor'}
                  fullWidth={true}
                  hideTrialBanner={true}
                >
                  {loading ? (
                    <FullHeightContainer alignVertically>
                      <Spinner size="small" />
                    </FullHeightContainer>
                  ) : (
                    <Query
                      query={TEMPLATE_PREVIEW}
                      variables={{
                        templateType,
                        templateId,
                        settings: JSON.stringify(settings),
                        strings: JSON.stringify(strings),
                        body,
                        eventId: selectedEvent?.id,
                        attendeeId: selectedAttendeeId,
                        extraContext: JSON.stringify({
                          ...(templateType === 'reminder-email' ? {
                            message: 'This is a test.',
                            cta_label: 'View livestream',
                            cta_url: 'https://eveyevents.com'
                          } : {})
                        }),
                      }}
                      onCompleted={(data) => {
                        setCurrentPreview(data.currentShop.template.renderedHtml);
                      }}
                    >
                    {({ loading: previewLoading, error, data }) => {

                      return (
                        <>
                          <TemplateEditor
                            shop={shop}
                            template={template}
                            preview={currentPreview}
                            previewLoading={previewLoading}
                            settings={settings}
                            strings={strings}
                            onChange={(v) => {
                              setSettings(v);
                            }}
                            onStringsChange={(v) => {
                              console.log(`[onStringsChange]: `, v);
                              setStrings(v);
                            }}
                            onUseDefault={() => {
                              setUseDefault(true);
                            }}
                            onDownloadSample={() => {
                            }}
                          />
                        </>
                      );
                    }}
                    </Query>
                  )}
                </AppPage>
              </Container>
            )}
          </Mutation>
        );
      }}
      </Query>
    </>
  );
};

export default TemplateEditorPage;
