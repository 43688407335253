import React from 'react';
import { OptionList, Popover, Label, Stack, Button } from '@shopify/polaris';

const OptionListSelect = ({ label, options, listTitle, sections, allowMultiple, value, onChange, width }) => {
  const [active, setActive] = React.useState(false);

  const selectedOption = value && sections ?
    sections.map((s) => s.options).flat().find((o) => value.some((v) => o.value == v))
      :
    options.find((o) => value.some((v) => o.value == v));

  return (
    <Stack vertical={true} spacing='extraTight'>
      {label && <Label>{label}</Label>}
      <Popover
        active={active}
        activator={
          <div style={{ ...(width ? { width } : {}) }}>
            <Button
              fullWidth={!!width}
              disclosure
              textAlign='left'
              onClick={() => setActive(!active)}
            >
              {selectedOption ? selectedOption.label : ''}
            </Button>
          </div>
        }
        onClose={() => setActive(false)}
      >
        <div style={{ ...(width ? { width } : {}) }}>
          <OptionList
            title={listTitle}
            onChange={(v) => {
              onChange(v);
              setActive(false);
            }}
            options={sections ? undefined : options}
            sections={sections}
            selected={value}
            allowMultiple={allowMultiple}
          />
        </div>
      </Popover>
    </Stack>
  );
};

export default OptionListSelect;
