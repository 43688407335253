import React from 'react';
import styled from '@emotion/styled';
import { buildDateWithCompensatedTimezone } from '../../utils/time';
import moment from 'moment';

const CalendarContainer = styled.div`
  max-width:300px;
  width:100%;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
`;

const CalendarCard = styled.div`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

  table {
    border: 1px solid #dee2e6;
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    background-color: transparent;
    border-collapse: collapse;

    td.current-day {
      background-color: #17a2b8;
    }

    td.has-events {
      cursor:pointer;
    }

    td.selected {
      background-color: #EFEFEF;
    }
  }
  table thead th {
    text-align: inherit;
    position:relative;
    // padding: .75rem;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    vertical-align: bottom;
    border-bottom-width: 2px;
    font-size:0.7em;
    padding:0;
    cursor:default;
  }
  table thead th:after {
    content: '';
    display:block;
    margin-top:100%;
  }
  table thead th .cell-content {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    text-align:center;
    padding-top:3px;
  }
  table td {
    position:relative;
    // padding: .75rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
    padding:0;
    font-size:0.7em;
    cursor:default;

    .cell-content {
      display:flex;
      flex-direction: column;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      text-align:center;
      padding-top:3px;
    }

    .cell-date {
      font-size:inherit;
    }
    .markers {
      font-size:25px;
      margin-top:-10px;
    }
  }
  table td:after {
    content: '';
    display:block;
    margin-top:100%;
  }
  tr:first-child th:after, tr:first-child td:after {
    position:relative;
    border-bottom:none;
  }
`;

const CalendarCardHeader = styled.h3`
  display: flex;
  justify-content: space-between;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  align-items:center;
  width:100%;
  background-color: rgba(0,0,0,.03);
  padding: 5px 10px;;
  margin-bottom: 0;
  margin-top: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);

  .previous,.next {
    cursor:pointer;
    font-family: inherit;
  }

  .content {
    font-size: 1rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
`;

const Calendar = ({ onSelect, events, selectedDate }) => {
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());

  const monthYear = new Date(year, month, 1, 0, 0, 0, 0);
  const firstDay = (new Date(year, month)).getDay();
  const daysInMonth = 32 - new Date(year, month, 32).getDate();
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

  const rows = [0, 1, 2, 3, 4, 5];
  const columns = [0, 1, 2, 3, 4, 5, 6];

  const next = () => {
    if (month === 11) {
      setYear(year + 1);
    }
    setMonth((month + 1) % 12);
  }

  const previous = async () => {
      if (month === 0) {
        setYear(year - 1);
      }
      setMonth(month === 0 ? 11 : (month-1));
  }

  let date = 1;
  const calendarBody = rows.map((row) => (
    <tr key={`${row}`}>
      {columns.map((column) => {
        let cell;
        const today = new Date();
        const dateObject = new Date(year, month, date);
        const dateString = moment(dateObject).format('YYYY-MM-DD');
        const eventsOnDay = Array.from(events).filter((item) => moment(new Date(item.start)).format('YYYY-MM-DD') === dateString);

        if (row === 0 && column < firstDay) {
          cell = <td key={`${row}-${column}`}><div className="cell-content"></div></td>;
        } else if (date > daysInMonth) {
          cell = <td key={`${row}-${column}`}></td>;;
        } else {
          let classes = [];
          if (eventsOnDay.length) {
            classes.push('has-events');
          }
          if (date === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
            classes.push('current-day');
          }
          if (selectedDate && moment(selectedDate).format('YYYY-MM-DD') === dateString) {
            classes.push('selected');
          }
          cell = (
            <td className={classes.join(' ')} key={`${row}-${column}`} onClick={() => onSelect(dateObject)}>
              <div className="cell-content">
                <div className='cell-date'>{date}</div>
                {eventsOnDay.length ? (
                  <div className='markers'>
                    {eventsOnDay.slice(0, 3).map(() => `•`).join('')}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </td>
          );
          date++;
        }
        return cell;
      })}
    </tr>
  ));

  return (
    <CalendarContainer>
      <CalendarCard>
        <CalendarCardHeader>
          <div className="previous" onClick={() => previous()}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="chevron-left"><g id="arrow-down"><path id="Path 3" d="M14 6L8 12L14 18" stroke="black" stroke-linecap="round"/></g></g></svg></div>
          <div className="content">{moment(monthYear).format('MMM YYYY')}</div>
          <div className="next" onClick={() => next()}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="chevron-right"><g id="arrow-down"><path id="Path 3" d="M10 18L16 12L10 6" stroke="black" stroke-linecap="round"/></g></g></svg></div>
        </CalendarCardHeader>
        <table>
            <thead>
            <tr>
                <th><div className="cell-content">Sun</div></th>
                <th><div className="cell-content">Mon</div></th>
                <th><div className="cell-content">Tue</div></th>
                <th><div className="cell-content">Wed</div></th>
                <th><div className="cell-content">Thu</div></th>
                <th><div className="cell-content">Fri</div></th>
                <th><div className="cell-content">Sat</div></th>
            </tr>
            </thead>

            <tbody className="calendar-body">
              {calendarBody}
            </tbody>
        </table>
      </CalendarCard>
    </CalendarContainer>
  );
};

export default Calendar;
