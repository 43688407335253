import React from 'react';
import { Card, Badge, Link as ShopifyLink, DataTable, ExceptionList, Tooltip } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { useHistory, useLocation } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { getHostFromShopDomain } from '../../utils/auth';

const OrderCard = ({ shop, order }) => {

  const { showToastNotice, showToastError, navigateTo } = useFrame();
  const history = useHistory();
  const location = useLocation();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  const shopifyOrder = order.shopifyOrder;

  return (
    <Card
      title={`Order ${order.orderName}`}
      sectioned
      actions={[{
        content: 'Go to Shopify Order',
        url: `https://${shop.domain}/admin/orders/${order.remoteId}`,
        external: true
      }]}
    >
      <Card.Section title="Summary">
        <ExceptionList items={[
          { description: <div>Order ID: <ShopifyLink external url={`${order.orderUrl}${searchParams}`} target="_blank">{order.remoteId}</ShopifyLink></div> },
          ...(shopifyOrder && !['paid', 'partially_paid', 'refunded', 'partially_refunded'].includes(shopifyOrder.financialStatus) ? [{
            status: 'warning',
            description: `Financial status is ${shopifyOrder.financialStatus}. We do not process an order until it is marked as paid in Shopify.`
          }] : order.orderProcessingEvents.length == 0 ? [{
            status: 'critical',
            description: 'We have not receieved an order notification from Shopify yet. Contact us at support@eveyevents.com if this problem continues.'
          }] : []),
          ...(shopifyOrder && !shopifyOrder.lineItems.some((item) => item.event) ? [{
            status: 'critical',
            description: 'None of the line items are for products that are registered as events in this app.'
          }] : []),
          ...(!shopifyOrder ? [{
            status: 'critical',
            description: <span>The app does not have access to some data in this order due to restrictions from Shopify. Click <ShopifyLink external url={`${order.orderUrl}${searchParams}`} target="_blank">here</ShopifyLink> to see the Shopify Order directly.</span>
          }] : []),
          ...(order.attendees.length > 0 ? [{
            description: <div>There {order.attendees.length == 1 ? 'was' : 'were'} {order.attendees.length} {order.attendees.length == 1 ? 'attendee' : 'attendees'} created for this order</div>
          }] : []),
        ]} />
      </Card.Section>
      <Card.Section title="Line items">
        {shopifyOrder ? (
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'numeric'
            ]}
            headings={[
              'ID',
              'Product / Variant',
              'Event',
              'Ticket Type',
              'Quantity'
            ]}
            rows={shopifyOrder.lineItems.map((lineItem) => ([
              lineItem.id,
              <ShopifyLink external url={`https://${shop.domain}/admin/products/${lineItem.productId}`} target="_blank">{lineItem.name}</ShopifyLink>,
              lineItem.event ? <ShopifyLink onClick={() => navigateTo({ history, path: `/events/${lineItem.event.id}`, external: true, newContext: true })} external>{lineItem.event.title}</ShopifyLink> : <Badge status="attention">Not found</Badge>,
              lineItem.ticket && lineItem.ticket.title.length > 0 ? (lineItem.ticket.isDeleted ? <Badge status="attention">Deleted</Badge> : lineItem.ticket.title) : <Badge status="attention">Not found</Badge>,
              lineItem.quantity,
            ]))}
          />
        ) : (
          <div>
            <p>This data is not available in the app due to access restrictions from Shopify. View the <ShopifyLink external url={`https://${shop.domain}/admin/orders/${order.remoteId}`} target="_blank">Shopify Order</ShopifyLink> in the Shopify Dashboard for more details.</p>
          </div>
        )
        }
      </Card.Section>
      {order.attendees.length > 0 &&
        <Card.Section title="Attendees">
          <DataTable
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              'Ticket',
              'Name',
              'Line Item',
              'Status',
            ]}
            rows={order.attendees.map((attendee) => ([
              <ShopifyLink external onClick={() => navigateTo({ history, path: `/events/${attendee.shopifyEventId}/attendees/${attendee.id}`, external: true, newContext: true })}>{attendee.ticketCode}</ShopifyLink>,
              attendee.name,
              attendee.lineItemId,
              attendee.cancelledAt.length > 0 ? (
                attendee.refundedAt.length > 0 ? (
                  <Badge status='critical'>Cancelled</Badge>
                ) : (
                  <Tooltip content="The ticket was cancelled but no refund was issued on the order">
                    <Badge status='critical'>Cancelled / Not refunded</Badge>
                  </Tooltip>
                )
              ) : (
                attendee.refundedAt.length > 0 ? (
                  <Tooltip content="The line item was refunded but the ticket was not cancelled">
                    <Badge status='warning'>Active / Refunded</Badge>
                  </Tooltip>
                ) : (
                  <Badge status='success'>Active</Badge>
                )
              ),
            ]))}
          />
        </Card.Section>
      }
      {order.orderProcessingEvents.length > 0 &&
        <Card.Section title="Processing log">
          <ExceptionList items={order.orderProcessingEvents.map((ev) => ({
            description: `${(new Date(ev.createdAt)).toLocaleString()}: ${ev.description}`
          }))} />
        </Card.Section>
      }
    </Card>
  );
};

export default OrderCard;
