import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Button, Modal, EmptyState, Heading, List, Select, Stack, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';

const EDIT_CALENDAR_SYNC = gql`
  mutation editCalendarSync($eventId: ID!, $enabled: Boolean!) {
    editCalendarSync(eventId: $eventId, enabled: $enabled) {
      success
    }
  }
`;

const EventCalendarCard = ({ shop, event }) => {
  const [calendarService, setCalendarService] = React.useState('google');

  const { showToastNotice, showToastError } = useFrame();

  const integration = event.calendarSyncIntegration;

  const copyToClipboard = (elementId) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    showToastNotice("Copied to clipboard");
  };

  const UrlField = () => (
    <TextField
      id={`icsUrl`}
      label="Calendar subscription URL"
      labelAction={{
        content: "Copy to clipboard",
        onAction: () => copyToClipboard(`icsUrl`),
      }}
      value={integration.icsUrl || ''}
      readOnly
    />
  );

  return (
    <Mutation mutation={EDIT_CALENDAR_SYNC}
      onError={() => showToastError('Unable to update calendar sync')}
      onCompleted={(data) => showToastNotice('Activated')}
      refetchQueries={() => ['EventQuery']}
      awaitRefetchQueries={true}
    >
      {(editCalendarSync, { loading: saving }) => (
        <Card>
          <Card.Section title="Step 1. Activate secure URL">
            <Button
              outline={true}
              loading={saving}
              destructive={integration.enabled}
              onClick={() => {
                editCalendarSync({
                  variables: {
                    eventId: event.id,
                    enabled: !integration.enabled,
                  },
                });
              }}
            >
              {integration.enabled ? 'De-activate' : 'Activate'}
            </Button>
          </Card.Section>
          {integration.enabled ? (
            <Card.Section title="Step 2. Add to a calendar">
              <FormLayout>
                <Select
                  label=''
                  options={[
                    { value: 'google', label: 'Google calendar' },
                    { value: 'apple', label: 'Apple calendar' },
                    { value: 'other', label: 'Other calendars' },
                  ]}
                  value={calendarService}
                  onChange={setCalendarService}
                />
                {calendarService == 'google' &&
                  <List>
                    <List.Item>Open your Google calendar</List.Item>
                    <List.Item>Under "Other calendars", hit the plus icon button</List.Item>
                    <List.Item>Select the "From URL" option</List.Item>
                    <List.Item>Enter the following URL</List.Item>
                  </List>
                }
                {calendarService == 'apple' &&
                  <List>
                    <List.Item>Open the Apple calendar app</List.Item>
                    <List.Item>Go to File -&gt; New Calendar Subscription</List.Item>
                    <List.Item>Enter the following URL</List.Item>
                  </List>
                }
                {calendarService == 'other' &&
                  <List>
                    <List.Item>Consult your calendar service or application documentation</List.Item>
                    <List.Item>Most calendar services / apps have a subscribe or add calendar action where you can enter a URL to an ICS file</List.Item>
                    <List.Item>Enter the following URL</List.Item>
                  </List>
                }
                <UrlField />
              </FormLayout>
            </Card.Section>
          ) : ('')}
        </Card>
      )}
    </Mutation>
  );
};

export default EventCalendarCard;
