import React from 'react';
import { Thumbnail, Icon } from '@shopify/polaris';
import styled from '@emotion/styled';
import { ChannelsMajor } from '@shopify/polaris-icons';

const DefaultImageContainer = styled.div`
  flex: 0 0 auto;
  color: inherit;
  text-decoration: none;
`;

const DefaultImageInnerContainer = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  background: var(--p-surface, #f9fafb);
`;

const DefaultImageSvg = styled.svg`
  display: block;
  fill: var(--p-icon, #c4cdd5);
  color: transparent;
  width: 2rem;
  height: 2rem;
`;

const SeatingChartImage = ({ seatingChart }) => {
  return (
    <>
      {seatingChart.imageUrl ? (
        <Thumbnail
          source={seatingChart.imageUrl}
          alt={seatingChart.name}
        />
      ) : (
        <DefaultImageContainer>
          <DefaultImageInnerContainer>
            <Icon source={ChannelsMajor} color='base' />
            {/*<DefaultImageSvg viewBox="0 0 20 20">
              <path fill="currentColor" d="M14 9l-5 5-3-2-5 3v4h18v-6z"></path>
              <path d="M19 0H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM8 6c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.654 0 3-1.346 3-3S9.654 4 8 4 5 5.346 5 7s1.346 3 3 3zm-6 8v-2.434l3.972-2.383 2.473 1.65c.398.264.925.21 1.262-.126l4.367-4.367L18 13.48V18H2zM18 2v8.92l-3.375-2.7a.999.999 0 0 0-1.332.073l-4.42 4.42-2.318-1.545a1 1 0 0 0-1.07-.025L2 13.233V2h16z"></path>
            </DefaultImageSvg>*/}
          </DefaultImageInnerContainer>
        </DefaultImageContainer>
      )}
    </>
  );
};

export default SeatingChartImage;
