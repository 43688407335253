import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, withApollo } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import translations from '@shopify/polaris/locales/en.json';
import ErrorPage from 'components/Error/ErrorPage.jsx';
import { BrowserRouter } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
// import { HttpLink } from 'apollo-link-http';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { currentShopDomain, currentHost } from './utils/auth';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { Context as ShopifyContext } from '@shopify/app-bridge-react';
import { FrameProvider as EveyFrameProvider } from './contexts/frame';
import { VendorProvider } from './contexts/vendor';
import '@shopify/polaris/dist/styles.css';

const EveyApolloProvider = ({ children }) => {
  const app = React.useContext(ShopifyContext);

  // getToken from meta tags
  const getToken = () =>
    document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const token = getToken();
  const setTokenForOperation = async operation =>
    operation.setContext({
      headers: {
        'X-CSRF-Token': token,
      },
    });
  // link with token
  const createLinkWithToken = () =>
    new ApolloLink(
      (operation, forward) =>
        new Observable(observer => {
          let handle;
          Promise.resolve(operation)
            .then(setTokenForOperation)
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch(observer.error.bind(observer));
          return () => {
            if (handle) handle.unsubscribe();
          };
        })
    );

  // const createHttpLink = () => new HttpLink({
  //   uri: '/graphql',
  //   credentials: 'include',
  // })

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    }
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      if (networkError.bodyText && networkError.bodyText.indexOf('InvalidAuthenticityToken') != -1) {
        console.log('[Network error] Authenticity token has expired, refreshing page.');
        window.location.reload(false);
      } else if (networkError.bodyText && networkError.bodyText.indexOf('Failed to retrieve a session token') != -1) {
        console.log('[Network error] Session token fetch failed, refreshing page.');
        window.location.reload(false);
      } else {
        console.log(`[Network error]: ${networkError}`);
      }
    }
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache(),
    // link: createUploadLink(),
    link: ApolloLink.from([
      // createErrorLink(),
      createLinkWithToken(),
      errorLink,
      createUploadLink({
        fetch: authenticatedFetch(app),
      }),
      // createHttpLink(),
    ]),
    defaultOptions: defaultOptions
  });

  window.client = client;

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

export default function renderApp(AppComponent) {
  document.addEventListener('DOMContentLoaded', () => {
    const APIToken = document.head.querySelector('[name=api-token]').content;
    const currentShop = currentShopDomain();
    const host = currentHost();

    const AppWithClient = withApollo(AppComponent);

    ReactDOM.render(
      <BrowserRouter>
        <AppProvider i18n={translations}>
          <Provider config={{apiKey: APIToken, host: host, forceRedirect: true}}>
            <EveyApolloProvider>
              <VendorProvider>
                <EveyFrameProvider>
                  <ErrorPage>
                    <AppWithClient />
                  </ErrorPage>
                </EveyFrameProvider>
              </VendorProvider>
            </EveyApolloProvider>
          </Provider>
        </AppProvider>
      </BrowserRouter>,
      document.getElementById('app-content'),
    );
  });
}
