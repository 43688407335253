import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Badge, Stack, Subheading, TextField, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import ColorPicker from '../shared/ColorPicker.jsx';
import SaveBar from '../shared/SaveBar';
import { shallowEqual } from '../../utils/compare';
import StorefrontScriptCard from './StorefrontScriptCard';
import styled from '@emotion/styled';

const SubtitleContents = styled.div`
  padding: 20px;
`;

const TEXT = [
  {
    handle: 'service_fee',
    label: 'Service fee label',
    defaultValue: '+ %{fee} ticket fee',
    helpText: 'Text, including fee, displayed next to the price on the product page. %{fee} is replaced with the fee value',
  },
  {
    handle: 'service_fee_fee_property_label',
    label: 'Service fee line item property label',
    defaultValue: '',
    placeholder: '_service_fee',
    helpText: 'Set this to any value to include a line item property that can be used in order receipt emails, invoices, etc. A property that starts with an underscore (_) will be hidden by Shopify during checkout.',
  },
  {
    handle: 'service_fee_price_without_fee_property_label',
    label: 'Service fee line item property label for price without the fee included',
    defaultValue: '',
    placeholder: '_price_without_fee',
    helpText: 'Set this to any value to include a line item property that can be used in order receipt emails, invoices, etc. A property that starts with an underscore (_) will be hidden by Shopify during checkout.',
  },
]

const TicketFeesCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [strings, setStrings] = React.useState(originalStrings);

  const { showToastNotice, showToastError } = useFrame();

  const defaultLocale = shop.defaultLocaleFile.locale;
  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config) || JSON.stringify(originalStrings) != JSON.stringify(strings);

  return (
    <>
      <SubtitleContents>
        <TextContainer>
          <p>The storefront ticket fee integration allows you to show service fees for ticket types on events. The app will show a service fee next to the price on the product page.</p>
          <p>Learn more about service fees <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">in our help center</ShopifyLink> or see it in action on our <ShopifyLink external url='https://eveyevents.myshopify.com/products/awesome-event'>demo shop</ShopifyLink>.</p>
        </TextContainer>
      </SubtitleContents>
      <StorefrontScriptCard shop={shop} />
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update customizations')}
        onCompleted={() => showToastNotice('Customizations updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Card sectioned
            title='Customize'
            actions={[
              {
                content: 'Reset to default',
                onAction: () => {
                  let newConfig = {};

                  let newStrings = {};
                  TEXT.map((string) => {
                    newStrings[string.handle] = string.defaultValue;
                  });
                  setConfig(newConfig);
                  setStrings(newStrings);
                }
              }
            ]}
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !hasChanges,
              onAction: () => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }
            }}
          >
            <Stack distribution="fillEvenly" spacing="extraLoose">
              <Stack vertical spacing='loose'>
                <Subheading>PREFERENCES</Subheading>
                <Checkbox
                  checked={config.ticket_fee_enabled !== false}
                  label='Enable service fee on the product page for events'
                  onChange={(v) => setConfig({ ...config, ticket_fee_enabled: v })}
                  helpText='This is only applicable if you have service fees set on ticket types.'
                />
                <TextField
                  label='Custom CSS'
                  type='text'
                  multiline={true}
                  value={config.ticket_fee_custom_css}
                  onChange={(v) => setConfig({ ...config, ticket_fee_custom_css: v })}
                  helpText='CSS that will be included in the container element of this widget. Prefix with the .evey-ticket-fee selector to restrict the scope to only the fee element.'
                />
                <Checkbox
                  checked={config.ticket_fee_money_format === 'currency'}
                  label='Include currency code after price and fee value.'
                  onChange={(v) => setConfig({ ...config, ticket_fee_money_format: v ? 'currency' : 'no-currency' })}
                />
                <Checkbox
                  checked={!!config.disable_fee_display}
                  label='Do not display the fee on the product page'
                  onChange={(v) => setConfig({ ...config, disable_fee_display: v })}
                />
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>TEXT (for default language: {defaultLocale})</Subheading>
                {TEXT.map((text) =>
                  <TextField
                    key={text.handle}
                    label={text.label}
                    value={strings[text.handle] || text.defaultValue}
                    helpText={text.helpText}
                    onChange={(v) => setStrings({ ...strings, [text.handle]: v })}
                    placeholder={text.placeholder || ''}
                  />
                )}
              </Stack>
            </Stack>
            <SaveBar
              show={hasChanges}
              loading={saving}
              onDiscard={() => {
                setConfig(originalConfig);
                setStrings(originalStrings);
              }}
              onSave={() => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }}
            />
          </Card>
        )}
      </Mutation>
    </>
  );
};

export default TicketFeesCard;
