import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import PropTypes from 'prop-types';
import { Link as ShopifyLink, Layout, Banner } from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import ReminderPreviewCard from './ReminderPreviewCard.jsx';
import ReminderEditCard from './ReminderEditCard.jsx';
import Liquid from 'liquidjs/dist/liquid';
import { buildPreviewData } from '../../utils/preview';
import { getHostFromShopDomain } from '../../utils/auth';
import SendReminderConfirmModal from './SendReminderConfirmModal.jsx';
import SendingBanner from './SendingBanner.jsx';
import CompletedBanner from './CompletedBanner.jsx';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export default class ReminderPage extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    subject: null,
    message: null,
    ctaLabel: null,
    ctaUrl: null,

    subjectError: false,
    messageError: false,
    ctaLabelError: false,
    ctaUrlError: false,

    renderedSubject: '',
    renderedMessage: '',
    renderedPlainTextMessage: '',
    renderedCtaLabel: '',
    renderedCtaUrl: '',

    sendReminderModalOpen: false,
    renderCommunicationType: 'email',

    typingTimeout: 0,
  }

  liquidEngine = new Liquid();

  BASE_EVENT_FIELDS = `
    email {
      id
      subject
      body
      sendAsHtml

      reminderSubject
      reminderBody
      reminderSendAsHtml

      hasMultipleEmail
      multiTicketSubject
      multiTicketBody
      multiTicketSendAsHtml
      multiTicketThreshold

      smsBody
      reminderSmsBody

      defaultReminderSubject
      defaultReminderBody
      defaultReminderSmsBody

      config
    }
    ticketPage {
      sampleUrl
    }
    tickets {
      id
      title
    }
    scheduleItems {
      id
      name
      startDate
      endDate
      allDay
      startTime
      duration
      period
      periodNumber
      scheduleExceptions {
        startDate
        startTime
      }
      ticketTypes
    }
    zoom {
      id
      active
    }
    organizer {
      name
      imageUrl
    }
  `;

  EDIT_REMINDER_EVENT_FIELDS = `
    attendeeReminder(id: $reminderId) {
      id
      name
      subject
      sending
      sentAt
      createdAt
      message
      ctaLabel
      ctaUrl
      search
      filters
      totalCount
      processedCount
      sentCount
      errorEvents
      sendUnique
      schedule {
        action
        dateType
        relativeMinutes
        customDate
        params
        triggeredAt
      }
      scheduledDate
    }
    attendeeReminders {
      id
      name
      sentAt
    }
    ${this.BASE_EVENT_FIELDS}
  `;

  NEW_REMINDER_EVENT_FIELDS = `
    attendeeReminders {
      id
      name
      sentAt
    }
    ${this.BASE_EVENT_FIELDS}
  `;

  handleChange = (shop, event) => (field) => {
    return async (newValue) => {
      const config = JSON.parse(event.email.config);
      const baseData = {
        ...buildPreviewData({ shop, event, config }),
        communication_type: this.state.renderCommunicationType,
      };

      this.setState({[field]: newValue});

      const body = field == 'message' && this.state.renderCommunicationType === 'sms' ? newValue.trim() : newValue;

      try {
        const result = await this.liquidEngine.parseAndRender(body, baseData);
        if (field == 'message') {
          if (this.state.renderCommunicationType === 'sms') {
            if (this.state.typingTimeout) {
              clearTimeout(this.state.typingTimeout);
            }
            this.setState({
              typingTimeout: setTimeout(async () => {
                const html2textResponse = await fetch(`/app/api/html2text`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                  },
                  body: JSON.stringify({
                    html: result,
                  }),
                });
                const html2textResult = await html2textResponse.json();
                this.setState({ renderedPlainTextMessage: html2textResult.text, renderedMessage: result.trim(), messageError: false });
              }, 500)
            });
          } else {
            this.setState({ renderedMessage: result.trim(), messageError: false });
          }
        } else if (field == 'subject') {
          this.setState({ renderedSubject: result.trim(), subjectError: false });
        } else if (field == 'ctaLabel') {
          this.setState({ renderedCtaLabel: result.trim(), ctaLabelError: false });
        } if (field == 'ctaUrl') {
          this.setState({ renderedCtaUrl: result.trim(), ctaUrlError: false });
        }
      } catch (error) {
        if (error.name === 'ParseError') {
          this.setState({ [`${field}Error`]: error.message });
        } else {
          throw error;
        }
      }
    };
  };

  render() {
    const reminderId = this.props.match.params.reminderId;
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={reminderId ? this.EDIT_REMINDER_EVENT_FIELDS : this.NEW_REMINDER_EVENT_FIELDS}
        extraVariables={reminderId ? {'reminderId': reminderId} : {}}
        queryDeclaration={reminderId ? 'AttendeeReminderQuery($eventId: ID!, $reminderId: ID!)' : undefined}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
        onCompleted={(data) => {
          const shop = data.currentShop;
          const event = data.currentShop.event;
          if (reminderId) {
            const reminder = event.attendeeReminder;
            this.handleChange(shop, event)('subject')(reminder.subject || event.email.defaultReminderSubject);
            this.handleChange(shop, event)('message')(reminder.message);
            this.handleChange(shop, event)('ctaLabel')(reminder.ctaLabel);
            this.handleChange(shop, event)('ctaUrl')(reminder.ctaUrl);
          } else {
            this.handleChange(shop, event)('subject')(null || event.email.defaultReminderSubject);
            this.handleChange(shop, event)('message')('');
            this.handleChange(shop, event)('ctaLabel')('');
            this.handleChange(shop, event)('ctaUrl')('');
          }
        }}
      >
        {({ shop, event }) => {
          let { renderedSubject, renderedMessage, renderedPlainTextMessage, renderedCtaLabel, renderedCtaUrl, sendReminderModalOpen } = this.state;

          const reminder = event.attendeeReminder;

          const canSend = reminder && !reminder.sentAt && !reminder.sending;
          const isSending = reminder && !reminder.sentAt && reminder.sending;
          const isComplete = reminder && reminder.sentAt;

          const location = useLocation();
          const searchParams = "&host=" + getHostFromShopDomain(shop?.domain);

          return (
            <EventPage
              shop={shop}
              event={event}
              title="Attendee message"
              pageTitle={reminder ? `Attendee message: ${reminder.name}` : 'New attendee message'}
              pageSubtitle=<p>Configure the notification details on the right and view the preview on the left. The base template can be updated under the <ShopifyLink external url={`/events/${event.id}/edit?section=notifications${searchParams}`} target="_blank">Notifications</ShopifyLink> section in the Event Settings page.</p>
              flashCallback={this.props.flashCallback}
              history={this.props.history}
              fullWidth={true}
              secondaryActions={[
                {
                  content: 'Back to messages',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/reminders`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
            >
              {reminder &&
                <Layout>
                  <Layout.Section>
                    {canSend &&
                      <div className="ReminderPage__Banner">
                        <Banner
                          status='default'
                          title='Ready to send'
                          action={{
                            content: 'Send to attendees',
                            onAction: () => this.setState({ sendReminderModalOpen: true }),
                          }}
                        >
                          {reminder.schedule ? (
                            `This message is scheduled to send to your attendees on ${dayjs(reminder.scheduledDate).format('LLL')}. Click the button below to begin sending right now and cancel the scheduled delivery.`
                          ) : (
                            'This message is ready to send to your attendees. Click the button below to begin sending.'
                          )}
                        </Banner>
                      </div>
                    }
                    {isSending &&
                      <SendingBanner
                        shop={shop}
                        event={event}
                        reminder={reminder}
                        flashCallback={this.props.flashCallback}
                        history={this.props.history}
                      />
                    }
                    {isComplete &&
                      <CompletedBanner
                        shop={shop}
                        event={event}
                        reminder={reminder}
                        flashCallback={this.props.flashCallback}
                        history={this.props.history}
                      />
                    }
                  </Layout.Section>
                </Layout>
              }
              <Layout>
                <Layout.Section>
                  <ReminderPreviewCard
                    flashCallback={this.props.flashCallback}
                    history={this.props.history}
                    shop={shop}
                    event={event}
                    subject={renderedSubject}
                    message={renderedMessage}
                    plainTextMessage={renderedPlainTextMessage}
                    ctaLabel={renderedCtaLabel}
                    ctaUrl={renderedCtaUrl}
                    onChangeType={async (communicationType) => {
                      this.setState({ renderCommunicationType: communicationType }, () => {
                        this.handleChange(shop, event)('subject')(this.state.subject);
                        this.handleChange(shop, event)('message')(this.state.message);
                        this.handleChange(shop, event)('ctaLabel')(this.state.ctaLabel);
                        this.handleChange(shop, event)('ctaUrl')(this.state.ctaUrl);
                      });
                    }}
                  />
                </Layout.Section>
                <Layout.Section secondary>
                  <ReminderEditCard
                    flashCallback={this.props.flashCallback}
                    history={this.props.history}
                    shop={shop}
                    event={event}
                    reminder={reminder}
                    onChange={this.handleChange(shop, event)}
                    messageError={this.state.messageError}
                    subjectError={this.state.subjectError}
                    ctaLabelError={this.state.ctaLabelError}
                    ctaUrlError={this.state.ctaUrlError}
                  />
                </Layout.Section>
              </Layout>
              {canSend && sendReminderModalOpen &&
                <SendReminderConfirmModal
                  shop={shop}
                  event={event}
                  reminder={reminder}
                  onComplete={() => {
                    this.setState({ sendReminderModalOpen: false });
                  }}
                  onClose={() => this.setState({ sendReminderModalOpen: false })}
                  flashCallback={this.props.flashCallback}
                  history={this.props.history}
                />
              }
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

ReminderPage.propTypes = {
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string,
      reminderId: PropTypes.string,
    })
  })
};
