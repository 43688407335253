import React from "react";
import { Page, Navigation, Banner } from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import {
  RoutePropagator as AppBridgeRoutePropagator,
  ClientRouter,
} from "@shopify/app-bridge-react";
import { useHistory, useLocation } from "react-router-dom";
import TrialBanner from "../Trial/TrialBanner";
import AccessScopeBanner from "../AccessScope/AccessScopeBanner";
import KlaviyoKeyBanner from "../Klaviyo/KlaviyoKeyBanner";
import { useFrame } from "../../contexts/frame";
import ReviewAppBanner from "../ReviewApp/ReviewAppBanner";

const AppPage = ({
  children,
  pageTitle,
  pageSubtitle,
  fullWidth,
  title,
  breadcrumbs,
  primaryAction,
  secondaryActions,
  actionGroups,
  hideTrialBanner,
  hideAccessScopeBanner,
  hideKlaviyoKeyBanner
}) => {
  const history = useHistory();
  const location = useLocation();

  const { isExternal } = useFrame();

  const pageProps = !isExternal
    ? {
        title: pageTitle || null,
        subtitle: pageSubtitle || null,
        fullWidth: fullWidth || false,
      }
    : {
        title: title || null,
        subtitle: pageSubtitle || null,
        fullWidth: fullWidth || false,
        // breadcrumbs: breadcrumbs || [],
        primaryAction,
        secondaryActions: secondaryActions || [],
        actionGroups: actionGroups || [],
      };

  return (
    <Page {...pageProps}>
      {isExternal ? (
        ""
      ) : (
        <>
          <TitleBar
            title={title}
            breadcrumbs={breadcrumbs || []}
            primaryAction={primaryAction}
            secondaryActions={secondaryActions || []}
            actionGroups={actionGroups || []}
          />
        </>
      )}
      {!isExternal && <AppBridgeRoutePropagator location={location} />}
      {!isExternal && <ClientRouter history={history} />}
      {!isExternal && !hideAccessScopeBanner && (
        <AccessScopeBanner backToPath={location.pathname} />
      )}
      {!isExternal && !hideKlaviyoKeyBanner && (
        <KlaviyoKeyBanner />
      )}
      {!isExternal && (
        <ReviewAppBanner />
      )}
      {!isExternal && !hideTrialBanner && (
        <TrialBanner backToPath={location.pathname} />
      )}
      {children}
    </Page>
  );
};

export default AppPage;
