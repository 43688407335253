import React from 'react';
import { Link, ResourceList, Stack, Badge, TextStyle } from '@shopify/polaris';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";
import { useFrame } from '../../contexts/frame';

const EventListResourceItem = ({ event, showStatusBadge }) => {
  const { id, title, tags, location, locationType, savedLocation, dateType, prettyDateRange, subscriptionStatusLabel, number, totalTicketsSold } = event;

  const history = useHistory();
  const { canViewAttendees } = useFrame();

  const statusBadgeType = (status) => {
    if (status == 'trial') {
      return 'info';
    } else if (status == 'active') {
      return 'success';
    } else {
      return 'warning';
    }
  };

  let subtitle;
  let subsubtitle;
  if (dateType === 'one-time' && prettyDateRange) {
    subtitle = prettyDateRange;
    subsubtitle = locationType == 'venue' && (savedLocation ? savedLocation.name : location);
  } else if (dateType === 'recurring') {
    subtitle = 'Recurring event';
    subsubtitle = locationType == 'venue' && (savedLocation ? savedLocation.name : location);
  } else {
    if (event.locationType == 'transit' && event.location && event.destination) {
      subtitle = `${event.location}${event.locationCode ? ` (${event.locationCode})` : ''} to ${event.destination}${event.destinationCode ? ` (${event.destinationCode})` : ''}`;
    } else {
      subtitle = (savedLocation ? savedLocation.name : location);
    }
  }

  return (
    <ResourceList.Item
      id={id}
      onClick={() => goToPage({ history, path: `/events/${id}` })}
      accessibilityLabel={`Veiw details for event ${title}`}
    >
      <Stack vertical={false} distribution='fillEvenly' alignment='center'>
        <Stack vertical={true} distribution='leading' spacing='tight'>
          <Stack vertical={false} spacing='tight'>
            <TextStyle variation='subdued'>#{number}</TextStyle>
            <TextStyle variation='strong'>{title}</TextStyle>
            {showStatusBadge && <Badge status={statusBadgeType(subscriptionStatusLabel)}>{subscriptionStatusLabel}</Badge>}
          </Stack>
          {subtitle && <TextStyle>{subtitle}</TextStyle>}
          {subsubtitle && <TextStyle>{subsubtitle}</TextStyle>}
          {tags && tags.length > 0 &&
            <Stack vertical={false}>
              {(tags || []).map((tag, index) => (
                <Badge key={`${index}`}>{tag}</Badge>
              ))}
            </Stack>
          }
        </Stack>
        {!event?.productId &&
          <Stack vertical={false} distribution='trailing' alignment='center'>
            <Badge status="attention">Shopify product missing</Badge>
          </Stack>
        }
        {canViewAttendees &&
          <Stack vertical={false} distribution='trailing' alignment='center'>
            <Link to={`events/${id}/attendees`}>{totalTicketsSold} {totalTicketsSold === 1 ? 'attendee' : 'attendees'}</Link>
          </Stack>
        }
      </Stack>
    </ResourceList.Item>
  );
};

export default EventListResourceItem;
