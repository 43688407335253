import React from 'react';
import SettingsPage from './SettingsPage';
import CheckinSettings from './CheckinSettings';
import { useHistory } from "react-router-dom";

const CheckinPage = ({ flashCallback }) => {
  const history = useHistory();

  return (
    <SettingsPage pageTitle='Check-In accounts' title='Check-In accounts'>
      <CheckinSettings
        flashCallback={flashCallback}
        history={history}
      />
    </SettingsPage>
  );
};

export default CheckinPage;
