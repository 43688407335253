import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';

const FIX_INVENTORY_MANAGEMENT = gql`
  mutation fixVariantInventoryManagement($eventId: ID!) {
    fixVariantInventoryManagement(eventId: $eventId) {
      success
    }
  }
`;

const FixVariantInventoryManagementModal = ({ event, onClose }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={FIX_INVENTORY_MANAGEMENT}
      onError={() => showToastError('Failed to fix inventory management')}
      onCompleted={(data) => {
        showToastNotice('Variant inventory management fixed');
        onClose();
      }}
      refetchQueries={() => ['EventQuery', 'GetSeatingIssues']}
    >
      {(fixVariantInventoryManagement, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Fix variant inventory management'
          primaryAction={{
            content: 'Continue',
            loading: saving,
            onAction: () => {
              fixVariantInventoryManagement({
                variables: {
                  eventId: event.id,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              Continuing will set all variants for this product in Shopify to not track inventory, allowing this app to manage inventory.
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default FixVariantInventoryManagementModal;
