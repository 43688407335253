import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, FormLayout, Heading, TextContainer, Link as ShopifyLink } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import ModalFooter from '../../shared/ModalFooter.jsx';

const EDIT_SEAT = gql`
  mutation editSeat($seatId: ID!, $label: String!, $capacity: Int) {
    editSeat(seatId: $seatId, label: $label, capacity: $capacity) {
      seat {
        id
      }
    }
  }
`;

const DELETE_SEAT = gql`
  mutation deleteSeat($seatId: ID!) {
    deleteSeat(seatId: $seatId) {
      success
    }
  }
`;

const EditSeatModal = ({ shop, seat, onClose }) => {
  const [label, setLabel] = React.useState(seat.label);
  const [capacity, setCapacity] = React.useState(seat.capacity.toString());

  const { showToastNotice, showToastError } = useFrame();

  const isValid = label && label.length > 0;

  return (
    <Mutation
      mutation={DELETE_SEAT}
      onError={() => showToastError('Failed to delete seat')}
      onCompleted={() => {
        showToastNotice('Seat deleted');
        onClose();
      }}
      refetchQueries={() => ['GetSeats', 'GetSeatingChart']}
    >
      {(deleteSeat, { loading: deleting }) => (
        <Mutation
          mutation={EDIT_SEAT}
          onError={() => showToastError('Failed to update seat')}
          onCompleted={() => {
            showToastNotice('Seat updated');
            onClose();
          }}
          refetchQueries={() => ['GetSeats', 'GetSeatingChart']}
        >
          {(editSeat, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={'Edit seat'}
              footer={
                <ModalFooter
                  saveButtonText="Save"
                  saveButtonAction={() => {
                    editSeat({
                      variables: {
                        seatId: seat.id,
                        label,
                        capacity: capacity && parseInt(capacity) || 1,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={!isValid || deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText={'Delete'}
                  destructiveButtonAction={() => {
                    deleteSeat({
                      variables: {
                        seatId: seat.id,
                      },
                    });
                  }}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />
              }
            >
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label="Label"
                    value={label}
                    placeholder="A1"
                    onChange={setLabel}
                    helpText='Friendly name for the seat that the customer will see'
                  />
                  <TextField
                    type="number"
                    label="Capacity"
                    value={capacity}
                    min={1}
                    placeholder="1"
                    onChange={setCapacity}
                    helpText='Maximum number of tickets that can be purchased for this seat. In most cases, this should be kept at a value of 1'
                  />
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default EditSeatModal;
