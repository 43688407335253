import React from 'react';
import AirbrakeClient from 'airbrake-js';
import dayjs from 'dayjs';

const airbrakeId = document.head.querySelector('[name=airbrake-id]').content;
const airbrakeKey = document.head.querySelector('[name=airbrake-key]').content;

export default class EveyComponent extends React.Component {
  constructor(props) {
    super(props);

    if (this.environment == 'production') {
      this.airbrake = new AirbrakeClient({
        projectId: airbrakeId,
        projectKey: airbrakeKey
      });
    } else {
      this.airbrake = new AirbrakeClient({
        projectId: 1234,
        projectKey: 'test'
      });
    }
  }

  environment = document.head.querySelector('[name=environment]').content;
  airbrake = null;

  reportError = (error, params) => {
    if (this.environment == 'production') {
      this.airbrake.notify({
        error: error,
        params: params || {}
      });
    } else {
      console.log('[Airbrake.nofify]: error, params');
      console.log(error);
      console.log(params);
      console.log(`[Airbrake.nofify]: error: ${error}`);
    }
  }

  handleChange = (field) => {
    return (value) => {
      this.setState({[field]: value});
    };
  };

  moneyFormat = (shop, value) => {
    let amount = parseFloat(value.toString().replace('$', '')).toFixed(2).toString();
    return shop.moneyFormat
      .replace('{{amount}}', amount)
      .replace('{{amount_with_comma_separator}}', amount)
      .replace('{{amount_no_decimals}}', parseInt(amount))
      .replace('{{amount_no_decimals_with_comma_separator}}', parseInt(amount));
  }

  currencySymbolLookup = (currency) => {
    var currencySymbols = {
      'USD': '$', // US Dollar
      'EUR': '€', // Euro
      'CRC': '₡', // Costa Rican Colón
      'GBP': '£', // British Pound Sterling
      'ILS': '₪', // Israeli New Sheqel
      'INR': '₹', // Indian Rupee
      'JPY': '¥', // Japanese Yen
      'KRW': '₩', // South Korean Won
      'NGN': '₦', // Nigerian Naira
      'PHP': '₱', // Philippine Peso
      'PLN': 'zł', // Polish Zloty
      'PYG': '₲', // Paraguayan Guarani
      'THB': '฿', // Thai Baht
      'UAH': '₴', // Ukrainian Hryvnia
      'VND': '₫', // Vietnamese Dong
    };
    return currencySymbols[currency];
  }

  currencySymbolForShop = (shop) => {
    return this.currencySymbolLookup(shop.currency);
  }

  formatDate = (dateString) => {
    let date = new Date(dateString);
    return date.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' });
  }

  parseBoolean = (value) => {
    if (value == 'true' || value == '1' || value == true) {
      return true;
    } else {
      return false;
    }
  }

  convertToDateTime = (t, h) => {
    const yearPart = dayjs(t).get('year');
    const monthPart = (dayjs(t).get('month')+1).toString().padStart(2, '0');
    const dayPart = dayjs(t).get('date').toString().padStart(2, '0');
    const hourPart = parseInt(h.split(':')[0]).toString().padStart(2, '0');
    const minutePart = parseInt(h.split(':')[1]).toString().padStart(2, '0');
    return `${yearPart}-${monthPart}-${dayPart}T${hourPart}:${minutePart}:00[-00:00]`;
  }

  getTimeString = (date) => {
    return date.toTimeString().split(':')[0] + ':' + date.toTimeString().split(':')[1];
  }

  slugify = (text) => {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w-]+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  humanFileSize = (bytes, si) => {
    var thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
  }

  hasErrors = () => {
    if(!this.state.errors) {
      return false;
    }

    if (Array.isArray(this.state.errors)) {
      return this.state.errors.length > 0;
    } else {
      return Object.keys(this.state.errors).length > 0;
    }
  }

  errorMessage = () => {
    let children = [];
    if (this.state.errors) {
      for(var i in this.state.errors) {
        let err = '';
        if (Array.isArray(this.state.errors)) {
          err = this.state.errors[i];
        } else {
          err = `${i} ${this.state.errors[i]}`;
        }
        children.push(<li key={`error_${i}`}>{err}</li>);
      }
      return <ul>{children}</ul>;
    } else {
      return '';
    }
  }
}
