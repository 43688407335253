import React from 'react';
import { Layout, Link, Banner } from '@shopify/polaris';
import PdfTicketEdit from '../PdfTicket/PdfTicketEdit.jsx';
import PassbookTicketEdit from '../PassbookTicket/PassbookTicketEdit.jsx';
import TicketTypesCard from './TicketTypesCard';
import TicketCodeTemplateCard from './Tickets/TicketCodeTemplateCard';
import BarCodeTemplateCard from './Tickets/BarCodeTemplateCard';
import SeatingIssuesBanner from './Seating/SeatingIssuesBanner';
import TemplateCard from '../Template/TemplateCard.js';
import { useFrame } from '../../contexts/frame';
import { getHostFromShopDomain } from '../../utils/auth';

const EventTickets = ({ shop, event, flashCallback, history }) => {
  const { isVendor, canEditTicketTemplates } = useFrame();

  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <Layout>
      {event.hasSeating &&
        <SeatingIssuesBanner eventId={event.id} />
      }
      <Layout.AnnotatedSection
        title="Ticket Types"
        description={isVendor ? (
          <div>
            <p>Ticket types are available on the event page for purchase by your customers, they can choose between the list of ticket types available, for example General Admission, VIP, etc.</p>
            <p>Group ticket types has multiple unique tickets generated for each one sold. Group Size is the number of unique tickets created and sent to the attendee when this ticket is purchased. It has a value of 1 for a normal ticket type.</p>
          </div>
        ) : (
          <div>
            <p>
                    Tickets are variants on your Shopify Product, useful if you have for example a General Admission ticket and a VIP ticket.
                    You can also add/update/remove variants, and change inventory, on your <Link external url={'https://' + shop.domain + '/admin/products/' + event.productId + searchParams}>Shopify Admin Product page</Link> for this event.
            </p>
            <p>
                    Group ticket types has multiple unique tickets generated for each one sold. Group Size is the number of unique tickets created and sent to the attendee when this variant is purchased. It has a value of 1 for a normal ticket.
            </p>
          </div>
        )}
      >
        <TicketTypesCard
          shop={shop}
          event={event}
        />
      </Layout.AnnotatedSection>
        {canEditTicketTemplates &&
        <Layout.AnnotatedSection
          title="Online Ticket Page"
          description=<div>
            <p>
              The online ticket page is the first page your attendees receive a link to after they purchase
              tickets It contains all the event information, their ticket information (such as their name, email address),
              and the QR code associated with their ticket, as well as links to download any other ticket formats
              that you have configured such as PDF ticket, Apple Wallet Pass, and iCalendar. If you have enabled the option
              your attendees can also update their name and email address here to transfer the ticket to someone else.
            </p>
            <p>
              Visit our help center to <Link external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</Link> about customizing
              this ticket.
            </p>
          </div>
        >
          <TemplateCard
            templateId={event.ticketPage.id}
            templateType='ticket-page'
            sharedTemplateType='ticket_page'
            event={event}
            editCodePath={`/events/${event.id}/ticket_page/code`}
            sampleUrl={event.sampleTicketPageUrl}
          />
        </Layout.AnnotatedSection>
      }
      {canEditTicketTemplates &&
        <Layout.AnnotatedSection
          title="PDF Ticket"
          description=<div>
          <p>
            You can customize the PDF ticket appearance for this event by setting a custom logo
            image to include at the top left corner of the PDF and/or some
            notes / instructions text.
          </p>
          <p>
            We do not reduce the file size of your images as we scale them to fit the area they
            belong to. If you provide files that are very large, your customers will have to
            download very large PDF ticket files and some email clients may not accept them as
            attachments.
          </p>
          <p>
            Visit our help center to <Link external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</Link> about customizing
            this ticket.
          </p>
          </div>
        >
          <TemplateCard
            templateId={event.pdfTicket.id}
            templateType='pdf-ticket'
            sharedTemplateType='pdf_ticket'
            event={event}
            editCodePath={`/events/${event.id}/pdf_ticket/code`}
            sampleUrl={`/events/${event.id}/pdf/sample?shop=${shop.domain}&shop_id=${shop.remoteId}`}
          />
        </Layout.AnnotatedSection>
      }
      {canEditTicketTemplates &&
        <Layout.AnnotatedSection
          title="Apple Wallet Pass"
          description=<div>
            <p>You can customize the passbook appearance for this event by setting custom images for three images on the pass itself and the icon used outside the pass. Your images will be scaled to fit the full area, while keeping the aspect ratio and file size. The icon image is only displayed outside of the Passbook app, such as on the lock screen or in the mail app, and is the only image that is required by Apple.</p>
            <p>We do not reduce the file size of your images as we scale them to fit the area they belong to. If you provide files that are very large, your customers will have to download very large passbook files and some email clients may not accept them as attachments.</p>
            <p>See the <Link external url='https://help.passkit.com/passes/designing-and-creating-passes/what-are-the-optimum-image-sizes'>Apple Passbook documentation</Link> for more details.</p>
            <Banner status='info'>This ticket format can only be viewed on Apple devices such as the iPhone and iPad.</Banner>
          </div>
        >
          <TemplateCard
            templateId={event.passbook.id}
            templateType='passbook-ticket'
            sharedTemplateType='passbook_ticket'
            event={event}
            sampleUrl={`/events/${event.id}/passbook/sample?shop=${shop.domain}&shop_id=${shop.remoteId}`}
          />
        </Layout.AnnotatedSection>
      }
      {canEditTicketTemplates &&
        <Layout.AnnotatedSection
          title="Ticket code"
          description=<div>
            <p>The ticket code is the primary and unique way that attendees can identify their ticket. By default it is simply the ticket number with a hash character (#) at the beginning. You can use this to add a more branded code, a standardized code for other services, or any other purpose you want.</p>
          </div>
        >
          <TicketCodeTemplateCard
            shop={shop}
            event={event}
          />
        </Layout.AnnotatedSection>
      }
      {canEditTicketTemplates &&
        <Layout.AnnotatedSection
          title="QR code template"
          description=<div>
            <p>The QR code is included on all ticket formats by default and used by the Evey Check-In Manager app to help you scan and check-in attendees at your event. By default, the value of this code is a unique URL pointing to the check-in interface for each ticket.</p>
            <Banner status='warning'>Changing this template will prevent you from using the Check-in Manager app to scan tickets</Banner>
          </div>
        >
          <BarCodeTemplateCard
            shop={shop}
            event={event}
          />
        </Layout.AnnotatedSection>
      }
    </Layout>
  );
};

export default EventTickets;
