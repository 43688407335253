import React from 'react';
import { Select, Modal, Spinner, Stack, ChoiceList } from '@shopify/polaris';
import { Mutation, Query } from 'react-apollo';
import styled from '@emotion/styled';
import { GET_SHARED_TEMPLATE_OPTIONS, EDIT_SHARED_TEMPLATE } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';

const Container = styled.span`
`;

const SharedTemplateModal = ({ shop, event, template, templateType, onClose, allowGlobal }) => {
  const [selected, setSelected] = React.useState(template.id.toString());
  const [selectedOption, setSelectedOption] = React.useState(allowGlobal ? 'global' : 'custom');
  const [defaultNotificationsTemplate, setDefaultNotificationsTemplate] = React.useState(null);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={EDIT_SHARED_TEMPLATE}
      onError={() => showToastError('Failed to save template')}
      onCompleted={() => {
        showToastNotice('Shared template updated');
        onClose();
      }}
      refetchQueries={() => ['EventQuery', 'GetDefaultNotificationTemplate']}
    >
      {(editSharedTemplate, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Share template'
          primaryAction={{
            content: 'Change template',
            loading: saving,
            onAction: () => {
              editSharedTemplate({
                variables: {
                  eventId: event.id,
                  templateType,
                  templateId: selectedOption == 'global' && templateType == 'ticket_email' ? defaultNotificationsTemplate.id : selectedOption == 'shared' ? selected : null,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: () => {
                onClose();
              },
            },
          ]}
        >
          <Modal.Section>
            <Query
              query={GET_SHARED_TEMPLATE_OPTIONS}
              variables={{ templateType: templateType }}
              onCompleted={(data) => {
                setDefaultNotificationsTemplate(data.currentShop.defaultNotificationsTemplate);
                if (allowGlobal) {
                  setSelectedOption(
                    data.currentShop.defaultNotificationsTemplate.id == template.id ?
                      'global'
                    :
                      (
                        template.sharedEventsCount && template.sharedEventsCount > 0 ? 'shared' : 'custom'
                      )
                  );
                } else {
                  setSelectedOption(template.sharedEventsCount && template.sharedEventsCount > 0 ? 'shared' : 'custom');
                }
              }}
            >
              {({ loading, data }) => {
                if (loading || !data || !data.currentShop || !data.currentShop.sharedTemplateOptions) {
                  return (
                    <Stack distribution='center'>
                      <Spinner size="small" />
                    </Stack>
                  );
                }

                const sortKey = (option) => `${option.count}-${option.label}`;

                const options = JSON.parse(data.currentShop.sharedTemplateOptions).map((option) => ({
                  label: `${option.label} (used for ${option.count+1} event${option.count+1 == 1 ? '' : 's'})`,
                  value: option.id.toString(),
                })).sort((a,b) => (sortKey(a) > sortKey(b)) ? 1 : ((sortKey(a) < sortKey(b)) ? -1 : 0));

                return (
                  <Stack vertical={true}>
                    <ChoiceList
                      title=""
                      titleHidden
                      choices={[
                        ...(allowGlobal ? [{ value: 'global', label: 'Use the shared global template', helpText: 'Use the shop-wide template that applies to all new events. This is recommended unless you require different design/content for each event' }] : []),
                        { value: 'custom', label: 'Use custom template for this event', helpText: 'Configure a custom template that applies to just this event' },
                        { value: 'shared' , label: 'Use a shared template with another event', helpText: 'Select another event to share the template with' },
                      ]}
                      selected={selectedOption}
                      onChange={(v) => setSelectedOption(v[0])}
                    />
                    {selectedOption == 'shared' &&
                      <Select
                        label="Shared event"
                        labelInline
                        options={options}
                        onChange={(v) => setSelected(v)}
                        value={selected}
                      />
                    }
                  </Stack>
                );
              }}
            </Query>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default SharedTemplateModal;
