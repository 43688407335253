import React from 'react';
import { Layout, Spinner } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';
import styled from '@emotion/styled';
import TicketSalesCard from './Cards/TicketSalesCard';
import AttendeesCard from './Cards/AttendeesCard';
import CheckinCard from './Cards/CheckinCard';
import HelpCard from './Cards/HelpCard';
import VirtualEventCard from './Cards/VirtualEventCard';
import RecurringCard from './Cards/RecurringCard';
import { FullHeightContainer } from '../shared/containers';
import { useFrame } from '../../contexts/frame';

const Container = styled.span`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  max-width:1200px;
  margin-left:auto;
  margin-right:auto;

  >.Polaris-Card {
    width:560px;
    min-height:421px;
    margin-top:0;
    margin-right:16px;
    margin-top:16px;

    .Polaris-Card__Section {
      padding-left:0;
      padding-right:0;
    }

    @media (max-width: 768px) {
      margin-right:0;
    }
  }
`;

const DashboardPage = ({ match }) => {
  const { canEditEvents, canViewAttendees, isVendor } = useFrame();
  return (
    <EventQuery
      eventId={match.params.eventId}
      loadingPage={(
        <FullHeightContainer alignVertically>
          <Spinner size="small" />
        </FullHeightContainer>
      )}
    >
      {({ shop, event }) => {
        const cards = [
          ...(event.dateType === 'recurring' ? [<RecurringCard key='recurring' shop={shop} event={event} />] : []),
          <TicketSalesCard key='sales' shop={shop} event={event} />,
          ...(canViewAttendees ? [<AttendeesCard key='attendees' shop={shop} event={event} />] : []),
          ...(
            canEditEvents ? (
              event.locationType === 'online' ?
                [<VirtualEventCard key='checkin' shop={shop} event={event} />]
                  :
                [<CheckinCard key='checkin' shop={shop} event={event} />]
            ) : ([])
          ),
          ...(isVendor ? [] : [<HelpCard key='help' shop={shop} event={event} />]),
        ];

        return (
          <EventPage
            shop={shop}
            event={event}
            title="Dashboard"
            fullWidth={true}
          >
            <Container>
              {cards}
            </Container>
          </EventPage>
        );
      }}
    </EventQuery>
  );
};

export default DashboardPage;
