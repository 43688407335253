import React from 'react';
import { Layout, DisplayText } from '@shopify/polaris';
import EventNotificationSettings from '../Notifications/EventNotificationSettings';

const EventNotifications = ({ eventId }) => {
  return (
    <Layout>
      <EventNotificationSettings eventId={eventId} />
    </Layout>
  );
}

export default EventNotifications;
