import React from 'react';
import { Card, TextContainer, Link as ShopifyLink, ResourceList } from '@shopify/polaris';
import { useLocation } from "react-router-dom";
import EditUserOnEventModal from './EditUserOnEventModal';
import AddUserToEventModal from './AddUserToEventModal';
import { hasPlanFeature } from '../../utils/features';
import { getHostFromShopDomain } from '../../utils/auth';

const CheckinUsersCard = ({ shop, event }) => {
  const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
  const [editingUserId, setEditingUserId] = React.useState(false);
  const location = useLocation(); 
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  const hasCheckinFeature = hasPlanFeature({ shop, feature: 'checkin' });

  let rows = [];

  for (var i in event.checkinUsers) {
    let user = event.checkinUsers[i];
    rows.push({
      id: user.id,
      email: user.email,
      checkedinAttendeesCount: user.checkedinAttendeesCount,
      createdAt: user.createdAt,
      user,
    });
  }

  return (
    <Card
      title="Check-In Users"
      sectioned
      primaryFooterAction={{
        content: 'Add User',
        disabled: !hasCheckinFeature,
        onAction: () => setAddUserModalOpen(true),
      }}
    >
      <TextContainer spacing="loose">
        <p>
          Check-in user accounts are used to access
          the <ShopifyLink external url={`${event.checkinUrl}${searchParams}`} target="_blank">check-in interface</ShopifyLink> where you
          can scan tickets an check-in guests at the door for your event. You
          can use the <ShopifyLink external url={`${event.checkinUrl}${searchParams}`} target="_blank">web interface</ShopifyLink> to access
          your attendee list and manage check ins, or download one of our mobile/tablet apps for
          full scanning functionality.
          Visit our help center to <ShopifyLink external url={`https://evey-events.zendesk.com/hc/en-us`} target="_blank">learn more</ShopifyLink> about
          the check-in interface and check-in account management.
        </p>
        <p>
          <div style={{width:'150px', display: 'inline-block'}}>
          <ShopifyLink external url={`https://apps.apple.com/ca/app/evey-events/id1121775093`} target="_blank">
              <img alt='Download on the Apple App Store' src={document.head.querySelector('[name=img-ios-download-badge]').content} style={{maxWidth:'100%', height: 'auto', margin: '6%', width: '88%'}} />
            </ShopifyLink>
          </div>
          <div style={{width:'150px', display: 'inline-block', position: 'relative', top: '-2px'}}>
            <ShopifyLink external url={`https://play.google.com/store/apps/details?id=com.eveyevents.checkin`} target="_blank"> 
              <img alt='Get it on Google Play' src={document.head.querySelector('[name=img-google-play-badge]').content} style={{maxWidth:'100%', height: 'auto'}} />
            </ShopifyLink>
          </div>
        </p>
      </TextContainer>
      <ResourceList
        resourceName={{ singular: 'user', plural: 'users' }}
        items={rows}
        hasMoreItems={false}
        showHeader={true}
        renderItem={ (item) => {
          const { id, email, checkedinAttendeesCount, createdAt, user } = item;

          return (
            <div className="CheckinUserListItem">
              <ResourceList.Item
                id={id}
                onClick={() => hasCheckinFeature ? setEditingUserId(id) : undefined}
                accessibilityLabel={`Veiw details for check-in user ${email}`}
              >
                <div className="CheckinUserListItem__Main">
                  <div className="CheckinUserListItem__MainInfo">
                    <div className="CheckinUserListItem__TitleLine">
                      <h3 className="CheckinUserListItem__Email">{email}</h3>
                    </div>
                    <div className="CheckinUserListItem__Date">
                  Created {(new Date(createdAt)).toLocaleString()}
                    </div>
                  </div>
                  <div className="CheckinUserListItem__Attendees">
                    <div className="CheckinUserListItem__AttendeeCount">
                      {checkedinAttendeesCount} attendees checked-in
                    </div>
                  </div>
                </div>
              </ResourceList.Item>
              {editingUserId == id &&
                <EditUserOnEventModal
                  event={event}
                  user={user}
                  onClose={() => setEditingUserId(null)}
                />
              }
            </div>
          );
        }}
      />
      {addUserModalOpen &&
        <AddUserToEventModal
          event={event}
          onClose={() => setAddUserModalOpen(false)}
          availableUsers={event.globalCheckinUsers}
        />
      }
    </Card>
  );
};

export default CheckinUsersCard;
