import React from 'react';
import { Subheading, TextContainer, Checkbox, Select } from '@shopify/polaris';
import styled from '@emotion/styled';
import ColorPicker from './Settings/ColorPicker';
import FilePicker from './Settings/FilePicker';
import TextField from './Settings/TextField';
import { hasPlanFeature } from '../../utils/features';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding-top:10px;
  padding-bottom:10px;
`;

const TemplateEditorSetting = ({ shop, setting, value, onChange }) => {
  const hasBrandingFeature = hasPlanFeature({ shop, feature: 'branding' });

  if (setting.type == 'header') {
    return (
      <Subheading>{setting.content}</Subheading>
    );
  } else if (setting.type == 'paragraph') {
    return (
      <TextContainer>{setting.content}</TextContainer>
    );
  } else if (setting.type == 'image') {
    return (
      <Container>
        <FilePicker
          shop={shop}
          setting={setting}
          onChange={onChange}
          value={value}
        />
      </Container>
    );
  } else if (setting.type == 'color') {
    return (
      <Container>
        <ColorPicker
          label={setting.label}
          value={value}
          onChange={onChange}
          helpText={setting.info || ''}
          defaultValue={setting.default}
        />
      </Container>
    );
  } else if (setting.type == 'text' || setting.type == 'number' || setting.type == 'textarea') {
    return (
      <TextField
        setting={setting}
        value={value}
        onChange={onChange}
        defaultValue={setting.default}
      />
    );
  } else if (setting.type == 'select') {
    return (
      <Select
        label={setting.label}
        options={setting.options || []}
        onChange={onChange}
        value={value || setting.default || ''}
        helpText={setting.info}
      />
    );
  } else if (setting.type == 'checkbox') {
    if (setting.id === 'hide_powered_by' && !hasBrandingFeature) {
      return (
        <Checkbox
          disabled
          helpText={'Upgrade your plan to use this feature'}
          checked={false}
          label={setting.label}
          onChange={onChange}
        />
      );
    } else {
      return (
        <Checkbox
          helpText={setting.info || ''}
          checked={value == null ? setting.default : value}
          label={setting.label}
          onChange={onChange}
        />
      );
    }
  } else {
    return (
      ''
    );
  }
};

export default TemplateEditorSetting;
