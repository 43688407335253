import React from 'react';
import gql from 'graphql-tag';
import { Layout, Link as ShopifyLink, Banner } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import MailchimpCard from './MailchimpCard';
import { GET_SHOP_INTEGRATIONS } from '../../utils/graphql';
import { Query } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';

const EVENT_FIELDS = `
  mailchimp {
    id
    username
    email
    token
    syncListId
    syncListName
    tagEventName
    tagTicketType
    customTags
    audiences {
      id
      name
    }
  }
`;

const Mailchimp = ({ eventId }) => {
  const [selectedAudience, setSelectedAudience] = React.useState('0');

  const history = useHistory();

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <Layout.AnnotatedSection
          title="Mailchimp"
          description=<div>
            <p>
              The MailChimp integration allows you to link your MailChimp account and a selected audience list
              to this event so that new attendee contacts are kept in sync with that list so you can
              easily manage email compaigns and other features that MailChimp allows.
            </p>
            <Query query={GET_SHOP_INTEGRATIONS} >
              {({ loading, data }) => (
                loading ? ('') : (
                  data.currentShop.mailchimp && data.currentShop.mailchimp.token ? (
                    <Banner status='info'>You have the <ShopifyLink onClick={() => goToPage({ history, path: '/settings/integrations/mailchimp' })}>shop-wide Mailchimp integration</ShopifyLink> linked to an account already, if you link an account here it will override those settings for this event only.</Banner>
                  ) : (
                    ''
                  )
                )
              )}
            </Query>
          </div>
        >
          <MailchimpCard
            shop={shop}
            mailchimp={event.mailchimp}
            redirectTo={`/events/${event.id}/mailchimp?shop=${shop.domain}`}
          />
        </Layout.AnnotatedSection>
      )}
    </EventQuery>
  );
};

export default Mailchimp;
