import React from 'react';
import { Query } from 'react-apollo';
import { GET_INVENTORY_LEVELS_FOR_DATE } from '../../utils/graphql';
import { Spinner, TextStyle } from '@shopify/polaris';
import styled from '@emotion/styled';
import moment from 'moment';

const Container = styled.span`
  margin-left:10px;
`;
const SpinnerContainer = styled.span`
  margin-left:10px;
  position: relative;
  top:4px;
`;

const InventoryLevel = ({ event, ticket, start, seatId }) => {

  return (
    <Query
      query={GET_INVENTORY_LEVELS_FOR_DATE}
      variables={{
        'eventId': event.id,
        'date': moment(start).format('YYYY-MM-DD'),
        'seatId': seatId,
      }}
    >
      {({ loading, data }) => {
        if (loading) {
          return <SpinnerContainer><Spinner size="small" color="teal" /></SpinnerContainer>;
        }

        const inventoryLevels = data && data.currentShop && data.currentShop.event && data.currentShop.event.inventoryLevels.filter((lvl) => lvl.startTime === moment(start).format('HH:mm'));
        const seat = data && data.currentShop && data.currentShop.event && data.currentShop.event.seat;

        let capacity;
        let inventoryLevel;
        if (seatId) {
          inventoryLevel = inventoryLevels && inventoryLevels[0];
          if (inventoryLevel && inventoryLevel.capacity != null) {
            capacity = inventoryLevel.capacity;
          } else {
            capacity = seat.capacity;
          }
        } else if (event.hasCrossVariantInventory) {
          inventoryLevel = inventoryLevels.find((lvl) => !lvl.eventTicket || !lvl.eventTicket.id);
          if (inventoryLevel && inventoryLevel.capacity != null) {
            capacity = inventoryLevel.capacity;
          } else {
            capacity = event.inventory;
          }
        } else {
          inventoryLevel = inventoryLevels.find((lvl) => lvl.eventTicket && lvl.eventTicket.id == ticket.id);
          if (inventoryLevel && inventoryLevel.capacity != null) {
            capacity = inventoryLevel.capacity;
          } else {
            capacity = ticket.inventory;
          }
        }

        const ticketsSold = inventoryLevel ? inventoryLevel.ticketsSold : 0;
        const remaining = Math.max(0, capacity - ticketsSold);

        return (
          <Container>
            <TextStyle variation="subdued">
              {remaining} tickets remaining
            </TextStyle>
          </Container>
        );
      }}
    </Query>
  );
};

export default InventoryLevel;
