import React from 'react';
import { ResourceItem, Stack, TextContainer, TextStyle, Badge } from '@shopify/polaris';

const AttendeeFieldsResourceItem = ({ template, onSelect }) => {
  return (
    <ResourceItem
      id={template.id}
      onClick={() => onSelect(template)}
    >
      <Stack vertical={false} alignment='center'>
        <Stack.Item fill>
          <Stack vertical={true} distribtion='leading'>
            <TextContainer>{template.name}</TextContainer>
          </Stack>
        </Stack.Item>
        <Badge status='info'>{template.specCount} fields</Badge>
      </Stack>
    </ResourceItem>
  );
};

export default AttendeeFieldsResourceItem;
