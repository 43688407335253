import React from 'react';
import { Mutation } from 'react-apollo';
import { Stack, DataTable, Pagination, ButtonGroup, Button, Modal, Filters } from '@shopify/polaris';
import {
  SettingsMajor,
  DeleteMajor,
  ArrowDownMinor,
  ArrowUpMinor
} from '@shopify/polaris-icons';
import { EDIT_ATTENDEE_ATTRIBUTE_SPECS } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';
import { parseBoolean } from '../../utils/parse';
import AttendeeInfoSpecModal from './AttendeeInfoSpecModal';
import styled from '@emotion/styled';

const PaginationContainer = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
`;

const PER_PAGE = 10;

  const AttendeeInfoSpecsTable = ({ event, template, attendeeAttributeSpecs, readonly }) => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const checkMark = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>;

  const specsCount = attendeeAttributeSpecs.length;
  const start = (currentPage - 1) * PER_PAGE;
  const filteredSpecs = attendeeAttributeSpecs
    .filter((spec) => !searchValue || spec.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  const visibleSpecs = filteredSpecs
    .slice(start, start + PER_PAGE);

  return (
    <Mutation mutation={EDIT_ATTENDEE_ATTRIBUTE_SPECS}
      onError={() => {
        showToastError('Unable to update attendee info fields')
        setShowEditModal(false);
        setShowDeleteModal(false);
      }}
      onCompleted={(data) => {
        showToastNotice('Attendee info fields updated')
        setShowEditModal(false);
        setShowDeleteModal(false);
      }}
      refetchQueries={() => ['AttendeeAttributeSpecsQuery', 'GetAttendeeInfoFieldsTemplates']}
    >
      {(editAttendeeAttributeSpecs, { loading: saving }) => (
        <Stack vertical={true}>
          {!readonly && specsCount > 0 &&
            <Filters
              queryValue={searchValue}
              queryPlaceholder='Field label'
              filters={[]}
              appliedFilters={[]}
              onQueryChange={(v) => {
                setSearchValue(v)
                setCurrentPage(1);
              }}
              onQueryClear={() => {
                setSearchValue('');
                setCurrentPage(1);
              }}
              onClearAll={() => {
                setSearchValue('');
                setCurrentPage(1);
              }}
            />
          }
          <DataTable
            columnContentTypes={['text', 'text', 'text', ...(readonly ? [] : ['text'])]}
            headings={['Label', 'Type', 'Required', ...(readonly ? [] : [''])]}
            rows={visibleSpecs.map((spec) => {
              let type = spec.type;
              if (['firstname', 'lastname', 'email', 'phone'].includes(spec.name)) {
                if (spec.name == 'firstname') {
                  type = 'First name';
                } else if (spec.name == 'lastname') {
                  type = 'Last name';
                } else {
                  type = spec.name;
                }
              }
              const buttons = <Stack vertical={false}>
                <ButtonGroup>
                  <Button size='slim' plain icon={SettingsMajor} onClick={() => setShowEditModal(spec)}></Button>
                  <Button size='slim' plain icon={DeleteMajor} onClick={() => setShowDeleteModal(spec)}></Button>
                </ButtonGroup>
              </Stack>;
              return [
                spec.label.length > 50 ? `${spec.label.substring(0, 50)}...` : spec.label,
                type,
                parseBoolean(spec.required) ? checkMark : '',
                ...(readonly ? [] : [buttons]),
              ];
            })}
          />
          {visibleSpecs.length > 0 &&
            <div className="Polaris-DataTable__Footer">{`Showing ${filteredSpecs.length > PER_PAGE ? `${start+1}-${start+visibleSpecs.length}` : `${visibleSpecs.length}`} of ${filteredSpecs.length} results`}</div>
          }
          {visibleSpecs.length > 0 &&
            <PaginationContainer>
              <Pagination
                hasPrevious={currentPage > 1}
                hasNext={currentPage < (Math.ceil(filteredSpecs.length / PER_PAGE))}
                onPrevious={() => {
                  setCurrentPage(currentPage - 1);
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1);
                }}
              />
            </PaginationContainer>
          }
          {showDeleteModal !== false &&
            <Modal
              title='Confirm attendee info field delete'
              open
              onClose={() => setShowDeleteModal(false)}
              primaryAction={{
                content: 'Yes, delete it',
                destructive: true,
                loading: saving,
                onAction: () => {
                  let updatedSpecs = attendeeAttributeSpecs;
                  const index = attendeeAttributeSpecs.findIndex((spec) => spec.name == showDeleteModal.name);
                  updatedSpecs.splice(index, 1);

                  editAttendeeAttributeSpecs({
                    variables: {
                      eventId: event?.id,
                      templateId: template?.id,
                      specs: JSON.stringify(updatedSpecs),
                    }
                  });
                }
              }}
              secondaryActions={[
                {
                  content: 'No, go back',
                  disabled: saving,
                  onAction: () => setShowDeleteModal(false),
                }
              ]}
            >
              <Modal.Section>
                Are you sure you want to delete this attendee information field? This cannot be reversed.
              </Modal.Section>
            </Modal>
          }
          {showEditModal !== false &&
            <AttendeeInfoSpecModal
              spec={attendeeAttributeSpecs.find((spec) => spec.name == showEditModal.name)}
              specs={attendeeAttributeSpecs}
              saving={saving}
              ticketTypes={event?.tickets}
              onSave={(newSpec) => {
                let updatedSpecs = attendeeAttributeSpecs;
                if (showEditModal) {
                  const index = attendeeAttributeSpecs.findIndex((spec) => spec.name == showEditModal.name);
                  updatedSpecs[index] = newSpec;
                } else {
                  updatedSpecs.push(newSpec);
                }
                editAttendeeAttributeSpecs({
                  variables: {
                    eventId: event?.id,
                    templateId: template?.id,
                    specs: JSON.stringify(updatedSpecs),
                  }
                });
              }}
              onClose={() => setShowEditModal(false)}
            />
          }
        </Stack>
      )}
    </Mutation>
  );
};

export default AttendeeInfoSpecsTable;
