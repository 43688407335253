import React from 'react';
import gql from 'graphql-tag';
import EventQuery from '../EventPage/EventQuery.jsx';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import NotificationSettings from './NotificationSettings';
import { NOTIFICATION_TEMPLATE_FIELDS } from '../../utils/graphql';

const EVENT_FIELDS = `
  ticketEmailSharedCount
  email {
    ${NOTIFICATION_TEMPLATE_FIELDS}
  }
  ticketPage {
    sampleUrl
  }
`;

const EventNotificationSettings = ({ eventId }) => {
  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <NotificationSettings shop={shop} event={event} email={event.email} />
      )}
    </EventQuery>
  );
};

export default EventNotificationSettings;
