import moment from 'moment';

export const buildCalendarEvents = ({ event, items }) => {
  const eventInstanceFromScheduleItem = (item, day) => {
    const startHour = parseInt(item.startTime.split(':')[0]);
    const startMinute = parseInt(item.startTime.split(':')[1]);
    const duration = parseInt(item.duration);

    const start = day.clone().set({ hour: startHour, minute: startMinute, second: 0, millisecond: 0 });
    const end = start.clone().add(duration, 'seconds');

    let title = item.name;
    if (!title) {
      title = event.title;
    }

    return {
      title: `${item.allDay ? '' : `${item.startTime}: `}${title}`,
      all_day: item.allDay,
      start: start.toDate(),
      end: end.toDate(),
      resource: item,
    };
  }

  let calendarEvents = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.deleted) {
      continue;
    }
    const startDate = item.startDate ? moment(item.startDate).startOf('day') : moment();
    const endDate = item.endDate ? moment(item.endDate).endOf('day') : moment().add(1, 'year');

    if (item.period === 'daily' || item.period === 'weekly' || item.period === 'monthly' || item.period === 'monthly-weekday-first' || item.period === 'monthly-weekday-last') {
      let day = startDate.clone();
      const startWeekOfMonth = Math.ceil(startDate.date() / 7);
      const startDayOfMonth = startDate.date();
      while (day.isSameOrBefore(endDate)) {
        if (
          (!item.scheduleExceptions ||
          !item.scheduleExceptions.some((exception) =>
            moment(exception.startDate).format('YYYY-MM-DD') === day.format('YYYY-MM-DD') &&
            exception.startTime === item.startTime
          )) &&
          (item.period !== 'monthly' || day.date() === startDayOfMonth)
        ) {
          calendarEvents.push(eventInstanceFromScheduleItem(item, day));
        }

        const periodNumber = parseInt(item.periodNumber || 1);
        if (item.period === 'daily') {
          day = day.add(periodNumber, 'day');
        } else if (item.period === 'weekly') {
          day = day.add(periodNumber, 'week');
        } else if (item.period === 'monthly') {
          const startOfMonth = day.clone().startOf('month').add(periodNumber, 'month');
          if (startOfMonth.daysInMonth() < startDayOfMonth) {
            day = startOfMonth.endOf('month'); // clamp it to end of month
          } else {
            day = startOfMonth.date(startDayOfMonth);
          }
        } else if (item.period === 'monthly-weekday-first') {
          const startOfMonth = day.clone().startOf('month').add(periodNumber, 'month');
          day = startOfMonth.clone().day(day.day());
          if (day.month() !== startOfMonth.month()) {
            day = day.add(1, 'week'); // check if day of week is the same month, otherwise go to the following week to get the first week with that day
          }
          day = day.add(startWeekOfMonth - 1, 'week');
        } else if (item.period === 'monthly-weekday-last') {
          const endOfMonth = day.clone().endOf('month').add(periodNumber, 'month');
          day = endOfMonth.clone().day(day.day());
          if (day.month() !== endOfMonth.month()) {
            day = day.subtract(1, 'week'); // check if day of week is the same month, otherwise go to the previous week to get the last week with that day
          }
        }
      }
    } else {
      calendarEvents.push(eventInstanceFromScheduleItem(item, startDate));
    }
  }

  return calendarEvents;
};
