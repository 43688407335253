import React from 'react';
import { TextField, Checkbox, Label, HelpText } from '@shopify/polaris';
import { ChromePicker } from 'react-color';
import styled from '@emotion/styled';

const Container = styled.div`

`;

const ValueContainer = styled.div`
  display:flex;
  flex-wrap:nowrap;
`;

const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 110;
  border-radius:5px;
`;

const InputContainer = styled.div`
  display:inline-block;
  width: 100%;
  margin-right:10px;
`;

const ColorCover = styled.div`
  position: fixed;
  z-index: 105;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ColorSwatch = styled.div`
  display:inline-block;

  padding:7px;
  background:#fff;
  border-radius:1px;
  box-shadow:0 0 0 1px rgba(0,0,0,.1);
  display:inline-block;
  cursor:pointer;
`;

const ColorSwatchColor = styled.div`
  width:36px;
  height:100%;
  border-radius:2px;
`;

const ColorPicker = ({ label, value, onChange, helpText, allowInherit, defaultValue, initialValue }) => {
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState((value || defaultValue) && (value || defaultValue).startsWith('#') ? (value || defaultValue).substring(1) : (value || defaultValue));

  const handleChange = (v) => {
    if (v && !v.startsWith('#') && v !== 'inherit') {
      v = `#${v}`;
    }
    setColor(v && v.startsWith('#') ? v.substring(1) : v);
    onChange(v);
  };

  React.useEffect(() => {
    setColor((value || defaultValue) && (value || defaultValue).startsWith('#') ? (value || defaultValue).substring(1) : (value || defaultValue));
  }, [value, defaultValue])

  return (
    <Container>
      <Label>{label}</Label>
      <ValueContainer>
        <InputContainer onClick={() => setOpen(!open)}>
          <TextField
            value={color === 'inherit' ? '' : color}
            onChange={handleChange}
            prefix='#'
            disabled={color === 'inherit'}
            autoCompleteRequired='off'
          />
        </InputContainer>
        <ColorSwatch onClick={() => setOpen(!open)} >
          <ColorSwatchColor style={{background: `#${color}`}}></ColorSwatchColor>
        </ColorSwatch>
        {open &&
          <ColorPickerContainer>
            <ChromePicker
              color={`#${color}`}
              onChange={(v) => handleChange(v.hex)}
              disableAlpha
            />
          </ColorPickerContainer>
        }
        {open &&
          <ColorCover onClick={() => setOpen(false)} ></ColorCover>
        }
        {allowInherit &&
          <Checkbox
            checked={color === 'inherit'}
            label='Inherit'
            onChange={(v) => handleChange(v ? 'inherit' : initialValue)}
          />
        }
      </ValueContainer>
      {helpText && helpText.length &&
        <div className="Polaris-Labelled__HelpText" id="TextField1HelpText">
          {helpText}
        </div>
      }
    </Container>
  );
};

export default ColorPicker;
