import React from 'react';
import { Card, Link, TextContainer, Button } from '@shopify/polaris';
import CardHeader from '../CardHeader';
import styled from '@emotion/styled';
import { AWS_S3_BUCKET_FILES } from '../../../utils/s3Bucket';

const Container = styled.div`
  display:flex;
`;

const LeftContent = styled.div`
  width: 60%;
`;
const RightContent = styled.div`
  width: 40%;
`;

const Section = styled.div`
  padding:20px;
`;

const HelpCard = ({ shop, event }) => {
  return (
    <Card>
      <Card.Section>
        <CardHeader title='Getting started with your event' />
      </Card.Section>
      <Container>
        <LeftContent>
          <Section>
            <TextContainer>
              If you're looking for help gettting started with your event or have any questions, we're here to help!
            </TextContainer>
          </Section>
          <Section>
            <TextContainer>
              Use the quick help button in the corner of your page, or email us at <Link onClick={() => { window.open(`mailto:help@eveyevents.com`, '_blank') }}>help@eveyevents.com</Link>.
            </TextContainer>
          </Section>
          <Section>
            <TextContainer>
              <Button external url='https://evey-events.zendesk.com/hc'>Visit the help center</Button>
            </TextContainer>
          </Section>
        </LeftContent>
        <RightContent>
          <img src={`https://${AWS_S3_BUCKET_FILES}.s3.amazonaws.com/images/dashboard/help.svg`} style={{ width: '75%' }} />
        </RightContent>
      </Container>
    </Card>
  );
};

export default HelpCard;
