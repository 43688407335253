import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextContainer } from '@shopify/polaris';

const DELETE_LOCALE_FILE = gql`
  mutation deleteLocaleFile($localeFileId: ID!) {
    deleteLocaleFile(localeFileId: $localeFileId) {
      success
    }
  }
`;

const DeleteLocaleModal = ({ shop, localeFile, onError, onComplete, onDismiss }) => {
  return (
    <Mutation
      mutation={DELETE_LOCALE_FILE}
      onError={() => {
        if (onError) {
          onError();
        }
      }}
      onCompleted={() => {
        if (onComplete) {
          onComplete();
        }
      }}
      refetchQueries={['ShopLocaleFiles']}
      awaitRefetchQueries={true}
    >
      {(deleteLocaleFile, { loading: deleting }) => (
        <Modal
          title='Confirm locale deletion'
          open={true}
          onClose={onDismiss}
          primaryAction={{
            content:'Yes, delete it',
            destructive: true,
            loading: deleting,
            onAction: () => {
              deleteLocaleFile({
                variables: {
                  localeFileId: localeFile.id,
                },
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: deleting,
              onAction: onDismiss,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>Are you sure you want to delete this locale? This cannot be reversed.</TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default DeleteLocaleModal;
