import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { TextField, FormLayout, Tabs, ButtonGroup, Button } from '@shopify/polaris';
import styled from '@emotion/styled';

const JsonContainer = styled.div`
  min-height:300px;
`;

const Container = styled.div`
  padding-top:20px;
`;

const ViewToggleContainer = styled.div`
  display:flex;
  justify-content: flex-end;
`;

const LocaleEditor = ({ shop, content, setContent, isDefault }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [jsonView, setJsonView] = React.useState(false);

  const parsedContent = JSON.parse(content || '');
  const keys = Object.keys(parsedContent.events);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const selectedKey = Object.keys(parsedContent.events)[selectedTab];
  const selectedObject = parsedContent.events[selectedKey];

  return (
    <>
      <ViewToggleContainer>
        <ButtonGroup segmented>
          <Button
            onClick={() => setJsonView(false)}
          >
            Editor
          </Button>
          <Button
            onClick={() => setJsonView(true)}
          >
            JSON
          </Button>
        </ButtonGroup>
      </ViewToggleContainer>
      {jsonView ? (
        <FormLayout>
          <JsonContainer>
            <TextField
              multiline
              value={content}
              label="Translations (JSON)"
              placeholder="{}"
              onChange={setContent}
            />
          </JsonContainer>
        </FormLayout>
      ) : (
        <Tabs
          tabs={keys.map((key) => ({
            id: key,
            content: capitalizeFirstLetter(key).replace(/_/g, ' '),
            accessibilityLabel: key,
            panelID: `${key}-content`,
          }))}
          selected={selectedTab}
          onSelect={setSelectedTab}
          fitted
        >
          <Container>
            <FormLayout>
              {Object.keys(selectedObject).map((key) => (
                <TextField
                  label={capitalizeFirstLetter(key).replace(/_/g, ' ')}
                  value={selectedObject[key]}
                  onChange={(v) => {
                    setContent(JSON.stringify({
                      ...parsedContent,
                      events: {
                        ...parsedContent.events,
                        [selectedKey]: {
                          ...parsedContent.events[selectedKey],
                          [key]: v,
                        },
                      },
                    }, null, 2));
                  }}
                />
              ))}
            </FormLayout>
          </Container>
        </Tabs>
      )}
    </>
  );
};

export default LocaleEditor;
