import React from 'react';
import { Card, DataTable, Button } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import SeatingZoneModal from './SeatingZoneModal';

const SeatingZonesCard = ({ shop, seatingChart }) => {
  const [showAddZoneModal, setShowAddZoneModal] = React.useState(false);
  const [showEditZoneModal, setShowEditZoneModal] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const seatingZones = seatingChart.seatingZones;

  return (
    <Card sectioned
      actions={[
        {
          content: 'Add zone',
          onAction: () => setShowAddZoneModal(true),
        }
      ]}
    >
      <DataTable
        columnContentTypes={[
          'text',
          'numeric',
          'text',
        ]}
        headings={[
          'Name',
          'Seats',
          '',
        ]}
        rows={seatingZones.map((zone) => ([
          zone.name,
          zone.seatsCount,
          <Button
            size='slim'
            onClick={() => setShowEditZoneModal(zone)}
          >
            Edit
          </Button>
        ]))}
        footerContent={`Showing ${seatingZones.length} results`}
      />
      {showAddZoneModal &&
        <SeatingZoneModal
          seatingChart={seatingChart}
          onClose={() => setShowAddZoneModal(false)}
          onCompleted={() => setShowAddZoneModal(false)}
        />
      }
      {showEditZoneModal &&
        <SeatingZoneModal
          seatingChart={seatingChart}
          seatingZone={showEditZoneModal}
          onClose={() => setShowEditZoneModal(false)}
          onCompleted={() => setShowEditZoneModal(false)}
        />
      }
    </Card>
  );
};

export default SeatingZonesCard;
