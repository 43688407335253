import dayjs from 'dayjs';

export const appendLeadingZeroes = (n) => {
  if(n <= 9){
    return '0' + n;
  }
  return n;
};

export const buildDateWithCompensatedTimezone = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const normalizeToUtc = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export const getTimeString = (date) => {
  return date.toTimeString().split(':')[0] + ':' + date.toTimeString().split(':')[1];
};

export const getDateString = (date) => {
  return date.getFullYear() + '-' + appendLeadingZeroes(date.getMonth() + 1) + '-' + appendLeadingZeroes(date.getDate());
};

export const convertToDateTime = (t, h) => {
  const yearPart = dayjs(t).get('year');
  const monthPart = (dayjs(t).get('month')+1).toString().padStart(2, '0');
  const dayPart = dayjs(t).get('date').toString().padStart(2, '0');
  const hourPart = parseInt(h.split(':')[0]).toString().padStart(2, '0');
  const minutePart = parseInt(h.split(':')[1]).toString().padStart(2, '0');
  return `${yearPart}-${monthPart}-${dayPart}T${hourPart}:${minutePart}:00[-00:00]`;
}

export const formatDate = (dateString) => {
  let date = new Date(dateString);
  return date.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' });
}

export const getOrdinalNum = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};
