import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Modal, TextField, TextContainer, TextStyle, FormLayout } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';

const DELETE_TICKET = gql`
  mutation permanentlyDeleteTicket($eventId: ID!, $attendeeId: ID!) {
    permanentlyDeleteTicket(eventId: $eventId, attendeeId: $attendeeId) {
      success
    }
  }
`;

const DeleteTicketModal = ({ event, attendee, onDismiss, onDelete }) => {
  const [confirmText, setConfirmText] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={DELETE_TICKET}
      onError={() => showToastError('Failed to delete ticket')}
      onCompleted={() => {
        showToastNotice('Ticket deleted');
        onDelete();
      }}
    >
      {(permanentlyDeleteTicket, { loading: deleting }) => (
        <Modal
          title='Confirm ticket deletion'
          open={true}
          onClose={onDismiss}
          primaryAction={{
            content: 'Yes, delete ticket',
            destructive: true,
            loading: deleting,
            disabled: confirmText != 'permanently delete',
            onAction: () => {
              permanentlyDeleteTicket({
                variables:{
                  eventId: event.id,
                  attendeeId: attendee.id,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: deleting,
              onAction: onDismiss,
            }
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextContainer>Are you sure you want to delete this ticket? Deleting a ticket is irreversible and may allow the ticket number to be re-used. Enter the text <TextStyle variation='strong'>permanently delete</TextStyle> below to confirm.</TextContainer>
              <TextField
                value={confirmText}
                onChange={setConfirmText}
                autoComplete={false}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
}

export default DeleteTicketModal;
