import React from 'react';
import { TextField as ShopifyTextField } from '@shopify/polaris';
import { useDebounce } from 'use-debounce';

const TextField = ({ setting, value, defaultValue, onChange }) => {
  const [localValue, setLocalValue] = React.useState(value || defaultValue || '');
  const [debouncedValue] = useDebounce(localValue, 500);

  React.useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    setLocalValue(value || defaultValue || '');
  }, [value, defaultValue]);

  return (
    <ShopifyTextField
      label={setting.label}
      value={localValue}
      helpText={setting.info || ''}
      onChange={setLocalValue}
      type={setting.type == 'number' ? 'number' : 'text'}
      multiline={setting.type == 'textarea' ? 3 : false}
      suffix={setting.suffix}
      prefix={setting.prefix}
    />
  );
};

export default TextField;
