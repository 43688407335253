
export const currencySymbolLookup = (currency) => {
  var currencySymbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
  };
  return currencySymbols[currency];
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const moneyFormat = (shop, value) => {
  let amount = parseFloat(value.toString().replace('$', '')).toFixed(2).toString();
  return shop.moneyFormat
    .replace(/({{amount}}|{{ amount }})/, amount)
    .replace(/({{amount_with_comma_separator}}|{{ amount_with_comma_separator }})/, amount)
    .replace(/({{amount_no_decimals}}|{{ amount_no_decimals }})/, parseInt(amount))
    .replace(/({{amount_no_decimals_with_comma_separator}}|{{ amount_no_decimals_with_comma_separator }})/, numberWithCommas(parseInt(amount)));
}

