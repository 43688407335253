import React from 'react';
import { Card, FormLayout, Checkbox } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useFrame } from '../../../contexts/frame';

const SAVE_ORDERS_SETTINGS = gql`
  mutation saveOrdersSettings($refundPolicy: String!, $autoCancelUnavailableSeats: Boolean!) {
    saveOrdersSettings(refundPolicy: $refundPolicy, autoCancelUnavailableSeats: $autoCancelUnavailableSeats) {
      success
    }
  }
`;

const RefundsCard = ({ shop }) => {
  const [refundPolicy, setRefundPolicy] = React.useState(shop.refundPolicy);
  const [autoCancelUnavailableSeats, setAutoCancelUnavailableSeats] = React.useState(shop.autoCancelUnavailableSeats);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={SAVE_ORDERS_SETTINGS}
      onError={() => showToastError('Failed to update settings')}
      onCompleted={() => showToastNotice('Settings updated')}
      refetchQueries={() => ['GetShopOrdersSettings']}
    >
      {(saveOrdersSettings, { loading: saving }) => (
        <Card
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              saveOrdersSettings({
                variables: {
                  refundPolicy,
                  autoCancelUnavailableSeats,
                },
              });
            },
          }}
        >
          <Card.Section>
            <FormLayout>
              <Checkbox
                checked={refundPolicy == 'cancel'}
                label="Cancel all tickets for an order when it is refunded"
                helpText="When the orders is refunded through the Shopify dashboard all tickets that were created by that order will be cancelled and customers will no longer have access to them."
                onChange={(v) => setRefundPolicy(v ? 'cancel' : 'ignore')}
              />
            </FormLayout>
          </Card.Section>
          <Card.Section title='Assigned seating'>
            <FormLayout>
              <Checkbox
                checked={autoCancelUnavailableSeats}
                label='Automatically cancel and refund tickets when there are no seats available'
                helpText='If a customer purchases tickets and there are no seats available in their selected section / zone then we will attempt to cancel and refund the line items immediately after purchase.'
                onChange={setAutoCancelUnavailableSeats}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default RefundsCard;
