import React from 'react';
import { Card } from '@shopify/polaris';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { Button, ButtonGroup, PageActions, Stack, TextContainer, Banner, TextStyle } from '@shopify/polaris';
import { Modal } from '@shopify/app-bridge-react';
import ErrorPage from '../../Error/ErrorPage.jsx';
import LegacySubscribedEvents from './LegacySubscribedEvents';
import LegacyShopSubscription from './LegacyShopSubscription';
import CardLoading from '../../shared/CardLoading.jsx';
import { useHistory } from "react-router-dom";
import { useFrame } from '../../../contexts/frame';
import { hasNewPricing } from '../../../utils/features';

const GET_SUBSCRIPTIONS = gql`
  query SubscriptionsQuery {
    currentShop {
      domain
      prettyEventPrice
      prettyUnlimitedPlanPrice
      subscribed
      formattedTotalSubscriptionCost
      externalSubscription
      hasLegacyPricing
      subscribedEvents {
        id
        title
        subscribedAt
      }
    }
  }
`;

const CANCEL_ALL_SUBSCRIPTIONS = gql`
  mutation cancelAllSubscriptions {
    cancelAllSubscriptions {
      success
    }
  }
`;

const LegacySubscriptionCard = () => {
  const [plan, setPlan] = React.useState(null);
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = React.useState(false);

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Query
      query={GET_SUBSCRIPTIONS}
      onCompleted={(data) => {
        const planUrlParam = (new URLSearchParams(window.location.search)).get('plan');
        setPlan(planUrlParam || (data.currentShop.subscribed ? 'shop' : 'per-event'));
      }}
    >
      {({ loading, error, data }) => {
        if (error) return <ErrorPage error={error}/>;
        if (loading) return <CardLoading />;

        const shop = data.currentShop;

        const newPricingEnabled = hasNewPricing(shop.domain);

        if (!shop.hasLegacyPricing && newPricingEnabled && shop.subscribedEvents.length == 0) {
          return '';
        }

        if (shop && shop.externalSubscription) {
          return (
            <Banner status='info'>You currently have an external or comped subscription. Please contact us directly to make changes to this.</Banner>
          );
        }

        return (
          <Card sectioned title={newPricingEnabled ? 'Legacy pricing options' : undefined}>
            <Stack vertical={true} spacing='extraLoose'>
              {newPricingEnabled &&
                (shop.hasLegacyPricing ? (
                  <TextContainer>We've recently changed the way pricing works in the app, you can see these options in the card above. As an existing user of the app, we value you and want to offer you both options for a limited time.</TextContainer>
                ) : (
                  <TextContainer>We've recently changed the way pricing works in the app, select a plan in the card above to continue selling tickets.</TextContainer>
                ))
              }
              {(!newPricingEnabled || (newPricingEnabled && shop.hasLegacyPricing)) &&
                <ButtonGroup segmented>
                  <Button pressed={plan == 'per-event'} onClick={() => setPlan('per-event')}>
                    <div className="Subscription__PlanButtonName">Per event billing</div>
                    <div className="Subscription__PlanButtonSub">{shop.prettyEventPrice} per event per month</div>
                  </Button>
                  <Button pressed={plan != 'per-event'} onClick={() => setPlan('shop')}>
                    <div className="Subscription__PlanButtonName">Unlimited events</div>
                    <div className="Subscription__PlanButtonSub">{shop.prettyUnlimitedPlanPrice} per month</div>
                  </Button>
                </ButtonGroup>
              }
              {plan == 'per-event' ? (
                <LegacySubscribedEvents shop={shop} events={shop.subscribedEvents} />
              ) : (
                <LegacyShopSubscription shop={shop} />
              )}
            </Stack>
            {(plan == 'per-event' && shop.subscribedEvents.length > 0 || shop.subscribed) &&
              <Mutation
                mutation={CANCEL_ALL_SUBSCRIPTIONS}
                onError={(error) => showToastError('Unable to unsubscribe, try again or contact us.')}
                onCompleted={(data) => showToastNotice('Subscription cancelled')}
                refetchQueries={() => ['SubscriptionsQuery']}
              >
                {(cancelAllSubscriptions, { loading }) => (
                  <div>
                    <PageActions
                      secondaryActions={[
                        {
                          content: shop.subscribed ? 'Cancel subscription' : 'Cancel all subscriptions',
                          destructive: true,
                          disabled: loading,
                          onAction: () => setConfirmCancelModalOpen(true),
                        },
                      ]}
                    />
                    <Modal
                      title={shop.subscribed ? 'Confirm subscription cancellation' : 'Confirm subscriptions cancellation'}
                      open={confirmCancelModalOpen}
                      onClose={() => setConfirmCancelModalOpen(false)}
                      primaryAction={{
                        content: shop.subscribed ? 'Yes, cancel subscription' : 'Yes, cancel subscriptions',
                        destructive: true,
                        disabled: loading,
                        onAction: () => cancelAllSubscriptions(),
                      }}
                      secondaryActions={[
                        {
                          content: 'No, go back',
                          disabled: loading,
                          onAction: () => setConfirmCancelModalOpen(false),
                        }
                      ]}
                      message={`Are you sure you want to cancel your ${shop.subscribed ? 'subscription' : 'subscriptions for all events'}? You will not have access to your events anymore or be able to create new tickets unless you re-subscribe.`}
                    />
                  </div>
                )}
              </Mutation>
            }
          </Card>
        );
      }}
    </Query>
  );
};

export default LegacySubscriptionCard;
