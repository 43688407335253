import dayjs from 'dayjs';
import randomstring from 'randomstring';

const previewEvent = () => ({
  title: 'Awesome event',
  ticketPage: {
    sampleUrl: ''
  },
  sampleCheckinUrl: '',
  hasCalendarOptions: true,
  location: 'Toronto, ON',
  locationType: 'venue',
  publicProductUrl: '',
  virtualEventPageUrlBase: '',
  virtualEventPage: {
    requirePassword: false,
    showPasswordInNotification: true,
  },
  contactEmail: 'help@example.com',
  startAt: dayjs().add(2, 'day').format('YYYYMMDD[T]HHmmss[Z]'),
  endAt: dayjs().add(2, 'day').format('YYYYMMDD[T]HHmmss[Z]'),
  organizer: {
    name: 'Test organizer',
  }
});

const buildPreviewTicket = ({ title }) => ({
  title: title,
  variant_sku: '12345',
  variant_barcode: 'AAAZZZ123',
});

const buildPreviewAttendee = ({ event, firstName, lastName, ticketNumber }) => {
  const _event = event || previewEvent();
  return {
    first_name: firstName,
    last_name: lastName,
    name: `${firstName} ${lastName}`,
    token: randomstring.generate(24),
    ticket_number: ticketNumber,
    ticket_code: `#${ticketNumber}`,
    number: ticketNumber,
    ticket_url: _event.ticketPage && _event.ticketPage.sampleUrl,
    manage_url: _event.ticketPage && _event.ticketPage.sampleUrl,
    checkin_url: _event.sampleCheckinUrl,
    ticket: buildPreviewTicket({
      title: 'General Admission',
    }),
    access_code: 'H3WDKNMC',
    ...(_event.hasCalendarOptions ? {
      add_to_calendar_google_url: `https://www.google.com/calendar/render?action=TEMPLATE&text=${_event.title}&details=sample_description&location=${_event.location}&dates=${dayjs().format('YYYYMMDD[T]HHmmss[Z]')}`,
      add_to_calendar_outlook_url: `http://outlook.live.com/calendar/0/deeplink/compose?body=sample_description&location=${_event.location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=${_event.title}`,
      add_to_calendar_ics_url: 'https://evey-files.s3.amazonaws.com/samples/event.ics',
    } : {}),
    order_status_url: 'https://google.com',
    order_status_name: '#1001',
    seat_label: 'A1',
    event_start_at: _event.dateType == 'recurring' ? (new Date()) : _event.startAt,
  }
};

export const buildPreviewData = ({ shop, event, config }) => {
  const _event = event || previewEvent();

  return {
    event: {
      title: _event.title,
      start_at: _event.startAt,
      end_at: _event.endAt,
      event_url: _event.publicProductUrl,
      contact_email: _event.contactEmail,
      location_type: _event.locationType,
      location: _event.location,
      virtual_event_page_url: _event.virtualEventPageUrlBase,
      virtual_event_url: _event.virtualEventPageUrlBase,
      virtual_event_requires_password: _event.virtualEventPage?.requirePassword,
      virtual_event_show_password: _event.virtualEventPage?.showPasswordInNotification,
      has_calendar_options: _event.hasCalendarOptions,
      custom_fields: _event.metafields ? JSON.parse(_event.metafields || '{}') : {},
      organizer: _event.organizer,
    },
    attendees: [
      buildPreviewAttendee({
        shop,
        event: _event,
        firstName: 'Peter',
        lastName: 'Parker',
        ticketNumber: 1001
      }),
      buildPreviewAttendee({
        shop,
        event: _event,
        firstName: 'Peter',
        lastName: 'Parker',
        ticketNumber: 1002
      }),
    ],
    attendee: buildPreviewAttendee({
      shop,
      event: _event,
      firstName: 'Peter',
      lastName: 'Parker',
      ticketNumber: 1001
    }),
    shop: {
      name: shop.name,
      email: shop.email,
      shop_url: shop.shopUrl,
    },
    config,
  };
};
