import React from 'react';
import SettingsPage from './SettingsPage';
import StorefrontIntegrations from './StorefrontIntegrations';

const StorefrontPage = () => {

  return (
    <SettingsPage pageTitle='Storefront integrations' title='Storefront'>
      <StorefrontIntegrations />
    </SettingsPage>
  );
};

export default StorefrontPage;
