import React from 'react';
import { Modal, TextField, FormLayout } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useFrame } from '../../../contexts/frame';
import ModalFooter from '../../shared/ModalFooter.jsx';

const SAVE_SEATING_ZONE = gql`
  mutation saveSeatingZone($seatingChartId: ID!, $id: ID, $name: String!) {
    saveSeatingZone(seatingChartId: $seatingChartId, id: $id, name: $name) {
      seatingZone {
        id
        name
      }
    }
  }
`;

const DELETE_SEATING_ZONE = gql`
  mutation deleteSeatingZone($seatingChartId: ID!, $id: ID!) {
    deleteSeatingZone(seatingChartId: $seatingChartId, id: $id) {
      success
    }
  }
`;

const SeatingZoneModal = ({ seatingChart, seatingZone, onClose, onCompleted }) => {
  const [name, setName] = React.useState(seatingZone ? seatingZone.name : '');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = name && name.length > 0;
  const canDelete = seatingChart.seatingZones.length > 1;

  return (
    <Mutation
      mutation={DELETE_SEATING_ZONE}
      onError={() => showToastError('Failed to delete zone')}
      onCompleted={(data) => {
        showToastNotice('Seating zone deleted');
        onClose();
      }}
      refetchQueries={() => ['GetSeatingChart']}
      awaitRefetchQueries={true}
    >
      {(deleteSeatingZone, { loading: deleting }) => (
        <Mutation
          mutation={SAVE_SEATING_ZONE}
          onError={() => showToastError(seatingZone ? 'Failed to update zone' : 'Failed to add seating zone')}
          onCompleted={(data) => {
            showToastNotice(seatingZone ? 'Seating zone updated' : 'Seating zone created');
            onCompleted(data.saveSeatingZone.seatingZone);
          }}
          refetchQueries={() => ['GetSeatingChart']}
          awaitRefetchQueries={true}
        >
          {(saveSeatingZone, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={seatingZone ? 'Edit seating edit' : 'Add seating zone'}
              footer={
                <ModalFooter
                  saveButtonText='Save'
                  saveButtonAction={() => {
                    saveSeatingZone({
                      variables: {
                        seatingChartId: seatingChart.id,
                        id: seatingZone && seatingZone.id,
                        name,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={!isValid || deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText='Delete'
                  destructiveButtonAction={seatingZone && canDelete ? () => {
                    deleteSeatingZone({
                      variables: {
                        seatingChartId: seatingChart.id,
                        id: seatingZone.id,
                      },
                    });
                  } : undefined}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />
              }
            >
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label="Name"
                    value={name}
                    placeholder="Section A"
                    onChange={setName}
                  />
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default SeatingZoneModal;
