import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Badge, Stack, Subheading, TextField, Checkbox, List, TextStyle, Autocomplete, Tag, ChoiceList } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import SaveBar from '../shared/SaveBar';
import EditEventsCalendarCodeModal from './EditEventsCalendarCodeModal';
import { shallowEqual } from '../../utils/compare';

const TEXT = [
  {
    handle: 'calendar_heading',
    label: 'Page heading text',
    defaultValue: 'Events',
  },
  {
    handle: 'calendar_view_event',
    label: 'Event popup call-to-action to go to product page',
    defaultValue: 'View event details',
  },
  {
    handle: 'calendar_sold_out',
    label: 'Sold out notification',
    defaultValue: 'Sold out',
    helpText: 'Message to display in event popop when all tickets are sold out',
  },
  {
    handle: 'calendar_button_month',
    label: 'Month view button',
    defaultValue: 'month',
    helpText: 'Button to enable the month view at the top right of the calendar',
  },
  {
    handle: 'calendar_button_week',
    label: 'Week view button',
    defaultValue: 'week',
    helpText: 'Button to enable the week view at the top right of the calendar',
  },
  {
    handle: 'calendar_button_list',
    label: 'List view button',
    defaultValue: 'list',
    helpText: 'Button to enable the list view at the top right of the calendar',
  },
]

const EventsCalendarCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [strings, setStrings] = React.useState(originalStrings);
  const [editCodeModalOpen, setEditCodeModalOpen] = React.useState(false);
  const [eventsInputText, setEventsInputText] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const defaultLocale = shop.defaultLocaleFile.locale;
  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config) || JSON.stringify(originalStrings) != JSON.stringify(strings);

  const url = `${shop.proxyUrl}/calendar`;

  const events = shop.allEvents;

  const selectedEvents = config.calendar_excluded_events || [];
  const handleEventsChange = (v) => {
    setConfig({
      ...config,
      calendar_excluded_events: v,
    });
  };

  return (
    <>
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update customizations')}
        onCompleted={() => showToastNotice('Customizations updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Card sectioned
            title='Customize'
            actions={[
              {
                content: 'View page',
                url: url,
                external: true,
              },
              {
                content: 'Reset to default',
                onAction: () => {
                  let newConfig = {};

                  let newStrings = {};
                  TEXT.map((string) => {
                    newStrings[string.handle] = string.defaultValue;
                  });
                  setConfig(newConfig);
                  setStrings(newStrings);
                }
              }
            ]}
            primaryFooterAction={{
              content: 'Save',
              loading: saving,
              disabled: !hasChanges,
              onAction: () => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }
            }}
            secondaryFooterActions={[
              {
                content: 'Edit code',
                disabled: saving,
                onAction: () => setEditCodeModalOpen(true),
              },
            ]}
          >
            <Stack distribution="fillEvenly" spacing="extraLoose">
              <Stack vertical spacing='loose'>
                <Subheading>HOW TO</Subheading>
                <TextContainer>The events calendar page is a dedicated page on your storefront that displays all of your events in one place. Customers can use this to browse and discover your available events. To show the page on your storefront, simply link to <TextStyle variation='subdued'>{url}</TextStyle>.</TextContainer>
                <TextContainer>For example, to add a new top menu / navigation option on your storefront that points customers to this page:</TextContainer>
                <List type="number">
                  <List.Item>Go to your the Online Store section in your Shopify Dashboard</List.Item>
                  <List.Item>Under the Navigation menu option, choose "Main menu"</List.Item>
                  <List.Item>Tap the "Add menu item" button</List.Item>
                  <List.Item>Enter a name of your choosing (for example "Calendar" or "Events") and the Link as {`${shop.proxyPath}/calendar`}</List.Item>
                  <List.Item>You will now have a menu option on your storefront to access the calendar page</List.Item>
                </List>
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>TEXT (for default language: {defaultLocale})</Subheading>
                {TEXT.map((text) =>
                  <TextField
                    key={text.handle}
                    label={text.label}
                    value={strings[text.handle] || text.defaultValue}
                    helpText={text.helpText}
                    onChange={(v) => setStrings({ ...strings, [text.handle]: v })}
                  />
                )}
              </Stack>
              <Stack vertical spacing='loose'>
                <Subheading>PREFERENCES</Subheading>
                <Autocomplete
                  allowMultiple
                  options={events.filter((e) => e.title.toLowerCase().indexOf(eventsInputText.toLowerCase()) != -1).map((e) => ({ value: e.eveyEventId, label: e.title }))}
                  selected={selectedEvents}
                  textField=<Autocomplete.TextField
                    onChange={setEventsInputText}
                    label="Select the events to hide from this calendar"
                    value={eventsInputText}
                    placeholder="Event title"
                  />
                  onSelect={handleEventsChange}
                  listTitle="Events"
                />
                {selectedEvents.length > 0 ? (
                    <Stack vertical={false} spacing='tight'>
                      {selectedEvents.map((eventId, index) => (
                        <Tag
                          key={eventId}
                          onRemove={() => {
                            let tmp = [...selectedEvents];
                            tmp.splice(index, 1);
                            handleEventsChange(tmp);
                          }}
                        >
                          {events.find((e) => e.eveyEventId == eventId).title}
                        </Tag>
                      ))}
                    </Stack>
                  ) : ('')
                }
                <ChoiceList
                  title="Initial layout"
                  choices={[
                    { label: 'Month view', value: 'dayGridMonth'},
                    { label: 'List view', value: 'listMonth' },
                  ]}
                  selected={[(config || {}).calendar_initial_view || 'dayGridMonth']}
                  onChange={(v) => {
                    setConfig({
                      ...config,
                      calendar_initial_view: v[0],
                    });
                  }}
                />
              </Stack>
            </Stack>
            <SaveBar
              show={hasChanges}
              loading={saving}
              onDiscard={() => {
                setConfig(originalConfig);
                setStrings(originalStrings);
              }}
              onSave={() => {
                editAttendeeInfoStorefrontIntegration({
                  variables: {
                    config: JSON.stringify(config),
                    strings: JSON.stringify(strings),
                  }
                });
              }}
            />
            {editCodeModalOpen && <EditEventsCalendarCodeModal shop={shop} onClose={() => setEditCodeModalOpen(false)} />}
          </Card>
        )}
      </Mutation>
    </>
  );
};

export default EventsCalendarCard;
