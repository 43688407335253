import React from 'react';
import { Card, FormLayout, Button, OptionList, Select, TextField, Checkbox, Collapsible } from '@shopify/polaris';
import { parseBoolean } from '../../utils/parse';
import { slugify } from '../../utils/random';
import {
  DeleteMajor,
  CircleCancelMajor,
  CirclePlusMajor,
} from '@shopify/polaris-icons';

const AttendeeAttributesCard = ({ event, attendee, selectedTicket, attendeeAttributes, setAttendeeAttributes, disabled }) => {
  const [addAttendeeAttributeClicked, setAddAttendeeAttributeClicked] = React.useState(false);
  const [newAttendeeAttributeName, setNewAttendeeAttributeName] = React.useState('');

  const addAttendeeAttribute = (label) => {
    let name = slugify(label);
    setAttendeeAttributes({
      ...attendeeAttributes,
      [name]: {
        spec: {
          name: name,
          label: label,
          type: 'text'
        },
        value: ''
      },
    });
    setAddAttendeeAttributeClicked(false);
    setNewAttendeeAttributeName('');
  }

  return (
    <Card sectioned>
      <FormLayout>
        {Object.entries(attendeeAttributes).map(([name, attr]) => {
          const spec = attr.spec;

          if (spec.name == 'firstname' || spec.name == 'lastname' || spec.name == 'email') {
            return;
          }
          if (['header', 'custom'].includes(spec.type)) {
            return;
          }

          if (selectedTicket && spec.ticket_types != null && !spec.ticket_types.some((id) => id == selectedTicket.id)) {
            return;
          }

          let deleteButton = disabled ? '' : (
            <div style={{ marginTop:'24px' }}>
              <Button
                key={`attr_delete_${spec.name}`}
                icon={DeleteMajor}
                onClick={() => {
                  let tmp = { ...attendeeAttributes };
                  delete tmp[spec.name];
                  setAttendeeAttributes(tmp);
                }}
              >
              </Button>
            </div>
          );

          if (spec.type == 'select') {
            if (spec.selectMultiple) {
              return (
                <FormLayout.Group key={spec.name}>
                  <OptionList
                    title={spec.label}
                    onChange={(v) => setAttendeeAttributes({
                      ...attendeeAttributes,
                      [spec.name]: {
                        ...attendeeAttributes[spec.name],
                        value: v,
                      },
                    })}
                    options={(spec.options || []).map((opt) => ({ value: opt, label: opt }))}
                    selected={attendeeAttributes[spec.name]?.value || []}
                    allowMultiple
                    disabled={disabled}
                  />
                </FormLayout.Group>
              );
            } else {
              return (
                <FormLayout.Group key={spec.name}>
                  <Select
                    value={attendeeAttributes[spec.name]?.value}
                    options={spec.options}
                    label={spec.label}
                    disabled={disabled}
                    onChange={(v) => setAttendeeAttributes({
                      ...attendeeAttributes,
                      [spec.name]: {
                        ...attendeeAttributes[spec.name],
                        value: v,
                      },
                    })}
                  />
                  {deleteButton}
                </FormLayout.Group>
              );
            }
          } else if (spec.type == 'checkbox') {
            return (
              <FormLayout.Group key={spec.name}>
                <Checkbox
                  checked={parseBoolean(attendeeAttributes[spec.name]?.value)}
                  label={spec.label}
                  disabled={disabled}
                  onChange={(v) => setAttendeeAttributes({
                    ...attendeeAttributes,
                    [spec.name]: {
                      ...attendeeAttributes[spec.name],
                      value: v,
                    },
                  })}
                />
                {deleteButton}
              </FormLayout.Group>
            );
          } else {
            return (
              <FormLayout.Group key={spec.name}>
                <TextField
                  value={attendeeAttributes[spec.name]?.value || ''}
                  label={spec.label}
                  disabled={disabled}
                  onChange={(v) => setAttendeeAttributes({
                    ...attendeeAttributes,
                    [spec.name]: {
                      ...attendeeAttributes[spec.name],
                      value: v,
                    },
                  })}
                />
                {deleteButton}
              </FormLayout.Group>
            );
          }
        })}
        {!disabled &&
          <div>
            <Button
              key="addAttendeeAttribute"
              id="addAttendeeAttribute"
              icon={addAttendeeAttributeClicked ? CircleCancelMajor : CirclePlusMajor}
              plain={true}
              onClick={() => setAddAttendeeAttributeClicked(!addAttendeeAttributeClicked) }
            >
              {addAttendeeAttributeClicked ? 'Cancel new field' : 'Add Field'}
            </Button>
            <Collapsible open={addAttendeeAttributeClicked} id="addAttendeeattributeCollasp">
              <div onKeyDown={(event) => {
                if (event.keyCode == 13) {
                  event.preventDefault();
                  if (newAttendeeAttributeName.length > 0) {
                    addAttendeeAttribute(newAttendeeAttributeName);
                  }
                }
              }}>
                <FormLayout.Group condensed>
                  <TextField
                    key='addAttendeeAttributeNameInput'
                    id='addAttendeeAttributeNameInput'
                    value={newAttendeeAttributeName}
                    label="" placeholder="Field Name"
                    onChange={setNewAttendeeAttributeName}
                  />
                  <Button
                    key='addAttendeeAttributeSubmit'
                    id="addAttendeeAttributeSubmit"
                    disabled={newAttendeeAttributeName.length == 0}
                    onClick={() => {
                      addAttendeeAttribute(newAttendeeAttributeName);
                    }}
                  >
                    Add
                  </Button>
                </FormLayout.Group>
              </div>
            </Collapsible>
          </div>
        }
      </FormLayout>
    </Card>
  );
};

export default AttendeeAttributesCard;
