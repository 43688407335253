import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card, PageActions, FormLayout, TextField, DataTable, Link as ShopifyLink, ExceptionList, Badge } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import AppPage from '../AppPage/AppPage.jsx';
import { currentShopDomain } from '../../utils/auth';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import OrderCard from './OrderCard';

const LOOKUP_ORDER = gql`
  query OrderLookupQuery($id: String!) {
    currentShop {
      domain
      name
      id
      order(id: $id) {
        id
        orderName
        firstName
        lastName
        name
        email
        phone
        remoteId
        eventOrdersCount
        attendeesCount
        orderUrl
        attendees {
          id
          name
          ticketCode
          ticketUrl
          createdAt
          lineItemId
          shopifyEventId
          cancelledAt
          refundedAt
        }
        orderProcessingEvents {
          id
          createdAt
          name
          description
          altId
        }
        shopifyOrder {
          id
          name
          financialStatus
          lineItems {
            id
            name
            productId
            productTitle
            variantId
            variantTitle
            quantity
            event {
              id
              title
            }
            ticket {
              title
              isDeleted
            }
          }
        }
      }
    }
  }
`;

const OrderLookup = () => {
  const [searchValue, setSearchValue] = React.useState((new URLSearchParams(window.location.search)).get('id') || '');
  const [orderId, setOrderId] = React.useState((new URLSearchParams(window.location.search)).get('id') || '');

  const { showToastNotice, showToastError, canCreateEvents } = useFrame();
  const history = useHistory();

  const shopDomain = currentShopDomain();

  return (
    <AppPage
      title="Order Lookup"
      primaryAction={canCreateEvents ? {
        content: 'Create Event',
        onAction: () => goToPage({ history, path: '/events/new' }),
      } : undefined}
      secondaryActions={[
        {
          content: 'Back to Events',
          onAction: () => goToPage({ history, path: '/events' }),
          target: 'APP'
        },
        {
          content: 'Help',
          url: 'https://evey-events.zendesk.com/hc/en-us/',
          target: 'REMOTE',
          external: true
        },
      ]}
    >
      <Query
        query={LOOKUP_ORDER}
        variables={{ id: orderId }}
        skip={!orderId || orderId.length == 0}
        onError={() => {
          setOrderId('');
          showToastError('Order lookup failed');
        }}
      >
        {({ data, loading, error }) => {
          const shop = data?.currentShop;
          const order = shop?.order;

          return (
            <div>
              <Card title="Order lookup" sectioned>
                <FormLayout>
                  <div
                    onKeyDown={(event) => {
                      if (event.keyCode == 13 && searchValue && searchValue.length > 0) {
                        setOrderId(searchValue);
                      }
                    }}
                  >
                    <TextField
                      key='orderLookupId'
                      value={searchValue}
                      label='Unique order ID or name'
                      placeholder='398590438789 or #12345'
                      onChange={setSearchValue}
                      helpText='The unique ID or name for the order you want more infomation about. We only have access to orders from the past 60 days, if you require information on an order from before that time please contact us directly at support@eveyevents.com.'
                    />
                  </div>
                </FormLayout>
                <PageActions
                  primaryAction={{
                    content: 'Lookup Order',
                    loading: loading,
                    disabled: !searchValue || searchValue.length == 0,
                    onClick: () => setOrderId(searchValue),
                  }}
                />
              </Card>
              {order &&
                <OrderCard shop={shop} order={order} />
              }
              {orderId && orderId.length > 0 && !order && !loading &&
                <Card sectioned>
                  <p>This order cannot be found in the app. Try <ShopifyLink external url={`https://${shopDomain}/admin/orders?selectedView=all&query=${searchValue}`} target="_blank">searching for it in the Shopify Dashboard</ShopifyLink> directly.</p>
                </Card>
              }
            </div>
          );
        }}
      </Query>
    </AppPage>
  );
};

export default OrderLookup;
