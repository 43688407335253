import React from 'react';
import AppPage from '../AppPage/AppPage.jsx';
import { Layout, Spinner, Stack, TextStyle, Link as ShopifyLink } from '@shopify/polaris';
import { Query, Mutation } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { FullHeightContainer } from '../shared/containers';
import styled from '@emotion/styled';
import PlanCard from './PlanCard';
import { useFrame } from '../../contexts/frame';
import { getHostFromShopDomain } from '../../utils/auth';
import { GET_PLANS, CREATE_SUBSCRIPTION } from '../../utils/graphql';

const FooterContainer = styled.p`
  text-align:center;
  padding-top:25px;
`;

const CardsContainer = styled.p`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
`;

const PlansPage = () => {
  const [selectedPlan, setSelectedPlan] = React.useState(null);

  const history = useHistory();
  const { showToastNotice, showToastError, navigateTo } = useFrame();

  const nextUrl = (new URLSearchParams(window.location.search)).get('nextUrl');
  const nextLabel = (new URLSearchParams(window.location.search)).get('nextLabel');

  return (
    <Query query={GET_PLANS}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <FullHeightContainer alignVertically>
              <Spinner size="small" />
            </FullHeightContainer>
          );
        };

        const shop = data && data.currentShop;
        const hostParam = "?host=" + getHostFromShopDomain(shop?.domain);
        const availablePlans = shop && shop.availablePlans;
        const activeSubscription = shop && shop.activeSubscription;

        return (
          <AppPage
            title="Change plan"
            pageTitle="Change plan"
            fullWidth={true}
            hideTrialBanner={true}
            secondaryActions={[
              {
                content: nextUrl ? (nextLabel ? `Back to ${nextLabel}` : 'Back') : 'Back to account',
                target: 'APP',
                onAction: () => goToPage({ history, path: nextUrl || '/settings/account' }),
              }
            ]}
            primaryAction={null}
          >
            <Layout>
              <Mutation
                mutation={CREATE_SUBSCRIPTION}
                onError={() => {
                  showToastError('Failed to subscribe');
                  setSelectedPlan(null);
                }}
                onCompleted={(data) => {
                  if (data && data.createSubscription) {
                    const subscription = data.createSubscription.subscription;
                    console.log(`subscription: ${JSON.stringify(subscription)}`);
                    console.log(`confirmationUrl: ${subscription.confirmationUrl}`);
                    navigateTo({ history, path: subscription.confirmationUrl, external: true });
                  } else {
                    showToastError('Unable to subscribe, try again or contact us');
                    setSelectedPlan(null);
                  }
                }}
              >
                {(createSubscription, { loading: selecting }) => (
                  <Layout.Section>
                    <CardsContainer>
                      {availablePlans.map((plan) => (
                        <PlanCard
                          key={plan.id}
                          shop={shop}
                          plan={plan}
                          activeSubscription={activeSubscription}
                          onSelect={() => {
                            setSelectedPlan(plan);
                            createSubscription({
                              variables: {
                                planId: plan.id,
                                nextUrl,
                              },
                            });
                          }}
                          selecting={selectedPlan && selectedPlan.id == plan.id}
                          disabled={selectedPlan && selectedPlan.id != plan.id}
                        />
                      ))}
                    </CardsContainer>
                    <FooterContainer>
                      <TextStyle variation="strong">
                        Nothing here fit your requirements?{' '}
                      </TextStyle>
                      <ShopifyLink onClick={() => { window.open(`mailto:hello@eveyevents.com`, '_blank') }}>
                        Contact us
                      </ShopifyLink>{' '}
                      to talk about custom solutions.
                    </FooterContainer>
                  </Layout.Section>
                )}
              </Mutation>
            </Layout>
          </AppPage>
        );
      }}
    </Query>
  );
}

export default PlansPage;
