import React from 'react';
import { Mutation } from 'react-apollo';
import { COPY_EVENT } from '../../utils/graphql';
import { FormLayout, Modal, TextField, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { goToPage } from '../../utils/routing';
import { parseBoolean } from '../../utils/parse';

const CopyEventModal = ({ shop, event, onDismiss, onCopied }) => {
  const [title, setTitle] = React.useState(`Copy of ${event.title}`);
  const [draft, setDraft] = React.useState(false);
  const [copySchedules, setCopySchedules] = React.useState(false);
  const [jobId, setJobId] = React.useState();
  const [copying, setCopying] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={COPY_EVENT}
      onError={() => showToastError('Failed to copy event')}
      onCompleted={(data) => {
        const jobId = data.copyEvent.jobId;

        const checkStatus = async () => {
          const response = await fetch(`/event_copy_status?shop=${shop.domain}&job_id=${jobId}`);
          const result = await response.json();
          console.log(`result: `, result);
          const status = result.status || '';
          if (status.startsWith('Completed:')) {
            const eventId = status.split(':')[1];
            console.log('Event copied: ', eventId);
            showToastNotice('Event copied');
            onCopied(eventId);
          } else if (!status || status == 'Failed') {
            showToastError('Failed to copy event..')
            setCopying(false);
          } else {
            setTimeout(checkStatus, 1000);
          }
        };

        checkStatus();
      }}
    >
      {(copyEvent, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onDismiss}
          title='Copy event'
          primaryAction={{
            content: 'Save copy',
            loading: copying,
            onAction: () => {
              setCopying(true);
              copyEvent({
                variables: {
                  eventId: event.id,
                  title,
                  draft: parseBoolean(draft),
                  copySchedules: parseBoolean(copySchedules),
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: copying,
              onAction: () => onDismiss(),
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                value={title}
                label="Event Name"
                placeholder="Awesome Event"
                onChange={(v) => setTitle(v)}
              />
              <Checkbox
                checked={draft}
                label="Create product as draft"
                onChange={(v) => setDraft(v)}
              />
              {event.publishSchedule || event.unpublishSchedule ? (
                <Checkbox
                  checked={copySchedules}
                  label="Copy product availaibility schedule"
                  onChange={setCopySchedules}
                />
              ) : ''}
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default CopyEventModal;
