import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Card, DataTable, Badge, DropZone, Spinner, Link as ShopifyLink } from '@shopify/polaris';
import { Link } from 'react-router-dom';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';

export default class AttendeeImportList extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    fileUploading: false
  }

  EVENT_FIELDS = `
    attendeeImports {
      id
      startedAt
      completedAt
      filename
      fileUrl
      totalProcessed
      totalSuccess
      totalErrors
      assignments
      importErrors
    }
  `;

  CREATE_IMPORT = gql`
    mutation createAttendeeImport($eventId: ID!, $file: Upload!) {
      createAttendeeImport(eventId: $eventId, file: $file) {
        attendeeImport {
          id
        }
      }
    }
  `;

  renderImportCreate = (shop, event) => {
    return (
      <Mutation
        mutation={this.CREATE_IMPORT}
        onError={() => {
          this.props.flashCallback(true, 'Invalid file for import', true);
          this.setState({ fileUploading: false });
        }}
        onCompleted={(data) => {
          this.setState({ fileUploading: false });
          this.props.history.push(`/events/${event.id}/attendees_imports/${data.createAttendeeImport.attendeeImport.id}`);
          window.scrollTo({ top: 0 });
        }}
      >
        {(createAttendeeImport) => (
          <Card title="New Import" sectioned>
            <div>
              <DropZone
                label="Step 1, select CSV file for import"
                allowMultiple={false}
                disabled={!shop.trialExpired}
                onDropAccepted={(acceptedFiles) => {
                  this.setState({ fileUploading: true });
                  createAttendeeImport({
                    variables:{
                      eventId: event.id,
                      file: acceptedFiles[0]
                    }
                  });
                }}
              >
                {!this.state.fileUploading &&
                  <DropZone.FileUpload
                    actionTitle='Select CSV file'
                    actionHint='or drop CSV file to begin'
                  />
                }
                {this.state.fileUploading &&
                  <div style={{textAlign:'center',marginTop:'50px'}}>
                    <Spinner size="large" color="teal" />
                  </div>
                }
              </DropZone>
            </div>
          </Card>
        )}
      </Mutation>
    );
  }

  renderPageContent = (shop, event) => {
    const attendeeImports = event.attendeeImports;

    if (attendeeImports.length > 0) {
      let rows = [];
      for (var i in attendeeImports) {
        let imp = attendeeImports[i];

        let idLink = <Link to={`/events/${event.id}/attendees_imports/${imp.id}`}>{imp.id}</Link>;
        let filenameLink = <ShopifyLink external={true} url={imp.fileUrl}>{imp.filename}</ShopifyLink>;

        let completedAt = imp.completedAt;
        if (completedAt == null || completedAt.length == 0) {
          if (imp.startedAt && imp.startedAt.length > 0) {
            completedAt = <Badge status='info' progress='partiallyComplete'>processing</Badge>;
          } else {
            completedAt = <Badge status='warning' progress='incomplete'>not started</Badge>;
          }
        } else {
          completedAt = (new Date(completedAt)).toLocaleString();
        }

        let startedAt = imp.startedAt;
        if (startedAt != null && startedAt.length > 0) {
          startedAt = (new Date(startedAt)).toLocaleString();
        }

        let totalErrors = imp.totalErrors;

        rows.push([idLink, filenameLink, completedAt, imp.totalProcessed, totalErrors]);
      }

      return (
        <div>
          {this.renderImportCreate(shop, event)}
          <Card title="Previous imports" sectioned>
            <DataTable
              columnContentTypes={[
                'text',
                'text',
                'text',
                'numeric',
                'numeric'
              ]}
              headings={[
                'ID',
                'File',
                'Completed Date',
                'Rows Processed',
                'Errors'
              ]}
              rows={rows}
            />
          </Card>
        </div>
      );
    } else {
      return this.renderImportCreate(shop, event);
    }
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          return (
            <EventPage
              shop={shop}
              event={event}
              title="Attendee Imports"
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              {this.renderPageContent(shop, event)}
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

AttendeeImportList.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
