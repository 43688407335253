import React from 'react';
import gql from 'graphql-tag';
import {
  Modal,
  TextContainer,
  Button,
  TextField,
} from '@shopify/polaris';
import { useState, useCallback } from 'react';
import { client } from '../../renderApp.js';
import { useFrame } from '../../contexts/frame';
import { useHistory } from "react-router-dom";

const SAVE_FEEDBACK = gql`
  mutation createFeedback($feedback: String!, $positive: Boolean!) {
    createFeedback(feedback: $feedback, positive: $positive) {
      success
    }
  }
`;

const FeedbackModal = ({ open, onClose }) => {
  const [showFeedbackText, setShowFeedbackText] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleShowFeedbackText = useCallback(
    () => setShowFeedbackText(!showFeedbackText),
    [showFeedbackText]
  );

  const [feedbackText, setFeedbackText] = useState('');
  const handleFeedbackTextChange = useCallback(
    (value) => setFeedbackText(value),
    []
  );

  const { showToastNotice } = useFrame();
  const history = useHistory();

  const saveFeedback = async ({ feedback, positive }) => {
    setIsSaving(true);
    console.log('Saving feedback');
    try {
      await window.client.mutate({
        mutation: SAVE_FEEDBACK,
        variables: {
          feedback: feedback,
          positive,
        }
      });
      showToastNotice('Feedback submitted, thanks!');
    } catch (err) {
      console.log('Error: ' + JSON.stringify(err));
    }
    setIsSaving(false);
  };

  const primaryAction = showFeedbackText
    ? {
      content: 'Submit feedback',
      loading: isSaving,
      disabled: !feedbackText.length,
      onAction: async () => {
        await saveFeedback({
          feedback: feedbackText,
          positive: false,
        });
        onClose();
      },
    }
    : undefined;

  const secondaryActions = showFeedbackText
    ? [
      {
        content: 'Cancel',
        disabled: isSaving,
        onAction: async () => {
          await saveFeedback({
            feedback: 'FEEDBACK_MODAL_NEGATIVE_CANCELLED',
            positive: false,
          });
          onClose();
        },
      },
    ]
    : undefined;

  return (
    <Modal
      open={open}
      onClose={async () => {
        await saveFeedback({
          feedback: 'FEEDBACK_MODAL_CANCELLED',
          positive: false,
        });
        onClose();
      }}
      title="Is Evey Events working well for you and your events?"
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <p className="FeedbackModal__Note">
          We&apos;re always working to improve the app! We&apos;d love to hear any suggestions or comments to help us do that.
        </p>
        {!showFeedbackText && (
          <TextContainer>
            <div className="FeedbackModal">
              <div className="FeedbackModal__Buttons">
                <Button fullWidth onClick={handleShowFeedbackText}>
                  No, it could be better
                </Button>
              </div>
              <div className="FeedbackModal__Buttons">
                <Button
                  primary
                  fullWidth
                  onClick={() => {
                    saveFeedback({
                      feedback: 'FEEDBACK_MODAL_POSITIVE',
                      positive: true,
                    });
                    window.open(
                      'https://apps.shopify.com/evey-event-management#modal-show=ReviewListingModal'
                    );
                    onClose();
                  }}
                >
                  Yes, it&apos;s great!
                </Button>
              </div>
            </div>
          </TextContainer>
        )}
        {showFeedbackText && (
          <TextField
            label="Suggestions or comments"
            value={feedbackText}
            onChange={handleFeedbackTextChange}
            multiline={3}
            placeholder=""
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

export default FeedbackModal;
