import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Card, DataTable, TextStyle, Link as ShopifyLink } from '@shopify/polaris';
import AppPage from '../AppPage/AppPage.jsx';
import { Link, useLocation } from 'react-router-dom';
import ErrorPage from '../Error/ErrorPage.jsx';
import InitLoading from '../InitLoading.jsx';
import { getHostFromShopDomain } from '../../utils/auth';

export default class CustomerShow extends EveyComponent {
  constructor(props) {
    super(props);
  }

  shop = null;
  customer = null;

  state = {
  };

  GET_CUSTOMER = gql`
    query CustomerQuery($customerId: String) {
      currentShop {
        id
        domain
        customer(id: $customerId) {
          id
          name
          email
          ticketCount
          orders {
            id
            orderUrl
            orderName
            createdAt
          }
          eventStats {
            attendees {
              id
              number
            }
            event {
              id
              title
              prettyDateRange
              attendeeAttributeSpecs {
                name
                label
                type
                options
                selectMultiple
              }
            }
            orders {
              id
              orderUrl
              orderName
            }
            totalSales
          }
          attendees {
            id
            name
            email
            number
            ticketTitle
            event {
              id
              title
            }
            attendeeAttributes {
              value
              spec {
                name
                label
                type
                options
                selectMultiple
              }
            }
          }
        }
      }
    }
  `;

  renderCustomAttributeValue = (attr) => {
    if (attr && attr.value) {
      if (attr.spec.type == 'select' && attr.spec.selectMultiple && attr.value.indexOf('[') == 0) {
        return JSON.parse(attr.value).join(', ');
      } else {
        return attr.value;
      }
    } else {
      return '';
    }
  }

  renderOrders = (shop, customer) => {
    const location = useLocation();
    const searchParams = "?host=" + getHostFromShopDomain(shop?.domain); 

    if (!customer.orders || customer.orders.length == 0) {
      return (
        <TextStyle variation="subdued">There are no orders for this customer. This usually means that all tickets were manually created.</TextStyle>
      );
    }

    const columnContentTypes = [
      'text',
      'text'
    ];
    const headings = [
      'Number',
      'Date'
    ];
    let rows = [];
    for (let i in customer.orders) {
      let order = customer.orders[i];
      let orderLink = <ShopifyLink external url={`${order.orderUrl}${searchParams}`} target="_blank">{order.orderName}</ShopifyLink>;
      let dateString = this.formatDate(order.createdAt);
      rows.push([orderLink, dateString]);
    }
    return (
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
      />
    );
  }

  renderEvents = (shop, customer) => {
    const columnContentTypes = [
      'text',
      'text',
      'text',
      'numeric',
      'text',
      'text'
    ];
    const headings = [
      'Order',
      'Event',
      'Date',
      'Tickets',
      'Attendees',
      'Total Sales'
    ];
    let rows = [];

    for (let i in customer.eventStats) {
      let event = customer.eventStats[i].event;
      let orders = customer.eventStats[i].orders;
      let attendees = customer.eventStats[i].attendees;
      let totalSales = customer.eventStats[i].totalSales;

      let orderLinks = [];
      for (let j in orders) {
        let order = orders[j];
        orderLinks.push(
          <li><ShopifyLink external url={`${order.orderUrl}${searchParams}`} target="_blank">{order.orderName}</ShopifyLink></li>
        );
      }

      let attendeeLinks = [];
      for (let j in attendees) {
        let attendee = attendees[j];
        attendeeLinks.push(
          <li><Link to={`/events/${event.id}/attendees/${attendee.id}/edit`}>#{attendee.number}</Link></li>
        );
      }

      rows.push([
        <ul key={`customerEventOrders${i}`} className="CommaSeparatedList">{orderLinks}</ul>,
        <Link key={`customerEvent${i}`} to={`/events/${event.id}/attendees`}>{event.title}</Link>,
        event.prettyDateRange,
        attendees.length,
        <ul key={`customerEventAttendees${i}`} className="CommaSeparatedList">{attendeeLinks}</ul>,
        totalSales
      ]);
    }

    return (
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
      />
    );
  }

  renderTickets = (shop, customer) => {
    let attributeSpecs = [];
    for (let i in customer.eventStats) {
      let event = customer.eventStats[i].event;
      for (let j in event.attendeeAttributeSpecs) {
        let spec = event.attendeeAttributeSpecs[j];

        let alreadyExists = false;
        for (let k in attributeSpecs) {
          if (attributeSpecs[k].name == spec.name) {
            alreadyExists = true;
            break;
          }
        }

        if (!alreadyExists) {
          attributeSpecs.push(spec);
        }
      }
    }

    const columnContentTypes = [
      'text',
      'text',
      'text',
      'text',
    ];
    const headings = [
      'Name',
      'Email',
      'Event',
      'Ticket Type',
    ];
    for (let i in attributeSpecs) {
      columnContentTypes.push('text');
      headings.push(attributeSpecs[i].label);
    }

    let rows = [];
    for (let i in customer.attendees) {
      let attendee = customer.attendees[i];

      let attendeeLink = <Link to={`/events/${attendee.event.id}/attendees/${attendee.id}/edit`}>{attendee.name}</Link>;
      let eventLink = <Link to={`/events/${attendee.event.id}/attendees`}>{attendee.event.title}</Link>;

      let row = [attendeeLink, attendee.email, eventLink, attendee.ticketTitle];
      for (let i in attributeSpecs) {
        let attendeeAttr = null;
        for (let j in attendee.attendeeAttributes) {
          let attr = attendee.attendeeAttributes[j];
          if (attr.spec.name == attributeSpecs[i].name) {
            attendeeAttr = attr;
          }
        }
        if (attendeeAttr) {
          row.push(this.renderCustomAttributeValue(attendeeAttr));
        } else {
          row.push('');
        }
      }

      rows.push(row);
    }

    return (
      <DataTable
        columnContentTypes={columnContentTypes}
        headings={headings}
        rows={rows}
      />
    );
  }

  render() {
    return (
      <Query
        query={this.GET_CUSTOMER}
        variables={{
          'customerId': this.props.match.params.customerId,
        }}
      >
        {({ loading, error, data }) => {
          if (error) return <ErrorPage error={error}/>;
          if (loading) return <InitLoading />;

          if (data.currentShop) {
            this.shop = data.currentShop;
            this.customer = data.currentShop.customer;
          }

          return (
            <AppPage
              title={this.customer.name}
              fullWidth={true}
              breadcrumbs={[
                {
                  content: 'Master Attendance Report',
                  onAction: () => {
                    this.props.history.push('/customers');
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              primaryAction={{
                content: 'Edit Customer',
                onAction: () => {
                  this.props.history.push(`/customers/${this.customer.id}/edit`);
                  window.scrollTo({ top: 0 });
                }
              }}
              secondaryActions={[
                {
                  content: 'Back to Customers',
                  onAction: () => {
                    this.props.history.push('/customers');
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              <div>
                <Card title={this.customer.name} sectioned>
                  <Card.Section title="Events">
                    {this.renderEvents(this.shop, this.customer)}
                  </Card.Section>
                  <Card.Section title="Tickets">
                    {this.renderTickets(this.shop, this.customer)}
                  </Card.Section>
                  <Card.Section title="Orders">
                    {this.renderOrders(this.shop, this.customer)}
                  </Card.Section>
                </Card>
              </div>
            </AppPage>
          );
        }}
      </Query>
    );
  }
}

CustomerShow.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      customerId: PropTypes.string
    })
  })
};
