import React from 'react';
import SettingsPage from './SettingsPage';
import { Layout } from '@shopify/polaris';
import NotificationSettings from '../Notifications/NotificationSettings';
import { Query } from 'react-apollo';
import { GET_DEFAULT_NOTIFICATION_TEMPLATE } from '../../utils/graphql';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';

const NotificationsPage = () => {

  return (
    <SettingsPage
      pageTitle='Notifications'
      title='Notifications'
      fullWidth
      pageSubtitle='Set up and customize all notifications that may be sent to your customers related to their tickets or your events. These are the default settings for all events, you can configure event-specific notifications in the event settings area for each event.'
    >
      <Layout>
        <Query query={GET_DEFAULT_NOTIFICATION_TEMPLATE} >
          {({ loading, data }) => {
            if (loading) {
              return <AnnotatedSectionLoading />;
            }

            return (
              <NotificationSettings shop={data.currentShop} email={data.currentShop.defaultNotificationsTemplate} />
            );
          }}
        </Query>
      </Layout>
    </SettingsPage>
  );
};

export default NotificationsPage;
