import React from 'react';
import { Card, DataTable, Link as ShopifyLink, Button, Banner } from '@shopify/polaris';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const LegacySubscribedEvents = ({ shop, events }) => {
  const history = useHistory();

  const headings = ['Event', 'Subscribed On', ''];
  const columnTypes = ['text', 'text', 'text'];
  let rows = events.map((event) => {
    const eventLink = <ShopifyLink
      onClick={() => goToPage({ history, path: `/events/${event.id}` })}
    >
      {event.title}
    </ShopifyLink>;

    const action = (
      <Button
        destructive
        size='slim'
        onClick={() => goToPage({ history, path: `/events/${event.id}/edit?section=subscription` })}
      >
        Cancel subscription
      </Button>
    );

    return [
      eventLink,
      dayjs(event.subscribedAt).format('YYYY-MM-DD'),
      action,
    ];
  });

  return (
    <div>
      <Banner
        status="info"
        title="Per event billing"
        action={rows.length == 0 ? {
          content: 'Create event',
          onAction: () => goToPage({ history, path: '/events/new' }),
        } : undefined}
      >
        {rows.length > 0 ? (
          <div>
            <p>Per event billing gives you the power to control what events you want to pay for and when. You can manage each one individually on the event settings page. Each event costs {shop.prettyEventPrice} USD per month that you decide to activate it for, with full access to all features.</p>
            <p>You have {rows.length} subscribed {rows.length == 1 ? 'event' : 'events'}. Your total monthly fee is {shop.formattedTotalSubscriptionCost} USD. This charge will appear as a single item on your Shopify billing statement.</p>
            <p>You can add events to this subscription from the Subscription section of each event page separately. Cancel the subscriptions from the individual event pages, click the Cancel subscription button below for the event.</p>
          </div>
        ) : (
          <div>
            <p>Per event billing gives you the power to control what events you want to pay for and when. You can manage each one individually on the event settings page. <strong>Each event costs {shop.prettyEventPrice} USD per month</strong> that you decide to activate it for, with full access to all features.</p>
            <p>You have no active event subscriptions. Create an event or activate an existing event to start selling tickets today!</p>
          </div>
        )}
      </Banner>
      {rows.length > 0 &&
      <Card sectioned>
        <DataTable
          columnContentTypes={columnTypes}
          headings={headings}
          rows={rows}
        />
      </Card>
      }
    </div>
  );
};

export default LegacySubscribedEvents;
