import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, TextField, FormLayout } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import { SAVE_SEATING_CHART } from '../../../utils/graphql';

const AddSeatingChartModal = ({ shop, onClose, onCompleted }) => {
  const [name, setName] = React.useState('');

  const { showToastNotice, showToastError } = useFrame();

  const isValid = name && name.length > 0;

  return (
    <Mutation
      mutation={SAVE_SEATING_CHART}
      onError={() => showToastError('Failed to add seating plan')}
      onCompleted={(data) => {
        showToastNotice('Seating plan created');
        onCompleted(data.saveSeatingChart.seatingChart);
      }}
      refetchQueries={() => ['GetLocations']}
    >
      {(saveSeatingChart, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title={'Add seating plan'}
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              saveSeatingChart({
                variables: {
                  name,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                label="Name"
                value={name}
                placeholder="Venue A seating max capacity"
                onChange={setName}
                helpText='Internal name for seating plan, only used to help you organize and select the chart'
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default AddSeatingChartModal;
