import React from 'react';
import { Modal } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import { DELETE_REPORT } from '../../utils/graphql';
import { useFrame } from '../../contexts/frame';

const DeleteReportModal = ({ event, report, onClose, onDeleted }) => {
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation mutation={DELETE_REPORT}
      onError={() => {
        showToastError('Failed to delete report');
        onClose();
      }}
      onCompleted={(data) => {
        if (data && data.deleteAttendeeReport) {
          showToastNotice('Report deleted');
          onDeleted({
            reports: data.deleteAttendeeReport.attendeeReports,
          });
        }
      }}
    >
      {(deleteAttendeeReport, { loading: deleting }) => (
        <Modal
          title='Confirm attendee report deletion'
          open={true}
          onClose={onClose}
          primaryAction={{
            content: 'Yes, delete it',
            destructive: true,
            loading: deleting,
            onAction: () => {
              deleteAttendeeReport({
                variables:{
                  eventId: event.id,
                  reportId: report.id
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: deleting,
              onAction: onClose,
            }
          ]}
        >
          <Modal.Section>
            Are you sure you want to delete this report? This cannot be reversed.
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default DeleteReportModal;
