import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION, GET_EVENTS } from '../../utils/graphql';
import { Link as ShopifyLink, Card, TextContainer, Banner, Stack, Select, ResourceList, Spinner, OptionList, Popover, Button, TextStyle } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import SaveBar from '../shared/SaveBar';
import { shallowEqual } from '../../utils/compare';
import { getHostFromShopDomain } from '../../utils/auth';
import EventFilters from '../EventListing/EventFilters';
import EventListResourceItem from '../EventListing/EventListResourceItem';

const EventsCollectionCard = ({ shop }) => {
  const originalStringsContent = shop && JSON.parse(shop.defaultLocaleFile.content);
  const originalStrings = originalStringsContent.events && originalStringsContent.events.storefront || {};
  const originalConfig = JSON.parse(shop && shop.attendeeInfoStorefrontConfig || '{}');

  const [config, setConfig] = React.useState(originalConfig);
  const [collectionsPopoverActive, setCollectionsPopoverActive] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const hasChanges = JSON.stringify(originalConfig) != JSON.stringify(config);

  const originalCollection = originalConfig.collection_id && shop.collections.find((c) => c.id == originalConfig.collection_id);

  const collection = config.collection_id && shop.collections.find((c) => c.id == config.collection_id);
  const collectionId = config.collection_id;
  const collectionType = config.collection_type || 'custom';
  const collectionSortOrder = collection && collection.sortOrder;
  const handleCollectionChange = (v) => setConfig({
    ...config,
    collection_id: v && v != 0 ? v : null,
    collection_type: v && v != 0 ? collectionType : null,
  });

  const events = shop.allEvents;

  const sortOrderMarkup = (
    <Select
      label='Sort by'
      labelInline
      options={[
        { label: 'Newest start date', value: '-date' },
        { label: 'Oldest start date', value: 'date' },
        { label: 'High number', value: '-number' },
        { label: 'Low number', value: 'number' },
        { label: 'Title A-Z', value: '-name' },
        { label: 'Title Z-A', value: 'name' }
      ]}
      value={config.collection_sort_by || '-name'}
      onChange={(v) => setConfig({ ...config, collection_sort_by: v })}
    />
  );

  return (
    <>
      <Mutation key='config' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
        onError={() => showToastError('Unable to update collection settings')}
        onCompleted={() => showToastNotice('Collection settings updated')}
        refetchQueries={() => ['StorefrontIntegrationQuery']}
      >
        {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
          <Query
            query={GET_EVENTS}
            variables={{
              category: null,
              sort: config.collection_sort_by,
              filters: config.collection_filters ? JSON.stringify(config.collection_filters.filters || []) : '[]',
              search: config.collection_filters ? config.collection_filters.search : '',
              page: 1,
              perPage: 5
            }}
          >
            {({ loading, data }) => {
              const shopFromQuery = data && data.currentShop;
              const searchParams = "?host=" + getHostFromShopDomain(shopFromQuery?.domain);
              const previewEvents = shopFromQuery && shopFromQuery.events || [];
              const eventsCount = shopFromQuery && shopFromQuery.eventsCount || 0;
              const totalEventCount = shopFromQuery && shopFromQuery.totalEventCount || 0;

              return (
                <Card
                  title='Set up events collection'
                  actions={[
                    {
                      content: 'Go to collection',
                      url: `${shop.adminUrl}/collections/${collection && config.collection_id || ''}${searchParams}`,
                      external: true,
                    },
                  ]}
                  primaryFooterAction={{
                    content: 'Save',
                    loading: saving,
                    disabled: !hasChanges,
                    onAction: () => {
                      editAttendeeInfoStorefrontIntegration({
                        variables: {
                          config: JSON.stringify(config),
                          strings: JSON.stringify(originalStrings),
                          syncCollection: true,
                        }
                      });
                    }
                  }}
                >
                  <Card.Section>
                    <Stack distribution="fillEvenly" spacing="extraLoose">
                      <Stack vertical spacing='loose'>
                        <TextContainer>The events collection allows you to connect the app to a <ShopifyLink external url={`${shop.adminUrl}/collections${searchParams}`} target="_blank">collection in your Shopify Dashboard</ShopifyLink> that you can configure to automatically include events and sorted by event properties such as start date.</TextContainer>
                      </Stack>
                      <Stack vertical spacing='loose'>
                        <TextContainer>Select a collection for your events</TextContainer>
                        <Popover
                          active={collectionsPopoverActive}
                          activator={
                            <Button onClick={() => setCollectionsPopoverActive(!collectionsPopoverActive)} disclosure>
                              <Stack vertical={false} spacing='tight'><TextStyle variation='subdued'>Collection</TextStyle><TextStyle>{collection ? collection.title : '<Disabled>'}</TextStyle></Stack>
                            </Button>
                          }
                          onClose={() => setCollectionsPopoverActive(false)}
                        >
                          <OptionList
                            onChange={(v) => {
                              handleCollectionChange(v[0]);
                              setCollectionsPopoverActive(false);
                            }}
                            allowMultiple={false}
                            sections={[
                              {
                                options: [{ value: 0, label: '<Disabled>' }],
                              },
                              {
                                title: 'Custom collections',
                                options: shop.collections.filter((c) => c.collectionType === 'custom').map((c) => ({ label: c.title, value: c.id })),
                              },
                              {
                                title: 'Automated collections',
                                options: shop.collections.filter((c) => c.collectionType === 'smart').map((c) => ({ label: c.title, value: c.id })),
                              }
                            ]}
                            selected={[collectionId || 0]}
                          />
                        </Popover>
                        {/*
                        <Select
                          label='Select a collection to include events on'
                          options={[
                            { label: '<Disabled>', value: 0 },
                            ...(shop.collections.map((c) => ({ label: c.title, value: c.id }))),
                          ]}
                          value={collectionId || 0}
                          onChange={handleCollectionChange}
                        />
                        */}
                        {originalCollection && originalConfig.collection_id && collectionId && originalConfig.collection_id == collectionId &&
                          <Banner
                            status='info'
                            action={{
                              content: 'Refresh collection',
                              loading: saving,
                              onAction: () => {
                                editAttendeeInfoStorefrontIntegration({
                                  variables: {
                                    config: JSON.stringify(originalConfig),
                                    strings: JSON.stringify(originalStrings),
                                    syncCollection: true,
                                  }
                                });
                              },
                            }}
                          >
                            The collection will be updated hourly, or after saving changes here. Click the button below to force a refresh.
                          </Banner>
                        }
                        {collectionSortOrder && collectionSortOrder.toLowerCase() !== 'manual' &&
                          <Banner status='warning'>
                            This collection has an automatic sorting set up in Shopify. This will be changed to a manual sorting on refresh so that we can apply the event sorting.
                          </Banner>
                        }
                        {collection ?
                          collection.collectionType === 'smart' ? (
                            <Stack vertical spacing='loose'>
                              {sortOrderMarkup}
                              <TextContainer>
                                <TextStyle variation='subdued'>
                                  Automated collections will only have the order of the products changed, we will not change which products are in the collection. Any non-event products will not be sorted.
                                </TextStyle>
                              </TextContainer>
                            </Stack>
                          ) : (
                            <Stack vertical spacing='loose'>
                              <TextContainer>Which events are included in the collection?</TextContainer>
                              <EventFilters
                                filters={{
                                  search: config.collection_filters ? config.collection_filters.search : '',
                                  appliedFilters: config.collection_filters && config.collection_filters.filters || [],
                                }}
                                shop={shop}
                                onUpdate={({ search, filters }) => {
                                  setConfig({ ...config, collection_filters: { search, filters } });
                                }}
                                filterableEvents={events}
                              />
                              {sortOrderMarkup}
                              <TextContainer>
                                {loading ? (
                                  <div style={{textAlign:'center'}}>
                                    <Spinner size="small" color="teal" />
                                  </div>
                                ) : (
                                  <div>{eventsCount} of {totalEventCount} events selected</div>
                                )}
                              </TextContainer>
                            </Stack>
                          ) : ('')
                        }
                      </Stack>
                    </Stack>
                  </Card.Section>
                  {collection && collection.collectionType === 'custom' &&
                    <Card.Section title='Preview'>
                      <ResourceList
                        resourceName={{ singular: 'event', plural: 'events' }}
                        items={previewEvents}
                        loading={loading}
                        showHeader={true}
                        renderItem={(event) => <EventListResourceItem event={event} />}
                      />
                    </Card.Section>
                  }
                  <SaveBar
                    show={hasChanges}
                    loading={saving}
                    onDiscard={() => {
                      setConfig(originalConfig);
                    }}
                    onSave={() => {
                      editAttendeeInfoStorefrontIntegration({
                        variables: {
                          config: JSON.stringify(config),
                          strings: JSON.stringify(originalStrings),
                          syncCollection: true,
                        }
                      });
                    }}
                  />
                </Card>
              );
            }}
          </Query>
        )}
      </Mutation>
    </>
  );
};

export default EventsCollectionCard;
