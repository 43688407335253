import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Link as ShopifyLink, ChoiceList, Checkbox, TextStyle, Collapsible, Stack, Popover, Button, OptionList, InlineError, Banner } from '@shopify/polaris';
import { EDIT_VIRTUAL_EVENT_PAGE } from '../../utils/graphql';
import { NestedFormLayoutContainer } from '../shared/containers';
import { useFrame } from '../../contexts/frame';
import { VIRTUAL_EVENT_SERVICES } from '../../utils/constants';
import { goToPage } from '../../utils/routing';
import { useHistory } from "react-router-dom";

const VirtualEventConfigCard = ({ shop, event }) => {
  const [config, setConfig] = React.useState(JSON.parse(event.virtualEventPage.config || {}));
  const [service, setService] = React.useState(event.virtualEventPage.service || 'livestream');
  const [serviceUrl, setServiceUrl] = React.useState(event.virtualEventPage.serviceUrl);
  const [enableVirtualEventPage, setEnableVirtualEventPage] = React.useState(event.virtualEventPage.enabled);

  const [allTicketTypes, setAllTicketTypes] = React.useState(event.virtualEventPage.showUrlForTicketTypes == null);
  const [selectedTicketTypes, setSelectedTicketTypes] = React.useState(event.virtualEventPage.showUrlForTicketTypes && event.virtualEventPage.showUrlForTicketTypes.map((t) => t.toString()) || []);
  const [ticketTypesPopoverActive, setTicketTypesPopoverActive] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();
  const history = useHistory();

  const selectedTicketTypesTitles = selectedTicketTypes
    .filter((ticketTypeId) => event.tickets.find((t) => t.id == ticketTypeId))
    .map((ticketTypeId) => event.tickets.find((t) => t.id == ticketTypeId).title);
  const ticketTypesLabel = selectedTicketTypesTitles ? `${selectedTicketTypesTitles.length} ticket type${selectedTicketTypesTitles.length == 1 ? '' : 's'} selected` : 'Select ticket types';

  return (
    <Mutation mutation={EDIT_VIRTUAL_EVENT_PAGE}
      onError={() => showToastError('Unable to update virtual event settings')}
      onCompleted={() => showToastNotice('Virtual event settings updated')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editVirtualEventPage, { loading: saving }) => (
        <Card
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editVirtualEventPage({
                variables: {
                  eventId: event.id,
                  service,
                  serviceUrl,
                  enabled: enableVirtualEventPage,
                  showUrlForTicketTypes: !allTicketTypes && selectedTicketTypes ? JSON.stringify(selectedTicketTypes) : null,
                },
              });
            },
          }}
        >
          <Card.Section>
            <FormLayout>
              <ChoiceList
                title="Live video content"
                choices={Object.keys(VIRTUAL_EVENT_SERVICES).map((s) => ({label: VIRTUAL_EVENT_SERVICES[s].label, value: s}))}
                selected={[service]}
                onChange={(v) => setService(v[0])}
              />
              {service == 'zoom' && event.zoom.active ? (
                <Banner status='info'>A Zoom account and meeting is connected to this event, the personal meeting URLs for each registrant will be available on each ticket.</Banner>
              ) : (
                <TextField
                  label={`${VIRTUAL_EVENT_SERVICES[service].labelPrefix} link`}
                  value={serviceUrl}
                  helpText={service == 'zoom' && !event.zoom.active ? <span><ShopifyLink onClick={() => goToPage({ history, path: `/events/${event.id}/edit?section=integrations` })}>Connect your Zoom account</ShopifyLink> on the Integrations page to automatically create registrants on your Zoom meeting or webinar and provide attendees their personal meeting URL.</span> : ''}
                  placeholder={VIRTUAL_EVENT_SERVICES[service].placeholder}
                  onChange={(v) => setServiceUrl(v)}
                />
              )}
              <Checkbox
                value={true}
                checked={allTicketTypes}
                label='Allow access to the virtual event for all ticket types'
                helpText='The virtual event page link will not be given to attendees without access'
                onChange={(v) => setAllTicketTypes(v)}
              />
              {!allTicketTypes &&
                <NestedFormLayoutContainer>
                  <FormLayout>
                    <Popover
                      active={ticketTypesPopoverActive}
                      activator={<Button onClick={() => setTicketTypesPopoverActive(!ticketTypesPopoverActive)}>{ticketTypesLabel}</Button>}
                      onClose={() => setTicketTypesPopoverActive(false)}
                    >
                      <OptionList
                        title='Ticket types'
                        onChange={(v) => setSelectedTicketTypes(v)}
                        options={event.tickets.map((t) => ({ label: t.title, value: t.id.toString() }))}
                        selected={selectedTicketTypes}
                        allowMultiple
                      />
                    </Popover>
                    {(!selectedTicketTypes || selectedTicketTypes.length == 0) &&
                      <InlineError message='No ticket types selected' />
                    }
                  </FormLayout>
                </NestedFormLayoutContainer>
              }
              <ChoiceList
                title="Customer experience"
                choices={[
                  {
                    label: 'Include a link directly to the livestream service on the ticket page',
                    value: 'direct',
                    helpText: 'Attendees will receive a link directly to the livestream with their ticket.',
                  },
                  {
                    label: 'Include a link to a dedicated virtual event page with additional information and features',
                    value: 'page',
                    helpText: <span>Your virtual event page is a place you can offer your attendees that they can access all information about the event and gain access to embedded livestreams or videos. <ShopifyLink url="https://evey-events.zendesk.com/hc/en-us/" external>Learn more.</ShopifyLink></span>,
                  },
                ]}
                selected={[enableVirtualEventPage ? 'page' : 'direct']}
                onChange={(v) => setEnableVirtualEventPage(v[0] === 'page')}
              />
            </FormLayout>
          </Card.Section>
        </Card>
      )}
    </Mutation>
  );
};

export default VirtualEventConfigCard;
