import { currentShopDomain, currentHost } from './auth';
import { trackEvent } from './track';

export const goToPage = ({ history, path, params }) => {
  const shop = currentShopDomain();
  const host = currentHost();
  const q = params ? Object.assign({ shop, host }, params) : { shop, host };
  const queryString = new URLSearchParams(params).toString();
  history.push(path.indexOf('?') === -1 ? `${path}?${queryString}` : `${path}&${queryString}`);
  window.scrollTo({ top: 0 });

  trackEvent(`Navigate to ${(path || '').split('?')[0].replace(/\/\d+\//g, '/00/').replace(/\/\d+$/g, '/00')}`, params);
}
