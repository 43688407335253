import React from 'react';
import { Layout, Card, TextField, FormLayout, Spinner, PageActions } from '@shopify/polaris';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../../utils/routing';
import { ATTENDEE_INFO_SPECS_FIELDS } from '../../../utils/graphql';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { FullHeightContainer } from '../../shared/containers';
import SettingsPage from '../SettingsPage';
import AttendeeInfoCard from '../../AttendeeInfo/AttendeeInfoCard';
import { useFrame } from '../../../contexts/frame';
import CopyTemplateModal from './CopyTemplateModal';

const GET_SPECS = gql`
  query GetAttendeeInfoFieldsTemplates {
    currentShop {
      id
      domain
      attendeeFieldsTemplates {
        id
        name
        specs {
          ${ATTENDEE_INFO_SPECS_FIELDS}
        }
      }
    }
  }
`;

const SAVE_TEMPLATE = gql`
  mutation saveAttendeeFieldsTemplate($templateId: ID, $name: String) {
    saveAttendeeFieldsTemplate(templateId: $templateId, name: $name) {
      template {
        id
      }
    }
  }
`;

const AttendeeFieldsEdit = ({ match }) => {
  const templateId = match.params.templateId == 'new' ? null : match.params.templateId;

  const [name, setName] = React.useState('');
  const [specs, setSpecs] = React.useState([]);
  const [showCopyModal, setShowCopyModal] = React.useState(false);

  const history = useHistory();
  const { showToastNotice, showToastError } = useFrame();

  return (
    <Query
      query={GET_SPECS}
      onCompleted={(data) => {
        const shop = data.currentShop;
        const template = shop.attendeeFieldsTemplates.find((t) => t.id == templateId);
        setName(template ? template.name : '');
        setSpecs(template ? template.specs : []);
      }}
    >
      {({ loading, data }) => {
        if (loading) {
          return (
            <FullHeightContainer alignVertically>
              <Spinner size="small" />
            </FullHeightContainer>
          );
        }

        const shop = data.currentShop;
        const template = shop.attendeeFieldsTemplates.find((t) => t.id == templateId);

        return (
          <Mutation mutation={SAVE_TEMPLATE}
            onError={() => {
              showToastError('Unable to update attendee info template');
            }}
            onCompleted={(data) => {
              showToastNotice('Attendee info template updated');
              goToPage({ history, path: `/settings/attendee_info/${data.saveAttendeeFieldsTemplate.template.id}` });
            }}
            refetchQueries={['GetAttendeeInfoFieldsTemplates']}
          >
            {(saveAttendeeFieldsTemplate, { loading: saving }) => (
              <SettingsPage
                title={template ? template.name : 'New information template'}
                pageTitle={template ? template.name : 'New information template'}
                backToPath='/settings/attendee_info'
                extraBreadcrumbs={[
                  {
                    content: 'Attendee information',
                    onAction: () => goToPage({ history, path: '/settings/attendee_info' }),
                    target: 'APP'
                  }
                ]}
              >
                <Layout>
                  <Layout.Section>
                    <Card sectioned
                      primaryFooterAction={{
                        content: 'Save',
                        loading: saving,
                        onAction: () => {
                          saveAttendeeFieldsTemplate({
                            variables: {
                              templateId: template?.id,
                              name,
                            },
                          });
                        },
                      }}
                    >
                      <FormLayout>
                        <TextField
                          label='Name'
                          value={name}
                          onChange={setName}
                          helpText='For internal use only to help you organize your templates'
                        />
                      </FormLayout>
                    </Card>
                  </Layout.Section>
                  {template ? (
                    <Layout.Section>
                      <AttendeeInfoCard
                        specs={template?.specs}
                        template={template}
                      />
                    </Layout.Section>
                  ) : (
                    ''
                  )}
                  {template ? (
                    <Layout.Section>
                      <PageActions
                        secondaryActions={[
                          {
                            content: 'Copy template',
                            onAction: () => setShowCopyModal(true),
                          }
                        ]}
                      />
                    </Layout.Section>
                  ) : ''}
                </Layout>
                {!!template && showCopyModal &&
                  <CopyTemplateModal
                    template={template}
                    onDismiss={() => setShowCopyModal(false)}
                    onCopied={(templateId) => {
                      window.location = `/settings/attendee_info/${templateId}`;
                      setShowCopyModal(false);
                    }}
                  />
                }
              </SettingsPage>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default AttendeeFieldsEdit;
