import React from 'react';
import { Mutation } from 'react-apollo';
import { Link as ShopifyLink, DataTable, Card, Badge, Button } from '@shopify/polaris';
import { REVOKE_VIRTUAL_EVENT_SESSION } from '../../utils/graphql';
import { getHostFromShopDomain } from '../../utils/auth';
import { useFrame } from '../../contexts/frame';

const VirtualEventSessionsCard = ({ shop, event, sessions, showAttendee }) => {
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
  const [revokingSessionId, setRevokingSessionId] = React.useState(null);

  const { showToastError, showToastNotice } = useFrame();

  const columnContentTypes = showAttendee ? [
    'text',
    'text',
    'text',
    'text',
  ] : [
    'text',
    'text',
    'text',
  ];

  const headings = showAttendee ? [
    'ID',
    'Attendee',
    'Status',
    '',
  ] : [
    'ID',
    'Status',
    '',
  ];

  return (
    <Mutation mutation={REVOKE_VIRTUAL_EVENT_SESSION}
      onError={() => {
        showToastError('Unable to revoke session');
        setRevokingSessionId(null);
      }}
      onCompleted={() => {
        showToastNotice('Session access revoked');
        setRevokingSessionId(null);
      }}
      refetchQueries={() => ['EventQuery', 'AttendeeQuery']}
    >
      {(revokeVirtualEventSession) => {
        const rows = sessions.map((session) => {
          let row = [
            session.id,
          ];

          if (showAttendee) {
            row.push(<ShopifyLink url={`/events/${event.id}/attendees/${session.attendeeId}${searchParams}`}>{session.attendeeId}</ShopifyLink>);
          }

          row.push(<Badge status='info'>Active</Badge>);
          row.push(
            <Button
              loading={revokingSessionId == session.id}
              disabled={revokingSessionId}
              onClick={() => {
                setRevokingSessionId(session.id);
                revokeVirtualEventSession({
                  variables: {
                    eventId: event.id,
                    id: session.id,
                  },
                });
              }}
            >
              Revoke
            </Button>
          );
          return row;
        });

        return (
          <Card sectioned>
            <DataTable
              columnContentTypes={columnContentTypes}
              headings={headings}
              rows={rows}
            />
          </Card>
        )
      }}
    </Mutation>
  );
};

export default VirtualEventSessionsCard;
