import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, Button, Select, FormLayout, TextContainer, Checkbox, TextField } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { hasPlanFeature } from '../../utils/features';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';
import { useHistory } from "react-router-dom";

const UNLINK_ACCOUNT = gql`
  mutation unlinkMailchimp($mailchimpIntegrationId: ID!) {
    unlinkMailchimp(mailchimpIntegrationId: $mailchimpIntegrationId) {
      success
    }
  }
`;

const EDIT_MAILCHIMP = gql`
  mutation editMailchimp($mailchimpIntegrationId: ID!, $audienceId: String!, $tagEventName: Boolean, $tagTicketType: Boolean, $customTags: String) {
    editMailchimp(mailchimpIntegrationId: $mailchimpIntegrationId, audienceId: $audienceId, tagEventName: $tagEventName, tagTicketType: $tagTicketType, customTags: $customTags) {
      success
    }
  }
`;

const MailchimpCard = ({ shop, mailchimp, redirectTo }) => {
  const [selectedAudience, setSelectedAudience] = React.useState(mailchimp.syncListId || '0');
  const [tagEventName, setTagEventName] = React.useState(mailchimp.tagEventName);
  const [tagTicketType, setTagTicketType] = React.useState(mailchimp.tagTicketType);
  const [customTags, setCustomTags] = React.useState(mailchimp.customTags);
  const [linking, setLinking] = React.useState(false);

  const { showToastNotice, showToastError, navigateTo } = useFrame();
  const history = useHistory();

  const isAccountLinked = mailchimp && mailchimp.token && mailchimp.token.length > 0;
  const appHost = document.head.querySelector('[name=app-host]').content;

  const hasMailchimpFeature = hasPlanFeature({ shop, feature: 'mailchimp' });

  return (
    <Mutation mutation={EDIT_MAILCHIMP}
      onError={() => showToastError('Unable to update audience')}
      onCompleted={(data) => showToastNotice('Mailchimp audience updated')}
      refetchQueries={() => ['EventQuery', 'GetShopIntegrations']}
    >
      {(editMailchimp, { loading: saving }) => (
        <Card
          primaryFooterAction={isAccountLinked ? {
            content: 'Save',
            loading: saving,
            disabled: !hasMailchimpFeature,
            onAction: () => {
              editMailchimp({
                variables: {
                  mailchimpIntegrationId: mailchimp.id,
                  audienceId: selectedAudience,
                  tagEventName,
                  tagTicketType,
                  customTags,
                }
              });
            }
          } : undefined}
        >
          {!hasMailchimpFeature &&
            <Card.Section>
              <UpgradeRequiredBanner message='Upgrade your plan to access the Mailchimp integration settings.' />
            </Card.Section>
          }
          <Card.Section title="Step 1. Link MailChimp account">
            {isAccountLinked ? (
              <Mutation mutation={UNLINK_ACCOUNT}
                onError={() => showToastError('Unable to unlink account')}
                onCompleted={(data) => showToastNotice('Mailchimp account unlinked')}
                refetchQueries={() => ['EventQuery', 'GetShopIntegrations']}
                awaitRefetchQueries={true}
              >
                {(unlinkMailchimp, { loading: unlinking }) => (
                  <FormLayout>
                    <TextContainer>MailChimp Account: {mailchimp.email}</TextContainer>
                    <Button
                      outline={true}
                      destructive={true}
                      loading={unlinking}
                      disabled={saving}
                      onClick={() => {
                        unlinkMailchimp({
                          variables: {
                            mailchimpIntegrationId: mailchimp.id
                          },
                        });
                      }}
                    >
                      Unlink Mailchimp Account
                    </Button>
                  </FormLayout>
                )}
              </Mutation>
            ) : (
              <Button
                outline={true}
                loading={linking}
                disabled={!hasMailchimpFeature}
                onClick={() => {
                  setLinking(true);
                  navigateTo({
                    history,
                    path: `https://${appHost}/auth/mailchimp?shop=${shop.id}&mailchimp_integration_id=${mailchimp.id}&next_url=${redirectTo}`,
                    external: true,
                  });
                }}
              >
                Link Account
              </Button>
            )}
          </Card.Section>
          {isAccountLinked &&
            <Card.Section title="Step 2. Select Mailchimp audience list to sync attendee list with">
              <FormLayout>
                <Select
                  label=''
                  disabled={saving}
                  options={[
                    { label: '<no audience selected>', value: '0' },
                    ...mailchimp.audiences.map((audience) => ({ label: audience.name, value: audience.id })),
                  ]}
                  onChange={setSelectedAudience}
                  value={selectedAudience}
                />
              </FormLayout>
            </Card.Section>
          }
          {isAccountLinked &&
            <Card.Section title="Step 3. Configure tagging options">
              <FormLayout>
                <Checkbox
                  checked={tagEventName}
                  label="Tag the contact with the name of the event they purchased"
                  onChange={setTagEventName}
                  disabled={saving}
                />
                <Checkbox
                  checked={tagTicketType}
                  label="Tag the contact with the name of the ticket type they purchased"
                  onChange={setTagTicketType}
                  disabled={saving}
                />
                <TextField
                  value={customTags}
                  label="Custom tags (comma separated)"
                  placeholder="Events, Summer 2022"
                  onChange={setCustomTags}
                  disabled={saving}
                />
              </FormLayout>
            </Card.Section>
          }
        </Card>
      )}
    </Mutation>
  );
};

export default MailchimpCard;
