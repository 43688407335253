import React from 'react';
import { Query } from 'react-apollo';
import { Modal, Spinner, DataTable, Badge } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const SpinnerContainer = styled.div`
  display:flex;
  height:100px;
  justify-content: center;
  align-items:center;
`;

export const GET_ACTION_SCHEDULE_HISTORY = gql`
  query GetActionScheduleHistory($eventId: ID, $actionScheduleId: ID!) {
    currentShop {
      actionScheduleHistory(eventId: $eventId, actionScheduleId: $actionScheduleId) {
        id
        createdAt
        eventType
        params
      }
    }
  }
`;

const ActionScheduleHistoryModal = ({ event, actionSchedule, onClose }) => {
  const { showToastNotice, showToastError } = useFrame();

  const env = document.head.querySelector('[name=environment]').content;

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={`Schedule availability history`}
      secondaryActions={[
        {
          content: 'Close',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Query
          query={GET_ACTION_SCHEDULE_HISTORY}
          variables={{
            eventId: event.id,
            actionScheduleId: actionSchedule.id,
          }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <SpinnerContainer><Spinner size="large" color="teal" /></SpinnerContainer>;
            }

            const actionScheduleEvents = data && data.currentShop.actionScheduleHistory || [];

            return (
              <DataTable
                verticalAlign='middle'
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                ]}
                headings={[
                  'Date',
                  'Event',
                  'Action',
                ]}
                rows={actionScheduleEvents.map((actionScheduleEvent) => {
                  let parsedParams = {}
                  try {
                    parsedParams = JSON.parse(actionScheduleEvent.params);
                  } catch (err) {
                    parsedParams = {};
                  }
                  const start_date = parsedParams.start_date;
                  const start_time = parsedParams.start_time;

                  return [
                    dayjs(actionScheduleEvent.createdAt).format('LLL'),
                    `${start_date} at ${start_time}`,
                    <Badge status='success'>{actionScheduleEvent.eventType}</Badge>
                  ];
                })}
              />
            );
          }}
        </Query>
      </Modal.Section>
    </Modal>
  );
};

export default ActionScheduleHistoryModal;
