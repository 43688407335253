import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Button, Modal, EmptyState } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';

const EDIT_EVENT_FIELDS = gql`
  mutation editEventFields($eventId: ID!, $eventFields: String) {
    editEventFields(eventId: $eventId, eventFields: $eventFields) {
      success
    }
  }
`;

const AddFieldModal = ({ onDismiss, onAdd }) => {
  const [name, setName] = React.useState('');

  const isValid = name && name.length > 0;

  return (
    <Modal
      open={true}
      onClose={onDismiss}
      title='Add new custom field'
      primaryAction={{
        content: 'Add',
        disabled: !isValid,
        onAction: () => {
          onAdd(name);
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onDismiss,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label='Field name'
            value={name}
            placeholder={'Invite URL, Internal ID, Keynote speaker, etc'}
            onChange={setName}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

const EventFieldsCard = ({ shop, event }) => {
  const [eventFields, setEventFields] = React.useState(JSON.parse(event.metafields || '{}'));
  const [showAddFieldModal, setShowAddFieldModal] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation mutation={EDIT_EVENT_FIELDS}
      onError={() => showToastError('Unable to update fields')}
      onCompleted={(data) => showToastNotice('Custom fields updated')}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEventFields, { loading: saving }) => (
        <Card
          title='Custom fields'
          actions={eventFields && Object.keys(eventFields).length > 0 ? [
            {
              content: 'Add field',
              disabled: saving,
              onAction: () => setShowAddFieldModal(true),
            }
          ] : []}
          primaryFooterAction={eventFields && Object.keys(eventFields).length > 0 ? {
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEventFields({
                variables: {
                  eventId: event.id,
                  eventFields: JSON.stringify(eventFields),
                },
              });
            }
          } : undefined}
        >
          <Card.Section>
          {eventFields && Object.keys(eventFields).length > 0 ? (
            <FormLayout>
              {Object.keys(eventFields).map((key) => (
                <TextField
                  key={key}
                  label={key}
                  multiline={true}
                  value={eventFields[key]}
                  onChange={(v) => {
                    setEventFields({
                      ...eventFields,
                      [key]: v,
                    });
                  }}
                />
              ))}
            </FormLayout>
          ) : (
            <EmptyState
              action={{
                content: 'Add a custom field',
                onAction: () => setShowAddFieldModal(true),
              }}
            >
              <p>
                Use custom fields for this event in any liquid template for tickets or notifications, as well as through our API.
              </p>
            </EmptyState>
          )}
          </Card.Section>
          {showAddFieldModal &&
            <AddFieldModal
              onDismiss={() => setShowAddFieldModal(false)}
              onAdd={(name) => {
                setEventFields({ ...eventFields, [name]: '' });
                setShowAddFieldModal(false);
              }}
            />
          }
        </Card>
      )}
    </Mutation>
  );
};

export default EventFieldsCard;
