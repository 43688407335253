import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, TextContainer } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import gql from 'graphql-tag';

const CREATE_SEATING_VARIANTS = gql`
  mutation createSeatingVariants($eventId: ID!) {
    createSeatingVariants(eventId: $eventId) {
      success
    }
  }
`;

const CreateSeatingVariantsModal = ({ event, onClose }) => {
  const { showToastNotice, showToastError } = useFrame();

  const seatingChart = event.savedLocation && event.savedLocation.seatingChart;
  const variantOptionZoneName = seatingChart && seatingChart.variantOptionZoneName || 'Seating Zone';

  return (
    <Mutation
      mutation={CREATE_SEATING_VARIANTS}
      onError={() => showToastError('Failed to create seating variant options')}
      onCompleted={(data) => {
        showToastNotice('Seating variant options created');
        onClose();
      }}
      refetchQueries={() => ['EventQuery', 'GetSeatingIssues']}
    >
      {(createSeatingVariants, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Create seating variants'
          primaryAction={{
            content: 'Continue',
            loading: saving,
            onAction: () => {
              createSeatingVariants({
                variables: {
                  eventId: event.id,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              Continuing will create a product variant option called "{variantOptionZoneName}" and
              create variants (ticket types) for each seating zone you have configured.
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default CreateSeatingVariantsModal;
