import React from 'react';
import { ResourceItem, Stack, TextContainer, TextStyle, Badge } from '@shopify/polaris';
import LocationImage from './LocationImage';

const LocationResourceItem = ({ location, onSelect }) => {
  return (
    <ResourceItem
      id={location.id}
      onClick={() => onSelect(location)}
    >
      <Stack vertical={false} alignment='center'>
        <LocationImage location={location} />
        <Stack vertical={true} distribtion='leading'>
          <TextContainer>{location.name}{location.code && location.code.length > 0 ? ` (${location.code})` : ''}</TextContainer>
          {location.url ? (
            <TextContainer><TextStyle variation='subdued'>{location.url}</TextStyle></TextContainer>
          ) : ('')}
        </Stack>
        {location.seatingChart &&
          <Badge status='info'>Seating</Badge>
        }
      </Stack>
    </ResourceItem>
  );
};

export default LocationResourceItem;
