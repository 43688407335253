import React from 'react';
import { Stack, Heading } from '@shopify/polaris';
import styled from '@emotion/styled';

const Container = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 20px 20px 0 20px;
  min-height:56px;
`;

const CardHeader = ({ title, action }) => {
  return (
    <Container>
      <Heading>{title}</Heading>
      {action}
    </Container>
  );
};

export default CardHeader;
