import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, Button, FormLayout, Checkbox } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';

const SAVE_LOCALE_FILE = gql`
  mutation saveLocaleFile($localeFileId: ID, $locale: String!, $content: String, $defaultLocale: Boolean) {
    saveLocaleFile(localeFileId: $localeFileId, locale: $locale, content: $content, defaultLocale: $defaultLocale) {
      localeFile {
        id
        locale
        content
      }
    }
  }
`;

const EditLocaleModal = ({ shop, localeFile, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(localeFile ? localeFile.locale : '');
  const [isDefault, setIsDefault] = React.useState(localeFile ? shop.defaultLocaleFile.id == localeFile.id : false);
  const [saving, setSaving] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={localeFile ? `Edit ${localeFile.locale}` : 'Add locale'}
      primaryAction={{
        content: localeFile ? 'Save' : 'Add locale',
        disabled: !name || name.length === 0,
        loading: saving,
        onAction: async () => {
          setSaving(true);
          console.log(`Updating locale '${name}'`);
          try {
            const result = await window.client.mutate({
              mutation: SAVE_LOCALE_FILE,
              variables: {
                localeFileId: localeFile ? localeFile.id : null,
                locale: name,
                content: localeFile ? localeFile.content : null,
                defaultLocale: isDefault,
              },
              refetchQueries: ['ShopLocaleFiles'],
              awaitRefetchQueries: true,
            });
            onClose(result.data.saveLocaleFile.localeFile);
          } catch (err) {
            console.log('Update error: ' + JSON.stringify(err));
            console.log(err);
            if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].errors && err.graphQLErrors[0].errors.length > 0) {
              showToastError(err.graphQLErrors[0].errors[0]);
            } else {
              showToastError('Unable to update locale');
            }
          }
          setSaving(false);
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          disabled: saving,
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Locale name"
            value={name}
            placeholder="en"
            onChange={setName}
            helpText="The 2-character ISO-639-1 primary language code that your customers browsers will identify their preferred language as. The default locale is 'en' (English)."
          />
          <Checkbox
            checked={isDefault}
            label="Set as default locale"
            onChange={setIsDefault}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default EditLocaleModal;
