import React from 'react';
import AirbrakeClient from 'airbrake-js';

const airbrakeId = document.head.querySelector('[name=airbrake-id]').content;
const airbrakeKey = document.head.querySelector('[name=airbrake-key]').content;

export const formatErrors = (errors) => {
  let children = [];
  if (errors) {
    if (Array.isArray(errors) && errors.length == 1) {
      return errors[0];
    }
    for(var i in errors) {
      let err = '';
      if (Array.isArray(errors)) {
        err = errors[i];
      } else {
        err = `${i} ${errors[i]}`;
      }
      children.push(<li key={`error_${i}`}>{err}</li>);
    }
    return <ul>{children}</ul>;
  } else {
    return '';
  }
};

export const reportError = (error, params) => {
  const environment = document.head.querySelector('[name=environment]').content;

  const airbrake = new AirbrakeClient({
    projectId: airbrakeId,
    projectKey: airbrakeKey
  });

  if (environment == 'production') {
    airbrake.notify({
      error: error,
      params: params || {}
    });
  } else {
    console.log('[Airbrake.nofify]: error, params');
    console.log(error);
    console.log(params);
    console.log(`[Airbrake.nofify]: error: ${error}`);
  }
};

export const hasErrors = (errors) => {
  if(!errors) {
    return false;
  }

  if (Array.isArray(errors)) {
    return errors.length > 0;
  } else {
    return Object.keys(errors).length > 0;
  }
}
