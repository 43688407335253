import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Banner, List, Layout, Link as ShopifyLink, Button } from '@shopify/polaris';
import CreateSeatingVariantsModal from './CreateSeatingVariantsModal';
import FixVariantInventoryManagementModal from './FixVariantInventoryManagementModal';
import TicketTypeModal from '../TicketTypeModal';
import { goToPage } from '../../../utils/routing';
import { useHistory } from "react-router-dom";

const GET_SEATING_ISSUES = gql`
  query GetSeatingIssues($eventId: ID!) {
    currentShop {
      id
      domain
      currency
      attendeeInfoStorefrontEnabled
      event(id: $eventId) {
        id
        hasSeating
        dateType
        hasCrossVariantInventory
        savedLocation {
          id
          seatingChart {
            id
            variantOptionZoneName
            seatingZones {
              id
              name
            }
          }
        }
        tickets {
          id
          title
          price
          visible
          inventory
          variantId
          groupSize
          groupIndividualTickets
          variant {
            id
            title
            price
            option1
            option2
            option3
            inventoryManagement
          }
          eventDate
          eventEndDate
          seatingZoneId
          seatingZone {
            id
            name
          }
          isDeleted
        }
        product {
          id
          options {
            name
          }
        }
      }
    }
  }
`;

const SeatingIssuesBanner = ({ eventId }) => {
  const [showCreateSeatingVariantsModal, setShowCreateSeatingVariantsModal] = React.useState(false);
  const [showTicketTypeModal, setShowTicketTypeModal] = React.useState(false);
  const [showNewTicketTypeModal, setShowNewTicketTypeModal] = React.useState(false);
  const [showFixInventoryManagementModal, setShowFixInventoryManagementModal] = React.useState(false);

  const history = useHistory();

  return (
    <Query
      query={GET_SEATING_ISSUES}
      variables={{ eventId }}
    >
      {({ loading, data }) => {
        if (loading || !data) {
          return '';
        }

        const shop = data.currentShop;
        const event = shop.event;

        if (!event.hasSeating) {
          return '';
        }

        const seatingChart = event.savedLocation && event.savedLocation.seatingChart;
        const seatingZones = seatingChart && seatingChart.seatingZones || [];

        const missingSeatingZoneProductOption = !event.product.options.some((option) => option.name.toLowerCase() == seatingChart.variantOptionZoneName.toLowerCase());
        const storefrontIntegrationDisabled = !shop.attendeeInfoStorefrontEnabled;

        const ticketsWithMissingZone = event.tickets.filter((ticket) => !ticket.seatingZone);
        const zonesWithMissingTickets = seatingZones.filter((zone) => !event.tickets.some((ticket) => ticket.seatingZone && ticket.seatingZone.id == zone.id));
        const variantsWithIncorrectInventoryManagement = event.tickets.filter((ticket) => ticket.variant && ticket.variant.inventoryManagement != null);

        const hasIssues = missingSeatingZoneProductOption || storefrontIntegrationDisabled || ticketsWithMissingZone.length > 0 || zonesWithMissingTickets.length > 0 || variantsWithIncorrectInventoryManagement.length > 0;

        if (hasIssues) {
          return (
            <Layout.Section>
              <Banner
                status='warning'
                title="We've detected some issues with your seating and product options configuration"
              >
                <List>
                  {storefrontIntegrationDisabled &&
                    <List.Item>
                      The theme integration for the app is disabled, enable so the app can inject the seat selector on the product page.{' '}
                      <Button plain monochrome
                        onClick={() => goToPage({ history, path: '/settings/storefront?section=seating' })}
                      >
                        Fix issue
                      </Button>
                    </List.Item>
                  }
                  {missingSeatingZoneProductOption &&
                    <List.Item>
                      Product variant options are missing the seating zone option.{' '}
                      <Button plain monochrome
                        onClick={() => setShowCreateSeatingVariantsModal(true)}
                      >
                        Fix issue
                      </Button>
                    </List.Item>
                  }
                  {ticketsWithMissingZone.map((ticket) => (
                    <List.Item>
                      Ticket type "{ticket.title}" does not have a seating zone assigned.{' '}
                      <Button plain monochrome
                        onClick={() => setShowTicketTypeModal(ticket.id)}
                      >
                        Fix issue
                      </Button>
                    </List.Item>
                  ))}
                  {zonesWithMissingTickets.map((zone) => (
                    <List.Item>
                      Seating zone "{zone.name}" does not have any ticket types assigned to it. Create a ticket type that is availble in the zone to fix it.{' '}
                      <Button plain monochrome
                        onClick={() => setShowNewTicketTypeModal(zone.id)}
                      >
                        Fix issue
                      </Button>
                    </List.Item>
                  ))}
                  {variantsWithIncorrectInventoryManagement.length > 0 &&
                    <List.Item>
                      Some variants have their inventory configured to be controlled by Shopify.{' '}
                      <Button plain monochrome
                        onClick={() => setShowFixInventoryManagementModal(true)}
                      >
                        Fix issue
                      </Button>
                    </List.Item>
                  }
                </List>
              </Banner>
              {showCreateSeatingVariantsModal &&
                <CreateSeatingVariantsModal
                  event={event}
                  onClose={() => setShowCreateSeatingVariantsModal(false)}
                />
              }
              {showTicketTypeModal &&
                <TicketTypeModal
                  shop={shop}
                  event={event}
                  ticketTypeId={showTicketTypeModal}
                  onClose={() => setShowTicketTypeModal(false)}
                />
              }
              {showNewTicketTypeModal &&
                <TicketTypeModal
                  shop={shop}
                  event={event}
                  defaultSeatingZone={showNewTicketTypeModal}
                  onClose={() => setShowNewTicketTypeModal(false)}
                />
              }
              {showFixInventoryManagementModal &&
                <FixVariantInventoryManagementModal
                  event={event}
                  onClose={() => setShowFixInventoryManagementModal(false)}
                />
              }
            </Layout.Section>
          );
        } else {
          return '';
        }
      }}
    </Query>
  );
};

export default SeatingIssuesBanner;
