import { storageGetItem, storageSetItem, storageRemoveItem } from './storage';

export const currentShopDomain = () => {
  let shop = new URLSearchParams(window.location.search).get('shop');
  if(isExternal()) {
    if (shop && shop.length) {
      window.shopify_domain = shop;
      storageSetItem('EVEY_LAST_SHOP', shop);
    } else {
      shop = window.shopify_domain;
      if (!shop || !shop.length) {
        shop = storageGetItem('EVEY_LAST_SHOP');
      }
      console.log(
        `Missing shop domain, using last authenticated domain: ${shop}`
      );
    }
  }
  return shop;
};

export const clearShop = () => {
  window.shopify_domain = null;
  storageRemoveItem('EVEY_LAST_SHOP');
}

export const currentHost = () => {
  let host = new URLSearchParams(window.location.search).get('host');

  if(isExternal()) {
    if (host && host.length) {
      window.shopify_host = host;
      storageSetItem('EVEY_LAST_HOST', host);
    } else {
      host = window.shopify_host;
      if (!host || !host.length) {
        host = storageGetItem('EVEY_LAST_HOST');
      }
      console.log(
        `Missing host param, using last authenticated host value: ${host}`
      );
    }
  }
  return host;
};

export const getShopNameFromDomain = (shopDomain) => {
  if(shopDomain) return shopDomain.replace(".myshopify.com","");
  let shop = new URLSearchParams(window.location.search).get('shop');
  if(shop) return shop.replace(".myshopify.com","");
};

export const getHostFromShopDomain = (shopDomain) => {
  let shopName = shopDomain && shopDomain.replace(".myshopify.com","");
  if(shopDomain) return btoa(`admin.shopify.com/store/${shopName}`);
  let shop = new URLSearchParams(window.location.search).get('shop');
  let altShopName = shop && shop.replace(".myshopify.com","");
  if(shop) return btoa(`admin.shopify.com/store/${altShopName}`);

};

const isExternal = () => {
  let externalHost = document.head.querySelector('[name=external-host]').content;
  return [
    externalHost,
    'dashboard.eveyevents.com',
    'dash.eveyevents.com',
  ].includes(window.location.hostname);
}
