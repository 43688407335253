import React from 'react';
import { Card, ResourceList, EmptyState, TextField, FormLayout } from '@shopify/polaris';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import VendorAccountResourceItem from './VendorAccountResourceItem';
import VendorAccountModal from './VendorAccountModal';
import ImagePicker from '../../shared/ImagePicker';
import { useFrame } from '../../../contexts/frame';

const SAVE_VENDOR_SETTINGS = gql`
  mutation saveVendorSettings($orgName: String, $logoUrl: String) {
    saveVendorSettings(orgName: $orgName, logoUrl: $logoUrl) {
      success
    }
  }
`;

const VendorSettingsCard = ({ shop, accounts }) => {
  const [orgName, setOrgName] = React.useState(shop.vendorOrgName || '');
  const [logoUrl, setlogoUrl] = React.useState(shop.vendorLogoUrl || '');

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={SAVE_VENDOR_SETTINGS}
      onError={() => showToastError('Failed to save settings')}
      onCompleted={() => showToastNotice('Settings updated')}
      refetchQueries={() => ['GetVendors']}
    >
      {(saveVendorSettings, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              saveVendorSettings({
                variables: {
                  orgName,
                  logoUrl,
                },
              });
            },
          }}
        >
          <FormLayout>
            <TextField
              label='Organization name'
              value={orgName}
              onChange={setOrgName}
              placeholder={shop.name}
              helpText='Name of your organization when communicating to vendors. This is the shop name by default if left blank.'
            />
            <ImagePicker
              label='Logo'
              shop={shop}
              value={logoUrl}
              onChange={setlogoUrl}
              helpText='Logo used on the vendor dashboard topbar and in any account emails. If blank, an Evey Events logo will be used.'
              previewHeight='60px'
            />
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default VendorSettingsCard;
