import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, FormLayout, Collapsible, ChoiceList, TextField, Banner, Stack } from '@shopify/polaris';
import styled from '@emotion/styled';
import ActionScheduleForm from '../ActionSchedule/ActionScheduleForm';
import { useFrame } from '../../contexts/frame';
import { UPDATE_SCHEDULED_REMINDER } from '../../utils/graphql';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat)

const FormContainer = styled.div`
  padding-left:28px;
  padding-top:5px;
`;

const MethodContainer = styled.div`
  padding-top:5px;
`;

const ScheduleMessageModal = ({ event, reminder, onClose }) => {
  const [schedule, setSchedule] = React.useState(reminder.schedule ? { ...reminder.schedule } : null);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={UPDATE_SCHEDULED_REMINDER}
      onError={() => showToastError('Failed to update schedule')}
      onCompleted={() => {
        showToastNotice('Scheduled message updated');
        onClose();
      }}
      refetchQueries={() => ['AttendeeReminderQuery']}
    >
      {(updateScheduledReminder, { loading: saving }) => (
        <Modal
          open={true}
          onClose={onClose}
          title='Schedule message delivery'
          primaryAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              updateScheduledReminder({
                variables: {
                  reminderId: reminder.id,
                  dateType: schedule ? schedule.dateType : null,
                  relativeMinutes: schedule ? schedule.relativeMinutes : null,
                  customDate: schedule ? schedule.customDate : null,
                  createNew: schedule ? schedule.new : false,
                },
              });
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              disabled: saving,
              onAction: onClose,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <ChoiceList
                choices={[
                  { label: 'Manually trigger this message to be sent', value: false },
                  { label: 'Schedule a date and time for the message to send', value: true },
                ]}
                selected={[!!schedule]}
                onChange={(v) => setSchedule(v[0] ? { dateType: 'custom', new: true } : null)}
              />
              <Collapsible open={schedule} id="scheduleCollaps">
                <FormContainer>
                  <Stack vertical={true} spacing='tight'>
                    <ActionScheduleForm
                      event={event}
                      schedule={schedule}
                      onUpdate={(newSchedule) => {
                        setSchedule(newSchedule);
                      }}
                    />
                  </Stack>
                </FormContainer>
              </Collapsible>
            </FormLayout>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default ScheduleMessageModal;
