import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Layout, Link as ShopifyLink } from '@shopify/polaris';
import SettingsPage from './SettingsPage';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import AttendeeFieldsCard from './AttendeeInfo/AttendeeFieldsCard';
import { ATTENDEE_INFO_SPECS_FIELDS } from '../../utils/graphql';

const GET_SPECS = gql`
  query GetAttendeeInfoFieldsTemplates {
    currentShop {
      id
      domain
      attendeeFieldsTemplates {
        id
        name
        specs {
          ${ATTENDEE_INFO_SPECS_FIELDS}
        }
        specCount
      }
    }
  }
`;

const AttendeeInfoPage = () => {

  return (
    <SettingsPage title='Attendee information'>
      <Query query={GET_SPECS} >
        {({ loading, data }) => {
          if (loading) {
            return <AnnotatedSectionLoading />;
          }

          const shop = data.currentShop;

          return (
            <Layout>
              <Layout.AnnotatedSection
                title="Attendee information templates"
                description=<div>
                  <p>Custom questions to ask attendees before checkout, on their ticket page, or during check-in.</p>
                  <p>These templates can be shared and used on one or more events under Event Settings -&gt; Attendee information for each event.</p>
                  <p>Check out our <ShopifyLink url='https://eveyevents.myshopify.com/products/awesome-event' external>demo store</ShopifyLink> to see an example, just add a ticket to your cart and hit the checkout button.</p>
                </div>
              >
                <AttendeeFieldsCard shop={shop} templates={shop.attendeeFieldsTemplates} />

              </Layout.AnnotatedSection>
            </Layout>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default AttendeeInfoPage;
