import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Card, TextField, Banner, DataTable, Pagination, TextStyle, Button, Link as ShopifyLink, Spinner } from '@shopify/polaris';
import { useLocation } from "react-router-dom";
import ErrorPage from '../Error/ErrorPage.jsx';
import AppPage from '../AppPage/AppPage.jsx';
import { Link } from 'react-router-dom';
import { getHostFromShopDomain } from '../../utils/auth';
import {
  ExportMinor,
} from '@shopify/polaris-icons';

export default class CustomerDataTable extends EveyComponent {
  constructor(props) {
    super(props);
  }

  shop = null;

  state = {
    localSearchValue: '',
    searchValue: '',
    currentPage: 1,
    confirmExport: false,
    confirmExportEmail: ''
  };

  PER_PAGE = 20;

  GET_CUSTOMERS = gql`
    query CustomersQuery($search: String, $page: Int, $perPage: Int) {
      currentShop {
        id
        domain
        attendeeAttributeSpecs {
          name
          label
          type
          options
          selectMultiple
        }
        customers(search: $search, page: $page, perPage: $perPage) {
          id
          name
          email
          ticketCount
          latestOrder {
            id
            orderUrl
            orderName
          }
          latestAttendee {
            id
            name
            number
            attendeeAttributes {
              value
              spec {
                name
                label
                type
                options
                selectMultiple
              }
            }
          }
        }
        customersCount(search: $search)
      }
    }
  `;

  EXPORT = gql`
    mutation customersExport($search: String, $email: String!) {
      customersExport(search: $search, email: $email) {
        status
        email
      }
    }
  `;

  handleSearchChange = (searchValue) => {
    this.setState({ localSearchValue: searchValue });
  };

  handleConfirmExportEmailChange = (value) => {
    this.setState({ confirmExportEmail: value });
  }

  handleKeyPress = (event) => {
    if (event.keyCode == 13) {
      this.setState({ searchValue: this.state.localSearchValue });
      this.handlePageChange(1);
    }
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  isFirstPage = () => {
    if (this.state.currentPage <= 1) {
      return true;
    } else {
      return false;
    }
  };

  isLastPage = (customersCount) => {
    let numPages = Math.ceil(customersCount / this.PER_PAGE);
    if (this.state.currentPage >= numPages) {
      return true;
    } else {
      return false;
    }
  };

  handlePreviousPage = () => {
    this.handlePageChange(this.state.currentPage - 1);
  };

  handleNextPage = () => {
    this.handlePageChange(this.state.currentPage + 1);
  };

  formatDate = (dateString) => {
    let date = new Date(dateString);
    return date.toLocaleString();
  };

  renderCustomAttributeValue = (attr) => {
    if (attr && attr.value) {
      if (attr.spec.type == 'select' && attr.spec.selectMultiple && attr.value.indexOf('[') == 0) {
        return JSON.parse(attr.value).join(', ');
      } else {
        return attr.value;
      }
    } else {
      return '';
    }
  }

  render() {
    const { searchValue, localSearchValue, currentPage, confirmExport, confirmExportEmail } = this.state;
    const location = useLocation();
    const searchParams = "?host=" + getHostFromShopDomain(this.shop?.domain); 

    return (
      <AppPage
        title="Master Attendance Report"
        fullWidth={true}
        primaryAction={{
          content: 'Create Event',
          onAction: () => {
            this.props.history.push('/events/new');
            window.scrollTo({ top: 0 });
          }
        }}
        secondaryActions={[
          {
            content: 'Back to Events',
            onAction: () => {
              this.props.history.push('/events');
              window.scrollTo({ top: 0 });
            },
            target: 'APP'
          },
          {
            content: 'Help',
            url: 'https://evey-events.zendesk.com/hc/en-us/',
            target: 'REMOTE',
            external: true
          },
          {
            content: 'Subscriptions',
            onAction: () => {
              this.props.history.push('/events?category=subscription-active');
              window.scrollTo({ top: 0 });
            },
            target: 'APP'
          },
        ]}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        <Query
          query={this.GET_CUSTOMERS}
          variables={{
            'search': searchValue,
            'page': currentPage,
            'perPage': this.PER_PAGE
          }}
        >
          {({ loading, error, data }) => {
            if (error) return <ErrorPage error={error}/>;

            if (data && data.currentShop) {
              this.shop = data.currentShop;
            }

            let customers = [];
            let customersCount = 0;

            let columnContentTypes = [
              'text',
              'text',
              'text',
              'text'
            ];
            let headings = [
              'Name',
              'Email / Phone',
              'Latest order',
              'Number of ticket'
            ];
            let rows = [];

            if (!loading) {
              customers = this.shop.customers;
              customersCount = this.shop.customersCount;

              rows = [];

              for (let i in this.shop.attendeeAttributeSpecs) {
                let spec = this.shop.attendeeAttributeSpecs[i];
                columnContentTypes.push('text');
                headings.push(spec.label);
              }

              for (let i in customers) {
                let customer = customers[i];
                let latestAttendee = customer.latestAttendee;

                let email_phone = customer.phone;
                if (!email_phone || email_phone.length == 0) {
                  email_phone = customer.email;
                }

                let orderLink = '';
                if (customer.latestOrder) {
                  orderLink = <ShopifyLink external url={`${customer.latestOrder.orderUrl}${searchParams}`} target="_blank">{customer.latestOrder.orderName}</ShopifyLink>;
                }

                let row = [
                  <Link key={`customer_link_${customer.id}`} to={`/customers/${customer.id}`}>{customer.name}</Link>,
                  email_phone,
                  orderLink,
                  customer.ticketCount
                ];

                for (let i in this.shop.attendeeAttributeSpecs) {
                  let spec = this.shop.attendeeAttributeSpecs[i];
                  let attr_value = null;
                  for (let j in latestAttendee.attendeeAttributes) {
                    let attr = latestAttendee.attendeeAttributes[j];
                    if (attr.spec.name == spec.name) {
                      attr_value = this.renderCustomAttributeValue(attr);
                      break;
                    }
                  }
                  row.push(attr_value ? attr_value : '');
                }

                rows.push(row);
              }
            }

            return (
              <div>
                {!loading &&
                  <div className="AttendeeList__Header">
                    <div className="AttendeeList__ImportExport">
                      <Button
                        id="exportCustomers"
                        icon={ExportMinor}
                        plain={true}
                        onClick={() => {
                          this.setState({ confirmExport: true });
                        }}
                      >
                        Export CSV
                      </Button>
                    </div>
                    <TextStyle variation="subdued">
                      Showing {customers.length} of {customersCount} customers
                    </TextStyle>
                  </div>
                }
                {confirmExport &&
                  <Mutation
                    mutation={this.EXPORT}
                    onError={() => {
                      this.props.flashCallback(true, 'Unable to export customers', true);
                      this.setState({ confirmExport: false, confirmExportEmail: '' });
                    }}
                    onCompleted={() => {
                      this.props.flashCallback(true, 'Export will be delivered to ' + this.state.confirmExportEmail);
                      this.setState({ confirmExport: false, confirmExportEmail: '' });
                    }}
                  >
                    {(customersExport, { loading }) => {
                      return (
                        <Banner
                          title={'Confirm customer export'}
                          status="default"
                          action={{
                            content: 'Export customers',
                            disabled: !confirmExportEmail || confirmExportEmail.length == 0,
                            loading: loading,
                            onAction: () => {
                              customersExport({
                                variables: {
                                  search: this.state.searchValue,
                                  email: this.state.confirmExportEmail
                                }
                              });
                            }
                          }}
                          onDismiss={() => {
                            this.setState({ confirmExport: false, confirmExportEmail: '' });
                          }}
                        >
                          <p>
                            Please confirm your export of {customersCount} customers to a CSV file, we will email you when your export is ready. It may take some time depending on the number of customers you have. Enter the email address that you would like us to send the export to:
                          </p>
                          <TextField type="email" id='exportEmail' value={confirmExportEmail} onChange={this.handleConfirmExportEmailChange} />
                        </Banner>
                      );
                    }}
                  </Mutation>
                }
                <Card sectioned>
                  <div onKeyDown={this.handleKeyPress}>
                    <div style={{margin:'10px'}}>
                      <TextField
                        type="search"
                        id='searchCustomers'
                        value={localSearchValue}
                        onChange={this.handleSearchChange}
                        placeholder='Search customers'
                      />
                    </div>
                    <DataTable
                      columnContentTypes={columnContentTypes}
                      headings={headings}
                      rows={rows}
                    />
                    {loading &&
                      <div style={{textAlign:'center',marginTop:'50px',marginBottom:'50px'}}>
                        <Spinner size="large" color="teal" />
                      </div>
                    }
                    {customersCount > 0 &&
                    <div className="AttendeeListFooter">
                      <Pagination
                        hasPrevious={!this.isFirstPage()}
                        hasNext={!this.isLastPage(customersCount)}
                        onPrevious={this.handlePreviousPage}
                        onNext={this.handleNextPage}
                      />
                    </div>
                    }
                  </div>
                </Card>
              </div>
            );
          }}
        </Query>
      </AppPage>
    );
  }
}

CustomerDataTable.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
