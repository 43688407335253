import React from 'react';
import { Query } from 'react-apollo';
import { Layout } from '@shopify/polaris';
import SettingsPage from './SettingsPage';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import RefundsCard from './Orders/RefundsCard';
import NotificationsCard from './Orders/NotificationsCard';
import { GET_SHOP_ORDERS_SETTINGS } from '../../utils/graphql';

const LocationsPage = () => {

  return (
    <SettingsPage title='Orders settings'>
      <Query query={GET_SHOP_ORDERS_SETTINGS} >
        {({ loading, data }) => {
          if (loading) {
            return <AnnotatedSectionLoading />;
          }

          const shop = data.currentShop;

          return (
            <Layout>
              <Layout.AnnotatedSection
                title="Refunds"
                description='Manage how refunds are processed.'
              >
                <RefundsCard shop={shop} />
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Notifications"
                description='Manage what notifications the customer receives across all events.'
              >
                <NotificationsCard shop={shop} />
              </Layout.AnnotatedSection>
            </Layout>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default LocationsPage;
