import React from 'react';
import { Card, Icon } from '@shopify/polaris';
import styled from '@emotion/styled';
import { BillingStatementDollarMajor, ChevronRightMinor, BuyButtonHorizontalLayoutMajor, LanguageMinor, BarcodeMajor, TemplateMajor, AppsMajor, ClockMajor, LocationMajor, ReplaceMajor, FormsMajor, TeamMajor } from '@shopify/polaris-icons';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { useFrame } from '../../contexts/frame';
import { hasLocations } from '../../utils/features';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
`;

const ItemContainer = styled.a`
  width:100%;
  display:flex;
  cursor:pointer;

  font-weight: 400;
  line-height: 2rem;
  text-transform: none;
  letter-spacing: normal;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  color: #212b36;
  cursor: pointer;
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    background-color: #f9fafb;
    outline: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  margin-right: 1.6rem;
  border-radius: 3px;
  background-color: #f4f6f8;
  fill: #919eab;
  color: transparent;
`;

const ItemContent = styled.div`
`;

const ItemTitle = styled.div`
  font-weight:600;
  margin:0;
  color:#006fbb;
  font-size:1em;
`;

const ItemDescription = styled.div`
  font-weight:400;
  margin:0;
  color:var(--p-text-subdued);
  font-size:1em;
`;

const ItemLeftContent = styled.div`
  display:flex;
  width:100%;
  align-items:center;
`;

const ItemRightContent = styled.div`
  display:flex;

`;

const SettingItem = ({ icon, title, description, path }) => {
  const history = useHistory();

  return (
    <ItemContainer onClick={() => goToPage({ history, path })}>
      <ItemLeftContent>
        <IconContainer><Icon source={icon} backdrop color='subdued' /></IconContainer>
        <ItemContent>
          <ItemTitle>{title}</ItemTitle>
          <ItemDescription>{description}</ItemDescription>
        </ItemContent>
      </ItemLeftContent>
      <ItemRightContent>
        <Icon source={ChevronRightMinor} color="base" />
      </ItemRightContent>
    </ItemContainer>
  );
};

const SettingsCard = () => {
  const { currentShopDomain } = useFrame();

  return (
    <Card sectioned>
      <Container>
        <SettingItem title='Subscription' path='/settings/account' description='Manage and view your Evey Events & Tickets plan' icon={BillingStatementDollarMajor} />
        <SettingItem title='Storefront integrations' path='/settings/storefront' description='Manage the storefront integration to collect ticket information, recurring event scheduler, and the events calendar' icon={BuyButtonHorizontalLayoutMajor} />
        <SettingItem title='Translations manager' path='/settings/translations' description='Manage your translations for all customizable content such as ticket page, PDF ticket, Apple Wallet Pass, and notifications' icon={LanguageMinor} />
        <SettingItem title='Check-In accounts' path='/settings/checkin' description='Manage check-in user accounts for access to the check-in app where you can scan tickets and check-in guests at your event' icon={BarcodeMajor} />
        <SettingItem title='Notifications' path='/settings/notifications' description='Manage ticket and notification templates that can be shared across events' icon={TemplateMajor} />
        <SettingItem title='Integrations' path='/settings/integrations' description='Manage external integrations, such as MailChimp' icon={AppsMajor} />
        {/*<SettingItem title='Scheduled actions' path='/settings/scheduled_actions' description='Manage actions to apply to events at scheduled times' icon={ClockMajor} />*/}
        {hasLocations(currentShopDomain) &&
          <SettingItem title='Locations and seating' path='/settings/locations' description='Manage venue information, seating plans, as well as organizer information, that can be shared across events' icon={LocationMajor} />
        }
        <SettingItem title='Orders settings' path='/settings/orders' description='Manage how orders and refunds are processed by the app' icon={ReplaceMajor} />
        <SettingItem title='Attendee information' path='/settings/attendee_info' description='Manage shared attendee information / registration fields' icon={FormsMajor} />
        <SettingItem title='Vendors' path='/settings/vendors' description='Manage multi-vendor settings and account access' icon={TeamMajor} />
      </Container>
    </Card>
  );
};

export default SettingsCard;
