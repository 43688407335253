import React from 'react';
import { Select } from '@shopify/polaris';
import dayjs from 'dayjs';
import { storefrontGet } from '../../utils/storefrontApi.js';

const SeatSelect = ({ shop, event, ticketTypeId, value, onChange, initialValue, scheduledEventTs, disabled }) => {
  const [seats, setSeats] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const env = document.head.querySelector('[name=environment]').content;

  console.log(`ticketTypeId: ${ticketTypeId}`);

  React.useEffect(() => {
    console.log(`loading seats: ${ticketTypeId}`);
    (async () => {
      setLoading(true);
      const result = await storefrontGet({
        path: '/seats',
        params: {
          shop: shop.domain,
          ticketTypeId,
          eventDate: scheduledEventTs,
        },
      });
      setSeats(result.seats);
      setLoading(false);
    })();
  }, [ticketTypeId, scheduledEventTs]);

  return (
    <Select
      value={value == null ? 'no-seat' : value}
      loading={loading}
      options={[
        { value: 'no-seat', label: '' },
        ...seats.map((seat) => ({
          value: seat.id.toString(),
          label: initialValue == seat.id ? `${seat.label}*` : seat.is_available ? seat.label : `${seat.label} (sold out)`,
        }))
      ]}
      label="Seat"
      onChange={(v) => onChange(v)}
      disabled={disabled}
    />
  );
};

export default SeatSelect;
