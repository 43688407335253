import React from 'react';
import gql from 'graphql-tag';
import { Modal, Checkbox, Stack, TextContainer, Spinner, FormLayout, TextField, Banner } from '@shopify/polaris';
import { Mutation, Query } from 'react-apollo';
import { useFrame } from '../../contexts/frame';
import { moneyFormat } from '../../utils/money';

const CANCEL_TICKET = gql`
  mutation cancelTicket($eventId: ID!, $attendeeId: ID!, $restock: Boolean, $refund: Boolean, $refundReason: String, $refundNotify: Boolean) {
    cancelTicket(eventId: $eventId, attendeeId: $attendeeId, restock: $restock, refund: $refund, refundReason: $refundReason, refundNotify: $refundNotify) {
      success
      refundStatus
      refundError
    }
  }
`;

const GET_SUGGEST_REFUND = gql`
  query GetSuggestedRefund($eventId: ID!, $attendeeId: ID!) {
    currentShop {
      moneyFormat
      event(id: $eventId) {
        attendee(eventId: $eventId, id: $attendeeId) {
          suggestedRefund {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

const CancelTicketModal = ({ event, attendee, onClose, onCompleted }) => {
  const [restock, setRestock] = React.useState(false);
  const [refundEnabled, setRefundEnabled] = React.useState(false);
  const [refundReason, setRefundReason] = React.useState('');
  const [refundNotify, setRefundNotify] = React.useState(true);

  const { showToastNotice, showToastError } = useFrame();

  return (
    <Mutation
      mutation={CANCEL_TICKET}
      onError={() => {
        showToastError('Failed to cancel');
        onClose();
      }}
      onCompleted={(data) => {
        if (data.cancelTicket.refundStatus == 'error') {
          console.error("Refund error: ", data.cancelTicket.refundError);
          showToastError(`Failed to issue refund`);
        } else {
          showToastNotice('Ticket cancelled');
        }
        onCompleted();
      }}
      refetchQueries={() => ['AttendeeQuery']}
    >
      {(cancelTicket, { loading: saving }) => (
        <Modal
          title='Confirm ticket cancellation'
          open={true}
          onClose={onClose}
          primaryAction={{
            content: refundEnabled ? 'Yes, cancel ticket and refund' : 'Yes, cancel ticket',
            destructive: true,
            loading: saving,
            onAction: () => {
              cancelTicket({
                variables:{
                  eventId: event.id,
                  attendeeId: attendee.id,
                  restock,
                  refund: refundEnabled,
                  refundReason,
                  refundNotify,
                }
              });
            }
          }}
          secondaryActions={[
            {
              content: 'No, go back',
              disabled: saving,
              onAction: onClose,
            }
          ]}
        >
          <Modal.Section>
            <Stack vertical={true}>
              <TextContainer>Are you sure you want to cancel this ticket?</TextContainer>
              {event.dateType !== 'recurring' && !event.hasSeating &&
                <Checkbox
                  checked={restock}
                  label="Restock inventory"
                  onChange={setRestock}
                />
              }
              {attendee.orderName && attendee.ticket.groupSize == 1 &&
                <Checkbox
                  checked={refundEnabled}
                  label={`Refund the customer`}
                  onChange={setRefundEnabled}
                />
              }
              {refundEnabled &&
                <Query
                  query={GET_SUGGEST_REFUND}
                  variables={{
                    eventId: event.id,
                    attendeeId: attendee.id,
                  }}
                >
                  {({ loading, error, data }) => {

                    const shop = data?.currentShop;
                    const suggestedRefund = data?.currentShop?.event?.attendee?.suggestedRefund;
                    if (loading) {
                      return (
                        <Spinner size="small" />
                      );
                    }

                    const refundAmount = parseFloat(suggestedRefund?.amount || 0);

                    if (refundAmount == 0) {
                      return (
                        <Banner status='warning'>
                          The line item for this ticket type cannot be refunded. This can be because it has already been refunded.
                        </Banner>
                      )
                    }

                    return (
                      <FormLayout vertical={true}>
                        <TextField
                          label='Refund amount'
                          helpText='This is the value of a single quantity line item for this ticket type'
                          type='number'
                          value={refundAmount.toFixed(2)}
                          prefix={suggestedRefund?.currencyCode || ''}
                          disabled
                        />
                        <TextField
                          label='Reason for refund'
                          helpText='Only you and other staff can see this reason.'
                          value={refundReason}
                          onChange={setRefundReason}
                        />
                        <Checkbox
                          checked={refundNotify}
                          label='Send a refund notification to the customer'
                          onChange={setRefundNotify}
                          helpText='This is a Shopify notification and can be customized under your Shopify Settings -> Notifications.'
                        />
                      </FormLayout>
                    );
                  }}
                </Query>
              }
            </Stack>
          </Modal.Section>
        </Modal>
      )}
    </Mutation>
  );
};

export default CancelTicketModal;
