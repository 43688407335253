import React from 'react';
import { liquidEngine } from '../../utils/liquid';
import { buildPreviewData } from '../../utils/preview';

const EmailPreview = ({ shop, event, subject, body, renderHtml, config, extraContext }) => {
  const [renderedSubject, setRenderedSubject] = React.useState('');
  const [renderedBody, setRenderedBody] = React.useState('');
  const [renderError, setRenderError] = React.useState(null);

  const baseData = {
    ...buildPreviewData({ shop, event, config }),
    communication_type: 'email',
  };
  const data = Object.assign(baseData, extraContext || {});

  const renderPreview = async () => {
    try {
      setRenderedSubject(await liquidEngine.parseAndRender(subject, data));
      setRenderedBody(await liquidEngine.parseAndRender(body, data));
      setRenderError(false);
    } catch (error) {
      if (error.name === 'ParseError') {
        console.log(`Render error`, error);
        setRenderError(error.message);
      } else {
        throw error;
      }
    }
  };

  React.useEffect(() => {
    renderPreview();
  }, [subject, body, renderHtml, config, extraContext]);

  return (
    <div style={{padding: '20px 0'}}>
      <div className="EmailPreview__Subject">
        <p><strong>Subject: </strong>{renderedSubject}</p>
      </div>
      <div className="EmailPreview__Body">
        {renderHtml ? (
          <div dangerouslySetInnerHTML={{ __html: renderedBody }} />
        ) : (
          <pre>renderedBody</pre>
        )}
      </div>
    </div>
  );
};

export default EmailPreview;
