import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Card, FormLayout, TextField, Banner, PageActions } from '@shopify/polaris';
import EventQuery from '../EventPage/EventQuery.jsx';
import EventPage from '../EventPage/EventPage.jsx';

export default class CheckinUserCreate extends EveyComponent {
  constructor(props) {
    super(props);
  }

  state = {
    email: '',
    password: '',
    passwordConfirm: '',

    errors: {},
    unknownError: false
  }

  EVENT_FIELDS = '';

  CREATE_USER = gql`
    mutation createCheckinUser($eventId: ID!, $email: String!, $password: String, $passwordConfirm: String) {
      createCheckinUser(eventId: $eventId, email: $email, password: $password, passwordConfirm: $passwordConfirm) {
        user {
          id
        }
      }
    }
  `;

  handleChange = (field) => {
    return (value) => this.setState({[field]: value});
  };

  renderPageContent = (shop, event) => {
    const { email, password, passwordConfirm } = this.state;
    return (
      <Mutation mutation={this.CREATE_USER}
        onError={(error) => {
          if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
            this.setState({ errors: error.graphQLErrors[0].errors, unknownError: false });
          } else {
            this.setState({ unknownError: true, errors: [] });
            this.reportError(error);
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        onCompleted={(data) => {
          if (data && data.createCheckinUser) {
            this.props.flashCallback(true, 'User successfully created');
            this.props.history.push(`/events/${event.id}/checkin_settings`);
            window.scrollTo({ top: 0 });
          }
        }}
      >
        {(createCheckinUser, { loading }) => {
          return (
            <div>
              {this.state.unknownError &&
            <Banner
              key="unknownErrorBanner"
              title="There was a problem creating the user"
              status="critical"
            >
              <p>
                Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
              </p>
            </Banner>
              }
              {this.state.errors && this.state.errors.length > 0 &&
            <Banner
              key="errorBanner"
              title="There was a problem creating the user"
              status="critical"
            >
              {this.errorMessage()}
            </Banner>
              }
              <form id="createCheckinUser" onSubmit={e => {
                e.preventDefault();
                this.setState({ unknownError: false, errors: [] });

                createCheckinUser({
                  variables: {
                    eventId: event.id,
                    email: email,
                    password: password,
                    passwordConfirm: passwordConfirm
                  }
                });
              }}>
                <Card title="New check-in user" sectioned>
                  <FormLayout>
                    <TextField key="email" id='email' type='email' value={email} label="Email" placeholder="" onChange={this.handleChange('email')} />
                    <TextField key="password" type='password' label="New password" value={password} onChange={this.handleChange('password')} />
                    <TextField key="passwordConfirm" type='password' label="Confirm new password" value={passwordConfirm} onChange={this.handleChange('passwordConfirm')} />
                  </FormLayout>

                  <PageActions
                    primaryAction={{
                      content: 'Save',
                      loading: loading,
                      submit: true
                    }}
                    secondaryActions={[
                      {
                        content: 'Cancel',
                        disabled: loading,
                        target: 'APP',
                        onAction: () => {
                          this.props.history.push(`/events/${event.id}/checkin_settings`);
                          window.scrollTo({ top: 0 });
                        }
                      }
                    ]}
                  />
                </Card>
              </form>
            </div>
          );
        }}
      </Mutation>
    );
  }

  render() {
    return (
      <EventQuery
        eventId={this.props.match.params.eventId}
        extraEventAttributes={this.EVENT_FIELDS}
        flashCallback={this.props.flashCallback}
        history={this.props.history}
      >
        {({ shop, event }) => {
          return (
            <EventPage
              shop={shop}
              event={event}
              title='Add User'
              extraBreadcrumbs={[
                {
                  content: 'Check-In Settings',
                  onAction: () => {
                    this.props.history.push(`/events/${event.id}/checkin_settings`);
                    window.scrollTo({ top: 0 });
                  },
                  target: 'APP'
                }
              ]}
              flashCallback={this.props.flashCallback}
              history={this.props.history}
            >
              {this.renderPageContent(shop, event)}
            </EventPage>
          );
        }}
      </EventQuery>
    );
  }
}

CheckinUserCreate.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string
    })
  })
};
