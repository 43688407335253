import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, Banner, FormLayout, TextField, Checkbox, Collapsible, ChoiceList, Link } from '@shopify/polaris';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError, formatErrors, hasErrors } from '../../../utils/errors';
import { useFrame } from '../../../contexts/frame';
import { parseBoolean } from '../../../utils/parse';
import { hasPlanFeature } from '../../../utils/features';

const EventSettingsCard = ({ shop, event }) => {
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);

  const [hasCrossVariantInventory, setHasCrossVariantInventory] = React.useState(event.hasCrossVariantInventory);
  const [hasEtickets, setHasEtickets] = React.useState(event.hasEtickets);
  const [waitlistEnabled, setWaitlistEnabled] = React.useState(event.waitlistEnabled);
  const [waitlistKlaviyoIntegrationEnabled, setWaitlistKlaviyoIntegrationEnabled] = React.useState(event.waitlistKlaviyoIntegrationEnabled);

  const [deliveryTrigger, setDeliveryTrigger] = React.useState([event.autoSendEtickets ? 'immediately' : (event.deliverOnFulfill ? 'on-fulfill' : 'manual')]);
  const [inventory, setInventory] = React.useState(event.inventory == null ? '' : event.inventory.toString());
  const [ticketFormats, setTicketFormats] = React.useState([
    ...(event.hasPdfTicketType ? ['pdf'] : []),
    ...(event.hasPassbookTicketType ? ['passbook'] : []),
    ...(event.hasIcsTicketType ? ['ics'] : []),
  ]);
  const [allowTransfers, setAllowTransfers] = React.useState(event.allowTransfers);
  const [overrideContactEmail, setOverrideContactEmail] = React.useState(event.contactEmail && event.contactEmail.length > 0)
  const [contactEmail, setContactEmail] = React.useState(event.contactEmail || '');
  const [ticketNumberStart, setTicketNumberStart] = React.useState(event.ticketNumberStart.toString());
  const [randomTicketNumberEnd, setRandomTicketNumberEnd] = React.useState(event.randomTicketNumberEnd ? event.randomTicketNumberEnd.toString() : '');
  const [orderStatusEnabled, setOrderStatusEnabled] = React.useState(event.orderStatusEnabled);

  const { showToastNotice, showToastError, loadingMarkup, isVendor } = useFrame();
  const hasKlaviyoFeature = hasPlanFeature({ shop, feature: 'klaviyo' });

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setErrors([]);
          setUnknownError(true);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editEvent) {
          setErrors([]);
          setUnknownError(false);
          showToastNotice('Event updated');
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          sectioned
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables: {
                  eventId: event.id,
                  hasCrossVariantInventory: parseBoolean(hasCrossVariantInventory),
                  inventory: parseInt(inventory),
                  hasEtickets,
                  autoSendEtickets: deliveryTrigger == 'immediately',
                  deliverOnFulfill: deliveryTrigger == 'immediately' || deliveryTrigger == 'on-fulfill',
                  hasPdfTicketType: parseBoolean(ticketFormats.includes('pdf')),
                  hasPassbookTicketType: parseBoolean(ticketFormats.includes('passbook')),
                  hasIcsTicketType: parseBoolean(ticketFormats.includes('ics')),
                  allowTransfers: parseBoolean(allowTransfers),
                  contactEmail: overrideContactEmail ? contactEmail : null,
                  ticketNumberStart: parseInt(ticketNumberStart),
                  randomTicketNumberEnd: shop.hasRandomTicketNumbersFeature && randomTicketNumberEnd && randomTicketNumberEnd.length ? parseInt(randomTicketNumberEnd) : null,
                  orderStatusEnabled: shop.orderStatusEnabled == null ? (hasEtickets ? parseBoolean(orderStatusEnabled) : false) : shop.orderStatusEnabled,
                  waitlistEnabled,
                  waitlistKlaviyoIntegrationEnabled,
                }
              });
            },
          }}
        >
          {unknownError &&
            <Banner title="There was a problem updating your event" status="critical">
              Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
            </Banner>
          }
          {hasErrors(errors) &&
            <Banner title="There was a problem updating your event" status="critical">{formatErrors(errors)}</Banner>
          }
          {saving && loadingMarkup}
          <FormLayout>
            {!event.hasSeating &&
              <>
                {event.dateType === 'recurring' ? (
                  <>
                    <Checkbox
                      checked={hasCrossVariantInventory}
                      label="Share inventory across ticket types"
                      onChange={(v) => setHasCrossVariantInventory(v)}
                      helpText="Enable this option if you have a limited number of tickets across all your ticket types rather than a limit per ticket type."
                    />
                    <Collapsible open={hasCrossVariantInventory} id="cross-variant-inventory-collapsible">
                      <TextField type="number" label="Total capacity for each event time in your event calendar" value={inventory} placeholder="100" onChange={(v) => setInventory(v)} />
                    </Collapsible>
                  </>
                ) : (
                  <>
                    <Checkbox
                      checked={hasCrossVariantInventory}
                      label="Share inventory across ticket types"
                      onChange={(v) => setHasCrossVariantInventory(v)}
                      helpText={
                        isVendor ?
                          "Enable this option if you have a limited number of tickets across all your ticket types rather than a limit per ticket type. This will move inventory control to Evey instead of Shopify so there may be a delay in updating available quantities and overselling is possible. Do not use this if you expect to sell all your tickets very quickly, in that case leave inventory management to Shopify."
                           :
                          "Enable this option if you have a limited number of tickets across all your ticket types rather than a limit per ticket type."
                      }
                    />
                    <Collapsible open={hasCrossVariantInventory} id="cross-variant-inventory-collapsible">
                      <TextField type="number" label={`Total available inventory across ${isVendor ? 'ticket types' : 'variants'}`} value={inventory} placeholder="100" onChange={(v) => setInventory(v)} />
                    </Collapsible>
                  </>
                )}
              </>
            }

            <Checkbox
              checked={waitlistEnabled}
              label={ `Enable waitlist ${ event.hasSeating ? '(disabled because the event has seating )' : '' }` }
              onChange={(v) => setWaitlistEnabled(v)}
              disabled={event.hasSeating}
              helpText="Enabled Waitlist for this event"
            />

            { waitlistEnabled && hasKlaviyoFeature &&
              <Checkbox
                checked={waitlistKlaviyoIntegrationEnabled}
                label="Enable waitlist integration with Klaviyo"
                onChange={(v) => setWaitlistKlaviyoIntegrationEnabled(v)}
                helpText="Enabled Waitlist Klaviyo Integration for this event"
              />
            }

            <Checkbox
              checked={hasEtickets}
              label="Enable eTickets - delivered via email"
              onChange={(v) => setHasEtickets(v)}
              helpText="If you enable eTickets, all attendees will be sent an email with their ticket information. Attendees will have access to their online ticket page from this notification, which contains the event info, ticket details, and scannable QR code. You can also select any additional ticket formats you'd like to allow your attendees to download."
            />
            <Collapsible open={hasEtickets} id="has-etickets-collapsible">
              {!isVendor &&
                <div className="EventEdit__ETicketField">
                  <ChoiceList
                    title="When should we send the tickets?"
                    choices={[
                      { label: 'Automatically fulfill orders and send tickets to attendees as soon as they are paid', value: 'immediately' },
                      { label: 'Wait for orders to be fulfilled in your Shopify Admin before sending tickets to attendees.', value: 'on-fulfill' },
                      { label: "I'll manually trigger it, never automatically send tickets.", value: 'manual' }
                    ]}
                    selected={deliveryTrigger}
                    onChange={(v) => setDeliveryTrigger(v)}
                  />
                </div>
              }

              <div className="EventEdit__ETicketField">
                <ChoiceList
                  title="Additional Ticket Formats"
                  allowMultiple={true}
                  choices={[
                    { label: 'PDF', value: 'pdf' },
                    { label: 'Apple Wallet Pass', value: 'passbook' },
                    { label: '"Add to calendar" options', value: 'ics' }
                  ]}
                  selected={ticketFormats}
                  onChange={(v) => setTicketFormats(v)}
                />
              </div>

              <div className="EventEdit__ETicketField">
                <Checkbox
                  checked={allowTransfers}
                  label="Enable ticket transfers"
                  helpText="Allows ticket transfers through the online ticket page. Disable this if you don't want your attendees to change their ticket details after purchase."
                  onChange={(v) => setAllowTransfers(v)}
                />
              </div>

              <div className="EventEdit__ETicketField">
                <Checkbox
                  checked={overrideContactEmail}
                  label={isVendor ? `Use different email address for customer emails than your organizer (${shop.email})` : `Use different email address for customer emails than your store (${shop.email})`}
                  onChange={setOverrideContactEmail}
                />
              </div>
              {overrideContactEmail &&
                <div className="EventEdit__ETicketField">
                  <TextField
                    value={contactEmail || ''}
                    type="text"
                    label="Primary contact email for your event"
                    onChange={(v) => setContactEmail(v)}
                    helpText='Ticket or confirmation emails sent to attendees will have the Reply-To configured to this address. To add a custom display name, use the format "Display Name <email@domain.com>" or the event name will be used as the display name.'
                  />
                </div>
              }

              <div className="EventEdit__ETicketField">
                <TextField
                  value={ticketNumberStart || ''}
                  label="First ticket number"
                  type="number"
                  min={0}
                  onChange={(v) => setTicketNumberStart(v)}
                  helpText="Each attendee will have a sequential ticket number, change this to start at something other than 1."
                />
              </div>

              {shop.hasRandomTicketNumbersFeature &&
                <div>
                  <div className="EventEdit__ETicketField">
                    <Checkbox
                      checked={randomTicketNumberEnd && randomTicketNumberEnd != ''}
                      label="Use range of ticket numbers, randomly assigned to each attendee"
                      onChange={(v) => setRandomTicketNumberEnd(v ? (parseInt(ticketNumberStart || 0) + 1000).toString() : '')}
                    />
                  </div>

                  {randomTicketNumberEnd && randomTicketNumberEnd != '' &&
                    <div className="EventEdit__ETicketField">
                      <TextField
                        value={randomTicketNumberEnd || ''}
                        label="End of ticket number range"
                        type="number"
                        min={parseInt(ticketNumberStart || 0)}
                        onChange={(v) => setRandomTicketNumberEnd(v)}
                        helpText="Each attendee will be assigned a random number between the starting ticket number and the ending ticket number (including both numbers). Make sure to include a big enough range to accomodate all your available ticket inventory or numbers may be re-used."
                      />
                    </div>
                  }
                </div>
              }

              {!isVendor &&
                <div className="EventEdit__ETicketField">
                  <Checkbox
                    checked={shop.orderStatusEnabled == null ? orderStatusEnabled : shop.orderStatusEnabled}
                    disabled={shop.orderStatusEnabled != null}
                    label={`Show ticket download buttons on order status page${shop.orderStatusEnabled != null ? ' (configured in global settings)' : ''}`}
                    helpText=<span>A link to download the ticket(s) will be displayed on the order status page, the page at the end of the checkout flow after a customer purchases a ticket. See our <Link external url={'https://evey-events.zendesk.com/hc/en-us/'}>help page</Link> for more information on this feature.</span>
                    onChange={(v) => setOrderStatusEnabled(v)}
                  />
                </div>
              }
            </Collapsible>
          </FormLayout>
        </Card>
      )}
    </Mutation>
  );
};

export default EventSettingsCard;
