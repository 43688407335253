import React from 'react';
import SettingsPage from './SettingsPage';
import { Layout } from '@shopify/polaris';
import { Query } from 'react-apollo';
import { GET_SHOP_INTEGRATIONS } from '../../utils/graphql';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import MailchimpCard from '../Mailchimp/MailchimpCard';
import { useFrame } from '../../contexts/frame';
import KlaviyoCard from '../Klaviyo/KlaviyoCard';

const IntegrationsPage = () => {
  const { currentShopDomain } = useFrame();

  return (
    <SettingsPage
      pageTitle='Integrations'
      title='Integrations'
      fullWidth={false}
      hideKlaviyoKeyBanner={true}
      pageSubtitle='Manage external integrations'
    >
      <Query query={GET_SHOP_INTEGRATIONS} >
        {({ loading, data }) => {
          if (loading) {
            return <Layout><AnnotatedSectionLoading /></Layout>;
          }

          const shop = data.currentShop;

          return (
            <Layout>
              <Layout.AnnotatedSection
                title="Mailchimp"
                description=<div>
                  <p>
                    The MailChimp integration allows you to link your MailChimp account and a selected audience list
                    to all of your events so that new attendee contacts are kept in sync with that list, allowing you to
                    easily manage email compaigns and other features that MailChimp supports.
                  </p>
                  <p>
                    You can also override these Mailchimp settings for any event in the Event Settings area for the event.
                  </p>
                </div>
              >
                <MailchimpCard
                  shop={shop}
                  mailchimp={shop.mailchimp}
                  redirectTo={`/settings/integrations/mailchimp?shop=${shop.domain}`}
                />
              </Layout.AnnotatedSection>

              <Layout.AnnotatedSection
                title="Klaviyo"
                description=<div>
                  <p>
                    The Klaviyo integration allows you to link your Klaviyo account to all of your events. When tickets
                    are created, cancelled, or deleted activity metrics and ticket data will be sent to Klaviyo so that
                    you can segment, automate, and analyze using the Klavyo marketing and analytics tools.
                  </p>
                </div>
              >
                <KlaviyoCard
                  shop={shop}
                  klaviyo={shop.klaviyo}
                  redirectTo={`/settings/integrations/klaviyo?shop=${shop.domain}`}
                />
              </Layout.AnnotatedSection>
            </Layout>
          );
        }}
      </Query>
    </SettingsPage>
  );
};

export default IntegrationsPage;
