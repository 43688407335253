import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Modal, TextField, FormLayout, Heading, TextContainer, Link as ShopifyLink, Select } from '@shopify/polaris';
import { useFrame } from '../../../contexts/frame';
import ModalFooter from '../../shared/ModalFooter.jsx';
import { getShopNameFromDomain } from '../../../utils/auth';

const SAVE_LOCATION = gql`
  mutation saveLocation($locationId: ID, $name: String!, $url: String, $code: String, $imageUrl: String, $address: String, $description: String, $seatingChartId: ID) {
    saveLocation(locationId: $locationId, name: $name, url: $url, code: $code, imageUrl: $imageUrl, address: $address, description: $description, seatingChartId: $seatingChartId) {
      location {
        id
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation deleteLocation($locationId: ID!) {
    deleteLocation(locationId: $locationId) {
      success
    }
  }
`;

const LocationModal = ({ shop, location, onClose }) => {
  const [name, setName] = React.useState(location ? location.name : '');
  const [url, setUrl] = React.useState(location ? location.url : '');
  const [code, setCode] = React.useState(location ? location.code : '');
  const [imageUrl, setImageUrl] = React.useState(location ? location.imageUrl : '');
  const [address, setAddress] = React.useState(location ? location.address : '');
  const [description, setDescription] = React.useState(location ? location.description : '');
  const [seatingChartId, setSeatingChartId] = React.useState(location && location.seatingChart ? location.seatingChart.id : null);

  const { showToastNotice, showToastError } = useFrame();

  const shopName = getShopNameFromDomain(shop?.domain);

  const isValid = name && name.length > 0;

  return (
    <Mutation
      mutation={DELETE_LOCATION}
      onError={() => showToastError('Failed to delete location')}
      onCompleted={() => {
        showToastNotice('Location deleted');
        onClose();
      }}
      refetchQueries={() => ['GetLocations']}
      awaitRefetchQueries={true}
    >
      {(deleteLocation, { loading: deleting }) => (
        <Mutation
          mutation={SAVE_LOCATION}
          onError={() => showToastError(location ? 'Failed to update location' : 'Failed to add location')}
          onCompleted={() => {
            showToastNotice(location ? 'Location updated' : 'Location created');
            onClose();
          }}
          refetchQueries={() => ['GetLocations']}
        >
          {(saveLocation, { loading: saving }) => (
            <Modal
              open={true}
              onClose={onClose}
              title={location ? `Edit location` : 'Add location'}
              footer={
                <ModalFooter
                  saveButtonText="Save"
                  saveButtonAction={() => {
                    saveLocation({
                      variables: {
                        locationId: location ? location.id : null,
                        name,
                        url,
                        code,
                        imageUrl,
                        address,
                        description,
                        seatingChartId: seatingChartId == 'none' ? null : seatingChartId,
                      },
                    });
                  }}
                  saveButtonLoading={saving}
                  saveButtonDisabled={!isValid || deleting}
                  cancelButtonAction={onClose}
                  cancelButtonDisabled={saving || deleting}
                  destructiveButtonText={'Delete'}
                  destructiveButtonAction={location ? () => {
                    deleteLocation({
                      variables: {
                        locationId: location.id,
                      },
                    });
                  } : undefined}
                  destructiveButtonDisabled={saving}
                  destructiveButtonLoading={deleting}
                />
              }
            >
              <Modal.Section>
                <FormLayout>
                  <TextField
                    label="Name"
                    value={name}
                    placeholder="Ottawa, ON"
                    onChange={setName}
                    helpText="Friendly name of the location. This will be used to display the location on tickets."
                  />
                  <TextField value={url} label="URL" placeholder='https://goo.gl/maps/RMFXyDo5jh8DpwFS8' onChange={setUrl} />
                  <TextField
                    label="Code"
                    value={code}
                    placeholder="YYZ"
                    onChange={setCode}
                    helpText="Short location code. This is only used in origin/destination transit type events on some ticket types. For example, the airport code or bus terminal."
                  />
                  <Select
                    label='Seating plan'
                    options={[
                      { label: '', value: 'none' },
                      ...shop.seatingCharts.map((s) => ({ label: s.name, value: s.id }))
                    ]}
                    value={seatingChartId}
                    onChange={setSeatingChartId}
                  />
                </FormLayout>
              </Modal.Section>
              <Modal.Section>
                <FormLayout>
                  <Heading>More details</Heading>
                  <TextContainer>These fields are not used by default on events but are available for custom liquid templates and through metafields on the storefront.</TextContainer>
                  <TextField value={imageUrl} label="Image URL" onChange={setImageUrl} helpText=<div>Upload any custom files through the Shopify <ShopifyLink external url={`https://admin.shopify.com/store/${shopName}/content/files?selectedView=all`} target="_blank">files section</ShopifyLink> and paste the URL here.</div> />
                  <TextField value={address} label="Address" onChange={setAddress} />
                  <TextField value={description} label="Description" onChange={setDescription} multiline={3} />
                </FormLayout>
              </Modal.Section>
            </Modal>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default LocationModal;
