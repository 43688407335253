import React from 'react';
import { Filters, ChoiceList, Select, Stack, Autocomplete, Tag } from '@shopify/polaris';

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === '' || value == null;
  }
};

const EventFilters = ({ shop, onUpdate, filters, filterableEvents, includeStatusFilter }) => {
  const [localSearchValue, setLocalSearchValue] = React.useState(filters.search || '');
  const [searchValue, setSearchValue] = React.useState(filters.search || '');
  const [tags, setTags] = React.useState(filters.appliedFilters.find((f) => f.key === 'tags')?.value || []);
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'subscriptionStatus')?.value || []);
  const [productStatus, setProductStatus] = React.useState(filters.appliedFilters.find((f) => f.key === 'productStatus')?.value || []);
  const [ids, setIds] = React.useState(filters.appliedFilters.find((f) => f.key === 'ids')?.value || []);
  const [eventsInputText, setEventsInputText] = React.useState('');

  const showEventsFilter = !!filterableEvents;
  const showSubscriptionStatusFilter = !filterableEvents && includeStatusFilter;

  React.useEffect(() => {
    let appliedFilters = [];
    if (!isEmpty(tags)) {
      appliedFilters.push({
        key: 'tags',
        label: `Tagged with ${tags}`,
        value: tags,
        onRemove: () => setTags([]),
      });
    }
    if (!isEmpty(subscriptionStatus)) {
      appliedFilters.push({
        key: 'subscriptionStatus',
        label: `Subscription status: ${subscriptionStatus}`,
        value: subscriptionStatus,
        onRemove: () => setSubscriptionStatus([]),
      });
    }
    if (!isEmpty(ids)) {
      appliedFilters.push({
        key: 'ids',
        label: `${ids.length} ${ids.length == 1 ? 'event' : 'events'}`,
        value: ids,
        onRemove: () => setIds([]),
      });
    }
    if (!isEmpty(productStatus)) {
      appliedFilters.push({
        key: 'productStatus',
        label: `Publish status: ${productStatus}`,
        value: productStatus,
        onRemove: () => setProductStatus([]),
      });
    }

    onUpdate({
      search: searchValue,
      filters: appliedFilters,
    });
  }, [searchValue, tags, subscriptionStatus, ids, productStatus]);

  const filterList = [
    ...(showEventsFilter ? [{
      key: 'ids',
      label: 'Events',
      filter: (
        <Stack vertical spacing='loose'>
          <Autocomplete
            allowMultiple
            options={filterableEvents.filter((e) => e.title.toLowerCase().indexOf(eventsInputText.toLowerCase()) != -1).map((e) => ({ value: e.id, label: e.title }))}
            selected={ids}
            textField=<Autocomplete.TextField
              onChange={setEventsInputText}
              label="Events"
              value={eventsInputText}
              placeholder="Event title"
            />
            onSelect={(v) => setIds(v)}
            listTitle="Events"
          />
          {ids.length > 0 ? (
              <Stack vertical={false} spacing='tight'>
                {ids.map((eventId, index) => (
                  <Tag
                    key={eventId}
                    onRemove={() => {
                      let tmp = [...ids];
                      tmp.splice(index, 1);
                      setIds(tmp);
                    }}
                  >
                    {filterableEvents.find((e) => e.id == eventId).title}
                  </Tag>
                ))}
              </Stack>
            ) : ('')
          }
        </Stack>
      ),
      shortcut: true,
    }] : []),
    {
      key: 'tags',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={shop ? shop.eventTags.map((tag) => ({
            label: tag, value: tag,
          })) : []}
          selected={tags}
          onChange={(v) => setTags(v)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    ...(showSubscriptionStatusFilter ? [{
      key: 'subscriptionStatus',
      label: 'Subscription status',
      filter: (
        <ChoiceList
          title="Subscription status"
          titleHidden
          choices={[
            {label: 'Active', value: 'active'},
            {label: 'Trial', value: 'trial'},
            {label: 'Expired', value: 'expired'},
          ]}
          selected={subscriptionStatus}
          onChange={(v) => setSubscriptionStatus(v)}
        />
      ),
      shortcut: true,
    }] : []),
    {
      key: 'productStatus',
      label: 'Publish status',
      filter: (
        <ChoiceList
          title="Publish status"
          titleHidden
          choices={[
            {label: 'Active', value: 'active'},
            {label: 'Draft', value: 'draft'},
          ]}
          selected={productStatus}
          onChange={(v) => setProductStatus(v)}
        />
      ),
      shortcut: true,
    }
  ];

  return (
    <div onKeyDown={(ev) => {
      if (ev.keyCode == 13) {
        setSearchValue(localSearchValue);
      }
    }}>
      <Filters
        queryValue={localSearchValue}
        queryPlaceholder='Search events'
        filters={filterList}
        appliedFilters={filters.appliedFilters}
        onQueryChange={(v) => setLocalSearchValue(v)}
        onQueryClear={() => {
          setSearchValue('');
          setLocalSearchValue('');
        }}
        onClearAll={() => {
          setSearchValue('');
          setLocalSearchValue('');
          setTags([]);
          setSubscriptionStatus([]);
          setProductStatus([]);
        }}
      />
    </div>
  );
};

export default EventFilters
