import React from 'react';
import { FormLayout, TextField, Autocomplete, ChoiceList, Select, Link as ShopifyLink, Stack, Banner, Subheading } from '@shopify/polaris';
import { getHostFromShopDomain } from '../../../utils/auth';
import { useFrame } from '../../../contexts/frame';
import { useLocation } from "react-router-dom";

const LocationTransitForm = ({
  shop, event, locationType, transitType, setTransitType,
  originName, setOriginName, originUrl, setOriginUrl, originCode, setOriginCode,
  destinationName, setDestinationName, destinationUrl, setDestinationUrl, destinationCode, setDestinationCode,
  originId, setOriginId, destinationId, setDestinationId
}) => {
  const [useStoredLocation, setUseStoredLocation] = React.useState(originId != null || destinationId != null);
  const location = useLocation();
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);

  return (
    <FormLayout>
      <ChoiceList
        title=""
        titleHidden
        choices={[
          { value: true, label: <span>Use a saved locations that you have already set up in <ShopifyLink external url={`/settings/locations${searchParams}`} target="_blank">the location settings</ShopifyLink>.</span> },
          { value: false , label: 'Set a new locations just for this event' },
        ]}
        selected={[useStoredLocation]}
        onChange={(v) => {
          setUseStoredLocation(v[0])
          if (!v[0]) {
            setOriginId(null);
            setDestinationId(null);
          } else {
            setOriginId(shop.locations.length ? shop.locations[0].id : null);
            setDestinationId(shop.locations.length ? shop.locations[0].id : null);
          }
        }}
      />
      {useStoredLocation ? (
        <Stack vertical={true}>
          <Select
            options={shop.locations.map((loc) => ({ value: loc.id, label: loc.name }))}
            value={originId}
            onChange={setOriginId}
            label='Select an origin location'
          />
          <Select
            options={shop.locations.map((loc) => ({ value: loc.id, label: loc.name }))}
            value={destinationId}
            onChange={setDestinationId}
            label='Select a destination location'
          />
        </Stack>
      ) : (
        <div style={{paddingTop:'10px'}}>
          <FormLayout>
            <Subheading>Origin</Subheading>
            <TextField value={originName || ''} label="Origin" placeholder="Ottawa, ON" onChange={(v) => setOriginName(v)} />
            <TextField value={originUrl || ''} label="Origin URL" placeholder={'https://goo.gl/maps/RMFXyDo5jh8DpwFS8'} onChange={(v) => setOriginUrl(v)} />
            <TextField value={originCode || ''} label="Origin Code" placeholder="YOW" onChange={(v) => setOriginCode(v)} />

            <Subheading>Destination</Subheading>
            <TextField value={destinationName || ''} label="Destination" placeholder="Toronto, ON" onChange={(v) => setDestinationName(v)} />
            <TextField value={destinationUrl || ''} label="Destination URL" placeholder={'https://goo.gl/maps/RMFXyDo5jh8DpwFS8'} onChange={(v) => setDestinationUrl(v)} />
            <TextField value={destinationCode || ''} label="Destination Code" placeholder="YYZ" onChange={(v) => setDestinationCode(v)} />
          </FormLayout>
        </div>
      )}

      <Subheading>Transit</Subheading>
      <Select
        label="Transit type"
        options={[
          { label: 'Flight', value: 'flight' },
          { label: 'Bus', value: 'bus' },
          { label: 'Other', value: 'other' },
        ]}
        value={transitType}
        onChange={setTransitType}
      />
    </FormLayout>
  );
};

export default LocationTransitForm;
