import React from 'react';
import { Banner, TextContainer, Modal, List, Button, Link as ShopifyLink } from '@shopify/polaris';
import { getHostFromShopDomain } from '../../utils/auth';

const CompletedBanner = ({ shop, event, reminder }) => {
  const searchParams = "?host=" + getHostFromShopDomain(shop?.domain);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);

  const errorEvents = reminder.errorEvents && reminder.errorEvents.length && JSON.parse(reminder.errorEvents);

  const errorModal = (
    <Modal
      open={true}
      onClose={() => setErrorModalOpen(false)}
      title={'Message delivery issues'}
      secondaryActions={[
        {
          content: 'Close',
          onAction: () => setErrorModalOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <List type="bullet">
          {errorEvents.map((errorEvent, index) => (
            <List.Item key={index}>
              Attendee <ShopifyLink external url={`/events/${event.id}/attendees/${errorEvent.attendee.id}${searchParams}`}>#{errorEvent.attendee.number}</ShopifyLink>: {errorEvent.error}
            </List.Item>
          ))}
        </List>
      </Modal.Section>
    </Modal>
  );

  return (
    <div>
      <Banner
        title='Send has completed'
        status='success'
      >
        <TextContainer>The message has been sent to all{reminder.sendUnique ? ' unique' : ''} attendees you selected.</TextContainer>
        <TextContainer>{reminder.sendUnique && reminder.sentCount < reminder.totalCount ? (
          `${reminder.sentCount} messages sent to your attendees.`
        ) : (
          `${reminder.sentCount} of ${reminder.totalCount} messages sent to your attendees.`
        )}
        </TextContainer>
        {errorEvents && errorEvents.length ?
            <Button plain onClick={() => setErrorModalOpen(true)}>View issues</Button>
          :
            undefined
        }
      </Banner>
      {errorModalOpen && errorModal}
    </div>
  );
};

export default CompletedBanner;
