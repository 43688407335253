import React from 'react';
import {
  Modal,
  FormLayout,
  TextField,
  Select,
  Checkbox,
  Popover,
  OptionList,
  Button,
  InlineError,
  Badge,
  Stack,
  Subheading,
} from '@shopify/polaris';
import { useState } from 'react';
import OptionListSelect from '../shared/OptionListSelect';

const isStaticField = (spec) => ['header', 'custom'].includes(spec.type);

const AttendeeInfoSpecModal = ({ spec, onSave, onClose, saving, specs, ticketTypes }) => {
  const [name, setName] = useState(spec ? spec.name : '');
  const [label, setLabel] = useState(spec ? spec.label : '');
  const [type, setType] = useState(spec ? spec.type : 'text');
  const [options, setOptions] = useState(spec ? spec.options || [] : []);
  const [allowMultiple, setAllowMultiple] = useState(spec ? !!spec.allow_multiple : false);
  const [required, setRequired] = useState(spec ? spec.required : false);
  const [hideStorefront, setHideStorefront] = useState(spec ? spec.hide_storefront : false);
  const [allTicketTypes, setAllTicketTypes] = useState(spec ? spec.ticket_types == null : true);
  const [selectedTicketTypes, setSelectedTicketTypes] = useState(spec && spec.ticket_types != null ? spec.ticket_types.map((tId) => tId) : []);
  const [ticketTypesPopoverActive, setTicketTypesPopoverActive] = useState(false);
  const [hideTicketPage, setHideTicketPage] = useState(spec ? spec.hide_ticket_page : false);
  const [readOnlyTicketPage, setReadOnlyTicketPage] = useState(spec ? spec.readonly_ticket_page : false);
  const [checkinHide, setCheckinHide] = useState(spec ? spec.checkin_hide : false);
  const [checkinEdit, setCheckinEdit] = useState(spec ? spec.checkin_editable : false);
  const [hasCondition, setHasCondition] = useState(spec ? (spec.conditions || []).length > 0 : false);
  const [conditions, setConditions] = useState(spec ? spec.conditions || [] : []);

  const conditionIndex = 0;
  const condition = conditions[conditionIndex];
  const conditionSpec = condition && specs && specs.find((s) => s.name == condition.field);

  const primaryAction = {
    content: 'Save',
    disabled: !name.length || !label.length || saving,
    loading: saving,
    onAction: () => {
      onSave({
        name,
        label,
        type,
        ...(type == 'select' || type == 'choice' ? { options } : {}),
        ...(type == 'choice' ? { allow_multiple: allowMultiple } : {}),
        required,
        hide_storefront: hideStorefront,
        ...(!allTicketTypes ? { ticket_types: selectedTicketTypes } : {}),
        hide_ticket_page: hideTicketPage,
        readonly_ticket_page: readOnlyTicketPage,
        checkin_hide: checkinHide,
        checkin_editable: checkinEdit,
        conditions: hasCondition ? (conditions || []) : [],
      });
    },
  };

  const secondaryActions = [
    {
      content: 'Cancel',
      disabled: saving,
      onAction: () => {
        onClose();
      },
    },
  ];

  function stringToSlug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to   = 'aaaaeeeeiiiioooouuuunc------';
    for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    if (str.length == 0) {
      str = makeid(6);
    }

    return str;
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const buildNewName = (newValue) => {
    let newName = stringToSlug(newValue.substring(0, 10));
    if (specs.find((s) => s.name == newName)) {
      let i = 2;
      let altNewName = newName;
      do {
        altNewName = `${newName}-${i}`;
        i++;
      } while (specs.find((s) => s.name == altNewName));
      newName = altNewName;
    }
    return newName;
  }

  const updateLabel = (newValue) => {
    setLabel(newValue);
    if (!spec) {
      if (['firstname', 'lastname', 'email', 'phone'].includes(name)) {
        setName(name);
      } else {
        setName(buildNewName(newValue));
      }
    }
  };

  let title = spec ? spec.name : 'New information field';
  if (spec) {
    if (['firstname', 'lastname', 'email', 'phone'].includes(type)) {
      title = spec.label;
    }
  }

  const selectedTicketTypesTitles = ticketTypes && selectedTicketTypes
    .filter((ticketTypeId) => ticketTypes.find((t) => t.id == ticketTypeId))
    .map((ticketTypeId) => ticketTypes.find((t) => t.id == ticketTypeId).title);
  let ticketTypesLabel = selectedTicketTypesTitles ? `${selectedTicketTypesTitles.length} ticket type${selectedTicketTypesTitles.length == 1 ? '' : 's'} selected` : 'Select ticket types';

  const staticField = isStaticField({ type });

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <FormLayout>
          <OptionListSelect
            label='Type'
            sections={[
              {
                title: 'Custom fields',
                options: [
                  { label: 'Text', value: 'text' },
                  { label: 'Checkbox', value: 'checkbox' },
                  { label: 'Dropdown', value: 'select' },
                  { label: 'Choice list', value: 'choice' },
                  { label: 'Header', value: 'header' },
                  { label: 'Custom content', value: 'custom' },
                ],
              },
              {
                title: 'Primary fields',
                options: [
                  { label: 'First name', value: 'firstname', disabled: spec?.type != 'firstname' && specs.some((s) => s.name == 'firstname') },
                  { label: 'Last name', value: 'lastname', disabled: spec?.type != 'lastname' && specs.some((s) => s.name == 'lastname') },
                  { label: 'Email', value: 'email', disabled: spec?.type != 'email' && specs.some((s) => s.name == 'email') },
                  { label: 'Phone', value: 'phone', disabled: spec?.type != 'phone' && specs.some((s) => s.name == 'phone') },
                ],
              },
            ]}
            value={[['firstname', 'lastname', 'email', 'phone'].includes(name) ? name : type]}
            onChange={(v) => {
              if (['firstname', 'lastname', 'email', 'phone'].includes(v[0])) {
                setName(v[0]);
                setType('text');
              } else {
                if (['firstname', 'lastname', 'email', 'phone'].includes(name)) {
                  setName(buildNewName(label));
                }
                setType(v[0]);
              }
            }}
            width='300px'
          />
          {staticField ? (
            <TextField
              label='Text'
              type='text'
              multiline={true}
              value={label}
              onChange={updateLabel}
              helpText='Static text to include for header or custom content'
            />
          ) : (
            <TextField
              label='Label'
              type='text'
              value={label}
              onChange={updateLabel}
              helpText='This is what the customer sees when being asked to enter this information.'
            />
          )}
          {(type == 'select' || type == 'choice') &&
            <TextField
              multiline
              label={type == 'choice' ? 'Options' : 'Dropdown Options'}
              helpText={`List of ${type == 'choice' ? 'options' : 'options for the dropdown'}. Add one per line.`}
              type='text'
              value={options.join('\n')}
              onChange={(v) => setOptions(v.split('\n'))}
            />
          }
          {type == 'choice' &&
            <Checkbox
              checked={allowMultiple}
              label='Allow multiple options to be selected'
              onChange={setAllowMultiple}
            />
          }
          {!staticField &&
            <Checkbox value={true} checked={required} label='The attendee is required to complete this field' onChange={(v) => setRequired(v)} />
          }
          {ticketTypes &&
            <Checkbox value={true} checked={allTicketTypes} label={staticField ? 'Show this content for all ticket types' : 'Collect this information for all ticket types'} onChange={(v) => setAllTicketTypes(v)} />
          }
          {ticketTypes && !allTicketTypes &&
            <div style={{ paddingTop: '10px' }}>
            <FormLayout>
              <Popover
                active={ticketTypesPopoverActive}
                activator={<Button onClick={() => setTicketTypesPopoverActive(!ticketTypesPopoverActive)}>{ticketTypesLabel}</Button>}
                onClose={() => setTicketTypesPopoverActive(false)}
              >
                <OptionList
                  title='Ticket types'
                  onChange={(v) => setSelectedTicketTypes(v)}
                  options={ticketTypes.map((t) => ({ label: t.title, value: t.id.toString() }))}
                  selected={selectedTicketTypes}
                  allowMultiple
                />
              </Popover>
              {(!selectedTicketTypes || selectedTicketTypes.length == 0) &&
                <InlineError message='No ticket types selected' />
              }
            </FormLayout>
            </div>
          }
          <Checkbox
            checked={hasCondition}
            label='Show this field only under certain conditions'
            onChange={setHasCondition}
          />
          {hasCondition &&
            <Stack vertical={true} spacing='tight'>
              <Select
                label='When this field'
                options={[
                  { label: '', value: '' },
                  ...specs.filter((s) => !isStaticField(s)).map((s) => ({ label: s.label, value: s.name }))
                ]}
                value={condition?.field || ''}
                onChange={(v) => {
                  setConditions([
                    ...conditions.filter((c, i) => i != conditionIndex),
                    { field: v, op: 'equals' },
                  ]);
                }}
              />
              <Select
                options={[
                  { label: conditionSpec?.type == 'select' ? 'is any of' : 'has a value of', value: 'equals' },
                  { label: conditionSpec?.type == 'select' ? 'is none of' : 'is not', value: 'not' },
                ]}
                value={condition?.op || 'equals'}
                onChange={(v) => {
                  setConditions([
                    ...conditions.filter((c, i) => i != conditionIndex),
                    { ...condition , op: v },
                  ]);
                }}
              />
              {conditionSpec && conditionSpec.type == 'select' ? (
                  <OptionList
                    title=""
                    options={(conditionSpec.options || []).map((option) => ({ label: option, value: option }))}
                    selected={condition && condition.value || []}
                    allowMultiple
                    onChange={(v) => {
                      console.log(`onChange: `, v);
                      setConditions([
                        ...conditions.filter((c, i) => i != conditionIndex),
                        { ...condition, value: v },
                      ]);
                    }}
                  />
                ) : (
                  conditionSpec && conditionSpec.type == 'checkbox' ? (
                    <Select
                      label=''
                      options={[
                        { label: 'No', value: '0' },
                        { label: 'Yes', value: '1' },
                      ]}
                      value={condition && condition.value && condition.value[0] === true ? '1' : '0'}
                      onChange={(v) => {
                        setConditions([
                          ...conditions.filter((c, i) => i != conditionIndex),
                          { ...condition, value: [v != '0'] },
                        ]);
                      }}
                    />
                  ) : (
                    <TextField
                      label=''
                      value={condition && condition.value && condition.value[0] || ''}
                      onChange={(v) => {
                        setConditions([
                          ...conditions.filter((c, i) => i != conditionIndex),
                          { ...condition, value: [v] },
                        ]);
                      }}
                    />
                  )
                )
              }
            </Stack>
          }
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <Subheading>Storefront</Subheading>
          <Checkbox value={true} checked={hideStorefront} label='Hide this field on the storefront popup before they purchase their ticket' onChange={(v) => setHideStorefront(v)} />
        </FormLayout>
      </Modal.Section>
      <Modal.Section title="Ticket page">
        <FormLayout>
          <Subheading>Ticket page</Subheading>
          <Checkbox value={true} checked={hideTicketPage} label='Hide this field on their ticket page' onChange={(v) => setHideTicketPage(v)} />
          {!staticField &&
            <Checkbox value={true} checked={readOnlyTicketPage} label='This field cannot be changed on their ticket page' onChange={(v) => setReadOnlyTicketPage(v)} />
          }
        </FormLayout>
      </Modal.Section>
      <Modal.Section title="Check-In app">
        <FormLayout>
          <Subheading>Check-In app</Subheading>
          <Checkbox value={true} checked={checkinHide} label='Hide this field in the check-in app' onChange={(v) => setCheckinHide(v)} />
          {!checkinHide && !staticField &&
            <Checkbox value={true} checked={checkinEdit} label='Allow this field to be changed in the check-in app' onChange={(v) => setCheckinEdit(v)} />
          }
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <TextField
            label='Unique ID'
            type='text'
            readonly
            disabled
            value={name}
            helpText='A short unique name for internal use only. You will also need this if you do any custom storefront integrations.'
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default AttendeeInfoSpecModal;
