import React from 'react';
import EventQuery from '../EventPage/EventQuery.jsx';
import { Layout, Banner } from '@shopify/polaris';
import AnnotatedSectionLoading from '../shared/AnnotatedSectionLoading.jsx';
import VirtualEventSettings from '../VirtualEvents/VirtualEventSettings.jsx';

const EVENT_FIELDS = `
  tickets {
    id
    title
    price
    visible
    inventory
    variantId
    groupSize
    variant {
      id
      title
      price
      option1
      option2
      option3
    }
    eventDate
  }
  zoom {
    id
    active
  }
`;

const VirtualEvents = ({ eventId, flashCallback, history }) => {
  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      flashCallback={flashCallback}
      history={history}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <Layout>
          {event.locationType == 'online' ? (
            <VirtualEventSettings
              shop={shop}
              event={event}
              flashCallback={flashCallback}
              history={history}
            />
          ) : (
            <Banner status='info'>This event is not configured as an online/virtual event. The virtual event page is only available for online events. If you&apos;d like to make this event an online event, go to the event settings page</Banner>
          )}
        </Layout>
      )}
    </EventQuery>
  );
};

export default VirtualEvents;
