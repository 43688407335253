import React from 'react';
import TimeAgo from 'react-timeago';
import { Banner } from '@shopify/polaris';
import SubscribeEvent from '../SubscribeEvent/SubscribeEvent.jsx';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../utils/routing';
import { getHostFromShopDomain } from '../../utils/auth';
import { useFrame } from '../../contexts/frame';
import { hasNewPricing } from '../../utils/features';

const LegacyTrial = ({ shop, event }) => {
  const hostParam = "&host=" + getHostFromShopDomain(shop?.domain);
  const [activateError, setActivateError] = React.useState(false);
  const [activateClicked, setActivateClicked] = React.useState(false);

  const { showToastNotice, showToastError, navigateTo } = useFrame();
  const history = useHistory();

  const newPricingEnabled = hasNewPricing(shop.domain);

  const subscribeEventMarkup = (
    <SubscribeEvent
      event={event}
      onConfirmRequest={(confirmUrl) => navigateTo({ history, path: confirmUrl, adminPath: true })}
      onError={() => {
        showToastError('Error activating event, please try again or contact support');
        setActivateClicked(false);
      }}
      onDismiss={() => setActivateClicked(false)}
    />
  );

  if (!newPricingEnabled || shop.hasLegacyPricing) {
    if (!shop.subscribed && !event.subscribed && !event.isMissingProduct && event.trial) {
      if (event.dateType === 'recurring' && !shop.canUseRecurringEvents) {
        return (
          <div className="trial">
            <Banner
              title="This event is in trial mode"
              action={{
                content: 'Learn more',
                onAction: () => goToPage({ history, path: '/settings/account?tab=subscription&plan=shop' }),
              }}
              status="info"
            >
              <p>Your event will expire in <TimeAgo date={event.expiresAt}/>. Recurring events are available on the Unlimited events plan, follow the link below to subscribe. </p>
            </Banner>
            {activateClicked && subscribeEventMarkup}
          </div>
        );
      } else {
        return (
          <div className="trial">
            <Banner
              title="This event is in trial mode"
              action={{
                content: 'Activate My Event',
                onAction: () => setActivateClicked(true),
              }}
              secondaryAction={{
                content: 'Learn about the unlimited events plan',
                onAction: () => goToPage({ history, path: '/settings/account?tab=subscription&plan=shop' }),
              }}
              status="info"
            >
              <p>Your event will expire in <TimeAgo date={event.expiresAt}/>. Activating an event costs ${parseInt(event.price)}/month for each event. </p>
            </Banner>
            {activateClicked && subscribeEventMarkup}
          </div>
        );
      }
    } else if (!shop.subscribed && !event.subscribed && !event.trial) {
      if (event.dateType === 'recurring' && !shop.canUseRecurringEvents) {
        return (
          <div className="trial">
            <Banner
              title="This event trial has expired"
              action={{
                content: 'Learn more',
                onAction: () => goToPage({ history, path: '/settings/account?tab=subscription&plan=shop' }),
              }}
              status="critical"
            >
              <p>Subscribe to continue selling tickets. Recurring events are available on the Unlimited events plan, follow the link below to subscribe.</p>
            </Banner>
            {activateClicked && subscribeEventMarkup}
          </div>
        );
      } else {
        return (
          <div className="trial">
            <Banner
              title="This events trial has expired"
              action={{
                content: 'Activate My Event',
                onAction: () => setActivateClicked(true),
              }}
              secondaryAction={{
                content: 'Learn about the unlimited events plan',
                onAction: () => goToPage({ history, path: '/settings/account?tab=subscription&plan=shop' }),
              }}
              status="critical"
            >
              <p>Activate your event to continue selling tickets. Activating an event costs ${parseInt(event.price)}/month for each event. </p>
            </Banner>
            {activateClicked && subscribeEventMarkup}
          </div>
        );
      }
    } else {
      return ('');
    }
  } else {
    return '';
  }
};

export default LegacyTrial;
