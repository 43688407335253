import React from 'react';
import { Layout } from '@shopify/polaris';
import EventMailchimp from '../Mailchimp/EventMailchimp';
import EventKlaviyo from '../Klaviyo/EventKlaviyo';
import EventFields from './Integrations/EventFields';
import EventCalendar from './Integrations/EventCalendar';
import { hasZoomIntegration } from '../../utils/features';
import { useFrame } from '../../contexts/frame';

const EventIntegrations = ({ shop, eventId }) => {
  return (
    <Layout>
      <EventCalendar eventId={eventId} />
      <EventMailchimp eventId={eventId} />
      <EventFields eventId={eventId} />
      <EventKlaviyo eventId={eventId} />
    </Layout>
  );
};

export default EventIntegrations;
