import React from 'react';
import { Card, TextStyle, Button, Icon } from '@shopify/polaris';
import TemplateEditorPreview from './TemplateEditorPreview';
import { ChevronRightMinor, ChevronLeftMinor } from '@shopify/polaris-icons';
import styled from '@emotion/styled';
import TemplateEditorBlockConfig from './TemplateEditorBlockConfig';

const OuterContainer = styled.div`
  margin:0;
  padding:0;
`;

const Container = styled.div`
  display:flex;
  flex-direction:row;
  width: 100vw;
  /* height: 100vh; */
  overflow: hidden;

  .Polaris-Card {
    border-radius:0;
  }
`;

const MobileToggle = styled.div(props => `
  position:absolute;
  left:${props.open ? '223px' : '0'};
  top: 63px;
  z-index:10;

  .Polaris-Button {
    border:none;
    padding:5px;
    border: 0.1rem solid #dfe3e8;
  }
  .Polaris-Button:hover {
    color: var(--p-text,#454f5b);
    text-decoration: none;
  }
  .Polaris-Button__Text {
    text-transform: capitalize;
    display:flex;
    align-items:center;
  }
`);

const TemplateEditor = ({ shop, template, preview, settings, strings, onChange, onStringsChange, previewLoading, onUseDefault, onDownloadSample }) => {
  let previewWidth, previewHeight;
  if (template.templateType == 'pdf-ticket') {
    previewWidth = `${settings?.settings?.page_width || '8.27'}in`;
    previewHeight = `${settings?.settings?.page_height || '11.7'}in`;
  }

  const mobileView = window.innerWidth <= 800;

  const [settingsOpen, setSettingsOpen] = React.useState(true);

  const schema = JSON.parse(template.templateSchema || '{}') || {};
  const prebuiltBlocks = JSON.parse(template.prebuiltBlocks || '{}') || {};
  const blocksCache = JSON.parse(template.availableBlocks || '{}') || {};

  return (
    <OuterContainer>
      <Container>
        {settingsOpen &&
          <TemplateEditorBlockConfig
            shop={shop}
            templateBlock={schema}
            blocksCache={blocksCache}
            title={schema.name}
            settings={settings}
            strings={strings}
            onChange={onChange}
            onStringsChange={onStringsChange}
            prebuiltBlocks={prebuiltBlocks}
            preview={preview}
            previewLoading={previewLoading}
            onUseDefault={onUseDefault}
          />
        }
        <TemplateEditorPreview
          preview={preview}
          settings={settings}
          strings={strings}
          loading={previewLoading}
          onClick={(target) => {
            if (target && target.getAttribute('data-template-string')) {
              const stringId = target.getAttribute('data-template-string');
              console.log(`stringId: ${stringId}`);
            }
          }}
          fixedWidth={previewWidth}
          fixedHeight={previewHeight}
          onDownloadSample={onDownloadSample}
        />
      </Container>
      {mobileView &&
        <MobileToggle open={settingsOpen}>
          <Button
            onClick={() => setSettingsOpen(!settingsOpen)}
          >
            <Icon source={settingsOpen ? ChevronLeftMinor : ChevronRightMinor} />
            {settingsOpen ? 'Hide' : 'Settings'}
          </Button>
        </MobileToggle>
      }
    </OuterContainer>
  );
};

export default TemplateEditor;
