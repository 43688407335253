import React from 'react';
import gql from 'graphql-tag';
import { Layout } from '@shopify/polaris';
import EventQuery from '../../EventPage/EventQuery.jsx';
import EventFieldsCard from './EventFieldsCard';
import { Query } from 'react-apollo';
import { useHistory } from "react-router-dom";
import { goToPage } from '../../../utils/routing';
import AnnotatedSectionLoading from '../../shared/AnnotatedSectionLoading.jsx';

const EVENT_FIELDS = `
  metafields
`;

const EventFields = ({ eventId }) => {
  const [selectedAudience, setSelectedAudience] = React.useState('0');

  const history = useHistory();

  return (
    <EventQuery
      eventId={eventId}
      extraEventAttributes={EVENT_FIELDS}
      loadingPage={<AnnotatedSectionLoading />}
    >
      {({ shop, event }) => (
        <Layout.AnnotatedSection
          title="Custom fields"
          description='Custom fields can be used to enter any information you need about the event. These fields are then exposed in all liquid templates and APIs.'>
          <EventFieldsCard
            shop={shop}
            event={event}
          />
        </Layout.AnnotatedSection>
      )}
    </EventQuery>
  );
};

export default EventFields;
