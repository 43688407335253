import React from 'react';
import { Mutation } from 'react-apollo';
import { EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION } from '../../utils/graphql';
import { Link as ShopifyLink, TextContainer, Badge, Banner, Card } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';

const StorefrontScriptCard = ({ shop }) => {
  const { showToastNotice, showToastError } = useFrame();

  const attendeeInfoStorefrontEnabled = shop && shop.attendeeInfoStorefrontEnabled;

  return (
    <Mutation key='enable' mutation={EDIT_ATTENDEE_INFO_STOREFRONT_INTEGRATION}
      onError={() => showToastError('Unable to update storefront integration')}
      onCompleted={() => showToastNotice('Storefront integration updated')}
      refetchQueries={() => ['StorefrontIntegrationQuery']}
    >
      {(editAttendeeInfoStorefrontIntegration, { loading: saving }) => (
        <Card sectioned
          title='Theme integration'
          primaryFooterAction={{
            content: attendeeInfoStorefrontEnabled ? 'Remove from storefront' : 'Enable on storefront',
            destructive: attendeeInfoStorefrontEnabled,
            loading: saving,
            onAction: () => {
              editAttendeeInfoStorefrontIntegration({
                variables: {
                  enabled: !attendeeInfoStorefrontEnabled,
                }
              });
            },
          }}
          footerActionAlignment='left'
        >
          <TextContainer>
            {attendeeInfoStorefrontEnabled ? (
              <div>
                <p><strong>Status: </strong> <Badge status='success'>Installed</Badge></p>
                <p>The storefront integration is currently <strong>installed and enabled</strong>. Your customers may be asked for their information for each ticket in their cart before purchasing the tickets or for recurring events they may be asked to select the date/time for their ticket. To remove it, click the button below.</p>
              </div>
            ) : (
              <div>
                <p><strong>Status: </strong> <Badge status='critical'>Disabled</Badge></p>
                <p>The storefront integration is <strong>not installed</strong> on your store and your customers will <strong>not</strong> be asked for extra information you have configured for each event before purchasing their tickets. If you have any recurring events set up you should not disable this unless you have a custom solution for selecting the date/time.</p>
              </div>
            )}
          </TextContainer>
          <Banner status='info'>
            <ul>
              <li>We do not permanently install anything on your store or its theme, and we do not change the theme in any way.</li>
              <li>If you decide you don&apos;t want this integration on your storefront, it&apos;s safe, quick, and easy to click the <i>Remove from storefront</i> button right here and it will be gone immediately. Also, remember to disable the Evey theme extension.</li>
              <li>Shopify storefront and theme combinations are all unique. This integration is built to work well on common setups but may not work as expected right away if you have a lot of customizations in your theme. If you do have any issues or are looking for a more custom solution, please reach out - we&apos;d be happy to help!</li>
            </ul>
          </Banner>
        </Card>
      )}
    </Mutation>
  );
};

export default StorefrontScriptCard;
