import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Card, Button, Select, FormLayout, TextContainer, Stack, Checkbox, TextField, Link as ShopifyLink } from '@shopify/polaris';
import { useFrame } from '../../contexts/frame';
import { hasPlanFeature } from '../../utils/features';
import UpgradeRequiredBanner from '../shared/UpgradeRequiredBanner';

const UNLINK_ACCOUNT = gql`
  mutation unlinkKlaviyo($klaviyoIntegrationId: ID!) {
    unlinkKlaviyo(klaviyoIntegrationId: $klaviyoIntegrationId) {
      success
    }
  }
`;

const EDIT_KLAVIYO = gql`
  mutation editKlaviyo($klaviyoIntegrationId: ID! $publicApiKey: String, $apiKey: String, $listId: String, $tagEventName: Boolean, $tagTicketType: Boolean, $customTags: String) {
    editKlaviyo(klaviyoIntegrationId: $klaviyoIntegrationId, publicApiKey: $publicApiKey, apiKey: $apiKey, listId: $listId, tagEventName: $tagEventName, tagTicketType: $tagTicketType, customTags: $customTags) {
      success
    }
  }
`;

const KlaviyoCard = ({ shop, klaviyo, redirectTo }) => {
  const [publicApiKey, setPublicApiKey] = React.useState('');
  const [selectedListId, setSelectedListId] = React.useState(klaviyo.syncListId || '0');
  const [tagEventName, setTagEventName] = React.useState(klaviyo.tagEventName);
  const [tagTicketType, setTagTicketType] = React.useState(klaviyo.tagTicketType);
  const [customTags, setCustomTags] = React.useState(klaviyo.customTags);
  const [linking, setLinking] = React.useState(false);

  const { showToastNotice, showToastError } = useFrame();

  const isAccountLinked = klaviyo && klaviyo.publicApiKey && klaviyo.publicApiKey.length > 0;

  const hasKlaviyoFeature = hasPlanFeature({ shop, feature: 'klaviyo' });

  return (
    <Mutation mutation={EDIT_KLAVIYO}
      onError={() => showToastError('Unable to update Klaviyo settings')}
      onCompleted={(data) => showToastNotice('Klaviyo settings updated')}
      refetchQueries={() => ['EventQuery', 'GetShopIntegrations']}
      awaitRefetchQueries={true}
    >
      {(editKlaviyo, { loading: saving }) => (
        <Card
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            disabled: !hasKlaviyoFeature,
            onAction: () => {
              if (isAccountLinked && (klaviyo.publicApiKey == klaviyo.apiKey)) {
                editKlaviyo({
                  variables: {
                    klaviyoIntegrationId: klaviyo.id,
                    listId: selectedListId,
                    // tagEventName,
                    // tagTicketType,
                    // customTags,
                  }
                });
              } else {
                editKlaviyo({
                  variables: {
                    klaviyoIntegrationId: klaviyo.id,
                    publicApiKey,
                  }
                });
              }
            }
          }}
        >
          {!hasKlaviyoFeature &&
            <Card.Section>
              <UpgradeRequiredBanner message='Upgrade your plan to access the Klaviyo integration settings.' />
            </Card.Section>
          }
          <Card.Section title="Link Klaviyo account">
            {isAccountLinked && (klaviyo.publicApiKey == klaviyo.apiKey) ? (
              <Mutation mutation={UNLINK_ACCOUNT}
                onError={() => showToastError('Unable to unlink account')}
                onCompleted={(data) => showToastNotice('Klaviyo account unlinked')}
                refetchQueries={() => ['EventQuery', 'GetShopIntegrations']}
                awaitRefetchQueries={true}
              >
                {(unlinkKlaviyo, { loading: unlinking }) => (
                  <FormLayout>
                    <TextContainer>Klaviyo Private API Key: {klaviyo.publicApiKey}</TextContainer>
                    <Button
                      outline={true}
                      destructive={true}
                      loading={unlinking}
                      disabled={saving}
                      onClick={() => {
                        unlinkKlaviyo({
                          variables: {
                            klaviyoIntegrationId: klaviyo.id
                          },
                        });
                      }}
                    >
                      Unlink Klaviyo Account
                    </Button>
                  </FormLayout>
                )}
              </Mutation>
            ) : (
              <Stack vertical={true}>
                <TextField
                  value={publicApiKey}
                  disabled={saving}
                  onChange={setPublicApiKey}
                  label='Private API Key'
                  //helpText=<span>Create a private API key in your Klaviyo <ShopifyLink external={true} url='https://www.klaviyo.com/account#api-keys-tab'>account settings</ShopifyLink>.</span>
                  helpText={<span>Create your private API key from your Klaviyo with full scopes.</span>}
                />
                <TextContainer>Need help? Read <ShopifyLink external url="https://www.klaviyo.com/account#api-keys-tab" removeUnderline target="_blank">this article</ShopifyLink> for step by step guidance. </TextContainer>
              </Stack>
            )}
          </Card.Section>
          {/*isAccountLinked &&
            <Card.Section title="Step 2. Select Klaviyo list to sync attendee list with">
              <FormLayout>
                <Select
                  label=''
                  disabled={saving}
                  options={[
                    { label: '', value: '0' },
                    ...klaviyo.lists.map((list) => ({ label: list.name, value: list.id })),
                  ]}
                  onChange={setSelectedListId}
                  value={selectedListId}
                />
              </FormLayout>
            </Card.Section>
          */}
          {/*isAccountLinked &&
            <Card.Section title="Step 3. Configure tagging options">
              <FormLayout>
                <Checkbox
                  checked={tagEventName}
                  label="Tag the contact with the name of the event they purchased"
                  onChange={setTagEventName}
                  disabled={saving}
                />
                <Checkbox
                  checked={tagTicketType}
                  label="Tag the contact with the name of the ticket type they purchased"
                  onChange={setTagTicketType}
                  disabled={saving}
                />
                <TextField
                  value={customTags}
                  label="Custom tags (comma separated)"
                  placeholder="Events, Summer 2022"
                  onChange={setCustomTags}
                  disabled={saving}
                />
              </FormLayout>
            </Card.Section>
          */}
        </Card>
      )}
    </Mutation>
  );
};

export default KlaviyoCard;
