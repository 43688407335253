
const env = document.head.querySelector('[name=environment]').content;
const storefrontHost = document.head.querySelector('[name=storefront-host]').content;
export const BASE_URL = env === 'development'
  ? `https://${storefrontHost}/storefront`
  : env === 'staging'
    ? `${storefrontHost}/storefront`
    : 'https://tufpbsjsl6.execute-api.us-east-1.amazonaws.com/production-v2/storefront';

export const request = async ({ path, params, data, method }) => {
  const url = new URL(`${BASE_URL}${path}`);
  url.search = new URLSearchParams(params || {}).toString();
  console.log(`url: ${url}`);
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(data && {
      body: JSON.stringify(data),
    }),
  });
  return await response.json();
};

export const storefrontGet = async ({ path, params }) => {
  return await request({ path, params, method: 'GET' });
};

export const storefrontPost = async ({ path, data }) => {
  return await request({ path, data, method: 'POST' });
};
