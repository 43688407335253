import React from 'react';
import EveyComponent from '../EveyComponent.jsx';
import PropTypes from 'prop-types';
import { Layout, Card, TextStyle, Button } from '@shopify/polaris';
import { Context } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import TimeAgo from 'react-timeago';
import SubscribeEvent from '../SubscribeEvent/SubscribeEvent.jsx';
import UnsubscribeEvent from '../UnsubscribeEvent/UnsubscribeEvent.jsx';
import { goToPage } from '../../utils/routing';
import { getHostFromShopDomain } from '../../utils/auth';

export default class EventSettings extends EveyComponent {
  constructor(props) {
    super(props);
    this.state = {
      unsubscribeEventClicked: false,
      activateClicked: false
    };
  }

  static contextType = Context;

  render() {
    const app = this.context;
    const shop = this.props.shop;
    const event = this.props.event;

    const hostParam = "&host=" + getHostFromShopDomain(shop?.domain);

    return (
      <div>
        <Layout.AnnotatedSection
          title="Subscription"
          description=""
        >
          <div id="subscription-section">
            {event.isShopifySubscription ? (
              <Card sectioned>
                <p>
                  You currently have an active subscription for this event. Your subscription cost per event is {shop.prettyEventPrice} per month.
                </p>
                <p>
                  <TextStyle variation="strong">We can not automatically cancel any subscriptions for you at any time, even for example after your event End Date is over or if you delete the product in Shopify.</TextStyle> To cancel this subscription click the button below.
                </p>
                <p>
                  <Button
                    destructive={true}
                    onClick={() => {
                      this.setState({ unsubscribeEventClicked: true });
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </p>
              </Card>
            ) : (
              <>
                {event.dateType === 'recurring' && !shop.canUseRecurringEvents ? (
                  <Card sectioned>
                    <p>You do not have an active subscription for this event at the moment.</p>
                    {event.trial ? (
                      <p>
                            Your event is in a trial period and will expire in <TimeAgo date={event.expiresAt}/>. Recurring events are available on the Unlimited events plan, follow the link below to subscribe.
                      </p>
                    ) : (
                      <p>
                            Your event trial period has expired, subscribe to continue selling tickets or to retain access to your attendee data. Recurring events are available on the Unlimited events plan, follow the link below to subscribe.
                      </p>
                    )
                    }
                    <Button
                      onClick={() => goToPage({ history: this.props.history, path: '/settings/account?tab=subscription&plan=shop' })}
                    >
                        Learn more
                    </Button>
                  </Card>
                ) : (
                  <Card sectioned>
                    <p>You do not have an active subscription for this event at the moment.</p>
                    {event.trial ? (
                      <p>
                            Your event is in a trial period and will expire in <TimeAgo date={event.expiresAt}/>. Activating an event costs ${parseInt(event.price)}/month for each event.
                      </p>
                    ) : (
                      <p>
                            Your event trial period has expired, activate your event to continue selling tickets or to retain access to your attendee data. Activating an event costs ${parseInt(event.price)}/month for each event.
                      </p>
                    )
                    }
                    <Button
                      onClick={() => {
                        this.setState({ activateClicked: true });
                      }}
                    >
                        Activate Event
                    </Button>
                  </Card>
                )}
              </>
            )
            }
          </div>
        </Layout.AnnotatedSection>
        {this.state.activateClicked &&
          <SubscribeEvent
            event={event}
            onConfirmRequest={(confirmUrl) => {
              const redirect = Redirect.create(app);
              redirect.dispatch(Redirect.Action.ADMIN_PATH, confirmUrl);
            }}
            onError={() => {
              this.setState({ activateClicked: false });
              this.props.flashCallback(true, 'Error activating event, please try again or contact support', true);
            }}
            onDismiss={() => {
              this.setState({ activateClicked: false });
            }}
          />
        }
        {this.state.unsubscribeEventClicked &&
          <UnsubscribeEvent
            event={event}
            onConfirmRequest={(confirmUrl) => {
              const redirect = Redirect.create(app);
              redirect.dispatch(Redirect.Action.ADMIN_PATH, confirmUrl);
            }}
            onComplete={() => {
              window.location.reload();
            }}
            onError={() => {
              this.setState({ unsubscribeEventClicked: false });
              this.props.flashCallback(true, 'Error unsubscribing from event, please try again or contact support', true);
            }}
            onDismiss={() => {
              this.setState({ unsubscribeEventClicked: false });
            }}
          />
        }
      </div>
    );
  }
}

EventSettings.propTypes = {
  error: PropTypes.string,
  flashCallback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
