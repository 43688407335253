import React from 'react';
import { Mutation } from 'react-apollo';
import { Card, Banner, FormLayout, TextField, Checkbox, Select, Button, Label, Stack } from '@shopify/polaris';
import { EDIT_EVENT } from '../../../utils/graphql';
import { reportError, formatErrors, hasErrors } from '../../../utils/errors';
import { useFrame } from '../../../contexts/frame';
import { goToPage } from '../../../utils/routing';
import { parseBoolean } from '../../../utils/parse';
import ScheduleAvailabilityModal from './ScheduleAvailabilityModal';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ActionScheduleHistoryModal from './ActionScheduleHistoryModal';
import { Editor } from '@tinymce/tinymce-react';
import ImagePicker from '../../shared/ImagePicker';

const TINY_MCE_API = document.head.querySelector('[name=tiny-mce-api]').content;

dayjs.extend(localizedFormat);

const EventDetailsCard = ({ shop, event }) => {
  const [errors, setErrors] = React.useState([]);
  const [unknownError, setUnknownError] = React.useState(false);
  const [title, setTitle] = React.useState(event.title);
  const [description, setDescription] = React.useState(event.description);
  const [imageUrl, setImageUrl] = React.useState(event.imageUrl);
  const [visibleStatus, setVisibleStatus] = React.useState(event.published ? 'active' : 'draft');
  const [showScheduleAvailability, setShowScheduleAvailability] = React.useState(false);
  const [showActionScheduleHistoryModal, setShowActionScheduleHistoryModal] = React.useState(false);

  const { showToastNotice, showToastError, loadingMarkup, isVendor, navigateTo, appHost, newEventsAllowPublish } = useFrame();

  const editorRef = React.useRef(null);

  return (
    <Mutation mutation={EDIT_EVENT}
      onError={(error) => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].errors) {
          setErrors(error.graphQLErrors[0].errors);
          setUnknownError(false);
        } else {
          setErrors([]);
          setUnknownError(true);
          reportError(error);
        }
      }}
      onCompleted={(data) => {
        if (data && data.editEvent) {
          setErrors([]);
          setUnknownError(false);
          showToastNotice('Event updated');
        }
      }}
      refetchQueries={() => ['EventQuery']}
    >
      {(editEvent, { loading: saving }) => (
        <Card
          actions={[
            {
              content: 'View on storefront',
              onAction: async () => {
                const url = `https://${appHost}/events/${event.id}/storefront_url?shop=${shop.domain}&shop_id=${shop.remoteId}`;
                navigateTo({
                  history,
                  path: url,
                  external: true,
                  newContext: true
                });
              },
            }
          ]}
          primaryFooterAction={{
            content: 'Save',
            loading: saving,
            onAction: () => {
              editEvent({
                variables: {
                  eventId: event.id,
                  title: title,
                  ...(isVendor ? {
                    description: description,
                    imageUrl: imageUrl,
                  } : {}),
                  published: visibleStatus == 'active',
                }
              });
            },
          }}
        >
          {unknownError &&
            <Card.Section>
              <Banner title="There was a problem updating your event" status="critical">
                Please ensure all required fields are filled out and try submitting again. Contact support at support@eveyevents.com if the problem persists.
              </Banner>
            </Card.Section>
          }
          {hasErrors(errors) &&
            <Card.Section>
              <Banner title="There was a problem updating your event" status="critical">{formatErrors(errors)}</Banner>
            </Card.Section>
          }
          {saving && loadingMarkup}
          <Card.Section>
            <FormLayout>
              <TextField value={title || ''} label="Event Name" placeholder="Awesome Event" onChange={(v) => setTitle(v)} />
              {isVendor &&
                <Stack vertical={true} spacing='extraTight'>
                  <Label>Product description</Label>
                  <Editor
                    apiKey={`${TINY_MCE_API}`}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={event.description || ''}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | link | bullist numlist outdent indent | ' +
                      'removeformat | code',
                      content_style: 'body { font-size:14px; color:rgba(32, 34, 35, 1); font-weight:400 }'
                    }}
                    onEditorChange={setDescription}
                    value={description}
                   />
                </Stack>
              }
              {isVendor &&
                <ImagePicker
                  label='Product image'
                  shop={shop}
                  value={imageUrl}
                  onChange={setImageUrl}
                  helpText='Image displayed on the storefront product page for this event.'
                />
              }
            </FormLayout>
          </Card.Section>
          {newEventsAllowPublish &&
            <Card.Section title='Product status'>
              <FormLayout>
                <Select
                  options={[
                    { label: "Active", value: 'active' },
                    { label: 'Draft', value: 'draft' },
                  ]}
                  value={visibleStatus}
                  onChange={(v) => setVisibleStatus(v)}
                  helpText='Active products are visible on your storefront. Draft products are not visible and cannot be purchased.'
                />
                <Button
                  plain
                  monochrome
                  onClick={() => setShowScheduleAvailability(true)}
                >
                  Schedule availability
                </Button>
                {event.publishSchedule && !event.publishSchedule.triggeredAt ? (
                  <Banner status='info'>
                    This event is scheduled to be made available for sale on {dayjs(event.scheduledPublishDate).format('LLL')}
                  </Banner>
                ) : ''}
                {event.unpublishSchedule && !event.unpublishSchedule.triggeredAt ? (
                  <>
                    {event.dateType === 'recurring' ? (
                      <Banner
                        status='info'
                        secondaryAction={{
                          content: 'View history',
                          onAction: () => setShowActionScheduleHistoryModal(event.unpublishSchedule),
                        }}
                      >
                        Every event instance in the recurring event schedule is scheduled to be made <strong>not</strong> available for sale.
                      </Banner>
                    ) : (
                      <Banner status='info'>
                        This event is scheduled to be made <strong>not</strong> available for sale on {dayjs(event.scheduledUnpublishDate).format('LLL')}
                      </Banner>
                    )}
                  </>
                ) : ''}
              </FormLayout>
            </Card.Section>
          }
          {showScheduleAvailability &&
            <ScheduleAvailabilityModal
              event={event}
              onClose={() => setShowScheduleAvailability(false)}
            />
          }
          {showActionScheduleHistoryModal &&
            <ActionScheduleHistoryModal
              event={event}
              actionSchedule={showActionScheduleHistoryModal}
              onClose={() => setShowActionScheduleHistoryModal(false)}
            />
          }
        </Card>
      )}
    </Mutation>
  );
};

export default EventDetailsCard;
