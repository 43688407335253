import React from 'react';
import PropTypes from 'prop-types';
import ErrorTemplate from './ErrorTemplate.jsx';
import EveyComponent from '../EveyComponent.jsx';
import { Spinner } from '@shopify/polaris';
import { FullHeightContainer } from '../shared/containers';

class ErrorPage extends EveyComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.reportError(error, {info: info});
  }

  render() {
    if (this.state.hasError) {
      return <ErrorTemplate />;
    } else if(this.props.error){
      if (this.props.error.message == 'Network error: cancelled') {
        return '';
      } else if (this.props.error.message.indexOf('Received status code 401') !== -1 || this.props.error.message.indexOf('Failed to retrieve a session token') !== -1) {
        return (
          <FullHeightContainer alignVertically>
            <Spinner size="small" />
          </FullHeightContainer>
        );
      } else {
        this.reportError(this.props.error);
        return <ErrorTemplate />;
      }
    } else {
      return this.props.children;
    }
  }
}

ErrorPage.propTypes = {
  children: PropTypes.object,
  error: PropTypes.object
};

export default ErrorPage;
