import React from 'react';
import { Badge, ResourceItem, Stack, TextContainer } from '@shopify/polaris';

const CommunicationResourceItem = ({ communication, onSelect }) => {
  const didFail = !communication.sentAt || communication.errorCode;
  const isTicket = communication.tags.indexOf('ticket') != -1;

  let statusBadge = <Badge status='success'>Delivered</Badge>;
  if (didFail) {
    statusBadge = <Badge status='critical'>Failed</Badge>;
  }

  return (
    <ResourceItem
      id={communication.id}
      onClick={() => onSelect(communication)}
    >
      <Stack vertical={false} distribution='equalSpacing' alignment='center'>
        <Stack vertical={true} distribtion='leading'>
          <Badge status='info'>{isTicket ? 'Ticket' : 'Message'} {communication.communicationType == 'sms' ? 'SMS' : 'Email'}</Badge>
          <TextContainer>{(new Date(communication.createdAt)).toLocaleString()}</TextContainer>
          <TextContainer>To: {communication.to}</TextContainer>
          {communication.from && communication.from.length > 0 &&
            <TextContainer>From: {communication.from}</TextContainer>
          }
          {communication.communicationType == 'email' &&
            <TextContainer>Subject: {communication.subject}</TextContainer>
          }
        </Stack>
        <Stack vertical={true} distribtion='trailing' alignment='center'>
          {statusBadge}
        </Stack>
      </Stack>
    </ResourceItem>
  );
};

export default CommunicationResourceItem;
